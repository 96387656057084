import {z} from '@cheddarup/util'

export const orgMemberSchema = z
  .object({
    id: z.number(),
  })
  .passthrough()

declare global {
  namespace Api {
    type OrgMember = z.infer<typeof orgMemberSchema>
  }
}
