import {z} from '@cheddarup/util'

export const tabInviteSchema = z
  .object({
    id: z.number(),
  })
  .passthrough()

declare global {
  namespace Api {
    type TabInvite = z.infer<typeof tabInviteSchema>
  }
}
