import {z} from '@cheddarup/util'

export const orgMemberInviteSchema = z
  .object({
    id: z.number(),
  })
  .passthrough()

declare global {
  namespace Api {
    type OrgMemberInvite = z.infer<typeof orgMemberInviteSchema>
  }
}
