import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useRemindManagerMutation,
  useUpdateManagerMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import * as Util from '@cheddarup/util'
import {SharpAvatar} from 'src/components/SharpAvatar'

import {CollectionSettingsPanel} from './CollectionSettingsPanel'
import {CollectionSettingsUpgradePlanButton} from './CollectionSettingsUpgradePlanButton'

// MARK: – ManagersSettings

export interface ManagersSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ManagersSettings = ({
  collection,
  ...restProps
}: ManagersSettingsProps) => {
  const otherManagersQuery = api.managers.list.useQuery(undefined, {
    select: (managers) => {
      const collectionManagerIds = Array.isArray(collection.managers)
        ? collection.managers.map((m) => m.id)
        : []
      return managers.filter((m) => !collectionManagerIds.includes(m.id))
    },
  })
  const updateManagerMutation = useUpdateManagerMutation()

  const label =
    'Give others access to edit, view payments and receive notifications.'

  return (
    <CollectionSettingsPanel
      heading="Managers"
      settings={[
        <WebUI.Disclosure
          key="disclosure"
          className="gap-4"
          initialVisible={collection.status === 'draft' || collection.is_team}
        >
          {collection.status === 'draft' || collection.is_team ? (
            <span>{label}</span>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="team">
              {label}
            </CollectionSettingsUpgradePlanButton>
          )}
          <WebUI.DisclosureContent>
            <WebUI.VStack className="gap-8">
              <div className="w-4/5 text-ds-sm">
                <WebUI.Anchor
                  href="https://support.cheddarup.com/hc/en-us/articles/360035587291-Assign-manager-permissions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more about setting permissions
                </WebUI.Anchor>
                <br />
                <span className="font-bold">
                  You can add new managers and edit manager permissions within{' '}
                  <Link variant="primary" target="_blank" to="/managers">
                    the Managers section
                  </Link>
                  .
                </span>
              </div>

              {otherManagersQuery.data && otherManagersQuery.data.length > 0 ? (
                <WebUI.DropdownSelect
                  className="max-w-[180px]"
                  variant="secondary"
                  size="compact"
                  placeholder="Add Manager"
                  onValueChange={(managerId) => {
                    const manager = otherManagersQuery.data.find(
                      (u) => u.id === managerId,
                    )

                    if (manager) {
                      updateManagerMutation.mutate({
                        pathParams: {
                          managerId: manager.id,
                        },
                        body: {
                          ...manager,
                          permissions: {
                            ...manager.permissions,
                            collectionIds: Util.unique([
                              ...manager.permissions.collectionIds,
                              collection.id,
                            ]),
                          },
                        },
                      })
                    }
                  }}
                >
                  <WebUI.DropdownSelectOption
                    value="addNewManager"
                    as={Link}
                    iconAfter={
                      <WebUI.PhosphorIcon
                        icon="arrow-square-out-fill"
                        width={16}
                      />
                    }
                    target="_blank"
                    to="/managers"
                  >
                    Add New Manager
                  </WebUI.DropdownSelectOption>
                  {otherManagersQuery.data.map((u) => (
                    <WebUI.DropdownSelectOption key={u.id} value={u.id}>
                      {u.name}
                    </WebUI.DropdownSelectOption>
                  ))}
                </WebUI.DropdownSelect>
              ) : (
                <LinkButton
                  className="self-start"
                  size="compact"
                  variant="secondary"
                  iconAfter={
                    <WebUI.PhosphorIcon
                      icon="arrow-square-out-fill"
                      width={16}
                    />
                  }
                  to="/managers"
                >
                  Add Manager
                </LinkButton>
              )}
              {collection.managers && collection.managers.length > 0 && (
                <>
                  <WebUI.Separator className="-mx-6" variant="primary" />
                  <WebUI.VStack className="gap-4 sm:w-4/5">
                    {collection.managers.map((m) => (
                      <ManagerListItem key={m.id} manager={m} />
                    ))}
                  </WebUI.VStack>
                </>
              )}
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – MainContactSettings

export interface MainContactSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const MainContactSettings = ({
  collection,
  ...restProps
}: MainContactSettingsProps) => {
  const {data: acceptedManagers} = api.managers.list.useQuery(undefined, {
    select: (managers) => managers.filter((m) => m.accepted_at) ?? [],
  })
  const updateCollectionMutation = useUpdateTabMutation()

  return (
    <CollectionSettingsPanel
      heading="Main contact"
      settings={[
        <WebUI.Disclosure
          key="disclosure"
          className="gap-4"
          visible={collection.status === 'draft' || collection.is_team}
        >
          {collection.status === 'draft' || collection.is_team ? (
            <span>
              Select main contact for payer inquires:{' '}
              <span className="text-orange-50">
                {collection.contact_manager_id
                  ? acceptedManagers?.find(
                      (m) => m.id === collection.contact_manager_id,
                    )?.name ?? collection.organizer.name
                  : collection.organizer.name}
              </span>
            </span>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="team">
              Select a manager to receive email inquiries from the contact link
              on your collection page.
            </CollectionSettingsUpgradePlanButton>
          )}
          <WebUI.DisclosureContent>
            <WebUI.VStack className="gap-4">
              <div className="w-4/5 text-ds-sm">
                Select a manager to receive email inquiries from the contact
                link on your collection page.
              </div>

              {acceptedManagers && acceptedManagers.length > 0 ? (
                <WebUI.DropdownSelect
                  className="max-w-[180px]"
                  variant="secondary"
                  size="compact"
                  placeholder="Main Contact"
                  onValueChange={(newManagerId) => {
                    if (newManagerId !== collection.contact_manager_id) {
                      updateCollectionMutation.mutate({
                        pathParams: {
                          tabId: collection.id,
                        },
                        body: {
                          contact_manager_id:
                            newManagerId == null ? null : Number(newManagerId),
                        },
                      })
                    }
                  }}
                >
                  <WebUI.DropdownSelectOption
                    value="addNewManager"
                    as={Link}
                    iconAfter={
                      <WebUI.PhosphorIcon
                        icon="arrow-square-out-fill"
                        width={16}
                      />
                    }
                    target="_blank"
                    to="/managers"
                  >
                    Add New Manager
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={null}>
                    {collection.organizer.name}
                  </WebUI.DropdownSelectOption>
                  {acceptedManagers.map((m) => (
                    <WebUI.DropdownSelectOption key={m.id} value={m.id}>
                      {m.name}
                    </WebUI.DropdownSelectOption>
                  ))}
                </WebUI.DropdownSelect>
              ) : (
                <LinkButton
                  className="self-start"
                  variant="secondary"
                  size="compact"
                  iconAfter={
                    <WebUI.PhosphorIcon
                      icon="arrow-square-out-fill"
                      width={16}
                    />
                  }
                  to="/managers"
                >
                  Add Manager
                </LinkButton>
              )}
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – ManagerListItem

interface ManagerListItemProps extends React.ComponentPropsWithoutRef<'div'> {
  manager: Api.Manager
}

const ManagerListItem: React.FC<ManagerListItemProps> = ({
  manager,
  className,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const [remindingManager, setRemindingManager] = useState(false)
  const remindManagerMutation = useRemindManagerMutation()
  const growlActions = WebUI.useGrowlActions()

  const status = manager.accepted_at ? 'Active' : 'Pending'

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'items-start justify-center gap-4 border p-4 font-light text-ds-sm sm:flex-row sm:items-center sm:justify-start',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="flex-[0_1_40%] items-center gap-4">
        <SharpAvatar
          alt="Manager avatar"
          size={36}
          image={manager.granted_user?.profile_pic}
          name={manager.name}
        />
        <WebUI.VStack>
          <span className="font-normal text-ds-base">{manager.name}</span>
          <span>{manager.granted_user?.email ?? manager.invited_email}</span>
        </WebUI.VStack>
      </WebUI.HStack>
      {media.sm && (
        <span className="flex-[0_1_30%] font-normal">
          {status}
          {status === 'Pending' && (
            <>
              {' '}
              |{' '}
              <WebUI.Button
                className="[&_>_.Text]:font-light"
                variant="link"
                loading={remindingManager}
                onClick={async () => {
                  try {
                    setRemindingManager(true)
                    const res = await remindManagerMutation.mutateAsync({
                      pathParams: {
                        managerId: manager.id,
                      },
                    })
                    growlActions.show('success', {
                      title: 'Success!',
                      body: `Invitation re-sent to: ${res.invited_email}`,
                    })
                  } finally {
                    setRemindingManager(false)
                  }
                }}
              >
                Resend
              </WebUI.Button>
            </>
          )}
        </span>
      )}
      <Link
        className="flex-[0_1_30%] [&_>_.Text]:font-light"
        variant="primary"
        target="_blank"
        to="/managers"
      >
        Permissions
      </Link>
    </WebUI.VStack>
  )
}
