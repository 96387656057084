import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {useCubeQuery} from 'src/hooks/cube'
import {Filter, TimeDimension} from '@cubejs-client/core'

import {HomePageChartsView} from './HomePageChartsView'
import {
  ReportOverviewDateRangePicker,
  getDateRangeFromTimeDimension,
} from '../../ReportsPage/components/ReportOverview'

const HomePage = () => {
  const [overviewRange, setOverviewRange] = useState(
    getDateRangeFromTimeDimension('yearToDate'),
  )

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <WebUI.Heading as="h2">Overview</WebUI.Heading>

        <ReportOverviewDateRangePicker
          defaultTimeDimension="yearToDate"
          dateRange={overviewRange}
          onDateRangeChange={(newDateRange) => setOverviewRange(newDateRange)}
        />
      </WebUI.HStack>

      <WebUI.HStack
        className="[&_>_.Stack]:flex-[0_0_33.33%] [&_>_.Stack]:p-6"
        as={WebUI.Panel}
      >
        <OverviewTotal
          measure="Payments.totalPayers"
          timeDimension={{
            dimension: 'Payments.createdAt',
            dateRange: overviewRange,
          }}
          title="TOTAL PAYERS"
        />
        <WebUI.Separator orientation="vertical" variant="primary" />
        <OverviewTotal
          measure="Payments.totalPaid"
          timeDimension={{
            dimension: 'Payments.createdAt',
            dateRange: overviewRange,
          }}
          title="TOTAL VOLUME"
          isAmount
        />
        <WebUI.Separator orientation="vertical" variant="primary" />
        <OverviewTotal
          measure="Collections.totalCollectors"
          timeDimension={{
            dimension: 'Payments.createdAt',
            dateRange: overviewRange,
          }}
          title="TOTAL COLLECTORS"
        />
      </WebUI.HStack>

      <WebUI.HStack className="*:flex-[0_0_50%]" as={WebUI.Panel}>
        <OverviewTop
          dimensions={[
            'Users.id',
            'Users.name',
            'Users.profilePicturePath',
            'Users.email',
          ]}
          measures={['Payments.totalPaid']}
          timeDimension={{
            dimension: 'Payments.createdAt',
            dateRange: overviewRange,
          }}
          order="Payments.totalPaid"
          title="Top Collectors"
          bottomLink="/reporting/collectors"
          bottomLinkText="view all collectors"
        />
        <WebUI.Separator orientation="vertical" variant="primary" />
        <OverviewTop
          dimensions={[
            'Collections.id',
            'Collections.name',
            'Users.profilePicturePath',
            'Users.email',
            'Collections.paymentsTotal',
          ]}
          timeDimension={{
            dimension: 'Payments.createdAt',
            dateRange: overviewRange,
          }}
          order="Collections.paymentsTotal"
          title="Top Collection Pages"
          bottomLink="/reporting/collection-pages"
          bottomLinkText="view all collection pages"
        />
      </WebUI.HStack>

      <HomePageChartsView />
    </WebUI.VStack>
  )
}

// MARK: – OverviewTotal

interface OverviewTotalProps {
  measure: string
  timeDimension: TimeDimension
  title: string
  isAmount?: boolean
}
const OverviewTotal: React.FC<OverviewTotalProps> = ({
  measure,
  timeDimension,
  title,
  isAmount,
  ...restProps
}) => {
  const {resultSet} = useCubeQuery({
    measures: [measure],
    timeDimensions: [timeDimension],
  })
  const total = resultSet?.tablePivot()[0]?.[measure] ?? 0

  return (
    <WebUI.VStack {...restProps}>
      <div className="font-semibold text-ds-xs">{title}</div>
      <div className="font-normal text-ds-xl">
        {isAmount ? Util.formatAmount(Number(total)) : Number(total)}
      </div>
    </WebUI.VStack>
  )
}

// MARK: – OverviewTop

interface OverviewTopProps extends React.ComponentPropsWithoutRef<'div'> {
  timeDimension: TimeDimension
  dimensions: any
  measures?: [string]
  filters?: Filter[]
  order: string
  title: string
  bottomLink: string
  bottomLinkText: string
}

const OverviewTop: React.FC<OverviewTopProps> = ({
  timeDimension,
  dimensions,
  measures,
  filters,
  order,
  title,
  bottomLink,
  bottomLinkText,
  className,
  ...restProps
}) => {
  const {resultSet} = useCubeQuery({
    dimensions,
    measures,
    filters,
    timeDimensions: [timeDimension],
    order: [[order, 'desc']],
    limit: 3,
  })
  const topEntities = resultSet?.tablePivot() || []

  return (
    <WebUI.VStack className={WebUI.cn('gap-4 p-6', className)} {...restProps}>
      <div className="font-normal text-ds-md">{title}</div>
      {topEntities.map((entity) => (
        <WebUI.HStack
          key={entity[dimensions[0]] as string}
          className="justify-between"
        >
          <WebUI.HStack className="gap-4">
            <SharpAvatar
              size={36}
              name={entity[dimensions[1]] as string}
              image={entity[dimensions[2]] as any}
            />
            <div className="font-normal text-ds-sm">
              {entity[dimensions[1]]}
              <br />
              <small className="text-ds-xs">{entity[dimensions[3]]}</small>
            </div>
          </WebUI.HStack>
          <div className="font-normal text-ds-md">
            {Util.formatAmount(
              Number(entity[measures ? measures[0] : dimensions[4]]),
            )}
          </div>
        </WebUI.HStack>
      ))}
      <Link className="text-ds-sm uppercase" variant="primary" to={bottomLink}>
        {bottomLinkText}
      </Link>
    </WebUI.VStack>
  )
}

export default HomePage
