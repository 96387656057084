import React, {useRef} from 'react'
import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateFormMutation,
  useUpdateFormMutation,
} from '@cheddarup/api-client'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import WaiverFormIcon from 'src/images/WaiverFormIcon.svg'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'

export interface AddWaiverPageProps {
  waiver?: Api.TabForm | null
}

const AddWaiverPage = ({waiver}: AddWaiverPageProps) => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const dialogDefaultHideRef = useRef<(() => void) | null>(null)
  const alertRef = useRef<WebUI.DialogInstance>(null)
  const collectionId = Number(urlParams.collection)

  const createFormMutation = useCreateFormMutation()
  const updateFormMutation = useUpdateFormMutation()
  const {data: collection} = api.tabs.detail.useSuspenseQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const isBasic = !collection?.is_pro && !collection?.is_team
  const enforceAddlGatFeatures = !collection?.options?.doNotEnforceAddlGated
  const showPaidBadge = isBasic && enforceAddlGatFeatures

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Waiver Name is required'),
    }),
    initialValues: {
      name: waiver?.name ?? '',
      description: waiver?.description ?? '',
    },
    onSubmit: async (values) => {
      if (waiver) {
        await updateFormMutation.mutateAsync({
          pathParams: {
            tabId: collectionId,
            formId: waiver.id,
          },
          body: {...waiver, ...values},
        })
      } else {
        await createFormMutation.mutateAsync({
          pathParams: {
            tabId: collectionId,
          },
          body: {required: true, ...values, options: {isWaiver: true}},
        })
      }

      navigate('..')
    },
  })

  if (isBasic && collection?.status !== 'draft' && enforceAddlGatFeatures) {
    return <Navigate to="../i/plans" />
  }

  return (
    <PremiumFeaturesSideSheet
      tabId={collectionId}
      enforcedPremiumMeta={{pro: {waiver: true}}}
    >
      <WebUI.Modal
        ref={(dialog) => {
          if (!dialog) {
            return
          }

          dialogDefaultHideRef.current = dialog.hide
          dialog.hide = () => {
            if (formik.dirty) {
              alertRef.current?.show()
            } else {
              dialogDefaultHideRef.current?.()
            }
          }
        }}
        className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
        onDidHide={() => navigate('..')}
      >
        {(dialog) => (
          <>
            <WebUI.ModalCloseButton />
            <WebUI.VStack className="grow gap-7 overflow-y-auto px-4 pt-[45px] pb-4 sm:px-8">
              <WebUI.ModalHeader className="mb-0 p-0 pb-7">
                <WebUI.PageHeader
                  graphics={
                    !showPaidBadge && <img src={WaiverFormIcon} alt="" />
                  }
                  subheading="Four required fields (first name, last name, date and e-signature) will be added below your waiver text automatically."
                >
                  <WebUI.HStack className="gap-4">
                    {waiver ? 'Edit Waiver' : 'Add Waiver'}
                    {showPaidBadge && (
                      <PremiumFeatureSideSheetDisclosure
                        className="mb-1"
                        featureKeys={['waiver']}
                      />
                    )}
                  </WebUI.HStack>
                </WebUI.PageHeader>
              </WebUI.ModalHeader>
              <WebUI.Form onSubmit={formik.handleSubmit}>
                <WebUI.FormField error={formik.errors.name}>
                  <WebUI.Input
                    name="name"
                    placeholder="Waiver Name (required)"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>

                <WebUI.RichTextEditor
                  className="min-h-[200px]"
                  name="description"
                  placeholder="Waiver Text"
                  initialMarkdownValue={formik.values.description}
                  onMarkdownValueChange={(newDescription) =>
                    formik.setFieldValue('description', newDescription)
                  }
                >
                  <WebUI.RichTextEditorToolbar rootClassName="-order-1" />
                </WebUI.RichTextEditor>
              </WebUI.Form>
            </WebUI.VStack>

            <WebUI.PageToolbar>
              <WebUI.PageToolbarSubmitButton
                arrow={false}
                loading={formik.isSubmitting}
                onClick={() => formik.submitForm()}
              >
                Save Waiver
              </WebUI.PageToolbarSubmitButton>
            </WebUI.PageToolbar>

            <UpgradeRequiredAlert
              visible={
                !waiver &&
                collection &&
                collection.status !== 'draft' &&
                !collection.is_pro &&
                collection.reportsAvailable.activeFormsCount +
                  collection.reportsAvailable.activeSignupsCount >=
                  1
              }
              onDidHide={() => dialog.hide()}
            />
            <DirtyFormConfirmAlert
              ref={alertRef}
              onProceed={() => dialogDefaultHideRef.current?.()}
            />
          </>
        )}
      </WebUI.Modal>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – DirtyFormConfirmAlert

export interface DirtyFormConfirmAlertProps extends WebUI.AlertProps {
  onProceed?: () => void
}

export const DirtyFormConfirmAlert = React.forwardRef<
  WebUI.DialogInstance,
  DirtyFormConfirmAlertProps
>(({onProceed, className, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Confirm closing waiver form modal"
    className={WebUI.cn('[&_.Alert-closeButton]:invisible', className)}
    {...restProps}
  >
    {(dialog) => (
      <>
        <WebUI.AlertHeader>
          Are you sure you want to close this waiver?
        </WebUI.AlertHeader>
        <WebUI.AlertContentView
          text="You haven’t saved your waiver and your information will be lost."
          actions={
            <>
              <WebUI.AlertActionButton onClick={() => onProceed?.()}>
                Close Waiver
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton
                onClick={(event) => {
                  event.preventDefault()
                  dialog.hide()
                }}
              >
                Cancel
              </WebUI.AlertCancelButton>
            </>
          }
        />
      </>
    )}
  </WebUI.Alert>
))

export default AddWaiverPage
