import type {EmptyObject, SimpleMerge} from '@cheddarup/util'
import React from 'react'
import reactFastCompare from 'react-fast-compare'
import flattenChildren from 'react-keyed-flatten-children'

export const reactDeepEqual = reactFastCompare

/**
 * Passthrough function used to type a full-fledged generic React component
 * based on a generic function
 */
export function generic<T, P = T extends (props: infer _P) => any ? _P : never>(
  Component: T,
) {
  return Component as any as React.ComponentType<P> & T
}

/** Generic version of React.forwardRef */
export const genericForwardRef = <
  R extends React.ForwardRefRenderFunction<any, any>,
  T = R extends React.ForwardRefRenderFunction<infer _T, any> ? _T : never,
  P = R extends React.ForwardRefRenderFunction<any, infer _P> ? _P : never,
>(
  render: R,
) =>
  React.forwardRef<T, P>(render) as React.ForwardRefExoticComponent<
    React.PropsWithoutRef<P> & React.RefAttributes<T>
  > &
    R

// See https://github.com/ariakit/ariakit/blob/1bd3b5adb21b3f81f979e8494b9798b1ca0c2939/packages/ariakit-react-core/src/utils/system.tsx#L23
/**
 * The same as `React.forwardRef` but passes the `ref` as a prop and returns a
 * component with the same generic type.
 */
export function newGenericForwardRef<T extends React.FC<any>>(render: T) {
  const Role = React.forwardRef<any>((props, ref) => render({...props, ref}))
  Role.displayName = render.displayName || render.name
  return Role as unknown as T
}

/** Generic version of React.memo */
export const genericMemo = <
  C,
  P = C extends (props: infer _P) => any ? _P : never,
>(
  Component: C,
  propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean,
) =>
  React.memo(
    Component as any,
    propsAreEqual,
  ) as any as React.MemoExoticComponent<React.ComponentType<P>> & C

/** Generic version of React.memo and React.forwardRef */
export const genericMemoForwardRef = <
  R extends React.ForwardRefRenderFunction<any, any>,
  T = R extends React.ForwardRefRenderFunction<infer _T, any> ? _T : never,
  P = R extends React.ForwardRefRenderFunction<any, infer _P> ? _P : never,
>(
  render: R,
  propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean,
) =>
  React.memo(
    React.forwardRef<T, P>(render),
    propsAreEqual as any,
  ) as React.ForwardRefExoticComponent<
    React.PropsWithoutRef<P> & React.RefAttributes<T>
  > &
    R

// Based on https://github.com/radix-ui/primitives/blob/main/packages/react/polymorphic/src/polymorphic.ts

type ForwardRefExoticComponent<E, TOwnProps> = React.ForwardRefExoticComponent<
  SimpleMerge<
    E extends React.ElementType ? React.ComponentPropsWithRef<E> : never,
    TOwnProps & {as?: E}
  >
>

/* -------------------------------------------------------------------------------------------------
 * ForwardRefComponent
 * -----------------------------------------------------------------------------------------------*/

export interface ForwardRefComponent<
  IntrinsicElementString,
  TOwnProps = EmptyObject,
  /**
   * Extends original type to ensure built in React types play nice
   * with polymorphic components still e.g. `React.ElementRef` etc.
   */
> extends ForwardRefExoticComponent<IntrinsicElementString, TOwnProps> {
  /**
   * When `as` prop is passed, use this overload.
   * Merges original own props (without DOM props) and the inferred props
   * from `as` element with the own props taking precendence.
   *
   * We explicitly avoid `React.ElementType` and manually narrow the prop types
   * so that events are typed when using JSX.IntrinsicElements.
   */
  <As = IntrinsicElementString>(
    props: As extends ''
      ? {as: keyof JSX.IntrinsicElements}
      : As extends React.ComponentType<infer P>
        ? SimpleMerge<P, TOwnProps & {as: As}>
        : As extends keyof JSX.IntrinsicElements
          ? SimpleMerge<JSX.IntrinsicElements[As], TOwnProps & {as: As}>
          : never,
  ): React.ReactElement | null
}

// Based on https://github.com/udecode/plate/blob/main/packages/core/src/utils/react/withProps.tsx

export const withProps: <T extends object, U = T>(
  Component: React.ComponentType<T>,
  props: Partial<T> | ((passedProps: U & T) => Partial<T>),
) => React.FunctionComponent<T & U> = (Component, props) => (_props) => {
  const staticProps = typeof props === 'function' ? props(_props) : props

  return (
    <Component
      {...staticProps}
      {..._props}
      {...('className' in _props &&
      typeof _props.className === 'string' &&
      'className' in staticProps &&
      typeof staticProps.className === 'string'
        ? {className: `${staticProps.className} ${_props.className}`}
        : undefined)}
    />
  )
}

// Based on https://github.com/fernandopasik/react-children-utilities/blob/main/src/lib/onlyText.ts

export function hasChildren(
  element: React.ReactNode,
): element is React.ReactElement<{
  children: React.ReactNode
}> {
  return React.isValidElement(element) && !!element.props.children
}

export function childToString(child: React.ReactNode) {
  if (child == null || typeof child === 'boolean') {
    return ''
  }

  if (JSON.stringify(child) === '{}') {
    return ''
  }

  return (child as number | string).toString()
}

export function getStringFromChildren(
  children: React.ReactNode,
  joiner = '',
): string {
  return flattenChildren(children).reduce((text: string, child) => {
    if (React.isValidElement(child)) {
      return text.concat(
        hasChildren(child) ? getStringFromChildren(child.props.children) : '',
        joiner,
      )
    }

    return text.concat(childToString(child), joiner)
  }, '')
}
