import {mapToObj} from '@cheddarup/util'
import React, {
  Dispatch,
  Reducer,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useReducer,
} from 'react'

import {newGenericForwardRef} from '../hoc-type-utils'
import {useLiveRef, useUpdateEffect} from '../hooks'

export type CollectionItemId = string | number

export interface CollectionItem {
  id: CollectionItemId
}

export interface CollectionStateDefault {
  selectedItemIdsMap: Record<CollectionItemId, boolean>
  focusedItemId: CollectionItemId | null
}

export interface CollectionAction {
  type: string
  [key: string]: any
}

export type CollectionActionDefault =
  | {
      type: 'SET_ITEM_SELECTED'
      key: CollectionItemId
      value: boolean
    }
  | {
      type: 'SET_ITEMS_SELECTED'
      keys: CollectionItemId[]
      value: boolean
    }
  | {
      type: 'SET_ALL_ITEMS_SELECTED'
      value: boolean
    }
  | {
      type: 'SET_ITEM_FOCUSED'
      key: CollectionItemId | null
    }
  | {
      type: 'RESET'
    }

interface CollectionStateContextValue<S extends CollectionStateDefault, A> {
  itemIds: CollectionItemId[]
  state: S
  dispatch: Dispatch<A>
}

const CollectionStateContext = React.createContext(
  {} as CollectionStateContextValue<any, any>,
)

export interface CollectionStateProviderInstance<
  S extends CollectionStateDefault,
  A,
> {
  state: S
  dispatch: Dispatch<A>
}

export interface CollectionStateProviderProps<
  S extends CollectionStateDefault = CollectionStateDefault,
  A = CollectionActionDefault,
> {
  initialState: S
  reducer?: Reducer<S, A>
  items: CollectionItem[]
  onStateChange?: (newState: S) => void
  children:
    | React.ReactNode
    | ((list: CollectionStateProviderInstance<S, A>) => React.ReactNode)
}

export const CollectionStateProvider = newGenericForwardRef(
  <S extends CollectionStateDefault, A extends CollectionAction>({
    initialState,
    reducer: reducerProp,
    items,
    onStateChange,
    children,
    ref: forwardedRef,
  }: CollectionStateProviderProps<S, A> & {
    ref?: React.Ref<CollectionStateProviderInstance<S, A>>
  }) => {
    const onStateChangeRef = useLiveRef(onStateChange)

    const itemIds = useMemo(() => items.map((i) => i.id), [items])

    const baseReducer: Reducer<
      CollectionStateDefault,
      CollectionActionDefault | A
    > = useCallback(
      (state, action) => {
        switch (action.type) {
          case 'SET_ITEM_SELECTED':
            return {
              ...state,
              selectedItemIdsMap: {
                ...state.selectedItemIdsMap,
                [action.key]: action.value,
              },
            }
          case 'SET_ITEMS_SELECTED':
            return {
              ...state,
              selectedItemIdsMap: {
                ...state.selectedItemIdsMap,
                ...mapToObj(action.keys, (key) => [String(key), action.value]),
              },
            }
          case 'SET_ALL_ITEMS_SELECTED':
            return {
              ...state,
              selectedItemIdsMap: action.value
                ? mapToObj(itemIds, (itemId) => [itemId, true])
                : {},
              focusedItemId: null,
            }
          case 'SET_ITEM_FOCUSED':
            return {...state, focusedItemId: action.key}
          case 'RESET':
            return {selectedItemIdsMap: {}, focusedItemId: null}
          default:
            throw new Error(`action "${action}" is not supported`)
        }
      },
      [itemIds],
    )

    const reducer: Reducer<S, A> = useCallback(
      (state, action) =>
        reducerProp?.(state, action) ?? (baseReducer(state, action) as any),
      [baseReducer, reducerProp],
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    useUpdateEffect(() => {
      onStateChangeRef.current?.(state)
    }, [state])

    const contextValue: CollectionStateContextValue<S, A> = useMemo(
      () => ({
        state,
        dispatch,
        itemIds,
      }),
      [itemIds, state],
    )

    const collectionState = useMemo(
      (): CollectionStateProviderInstance<S, A> => ({state, dispatch}),
      [state],
    )

    useImperativeHandle(forwardedRef, () => collectionState, [collectionState])

    return (
      <CollectionStateContext.Provider value={contextValue}>
        {typeof children === 'function' ? children(collectionState) : children}
      </CollectionStateContext.Provider>
    )
  },
)

// MARK: – Hooks

export const useCollectionState = <
  S extends CollectionStateDefault = CollectionStateDefault,
  A = CollectionActionDefault,
>() => {
  const context = useContext(
    CollectionStateContext,
  ) as CollectionStateContextValue<S, A>
  if (context === undefined) {
    throw new Error(
      '`useCollectionState` must be used within a `CollectionStateProvider`',
    )
  }
  return context
}

export const useCollectionDispatchHelpers = () => {
  const {itemIds, state, dispatch} = useCollectionState()
  const itemIdsRef = useLiveRef(itemIds)
  const stateRef = useLiveRef(state)
  const dispatchRef = useLiveRef(dispatch)

  const setItemFocused = useCallback((key: CollectionItemId | null) => {
    dispatchRef.current({type: 'SET_ITEM_FOCUSED', key})
  }, [])
  const setItemSelected = useCallback(
    (key: CollectionItemId, value: boolean) =>
      dispatchRef.current({type: 'SET_ITEM_SELECTED', key, value}),
    [],
  )
  const setRangeToItemSelected = useCallback(
    (destId: CollectionItemId, value: boolean) => {
      const selectedItemIndexes = itemIdsRef.current
        .map((iId, idx) =>
          stateRef.current.selectedItemIdsMap[iId] ? idx : -1,
        )
        .filter((idx) => idx !== -1)
      const destinationIndex = itemIdsRef.current.indexOf(destId)
      const closestIndex =
        selectedItemIndexes.sort(
          (a, b) =>
            Math.abs(a - destinationIndex) - Math.abs(b - destinationIndex),
        )[0] ?? destinationIndex
      const selectableRange = [
        Math.min(destinationIndex, closestIndex),
        Math.max(closestIndex, destinationIndex),
      ] as const
      const selectableItemIds = itemIdsRef.current.slice(
        selectableRange[0],
        selectableRange[1] + 1,
      )
      if (selectableItemIds.length === 0) {
        return
      }
      dispatchRef.current({
        type: 'SET_ITEMS_SELECTED',
        keys: selectableItemIds,
        value,
      })
    },
    [],
  )

  const helpers = useMemo(
    () => ({
      setItemFocused,
      setItemSelected,
      setRangeToItemSelected,
    }),
    [setItemFocused, setItemSelected, setRangeToItemSelected],
  )

  return helpers
}
