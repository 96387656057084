import * as WebUI from '@cheddarup/web-ui'

import {Link} from 'src/components/Link'
import type {AboutUsFormFormik} from './AddAboutUsPage'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

interface AbousFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  formik: AboutUsFormFormik
}

const AboutUsForm: React.FC<AbousFormProps> = ({
  formik,
  className,
  ...restProps
}) => {
  const [managerRoleId] = useManagerRoleId()

  return (
    <form
      className={WebUI.cn('flex grow flex-col', className)}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      noValidate
      {...restProps}
    >
      <WebUI.VStack className="grow gap-5">
        <WebUI.FormField
          label="Section Header"
          className="max-w-96"
          error={formik.errors.headline}
          required
        >
          <WebUI.Input
            name="headline"
            value={formik.values.headline}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField className="grow" error={formik.errors.description}>
          <WebUI.RichTextEditor
            key={formik.initialValues.description}
            className="h-full min-h-60 text-ds-base [&_h2]:text-ds-md"
            name="description"
            placeholder="Add a description"
            initialMarkdownValue={formik.values.description}
            onMarkdownValueChange={(newDescription) =>
              formik.setFieldValue('description', newDescription)
            }
          >
            <WebUI.RichTextEditorToolbar rootClassName="-order-1" />
          </WebUI.RichTextEditor>
        </WebUI.FormField>
      </WebUI.VStack>
      {managerRoleId == null && (
        <WebUI.HStack className="gap-1 py-6">
          <WebUI.Switch
            className="font-light text-ds-sm"
            size="compact"
            checked={formik.values.details.includeWebsiteLink}
            onChange={(event) =>
              formik.setFieldValue(
                'details.includeWebsiteLink',
                event.target.checked,
              )
            }
          >
            Include button linking to your main website. This link can be added
            or edited in your
          </WebUI.Switch>
          <span className="text-ds-sm">
            <Link className="text-tint" to="my-account/brand-kit-footer">
              Account Settings
            </Link>
            .
          </span>
        </WebUI.HStack>
      )}
    </form>
  )
}

export default AboutUsForm
