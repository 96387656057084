import * as WebUI from '@cheddarup/web-ui'
import {NumberParam, useQueryParam} from 'use-query-params'
import React from 'react'
import {api} from '@cheddarup/api-client'
import {useFormik, useUpdateEffect} from '@cheddarup/react-util'

import {
  ContactListDropdown,
  ContactListDropdownSelection,
} from './ContactListDropdown'
import type {RecipientsModalRecipient} from '../RecipientsModal'
import {CollectionCombobox} from '../../CollectionCombobox'
import {useManagerRoleId} from '../../ManageRoleProvider'

interface ContactListPanelValues {
  paid: boolean
  notPaid: boolean
  included: unknown[]
  contactsSelection: ContactListDropdownSelection
}

export interface ContactListPanelProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  onAddRecipients: (recipients: RecipientsModalRecipient[]) => void
}

export const ContactListPanel = ({onAddRecipients}: ContactListPanelProps) => {
  const [collectionId] = useQueryParam('collectionId', NumberParam)
  const [managerRoleId] = useManagerRoleId()
  const contactListsQuery = api.contacts.contactListList.useQuery()
  const collectionsQuery = api.tabs.list.useQuery({
    queryParams: {
      filter: 'contacts',
    },
  })

  const formik = useFormik<ContactListPanelValues>({
    initialValues: {
      paid: false,
      notPaid: false,
      included: [],
      contactsSelection: collectionId
        ? {
            type: 'collectionList',
            ids: [collectionId],
          }
        : {
            type: 'contactList',
            ids: [],
          },
    },
    validate: (values) => {
      if (
        hasInvitees &&
        hasVisitors &&
        values.notPaid &&
        values.included.length === 0 &&
        values.contactsSelection.type === 'collectionList'
      ) {
        return {included: 'Please select a checkbox.'}
      }
      if (
        (hasInvitees || hasVisitors) &&
        !values.paid &&
        !values.notPaid &&
        values.contactsSelection.type === 'collectionList'
      ) {
        return {notPaid: 'Please select a checkbox.'}
      }
      return {}
    },
    onSubmit: async (values) => {
      if (!values.contactsSelection.ids[0]) {
        return
      }

      if (values.contactsSelection.type === 'collectionList') {
        if (!collectionMembersQuery.data) {
          throw new Error('Something went wrong...')
        }

        const recipients: RecipientsModalRecipient[] = []
        collectionMembersQuery.data
          .filter((m) => !m.unsubscribed)
          ?.forEach((m) => {
            const recipient = {
              name: `${m.first_name ?? ''} ${m.last_name ?? ''}`.trim(),
              email: m.email.trim().toLowerCase(),
            }

            if (!values.paid && !values.notPaid) {
              recipients.push(recipient)
            }
            if (values.paid && m.paid) {
              recipients.push(recipient)
            }
            if (values.notPaid && !m.paid) {
              if (values.included.length === 0) {
                recipients.push(recipient)
              }
              if (values.included.includes('invited') && m.invited) {
                recipients.push(recipient)
              }
              if (values.included.includes('visited') && m.visited) {
                recipients.push(recipient)
              }
            }
          })

        onAddRecipients(recipients)
      } else if (values.contactsSelection.type === 'contactList') {
        const res = await Promise.all(
          values.contactsSelection.ids.map((contactId) =>
            api.contacts.list.fetch({
              queryParams: {
                perPage: 99999,
                contact_list_id: contactId,
              },
              headers: {
                'X-Manager-Account-Id': managerRoleId,
              },
            }),
          ),
        )
        onAddRecipients(
          res.flatMap((d) =>
            d.data.map((c) => ({name: c.name, email: c.email})),
          ),
        )
      }
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    formik.resetForm({
      values: {
        ...formik.initialValues,
        contactsSelection: formik.values.contactsSelection,
      },
    })
  }, [formik.resetForm, formik.values.contactsSelection])

  const collectionQuery = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: formik.values.contactsSelection.ids[0]!,
      },
    },
    {
      enabled:
        formik.values.contactsSelection.type === 'collectionList' &&
        !!formik.values.contactsSelection.ids[0],
    },
  )
  const collectionMembersQuery = api.tabs.listMembers.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: formik.values.contactsSelection.ids[0]!,
      },
    },
    {
      enabled:
        formik.values.contactsSelection.type === 'collectionList' &&
        !!formik.values.contactsSelection.ids[0],
    },
  )

  const collection = collectionQuery.data
  const isCollectionFormsOnly =
    !!collection &&
    collection.reportsAvailable.activeItemsCount === 0 &&
    collection.reportsAvailable.activeFormsCount > 0
  const collections = collectionsQuery.data ?? []

  if (collections.length === 0 && contactListsQuery.data?.length === 0) {
    return <div>You haven't created any lists yet.</div>
  }

  const hasInvitees = !!collectionMembersQuery.data?.some((m) => m.invited)
  const hasVisitors = collectionMembersQuery.data?.some(
    (m) => m.visited && !m.paid,
  )

  return (
    <WebUI.Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <WebUI.HStack className="items-center gap-4">
        <WebUI.FormField
          className="max-w-[320px] flex-[1]"
          label="Select a collection list"
        >
          <CollectionCombobox
            popoverClassName="max-w-[480px]"
            inputSize="default"
            placeholder="Select One"
            collections={collectionsQuery.data ?? []}
            selectedCollectionId={
              formik.values.contactsSelection.type === 'collectionList'
                ? formik.values.contactsSelection.ids[0] ?? null
                : null
            }
            onSelectedCollectionIdChange={(newCollectionId) => {
              formik.setFieldValue('contactsSelection', {
                type: 'collectionList',
                ids: [newCollectionId],
              })
            }}
          />
        </WebUI.FormField>
        {contactListsQuery.data && contactListsQuery.data.length > 0 && (
          <>
            <WebUI.Text className="mt-7 hidden text-ds-sm sm:block">
              Or
            </WebUI.Text>
            <WebUI.FormField className="flex-[1]" label="Select from my lists">
              <ContactListDropdown
                collections={[]}
                contactLists={contactListsQuery.data ?? []}
                selection={formik.values.contactsSelection}
                onSelectionChange={(newSelection) =>
                  formik.setFieldValue('contactsSelection', newSelection)
                }
              />
            </WebUI.FormField>
          </>
        )}
      </WebUI.HStack>

      {formik.values.contactsSelection.type === 'collectionList' &&
        formik.values.contactsSelection.ids[0] &&
        (hasInvitees || hasVisitors) && (
          <WebUI.VStack className="gap-4">
            <WebUI.Text>Filter contacts</WebUI.Text>

            <WebUI.VStack className="gap-1">
              <WebUI.FormField label="Include contacts who have:">
                <WebUI.Checkbox
                  name="paid"
                  size="compact"
                  checked={formik.values.paid}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {isCollectionFormsOnly ? 'Responded' : 'Paid'}
                </WebUI.Checkbox>
              </WebUI.FormField>
              <WebUI.FormField error={formik.errors.notPaid}>
                <WebUI.Checkbox
                  name="notPaid"
                  size="compact"
                  checked={formik.values.notPaid}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {isCollectionFormsOnly ? 'Not Responded' : 'Not Paid'}
                </WebUI.Checkbox>
              </WebUI.FormField>
              {hasInvitees && hasVisitors && formik.values.notPaid && (
                <WebUI.VStack className="ml-7 gap-4">
                  <WebUI.HStack className="items-center gap-2">
                    <WebUI.PhosphorIcon
                      icon="arrow-bend-down-right"
                      color="#2c7b91"
                    />{' '}
                    <span className="text-ds-sm">
                      Who do you want to include? *
                    </span>
                  </WebUI.HStack>
                  <WebUI.VStack className="ml-7 gap-2">
                    <WebUI.Checkbox
                      name="included"
                      size="compact"
                      value="visited"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      Visited
                    </WebUI.Checkbox>
                    <WebUI.FormField error={formik.errors.included}>
                      <WebUI.Checkbox
                        name="included"
                        size="compact"
                        value="invited"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Were invited
                      </WebUI.Checkbox>
                    </WebUI.FormField>
                  </WebUI.VStack>
                </WebUI.VStack>
              )}
            </WebUI.VStack>
          </WebUI.VStack>
        )}

      {collection && (
        <ContactListFilterText
          collection={collection}
          hasInvites={hasInvitees}
          filterValues={formik.values}
        />
      )}

      <WebUI.Button
        className="self-start"
        type="submit"
        loading={formik.isSubmitting}
      >
        Add
      </WebUI.Button>
    </WebUI.Form>
  )
}

interface ContactListFilterTextProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.TabDetailed
  hasInvites: boolean
  filterValues: ContactListPanelValues
}

const ContactListFilterText = ({
  collection,
  hasInvites,
  filterValues,
  className,
  ...restProps
}: ContactListFilterTextProps) => {
  const action =
    collection.reportsAvailable.activeItemsCount === 0 &&
    collection.reportsAvailable.activeFormsCount > 0
      ? 'responded'
      : 'paid'

  const invited =
    hasInvites && collection.payer_identify
      ? filterValues.included.includes('invited')
      : hasInvites
  const visited =
    hasInvites && collection.payer_identify
      ? filterValues.included.includes('visited')
      : collection.payer_identify

  if (!filterValues.paid && !filterValues.notPaid) {
    return null
  }

  return (
    <WebUI.VStack
      className={WebUI.cn('text-ds-sm italic', className)}
      {...restProps}
    >
      <span>
        {filterValues.paid && (
          <>
            Your message will be sent to contacts who{' '}
            <span className="font-bold">have {action}</span>.
          </>
        )}
        {filterValues.notPaid && !filterValues.paid && (
          <>
            Your message will be sent to contacts who
            <span className="font-bold">
              {invited ? ' were invited' : ''}
              {visited ? ` ${invited ? 'and ' : ''}visited` : ''}
            </span>
            {invited || visited ? ' but ' : ''}
            <span className="font-bold"> haven’t {action}</span>.
          </>
        )}
      </span>
      {filterValues.paid && filterValues.notPaid && (
        <span>
          It will also be sent to contacts who{' '}
          {visited && <span className="font-bold">visited</span>}
          {visited && invited && ' and '}
          {invited && (
            <>
              were <span className="font-bold">invited</span>{' '}
            </>
          )}{' '}
          but <span className="font-bold">haven’t {action}</span>.
        </span>
      )}
    </WebUI.VStack>
  )
}
