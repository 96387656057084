import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useState} from 'react'
import {
  api,
  useDeleteManagerFromTabMutation,
  useRemindManagerMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {SharpAvatar} from 'src/components/SharpAvatar'

import CollectionsLayout from '../../components/CollectionsLayout'
import {ManagerFormModal} from './ManagerFormModal'
import {ManagersEmptyStateView} from './ManagersEmptyStateView'

const ManagersPage = () => (
  <CollectionsLayout className="max-h-full sm:[&_.CollectionsLayout-contentContainer]:p-0">
    <ManagersTableView className="min-h-0 w-full max-w-[1200px] grow" />
  </CollectionsLayout>
)

// MARK: – ManagersTableView

const ManagersTableView = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerToEdit, setManagerToEdit] = useState<Api.Manager | null>(null)
  const managersQuery = api.managers.list.useQuery(undefined, {
    select: (managers) => Util.sort(managers).desc((m) => m.invited_at),
  })
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const managersData = managersQuery.data ?? []

  const remindManagerMutation = useRemindManagerMutation()
  const deleteManagerFromCollectionMutation = useDeleteManagerFromTabMutation()
  const growlActions = WebUI.useGrowlActions()

  const remindManagerMutateAsync = remindManagerMutation.mutateAsync
  const deleteManagerFromCollectionMutate =
    deleteManagerFromCollectionMutation.mutate

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.Manager>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((m) => m.name, {
        id: 'name',
        size: 240,
        header: 'Name',
        cell: ({cell, row: {original}}) => (
          <WebUI.HStack className="items-center gap-4">
            <SharpAvatar
              name={cell.getValue()}
              image={original.granted_user?.profile_pic}
              size={34}
            />

            <WebUI.Text className="w-[180px] font-medium">
              {cell.getValue()}
            </WebUI.Text>
            <WebUI.Text className="font-light">
              {original.granted_user?.email || original.invited_email}
            </WebUI.Text>
          </WebUI.HStack>
        ),
      }),
      columnHelper.accessor((m) => (m.accepted_at ? 'Active' : 'Pending'), {
        id: 'status',
        size: 60,
        header: 'Status',
      }),
      columnHelper.accessor((m) => Util.capitalize(m.permissions.scope), {
        id: 'collectionAccess',
        size: 80,
        header: 'Collection Access',
      }),
      columnHelper.accessor((m) => Util.capitalize(m.permissions.role), {
        id: 'role',
        size: 60,
        header: 'Role',
      }),
      columnHelper.display({
        id: 'actions',
        size: 40,
        cell: ({row: {original: manager}}) => (
          <ManagerActionDropdown
            isActive={!!manager.accepted_at}
            onInvite={async () => {
              await remindManagerMutateAsync({
                pathParams: {
                  managerId: manager.id,
                },
              })
              growlActions.show('success', {
                title: 'Success',
                body: `Invitation re-sent to: ${manager.invited_email}`,
              })
            }}
            onEdit={() => setManagerToEdit(manager)}
            onDelete={() => {
              deleteManagerFromCollectionMutate({
                pathParams: {
                  managerId: manager.id,
                },
              })
            }}
          />
        ),
      }),
    ],
    [
      deleteManagerFromCollectionMutate,
      growlActions,
      remindManagerMutateAsync,
      columnHelper,
    ],
  )

  return (
    <WebUI.VStack
      className={WebUI.cn('rounded-none border-t-0 border-b-0', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.HStack className="h-[84px] flex-0 items-center justify-between px-2 sm:px-6">
        <WebUI.Heading className="leading-compact" as="h2">
          Managers
        </WebUI.Heading>

        {isSubscribedToTeamQuery.data === true && (
          <ManagerFormModal
            visible={!!managerToEdit}
            manager={managerToEdit}
            disclosure={
              <WebUI.DialogDisclosure className="self-center" variant="primary">
                Add Manager
              </WebUI.DialogDisclosure>
            }
            onDidHide={() => setManagerToEdit(null)}
          />
        )}
      </WebUI.HStack>
      {managersData.length === 0 &&
      (managersQuery.isSuccess || managersQuery.isError) ? (
        <ManagersEmptyStateView />
      ) : (
        <WebUI.TableView
          className={
            'flex-[0_1_auto] [&_.TableView-headerGroup]:px-6 [&_.TableViewRow:hover_.ManagerActionDropdown]:visible [&_.TableViewRow:hover_.ManagerActionDropdown]:opacity-100 [&_.TableViewRow]:px-6 [&_.TableViewRow]:py-2 [&_.TableViewRow_.ManagerActionDropdown]:invisible [&_.TableViewRow_.ManagerActionDropdown]:opacity-0'
          }
          sortable
          sortByTogglesVisible
          loading={managersQuery.isPending}
          data={managersData}
          columns={columns}
        />
      )}
    </WebUI.VStack>
  )
}

// MARK: - ManagerActionDropdown

interface ManagerActionDropdownProps
  extends React.ComponentPropsWithoutRef<'button'> {
  onInvite: () => void
  onEdit: () => void
  onDelete: () => void
  isActive: boolean
}

const ManagerActionDropdown: React.FC<ManagerActionDropdownProps> = ({
  className,
  onInvite,
  onEdit,
  onDelete,
  isActive,
  ...restProps
}) => (
  <WebUI.Menu>
    <WebUI.MenuButton
      className={WebUI.cn('ManagerActionDropdown text-ds-lg', className)}
      as={WebUI.IconButton}
      size="default_alt"
      variant="outlined"
      {...restProps}
    >
      <WebUI.PhosphorIcon icon="dots-three-outline-fill" />
    </WebUI.MenuButton>

    <WebUI.MenuList>
      {!isActive && (
        <WebUI.MenuItem
          iconBefore={<WebUI.PhosphorIcon icon="envelope-simple" />}
          onClick={() => onInvite()}
        >
          Resend Invitation
        </WebUI.MenuItem>
      )}
      <WebUI.MenuItem
        iconBefore={<WebUI.PhosphorIcon icon="pencil" />}
        onClick={() => onEdit()}
      >
        Edit Access
      </WebUI.MenuItem>
      <WebUI.MenuItem
        iconBefore={<WebUI.PhosphorIcon icon="trash" />}
        onClick={() => onDelete()}
      >
        Delete
      </WebUI.MenuItem>
    </WebUI.MenuList>
  </WebUI.Menu>
)

export default ManagersPage
