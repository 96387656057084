import {
  LinkProps as LinkPrimitiveProps,
  NavLink as NavLinkPrimitive,
  NavLinkProps as NavLinkPrimitiveProps,
  useHref,
  useLinkClickHandler,
  useLocation,
} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ForwardRefComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

export const link = cva('', {
  variants: {
    variant: {
      default: 'text-inherit hover:text-linkDefaultHoverText',
      primary: 'text-linkPrimaryText hover:text-linkPrimaryHoverText',
      headless: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export interface LinkProps
  extends WebUI.VariantsProps<typeof link>,
    Omit<LinkPrimitiveProps, 'relative'>,
    UseLinkOptions,
    WebUI.AnchorProps {}

export const Link = React.forwardRef(
  (
    {
      variant = 'default',
      to,
      reloadDocument,
      preventScrollReset,
      relative,
      target,
      replace,
      state,
      className,
      onClick: onClickProp,
      preserveSearch,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {onClick, ...linkProps} = useLinkProps({
      preserveSearch,
      relative,
      to,
      replace,
      state,
      target,
      preventScrollReset,
    })

    return (
      <WebUI.Anchor
        ref={forwardedRef}
        className={WebUI.cn('Link', link({variant}), className)}
        onClick={(event) => {
          onClickProp?.(event)
          if (reloadDocument !== true && !event.defaultPrevented) {
            onClick(event)
          }
        }}
        {...linkProps}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'a', LinkProps>

// MARK: – AbsoluteLink

export const AbsoluteLink = React.forwardRef(
  ({to, className, ...restProps}, forwardedRef) => {
    const location = useLocation()

    const newTo = typeof to === 'string' ? to : to?.pathname
    let newPathname = `${location.pathname}/${newTo}`

    if (newTo && location.pathname.endsWith(newTo)) {
      newPathname = location.pathname
    }

    return (
      <Link
        ref={forwardedRef}
        className={WebUI.cn('AbsoluteLink', className)}
        to={
          typeof to === 'string' ? newPathname : {...to, pathname: newPathname}
        }
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'a', LinkProps>

// MARK: – NavLink

export interface NavLinkProps
  extends Omit<
      NavLinkPrimitiveProps,
      'className' | 'style' | 'activeClassName' | 'activeStyle' | 'children'
    >,
    WebUI.AnchorProps,
    Pick<LinkProps, 'variant'>,
    React.ComponentPropsWithoutRef<'a'> {}

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({className, ...restProps}, forwardedRef) => (
    <WebUI.Anchor
      ref={forwardedRef}
      className={WebUI.cn(
        'NavLink',
        'text-inherit hover:text-inherit aria-current-page:text-orange-50',
        className,
      )}
      as={NavLinkPrimitive}
      {...restProps}
    />
  ),
)

// MARK: – Hooks

// Default relative="path" is contextual
// See https://reactrouter.com/en/main/components/link#relative
export type LinkRelativeRoutingType =
  | LinkPrimitiveProps['relative']
  | 'nonContextualPath'

export interface UseLinkOptions
  extends Pick<
    LinkProps,
    'to' | 'replace' | 'state' | 'target' | 'preventScrollReset'
  > {
  preserveSearch?: boolean
  relative?: LinkRelativeRoutingType
}

export function useLinkProps({
  preserveSearch,
  relative,
  to,
  replace,
  state,
  target,
  preventScrollReset,
}: UseLinkOptions) {
  const location = useLocation()

  let _to = to
  if (preserveSearch) {
    const currentSearchParams = new URLSearchParams(location.search)
    let newSearchParams: URLSearchParams

    if (typeof to === 'string') {
      const [path, search] = to.split('?')
      newSearchParams = new URLSearchParams(search)

      // Merge params, new params take precedence
      for (const [key, value] of currentSearchParams) {
        if (!newSearchParams.has(key)) {
          newSearchParams.append(key, value)
        }
      }

      _to = `${path}?${newSearchParams.toString()}`
    } else if (typeof to === 'object') {
      newSearchParams = new URLSearchParams(to.search)

      for (const [key, value] of currentSearchParams) {
        if (!newSearchParams.has(key)) {
          newSearchParams.append(key, value)
        }
      }

      _to = {
        ...to,
        search: newSearchParams.toString(),
      }
    }
  }

  if (relative === 'nonContextualPath') {
    const toPathname = typeof _to === 'string' ? _to : _to.pathname ?? ''
    const goBacksCount = toPathname.split('..').length - 1

    const toPathnamePrefix = location.pathname
      .split('/')
      .slice(0, goBacksCount === 0 ? undefined : -goBacksCount)
      .join('/')

    const toPathnameWithoutGoBacks = toPathname
      .split('/')
      .filter((segment) => segment !== '..' && segment !== '.')
      .join('/')

    if (typeof _to === 'string') {
      _to = `${toPathnamePrefix}/${toPathnameWithoutGoBacks}`
    } else if (typeof _to === 'object') {
      _to = {
        ..._to,
        pathname: `${toPathnamePrefix}/${toPathnameWithoutGoBacks}`,
      }
    }
  }

  const stdRelative = relative === 'nonContextualPath' ? 'route' : relative

  return {
    href: useHref(_to, {relative: stdRelative}),
    onClick: useLinkClickHandler(_to, {
      replace,
      state,
      target,
      relative: stdRelative,
      preventScrollReset,
    }),
    target,
  }
}
