import * as Yup from 'yup'
import {
  BinaryFilter,
  BinaryOperator,
  Filter as CubeFilter,
  Query as CubeQuery,
  UnaryFilter,
  UnaryOperator,
} from '@cubejs-client/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik} from '@cheddarup/react-util'
import React from 'react'
import {
  api,
  useCreateCubeExportMutation,
  useCreateCubeReportMutation,
  useDeleteCubeReportMutation,
  useUpdateCubeReportMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {CubeTable, CubeTableProps} from 'src/components/CubeTable'
import {useCubeQuery} from 'src/hooks/cube'
import {
  Dimension,
  dimensionToSchema,
  formatCubeValue,
} from 'src/helpers/cube-schema'

// MARK: – CubeReportSummary

export interface CubeReportSummaryProps
  extends React.ComponentPropsWithoutRef<'div'> {
  cubeReport: Api.CubeReport
  onViewAll: () => void
}

export const CubeReportSummary = ({
  cubeReport,
  onViewAll,
  className,
  ...restProps
}: CubeReportSummaryProps) => {
  const visibleFilters = Util.sort(
    cubeReport.export_query.filters
      ?.filter(
        (f): f is BinaryFilter | UnaryFilter =>
          'dimension' in f || 'member' in f,
      )
      .filter(
        (f): f is BinaryFilter => !['set', 'notSet'].includes(f.operator),
      ) ?? [],
  ).asc((f) => {
    const filterDim = f.dimension ?? f.member
    const isDimIdValue = filterDim?.split('.')[1] === 'id'
    return isDimIdValue ? 1 : 0
  })

  const countMeasure = cubeReport.count_query.measures?.find(
    (cm) => !cubeReport.export_query.measures?.includes(cm),
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.HStack className="justify-between gap-3">
        <WebUI.Button variant="link" onClick={onViewAll}>
          {'< View All'}
        </WebUI.Button>
        {countMeasure && (
          <CreateCubeReportExportButton
            query={cubeReport.export_query}
            countMeasure={countMeasure}
          />
        )}
      </WebUI.HStack>

      <WebUI.VStack className="gap-7">
        <WebUI.Heading as="h3">
          Saved Report: {cubeReport.detail.name}
        </WebUI.Heading>

        <WebUI.VStack className="gap-1 text-ds-sm">
          {!visibleFilters.some((f) => f.dimension === 'Collections.id') && (
            <WebUI.HStack className="gap-4">
              <WebUI.Ellipsis className="w-[120px]">
                {dimensionToSchema['Collections.id'].defaultInflection}(s):
              </WebUI.Ellipsis>
              <WebUI.Ellipsis>All Collections</WebUI.Ellipsis>
            </WebUI.HStack>
          )}

          {visibleFilters.map((f, idx) => {
            const filterDim = (f.dimension ?? f.member) as Dimension | undefined
            const isDimIdValue = filterDim?.split('.')[1] === 'id'
            return (
              <WebUI.HStack key={idx} className="gap-4">
                <WebUI.Ellipsis className="w-[120px]">
                  {filterDim
                    ? dimensionToSchema[filterDim]?.defaultInflection ??
                      filterDim
                    : filterDim}
                  {isDimIdValue ? '(s)' : ''}:
                </WebUI.Ellipsis>
                <CubeReportDetailViewFilterValue filter={f} />
              </WebUI.HStack>
            )
          })}
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – CubeReportDetailViewFilterValue

interface CubeReportDetailViewFilterValueProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filter: BinaryFilter
}

const CubeReportDetailViewFilterValue = ({
  filter,
  className,
  ...restProps
}: CubeReportDetailViewFilterValueProps) => {
  const dimension = filter.dimension ?? filter.member
  const isDimCollectionsId = dimension === 'Collections.id'

  const collectionsQuery = useCubeQuery(
    {
      dimensions: ['Collections.name'],
      filters: [
        {
          dimension: 'Collections.id',
          operator: 'equals',
          values: filter.values,
        },
      ],
    },
    {
      skip: !isDimCollectionsId,
    },
  )

  if (isDimCollectionsId) {
    const collectionNames =
      collectionsQuery.resultSet
        ?.tablePivot()
        .map((c) => c['Collections.name']) ?? []

    return collectionNames.length > 0 ? (
      <WebUI.VStack
        className={WebUI.cn(
          'max-h-[240px] shrink-0 basis-auto gap-0_5 overflow-y-auto pr-5 [&_>_.Ellipsis]:grow',
          className,
        )}
        {...restProps}
      >
        {collectionNames.map((collectionName, idx) => (
          <WebUI.Ellipsis key={idx}>{collectionName}</WebUI.Ellipsis>
        ))}
      </WebUI.VStack>
    ) : null
  }

  return (
    <WebUI.Ellipsis className={className} {...restProps}>
      {formatCubeFilterValue(filter)}
    </WebUI.Ellipsis>
  )
}

// MARK: – CubeReportTable

interface CubeReportTableProps
  extends Omit<CubeTableProps, 'query' | 'countMeasure' | 'columns'> {
  cubeReport: Api.CubeReport
}

export const CubeReportTable = ({
  cubeReport,
  ...restProps
}: CubeReportTableProps) => (
  <CubeTable
    query={cubeReport.export_query}
    countMeasure={cubeReport.count_query.measures?.[0]}
    columns={reportTypeToCubeTableColumns[cubeReport.detail.reportType]}
    getRowProps={
      cubeReport.detail.reportType === 'payments'
        ? (row) =>
            ({
              as: LinkButton,
              preserveSearch: true,
              className: 'px-0 h-auto [font-size:inherit]',
              to: `collection/${row.original['Collections.id']}/payment/${row.original['Payments.id']}`,
            }) as any
        : undefined
    }
    {...restProps}
  />
)

// MARK: – CubeReportFormPrompt

interface CubeReportFormPromptProps extends WebUI.PromptProps {
  report?: Api.CubeReport
  reportType?: Api.CubeReportType
  query?: CubeQuery
  countMeasure?: string
}

export const CubeReportFormPrompt = React.forwardRef<
  WebUI.DialogInstance,
  CubeReportFormPromptProps
>(
  (
    {
      report,
      reportType,
      query,
      countMeasure,
      disclosure = (
        <WebUI.DialogDisclosure variant="secondary">
          Save Report
        </WebUI.DialogDisclosure>
      ),
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Prompt
      ref={forwardedRef}
      aria-label="Save report form"
      disclosure={disclosure}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="gap-4">
          <WebUI.PromptHeader heading="Save Report" />

          <CubeReportForm
            report={report}
            reportType={reportType}
            query={query}
            countMeasure={countMeasure}
            onDidSave={() => dialog.hide()}
            onCancel={() => dialog.hide()}
          />
        </WebUI.VStack>
      )}
    </WebUI.Prompt>
  ),
)

// MARK: – CreateCubeReportExportButton

interface CreateCubeReportExportButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  query: CubeQuery
  countMeasure: string
}

export const CreateCubeReportExportButton = ({
  query,
  countMeasure,
  className,
  ...restProps
}: CreateCubeReportExportButtonProps) => {
  const countQuery = {
    measures: [...(query.measures ?? []), countMeasure],
    filters: query.filters,
  }

  const {data: existingCubeExport} = api.cube.exportList.useQuery(undefined, {
    select: (cubeExports) =>
      cubeExports.find(
        (ce) =>
          Util.deepEqual(ce.count_query, countQuery) &&
          Util.deepEqual(ce.export_query, query),
      ),
  })
  const createCubeExportMutation = useCreateCubeExportMutation()
  const growlActions = WebUI.useGrowlActions()

  const button = (
    <WebUI.IconButton
      className={WebUI.cn('text-ds-xl', className)}
      size="default_alt"
      variant="secondary"
      loading={createCubeExportMutation.isPending}
      disabled={existingCubeExport && existingCubeExport.status !== 'complete'}
      onClick={async () => {
        try {
          await createCubeExportMutation.mutateAsync({
            body: {
              export_query: query,
              count_query: countQuery,
            },
          })
          growlActions.show('success', {
            title: 'Success',
            body: 'The report has been sent to your inbox. It can take up to 30 minutes.',
          })
        } catch (err: any) {
          growlActions.show('error', {title: 'Error', body: err.message})
        }
      }}
      {...restProps}
    >
      <WebUI.PhosphorIcon icon="download-simple" />
    </WebUI.IconButton>
  )

  return existingCubeExport ? (
    <WebUI.DeprecatedTooltip label={Util.startCase(existingCubeExport.status)}>
      {button}
    </WebUI.DeprecatedTooltip>
  ) : (
    button
  )
}

// MARK: – CubeReportForm

interface CubeReportFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  report?: Api.CubeReport
  reportType?: Api.CubeReportType
  query?: CubeQuery
  countMeasure?: string
  onDidSave: () => void
  onCancel: () => void
}

const CubeReportForm = ({
  report,
  reportType,
  query,
  countMeasure,
  onDidSave,
  onCancel,
  ...restProps
}: CubeReportFormProps) => {
  const createCubeReportMutation = useCreateCubeReportMutation()
  const updateCubeReportMutation = useUpdateCubeReportMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Required'),
    }),
    initialValues: {
      name: report?.detail.name ?? '',
    },
    onSubmit: async (values) => {
      try {
        if (report) {
          await updateCubeReportMutation.mutateAsync({
            pathParams: {
              reportId: report.id,
            },
            body: {
              ...report,
              name: values.name,
              report_type: report.detail.reportType,
            },
          })
        } else {
          const countQuery = {
            measures: [...(query?.measures ?? []), countMeasure].filter(
              (m) => m != null,
            ),
            filters: query?.filters,
          }
          await createCubeReportMutation.mutateAsync({
            body: {
              name: values.name,
              report_type: reportType,
              export_query: query,
              count_query: countQuery,
            },
          })
        }

        onDidSave()
      } catch (err: any) {
        growlActions.show('error', {title: 'Error', body: err.message})
      }
    },
  })
  return (
    <WebUI.Form onSubmit={formik.handleSubmit} {...restProps}>
      <WebUI.FormField error={formik.errors.name}>
        <WebUI.Input
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.HStack className="gap-3">
        <WebUI.Button type="submit" loading={formik.isSubmitting}>
          Save
        </WebUI.Button>
        <WebUI.Button
          type="button"
          variant="secondary"
          disabled={formik.isSubmitting}
          onClick={() => onCancel()}
        >
          Cancel
        </WebUI.Button>
      </WebUI.HStack>
    </WebUI.Form>
  )
}

// MARK: – CubeReportRow

interface CubeReportRowProps extends React.ComponentPropsWithoutRef<'div'> {
  cubeReport: Api.CubeReport
  onView: () => void
}

export const CubeReportRow = ({
  cubeReport,
  onView,
  className,
  ...restProps
}: CubeReportRowProps) => (
  <WebUI.HStack
    className={WebUI.cn('items-start justify-between gap-3', className)}
    {...restProps}
  >
    <WebUI.VStack className="min-w-0 gap-0_5">
      <WebUI.Ellipsis className="text-ds-xs">
        {Util.capitalize(cubeReport.detail.reportType)}
      </WebUI.Ellipsis>
      <WebUI.Button className="text-ds-sm" variant="link" onClick={onView}>
        {cubeReport.detail.name}
      </WebUI.Button>
    </WebUI.VStack>
    <WebUI.Menu>
      {(menu) => (
        <>
          <WebUI.MenuButton
            className="text-ds-md text-teal-50"
            as={WebUI.IconButton}
            size="default_alt"
          >
            <WebUI.PhosphorIcon icon="dots-three-outline-fill" />
          </WebUI.MenuButton>

          <WebUI.MenuList>
            <WebUI.MenuItem onClick={onView}>View</WebUI.MenuItem>
            <CubeReportFormPrompt
              disclosure={
                <WebUI.MenuItem hideOnClick={false} as={WebUI.DialogDisclosure}>
                  Rename
                </WebUI.MenuItem>
              }
              report={cubeReport}
              onDidHide={() => menu.hide()}
            />
            <CubeReportDeleteAlertConfirmation
              disclosure={
                <WebUI.MenuItem hideOnClick={false} as={WebUI.DialogDisclosure}>
                  Delete
                </WebUI.MenuItem>
              }
              cubeReport={cubeReport}
            />
          </WebUI.MenuList>
        </>
      )}
    </WebUI.Menu>
  </WebUI.HStack>
)

// MARK: – CubeReportDeleteAlertConfirmation

interface CubeReportDeleteAlertConfirmationProps extends WebUI.AlertProps {
  cubeReport: Api.CubeReport
}

const CubeReportDeleteAlertConfirmation = ({
  cubeReport,
  ...restProps
}: CubeReportDeleteAlertConfirmationProps) => {
  const deleteCubeReportMutation = useDeleteCubeReportMutation()
  return (
    <WebUI.Alert {...restProps}>
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="Deleting this report will permanently remove it from your account."
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                deleteCubeReportMutation.mutate({
                  pathParams: {
                    reportId: cubeReport.id,
                  },
                })
              }
            >
              Delete
            </WebUI.AlertActionButton>{' '}
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}

// MARK: – Helpers

const formatCubeFilterValue = (filter: CubeFilter) => {
  if ('or' in filter && filter.or) {
    throw new Error('`formatCubeFilterValue` doesn`t support `or` operator')
  }
  if ('and' in filter && filter.and) {
    const andFilters = filter.and.filter(
      (f): f is UnaryFilter | BinaryFilter => 'operator' in f,
    )
    const operators = andFilters.map((f) => f.operator)

    if (
      filter.and.length === 2 &&
      andFilters.length === 2 &&
      operators.includes('lte') &&
      operators.includes('gte')
    ) {
      const [dim0, dim1] = andFilters.map((f) => f.dimension)
      const [val0, val1] = andFilters.map((f) => f.values?.[0])

      if (!dim0 || !dim1) {
        throw new Error('`formatCubeFilterValue` is missing dimensions')
      }
      if (!val0 || !val1) {
        throw new Error('`formatCubeFilterValue` is missing values')
      }
      if (dim0 !== dim1) {
        throw new Error(
          '`formatCubeFilterValue` only supports filtering by one dimension in `and`',
        )
      }

      return `Between ${formatCubeValue(
        val0,
        dim0 as any,
      )} and ${formatCubeValue(val1, dim1 as any)}`
    }
    throw new Error(
      '`formatCubeFilterValue` only supports gte, lte subfilters in `and`',
    )
  }

  const typedFilter = filter as BinaryFilter | UnaryFilter

  const operatorFormatted = formatCubeFilterOprator(typedFilter.operator)

  return `${
    operatorFormatted ? `${operatorFormatted} ` : ''
  }${typedFilter.values
    ?.map((v) => formatCubeValue(v, (typedFilter.dimension ?? '') as any))
    .join(', ')}`
}

const formatCubeFilterOprator = (op: BinaryOperator | UnaryOperator) => {
  switch (op) {
    case 'gt':
      return 'is greater than'
    case 'gte':
      return 'is greater than or equal to'
    case 'lt':
      return 'is less than'
    case 'lte':
      return 'is less than or equal to'
    default:
      return ''
  }
}

export const reportTypeToCubeTableColumns = {
  payments: [
    {path: 'Payments.total', maxSize: 160},
    {path: 'Payments.paymentMethod', headerTitle: 'Method', maxSize: 160},
    {path: 'Customers.name', fallbackPath: 'Customers.email'},
    {path: 'Payments.createdAt', headerTitle: 'Date', maxSize: 120},
    {path: 'Payments.status', headerTitle: 'Status', maxSize: 120},
  ],
  payers: [
    {path: 'Customers.name'},
    {path: 'Customers.totalPaid', maxSize: 160},
    {path: 'Customers.paymentCount', maxSize: 160},
    {
      path: 'Customers.firstSeenAt',
      headerTitle: 'First Payment',
      maxSize: 160,
    },
    {path: 'Customers.lastSeenAt', headerTitle: 'Last Payment', maxSize: 160},
  ],
  withdrawals: [
    {path: 'Withdrawals.createdAt', maxSize: 160},
    {path: 'Collections.name'},
    {path: 'Withdrawals.description'},
    {path: 'Withdrawals.amount', headerTitle: 'Amount', maxSize: 120},
    {path: 'WithdrawalAccounts.last4', maxSize: 160},
    {path: 'Withdrawals.status', headerTitle: 'Status', maxSize: 120},
  ],
  visitors: [
    {path: 'Customers.name', headerTitle: 'Visitor Name'},
    {path: 'Customers.totalPaid', maxSize: 160},
    {path: 'Customers.visitCount', maxSize: 120},
    {path: 'Customers.firstSeenAt', headerTitle: 'First Visit', maxSize: 120},
    {path: 'Customers.lastSeenAt', headerTitle: 'Last Visit', maxSize: 120},
  ],
} as const
