import {z} from '@cheddarup/util'

import {timeSlotSchema} from './time-slots'

export const signUpSpotOptionsSchema = z.object({})

export const signUpSpotSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullish(),
  hidden: z.boolean(),
  options: signUpSpotOptionsSchema,
  time_slots: timeSlotSchema.array(),
  position: z.number(),
  active_participant_count: z.number(),
  available_quantity: z.number().nullable(),
})

declare global {
  namespace Api {
    type SignUpSpotOptions = z.infer<typeof signUpSpotOptionsSchema>
    type SignUpSpot = z.infer<typeof signUpSpotSchema>
  }
}
