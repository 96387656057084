import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'

export const managerGrantedUserSchema = z.object({
  profile_pic: s3ImageSchema.optional(),
  email: z.string(),
})

export const managerPermissionRoleSchema = z.enum(['viewer', 'editor', 'admin'])

export const managerPermissionsSchema = z.object({
  role: managerPermissionRoleSchema,
  scope: z.enum(['all', 'specific']),
  collectionIds: z.number().array(),
  address_book_and_message_center: z.boolean(),
  group_page: z.boolean(),
})

export const managerSchema = z.object({
  id: z.number(),
  invited_at: z.string().nullish(),
  name: z.string(),
  invited_email: z.string(),
  accepted_at: z.string().nullish(),
  granted_user: managerGrantedUserSchema.optional(),
  permissions: managerPermissionsSchema,
})

declare global {
  namespace Api {
    type Manager = z.infer<typeof managerSchema>
    type ManagerPermissions = z.infer<typeof managerPermissionsSchema>
    type ManagerPermissionRole = z.infer<typeof managerPermissionRoleSchema>
    type ManagerGrantedUser = z.infer<typeof managerGrantedUserSchema>
  }
}
