import {NumberParam, useQueryParam, withDefault} from 'use-query-params'
import {useParams} from 'react-router-dom'
import React, {useCallback, useContext, useEffect, useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {api} from '@cheddarup/api-client'
import {useFolders} from 'src/hooks/useFolder'
import {useIsAuthed} from 'src/hooks/useAuthToken'

export type ManagerRoleId = number | null | undefined

interface ManagerRoleContextValue {
  managerRoleId: ManagerRoleId
  setManagerRoleId: (managerRoleId: ManagerRoleId) => void
}

const ManagerRoleContext = React.createContext({} as ManagerRoleContextValue)

// MARK: – ManagerRoleProvider

export interface ManagerRoleProviderProps {
  children: React.ReactNode
}

export const ManagerRoleProvider = ({children}: ManagerRoleProviderProps) => {
  const [initialManagerRoleId, setInitialManagerRoleId] =
    useInitialManagerRoleId()
  const [managerRoleId, setManagerRoleId] = useQueryParam(
    'mrId',
    withDefault(NumberParam, initialManagerRoleId, false),
  )
  const isLoggedIn = useIsAuthed()
  const managerRoleIdsQuery = api.auth.session.useQuery(undefined, {
    enabled: isLoggedIn,
    select: (session) => session.manager_roles.map((mr) => mr.id),
  })

  useEffect(
    () => setInitialManagerRoleId(managerRoleId),
    [managerRoleId, setInitialManagerRoleId],
  )

  useUpdateEffect(() => {
    if (
      managerRoleIdsQuery.isSuccess &&
      managerRoleIdsQuery.data.length === 0
    ) {
      // HACK: was preventing navigation sometimes
      setTimeout(() => setManagerRoleId(null), 200)
    }
  }, [
    managerRoleIdsQuery.data?.length,
    managerRoleIdsQuery.isSuccess,
    setManagerRoleId,
  ])

  const contextValue: ManagerRoleContextValue = useMemo(
    () => ({
      managerRoleId,
      setManagerRoleId: (newValue) => {
        if (newValue === undefined) {
          setInitialManagerRoleId(undefined)
        }

        setManagerRoleId(newValue)
      },
    }),
    [managerRoleId, setInitialManagerRoleId, setManagerRoleId],
  )

  return (
    <ManagerRoleContext.Provider value={contextValue}>
      {children}
    </ManagerRoleContext.Provider>
  )
}

// MARK: – Ensurers

export interface EnsureNoManagerProps {
  allowedPermissionRoles?: Api.ManagerPermissionRole[]
  children: React.ReactNode
}

export const EnsureNoManager: React.FC<EnsureNoManagerProps> = ({
  allowedPermissionRoles = [],
  children,
}) => {
  const [managerRole, setManagerRoleId] = useManagerRole()

  const isAllowed = managerRole
    ? allowedPermissionRoles.includes(managerRole.permissions.role)
    : false
  useEffect(() => {
    if (!isAllowed) {
      requestAnimationFrame(() => setManagerRoleId(null))
    }
  }, [setManagerRoleId, isAllowed])

  return <>{children}</>
}

export interface EnsureCollectionBelongsToManagerRoleProps {
  children: React.ReactNode
}

export const EnsureCollectionBelongsToManagerRole: React.FC<
  EnsureCollectionBelongsToManagerRoleProps
> = ({children}) => {
  const [managerRoleId, setManagerRoleId] = useManagerRoleId()
  const urlParams = useParams()

  const collectionId =
    urlParams.collection == null ? undefined : Number(urlParams.collection)

  const collectionQuery = api.tabs.list.useQuery(undefined, {
    enabled: collectionId != null,
    select: (collections) => collections.find((c) => c.id === collectionId),
  })

  const collection = collectionQuery.data
  useEffect(() => {
    if (!collection) {
      return
    }

    if (collection.access.owner && managerRoleId != null) {
      requestAnimationFrame(() => setManagerRoleId(null))
    } else if (
      !collection.access.owner &&
      collection.organizer.id !== managerRoleId
    ) {
      requestAnimationFrame(() => setManagerRoleId(collection.organizer.id))
    }
  }, [collection, managerRoleId, setManagerRoleId])

  return <>{children}</>
}

// MARK: – Helper hooks

export function useManagerRoleId() {
  const contextValue = useContext(ManagerRoleContext)

  return useMemo(
    () => [contextValue.managerRoleId, contextValue.setManagerRoleId] as const,
    [contextValue.managerRoleId, contextValue.setManagerRoleId],
  )
}

export function useManagerRoleQuery(
  queryOptions?: Parameters<typeof api.auth.session.useQuery>[1],
) {
  const [managerRoleId, setManagerRoleId] = useManagerRoleId()
  const isLoggedIn = useIsAuthed()
  const managerRoleQuery = api.auth.session.useQuery(undefined, {
    ...queryOptions,
    enabled: isLoggedIn && managerRoleId != null,
    select: (session) =>
      session.manager_roles.find((mr) => mr.id === managerRoleId),
  })

  return [managerRoleQuery, setManagerRoleId] as const
}

export function useManagerRole() {
  const [managerRoleQuery, setManagerRoleId] = useManagerRoleQuery()
  return [managerRoleQuery.data, setManagerRoleId] as const
}

export function useManagerRoleFolders() {
  const [managerRoleId] = useManagerRoleId()
  return useFolders(managerRoleId)
}

// MARK: – Helpers

type ManagerRoleIdInput = number | null | undefined
type ManagerRoleIdInitialValue = number | 'personal' | null

function convertInputToInitialValue(
  input: ManagerRoleIdInput,
): ManagerRoleIdInitialValue {
  return input === null ? 'personal' : input === undefined ? null : input
}

function convertInnerToInputValue(
  innerValue: ManagerRoleIdInitialValue,
): ManagerRoleIdInput {
  return innerValue === 'personal'
    ? null
    : innerValue == null
      ? undefined
      : innerValue
}

const inititialManagerRoleIdStorageKey = 'initialMrId'

function useInitialManagerRoleId() {
  const [initialManagerRoleId, _setInitialManagerRoleId] =
    WebUI.useLocalStorage<ManagerRoleIdInitialValue>(
      inititialManagerRoleIdStorageKey,
      null,
    )
  const initialManagerRoleIdRef = useLiveRef(initialManagerRoleId)

  const setInitialManagerRoleId: React.Dispatch<
    React.SetStateAction<ManagerRoleIdInput>
  > = useCallback(
    (newValue) => {
      const newManagerRoleId =
        newValue instanceof Function
          ? newValue(
              initialManagerRoleIdRef.current === 'personal'
                ? null
                : initialManagerRoleIdRef.current,
            )
          : newValue

      _setInitialManagerRoleId(convertInputToInitialValue(newManagerRoleId))
    },
    [_setInitialManagerRoleId],
  )

  return [
    convertInnerToInputValue(initialManagerRoleId),
    setInitialManagerRoleId,
  ] as const
}

// MARK: – useUserId

export function useUserId() {
  const [managerRoleId] = useManagerRoleId()
  const currentUserIdQuery = api.auth.session.useQuery(undefined, {
    enabled: managerRoleId == null,
    select: (session) => session.user.id,
  })

  return managerRoleId ?? currentUserIdQuery.data
}

// MARK: – useUserSlug

export function useUserSlug() {
  const [managerRole] = useManagerRole()
  const userSlugQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.slug,
  })

  return managerRole?.slug ?? userSlugQuery.data
}

// MARK: – useUiClientFlags

export function useUiClientFlags() {
  const [managerRoleQuery] = useManagerRoleQuery()
  const sessionQuery = api.auth.session.useQuery(undefined, {
    enabled: !managerRoleQuery.isPending && managerRoleQuery.data == null,
    select: (session) => session.user.profile.uiClientFlags,
  })

  if (!managerRoleQuery.isPending && managerRoleQuery.data == null) {
    return sessionQuery.data
  }

  return managerRoleQuery.data?.profile.uiClientFlags
}
