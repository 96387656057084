import {z} from '@cheddarup/util'

export const tabTaxAppliedToTypeSchema = z.enum(['all', 'some', 'none'])

export const tabTaxSchema = z.object({
  name: z.string(),
  rate: z.number(),
  applied_to: tabTaxAppliedToTypeSchema,
  applicable_items: z.number().array().optional(),
})

declare global {
  namespace Api {
    type TabTax = z.infer<typeof tabTaxSchema>
    type TabTaxAppliedToType = z.infer<typeof tabTaxAppliedToTypeSchema>
  }
}
