import * as WebUI from '@cheddarup/web-ui'
import {useParams} from 'react-router-dom'
import React, {useMemo} from 'react'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {fetchAndSave} from 'src/helpers/api-helpers'

import CollectorPayerDropdown from './components/CollectorPayerDropdown'
import {useCubeQuery} from '../../hooks/cube'
import {Link} from '../../components/Link'

const CollectorPage = () => {
  const {collector: collectorUuid} = useParams()
  const {data: user} = api.clients.userDetail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      userId: collectorUuid!,
    },
  })
  const isPayerVisible = !!user?.customer

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <WebUI.HStack className="items-center gap-3">
          <SharpAvatar
            size={36}
            image={user?.user.profile_pic}
            name={user?.user.full_name}
          />
          <WebUI.VStack>
            <div className="font-semibold text-ds-md">
              {user?.user.full_name}
            </div>
            <div className="font-normal text-ds-sm text-gray400">
              {user?.user.email}
            </div>
          </WebUI.VStack>
        </WebUI.HStack>

        <WebUI.HStack className="gap-3">
          <WebUI.Button
            variant="outlined"
            iconBefore={<WebUI.PhosphorIcon icon="download-simple" />}
            onClick={() => {
              if (user) {
                fetchAndSave({
                  url: 'users/exports/account.xlsx',
                  fileName: 'account.xlsx',
                  config: {headers: {'User-Id': user.user.uuid}},
                })
              }
            }}
          >
            Download Account Excel Report
          </WebUI.Button>
          <CollectorPayerDropdown
            isCollectorVisible
            isCollector
            isPayerVisible={isPayerVisible}
            userId={user?.user.id}
            customerLink={
              isPayerVisible && user?.user.email
                ? `/reporting/payers/${user.user.email}`
                : ''
            }
          />
        </WebUI.HStack>
      </WebUI.HStack>

      {!!user && (
        <WebUI.HStack className="items-start gap-4">
          <WebUI.VStack className="w-[320px] gap-4">
            <CollectorPageOverviewCard user={user} />
            <CollectorPageAccountCard user={user} />
          </WebUI.VStack>
          <CollectorPageCollectionPagesTable className="grow" user={user} />
        </WebUI.HStack>
      )}
    </WebUI.VStack>
  )
}

// MARK: – CollectorPageOverviewCard

interface CollectorPageOverviewCardProps
  extends React.ComponentPropsWithoutRef<'div'> {
  user: Api.ClientUserByEmail
}

const CollectorPageOverviewCard = ({
  user,
  className,
  ...restProps
}: CollectorPageOverviewCardProps) => {
  const {resultSet: distributors} = useCubeQuery({
    measures: ['Payments.totalPaid'],
    filters: [
      {
        member: 'Users.uuid',
        operator: 'equals',
        values: [user.user.uuid],
      },
    ],
  })
  const distributor = distributors?.tablePivot()[0]

  return (
    <WebUI.VStack
      className={WebUI.cn('[&_>_.Stack]:p-6', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="gap-4">
        <WebUI.VStack>
          <div className="font-normal text-ds-xs text-gray400">
            DISPLAY NAME
          </div>
          <div className="font-accent text-ds-sm italic">
            {user.user.full_name}
          </div>
        </WebUI.VStack>
        {!!user.user.profile.organizationIdentifier?.fieldName && (
          <WebUI.VStack>
            <div className="font-normal text-ds-xs text-gray400">
              {user.user.profile.organizationIdentifier?.fieldName.toUpperCase()}{' '}
              NUMBER
            </div>
            <div className="font-accent text-ds-sm italic">
              {user.user.display_name?.split(' ‒ ')[1] || '–'}
            </div>
          </WebUI.VStack>
        )}
      </WebUI.VStack>
      <WebUI.Separator variant="primary" />
      <WebUI.HStack className="justify-between gap-2">
        <WebUI.VStack>
          <div className="font-semibold text-ds-xs text-gray400">
            TOTAL COLLECTED
          </div>
          <div className="font-normal text-ds-xl">
            {Util.formatAmount(
              (distributor?.['Payments.totalPaid'] ?? 0) as number,
            )}
          </div>
        </WebUI.VStack>
        <WebUI.VStack>
          <div className="font-semibold text-ds-xs text-gray400">
            BALANCE AVAIL
          </div>
          <div className="font-normal text-ds-xl">
            {Util.formatAmount(
              user.user.withdrawal_data.total_available_balance,
            )}
          </div>
        </WebUI.VStack>
      </WebUI.HStack>
    </WebUI.VStack>
  )
}

// MARK: – CollectorPageAccountCard

interface CollectorPageAccountCardProps
  extends React.ComponentPropsWithoutRef<'div'> {
  user: Api.ClientUserByEmail
}

const CollectorPageAccountCard = ({
  user,
  className,
  ...restProps
}: CollectorPageAccountCardProps) => {
  const {data: primaryBank} = api.externalAccounts.list.useQuery(
    {
      headers: {
        'User-Id': user.user.uuid,
      },
    },
    {
      select: (externalAccounts) =>
        externalAccounts.banks.find((b) => b.isDefault),
    },
  )

  const {personal_address: address} = user?.user ?? {}

  return (
    <WebUI.VStack
      className={WebUI.cn('[&_>_.Stack]:p-6', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="gap-4">
        <div className="font-normal text-ds-xs text-gray400">
          ACCOUNT INFORMATION
        </div>
        <WebUI.VStack className="gap-1 [&_>_.Stack_>_svg]:text-ds-md">
          <WebUI.HStack className="items-center gap-2">
            <WebUI.PhosphorIcon icon="chat-circle" />
            <span className="font-normal text-ds-sm">{user.user.email}</span>
          </WebUI.HStack>
          <WebUI.HStack className="items-center gap-2">
            <WebUI.PhosphorIcon icon="device-mobile" />
            <span className="font-normal text-ds-sm">
              {user?.user?.profile?.phone?.fullNumber || ''}
            </span>
          </WebUI.HStack>
          <WebUI.HStack className="items-center gap-2">
            <WebUI.PhosphorIcon icon="map-pin" />
            <div className="font-normal text-ds-sm">
              <span>
                {address?.line1 || ''}
                {address?.line2 && `, ${address?.line2}`}
              </span>
              {!!address?.city && (
                <div>
                  {address?.city || ''}
                  {address?.state && `, ${address?.state}`}
                  {address?.postal_code && `, ${address?.postal_code}`}
                  {address?.country && ` ${address?.country}`}
                </div>
              )}
            </div>
          </WebUI.HStack>
          <WebUI.HStack className="items-center gap-2">
            <WebUI.PhosphorIcon icon="currency-dollar" />
            <span className="font-normal text-ds-sm">
              {user?.user?.currency?.toUpperCase() || 'USD'}
            </span>
          </WebUI.HStack>
          {!!primaryBank && (
            <WebUI.HStack className="items-center gap-2">
              <WebUI.PhosphorIcon icon="bank" />
              <span className="font-normal text-ds-sm">
                {primaryBank?.last4 || ''}
              </span>
            </WebUI.HStack>
          )}
        </WebUI.VStack>
      </WebUI.VStack>
      <WebUI.VStack className="font-normal text-ds-sm [&_>_.Stack_>_div]:flex-[0_0_50%]">
        <WebUI.HStack className="gap-2">
          <div className="text-gray400">Account Created</div>
          <div>{Util.formatDateAs(user.user.created_at, 'date_tabular')}</div>
        </WebUI.HStack>
        <WebUI.HStack className="gap-2">
          <div className="text-gray400">Status</div>
          <div>Active</div>
        </WebUI.HStack>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – CollectorPageCollectionPagesTable

interface CollectorPageCollectionPagesTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  user: Api.ClientUserByEmail
}

const CollectorPageCollectionPagesTable = ({
  user,
  ...restProps
}: CollectorPageCollectionPagesTableProps) => {
  const collectionsQuery = api.tabs.list.useQuery(
    {
      headers: {
        'User-Id': user.user.uuid,
      },
    },
    {
      select: (cs) => cs.filter((c) => !!c.access.owner),
    },
  )

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Api.Tab>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((collection) => collection.name, {
        id: 'name',
        header: 'Collection Name',
      }),
      columnHelper.accessor((collection) => collection.created_at, {
        id: 'createdAt',
        meta: {
          align: 'right',
        },
        header: 'Created Date',
        cell: ({cell}) => Util.formatDateAs(cell.getValue(), 'date_tabular'),
      }),
      columnHelper.accessor((collection) => collection.status, {
        id: 'status',
        meta: {
          align: 'right',
        },
        header: 'Status',
        cell: ({cell}) => (
          <WebUI.Tag
            className={WebUI.cn(
              'text-ds-xs',
              {
                Closed: 'bg-teal-70',
                Open: 'bg-teal-50',
              }[cell.getValue() as string],
            )}
          >
            {cell.getValue()}
          </WebUI.Tag>
        ),
      }),
      columnHelper.accessor((collection) => collection.payments_total, {
        id: 'paymentsTotal',
        meta: {
          align: 'right',
        },
        header: 'Collected',
        cell: ({cell}) => Util.formatAmount(cell.getValue()),
      }),
    ],
    [columnHelper],
  )

  const initialPaginationState = useMemo(
    () => ({pageIndex: 0, pageSize: 25}),
    [],
  )

  return (
    <WebUI.VStack as={WebUI.Panel} {...restProps}>
      <WebUI.HStack className="items-center gap-4 px-6 py-4 text-ds-md">
        <WebUI.PhosphorIcon
          className="rounded bg-[#f6ab2e] p-1 text-ds-2xl text-natural-100"
          icon="link"
        />
        <span className="font-normal">
          Collection Pages: {collectionsQuery.data?.length ?? 0}
        </span>
      </WebUI.HStack>
      <WebUI.Separator variant="primary" />
      <WebUI.TableView
        className="[&_.TableView-headerGroup]:px-4 [&_.TableViewRow]:px-4"
        initialState={{pagination: initialPaginationState}}
        sortable
        sortByTogglesVisible
        loading={collectionsQuery.isPending}
        columns={columns}
        data={collectionsQuery.data ?? []}
        getRowProps={(row) =>
          ({
            as: Link,
            className: 'px-0 h-auto [font-size:inherit] text-left',
            to: {
              pathname: `/reporting/collection-pages/${row.original.id}`,
            },
            state: {
              uuid: row.original.organizer?.uuid,
              email: row.original.organizer?.email,
            },
          }) as any
        }
      >
        {(table) =>
          table.getPageCount() > 1 ? (
            <WebUI.HStack className="items-center justify-end p-4">
              <WebUI.TablePaginator />
            </WebUI.HStack>
          ) : null
        }
      </WebUI.TableView>
    </WebUI.VStack>
  )
}

export default CollectorPage
