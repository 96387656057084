import {useQueryClient} from '@tanstack/react-query'
import {PartialDeep, groupBy, z} from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {Endpoints, endpoints} from '../../endpoints'
import {FetchInput, getEndpointKey} from '../../utils'
import {makeUseBatchMutation} from './misc'

export const useCreateItemFieldMutation = makeUseMutation(
  endpoints.fields.createItemField,
  (vars) => ({
    regular: (newField) => [
      makeQueryUpdate(
        endpoints.fields.itemList,
        (prevFields) => (prevFields ? [...prevFields, newField] : undefined),
        vars,
      ),
    ],
  }),
)

export const useUpdateItemFieldMutation = makeUseMutation(
  endpoints.fields.updateItemField,
  (vars) => ({
    regular: (newField) => [
      makeQueryUpdate(
        endpoints.fields.itemList,
        (prevFields) =>
          prevFields?.map((f) =>
            f.id === Number(vars.pathParams.fieldId) ? newField : f,
          ),
        vars,
      ),
    ],
  }),
)

export const useDeleteItemFieldMutation = makeUseMutation(
  endpoints.fields.deleteItemField,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.fields.itemList,
        (prevFields) =>
          prevFields?.filter((f) => f.id !== Number(vars.pathParams.fieldId)),
        vars,
      ),
    ],
  }),
)

export const useCreateFormFieldMutation = makeUseMutation(
  endpoints.fields.createFormField,
  (vars) => ({
    regular: (newField) => [
      makeQueryUpdate(
        endpoints.fields.formList,
        (prevFields) => (prevFields ? [...prevFields, newField] : undefined),
        vars,
      ),
    ],
  }),
)

export const useUpdateFormFieldMutation = makeUseMutation(
  endpoints.fields.updateFormField,
  (vars) => ({
    regular: (newField) => [
      makeQueryUpdate(
        endpoints.fields.formList,
        (prevFields) =>
          prevFields?.map((f) =>
            f.id === Number(vars.pathParams.fieldId) ? newField : f,
          ),
        vars,
      ),
    ],
  }),
)

export const useDeleteFormFieldMutation = makeUseMutation(
  endpoints.fields.deleteFormField,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.fields.formList,
        (prevFields) =>
          prevFields?.filter((f) => f.id !== Number(vars.pathParams.fieldId)),
        vars,
      ),
    ],
  }),
)

export const useCreateFieldValueMutation = makeUseMutation(
  endpoints.fields.createValue,
  undefined,
  (queryClient) => ({
    onSuccess: (_newFieldValue, vars) => {
      const paymentDetailQueryKey = getEndpointKey(
        endpoints.payments.detail,
        vars,
      )
      const tabPaymentDetailQueryKey = getEndpointKey(
        endpoints.tabPayments.detail,
        vars,
      )

      queryClient.invalidateQueries({queryKey: paymentDetailQueryKey})
      queryClient.invalidateQueries({queryKey: tabPaymentDetailQueryKey})
    },
  }),
)

export const useUpdateFieldValueMutation = makeUseMutation(
  endpoints.fields.updateValue,
  undefined,
  (queryClient) => ({
    onSuccess: (_newFieldValue, vars) => {
      const paymentDetailQueryKey = getEndpointKey(
        endpoints.payments.detail,
        vars,
      )
      const tabPaymentDetailQueryKey = getEndpointKey(
        endpoints.tabPayments.detail,
        vars,
      )

      queryClient.invalidateQueries({queryKey: paymentDetailQueryKey})
      queryClient.invalidateQueries({queryKey: tabPaymentDetailQueryKey})
    },
  }),
)

const update = (
  fields: Api.TabObjectField[] | undefined,
  resAndOps: Array<{
    op: z.infer<Endpoints['misc']['batch']['bodySchema']>['ops'][number]
    result: any
  }>,
): Api.TabObjectField[] | undefined => {
  if (!fields) {
    return fields
  }

  const resBodiesAndOps = groupBy(
    resAndOps
      .map(({op, result}) => {
        const fieldId = op.url.split('/').pop()
        return fieldId
          ? {fieldId: Number(fieldId), field: result, method: op.method}
          : undefined
      })
      .filter((r) => r != null),
    (r) => r.method,
  )
  const {
    POST: createResults = [],
    PATCH: updateResults = [],
    DELETE: deleteResults = [],
  } = resBodiesAndOps

  return [
    ...fields
      .filter((f) => !deleteResults.some((df) => df.fieldId === f.id))
      .map((f) => updateResults.find((uf) => uf.fieldId === f.id)?.field ?? f),
    ...createResults.map((cf) => cf.field),
  ]
}

const useFieldsCUDBatch = makeUseBatchMutation<
  FetchInput<Endpoints['misc']['batch']> & {
    tabId: number
    tabObjectId: number
    tabObjectType: 'item' | 'form'
  }
>((vars) => ({
  regular: (_newData, resAndOps) => [
    vars.tabObjectType === 'form'
      ? makeQueryUpdate(
          endpoints.fields.formList,
          (fields) => update(fields, resAndOps),
          {
            pathParams: {
              tabId: vars.tabId,
              formId: vars.tabObjectId,
            },
          },
        )
      : makeQueryUpdate(
          endpoints.fields.itemList,
          (fields) => update(fields, resAndOps),
          {
            pathParams: {
              tabId: vars.tabId,
              itemId: vars.tabObjectId,
            },
          },
        ),
  ],
}))

export function useFieldsCUDBatchMutateAsync() {
  const queryClient = useQueryClient()
  const batchMutation = useFieldsCUDBatch()

  return async (
    {
      tabId,
      tabObjectId,
      tabObjectType,
    }: {
      tabId: number
      tabObjectId: number
      tabObjectType: 'item' | 'form'
    },
    fields: {
      create: Array<PartialDeep<Api.TabObjectField>>
      update: Array<PartialDeep<Api.TabObjectField>>
      delete: Array<PartialDeep<Api.TabObjectField>>
    },
  ) => {
    const res = await batchMutation.mutateAsync({
      tabId,
      tabObjectId,
      tabObjectType,
      body: {
        sequential: true,
        ops: [
          ...fields.create.map((ftc) => ({
            method: 'POST' as const,
            url: `/api/users/tabs/${tabId}/${tabObjectType}s/${tabObjectId}/fields`,
            params: {
              field: ftc,
            },
          })),
          ...fields.update.map((ftu) => ({
            method: 'PATCH' as const,
            url: `/api/users/tabs/${tabId}/${tabObjectType}s/${tabObjectId}/fields/${ftu.id}`,
            params: {
              field: ftu,
            },
          })),
          ...fields.delete.map((ftd) => ({
            method: 'DELETE' as const,
            url: `/api/users/tabs/${tabId}/${tabObjectType}s/${tabObjectId}/fields/${ftd.id}`,
          })),
        ],
      },
    })

    const tabsDetailEndpointKey = getEndpointKey(endpoints.tabs.detail, {
      pathParams: {
        tabId,
      },
    })
    const tabObjectsListEndpointKey = getEndpointKey(
      tabObjectType === 'item'
        ? endpoints.tabItems.list
        : endpoints.tabForms.list,
      {
        pathParams: {
          tabId,
        },
      },
    )
    const tabObjectsDetailEndpointKey = getEndpointKey(
      tabObjectType === 'item'
        ? endpoints.tabItems.detail
        : endpoints.tabForms.detail,
      {
        pathParams: {
          tabId,
          itemId: tabObjectId,
          formId: tabObjectId,
        },
      },
    )

    queryClient.invalidateQueries({queryKey: tabObjectsDetailEndpointKey})
    queryClient.invalidateQueries({queryKey: tabObjectsListEndpointKey})
    queryClient.invalidateQueries({queryKey: tabsDetailEndpointKey})

    return res
  }
}
