import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {s3ImageSchema, tabMemberSchema, tabPaymentSchema} from '../schemas'

const extendedTabMemberSchema = tabMemberSchema.extend({
  payments: tabPaymentSchema
    .pick({
      id: true,
      created_at: true,
      total_refund: true,
      subtotal: true,
      total: true,
      status: true,
      fully_refunded: true,
      paid: true,
      payment_items: true,
    })
    .extend({
      items: z
        .object({
          id: z.number(),
          name: z.string(),
          quantity: z.number(),
          amount: z.number(),
          images: s3ImageSchema.array(),
        })
        .array(),
    })
    .array(),
})

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/members',
    queryParamsSchema: z
      .object({
        include_refunds: z.boolean(),
      })
      .partial()
      .optional(),
    responseSchema: extendedTabMemberSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/members/:memberId',
    responseSchema: extendedTabMemberSchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/members/:memberId',
    method: 'PATCH',
    bodySchema: z.object({
      unsubscribed_at: z.string().nullable(),
    }),
    responseSchema: extendedTabMemberSchema,
  }),
}
