import * as Ariakit from '@ariakit/react'
import React, {useImperativeHandle} from 'react'
import {HTMLMotionProps, motion} from 'framer-motion'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'
import {getPortalElement} from './Portal'

export type TooltipVariant = 'dark' | 'light'

export interface TooltipProps
  extends Ariakit.TooltipStoreProps,
    Ariakit.TooltipProviderProps {}

export const Tooltip = React.forwardRef<Ariakit.TooltipStore, TooltipProps>(
  (
    {
      type,
      placement,
      timeout,
      showTimeout = 200,
      hideTimeout,
      skipTimeout,
      defaultOpen,
      open,
      animated,
      disclosure,
      popover,
      setOpen,
      setMounted,
      ...restProps
    },
    forwardedRef,
  ) => {
    const tooltipStore = Ariakit.useTooltipStore({
      type,
      placement,
      timeout,
      showTimeout,
      hideTimeout,
      skipTimeout,
      defaultOpen,
      open,
      animated,
      disclosure,
      popover,
      setOpen,
      setMounted,
    })

    useImperativeHandle(forwardedRef, () => tooltipStore, [tooltipStore])

    return <Ariakit.TooltipProvider store={tooltipStore} {...restProps} />
  },
)

// MARK: – TooltipAnchor

export interface TooltipAnchorProps extends Ariakit.TooltipAnchorProps {}

export const TooltipAnchor = React.forwardRef<
  HTMLDivElement,
  TooltipAnchorProps
>(({className, ...restProps}, forwardedRef) => (
  <Ariakit.TooltipAnchor
    ref={forwardedRef}
    className={cn('TooltipAnchor', className)}
    {...restProps}
  />
))

// MARK: – TooltipContent

export const tooltipContent = cva(
  `max-w-[320px] rounded px-4 py-3 text-center text-ds-sm
  shadow-z16`,
  {
    variants: {
      variant: {
        dark: 'bg-tooltipBackgroundDark text-natural-70',
        light: 'bg-natural-100 text-tooltipTextLight',
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
)

export interface TooltipContentProps
  extends VariantsProps<typeof tooltipContent>,
    Omit<Ariakit.TooltipProps, 'arrowPadding' | 'backdrop'> {
  arrow?: boolean
}

// TODO: Fix disappearance animation
export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  TooltipContentProps
>(
  (
    {
      alwaysVisible = true,
      variant = 'dark',
      arrow = true,
      portalElement = getPortalElement,
      unmountOnHide = true,
      render,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => (
    <Ariakit.Tooltip
      ref={forwardedRef}
      className={cn('TooltipContent', tooltipContent({variant}), className)}
      alwaysVisible={alwaysVisible}
      portalElement={portalElement}
      unmountOnHide={unmountOnHide}
      render={
        render ?? (
          <TooltipInnerView>
            {arrow && <TooltipArrow className="Tooltip-arrow" />}
            <div>{children}</div>
          </TooltipInnerView>
        )
      }
      {...restProps}
    />
  ),
)

// MARK: – TooltipArrow

export interface TooltipArrowProps
  extends VariantsProps<typeof tooltipContent>,
    Ariakit.TooltipArrowProps {}

export const TooltipArrow = React.forwardRef<HTMLDivElement, TooltipArrowProps>(
  ({variant = 'dark', className, ...restProps}, forwardedRef) => (
    <Ariakit.TooltipArrow
      ref={forwardedRef}
      className={cn(
        'TooltipArrow',
        'bg-transparent leading-compact',
        '[&_.stroke]:fill-transparent',
        variant === 'dark' && '[&_.fill]:fill-tooltipBackgroundDark',
        variant === 'light' && '[&_.fill]:fill-natural-100',
      )}
      {...restProps}
    />
  ),
)

// MARK: – TooltipInnerView

const TooltipInnerView = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, forwardedRef) => {
  const tooltip = Ariakit.useTooltipContext()

  if (!tooltip) {
    throw new Error('TooltipInnerView must be used within a Tooltip')
  }

  const isOpen = tooltip.useState('open')

  return (
    <motion.div
      ref={forwardedRef}
      initial={{scale: 0.9, y: '-1rem', opacity: 0}}
      animate={
        isOpen
          ? {
              scale: 1,
              y: 0,
              opacity: 1,
              transition: {duration: 0.15},
            }
          : {
              scale: 0.9,
              y: '-1rem',
              opacity: 0,
              transition: {duration: 0.15},
              transitionEnd: {display: 'none'},
            }
      }
      onAnimationComplete={() =>
        setTimeout(() => tooltip.setState('animating', false), 0)
      }
      {...(props as HTMLMotionProps<'div'>)}
    />
  )
})
