import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query'
import React, {useState} from 'react'

export interface QueryProviderProps {
  config?: QueryClientConfig
  children?: React.ReactNode
}

export const QueryProvider = ({config, children}: QueryProviderProps) => {
  const [queryClient] = useState(
    new QueryClient({
      ...config,
      defaultOptions: {
        ...config?.defaultOptions,
        queries: {
          retry: false,
          staleTime: 60000,
          refetchOnWindowFocus: false,
          ...config?.defaultOptions?.queries,
        },
      },
    }),
  )

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
