import * as WebUI from '@cheddarup/web-ui'
import {forwardRef} from 'react'

import {SharpImage, SharpImageProps} from './SharpImage'

export interface SharpAvatarProps
  extends WebUI.AvatarProps,
    Omit<SharpImageProps, 'width' | 'height' | 'alt' | 'onError'> {}

export const SharpAvatar = forwardRef<HTMLImageElement, SharpAvatarProps>(
  ({className, ...restProps}, forwardedRef) => (
    <WebUI.Avatar
      ref={forwardedRef}
      className={WebUI.cn('SharpAvatar', className)}
      as={SharpImage}
      {...restProps}
    />
  ),
)
