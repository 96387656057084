import alerts from './alerts'
import auth from './auth'
import carts from './carts'
import clients from './clients'
import contacts from './contacts'
import cube from './cube'
import exports from './exports'
import externalAccounts from './external-accounts'
import fields from './fields'
import fieldViews from './field-views'
import fileUploads from './file-uploads'
import formTemplates from './form-templates'
import global from './global'
import groupPages from './group-pages'
import groupPageCategories from './group-page-categories'
import groupPageSections from './group-page-sections'
import groupPageSponsors from './group-page-sponsors'
import groupPageSpotlightItems from './group-page-spotlight-items'
import groupPageTeamMembers from './group-page-team-members'
import headerImages from './header-images'
import headers from './headers'
import inventoryGroups from './inventory-groups'
import itemCatalogs from './item-catalogs'
import managers from './managers'
import marketplace from './marketplace'
import messages from './messages'
import misc from './misc'
import notifications from './notifications'
import orgMemberInvites from './org-member-invites'
import orgMembers from './org-members'
import paymentItems from './payment-items'
import paymentMethods from './payment-methods'
import payments from './payments'
import publicTabs from './public-tabs'
import recurringPaymentContracts from './recurring-payment-contracts'
import shipments from './shipments'
import subscriptions from './subscriptions'
import tabAttachments from './tab-attachments'
import tabCategories from './tab-categories'
import tabDeposits from './tab-deposits'
import tabDiscounts from './tab-discounts'
import tabFolders from './tab-folders'
import tabForms from './tab-forms'
import tabInvites from './tab-invites'
import tabItems from './tab-items'
import tabMembers from './tab-members'
import tabPaymentRefunds from './tab-payment-refunds'
import tabPayments from './tab-payments'
import tabs from './tabs'
import tabSignups from './tab-signups'
import tabSignupSpots from './tab-signup-spots'
import templates from './templates'
import tickets from './tickets'
import timeSlots from './time-slots'
import users from './users'
import userBrandings from './user-brandings'
import withdrawals from './withdrawals'

// HACK: – getters here improve performance of intellisense
export const endpoints = {
  get alerts() {
    return alerts
  },
  get auth() {
    return auth
  },
  get carts() {
    return carts
  },
  get clients() {
    return clients
  },
  get contacts() {
    return contacts
  },
  get cube() {
    return cube
  },
  get exports() {
    return exports
  },
  get externalAccounts() {
    return externalAccounts
  },
  get fieldViews() {
    return fieldViews
  },
  get fields() {
    return fields
  },
  get fileUploads() {
    return fileUploads
  },
  get formTemplates() {
    return formTemplates
  },
  get global() {
    return global
  },
  get groupPages() {
    return groupPages
  },
  get groupPageCategories() {
    return groupPageCategories
  },
  get groupPageSections() {
    return groupPageSections
  },
  get groupPageSponsors() {
    return groupPageSponsors
  },
  get groupPageSpotlightItems() {
    return groupPageSpotlightItems
  },
  get groupPageTeamMembers() {
    return groupPageTeamMembers
  },
  get headerImages() {
    return headerImages
  },
  get headers() {
    return headers
  },
  get inventoryGroups() {
    return inventoryGroups
  },
  get itemCatalogs() {
    return itemCatalogs
  },
  get managers() {
    return managers
  },
  get marketplace() {
    return marketplace
  },
  get messages() {
    return messages
  },
  get misc() {
    return misc
  },
  get notifications() {
    return notifications
  },
  get orgMemberInvites() {
    return orgMemberInvites
  },
  get orgMembers() {
    return orgMembers
  },
  get paymentItems() {
    return paymentItems
  },
  get paymentMethods() {
    return paymentMethods
  },
  get payments() {
    return payments
  },
  get publicTabs() {
    return publicTabs
  },
  get recurringPaymentContracts() {
    return recurringPaymentContracts
  },
  get shipments() {
    return shipments
  },
  get subscriptions() {
    return subscriptions
  },
  get tabAttachments() {
    return tabAttachments
  },
  get tabCategories() {
    return tabCategories
  },
  get tabDeposits() {
    return tabDeposits
  },
  get tabDiscounts() {
    return tabDiscounts
  },
  get tabFolders() {
    return tabFolders
  },
  get tabForms() {
    return tabForms
  },
  get tabInvites() {
    return tabInvites
  },
  get tabItems() {
    return tabItems
  },
  get tabMembers() {
    return tabMembers
  },
  get tabPaymentRefunds() {
    return tabPaymentRefunds
  },
  get tabPayments() {
    return tabPayments
  },
  get tabSignupSpots() {
    return tabSignupSpots
  },
  get tabSignups() {
    return tabSignups
  },
  get tabs() {
    return tabs
  },
  get templates() {
    return templates
  },
  get tickets() {
    return tickets
  },
  get timeSlots() {
    return timeSlots
  },
  get users() {
    return users
  },
  get userBrandings() {
    return userBrandings
  },
  get withdrawals() {
    return withdrawals
  },
}

export type Endpoints = typeof endpoints
