import {useEffect, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {CreditCardSelectRow, PrintShippingLabelInvoice} from 'src/components'
import {PrintShippingLabelInvoiceAmounts} from 'src/components/PrintShippingLabelInvoice'
import {PaymentElement} from 'src/components/Stripe/PaymentElement'

import type {PrintShippingLabelFormik} from '../containers/PrintShippingLabelContainer/PrintShippingLabelContainer'

export interface PrintShippingLabelPayFormProps {
  stripeReady?: boolean
  creditCards: Api.CreditCard[]
  amounts: PrintShippingLabelInvoiceAmounts
  amountsVisible?: boolean
  calculateShippingCostDisabled?: boolean
  calculateShippingCostLoading?: boolean
  formik: PrintShippingLabelFormik
  onCalculateShippingCost: () => void
}

const PrintShippingLabelPayForm = ({
  stripeReady,
  creditCards,
  amounts,
  amountsVisible,
  formik,
  calculateShippingCostLoading,
  calculateShippingCostDisabled,
  onCalculateShippingCost,
}: PrintShippingLabelPayFormProps) => {
  const [addingCard, setAddingCard] = useState(false)
  const creditCardFormVisible =
    !!stripeReady && (creditCards.length === 0 || addingCard)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    formik.setFieldValue(
      'savedCardId',
      creditCardFormVisible ? null : creditCards[0]?.id ?? null,
    )
    if (!creditCardFormVisible) {
      formik.setFieldValue('creditCard', null)
    }
  }, [creditCardFormVisible])

  return (
    <WebUI.HStack className="gap-8 p-4 *:flex-[1_0_0px]" as={WebUI.Panel}>
      <WebUI.VStack className="gap-4">
        <div className="font-normal text-ds-md">Shipping Costs</div>
        {amountsVisible ? (
          <PrintShippingLabelInvoice
            amounts={amounts}
            amountsVisible={amountsVisible}
            titleHidden
          />
        ) : (
          <WebUI.Button
            className="w-[280px]"
            type="button"
            size="large"
            loading={calculateShippingCostLoading}
            disabled={calculateShippingCostDisabled}
            onClick={onCalculateShippingCost}
          >
            Calculate Shipping Cost
          </WebUI.Button>
        )}
      </WebUI.VStack>
      {amountsVisible ? (
        <WebUI.VStack className="gap-4">
          <WebUI.HStack className="items-baseline justify-between text-ds-sm">
            <div className="font-normal text-ds-md">
              How would you like to pay?
            </div>
            {creditCards.length > 0 && (
              <WebUI.Button
                className="text-right"
                variant="link"
                onClick={() =>
                  setAddingCard((prevAddingCard) => !prevAddingCard)
                }
              >
                {creditCardFormVisible ? 'Saved card' : 'Add card'}
              </WebUI.Button>
            )}
          </WebUI.HStack>
          {creditCardFormVisible ? (
            <PaymentElement />
          ) : (
            <WebUI.RadioGroup
              aria-label="Credit card select"
              name="savedCardId"
              state={formik.values.savedCardId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {creditCards.map((creditCard) => (
                <CreditCardSelectRow
                  key={creditCard.id}
                  creditCard={creditCard}
                  as={WebUI.Radio}
                  value={creditCard.id}
                />
              ))}
            </WebUI.RadioGroup>
          )}
          <WebUI.Button
            className="w-full"
            loading={formik.isSubmitting}
            type="submit"
            variant={amountsVisible ? 'primary' : 'secondary'}
            disabled={
              !amountsVisible || amounts.postage + (amounts.signature ?? 0) <= 0
            }
            onClick={formik.submitForm}
          >
            Confirm and Pay
          </WebUI.Button>
        </WebUI.VStack>
      ) : (
        <div />
      )}
    </WebUI.HStack>
  )
}

export default PrintShippingLabelPayForm
