import {newGenericForwardRef} from '@cheddarup/react-util'
import {mapToObj} from '@cheddarup/util'
import {SortingStrategy, verticalListSortingStrategy} from '@dnd-kit/sortable'
import React, {useImperativeHandle, useMemo, useRef, useState} from 'react'

import {
  CollisionDetection,
  DragAndDrop,
  DragEndEvent,
  Modifier,
  Sortable,
  SortableContainer,
  SortableContext,
  UniqueIdentifier,
  arrayMoveByValue,
  pointerWithin,
} from './DragAndDrop'
import {
  List,
  ListDefaultEmptyStateView,
  ListInstance,
  ListRowComponentProps,
  ListRowComponentType,
  ListRowData,
  ListRowDataId,
} from './List'
import {ViewportList, ViewportListInstnce} from './ViewportList'
import {VStack} from './Stack'
import {cn} from '../utils'

export type AnySectionList<S extends ListRowData, R extends ListRowData> =
  | typeof SectionList<S, R>
  | typeof SortableSectionList<S, R>

export interface SectionListData<S extends ListRowData, R extends ListRowData> {
  sectionData: S
  rowsData: R[]
}

export type ListSectionComponentType<T extends ListRowData> =
  React.ComponentType<
    Omit<ListRowComponentProps<T>, 'index'> & {ref?: React.Ref<HTMLDivElement>}
  >

export interface SectionListInstance {
  scrollToSection: (sectionKey: ListRowDataId) => void
  scrollToRow: (rowKey: ListRowDataId) => void
}

export interface SectionListProps<S extends ListRowData, R extends ListRowData>
  extends React.ComponentPropsWithoutRef<'div'> {
  estimateRowSize?: number
  SectionComponent: ListSectionComponentType<S>
  RowComponent: ListRowComponentType<R>
  EmptyStateSectionViewComponent?: React.ComponentType<any>
  EmptyStateViewComponent?: React.ComponentType<any>
  data: Array<SectionListData<S, R>>
}

export const SectionList = newGenericForwardRef(
  <S extends ListRowData, R extends ListRowData>({
    estimateRowSize,
    SectionComponent,
    RowComponent,
    EmptyStateSectionViewComponent = 'div' as any,
    EmptyStateViewComponent = ListDefaultEmptyStateView,
    data,
    className,
    ref: forwardedRef,
    ...restProps
  }: SectionListProps<S, R> & {
    ref?: React.Ref<SectionListInstance>
  }) => {
    const sectionRefMap = useRef<Record<string, HTMLDivElement | null>>({})
    const rowRefMap = useRef<Record<string, HTMLDivElement | null>>({})

    useImperativeHandle(
      forwardedRef,
      (): SectionListInstance => ({
        scrollToSection: (sectionKey) =>
          sectionRefMap.current?.[sectionKey]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        scrollToRow: (rowKey) =>
          rowRefMap.current?.[rowKey]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
      }),
      [],
    )

    if (data.length === 0) {
      return <EmptyStateViewComponent className={className} />
    }

    return (
      <VStack
        className={cn(
          'SectionList',
          'h-full overflow-y-auto *:flex-0',
          className,
        )}
        role="list"
        {...restProps}
      >
        {data.map((section) => (
          <SectionComponent
            key={section.sectionData.id}
            ref={(sectionRef) => {
              sectionRefMap.current[section.sectionData.id] = sectionRef
            }}
            className="SectionList-section"
            data={section.sectionData}
            role="listitem"
          >
            {section.rowsData.length > 0 ? (
              <ViewportList<R>
                className="SectionList-rowsContainer SectionContainer"
                itemSize={estimateRowSize}
                items={section.rowsData}
              >
                {(row, index) => (
                  <RowComponent
                    key={row.id}
                    ref={(rowRef) => {
                      rowRefMap.current[row.id] = rowRef
                    }}
                    className="SectionList-row"
                    index={index}
                    data={row}
                    role="listitem"
                  />
                )}
              </ViewportList>
            ) : (
              <EmptyStateSectionViewComponent />
            )}
          </SectionComponent>
        ))}
      </VStack>
    )
  },
)

// MARK: – SortableSectionList

export interface SortableSectionListProps<
  S extends ListRowData,
  R extends ListRowData,
> extends SectionListProps<S, R> {
  virtualized?: boolean
  noDndContext?: boolean
  dragOverlayPortal?: boolean
  sortingStrategy?: SortingStrategy
  collisionDetection?: CollisionDetection
  dndModifiers?: Modifier[]
  sortableBetweenSections?: boolean
  sortableRows?: boolean
  // if you drag an outer row data into a list, SortableContext will inject the
  // id into items and the list won't be able to pull the row data,
  // `hiddenRowsData` is here to handle that
  hiddenRowsData?: R[]
  sortDisabledSectionIds?: Array<S['id']>
  onOrderChange?: (
    params: {
      sectionId: S['id'] | null
      orderedRowIds: Array<R['id']>
    },
    event: DragEndEvent,
  ) => void
  onMoveToSection?: (
    source: {sectionId: S['id']; orderedRowIds: Array<R['id']>},
    dest: {sectionId: S['id']; orderedRowIds: Array<R['id']>},
    event: DragEndEvent,
  ) => void
}

export const SortableSectionList = newGenericForwardRef(
  <S extends ListRowData, R extends ListRowData>({
    virtualized = true,
    noDndContext = false,
    dragOverlayPortal = true,
    sortingStrategy = verticalListSortingStrategy,
    collisionDetection = pointerWithin,
    dndModifiers,
    sortableBetweenSections = true,
    sortableRows = true,
    hiddenRowsData = [],
    estimateRowSize,
    SectionComponent,
    RowComponent,
    onOrderChange,
    onMoveToSection,
    EmptyStateViewComponent = 'div' as any,
    EmptyStateSectionViewComponent = ListDefaultEmptyStateView,
    sortDisabledSectionIds = [],
    data,
    className,
    ref: forwardedRef,
    ...restProps
  }: SortableSectionListProps<S, R> & {
    ref?: React.Ref<SectionListInstance>
  }) => {
    const viewportListsRefMap = useRef<
      Record<string, ViewportListInstnce | ListInstance | null>
    >({})
    const sectionRefMap = useRef<Record<string, HTMLDivElement | null>>({})
    const [rowKeysOrderedMap, setRowKeysOrdered] = useState<
      Record<string, UniqueIdentifier[]>
    >({})

    const sectionKeyRowKeysMap = useMemo(
      () =>
        mapToObj(data, (section) => [
          section.sectionData.id,
          section.rowsData.map((r) => r.id),
        ]),
      [data],
    )
    const sectionIds = useMemo(() => data.map((i) => i.sectionData.id), [data])
    const rowMap = useMemo(
      () =>
        mapToObj(
          [...data.flatMap((i) => i.rowsData), ...hiddenRowsData],
          (row) => [row.id, row],
        ),
      [data, hiddenRowsData],
    )

    useImperativeHandle(
      forwardedRef,
      (): SectionListInstance => ({
        scrollToSection: (sectionKey) =>
          sectionRefMap.current?.[sectionKey]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        scrollToRow: (rowKey) => {
          const sectionKey = Object.keys(sectionKeyRowKeysMap).find((sk) =>
            sectionKeyRowKeysMap[sk]?.includes(rowKey),
          )
          const rowIdx = sectionKey
            ? sectionKeyRowKeysMap[sectionKey]?.indexOf(rowKey)
            : -1

          if (sectionKey && rowIdx) {
            const list = viewportListsRefMap.current[sectionKey]

            if (list && 'scrollToIndex' in list) {
              list.scrollToIndex({
                index: rowIdx,
              })
            } else {
              list?.scrollToRow(rowKey)
            }
          }
        },
      }),
      [sectionKeyRowKeysMap],
    )

    const SortableRowComponent: ListRowComponentType<R> = useMemo(
      () =>
        React.forwardRef(
          (
            {data: rowData, className: rowClassName, ...rowProps},
            rowForwardedRef,
          ) => {
            const rowEl = (
              <RowComponent
                ref={rowForwardedRef}
                className={cn('SectionList-row', rowClassName)}
                data={rowData}
                {...rowProps}
              />
            )

            return sortableRows ? (
              <Sortable id={rowData.id} data={rowData} tabIndex={-1}>
                {rowEl}
              </Sortable>
            ) : (
              rowEl
            )
          },
        ),
      [RowComponent, sortableRows],
    )

    if (data.length === 0) {
      return <EmptyStateViewComponent />
    }

    const content = (
      <SortableContext
        id="base"
        strategy={verticalListSortingStrategy}
        items={sectionIds}
        disabled={!sortableBetweenSections}
      >
        {({items: sectionKeysOrdered}) => (
          <VStack
            className={cn(
              'SectionList',
              'h-full overflow-y-auto *:flex-0',
              className,
            )}
            role="list"
            {...restProps}
          >
            {sectionKeysOrdered.map((sectionKey) => {
              const section = data.find((s) => s.sectionData.id === sectionKey)

              if (!section) {
                return null
              }

              return (
                <SortableContainer
                  key={section.sectionData.id}
                  id={section.sectionData.id}
                  data={{
                    section,
                    rowKeys: rowKeysOrderedMap[section.sectionData.id],
                  }}
                  tabIndex={-1}
                  disabled={sortDisabledSectionIds?.includes(
                    section.sectionData.id,
                  )}
                >
                  <SortableContext
                    id={section.sectionData.id}
                    strategy={sortingStrategy}
                    items={sectionKeyRowKeysMap[section.sectionData.id] ?? []}
                    disabled={!sortableBetweenSections}
                    onItemsChange={(newRowKeys) =>
                      setRowKeysOrdered((prevRowKeysOrdered) => ({
                        ...prevRowKeysOrdered,
                        [section.sectionData.id]: newRowKeys,
                      }))
                    }
                  >
                    {({items: rowKeysOrdered}) => (
                      <SectionComponent
                        ref={(sectionRef) => {
                          sectionRefMap.current[section.sectionData.id] =
                            sectionRef
                        }}
                        className="SectionList-section"
                        data={section.sectionData}
                        role="listitem"
                      >
                        {rowKeysOrdered.length === 0 ? (
                          <Sortable
                            id={`${section.sectionData.id}-placeholder`}
                            tabIndex={-1}
                          >
                            <EmptyStateSectionViewComponent />
                          </Sortable>
                        ) : virtualized ? (
                          <ViewportList<UniqueIdentifier>
                            className="SectionList-itemList SectionContainer"
                            listRef={(list) => {
                              viewportListsRefMap.current[
                                section.sectionData.id
                              ] = list
                            }}
                            itemSize={estimateRowSize}
                            items={rowKeysOrdered}
                          >
                            {(rowKey, index) => {
                              const row = rowMap[rowKey]

                              if (!row) {
                                return null
                              }

                              return (
                                <SortableRowComponent
                                  role="listitem"
                                  key={rowKey}
                                  className="SectionList-row"
                                  index={index}
                                  data={row}
                                />
                              )
                            }}
                          </ViewportList>
                        ) : (
                          <List
                            ref={(list) => {
                              viewportListsRefMap.current[
                                section.sectionData.id
                              ] = list
                            }}
                            className="SectionList-itemList SectionContainer"
                            data={
                              rowKeysOrdered
                                .map((rowKey) => rowMap[rowKey])
                                .filter((rk) => rk != null) as R[]
                            }
                          >
                            {(rowData, idx) => {
                              const rowEl = (
                                <RowComponent
                                  key={rowData.id}
                                  role="listitem"
                                  className="SectionList-row"
                                  data={rowData}
                                  index={idx}
                                />
                              )

                              return sortableRows ? (
                                <Sortable
                                  key={rowData.id}
                                  id={rowData.id}
                                  data={{
                                    row: rowData,
                                    section,
                                    rowKeys: rowKeysOrdered,
                                  }}
                                  tabIndex={-1}
                                >
                                  {rowEl}
                                </Sortable>
                              ) : (
                                rowEl
                              )
                            }}
                          </List>
                        )}
                      </SectionComponent>
                    )}
                  </SortableContext>
                </SortableContainer>
              )
            })}
          </VStack>
        )}
      </SortableContext>
    )

    if (noDndContext) {
      return content
    }

    return (
      <DragAndDrop
        touchEnabled
        keyboardEnabled
        collisionDetection={collisionDetection}
        onDragEnd={(event) =>
          processSortableSectionListOnDragEnd(event, {
            data,
            onOrderChange,
            onMoveToSection,
          })
        }
      >
        {content}
      </DragAndDrop>
    )
  },
)

// MARK: – Helpers

export function processSortableSectionListOnDragEnd(
  event: DragEndEvent,
  {
    data,
    onMoveToSection,
    onOrderChange,
  }: {
    data: Array<SectionListData<any, any>>
    onMoveToSection?: (
      source: {sectionId: string; orderedRowIds: string[]},
      dest: {sectionId: string; orderedRowIds: string[]},
      event: DragEndEvent,
    ) => void
    onOrderChange?: (
      params: {
        sectionId: string | null
        orderedRowIds: string[]
      },
      event: DragEndEvent,
    ) => void
  },
) {
  const {active, over} = event
  if (!over) {
    return
  }

  const overContainerId = over.data.current?.sortable
    .containerId as UniqueIdentifier
  const isDraggingSection = active.data.current?.$type === 'container'
  const sourceSection = data.find((i) =>
    i.rowsData.some((r) => r.id === active.id),
  )
  const sourceSectionId = sourceSection?.sectionData.id
  const isDroppingRowOnSameSection = sourceSectionId === overContainerId

  const isMovingRowToOtherSection =
    !isDraggingSection && !isDroppingRowOnSameSection

  const oldOrder = (over.data.current?.sortable.items ??
    []) as UniqueIdentifier[]

  if (isMovingRowToOtherSection) {
    const targetSectionId = (
      overContainerId === 'base' ? over.id : overContainerId
    ) as string
    const isOverSection = over.data.current?.$type === 'container'
    const sourceRowIds = (
      sourceSection?.rowsData.map((r) => r.id) ?? []
    ).filter((rId) => rId !== active.id)
    let newOrder = arrayMoveByValue(oldOrder, active.id, over.id) as string[]

    if (isOverSection) {
      const targetSectionRowIds =
        data
          .find((i) => i.sectionData.id === targetSectionId)
          ?.rowsData.map((r) => r.id) ?? []
      newOrder = [...targetSectionRowIds, active.id]
    }

    if (sourceSectionId != null) {
      onMoveToSection?.(
        {sectionId: sourceSectionId, orderedRowIds: sourceRowIds},
        {sectionId: targetSectionId, orderedRowIds: newOrder},
        event,
      )
    }
  } else {
    const newOrder = arrayMoveByValue(oldOrder, active.id, over.id) as string[]
    if (isDroppingRowOnSameSection) {
      onOrderChange?.(
        {
          sectionId: sourceSectionId,
          orderedRowIds: newOrder,
        },
        event,
      )
    } else {
      onOrderChange?.({sectionId: null, orderedRowIds: newOrder}, event)
    }
  }
}
