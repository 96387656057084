import {tokens} from '@cheddarup/tokens'
import type {PresetsConfig} from 'tailwindcss/types/config'
import plugin from 'tailwindcss/plugin'

export default {
  content: [
    '../../apps/web/src/**/*.{js,ts,jsx,tsx,css}',
    '../../apps/web/index.html',
    '../../packages/web-ui/components/__stories__/**/*.{js,ts,jsx,tsx,css}',
    '../../packages/web-ui/components/**/*.{js,ts,jsx,tsx,css}',
    '../../packages/web-ui/icons/**/*.{js,ts,jsx,tsx,css}',
    '../../packages/web-ui/hooks/**/*.{js,ts,jsx,tsx,css}',
  ],
  theme: {
    extend: {
      flex: {
        0: '0 0 auto',
      },
      colors: {
        ...tokens.colors,
        transparent: 'transparent',
        backdropBackground: '#000000d9',
        natural: {
          '00': '#000000',
          '10': '#241C15',
          '20': '#343330',
          '30': '#505051',
          '40': '#6F6F6F',
          '60': '#CCCCCC',
          '70': '#DEDEDE',
          '80': '#EEEEEE',
          '90': '#F4F4F4',
          '95': '#FAFAFA',
          '100': '#FFFFFF',
        },
        yellow: {
          '20': '#A8790B',
          '30': '#D89D0E',
          '40': '#EDB01D',
          '50': '#F9C442',
          '60': '#FBD475',
          '70': '#FCE3A6',
          '80': '#FDEFCE',
        },
        pink: {
          '10': '#8F83B3',
          '20': '#AD99B5',
          '30': '#C2ABCA',
          '40': '#D7BDE0',
          '50': '#E9CDF2',
          '60': '#F1DFF7',
          '70': '#F6EBFA',
          '80': '#FAF3FC',
        },
        orange: {
          '20': '#872D08',
          '30': '#B73D0B',
          '40': '#E74D0E',
          '50': '#F36D36',
          '60': '#F68F65',
          '70': '#F9B295',
          '80': '#FAC4AD',
        },
        teal: {
          '20': '#154551',
          '30': '#1A5666',
          '40': '#1F677A',
          '50': '#257A91',
          '60': '#6AACBB',
          '65': '#92BCC8',
          '70': '#B0DFE5',
          '75': '#C8DDE3',
          '80': '#D7EEF1',
          '90': '#E7F6F8',
        },
      },
      ringColor: {
        DEFAULT: tokens.colors.accent500,
      },
      ringOpacity: {
        DEFAULT: '1',
      },
      ringWidth: {
        DEFAULT: '1px',
      },
      textColor: tokens.colors,
      fontFamily: tokens.fonts,
      spacing: tokens.space,
      fontSize: {
        // Based on https://alistapart.com/article/more-meaningful-typography/
        '0': '0',
        xs: '10px',
        '3xl': '22px',
        '5xl': '26px',
        '8xl': '38px',

        'ds-xs': ['12px', '150%'],
        'ds-sm': ['14px', '150%'],
        'ds-base': ['16px', '150%'],
        'ds-md': ['18px', '150%'],
        'ds-lg': ['20px', '150%'],
        'ds-xl': ['24px', '150%'],
        'ds-2xl': ['32px', '144%'],
        'ds-3xl': ['36px', '140%'],
        'ds-4xl': ['40px', '135%'],
        'ds-5xl': ['48px', '130%'],
        'ds-6xl': ['64px', '125%'],
        'ds-7xl': ['72px', '120%'],

        'h-8': ['16px', '20px'],
        'h-7': ['24px', '28px'],
        'h-6': ['30px', '33px'],
        'h-5': ['36px', '40px'],
        'h-4': ['40px', '45px'],
        'h-3': ['45px', '48px'],
        'h-2': ['50px', '55px'],
        'h-1': ['60px', '64px'],
      },
      lineHeight: tokens.lineHeights,
      borderRadius: {
        ...tokens.radii,
        DEFAULT: '4px',
        xl: '40px',
      },
      borderWidth: {
        3: '3px',
      },
      boxShadow: tokens.shadows,
      backdropBlur: {
        '10': '4px',
        '20': '8px',
        '30': '12px',
        '40': '20px',
      },
      zIndex: tokens.zIndices,
      height: {
        unset: 'unset',
        'input-xs': '30px',
        'input-sm': '36px',
        'input-md': '40px',
        'input-lg': '48px',
        'input-xl': '60px',
      },
      screens: {
        xs: {max: '639px'},
      },
      keyframes: {
        fade: {
          '0%': {opacity: '0'},
          '100%': {opacity: '1'},
        },
        'caret-blink': {
          '0%,70%,100%': {opacity: '1'},
          '20%,50%': {opacity: '0'},
        },
        pulse: {
          '0%': {
            transform: 'scale(1, 1)',
          },
          '100%': {
            transform: 'scale(0.4, 0.4)',
          },
        },
      },
      animation: {
        fade: 'fade 200ms 1 forwards ease-in',
        pulse: 'pulse infinite 750ms alternate ease-in-out',
        'caret-blink': 'caret-blink 1.2s ease-out infinite',
      },
      aria: {
        'current-page': 'current="page"',
        haspopup: 'haspopup="true"',
        invalid: 'invalid="true"',
        'orientation-horizontal': 'orientation="horizontal"',
        'orientation-vertical': 'orientation="vertical"',
      },
    },
  },
  plugins: [
    plugin(({addUtilities}) => {
      addUtilities({
        '.scrollbar-hide': {
          /* IE and Edge */
          '-ms-overflow-style': 'none',

          /* Firefox */
          'scrollbar-width': 'none',

          /* Safari and Chrome */
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      })
    }),
  ],
  corePlugins: {
    preflight: false,
  },
} satisfies PresetsConfig
