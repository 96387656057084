import * as Ariakit from '@ariakit/react'
import React, {useImperativeHandle} from 'react'

import {NextButton} from './Button'
import {Heading} from '../Heading'
import {PhosphorIcon} from '../../icons'
import {cn} from '../../utils'

export interface NextPopoverInstance extends Ariakit.PopoverStore {}

export interface NextPopoverProps extends Ariakit.PopoverStoreProps {
  children?:
    | React.ReactNode
    | ((popover: NextPopoverInstance) => React.ReactNode)
}

export const NextPopover = React.forwardRef<
  NextPopoverInstance,
  NextPopoverProps
>(({children, ...restProps}, forwardedRef) => {
  const popoverStore = Ariakit.usePopoverStore(restProps)

  useImperativeHandle(forwardedRef, () => popoverStore, [popoverStore])

  return (
    <Ariakit.PopoverProvider store={popoverStore}>
      {typeof children === 'function' ? children(popoverStore) : children}
    </Ariakit.PopoverProvider>
  )
})

// MARK: - PopoverDisclosure

export interface NextPopoverDisclosureProps
  extends Ariakit.PopoverDisclosureProps {}

export const NextPopoverDisclosure = React.forwardRef<
  HTMLButtonElement,
  NextPopoverDisclosureProps
>(({render, ...restProps}, forwardedRef) => (
  <Ariakit.PopoverDisclosure
    ref={forwardedRef}
    render={render ?? <NextButton />}
    {...restProps}
  />
))

// MARK: - PopoverDisclosure

export interface NextPopoverDismissProps
  extends Ariakit.PopoverDisclosureProps {}

export const NextPopoverDismiss = React.forwardRef<
  HTMLButtonElement,
  NextPopoverDismissProps
>(({render, children, className, ...restProps}, forwardedRef) => (
  <Ariakit.PopoverDismiss
    ref={forwardedRef}
    className={cn('absolute top-4 right-4 text-lg', className)}
    render={render ?? <NextButton size="xs" variant="transparent" />}
    {...restProps}
  >
    {children ?? <PhosphorIcon icon="x" />}
  </Ariakit.PopoverDismiss>
))

// MARK: - PopoverAnchor

export interface NextPopoverAnchorProps extends Ariakit.PopoverAnchorProps {}

export const NextPopoverAnchor = React.forwardRef<
  HTMLDivElement,
  NextPopoverAnchorProps
>((props, forwardedRef) => (
  <Ariakit.PopoverAnchor ref={forwardedRef} {...props} />
))

// MARK: - PopoverContent

export interface NextPopoverContentProps
  extends Omit<Ariakit.PopoverProps, 'children'> {
  arrow?: boolean
  children?:
    | React.ReactNode
    | ((popover: NextPopoverInstance) => React.ReactNode)
}

export const NextPopoverContent = React.forwardRef<
  HTMLDivElement,
  NextPopoverContentProps
>(
  (
    {arrow, fitViewport = true, gutter = 8, className, children, ...restProps},
    forwardedRef,
  ) => {
    const popover = useNextPopover()
    const isOpen = popover.useState((p) => p.open)
    const isAnimating = popover.useState((p) => p.animating)

    if (!isOpen && !isAnimating) {
      return null
    }

    return (
      <Ariakit.Popover
        ref={forwardedRef}
        className={cn(
          'flex scale-90 flex-col overflow-auto rounded-default bg-natural-100 opacity-0 shadow-z16 transition-all data-[enter]:scale-100 data-[enter]:opacity-100',
          className,
        )}
        fitViewport={fitViewport}
        gutter={gutter}
        {...restProps}
      >
        {typeof children === 'function' ? children(popover) : children}
      </Ariakit.Popover>
    )
  },
)

// MARK: - PopoverHeading

export interface NextPopoverHeadingProps extends Ariakit.PopoverAnchorProps {}

export const NextPopoverHeading = React.forwardRef<
  HTMLHeadingElement,
  NextPopoverHeadingProps
>(({render, ...restProps}, forwardedRef) => (
  <Ariakit.PopoverHeading
    ref={forwardedRef}
    render={render ?? <Heading />}
    {...restProps}
  />
))

// MARK: - PopoverDescription

export interface NextPopoverDescriptionProps
  extends Ariakit.PopoverDescriptionProps {}

export const NextPopoverDescription = React.forwardRef<
  HTMLHeadingElement,
  NextPopoverDescriptionProps
>((props, forwardedRef) => (
  <Ariakit.PopoverDescription ref={forwardedRef} {...props} />
))

// MARK: – Hooks

export function useNextPopover() {
  const popover = Ariakit.usePopoverContext()

  if (!popover) {
    throw new Error('usePopover must be used in a component wrapped by popover')
  }

  return popover
}
