import {useEffect, useState} from 'react'
import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import {Navigate, useNavigate} from 'react-router-dom'
import {useLoginViaTokenMutation} from '@cheddarup/api-client'
import {useAuthToken} from 'src/hooks/useAuthToken'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useLogout} from 'src/hooks/useAuth'

export function LoginViaTokenPage() {
  const navigate = useNavigate()
  const [token] = useQueryParam('token', StringParam)
  const [redirect] = useQueryParam('redirect', StringParam)
  const [, setPreventAuthRedirect] = useQueryParam(
    'preventAuthRedirect',
    BooleanParam,
  )
  const [on_failure_redirect_to] = useQueryParam(
    'on_failure_redirect_to',
    StringParam,
  )
  const loginViaTokenMutation = useLoginViaTokenMutation()
  const [logout] = useLogout()
  const [, setAuthToken] = useAuthToken()
  const growlActions = WebUI.useGrowlActions()
  const [submitting, setSubmitting] = useState(true)
  const loginViaTokenAsync = loginViaTokenMutation.mutateAsync

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    ;(async () => {
      if (token) {
        try {
          setPreventAuthRedirect(true)

          await logout()
          await loginViaTokenAsync({
            queryParams: {
              token,
              respondWithJson: true,
            },
          })

          setAuthToken(token)

          const decodedToken = decodeAuthToken(token)

          await Util.delay(1000)
          navigate(
            redirect ||
              (decodedToken.claims?.data?.payload?.tokenType === 'TabMember'
                ? '/guest'
                : '/collections'),
          )
        } catch (err: any) {
          if (err.response.status === 401) {
            growlActions.show('error', {
              title: 'Link Expired',
              body: 'The link you used has expired. Use this page to request a new one',
            })
            navigate(on_failure_redirect_to || '/guest/auth')
          } else {
            navigate('/')
          }
        } finally {
          // TODO: Consider using react router loaders to avoid these delays
          setPreventAuthRedirect(undefined)
          setSubmitting(false)
        }
      } else {
        setSubmitting(false)
      }
    })()
  }, [])

  if (!token && !submitting) {
    return <Navigate to="/" />
  }

  return <>{null}</>
}

// MARK: – Helpers

function decodeAuthToken(jwt: string): Omit<Api.AuthToken, 'id'> {
  const [headerEncoded, payloadEncoded] = jwt.split('.')

  if (headerEncoded == null || payloadEncoded == null) {
    throw new Error('Invalid JWT')
  }

  const headerDecoded = JSON.parse(atob(headerEncoded))

  const payloadDecoded = JSON.parse(atob(payloadEncoded))

  return {
    header: headerDecoded,
    claims: payloadDecoded,
    expires_at: new Date(payloadDecoded.exp * 1000).toISOString(),
  }
}
