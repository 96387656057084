import * as Util from '@cheddarup/util'
import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ForwardRefComponent} from '@cheddarup/react-util'

export interface ItemAmountDisplayProps {
  item: Api.TabItem | Api.PublicTabItem
  listingUuid?: string | null
}

export const ItemAmountDisplay = forwardRef(
  (
    {item, listingUuid, as: Comp = 'span', className, ...restProps},
    forwardedRef,
  ) => {
    const amountType = getItemAmountType(item)

    return (
      <Comp
        ref={forwardedRef}
        className={WebUI.cn(
          `ItemAmountDisplay ItemAmountDisplay--${amountType}`,
          className,
        )}
        {...restProps}
      >
        {formatItemAmount(item, {listingUuid})}
      </Comp>
    )
  },
) as ForwardRefComponent<'span', ItemAmountDisplayProps>

// MARK: – Helpers

export const getItemAmountType = (item: Api.TabItem | Api.PublicTabItem) => {
  if (item.options.variants?.enabled) {
    return 'listing'
  }
  if (item.options.recurring?.enabled) {
    return 'recurring'
  }
  return item.amount_type
}

export const formatItemAmount = (
  item: Api.TabItem | Api.PublicTabItem,
  options?: {listingUuid?: string | null},
) => {
  const amountType = getItemAmountType(item)

  switch (amountType) {
    case 'open':
      return 'Enter Amount'
    case 'fixed':
      return Util.formatAmount(item.amount ?? 0)
    case 'listing': {
      if (options?.listingUuid) {
        const listing = item.options.variants?.listings.find(
          (l) => l.uuid === options.listingUuid,
        )
        return Util.formatAmount(listing?.amount ?? 0)
      }

      const [minAmount, maxAmount] = getListingsAmountRange(item)

      if (minAmount === maxAmount) {
        return Util.formatAmount(minAmount)
      }
      return `${Util.formatAmount(minAmount)} - ${Util.formatAmount(maxAmount)}`
    }
    case 'recurring':
      return `${Util.formatAmount(item.amount ?? 0)} / ${getItemRecurringPeriod(
        item,
      )}`
  }
}

export const getListingsAmountRange = (
  item: Api.TabItem | Api.PublicTabItem,
) => {
  if (!item.options.variants?.enabled) {
    throw new Error('`variants` is not enabled')
  }

  const listingAmounts = [
    ...(item.options.variants?.listings.map((l) => l.amount) ?? []),
  ]

  return [Math.min(...listingAmounts), Math.max(...listingAmounts)] as const
}

const getItemRecurringPeriod = (item: Api.TabItem | Api.PublicTabItem) => {
  if (
    !item.options.recurring?.enabled ||
    !item.options.recurring.options?.repeatInterval
  ) {
    throw new Error('Item is not recurring')
  }

  const duration = Util.parseDuration(
    item.options.recurring.options.repeatInterval,
  )
  const repeatCount = duration.months || duration.days / 7
  const num = repeatCount === 1 ? '' : ` ${repeatCount} `
  const unit = duration.months ? 'month' : 'week'

  return `${num}${unit}${repeatCount === 1 ? '' : 's'}`
}
