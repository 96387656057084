import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {cva} from 'class-variance-authority'

import {NavLinkButton, NavLinkButtonProps} from './LinkButton'

export type SlideOutNavVariant = 'default' | 'secondary'

export const slideOutNav = cva(
  [
    `[&_>_.SlideOutNavLinkButton]:h-14 [&_>_.SlideOutNavLinkButton]:justify-start
    [&_>_.SlideOutNavLinkButton]:rounded-none`,
    `[&_>_.SlideOutNavLinkButton_.Button-content]:block
    [&_>_.SlideOutNavLinkButton_.Button-content]:text-left
    [&_>_.SlideOutNavLinkButton_.Button-content]:transition-[min-width]
    [&_>_.SlideOutNavLinkButton_.Button-content]:duration-200`,
  ],
  {
    variants: {
      variant: {
        default: [
          'bg-teal-50',
          '[&_>_.SlideOutNavLinkButton_.Button-content]:text-natural-95',
          '[&_>_.SlideOutNavLinkButton_.Button-iconBefore]:text-natural-95',
          '[&_>_.SlideOutNavLinkButton:hover]:bg-accent300',
          '[&_>_.SlideOutNavLinkButton[aria-current=page]]:bg-accent300',
        ],
        secondary: [
          'bg-teal-70',
          '[&_>_.SlideOutNavLinkButton_.Button-iconBefore]:text-teal-50',
          '[&_>_.SlideOutNavLinkButton:hover]:bg-teal-80',
          '[&_>_.SlideOutNavLinkButton[aria-current=page]]:bg-teal-80',
        ],
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface SlideOutNavProps
  extends WebUI.ToolbarProps,
    WebUI.VariantsProps<typeof slideOutNav> {
  variant?: SlideOutNavVariant
  collapsable?: boolean
  isCollapsed?: boolean
}

export const SlideOutNav = forwardRef<WebUI.ToolbarInstance, SlideOutNavProps>(
  (
    {
      variant = 'default',
      collapsable,
      isCollapsed,
      className,
      ...restProps
    }: SlideOutNavProps,
    forwardedRef,
  ) => (
    <WebUI.Toolbar
      ref={forwardedRef}
      aria-label="Navigation"
      aria-expanded={!isCollapsed}
      className={WebUI.cn(
        'SlideOutNav',
        slideOutNav({variant}),
        '[&_>_.SlideOutNavLinkButton_>_.Button-iconBefore]:text-[28px] [&_>_.SlideOutNavLinkButton_>_.Button-iconBefore]:transition-[margin-right] [&_>_.SlideOutNavLinkButton_>_.Button-iconBefore]:duration-200',
        isCollapsed || collapsable
          ? '[&_>_.SlideOutNavLinkButton_.Button-content]:min-w-0 [&_>_.SlideOutNavLinkButton_.Button-content]:max-w-0'
          : '[&_>_.SlideOutNavLinkButton_.Button-content]:min-w-[140px] [&_>_.SlideOutNavLinkButton_.Button-content]:max-w-none',
        isCollapsed || collapsable
          ? '[&_>_.SlideOutNavLinkButton_>_.Button-iconBefore]:!mr-0'
          : '[&_>_.SlideOutNavLinkButton_>_.Button-iconBefore]:!mr-4',
        collapsable &&
          '[&_.SlideOutNavLinkButton_>_.Button-iconBefore]:hover:!mr-4 hover:z-10 [&_.SlideOutNavLinkButton_>_.Button-content]:hover:min-w-[180px] [&_.SlideOutNavLinkButton_>_.Button-content]:hover:max-w-none',
        className,
      )}
      orientation="vertical"
      {...restProps}
    />
  ),
)

// MARK: – SlideOutNavLinkButton

export const SlideOutNavLinkButton = forwardRef<
  HTMLAnchorElement,
  NavLinkButtonProps
>(({className, ...restProps}, forwardedRef) => (
  <NavLinkButton
    ref={forwardedRef}
    className={WebUI.cn('SlideOutNavLinkButton', className)}
    {...restProps}
  />
))
