import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import React, {useEffect, useState} from 'react'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {UpgradeTag} from 'src/components/UpgradeTag'

export interface ItemFormImagesFieldProps {
  collectionId: number
  images: any[]
  onChange: (newImages: any[]) => void
}

const ItemFormImagesField = ({
  collectionId,
  images,
  onChange,
}: ItemFormImagesFieldProps) => {
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const [selectedImageIdx, setSelectedImageIdx] = useState(
    images.length > 0 ? 0 : -1,
  )
  const selectedImageAsBlob = WebUI.useImageAsBlob(
    images[selectedImageIdx]?.image.preview,
    images[selectedImageIdx]?.contentType,
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    setSelectedImageIdx(images.findIndex((image) => !!image))
  }, [images.length])

  const renderImageThumbnail = (idx: number) => (
    <ItemImageThumbnail
      key={idx}
      premium={idx > 0 && !tabQuery.data?.is_pro}
      main={idx === 0}
      selected={idx === selectedImageIdx}
      crop={images[idx]?.thumbnailCrop}
      imageSrc={images[idx]?.image?.preview}
      imageType={images[idx]?.contentType}
      onClick={(event) => {
        if (images[idx]) {
          event.stopPropagation()
          setSelectedImageIdx(images[idx] ? idx : -1)
        }
      }}
      onChangeImage={(newImage) => {
        if (newImage) {
          ;(newImage as any).preview = URL.createObjectURL(newImage)
        }

        const nextValue = Util.pureInsert(images, idx, {
          ...images[idx],
          image: newImage,
          id: Util.makeShortId(),
          contentType: newImage.type ?? '',
          thumbnailCrop: null,
        })

        onChange(nextValue)

        if (nextValue.filter((i) => i != null).length !== 1) {
          setSelectedImageIdx(idx)
        }
      }}
    />
  )

  return (
    <WebUI.VStack className="gap-3">
      <WebUI.ImageEditor
        className="w-[400px]"
        toolbarTitle={
          <>
            Shows initial thumbnail view.
            <br />
            Payers can click to see full image.
          </>
        }
        image={selectedImageAsBlob}
        initialCrop={images[selectedImageIdx]?.thumbnailCrop ?? null}
        onApplyCrop={(newThumbnailCrop) => {
          if (newThumbnailCrop !== null) {
            if (selectedImageIdx >= 0) {
              onChange(
                Util.pureInsert(images, selectedImageIdx, {
                  ...images[selectedImageIdx],
                  thumbnailCrop: newThumbnailCrop,
                }),
              )
            }
          }
        }}
        onChangeImage={(newImage) => {
          if (newImage) {
            ;(newImage as any).preview = URL.createObjectURL(newImage)
          }

          if (images.length === 0) {
            const nextValue = Util.pureInsert(images, 0, {
              image: newImage,
              id: Util.makeShortId(),
              contentType: newImage?.type ?? '',
              thumbnailCrop: null,
            })

            onChange(nextValue)
          } else {
            const nextValue = Util.pureInsert(
              images,
              selectedImageIdx,
              newImage
                ? {
                    ...images[selectedImageIdx],
                    id: Util.makeShortId(),
                    contentType: newImage.type ?? '',
                    image: newImage,
                  }
                : null,
            ).filter((img) => !!img)
            onChange(nextValue)
          }
        }}
      />

      <div className="max-w-[408px] [&_.ItemImageThumbnail]:float-left [&_.ItemImageThumbnail]:m-2">
        <WebUI.DragAndDrop
          collisionDetection={WebUI.closestCorners}
          onDragEnd={({active, over}) => {
            if (!over) {
              return
            }
            const nextValue = WebUI.arrayMove(
              images,
              active.id as any,
              over.id as any,
            )
            onChange(nextValue)
          }}
        >
          <WebUI.SortableContext
            strategy={WebUI.rectSwappingStrategy}
            items={images.map((_image, idx) => idx)}
          >
            {images.map((_image, idx) => (
              <WebUI.Sortable
                key={String(idx)}
                id={idx}
                as={ItemImageThumbnail}
                premium={!tabQuery.data?.is_pro && idx > 0}
                main={idx === 0}
                selected={idx === selectedImageIdx}
                crop={images[idx]?.thumbnailCrop}
                imageSrc={images[idx]?.image?.preview}
                imageType={images[idx]?.contentType}
                onClick={(event) => {
                  if (images[idx]) {
                    event.stopPropagation()
                    setSelectedImageIdx(images[idx] ? idx : -1)
                  }
                }}
                onChangeImage={(newImage) => {
                  const nextValue = Util.pureInsert(images, idx, {
                    ...images[idx],
                    image: newImage,
                    id: Util.makeShortId(),
                    contentType: newImage.type ?? '',
                    thumbnailCrop: null,
                  })

                  onChange(nextValue)

                  if (nextValue.filter((i) => i != null).length !== 1) {
                    setSelectedImageIdx(idx)
                  }
                }}
              />
            ))}
          </WebUI.SortableContext>
        </WebUI.DragAndDrop>
        {!tabQuery.data?.is_pro &&
        images.filter((i) => i != null).length === 1 &&
        tabQuery.data?.status !== 'draft' ? (
          <WebUI.VStack
            className={
              'm-2 h-[120px] w-[120px] items-center justify-center overflow-hidden border p-2'
            }
          >
            <LinkButton
              className={
                'h-full w-full [&_>_.Button-content]:whitespace-normal [&_>_.Button-content]:font-light [&_>_.Button-content]:text-ds-xs'
              }
              variant="secondary"
              to="i/plans"
            >
              Go PRO to add more images
            </LinkButton>
          </WebUI.VStack>
        ) : (
          renderImageThumbnail(images.length)
        )}
      </div>
    </WebUI.VStack>
  )
}

// MARK: – ItemImageThumbnail

interface ItemImageThumbnailProps
  extends React.ComponentPropsWithoutRef<'div'> {
  premium?: boolean
  main?: boolean
  imageSrc: string | null
  imageType: string | undefined
  crop: WebUI.ImageCropArea | null
  selected?: boolean
  onChangeImage: (newImage: Blob) => void
}

const ItemImageThumbnail = React.forwardRef<
  HTMLDivElement,
  ItemImageThumbnailProps
>(
  (
    {
      premium,
      main,
      imageSrc,
      imageType,
      crop,
      selected,
      onChangeImage,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [croppedImageBlobUrl, setCroppedImageBlobUrl] = useState<
      string | null
    >(null)

    useEffect(
      () => () => {
        if (croppedImageBlobUrl) {
          URL.revokeObjectURL(croppedImageBlobUrl)
        }
      },
      [croppedImageBlobUrl],
    )

    useEffect(() => {
      const updateCroppedImage = async () => {
        if (imageSrc) {
          let croppedImTool = await WebUI.ImTool.fromImage(imageSrc)

          if (crop) {
            croppedImTool = await croppedImTool.crop(
              crop.x,
              crop.y,
              crop.width,
              crop.height,
            )
          }

          const blobUrl = await croppedImTool.toBlobURL(imageType)
          setCroppedImageBlobUrl(blobUrl)
        } else {
          setCroppedImageBlobUrl(null)
        }
      }

      updateCroppedImage()
    }, [imageSrc, imageType, crop])

    return (
      <WebUI.FileUploader
        accept={{'image/*': []}}
        disabled={!!croppedImageBlobUrl}
        onDropAccepted={async ([acceptedFile]) => {
          if (!acceptedFile) {
            return
          }

          const file = await WebUI.resetImageOrientation(acceptedFile)
          onChangeImage(file)
        }}
      >
        <WebUI.FileUploaderInput />
        <WebUI.VStack
          ref={forwardedRef}
          aria-selected={selected}
          className={WebUI.cn(
            'ItemImageThumbnail',
            'relative h-[120px] w-[120px] cursor-pointer justify-center gap-2 border bg-gray100 p-2 aria-selected:bg-natural-70',
            className,
          )}
          style={!croppedImageBlobUrl && {backgroundColor: 'transparent'}}
          variant="text"
          as={croppedImageBlobUrl ? ('div' as any) : WebUI.FileUploaderButton}
          {...restProps}
        >
          {croppedImageBlobUrl ? (
            <img
              className="h-full w-full overflow-hidden bg-natural-95 object-cover [user-drag:none]"
              alt="Cropped preview"
              src={croppedImageBlobUrl}
            />
          ) : (
            <WebUI.PhosphorIcon
              className="text-ds-5xl opacity-50"
              icon="camera"
            />
          )}
          {main && croppedImageBlobUrl && (
            <div className="text-center font-light text-ds-xs">Main Photo</div>
          )}

          {premium && (
            <UpgradeTag className="absolute top-1 right-3" plan="pro" />
          )}
        </WebUI.VStack>
      </WebUI.FileUploader>
    )
  },
)

export default ItemFormImagesField
