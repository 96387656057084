import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import type {DonationItemFormFormik} from './DonationItemForm'

export interface DonationItemFormDetailsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formik: DonationItemFormFormik
}

const DonationItemFormDetails: React.FC<DonationItemFormDetailsProps> = ({
  formik,
  collectionId,
  className,
  ...restProps
}) => {
  const categoriesQuery = api.tabCategories.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.VStack className="gap-4">
        <WebUI.VStack className="gap-4">
          <WebUI.FormFieldGroup>
            <WebUI.FormField
              label="Donation Name:"
              error={formik.errors.name}
              required
            >
              <WebUI.Input
                className="max-w-[340px]"
                name="name"
                placeholder="Donation Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            {categoriesQuery.data && categoriesQuery.data.length > 0 && (
              <WebUI.FormField label="Category" error={formik.errors.parent_id}>
                <WebUI.Select
                  className="[&_>_.Select-select]:min-h-10"
                  name="parent_id"
                  value={formik.values.parent_id ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Add to Category</option>
                  {categoriesQuery.data.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </WebUI.Select>
              </WebUI.FormField>
            )}
          </WebUI.FormFieldGroup>
        </WebUI.VStack>

        <WebUI.VStack className="gap-4">
          <WebUI.Switch
            name="options.donation.suggestedAmount.enabled"
            size="compact"
            checked={formik.values.options.donation?.suggestedAmount.enabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            tabIndex={-1}
          >
            Suggest a donation amount
          </WebUI.Switch>
          {formik.values.options.donation?.suggestedAmount.enabled && (
            <WebUI.FormField
              label="Suggested Amount:"
              error={formik.errors.options?.donation?.suggestedAmount?.value}
              required
            >
              <WebUI.AmountInput
                className="max-w-[340px]"
                name="options.donation.suggestedAmount.value"
                placeholder="$0"
                value={formik.values.options.donation.suggestedAmount.value}
                onValueChange={(newAmount) =>
                  formik.setFieldValue(
                    'options.donation.suggestedAmount.value',
                    newAmount,
                  )
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          )}
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default DonationItemFormDetails
