import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'

export const marketplaceCatalogSchema = z.object({
  access_code: z.string(),
  created_at: z.string(),
  display_order: z.number().nullish(),
  header: z.any().nullable(),
  id: z.number(),
  itemCount: z.number(),
  logo: z.any().nullable(),
  name: z.string(),
  slug: z.string(),
  user_id: z.number(),
})

export const marketplaceCollectionOrganizerSchema = z.object({
  created_at: z.string(),
  id: z.number(),
  display_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  name: z.string(),
  profilePic: s3ImageSchema.nullable(),
})

export const marketplaceCollectionSchema = z.object({
  access_code: z.string(),
  created_at: z.string(),
  display_order: z.number().nullish(),
  featured_image: s3ImageSchema.nullable(),
  id: z.number(),
  itemCount: z.number(),
  logo: z.any().nullable(),
  name: z.string(),
  organizer: marketplaceCollectionOrganizerSchema,
  slug: z.string(),
  user_id: z.number(),
})

export const marketplaceItemSchema = z.object({
  amount: z.number(),
  created_at: z.string(),
  description: z.string(),
  id: z.number(),
  images: s3ImageSchema.array(),
  name: z.string(),
  retailPrice: z.number(),
  tab_id: z.number(),
  updated_at: z.string(),
  collection: marketplaceCollectionSchema.array(),
})

declare global {
  namespace Api {
    type MarketplaceItem = z.infer<typeof marketplaceItemSchema>
    type MarketplaceCollection = z.infer<typeof marketplaceCollectionSchema>
    type MarketplaceCollectionOrganizer = z.infer<
      typeof marketplaceCollectionOrganizerSchema
    >
    type MarketplaceCatalog = z.infer<typeof marketplaceCatalogSchema>
    type MarketplaceOrganizer = z.infer<
      typeof marketplaceCollectionOrganizerSchema
    >
  }
}
