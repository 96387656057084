import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabInviteSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/invites',
    responseSchema: tabInviteSchema.array(),
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/invites/:inviteId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  remind: makeEndpoint({
    path: 'users/tabs/:tabId/invites/:inviteId/remind',
    method: 'POST',
    responseSchema: z.any(),
  }),
}
