import {z} from '@cheddarup/util'

import {tabObjectPartnerSchema} from './item-catalogs'
import {tabFormSchema} from './tab-forms'
import {tabObjectFieldSchema} from './fields'

export const formTemplateSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  theme_id: z.number().nullish(),
  user: tabObjectPartnerSchema.pick({
    id: true,
    name: true,
    first_name: true,
    last_name: true,
  }),
  active_forms: tabFormSchema
    .pick({id: true, name: true, description: true, position: true})
    .extend({
      fields: tabObjectFieldSchema
        .pick({
          field_type: true,
          id: true,
          metadata: true,
          name: true,
          position: true,
          required: true,
          values: true,
        })
        .array(),
    })
    .array(),
})

declare global {
  namespace Api {
    type FormTemplate = z.infer<typeof formTemplateSchema>
  }
}
