import {z} from '@cheddarup/util'

import {groupPageSponsorSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  detail: makeEndpoint({
    path: 'users/group_page/sponsor/sponsor_items/:id',
    responseSchema: groupPageSponsorSchema,
  }),
  create: makeEndpoint({
    path: 'users/group_page/sponsor/sponsor_items',
    method: 'POST',
    bodySchema: z.object(groupPageSponsorSchema.shape).deepPartial(),
    responseSchema: groupPageSponsorSchema,
  }),
  update: makeEndpoint({
    path: 'users/group_page/sponsor/sponsor_items/:id',
    method: 'PATCH',
    bodySchema: z.object(groupPageSponsorSchema.shape).deepPartial(),
    responseSchema: groupPageSponsorSchema,
  }),
  delete: makeEndpoint({
    path: 'users/group_page/sponsor/sponsor_items/:id',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  deleteLogo: makeEndpoint({
    path: 'users/group_page/sponsor/sponsor_items/:sponsorId/delete_image/:imageId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
