import {NumericFormat, NumericFormatProps} from 'react-number-format'
import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Input} from './Input'
import {cn} from '../utils'

export interface NumberInputProps
  extends Omit<NumericFormatProps, 'size' | 'customInput'> {}

export const NumberInput = forwardRef(
  (
    {
      valueIsNumericString = true,
      as: Comp = Input,
      size,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <NumericFormat
      ref={forwardedRef}
      className={cn('NumberInput', className)}
      customInput={Comp as any}
      size={size as any}
      valueIsNumericString={valueIsNumericString}
      {...restProps}
    />
  ),
) as ForwardRefComponent<typeof Input, NumberInputProps>
