import 'react-phone-number-input/style.css'
import PhoneNumberInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/min'
import type {
  Country as CountryCode,
  Props as PhoneNumberInputProps,
} from 'react-phone-number-input'
import React from 'react'
import {useControlledState} from '@cheddarup/react-util'

import {Input} from './Input'
import {
  DropdownSelect,
  DropdownSelectInstance,
  DropdownSelectOption,
  DropdownSelectProps,
} from './DropdownSelect'
import {HStack} from './Stack'
import {cn} from '../utils'

export type {CountryCode}
export interface PhoneInputProps
  extends Omit<
    PhoneNumberInputProps<React.ComponentPropsWithoutRef<'input'>>,
    'value' | 'onChange'
  > {
  value?: string
  defaultValue?: string
  onChange?: (value?: string) => void
  allowNull?: boolean
}

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      defaultValue = '+1',
      value: valueProp,
      onChange,
      countries = availableCountries,
      className,
      allowNull = false,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [value, setValue] = useControlledState(
      valueProp,
      defaultValue,
      onChange,
    )
    return (
      <PhoneNumberInput
        ref={forwardedRef as any}
        className={cn('PhoneInput', className)}
        inputComponent={Input}
        countryCallingCodeEditable={false}
        countries={countries}
        countrySelectComponent={PhoneCountrySelect}
        defaultCountry="US"
        international
        limitMaxLength
        value={value as any}
        onChange={(newPhoneNumber) => {
          if (newPhoneNumber != null || allowNull) {
            setValue(newPhoneNumber ?? '')
          }
        }}
        {...restProps}
      />
    )
  },
)

// MARK: – PhoneCountrySelect

interface PhoneCountrySelectProps
  extends Omit<DropdownSelectProps<string>, 'onChange'> {
  iconComponent: React.ComponentType<{
    country: string
    label: string
    aspectRatio: number
  }>
  options: Array<{value: string; label: string}>
  onChange?: (newValue: string | undefined) => void
}

const PhoneCountrySelect = React.forwardRef<
  DropdownSelectInstance<any>,
  PhoneCountrySelectProps
>(
  (
    {iconComponent: Icon, options, onChange, className, ...restProps},
    forwardedRef,
  ) => (
    <DropdownSelect<string>
      ref={forwardedRef}
      className={cn(
        'PhoneCountrySelect',
        '[&_>_.Select-select]:min-w-0 [&_>_.Select-select]:bg-transparent [&_>_.Select-select]:text-ds-lg [&_>_.Select-select]:shadow-none',
        '[&_>_.Select-select_>_.DropdownSelectButton-content]:mr-4',
        '[&_>_.Select-select_>_.DropdownSelectButton-content_>_.PhoneInputCountryIcon]:bg-transparent [&_>_.Select-select_>_.DropdownSelectButton-content_>_.PhoneInputCountryIcon]:shadow-none',
        '[&_>_.Select-arrow]:text-ds-xs',
        className,
      )}
      {...restProps}
      onValueChange={(newValue) => onChange?.(newValue)}
    >
      {options.map((option) => {
        const countryFlagIcon = (
          <Icon country={option.value} label={option.label} aspectRatio={1} />
        )

        return (
          <DropdownSelectOption
            key={option.value}
            value={option.value}
            displayTitle={countryFlagIcon}
          >
            <HStack
              className={
                'gap-2 [&_>_.PhoneInputCountryIcon]:bg-transparent [&_>_.PhoneInputCountryIcon]:shadow-none'
              }
            >
              {countryFlagIcon}
              <span>{option.label}</span>
            </HStack>
          </DropdownSelectOption>
        )
      })}
    </DropdownSelect>
  ),
)

// MARK: – Helpers

export const availableCountries: CountryCode[] = [
  'AT',
  'BB',
  'BE',
  'CA',
  'DK',
  'FI',
  'FR',
  'DE',
  'GR',
  'GU',
  'IS',
  'IE',
  'IT',
  'KR',
  'MC',
  'MX',
  'NL',
  'NO',
  'PT',
  'ES',
  'SE',
  'CH',
  'GB',
  'US',
]

export {parsePhoneNumber, isPossiblePhoneNumber}
