import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {
  CheckoutFlowStep,
  useActiveCheckoutStep,
  useCheckoutFlowActions,
  useCheckoutSteps,
} from './CheckoutPageFlowContext'

export interface CheckoutSteppedPanelProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  expanded?: boolean
  heading?: React.ReactNode
  step: CheckoutFlowStep
  children?:
    | ((
        state: {
          isActive: boolean
          isCompleted: boolean
        },
        flowActions: ReturnType<typeof useCheckoutFlowActions>,
      ) => React.ReactNode)
    | React.ReactNode
}

export const CheckoutSteppedPanel = ({
  expanded,
  step,
  heading,
  className,
  children,
  ...restProps
}: CheckoutSteppedPanelProps) => {
  const steps = useCheckoutSteps()
  const activeStep = useActiveCheckoutStep()
  const flowActions = useCheckoutFlowActions()

  const isVisible = activeStep === step
  const isCompleted = steps.indexOf(step) < steps.indexOf(activeStep)

  return (
    <WebUI.Panel
      data-active={isVisible}
      data-completed={isCompleted}
      className={WebUI.cn('CheckoutSteppedPanel', 'px-8 py-6', className)}
      variant="capsule"
      {...restProps}
    >
      <WebUI.Disclosure className="gap-4" visible={expanded ?? isVisible}>
        {(disclosure) => (
          <>
            {!!heading && (
              <WebUI.Ellipsis className="text-ds-md leading-none">
                {heading}
              </WebUI.Ellipsis>
            )}
            {typeof children === 'function' &&
              !disclosure.visible &&
              children({isActive: false, isCompleted}, flowActions)}
            <WebUI.DisclosureContent renderLazily={false}>
              {typeof children === 'function'
                ? children({isActive: true, isCompleted}, flowActions)
                : children}
            </WebUI.DisclosureContent>
          </>
        )}
      </WebUI.Disclosure>
    </WebUI.Panel>
  )
}
