import * as WebUI from '@cheddarup/web-ui'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React from 'react'
import {
  api,
  useDeleteTabPaymentMutation,
  useSendReceiptTabPaymentMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {PayerOverview, PaymentNoteButtonDropdown} from 'src/components'
import {
  ItemVariantOptionValuesList,
  OrderSummaryLayout,
  OrderSummarySidebarHeader,
  PaymentFormTable,
  PaymentItemTable,
  PaymentOverview,
  PaymentSignupTable,
  SignupPaymentSummary,
  useSelectedPaymentObject,
  useSelectedTermAndHistoryTabItemId,
} from 'src/components/OrderSummaryLayout'
import OrderSummaryTermsHistory from 'src/components/OrderSummaryTermsHistory'
import {TicketOverviewCard} from 'src/components/TicketOverviewCard'
import * as Util from '@cheddarup/util'

import {PaymentObjectFieldsEdit} from './PaymentObjectFieldsEdit'

const OrderSummaryPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()

  const collectionId = Number(urlParams.collection)
  const paymentId = Number(urlParams.payment)

  const paymentQuery = api.tabPayments.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      paymentId: urlParams.payment!,
    },
  })
  const {data: collectionName} = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (collection) => collection.name,
    },
  )
  const [selectedPaymentObject] = useSelectedPaymentObject()
  const [selectedTermAndHistoryTabItemId] = useSelectedTermAndHistoryTabItemId()

  const pis = paymentQuery.data?.payment_items ?? []
  const paymentItems =
    (pis.filter((pi) => !!pi.tab_item) as Api.TabItemPayment[]) ?? []
  const paymentForms =
    (pis.filter((pi) => !!pi.tab_form) as Api.TabFormPayment[]) ?? []
  const paymentSignups = Util.sort(
    pis.filter((pi) => !!pi.time_slot) as Api.TabSignupPayment[],
  ).asc((pts) => pts.time_slot.options.startTime)
  const selectedTabObject =
    selectedPaymentObject?.tab_form ?? selectedPaymentObject?.tab_item

  return (
    <OrderSummaryLayout
      onDidHide={() => navigate({pathname: '..', search: location.search})}
      sidebar={
        selectedPaymentObject ? (
          <WebUI.VStack
            className={
              'gap-4 *:border-b *:pb-4 [&_>*:last-child]:border-b-0 [&_>_*&:last-child:not(.TicketOverviewCard)]:pb-0'
            }
          >
            <OrderSummarySidebarHeader />
            {selectedPaymentObject.detail?.itemType === 'ticket' ? (
              <>
                <WebUI.VStack className="gap-2">
                  <WebUI.Text className="text-ds-sm">Payment by</WebUI.Text>
                  <PayerOverview
                    avatarHidden
                    tabMember={paymentQuery.data?.tab_member}
                  />
                </WebUI.VStack>
                <TicketOverviewCard
                  collectionId={collectionId}
                  paymentObject={selectedPaymentObject as Api.TabItemPayment}
                />
              </>
            ) : (
              <>
                {!!selectedTabObject?.description && (
                  <WebUI.MarkdownParagraph
                    className="[&_>_.RichTextEditor]:text-ds-sm"
                    markdown={selectedTabObject.description}
                  />
                )}
                {selectedPaymentObject.time_slot && (
                  <SignupPaymentSummary
                    className="text-ds-sm"
                    paymentItem={selectedPaymentObject as Api.TabSignupPayment}
                  />
                )}
                <PaymentObjectFieldsEdit
                  className="text-ds-sm [&_.RichTextEditor]:line-clamp-[8]"
                  collectionId={collectionId}
                  paymentId={paymentId}
                  paymentObject={selectedPaymentObject}
                />
                <ItemVariantOptionValuesList />
              </>
            )}
          </WebUI.VStack>
        ) : selectedTermAndHistoryTabItemId ? (
          <OrderSummaryTermsHistory
            payment={paymentQuery.data}
            tabItemId={selectedTermAndHistoryTabItemId}
          />
        ) : undefined
      }
    >
      <WebUI.VStack className="gap-4 px-4 py-8 sm:p-8">
        <WebUI.Heading as="h3">Order Summary</WebUI.Heading>
        <WebUI.HStack className="gap-3">
          {paymentQuery.data?.can_delete && <DeletePaymentAlert />}
          <LinkButton
            variant="default"
            target="_blank"
            to={`/pdf/collection/${urlParams.collection}/tab-payment/${urlParams.payment}`}
          >
            Print
          </LinkButton>
          {paymentQuery.data && (
            <ResendPaymentReceiptButton payment={paymentQuery.data} />
          )}
        </WebUI.HStack>
      </WebUI.VStack>

      <WebUI.Separator variant="primary" />

      {!!paymentQuery.data && (
        <>
          <PayerOverview
            className="p-6 sm:p-8"
            payment={paymentQuery.data}
            tabMember={paymentQuery.data.tab_member}
            editable
            footer={<PaymentNote payment={paymentQuery.data} />}
          />

          <WebUI.Separator variant="primary" />

          <PaymentOverview
            className="px-4 py-8 sm:p-8"
            paymentType="tab"
            collectionName={collectionName ?? ''}
            payment={paymentQuery.data}
          />
        </>
      )}

      {!!paymentQuery.data && (
        <WebUI.VStack className="gap-8 sm:p-8">
          {paymentItems.length > 0 && (
            <PaymentItemTable
              payment={paymentQuery.data}
              paymentItems={paymentItems}
              shippingInfo={paymentQuery.data.shipping_info}
              refunds={paymentQuery.data.refunds ?? []}
            />
          )}
          {paymentForms.length > 0 && <PaymentFormTable data={paymentForms} />}
          {paymentSignups.length > 0 && (
            <PaymentSignupTable
              collectionId={collectionId}
              data={paymentSignups}
            />
          )}
        </WebUI.VStack>
      )}
    </OrderSummaryLayout>
  )
}

// MARK: – ResendPaymentReceiptButton

interface ResendPaymentReceiptButtonContainerProps
  extends React.ComponentPropsWithoutRef<'button'> {
  payment: Api.TabPayment
}

const ResendPaymentReceiptButton = ({
  payment,
  ...restProps
}: ResendPaymentReceiptButtonContainerProps) => {
  const sendReceiptMutation = useSendReceiptTabPaymentMutation()
  const growlActions = WebUI.useGrowlActions()
  return (
    <WebUI.Button
      variant="secondary"
      loading={sendReceiptMutation.isPending}
      onClick={async () => {
        if (payment.tab_member.email) {
          await sendReceiptMutation.mutateAsync({
            pathParams: {
              tabId: payment.tab_id,
              paymentId: payment.id,
            },
            body: {email: payment.tab_member.email},
          })
          growlActions.show('success', {
            title: 'Receipt is sent',
            body: `An email receipt is on its way to ${payment.tab_member.email}.`,
          })
        } else {
          growlActions.show('error', {
            title: 'No email address',
            body: 'No email address has been provided for this payer.',
          })
        }
      }}
      {...restProps}
    >
      Resend Receipt
    </WebUI.Button>
  )
}

// MARK: – DeletePaymentAlert

const DeletePaymentAlert = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'button'>) => {
  const urlParams = useParams<{collection: string; payment: string}>()
  const collectionId = Number(urlParams.collection)
  const paymentId = Number(urlParams.payment)
  const deletePaymentMutation = useDeleteTabPaymentMutation()

  return (
    <WebUI.Alert
      aria-label="Delete payment confirmation"
      disclosure={
        <WebUI.DeprecatedTooltip label="Delete record">
          <WebUI.IconButton
            className={WebUI.cn('text-ds-xl text-gray400', className)}
            size="default_alt"
            variant="secondary"
            as={WebUI.DialogDisclosure}
            {...restProps}
          >
            <WebUI.PhosphorIcon icon="trash-fill" />
          </WebUI.IconButton>
        </WebUI.DeprecatedTooltip>
      }
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>Delete Payment Record</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text="This will permanently delete payment record along with any associated form information. Are you sure?"
            actions={
              <>
                <WebUI.AlertActionButton
                  execute={async () => {
                    await deletePaymentMutation.mutate({
                      pathParams: {
                        tabId: collectionId,
                        paymentId,
                      },
                    })
                    dialog.hide()
                  }}
                >
                  Delete
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </>
      )}
    </WebUI.Alert>
  )
}

// MARK: – PaymentNote

interface PaymentNoteProps extends React.ComponentPropsWithoutRef<'div'> {
  payment: Api.TabPayment
}

const PaymentNote = ({payment, ...restProps}: PaymentNoteProps) => (
  <div {...restProps}>
    {!!payment.note && <div className="text-ds-sm italic">{payment.note}</div>}
    <PaymentNoteButtonDropdown
      className="text-ds-sm"
      variant="link"
      payment={payment}
    >
      {payment.note ? 'Edit Note' : 'Add Note'}
    </PaymentNoteButtonDropdown>
  </div>
)

export default OrderSummaryPage
