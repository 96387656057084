import {api} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useDeleteHeaderImageMutation} from '@cheddarup/api-client'
import React, {useMemo} from 'react'
import {SharpImage} from './SharpImage'

export interface AlbumImagePickerFormValues {
  imageId: number | null
}

export interface AlbumImagePickerFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  active?: boolean
  images: AlbumImage[]
  initialImageId?: number | null
  onSubmit?: (imageId: number) => void
}

export const AlbumImagePickerForm = ({
  active,
  images,
  initialImageId,
  onSubmit,
  children,
  ...restProps
}: AlbumImagePickerFormProps) => {
  const toolbarEl = document.getElementById('image-picker-toolbar')
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik<AlbumImagePickerFormValues>({
    initialValues: {
      imageId: initialImageId ?? null,
    },
    onSubmit: (values) => {
      if (values.imageId == null) {
        growlActions.show('error', {
          title: 'Error',
          body: 'Pick an image to proceed',
        })
        return
      }

      onSubmit?.(Number(values.imageId))
    },
  })

  return (
    <form
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <AlbumImageRadioGroup
        className="h-full"
        name="imageId"
        data={images}
        defaultState={formik.initialValues.imageId ?? undefined}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      {children}

      {active !== false && (
        <WebUI.Portal
          className="flex flex-row items-center justify-end gap-3 bg-natural-100 p-4"
          portalElement={toolbarEl}
        >
          <span className="font-normal text-ds-sm">
            By proceeding, you agree that your images do not violate copyright
            permissions.
          </span>
          <WebUI.Button
            // the button is rendered outside of form, type="submit" would not work here
            type="button"
            size="large"
            variant="primary"
            onClick={() => formik.submitForm()}
          >
            Save
          </WebUI.Button>
        </WebUI.Portal>
      )}
    </form>
  )
}

// MARK: – AlbumImageRadioGroup

type AlbumImage = Util.SetRequired<Api.S3Image, 'id'>

interface AlbumImageRadioGroupProps
  extends Util.Merge<
      React.ComponentPropsWithoutRef<'div'>,
      WebUI.RadioGroupProps
    >,
    Pick<WebUI.VirtualizedListProps<AlbumImage>, 'data'>,
    Pick<AlbumImageListRowProps, 'aspectRatio'> {}

export const AlbumImageRadioGroup = ({
  aspectRatio,
  data,
  ...restProps
}: AlbumImageRadioGroupProps) => {
  const EnhancedAlbumImageListRow: WebUI.ListRowComponentType<AlbumImage> =
    useMemo(
      () =>
        React.forwardRef((rowProps, forwardedRef) => (
          <AlbumImageListRow
            ref={forwardedRef}
            aspectRatio={aspectRatio}
            {...rowProps}
          />
        )),
      [aspectRatio],
    )

  return (
    <WebUI.RadioGroup aria-label="Album image picker" {...restProps}>
      <WebUI.VirtualizedList
        className="p-8 [&_>_.List-rowsContainer]:gap-8"
        data={data}
        RowComponent={EnhancedAlbumImageListRow}
      />
    </WebUI.RadioGroup>
  )
}

// MARK: – AlbumImageListRow

interface AlbumImageListRowProps
  extends WebUI.ListRowComponentProps<AlbumImage> {
  aspectRatio?: number
}

const AlbumImageListRow = React.forwardRef<
  HTMLDivElement,
  AlbumImageListRowProps
>(({aspectRatio, data, index, className, ...restProps}, forwardedRef) => {
  const width = 420
  const height =
    width / (aspectRatio ?? data.metadata.pintura?.cropAspectRatio ?? 3 / 1)
  const deleteHeaderImageMutation = useDeleteHeaderImageMutation()
  return (
    <div
      ref={forwardedRef}
      className={WebUI.cn('flex flex-row items-center gap-3', className)}
      {...restProps}
    >
      <WebUI.Radio value={String(data.id)}>
        <SharpImage
          className="rounded-default"
          alt="Album item"
          width={width}
          height={height}
          image={data}
          errorFallback={
            <div className="flex h-full w-full items-center justify-center rounded-default border bg-natural-80 text-center text-contentSecondary">
              Failed to load
            </div>
          }
        />
      </WebUI.Radio>

      {data.editable && (
        <WebUI.IconButton
          className="text-ds-lg text-gray600"
          variant="outlined"
          size="default_alt"
          onClick={() => {
            // TODO: delete the header – waiting for API
            deleteHeaderImageMutation.mutate({
              pathParams: {
                imageId: data.id,
              },
            })
          }}
        >
          <WebUI.PhosphorIcon icon="trash-fill" />
        </WebUI.IconButton>
      )}
    </div>
  )
})

// MARK: – Helpers

export function usePartnerAlbumsQuery() {
  return api.headerImages.list.useQuery(undefined, {
    select: (headers) => {
      const partners = Util.uniqueBy(
        headers
          .map((h) => h.partner)
          .filter(
            (partner): partner is NonNullable<typeof partner> =>
              partner != null,
          ),
        (partner) => partner.id,
      )

      return [
        {
          key: 'my-album',
          name: 'My Album',
          images: headers.filter((header) => !header.partner),
        },
        ...partners.map((partner) => ({
          key: `partner-album-${partner.id}`,
          id: partner.id,
          name: partner.name,
          images: headers.filter((h) => h.partner?.id === partner.id),
        })),
      ].filter((p) => p.images.length > 0)
    },
  })
}
