import {z} from '@cheddarup/util'

import {tabObjectFieldViewSchema} from './field-views'
import {itemTypeSchema} from './tab-items'

export const tabPaymentRefundableItemSchema = z.object({
  payment_item_id: z.number(),
  name: z.string(),
  refundableQuantity: z.number(),
  refundableTotal: z.number(),
  perItemPrice: z.number(),
  perItemDiscount: z.number(),
  perItemNet: z.number(),
  itemType: itemTypeSchema,
  item_field_views: tabObjectFieldViewSchema.array(),
})

export const tabPaymentRefundableDataSchema = z.object({
  items: tabPaymentRefundableItemSchema.array(),
  shipping: z.number(),
  taxes: z
    .object({
      name: z.string(),
      amount: z.number(),
    })
    .array(),
  totalRefundable: z.number(),
  accountFeesRefundable: z.number(),
})

declare global {
  namespace Api {
    type TabPaymentRefundableData = z.infer<
      typeof tabPaymentRefundableDataSchema
    >
    type TabPaymentRefundableItem = z.infer<
      typeof tabPaymentRefundableItemSchema
    >
  }
}
