import * as Yup from 'yup'
import {FormikHelpers, FormikState} from 'formik'
import * as WebUI from '@cheddarup/web-ui'
import {
  ForwardRefComponent,
  genericForwardRef,
  useFormik,
} from '@cheddarup/react-util'
import {api} from '@cheddarup/api-client'
import React, {useImperativeHandle} from 'react'

import AccountNumberCanImage from '../images/AccountNumberCan.png'
import ECheckAccountNumberHelpImage from '../images/ECheckAccountNumberHelp.jpg'
import ECheckRoutingNumberHelpImage from '../images/ECheckRoutingNumberHelp.jpg'
import InstitutionNumberCanImage from '../images/InstitutionNumberCan.png'
import TransitNumberCanImage from '../images/TransitNumberCan.png'

export type BankAccountFormInstance<TValues> = FormikState<TValues> &
  FormikHelpers<TValues>

export type BankAccountFormProps =
  | BankAccountFormUSProps
  | BankAccountFormCAProps

export const BankAccountForm = genericForwardRef(
  <TCurrency extends Api.Currency = 'cad' | 'usd'>(
    {
      className,
      ...restProps
    }: TCurrency extends 'cad'
      ? BankAccountFormCAProps & {
          ref?: React.Ref<BankAccountFormInstance<BankAccountFormCAValues>>
        }
      : BankAccountFormUSProps & {
          ref?: React.Ref<BankAccountFormInstance<BankAccountFormUSValues>>
        },
    forwardedRef: React.Ref<
      BankAccountFormInstance<
        TCurrency extends 'cad'
          ? BankAccountFormCAValues
          : BankAccountFormUSValues
      >
    >,
  ) => {
    const currencyQuery = api.auth.session.useQuery(undefined, {
      select: (session) => session.user.currency,
    })

    if (!currencyQuery.data) {
      return null
    }

    const FormComp = {
      cad: BankAccountFormCA,
      usd: BankAccountFormUS,
    }[currencyQuery.data]

    return (
      <FormComp
        ref={forwardedRef as any}
        className={WebUI.cn('BankAccountForm', className)}
        {...(restProps as any)}
      />
    )
  },
)

// MARK: – BankAccountFormUS

export interface BankAccountFormUSValues {
  nickName: string
  routingNumber: string
  confirmRoutingNumber: string
  accountNumber: string
  confirmAccountNumber: string
}

interface BankAccountFormUSProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  onSubmit: (
    values: BankAccountFormUSValues,
    formikHelpers: FormikHelpers<BankAccountFormUSValues>,
  ) => void
}

const BankAccountFormUS = React.forwardRef<
  BankAccountFormInstance<BankAccountFormUSValues>,
  BankAccountFormUSProps
>(({onSubmit, ...restProps}, forwardedRef) => {
  const formik = useFormik<BankAccountFormUSValues>({
    validationSchema: Yup.object().shape({
      nickName: Yup.string().required('Required'),
      routingNumber: Yup.string()
        .required('Required')
        .length(
          9,
          (params) => `Routing number should be ${params.length} digits`,
        ),
      confirmRoutingNumber: Yup.string()
        .oneOf(
          [Yup.ref('routingNumber')],
          'Numbers must match. Please correct your entry.',
        )
        .required('Required'),
      accountNumber: Yup.string()
        .required('Required')
        .min(4, 'Account number should be between 4 and 20 digits')
        .max(20, 'Account number should be between 4 and 20 digits'),
      confirmAccountNumber: Yup.string()
        .oneOf(
          [Yup.ref('accountNumber')],
          'Numbers must match. Please correct your entry.',
        )
        .required('Required'),
    }),
    initialValues: {
      nickName: '',
      routingNumber: '',
      confirmRoutingNumber: '',
      accountNumber: '',
      confirmAccountNumber: '',
    },
    onSubmit,
  })

  useImperativeHandle(forwardedRef, () => formik, [formik])

  return (
    <form onSubmit={formik.handleSubmit} {...restProps}>
      <WebUI.VStack as={WebUI.Panel} className="gap-6 py-6">
        <FormFieldSection heading="Nickname">
          <WebUI.FormField
            label="Account Nickname"
            error={formik.errors.nickName}
          >
            <WebUI.Input
              name="nickName"
              placeholder="Account Nickname"
              autoFocus
              value={formik.values.nickName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        </FormFieldSection>
        <WebUI.Separator />
        <FormFieldSection
          heading="Routing Number"
          faq={
            <WebUI.Tooltip>
              <WebUI.TooltipAnchor className="text-ds-sm text-tint">
                Where do I find my routing number?
              </WebUI.TooltipAnchor>
              <WebUI.TooltipContent>
                <WebUI.Image
                  src={ECheckRoutingNumberHelpImage}
                  width={240}
                  height={240}
                  alt="ECheck routing number help"
                />
              </WebUI.TooltipContent>
            </WebUI.Tooltip>
          }
        >
          <WebUI.FormFieldGroup>
            <WebUI.FormField
              label="Routing Number"
              error={formik.errors.routingNumber}
            >
              <WebUI.PatternInput
                name="routingNumber"
                placeholder="Confirm Routing Number"
                format="#########"
                value={formik.values.routingNumber}
                onValueChange={(newValues) =>
                  formik.setFieldValue('routingNumber', newValues.value)
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            <WebUI.FormField
              label="Confirm Routing Number"
              error={formik.errors.confirmRoutingNumber}
            >
              <WebUI.PatternInput
                name="confirmRoutingNumber"
                placeholder="Routing Number"
                format="#########"
                value={formik.values.confirmRoutingNumber}
                onValueChange={(newValues) =>
                  formik.setFieldValue('confirmRoutingNumber', newValues.value)
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          </WebUI.FormFieldGroup>
        </FormFieldSection>
        <WebUI.Separator />
        <FormFieldSection
          heading="Account Number"
          faq={
            <WebUI.Tooltip>
              <WebUI.TooltipAnchor className="text-ds-sm text-tint">
                Where do I find my account number?
              </WebUI.TooltipAnchor>
              <WebUI.TooltipContent>
                <WebUI.Image
                  src={ECheckAccountNumberHelpImage}
                  width={240}
                  height={240}
                  alt="ECheck routing number help"
                />
              </WebUI.TooltipContent>
            </WebUI.Tooltip>
          }
        >
          <WebUI.FormFieldGroup>
            <WebUI.FormField
              label="Account Number"
              error={formik.errors.accountNumber}
            >
              <WebUI.NumberInput
                name="accountNumber"
                placeholder="Account Number"
                allowLeadingZeros
                allowNegative={false}
                decimalScale={0}
                isAllowed={(values) => values.value.length <= 16}
                value={formik.values.accountNumber}
                onValueChange={(newValues) =>
                  formik.setFieldValue('accountNumber', newValues.value)
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            <WebUI.FormField
              label="Confirm Account Number"
              error={formik.errors.confirmAccountNumber}
            >
              <WebUI.NumberInput
                name="confirmAccountNumber"
                placeholder="Confirm Account Number"
                allowLeadingZeros
                allowNegative={false}
                decimalScale={0}
                isAllowed={(values) => values.value.length <= 16}
                value={formik.values.confirmAccountNumber}
                onValueChange={(newValues) =>
                  formik.setFieldValue('confirmAccountNumber', newValues.value)
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          </WebUI.FormFieldGroup>
        </FormFieldSection>

        <WebUI.HStack className="p-6 pb-0">
          <WebUI.Button
            variant="primary"
            className="self-start px-8"
            type="submit"
            loading={formik.isSubmitting}
          >
            Save
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.VStack>
    </form>
  )
})

// MARK: - FormFieldsSection

export interface FormFieldSectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading: string
  faq?: React.ReactNode
}

export const FormFieldSection = ({
  heading,
  faq,
  className,
  children,
  ...restProps
}: FormFieldSectionProps) => (
  <WebUI.VStack className={WebUI.cn('gap-3 px-6', className)} {...restProps}>
    <WebUI.VStack className="gap-1">
      <WebUI.Text className="font-extrabold text-ds-sm">{heading}</WebUI.Text>
      {faq}
    </WebUI.VStack>
    {children}
  </WebUI.VStack>
)

// MARK: – BankAccountFormCA

export interface BankAccountFormCAValues {
  nickName: string
  transitNumber: string
  confirmTransitNumber: string
  institutionNumber: string
  confirmInstitutionNumber: string
  accountNumber: string
  confirmAccountNumber: string
}

interface BankAccountFormCAProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  onCancel?: () => void
  onSubmit: (
    values: BankAccountFormCAValues,
    formikHelpers: FormikHelpers<BankAccountFormCAValues>,
  ) => void
}

const BankAccountFormCA = React.forwardRef<
  BankAccountFormInstance<BankAccountFormCAValues>,
  BankAccountFormCAProps
>(({onSubmit, onCancel, className, ...restProps}, forwardedRef) => {
  const formik = useFormik<BankAccountFormCAValues>({
    validationSchema: Yup.object().shape({
      nickName: Yup.string().required('Required'),
      transitNumber: Yup.string()
        .required('Required')
        .length(
          5,
          (params) => `Transit number should be ${params.length} digits`,
        ),
      confirmTransitNumber: Yup.string()
        .required('Required')
        .oneOf(
          [Yup.ref('transitNumber')],
          'Numbers must match. Please correct your entry.',
        ),
      institutionNumber: Yup.string()
        .required('Required')
        .length(
          3,
          (params) => `Institution number should be ${params.length} digits`,
        ),
      confirmInstitutionNumber: Yup.string()
        .required('Required')
        .oneOf(
          [Yup.ref('institutionNumber')],
          'Numbers must match. Please correct your entry.',
        ),
      accountNumber: Yup.string()
        .required('Required')
        .min(4, 'Account number should be between 4 and 20 digits')
        .max(20, 'Account number should be between 4 and 20 digits'),
      confirmAccountNumber: Yup.string()
        .required('Required')
        .oneOf(
          [Yup.ref('accountNumber')],
          'Numbers must match. Please correct your entry.',
        ),
    }),
    initialValues: {
      nickName: '',
      transitNumber: '',
      confirmTransitNumber: '',
      institutionNumber: '',
      confirmInstitutionNumber: '',
      accountNumber: '',
      confirmAccountNumber: '',
    },
    onSubmit,
  })

  useImperativeHandle(forwardedRef, () => formik, [formik])

  return (
    <WebUI.Form
      className={WebUI.cn(
        '[&_.FormFieldGroup_.FormField-label]:max-w-full [&_.FormFieldGroup_.FormField-label]:overflow-hidden [&_.FormFieldGroup_.FormField-label]:text-ellipsis [&_.FormFieldGroup_.FormField-label]:whitespace-nowrap',
        className,
      )}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.FormField
        className="sm:w-[calc(50%-theme(spacing.4)/2)]"
        label="Account Nickname"
        error={formik.errors.nickName}
      >
        <WebUI.Input
          name="nickName"
          autoFocus
          placeholder="Nickname"
          value={formik.values.nickName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormFieldGroup>
        <WebUI.FormField
          label="Transit Number"
          error={formik.errors.transitNumber}
        >
          <InputWithTooltip
            name="transitNumber"
            placeholder="Transit Number"
            tooltipContent={
              <WebUI.Image
                src={TransitNumberCanImage}
                alt="Transit number help"
                width={240}
                height={240}
              />
            }
            value={formik.values.transitNumber}
            as={WebUI.PatternInput}
            format="#####"
            onValueChange={(newValues) =>
              formik.setFieldValue('transitNumber', newValues.value)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField
          label="Confirm Transit Number"
          error={formik.errors.confirmTransitNumber}
        >
          <InputWithTooltip
            name="confirmTransitNumber"
            placeholder="Transit Number"
            tooltipContent={
              <WebUI.Image
                src={TransitNumberCanImage}
                alt="Transit number help"
                width={240}
                height={240}
              />
            }
            value={formik.values.confirmTransitNumber}
            as={WebUI.PatternInput}
            format="#####"
            onValueChange={(newValues) =>
              formik.setFieldValue('confirmTransitNumber', newValues.value)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormFieldGroup>
        <WebUI.FormField
          label="Institution Number"
          error={formik.errors.institutionNumber}
        >
          <InputWithTooltip
            name="institutionNumber"
            placeholder="Institution Number"
            tooltipContent={
              <WebUI.Image
                src={InstitutionNumberCanImage}
                alt="Institution number help"
                width={240}
                height={240}
              />
            }
            value={formik.values.institutionNumber}
            as={WebUI.PatternInput}
            format="###"
            onValueChange={(newValues) =>
              formik.setFieldValue('institutionNumber', newValues.value)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField
          label="Confirm Institution Number"
          error={formik.errors.confirmInstitutionNumber}
        >
          <InputWithTooltip
            name="confirmInstitutionNumber"
            placeholder="Institution Number"
            tooltipContent={
              <WebUI.Image
                src={InstitutionNumberCanImage}
                alt="Institution number help"
                width={240}
                height={240}
              />
            }
            value={formik.values.confirmInstitutionNumber}
            as={WebUI.PatternInput}
            format="###"
            onValueChange={(newValues) =>
              formik.setFieldValue('confirmInstitutionNumber', newValues.value)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormFieldGroup>
        <WebUI.FormField
          label="Account Number"
          error={formik.errors.accountNumber}
        >
          <InputWithTooltip
            name="accountNumber"
            placeholder="Account Number"
            tooltipContent={
              <WebUI.Image
                src={AccountNumberCanImage}
                alt="Account number help"
                width={240}
                height={240}
              />
            }
            value={formik.values.accountNumber}
            as={WebUI.NumberInput}
            allowLeadingZeros
            allowNegative={false}
            decimalScale={0}
            isAllowed={(values) => values.value.length <= 20}
            onValueChange={(newValues) =>
              formik.setFieldValue('accountNumber', newValues.value)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField
          label="Confirm Account Number"
          error={formik.errors.confirmAccountNumber}
        >
          <InputWithTooltip
            name="confirmAccountNumber"
            placeholder="Account Number"
            tooltipContent={
              <WebUI.Image
                src={AccountNumberCanImage}
                alt="Account number help"
                width={240}
                height={240}
              />
            }
            value={formik.values.confirmAccountNumber}
            as={WebUI.NumberInput}
            allowLeadingZeros
            allowNegative={false}
            decimalScale={0}
            isAllowed={(values) => values.value.length <= 20}
            onValueChange={(newValues) =>
              formik.setFieldValue('confirmAccountNumber', newValues.value)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.HStack className="items-center justify-between gap-4">
        <WebUI.Button type="submit" loading={formik.isSubmitting}>
          Save
        </WebUI.Button>
        <WebUI.Button
          type="button"
          variant="link"
          disabled={formik.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </WebUI.Button>
      </WebUI.HStack>
    </WebUI.Form>
  )
})

// MARK: – InputWithTooltip

export interface InputWithTooltipProps {
  tooltipAnchor?: React.ReactElement
  tooltipContent: React.ReactElement
}

export const InputWithTooltip = React.forwardRef(
  (
    {
      tooltipContent,
      tooltipAnchor = (
        <WebUI.PhosphorIcon
          className="text-ds-md text-gray400 leading-[0]"
          icon="question-fill"
        />
      ),
      as: Comp = WebUI.Input,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <div className={WebUI.cn('relative', className)}>
      <Comp
        ref={forwardedRef}
        className="w-full pr-[calc(theme(spacing.2)+theme(spacing.5)+theme(spacing.2))]"
        {...restProps}
      />
      <WebUI.Tooltip>
        <WebUI.TooltipAnchor
          render={(anchorProps) =>
            React.cloneElement(tooltipAnchor, {
              ...anchorProps,
              className: WebUI.cn(
                '-translate-y-1/2 absolute top-1/2 right-2 max-w-5',
                anchorProps.className,
                tooltipAnchor.props.className,
              ),
            })
          }
        />
        <WebUI.TooltipContent>{tooltipContent}</WebUI.TooltipContent>
      </WebUI.Tooltip>
    </div>
  ),
) as ForwardRefComponent<typeof WebUI.Input, InputWithTooltipProps>
