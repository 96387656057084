import {delay} from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useSignupMutation = makeUseMutation(
  endpoints.auth.signup,
  () => ({
    regular: (newSession) => [
      makeQueryUpdate(endpoints.auth.session, () => newSession),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          // prevent `usePublicCollection`'s refetch
          return (
            typeof path === 'string' &&
            path !== 'session' &&
            !path.startsWith('collections/')
          )
        },
      })
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          return (
            typeof path === 'string' &&
            path.startsWith('collections/') &&
            path.split('/').length === 2
          )
        },
      })
    },
  }),
)

export const useSignupWithTokenMutation = makeUseMutation(
  endpoints.auth.signupWithToken,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          // prevent `usePublicCollection`'s refetch
          return (
            typeof path === 'string' &&
            path !== 'session' &&
            !path.startsWith('collections/')
          )
        },
      })
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          return (
            typeof path === 'string' &&
            path.startsWith('collections/') &&
            path.split('/').length === 2
          )
        },
      })
    },
  }),
)

export const useLoginMutation = makeUseMutation(
  endpoints.auth.login,
  () => ({
    regular: (newSession) => [
      makeQueryUpdate(endpoints.auth.session, () => newSession),
    ],
  }),
  (queryClient) => ({
    onSuccess: async () => {
      // HACK: sometimes `onSuccess` fires so fast API responds with 401
      await delay(200)
      queryClient.resetQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          // prevent `usePublicCollection`'s refetch
          return (
            typeof path === 'string' &&
            path !== 'session' &&
            !path.startsWith('collections/')
          )
        },
      })
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          return (
            typeof path === 'string' &&
            path.startsWith('collections/') &&
            path.split('/').length === 2
          )
        },
      })
    },
  }),
)

export const useLoginWithTokenMutation = makeUseMutation(
  endpoints.auth.loginWithToken,
  undefined,
  (queryClient) => ({
    onSuccess: async () => {
      queryClient.resetQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          const isPublicCollectionQueryPath =
            typeof path === 'string' && path.startsWith('collections/')

          return !isPublicCollectionQueryPath
        },
      })
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          return (
            typeof path === 'string' &&
            path.startsWith('collections/') &&
            path.split('/').length === 2
          )
        },
      })
    },
  }),
)

export const useLogoutMutation = makeUseMutation(
  endpoints.auth.logout,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          // prevent `usePublicCollection`'s refetch
          return typeof path === 'string' && !path.startsWith('collections/')
        },
      })
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [path] = query.queryKey
          return (
            typeof path === 'string' &&
            path.startsWith('collections/') &&
            path.split('/').length === 2
          )
        },
      })
    },
  }),
)

export const useForgotPasswordMutation = makeUseMutation(
  endpoints.auth.forgotPassword,
)

export const useResetPasswordMutation = makeUseMutation(
  endpoints.auth.resetPassword,
)

export const useCheckEmailExistenceMutation = makeUseMutation(
  endpoints.auth.checkEmailExistence,
)

export const useSendGuestLoginLinkMutation = makeUseMutation(
  endpoints.auth.sendLoginLink,
)

export const useSendGuestLoginCodeMutation = makeUseMutation(
  endpoints.auth.sendLoginCode,
)

export const useLoginViaTokenMutation = makeUseMutation(
  endpoints.auth.loginViaToken,
  () => ({
    regular: (newSession) => [
      makeQueryUpdate(endpoints.auth.session, () => newSession),
    ],
  }),
  (queryClient) => ({
    onSuccess: async () => {
      queryClient.resetQueries({
        predicate: (query) => {
          const [queryPath] = query.queryKey
          // prevent `usePublicCollection`'s refetch
          return (
            typeof queryPath === 'string' &&
            queryPath !== 'session' &&
            !queryPath.startsWith('collections/')
          )
        },
      })
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [queryPath] = query.queryKey
          return (
            typeof queryPath === 'string' &&
            queryPath.startsWith('collections/') &&
            queryPath.split('/').length === 2
          )
        },
      })
    },
  }),
)

export const useVerifyCaptchaMutation = makeUseMutation(
  endpoints.auth.verifyCaptcha,
)

export const useTestCaptchaRequiredMutation = makeUseMutation(
  endpoints.auth.testCaptchaRequired,
)

export const useTestResetCaptchaMutation = makeUseMutation(
  endpoints.auth.testResetCaptcha,
)

export const useSendOneTimeLoginLinkMutation = makeUseMutation(
  endpoints.auth.sendOneTimeLoginLink,
)

export const useMailedSecurityCodeVerificationMutation = makeUseMutation(
  endpoints.auth.verifyMailedSecurityCode,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)

export const useConfirmPhoneNumberMutation = makeUseMutation(
  endpoints.auth.confirmPhoneNumber,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)
