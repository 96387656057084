import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {Link} from 'src/components/Link'

import {useFilteredItemViews} from '../../PayerPage/components/ItemPicker/ItemPicker'

export interface ItemViewHeaderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  item: Api.PublicTabItem
  collectionSlug: string
}

const ItemViewHeader = ({
  item,
  collectionSlug,
  className,
  ...restProps
}: ItemViewHeaderProps) => {
  const filteredItemViews = useFilteredItemViews()

  const itemViewIdx = filteredItemViews.findIndex((i) => i.id === item.id)
  const prevItemViewId = filteredItemViews[itemViewIdx - 1]?.id ?? null
  const nextItemViewId = filteredItemViews[itemViewIdx + 1]?.id ?? null

  if (!prevItemViewId && !nextItemViewId) {
    return null
  }

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'ItemViewHeader',
        'items-center justify-between gap-4 border-b px-4 py-4 font-normal text-ds-sm sm:justify-end sm:border-b-0 sm:px-8 sm:pb-2',
        className,
      )}
      {...restProps}
    >
      {(!!prevItemViewId || !!nextItemViewId) && (
        <WebUI.HStack className="gap-2">
          {prevItemViewId && (
            <Link preserveSearch to={`../item/${prevItemViewId}`}>
              {'<'} Prev
            </Link>
          )}
          {prevItemViewId && nextItemViewId && <span>|</span>}
          {nextItemViewId && (
            <Link preserveSearch to={`../item/${nextItemViewId}`}>
              Next {'>'}
            </Link>
          )}
        </WebUI.HStack>
      )}
    </WebUI.HStack>
  )
}

export default ItemViewHeader
