import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'

import {AddTopBannerForm} from './AddTopBannerForm'
import {GroupPageSectionHeader} from '../components'

const AddTopBannerPage = () => {
  const navigate = useNavigate()
  return (
    <WebUI.Modal
      aria-label="Top Banner form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-xl"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.ModalHeader>
            <GroupPageSectionHeader
              subheading="Set the tone with a headline and background image at the top of your Group Page."
              quickTourSlideId="top-banner"
            >
              Top Banner Section
            </GroupPageSectionHeader>
          </WebUI.ModalHeader>
          <AddTopBannerForm
            className="min-h-0 grow"
            onDidSucceed={() => dialog.hide()}
          />
        </>
      )}
    </WebUI.Modal>
  )
}

export default AddTopBannerPage
