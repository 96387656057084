import React, {useLayoutEffect, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import AddItemIcon from 'src/images/ItemIcon.svg'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import {api} from '@cheddarup/api-client'
import {parseItemType} from 'src/helpers/item-helpers'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'
import DonationLogo from 'src/images/Donation.svg'
import RecurringCalendar from 'src/images/Recurring-Calendar.svg'

import {ItemForm} from './containers/ItemForm'

const ItemFormPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [_itemType] = useQueryParam('type', withDefault(StringParam, 'fixed'))
  const alertRef = useRef<WebUI.DialogInstance>(null)
  const upgradeRequiredAlertRef = useRef<WebUI.DialogInstance>(null)
  const dialogDefaultHideRef = useRef<(() => void) | null>(null)
  const upgradeRequireCheckPerformedRef = useRef(false)
  const isFormDirtyRef = useRef(false)
  const {data: collection, isFetching: isCollectionFetching} =
    api.tabs.detail.useQuery({
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    })
  const itemQuery = api.tabItems.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        itemId: urlParams.item!,
      },
    },
    {
      enabled: urlParams.item != null,
    },
  )
  const fieldsQuery = api.fields.itemList.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        itemId: urlParams.item!,
      },
    },
    {enabled: urlParams.item != null},
  )

  let itemType = parseItemType(_itemType)
  if (itemType === 'ticket') {
    itemType = 'fixed'
  }

  useLayoutEffect(() => {
    if (
      !upgradeRequireCheckPerformedRef.current &&
      !itemQuery.data &&
      !isCollectionFetching
    ) {
      upgradeRequireCheckPerformedRef.current = true

      if (
        collection &&
        collection.status !== 'draft' &&
        collection.itemLimit != null &&
        !collection.is_pro &&
        collection.reportsAvailable.activeItemsCount >= collection.itemLimit
      ) {
        upgradeRequiredAlertRef.current?.show()
      }
    }
  }, [collection, isCollectionFetching, itemQuery.data])

  return (
    <PremiumFeaturesSideSheet
      tabId={collection?.id}
      enforcedPremiumMeta={{team: {recurringPayments: true}}}
    >
      <WebUI.Modal
        ref={(dialog) => {
          if (!dialog) {
            return
          }

          dialogDefaultHideRef.current = dialog.hide
          dialog.hide = () => {
            if (isFormDirtyRef.current) {
              alertRef.current?.show()
            } else {
              dialogDefaultHideRef.current?.()
            }
          }
        }}
        aria-label="Item form"
        className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
        initialVisible
        onDidHide={() => navigate('..')}
      >
        <WebUI.ModalCloseButton />

        <WebUI.ModalHeader className="border-b-0">
          <WebUI.PageHeader
            graphics={
              {
                fixed: <img src={AddItemIcon} alt="Item icon" />,
                donation: <img src={DonationLogo} alt="Donation" />,
                recurring: collection?.is_team && (
                  <img src={RecurringCalendar} alt="Repeating Calendar" />
                ),
              }[itemType]
            }
            subheading={
              {
                fixed:
                  'Fixed price items are anything someone can pay for (e.g., dues, t-shirt, registration).',
                donation:
                  'Donors can decide the amount they’d like to contribute.',
                recurring:
                  'Collect payments toward something over a period of time based on a set schedule or frequency.',
              }[itemType]
            }
          >
            <WebUI.HStack className="gap-4">
              {
                {
                  fixed: 'Fixed Price',
                  donation: 'Donation',
                  recurring: 'Recurring Plan',
                }[itemType]
              }
              {itemType === 'recurring' && !collection?.is_team && (
                <PremiumFeatureSideSheetDisclosure
                  featureKeys={['recurringPayments']}
                />
              )}
            </WebUI.HStack>
          </WebUI.PageHeader>
        </WebUI.ModalHeader>

        {(!urlParams.item ||
          (itemQuery.isSuccess && fieldsQuery.isSuccess)) && (
          <ItemForm
            className="grow"
            tabId={Number(urlParams.collection)}
            itemId={urlParams.item ? Number(urlParams.item) : undefined}
            itemType={itemType}
            onDirtyChange={(newIsDirty) => {
              isFormDirtyRef.current = newIsDirty
            }}
            onDismiss={() => dialogDefaultHideRef.current?.()}
          />
        )}

        <DirtyFormConfirmAlert
          ref={alertRef}
          onProceed={() => dialogDefaultHideRef.current?.()}
        />
      </WebUI.Modal>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – DirtyFormConfirmAlert

interface DirtyFormConfirmAlertProps extends WebUI.AlertProps {
  onProceed?: () => void
}

const DirtyFormConfirmAlert = React.forwardRef<
  WebUI.DialogInstance,
  DirtyFormConfirmAlertProps
>(({onProceed, className, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Confirm closing item modal"
    className={WebUI.cn('[&_.Alert-closeButton]:invisible', className)}
    {...restProps}
  >
    <WebUI.AlertHeader>
      Are you sure you want to close this item?
    </WebUI.AlertHeader>
    <WebUI.AlertContentView
      text="You haven’t saved your item and your information will be lost."
      actions={
        <>
          <WebUI.AlertActionButton onClick={() => onProceed?.()}>
            Close Item
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton />
        </>
      }
    />
  </WebUI.Alert>
))

export default ItemFormPage
