import {
  QueryFunctionContext as ReactQueryFunctionContext,
  QueryMeta as ReactQueryMeta,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
  useInfiniteQuery as useReactInfiniteQuery,
  useQuery as useReactQuery,
  useSuspenseQuery as useReactSuspenseQuery,
} from '@tanstack/react-query'
import {HasRequiredKeys, omitBy, z} from '@cheddarup/util'

import {
  AnyEndpoint,
  EndpointKey,
  GetEndpointKeyInput,
  fetchEndpoint,
  getEndpointKey,
} from '../utils'

export type QueryKey<TEndpoint extends AnyEndpoint> = EndpointKey<TEndpoint>

export interface QueryMeta extends ReactQueryMeta {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD'
}

export interface QueryFunctionContext<TEndpoint extends AnyEndpoint>
  extends Omit<
    ReactQueryFunctionContext<QueryKey<TEndpoint>, number>,
    'direction'
  > {
  meta: QueryMeta | undefined
}

export function useQuery<
  TEndpoint extends AnyEndpoint,
  TQueryFnData extends z.infer<TEndpoint['responseSchema']>,
  TError extends Error,
  TData = TQueryFnData,
>(
  [endpoint, endpointInput]: HasRequiredKeys<
    GetEndpointKeyInput<TEndpoint>
  > extends true
    ? [TEndpoint, GetEndpointKeyInput<TEndpoint>]
    : [TEndpoint, GetEndpointKeyInput<TEndpoint>?],
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, QueryKey<TEndpoint>>,
    'queryKey'
  >,
) {
  const headers = useApiHeaders?.()
  const queryKey = getEndpointKey(endpoint, {
    ...endpointInput,
    headers: omitBy(
      {
        ...endpointInput?.headers,
        ...headers,
      },
      (v) => v == null,
    ),
  })

  return useReactQuery<TQueryFnData, TError, TData, QueryKey<TEndpoint>>({
    queryKey,
    queryFn: (ctx) =>
      fetchEndpoint(endpoint, {signal: ctx.signal, ...ctx.queryKey[1]}),
    ...options,
  })
}

export function useSuspenseQuery<
  TEndpoint extends AnyEndpoint,
  TQueryFnData extends z.infer<TEndpoint['responseSchema']>,
  TError extends Error,
  TData = TQueryFnData,
>(
  [endpoint, endpointInput]: HasRequiredKeys<
    GetEndpointKeyInput<TEndpoint>
  > extends true
    ? [TEndpoint, GetEndpointKeyInput<TEndpoint>]
    : [TEndpoint, GetEndpointKeyInput<TEndpoint>?],
  options?: Omit<
    UseSuspenseQueryOptions<TQueryFnData, TError, TData, QueryKey<TEndpoint>>,
    'queryKey'
  >,
) {
  const queryKey = getEndpointKey(endpoint, endpointInput as any)

  return useReactSuspenseQuery<
    TQueryFnData,
    TError,
    TData,
    QueryKey<TEndpoint>
  >({
    queryKey,
    queryFn: (ctx) =>
      fetchEndpoint(endpoint, {signal: ctx.signal, ...ctx.queryKey[1]}),
    ...options,
  })
}

export function useInfiniteQuery<
  TEndpoint extends AnyEndpoint,
  TData = z.infer<TEndpoint['responseSchema']>,
  TQueryData = z.infer<TEndpoint['responseSchema']>,
>(
  [endpoint, endpointInput]: HasRequiredKeys<
    GetEndpointKeyInput<TEndpoint>
  > extends true
    ? [TEndpoint, GetEndpointKeyInput<TEndpoint>]
    : [TEndpoint, GetEndpointKeyInput<TEndpoint>?],
  options: Omit<
    UseInfiniteQueryOptions<
      z.infer<TEndpoint['responseSchema']>,
      unknown,
      TData,
      TQueryData,
      QueryKey<TEndpoint>,
      number
    >,
    'queryKey' | 'queryFn'
  >,
) {
  const queryKey = getEndpointKey(endpoint, endpointInput as any)

  return useReactInfiniteQuery({
    queryKey,
    queryFn: (ctx) =>
      infiniteQueryFetch(endpoint, {
        queryKey: ctx.queryKey,
        pageParam: ctx.pageParam,
        signal: ctx.signal,
        meta: options?.meta,
      }),
    ...options,
  })
}

function infiniteQueryFetch<TEndpoint extends AnyEndpoint>(
  endpoint: TEndpoint,
  {queryKey, pageParam = 1, ...rest}: QueryFunctionContext<TEndpoint>,
) {
  return fetchEndpoint(endpoint, {
    ...queryKey[1],
    queryParams: {
      ...(queryKey[1] as any).queryParams,
      page: pageParam,
    },
    ...rest,
  })
}
