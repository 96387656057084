import {z} from '@cheddarup/util'

import {signUpFieldSchema} from './fields'
import {signUpSpotSchema} from './tab-signup-spots'

export const signUpTypeSchema = z.enum(['list', 'schedule'])

export const signUpGroupedBySchema = z.enum(['spot', 'date'])

export const signUpSpotLimitSchema = z.object({
  enabled: z.boolean(),
  value: z.number(),
})

export const signUpSignupReminderSchema = z.object({
  enabled: z.boolean(),
  value: z.number(), // in seconds
})

export const signUpCancelBasedOnDateOptionsSchema = z.object({
  enabled: z.boolean(),
  value: z.number(), // in seconds
})

export const signUpOptionsComentSchema = z.object({
  enabled: z.boolean(),
  required: z.boolean(),
  fieldName: z.string(),
  hiddenFromPublicView: z.boolean(),
})

export const signUpOptionsSchema = z.object({
  comment: signUpOptionsComentSchema,
  timeZone: z.string(),
  signupType: signUpTypeSchema,
  groupedBy: signUpGroupedBySchema.nullish(),
  hideNamesFromPublicView: z.boolean(),
  perPersonSpotLimit: signUpSpotLimitSchema,
  allowParticipantToCancel: z.boolean().nullish(),
  cancelBasedOnDate: signUpCancelBasedOnDateOptionsSchema.nullish(),
  signupReminder: signUpSignupReminderSchema.nullish(),
  notifyMeWhenParticipantEdits: z.boolean().nullish(),
  signupListDate: z.string().nullish(),
  requireAtLeastOneSpot: z.boolean().nullish(),
})

export const signUpSchema = z.object({
  id: z.number(),
  tab_id: z.number(),
  name: z.string(),
  amount: z.number(),
  amount_type: z.literal('fixed'),
  allow_quantity: z.boolean(),
  deleted_at: z.string().nullish(),
  required: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  position: z.number(),
  anchor: z.string().nullish(),
  parent_id: z.number().nullish(),
  catalog_object_id: z.number().nullish(),
  description: z.string(),
  available_quantity: z.number().nullish(),
  hidden: z.boolean(),
  options: signUpOptionsSchema,
  tsv: z.string(),
  tsv_metaphone: z.string(),
  errors: z.record(z.string()),
  spots: signUpSpotSchema.array(),
  fields: signUpFieldSchema.array(),
  active_participant_count: z.number().optional(),
  is_pro: z.boolean(),
  is_team: z.boolean(),
  requires_pro: z.boolean(),
  requires_team: z.boolean(),
})

export const tabSignUpDetailedSchema = signUpSchema.extend({
  active_participant_count: z.number(),
})

declare global {
  namespace Api {
    type SignUp = z.infer<typeof signUpSchema>
    type TabSignUpDetailed = z.infer<typeof tabSignUpDetailedSchema>
    type SignUpOptions = z.infer<typeof signUpOptionsSchema>
    type SignUpOptionsComent = z.infer<typeof signUpOptionsComentSchema>
    type SignUpCancelBasedOnDateOptions = z.infer<
      typeof signUpCancelBasedOnDateOptionsSchema
    >
    type SignUpSignupReminder = z.infer<typeof signUpSignupReminderSchema>
    type SignUpSpotLimit = z.infer<typeof signUpSpotLimitSchema>
    type SignUpGroupedBy = z.infer<typeof signUpGroupedBySchema>
    type SignUpType = z.infer<typeof signUpTypeSchema>
  }
}
