import {z} from '@cheddarup/util'

import {tabMemberSchema} from './tab-members'
import {basePaymentItemSchema} from './base-payment-items'

export const timeSlotOptionsSchema = z.object({
  isSignupListDefaultSlot: z.boolean().nullish(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
})

export const timeSlotSchema = z.object({
  id: z.number(),
  name: z.string(),
  active_participant_count: z.number().nullish(),
  available_quantity: z.number().nullish(),
  options: timeSlotOptionsSchema,
  errors: z.record(z.string()),
  payment_items: basePaymentItemSchema
    .extend({
      payment: z.object({
        id: z.number(),
        created_at: z.string(),
        tab_member: tabMemberSchema,
      }),
    })
    .array(),
  position: z.number(),
})

declare global {
  namespace Api {
    type TimeSlot = z.infer<typeof timeSlotSchema>
    type TimeSlotOptions = z.infer<typeof timeSlotOptionsSchema>
  }
}
