import * as Yup from 'yup'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {FormikHandlers, FormikHelpers, FormikState} from 'formik'
import React, {useMemo} from 'react'
import {useDeleteHeaderMutation} from '@cheddarup/api-client'
import {SharpImage} from 'src/components/SharpImage'
import {useCroppedImage} from 'src/hooks/useCroppedImage'
import {useFormik} from '@cheddarup/react-util'

import ImageAlbumsSidebar from './ImageAlbumsSidebar'
import {useAlbums} from './hooks'

export interface ImageAlbumValues extends Record<string, any> {
  headerId: number | null
  uploadedImage: Blob | null
  uploadedImageCrop: Api.CropDetails | null
}

export interface ImageAlbumFormik
  extends FormikState<ImageAlbumValues>,
    FormikHelpers<ImageAlbumValues>,
    FormikHandlers {}

export interface ImageAlbumPageProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onHeaderSelect?: (header: Api.Header) => void
  onUpload?: () => void
  formik: ImageAlbumFormik
}

const ImageAlbumPage = ({
  formik: collectionFormik,
  onHeaderSelect,
  onUpload,
  className,
  ...restProps
}: ImageAlbumPageProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()
  const media = WebUI.useMedia()
  const {userAlbums, partnerAlbums} = useAlbums()

  const albumIdx = Number(urlParams.album) - 1
  const headers = [...userAlbums, ...partnerAlbums][albumIdx]?.images ?? []

  const formik = useFormik<{headerId: number | null}>({
    validationSchema: Yup.object().shape({
      headerId: Yup.string().required('Required'),
    }),
    initialValues: {
      headerId: null,
    },
    onSubmit: (values) => {
      if (typeof values.headerId === 'string') {
        if (values.headerId === 'UPLOAD') {
          onUpload?.()
        } else {
          const headerId = Number(values.headerId)
          const header = headers.find((h) => h.id === headerId)

          if (header) {
            onHeaderSelect?.(header)
          }
        }
      }
    },
  })

  const imageWithCrop = useMemo(
    () =>
      collectionFormik.values.uploadedImage &&
      collectionFormik.values.uploadedImageCrop
        ? {
            image: collectionFormik.values.uploadedImage,
            crop: collectionFormik.values.uploadedImageCrop,
          }
        : null,
    [
      collectionFormik.values.uploadedImage,
      collectionFormik.values.uploadedImageCrop,
    ],
  )

  return (
    <WebUI.Modal
      aria-label="Album image picker"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-h-full [&_>_.ModalContentView]:w-full [&_>_.ModalContentView]:rounded-none',
        className,
      )}
      onDidHide={() => navigate({pathname: '..', search: location.search})}
      {...restProps}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader>Add a Banner Image</WebUI.ModalHeader>

      <WebUI.Form
        className="flex min-h-0 grow [&_>_.Form-inner]:grow [&_>_.Form-inner]:gap-0"
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        <WebUI.VStack className="min-h-0 grow sm:flex-row">
          <ImageAlbumsSidebar className="flex-0 sm:flex-[0_1_320px]" />
          <WebUI.Separator
            orientation={media.sm ? 'vertical' : 'horizontal'}
            variant="primary"
          />
          <MyAlbum
            albumIdx={albumIdx}
            imageWithCrop={imageWithCrop}
            state={formik.values.headerId as any}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onDelete={() => {
              collectionFormik.setValues((prevValues) => ({
                ...prevValues,
                uploadedImage: null,
                uploadedImageCrop: null,
              }))
            }}
          />
        </WebUI.VStack>

        <WebUI.Separator variant="primary" />
        <WebUI.HStack className="justify-end gap-3 p-4">
          <WebUI.Button
            type="submit"
            size="large"
            variant="primary"
            disabled={formik.values.headerId == null}
          >
            Select
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.Form>
    </WebUI.Modal>
  )
}

// MARK: - MyAlbum

export interface MyAlbumProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange' | 'children'> {
  onDelete?: () => void
  albumIdx: number
  imageWithCrop?: {
    image: Blob
    crop: Api.CropDetails
  } | null
  type?: 'theme' | 'image'
}

export const MyAlbum: React.FC<MyAlbumProps> = ({
  onDelete,
  albumIdx,
  imageWithCrop,
  type = 'theme',
  className,
  ...restProps
}) => {
  const {userAlbums, partnerAlbums} = useAlbums()
  const headers = [...userAlbums, ...partnerAlbums][albumIdx]?.images ?? []
  const croppedUploadedImageUrl = useCroppedImage(imageWithCrop)
  const deleteHeaderMutation = useDeleteHeaderMutation()

  return (
    <WebUI.RadioGroup
      aria-label="Images"
      className={WebUI.cn('grow gap-8 overflow-y-auto sm:p-8', className)}
      name="headerId"
      size="large"
      {...restProps}
    >
      {croppedUploadedImageUrl && (
        <WebUI.Radio className="gap-5 sm:gap-10" value="UPLOAD">
          <img
            className="h-[150px] w-[450px] max-w-full overflow-hidden object-contain"
            alt="Album"
            src={croppedUploadedImageUrl}
          />
          <WebUI.IconButton
            className="text-gray600"
            type="button"
            size="default_alt"
            variant="outlined"
            onClick={onDelete}
          >
            <WebUI.PhosphorIcon icon="trash-fill" />
          </WebUI.IconButton>
        </WebUI.Radio>
      )}
      {headers?.map((header) => (
        <WebUI.Radio
          key={header.id}
          className="gap-5 sm:gap-10"
          value={type === 'theme' ? String(header.id) : String(header.image.id)}
        >
          <SharpImage
            className="max-w-full overflow-hidden object-contain"
            width={450}
            height={150}
            alt="Album image"
            image={header.image}
          />
          {header.editable && (
            <WebUI.IconButton
              className="text-gray600"
              type="button"
              size="default_alt"
              variant="outlined"
              onClick={() =>
                deleteHeaderMutation.mutate({
                  pathParams: {
                    headerId: header.id,
                  },
                })
              }
            >
              <WebUI.PhosphorIcon icon="trash-fill" />
            </WebUI.IconButton>
          )}
        </WebUI.Radio>
      ))}
    </WebUI.RadioGroup>
  )
}

export default ImageAlbumPage
