import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {PayerInfoPanels} from './PayerInfoPanels'
import {
  PayerContinueBand,
  PoweredByBadge,
  useIsPoweredByBadgeHidden,
} from './PayerContinueBand'

// MARK: – PayerObjectsLayout

export const PayerObjectsLayout: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({children, ...restProps}) => {
  const media = WebUI.useMedia()
  const isPoweredByBadgeHidden = useIsPoweredByBadgeHidden()

  return (
    <>
      <PayerContentContainerLayout {...restProps}>
        <PayerContentLayout className="grow justify-center">
          <PayerMainBlock className="flex-[1_0_0px]">{children}</PayerMainBlock>
          {media.lg && (
            <PayerInfoPanels className="max-w-[420px] flex-[1_1_0px]" />
          )}
        </PayerContentLayout>
        <PayerContinueBand
          className={WebUI.cn(
            'w-full sm:mx-4 sm:mt-0 lg:mx-8 lg:max-w-screen-xl',
            isPoweredByBadgeHidden ? '' : 'mt-16',
          )}
        />
      </PayerContentContainerLayout>
      {!isPoweredByBadgeHidden && (
        <div className="!z-[1] fixed inset-x-0 bottom-0 hidden h-9 flex-col items-center justify-center bg-natural-100 px-2 sm:flex lg:hidden">
          <PoweredByBadge />
        </div>
      )}
    </>
  )
}

// MARK: – PayerContentContainerLayout

export const PayerContentContainerLayout = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <WebUI.VStack
    ref={forwardedRef}
    className={WebUI.cn(
      'min-h-screen flex-[1_0_auto] items-center gap-4 bg-natural-100 sm:min-h-[85vh] sm:gap-8 sm:bg-inherit',
      className,
    )}
    {...restProps}
  />
))

// MARK: – PayerContentLayout

export const PayerContentLayout = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <WebUI.HStack
    ref={forwardedRef}
    className={WebUI.cn(
      'relative w-full max-w-[calc(1280px+theme(space.8)*2)] items-start gap-4 lg:gap-6 lg:px-8',
      className,
    )}
    {...restProps}
  />
))

// MARK: – PayerMainBlock

export interface PayerMainBlockProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const PayerMainBlock: React.FC<PayerMainBlockProps> = ({
  className,
  ...restProps
}) => (
  <WebUI.VStack
    className={WebUI.cn(
      'relative min-w-[320px] max-w-[836px] gap-4',
      className,
    )}
    {...restProps}
  />
)
