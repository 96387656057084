import {z} from '@cheddarup/util'

export const tabAttachmentSchema = z.object({
  id: z.number(),
  tab_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  file_name: z.object({
    url: z.string(),
  }),
})

declare global {
  namespace Api {
    type TabAttachment = z.infer<typeof tabAttachmentSchema>
  }
}
