import {useLiveRef} from '@cheddarup/react-util'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import {cn} from '../utils'

export interface CanvasProps extends React.ComponentPropsWithoutRef<'canvas'> {
  disabled?: boolean
  lineWidth?: number
  strokeStyle?: string
  onDataURLChange?: (dataURL: string | null) => void
  onIsEmptyChange?: (isEmpty: boolean) => void
}

export interface CanvasInstance {
  clear: () => void
  toDataURL: (type?: string, quality?: any) => string | undefined
  drawImage: CanvasDrawImage['drawImage']
  context?: CanvasRenderingContext2D | null
}

export const Canvas = React.forwardRef<CanvasInstance, CanvasProps>(
  (
    {
      className,
      'aria-disabled': ariaDisabled,
      disabled,
      lineWidth = 4,
      strokeStyle = '#000000',
      onDataURLChange,
      onIsEmptyChange,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
    const prevPosRef = useRef({offsetX: 0, offsetY: 0})
    const positionRef = useRef<DOMRect | null>(null)
    const isPaintingRef = useRef(false)
    const isEmptyRef = useRef(true)
    const onDataURLChangeRef = useLiveRef(onDataURLChange)
    const onIsEmptyChangeRef = useLiveRef(onIsEmptyChange)

    const ctxRef = useLiveRef(canvas?.getContext('2d'))

    if (canvas) {
      const boundingRect = canvas.getBoundingClientRect()
      positionRef.current = boundingRect
    }

    useImperativeHandle(
      forwardedRef,
      () => ({
        clear: () => {
          const position = positionRef.current
          if (!position) {
            return
          }

          ctxRef.current?.clearRect(0, 0, position.width, position.height)

          onDataURLChangeRef.current?.(null)

          if (!isEmptyRef.current) {
            isEmptyRef.current = true
            onIsEmptyChangeRef.current?.(true)
          }
        },
        toDataURL: (type, quality) => canvas?.toDataURL(type, quality),
        drawImage: ctxRef.current ? ctxRef.current.drawImage : () => {},
        context: ctxRef.current,
      }),
      [canvas],
    )

    const endPaintEvent = useCallback(() => {
      if (isPaintingRef.current) {
        onDataURLChangeRef.current?.(canvas?.toDataURL() ?? null)
      }

      isPaintingRef.current = false
    }, [canvas])

    useEffect(() => {
      document.addEventListener('mouseup', endPaintEvent)
      document.addEventListener('touchend', endPaintEvent)

      return () => {
        document.removeEventListener('mouseup', endPaintEvent)
        document.removeEventListener('touchend', endPaintEvent)
      }
    }, [endPaintEvent])

    const paint = (
      prevPos: {offsetX: number; offsetY: number},
      currPos: {offsetX: number; offsetY: number},
    ) => {
      if (disabled) {
        return
      }

      const ctx = ctxRef.current
      if (ctx) {
        ctx.lineJoin = 'round'
        ctx.lineCap = 'round'
        ctx.lineWidth = lineWidth
        ctx.strokeStyle = strokeStyle

        ctx.beginPath()
        ctx.moveTo(prevPos.offsetX, prevPos.offsetY)
        ctx.lineTo(currPos.offsetX, currPos.offsetY)
        ctx.stroke()

        prevPosRef.current = currPos
      }
    }

    return (
      <canvas
        ref={(ref) => {
          setCanvas(ref)
        }}
        aria-disabled={ariaDisabled ?? disabled}
        className={cn(
          'Canvas cursor-crosshair touch-none aria-disabled:pointer-events-none',
          className,
        )}
        width={positionRef.current?.width}
        height={positionRef.current?.height}
        onMouseDown={(event) => {
          isPaintingRef.current = true

          prevPosRef.current = {
            offsetX: event.nativeEvent.offsetX,
            offsetY: event.nativeEvent.offsetY,
          }
        }}
        onMouseMove={(event) => {
          if (isPaintingRef.current) {
            if (isEmptyRef.current) {
              isEmptyRef.current = false
              onIsEmptyChangeRef.current?.(false)
            }

            paint(prevPosRef.current, {
              offsetX: event.nativeEvent.offsetX,
              offsetY: event.nativeEvent.offsetY,
            })
          }
        }}
        onTouchStart={(event) => {
          isPaintingRef.current = true

          const position = positionRef.current
          const touch = event.nativeEvent.changedTouches[0]

          if (touch && position) {
            const {clientX, clientY} = touch
            prevPosRef.current = {
              offsetX: clientX - position.x,
              offsetY: clientY - position.y,
            }
          }
        }}
        onTouchMove={(event) => {
          if (isPaintingRef.current) {
            if (isEmptyRef.current) {
              isEmptyRef.current = false
              onIsEmptyChangeRef.current?.(false)
            }

            const position = positionRef.current
            const touch = event.nativeEvent.changedTouches[0]
            if (position && touch) {
              const {clientX, clientY} = touch
              paint(prevPosRef.current, {
                offsetX: clientX - position.x,
                offsetY: clientY - position.y,
              })
            }
          }
        }}
        onTouchEnd={endPaintEvent}
        onTouchCancel={endPaintEvent}
        {...restProps}
      />
    )
  },
)
