import React, {useContext, useRef} from 'react'
import {
  FieldGroup,
  FieldGroupType,
  FieldsEdit,
  FieldsEditInnerContext,
  FieldsEditProps,
} from 'src/components/FieldsEdit/FieldsEdit'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {FieldGroupTypePicker} from 'src/components/FieldsEdit/FieldGroupTypePicker'
import {FieldGroupEditList} from 'src/components/FieldsEdit/FieldGroupEditList'

import type {TicketFormFormik} from './TicketForm'

export interface TicketFormItemFieldsProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Omit<FieldsEditProps, 'children'>
  > {
  formik: TicketFormFormik
}

export const TicketFormFields: React.FC<TicketFormItemFieldsProps> = ({
  initialFieldSets,
  initialFields,
  onChange,
  onInit,
  className,
  formik,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const drawerRef = useRef<WebUI.DialogInstance>(null)

  const hiddenFieldGroupTypes: FieldGroupType[] = [
    'full_name',
    'email',
    'address',
    'text_multiline',
    'date',
    'time',
    'signature',
    'initials',
    'layout_divider',
    'layout_description',
    'file',
  ]

  return (
    <FieldsEdit
      initialFieldSets={initialFieldSets}
      initialFields={initialFields}
      onChange={(newFieldsEditValue) => {
        onChange(newFieldsEditValue)
        drawerRef.current?.hide()
      }}
      onInit={onInit}
    >
      <WebUI.HStack className={WebUI.cn('h-full', className)} {...restProps}>
        <FieldGroupTypePicker
          hiddenFieldGroupTypes={hiddenFieldGroupTypes}
          className="hidden sm:flex"
        />

        <WebUI.VStack className="grow gap-6 overflow-y-auto p-6 sm:px-11">
          <WebUI.Text className="font-light text-ds-base">
            Payers will be required to enter the first name, last name, and
            email for each ticket purchased.
          </WebUI.Text>
          <WebUI.FormFieldGroup>
            <WebUI.FormField label="First Name">
              <WebUI.Input readOnly placeholder="First Name" />
            </WebUI.FormField>
            <WebUI.FormField label="Last Name">
              <WebUI.Input readOnly placeholder="Last Name" />
            </WebUI.FormField>
          </WebUI.FormFieldGroup>
          <WebUI.FormField label="Email">
            <WebUI.Input readOnly placeholder="Email" />
          </WebUI.FormField>
          <WebUI.Separator className="-mx-11 mt-11" />
          <WebUI.Text className="font-light text-ds-base">
            You can include additional questions below:
          </WebUI.Text>
          <FieldGroupEditList className="h-auto overflow-y-visible" />
          <WithFieldGroups>
            {(fieldGroups) =>
              fieldGroups?.length > 0 && (
                <WebUI.Checkbox
                  name="options.showAttendeeResponseOnConfirmationEmail"
                  checked={
                    formik.values.options
                      .showAttendeeResponseOnConfirmationEmail
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  Show the attendee’s responses on their order confirmation and
                  ticket
                </WebUI.Checkbox>
              )
            }
          </WithFieldGroups>
          {!media.sm && (
            <WebUI.Drawer
              ref={drawerRef}
              disclosure={
                <WebUI.DialogDisclosure className="inline-flex flex-0 self-start">
                  Add Question
                </WebUI.DialogDisclosure>
              }
            >
              <FieldGroupTypePicker
                hiddenFieldGroupTypes={hiddenFieldGroupTypes}
                className="grow"
              />
            </WebUI.Drawer>
          )}
        </WebUI.VStack>
      </WebUI.HStack>
    </FieldsEdit>
  )
}

// MARK: – WithFieldGroups

interface WithFieldGroupsProps {
  children: (fieldGroups: FieldGroup[]) => React.ReactNode
}

const WithFieldGroups: React.FC<WithFieldGroupsProps> = ({children}) => {
  const contextValue = useContext(FieldsEditInnerContext)
  return <>{children(contextValue.fieldGroups)}</>
}
