import {BooleanParam, useQueryParam} from 'use-query-params'
import {useIsAuthed} from 'src/hooks/useAuthToken'

import {RecordPaymentMemberFormPanel} from '../CheckoutPage/RecordPaymentMemberFormPanel'
import {AuthedPanel} from '../CheckoutPage/AuthedPanel'
import {DefaultPayerAuth} from './DefaultPayerAuth'
import {
  useActiveCheckoutStep,
  useCheckoutFlowActions,
} from '../CheckoutPage/CheckoutPageFlowContext'
import useCart from '../hooks/useCart'
import * as WebUI from '@cheddarup/web-ui'
import CartHelpers from 'src/helpers/CartHelpers'
import usePublicCollection from '../hooks/usePublicCollection'

export const PayerAuthPanel = (
  props: React.ComponentPropsWithoutRef<'div'>,
) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const isAuthed = useIsAuthed()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const isActive = useActiveCheckoutStep() === 'auth'
  const flowActions = useCheckoutFlowActions()
  const [isGuest, setIsGuest] = WebUI.useSessionStorage(
    `${cart?.uuid}-guest-mode-on`,
    !!cart?.member?.name && !!cart?.member?.email,
  )

  const isQuickCheckout =
    !CartHelpers.isPaymentRequired({cart}) &&
    !publicCollection.shippingOptions.shipToEnabled

  if (addPayment) {
    return (
      <RecordPaymentMemberFormPanel
        onDidSubmit={() => flowActions.proceed()}
        {...props}
      />
    )
  }

  if ((isAuthed || isGuest || !isActive) && !isQuickCheckout) {
    return (
      <AuthedPanel
        onDidLogout={() => {
          flowActions.setActiveStep('auth')
          setIsGuest(false)
        }}
        onResetGuestMode={() => {
          flowActions.setActiveStep('auth')
          setIsGuest(false)
        }}
        onDidSubmitPhoneNumber={() => flowActions.proceed()}
        {...props}
      />
    )
  }

  return (
    <DefaultPayerAuth
      onDidContinueAsGuest={() => {
        setIsGuest(true)
        flowActions.proceed()
      }}
      onDidLogin={(session) => {
        if (session.user.profile.phone.fullNumber) {
          flowActions.proceed()
        }
      }}
      onDidSignup={() => flowActions.proceed()}
      {...props}
    />
  )
}
