import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import {useCallback, useEffect, useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useParams} from 'react-router-dom'
import {
  api,
  useCancelPaymentIntentMutation,
  useCreateCartFormMutation,
  useCreateCartItemMutation,
  useCreateCartMutation,
  useCreateCartTimeSlotMutation,
  useCreatePaymentIntentMutation,
  useUpdateCartMutation,
} from '@cheddarup/api-client'
import CartHelpers from 'src/helpers/CartHelpers'
import {resolveCartFieldValues} from 'src/components/FieldSetList'
import usePublicCollection from './usePublicCollection'
import * as Util from '@cheddarup/util'

const useCart = () => {
  const urlParams = useParams()
  const collectionSlug = urlParams.tabSlug as string
  const [loadCartFromUrl] = useQueryParam(
    'load-cart-from-url',
    withDefault(BooleanParam, false),
  )
  const [cartUuid, setCartUuid] = WebUI.useSessionStorage<string | null>(
    `CART_UUID-${collectionSlug}`,
    null,
  )
  // for support to see cart uuid
  const [cartUrlParam, setCartUuidQueryParam] = useQueryParam(
    'cart',
    withDefault(StringParam, cartUuid),
  )
  const cartQuery = api.carts.detail.useQuery(
    {
      pathParams: {
        tabId: collectionSlug,
        // biome-ignore lint/style/noNonNullAssertion:
        cartUuid: cartUuid!,
      },
    },
    {
      enabled: !!cartUuid,
    },
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (loadCartFromUrl && cartUrlParam) {
      setCartUuid(cartUrlParam)
    } else {
      setCartUuidQueryParam(cartUuid)
    }
  }, [cartUuid, cartUrlParam, loadCartFromUrl])

  useEffect(() => {
    if (cartQuery.isError) {
      setCartUuidQueryParam(null)
      setCartUuid(null)
    }
  }, [cartQuery.isError, setCartUuid, setCartUuidQueryParam])

  const value = useMemo(
    () => ({
      isPending: cartQuery.isPending,
      cart: cartQuery.data as Api.Cart | undefined,
      refetch: cartQuery.refetch,
      reset: () => setCartUuid(null),
    }),
    [cartQuery.isPending, cartQuery.data, cartQuery.refetch, setCartUuid],
  )

  return value
}

// MARK: – Enhanced cart mutations

export function useEnhancedUpdateCartMutation(
  ...args: Parameters<typeof useUpdateCartMutation>
) {
  const [, primitiveUpdateCartAsync] = usePrimitiveEnhancedUpdateCartMutation()
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const memberDataQuery = api.auth.session.useQuery(undefined, {
    enabled: !!addPayment,
    select: (session) => ({
      email: session.user.email,
      name: session.user.full_name,
    }),
  })
  const updateCartMutation = useUpdateCartMutation(...args)

  type UpdateCartMutationParameters = Parameters<
    (typeof updateCartMutation)['mutateAsync']
  >
  const updateCartAsync = useCallback(
    async (
      vars?: Omit<UpdateCartMutationParameters[0], 'pathParams'>,
      options?: UpdateCartMutationParameters[1],
    ) =>
      primitiveUpdateCartAsync(
        {
          ...vars,
          body: {
            ...(addPayment ? undefined : memberDataQuery.data),
            ...vars?.body,
          },
        },
        options,
      ),
    [primitiveUpdateCartAsync, memberDataQuery.data, addPayment],
  )

  return [updateCartMutation, updateCartAsync] as const
}

export const useEnhancedCreateCartItemMutation = makeEnhancedCartMutation(
  useCreateCartItemMutation,
)

export const useEnhancedCreateCartFormMutation = makeEnhancedCartMutation(
  useCreateCartFormMutation,
)

export const useEnhancedCreateCartTimeSlotMutation = makeEnhancedCartMutation(
  useCreateCartTimeSlotMutation,
)

export const useEnhancedCreatePaymentIntentMutation = makeEnhancedCartMutation(
  useCreatePaymentIntentMutation,
)

export const useEnhancedCancelPaymentIntentMutation = makeEnhancedCartMutation(
  useCancelPaymentIntentMutation,
)

export function useEnhancedResolveCartFieldValues() {
  const cartPathParams = useCartPathParams()
  const createCartIfNotExists = useCreateCartIfNotExists()
  const [, setCartUuid] = WebUI.useSessionStorage<string | null>(
    `CART_UUID-${cartPathParams.tabId}`,
    null,
  )

  const enhancedResolveCartFieldValues = useCallback(
    async (
      options: Omit<
        Parameters<typeof resolveCartFieldValues>[0],
        'collectionSlug' | 'cartUuid'
      >,
    ) => {
      const cartUuid = await createCartIfNotExists()

      const res = await resolveCartFieldValues({
        collectionSlug: cartPathParams.tabId,
        cartUuid,
        ...options,
      })

      setCartUuid(cartUuid)

      return res
    },
    [cartPathParams.tabId, createCartIfNotExists, setCartUuid],
  )

  return enhancedResolveCartFieldValues
}

// MARK: – Helpers

const usePrimitiveEnhancedUpdateCartMutation = makeEnhancedCartMutation(
  useUpdateCartMutation,
)

export function useCreateCartIfNotExists() {
  const [, createCartAsync] = useEnhancedCreateCartMutation()
  const cartPathParams = useCartPathParams()

  const createCartIfNotExists = useCallback(async () => {
    // `cartPathParams.cartUuid` is too slow sometimes,
    // may create race conditions
    const cartUuid = WebUI.readSessionStorageItem(
      `CART_UUID-${cartPathParams.tabId}`,
      null,
    )

    if (!cartUuid) {
      const cart = await createCartAsync()
      return cart.uuid
    }

    return cartUuid
  }, [cartPathParams.tabId, createCartAsync])

  return createCartIfNotExists
}

function useCartPathParams() {
  const urlParams = useParams()
  const collectionSlug = urlParams.tabSlug as string
  const [cartUuid] = WebUI.useSessionStorage<string | null>(
    `CART_UUID-${collectionSlug}`,
    null,
  )

  return useMemo(
    () => ({
      tabId: collectionSlug,
      cartUuid,
    }),
    [cartUuid, collectionSlug],
  )
}

function makeEnhancedCartMutation<
  T extends
    | typeof useUpdateCartMutation
    | typeof useCreateCartItemMutation
    | typeof useCreateCartFormMutation
    | typeof useCreateCartTimeSlotMutation
    | typeof useCreatePaymentIntentMutation
    | typeof useCancelPaymentIntentMutation,
>(useMutationHook: T) {
  function useEnhancedCartMutation(...args: Parameters<T>) {
    const mutation = useMutationHook(...(args as any))
    const cartPathParams = useCartPathParams()
    const createCartIfNotExists = useCreateCartIfNotExists()
    const [, setCartUuid] = WebUI.useSessionStorage<string | null>(
      `CART_UUID-${cartPathParams.tabId}`,
      null,
    )

    const originalMutateAsync = mutation.mutateAsync
    type MutateAsyncParameters = Parameters<ReturnType<T>['mutateAsync']>
    const enhancedMutateAsync = useCallback(
      async (
        vars?: Util.SetOptional<MutateAsyncParameters[0], 'pathParams'>,
        options?: MutateAsyncParameters[1],
      ) => {
        const cartUuid =
          vars?.pathParams?.cartUuid ?? (await createCartIfNotExists())

        const res = await originalMutateAsync(
          {
            body: vars?.body,
            pathParams: {
              ...cartPathParams,
              cartUuid,
            },
          },
          options as any,
        )

        setCartUuid(cartUuid)

        return res
      },
      [cartPathParams, createCartIfNotExists, originalMutateAsync, setCartUuid],
    )

    return [mutation, enhancedMutateAsync] as const
  }

  return useEnhancedCartMutation
}

function useEnhancedCreateCartMutation(
  ...args: Parameters<typeof useCreateCartMutation>
) {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const cartPathParams = useCartPathParams()
  const {publicCollection} = usePublicCollection()
  const createCartMutation = useCreateCartMutation(...args)
  const updateCartMutation = useUpdateCartMutation()

  const updateCartAsync = updateCartMutation.mutateAsync

  const originalCreateCartAsync = createCartMutation.mutateAsync
  type CreateCartMutationParameters = Parameters<
    (typeof createCartMutation)['mutateAsync']
  >
  const createCartAsync = useCallback(
    async (
      vars?: Omit<CreateCartMutationParameters[0], 'pathParams'>,
      options?: CreateCartMutationParameters[1],
    ) => {
      try {
        const cart = await originalCreateCartAsync(
          {
            ...vars,
            pathParams: {
              tabId: cartPathParams.tabId,
            },
            body: {
              ...vars?.body,
              record_payment: !!addPayment,
              branchName: window.netlify_ab_branch,
              shippingMethod: publicCollection?.shippingOptions.shipToEnabled
                ? 'toMe'
                : undefined,
              dripOptIn: true,
              paymentSource: 'web',
              pointOfSale: {
                usesPaymentIntents: true,
              },
            },
          },
          options,
        )

        return cart
      } catch (err: any) {
        CartHelpers.trackEvent('errorInCartCreation', {message: err.message})

        throw err
      }
    },
    [
      addPayment,
      cartPathParams.tabId,
      publicCollection?.shippingOptions.shipToEnabled,
      originalCreateCartAsync,
    ],
  )

  return [createCartMutation, createCartAsync] as const
}

export default useCart
