import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'

export const userBrandingFooterSchema = z.object({
  contact: z
    .object({
      orgName: z.string(),
      phoneNumber: z.string(),
      streetAddress: z.string(),
    })
    .optional(),
  socialAccounts: z
    .object({
      twitter: z.string(),
      youtube: z.string(),
      facebook: z.string(),
      instagram: z.string(),
    })
    .optional(),
  mainWebsiteLink: z
    .object({
      link: z.string(),
      text: z.string(),
    })
    .optional(),
  verifiedNonProfitBadge: z
    .object({
      enabled: z.boolean(),
    })
    .optional(),
})

export const userBrandingSchema = z.object({
  user_id: z.number(),
  custom_brand_emails: z.boolean(),
  primary_color: z.string().nullable(),
  secondary_color: z.string().nullable(),
  neutral_color: z.string().nullable(),
  logo: s3ImageSchema.nullish(),
  enable_brand_footer: z.boolean(),
  footer: userBrandingFooterSchema,
})

declare global {
  namespace Api {
    type UserBranding = z.infer<typeof userBrandingSchema>
    type UserBrandingFooter = z.infer<typeof userBrandingFooterSchema>
  }
}
