import * as Yup from 'yup'
import {Formik} from 'formik'
import {useStripe} from '@stripe/react-stripe-js'
import React, {useCallback} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} from '@cheddarup/api-client'
import {Elements} from 'src/components/Stripe'
import {
  BankAccountForm,
  BankAccountFormCAValues,
  BankAccountFormUSValues,
} from 'src/components/BankAccountForm'
import {PaymentElement} from 'src/components/Stripe/PaymentElement'
import {WithSetupIntent} from 'src/components/WithSetupIntent'
import {WithStripe} from 'src/components/WithStripe'
import {readApiError} from 'src/helpers/error-formatting'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'

const PaymentMethodsPage = () => {
  const {data: session} = api.auth.session.useQuery()

  return (
    <AccountSettingsContentLayout
      className="gap-5 [&_>_.AccountSettingsContentCard]:pb-6"
      heading="Payment Methods"
      body="Pay collections faster by storing your payment methods."
    >
      <AccountSettingsContentCard
        className="gap-5"
        heading="My Credit and Debit Cards"
      >
        <BankAndCardListContainer paymentMethodType="card" />
        <Elements>
          <CardAccountFormModal
            disclosure={
              <WebUI.DialogDisclosure
                className="self-start"
                variant="secondary"
              >
                Add Credit or Debit Card
              </WebUI.DialogDisclosure>
            }
          />
        </Elements>
      </AccountSettingsContentCard>
      <AccountSettingsContentCard
        className="gap-5"
        heading="My Checking Accounts (eCheck)"
      >
        <BankAndCardListContainer paymentMethodType="bank" />
        {session?.user.currency !== 'cad' && (
          <Elements>
            <BankAccountFormModal
              disclosure={
                <WebUI.DialogDisclosure
                  className="self-start"
                  variant="secondary"
                >
                  Link to a US Bank Account
                </WebUI.DialogDisclosure>
              }
            />
          </Elements>
        )}
      </AccountSettingsContentCard>
    </AccountSettingsContentLayout>
  )
}

// MARK: – BankAndCardListContainer

interface BankAndCardListContainerProps {
  paymentMethodType: 'card' | 'bank'
}

const BankAndCardListContainer: React.FC<BankAndCardListContainerProps> = ({
  paymentMethodType,
}) => {
  const paymentMethodsQuery = api.paymentMethods.list.useQuery()
  const deletePaymentMethodMutation = useDeletePaymentMethodMutation()
  const {banks, cards} = paymentMethodsQuery.data ?? {banks: [], cards: []}
  const growlActions = WebUI.useGrowlActions()

  const handleDeletePaymentMethod = useCallback(
    async (paymentMethod: Api.BankAccount | Api.CreditCard) => {
      try {
        await deletePaymentMethodMutation.mutateAsync({
          pathParams: {
            paymentMethodId: paymentMethod.id,
          },
        })
      } catch {
        growlActions.clear()
        growlActions.show('error', {
          body: 'Something went wrong. Please try again',
          title: 'Payment method not deleted',
        })
      }
    },
    [deletePaymentMethodMutation, growlActions],
  )

  return (
    <>
      {paymentMethodType === 'bank' &&
        banks.length > 0 &&
        banks.map((bank) => (
          <BankItem
            key={bank.id}
            bank={bank}
            onDelete={handleDeletePaymentMethod}
          />
        ))}
      {paymentMethodType === 'card' &&
        cards.length > 0 &&
        cards.map((card) => (
          <CardItem
            key={card.id}
            card={card}
            onDelete={handleDeletePaymentMethod}
          />
        ))}
    </>
  )
}

// MARK: – BankItem

interface BankItemProps {
  bank: Api.BankAccount
  onDelete: (bank: Api.BankAccount) => void
}

const BankItem = React.memo(({bank, onDelete}: BankItemProps) => (
  <WebUI.HStack
    className={
      'items-center justify-between gap-4 rounded-default border p-2 *:shrink-0'
    }
  >
    <WebUI.PhosphorIcon icon="bank" height={21} width={21} color="#2c7b91" />
    <WebUI.Ellipsis className="basis-2/6 font-light">
      {bank.account_holder_name}
    </WebUI.Ellipsis>
    <WebUI.Text className="grow basis-2/6 font-light">*{bank.last4}</WebUI.Text>
    {!bank.hidden && (
      <WebUI.Button
        className="basis-auto text-ds-sm"
        variant="link"
        onClick={() => onDelete(bank)}
      >
        Delete
      </WebUI.Button>
    )}
  </WebUI.HStack>
))

// MARK: – CardItem

interface CardItemProps {
  card: Api.CreditCard
  onDelete: (card: Api.CreditCard) => void
}

const CardItem = React.memo(({card, onDelete}: CardItemProps) => (
  <WebUI.HStack
    className={
      'items-center justify-between gap-4 rounded-default border p-2 *:shrink-0'
    }
  >
    <WebUI.CreditCardBrandLogo
      className="basis-8"
      brand={card.brand}
      width={26}
    />
    <WebUI.Ellipsis className="basis-1/3 font-light">
      {card.nickname}
    </WebUI.Ellipsis>
    <WebUI.Text className="grow basis-1/3 font-light">
      ****{card.last4}
    </WebUI.Text>
    <WebUI.Button
      className="basis-auto text-ds-sm"
      variant="link"
      onClick={() => onDelete(card)}
    >
      Delete
    </WebUI.Button>
  </WebUI.HStack>
))

// MARK: – BankAccountFormModal

interface BankAccountFormModalProps extends WebUI.ModalProps {}

const BankAccountFormModal = ({
  initialVisible = false,
  ...restProps
}: BankAccountFormModalProps) => {
  const stripe = useStripe()
  const createPaymentMethodMutation = useCreatePaymentMethodMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:px-9 [&_>_.ModalContentView]:py-8"
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="gap-5">
          <WebUI.ModalCloseButton />
          <WebUI.Heading as="h2">Add Bank Account</WebUI.Heading>
          <BankAccountForm
            onSubmit={async (
              values: BankAccountFormUSValues | BankAccountFormCAValues,
            ) => {
              if (!stripe) {
                return
              }

              const isCanadian = 'transitNumber' in values
              const routingNumber = isCanadian
                ? `${values.transitNumber}-${values.institutionNumber}`
                : `${values.routingNumber}`

              const stripeResponse = await stripe.createToken('bank_account', {
                account_holder_name: values.nickName || '',
                account_number: `${values.accountNumber}`,
                country: isCanadian ? 'CA' : 'US',
                currency: isCanadian ? 'CAD' : 'USD',
                routing_number: routingNumber,
                account_holder_type: 'individual',
              })

              if (stripeResponse.error) {
                growlActions.clear()
                growlActions.show('error', {
                  title: 'Error',
                  body: stripeResponse.error.message,
                })
              } else if (stripeResponse.token) {
                try {
                  await createPaymentMethodMutation.mutateAsync({
                    body: {
                      nickname: values.nickName,
                      source: stripeResponse.token.id ?? '',
                    },
                  })
                  growlActions.show('success', {
                    title: 'Account Added',
                    body: `${
                      values.nickName || ''
                    } was added to your payment sources.`,
                  })
                  dialog.hide()
                } catch (err) {
                  growlActions.clear()
                  growlActions.show('error', {
                    title: 'Error',
                    body: readApiError(err, {
                      account_already_exists: 'Account already exists',
                    }),
                  })
                }
              }
            }}
          />
        </WebUI.VStack>
      )}
    </WebUI.Modal>
  )
}

// MARK: – CardAccountFormModal

interface CardAccountFormModalProps extends WebUI.ModalProps {}

const CardAccountFormModal = ({
  initialVisible = false,
  className,
  ...restProps
}: CardAccountFormModalProps) => {
  const updatePaymentMethodMutation = useUpdatePaymentMethodMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:px-9 [&_>_.ModalContentView]:py-8"
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="gap-5">
          <WebUI.ModalCloseButton />
          <WebUI.Heading as="h2">Add Credit or Debit Card</WebUI.Heading>
          <WebUI.VStack as={WebUI.Panel} className="p-6">
            <WithSetupIntent>
              {({clientSecret}) => (
                <WithStripe clientSecret={clientSecret}>
                  {({stripe, elements}) => (
                    <Formik
                      validationSchema={Yup.object().shape({
                        nickname: Yup.string().required('Required'),
                      })}
                      initialValues={{nickname: ''}}
                      onSubmit={async (values) => {
                        if (!stripe || !elements) {
                          return
                        }

                        try {
                          const confirmSetupRes = await stripe.confirmSetup({
                            elements,
                            confirmParams: {
                              return_url: window.location.href,
                            },
                            redirect: 'if_required',
                          })
                          if (confirmSetupRes.error) {
                            throw new Error(confirmSetupRes.error.message)
                          }
                          if (
                            typeof confirmSetupRes.setupIntent
                              .payment_method !== 'string'
                          ) {
                            return
                          }

                          await updatePaymentMethodMutation.mutateAsync({
                            pathParams: {
                              paymentMethodId:
                                confirmSetupRes.setupIntent.payment_method,
                            },
                            body: {
                              nickname: values.nickname,
                            },
                          })

                          dialog.hide()
                        } catch (err: any) {
                          const errMsg =
                            err.message || err.response?.data?.error
                          if (errMsg) {
                            growlActions.clear()
                            growlActions.show('error', {
                              title: 'Error',
                              body: errMsg,
                            })
                          }
                        }
                      }}
                    >
                      {(formik) => (
                        <form
                          className={WebUI.cn('flex flex-col gap-3')}
                          onReset={formik.handleReset}
                          onSubmit={formik.handleSubmit}
                        >
                          <WebUI.FormField
                            label="Card Nickname"
                            error={formik.errors.nickname}
                          >
                            <WebUI.Input
                              name="nickname"
                              placeholder="Card Nickname"
                              value={formik.values.nickname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </WebUI.FormField>
                          <PaymentElement
                            options={{
                              wallets: {
                                applePay: 'never',
                                googlePay: 'never',
                              },
                            }}
                          />
                          <WebUI.Button
                            variant="primary"
                            className="self-start px-8"
                            type="submit"
                            loading={formik.isSubmitting}
                          >
                            Save
                          </WebUI.Button>
                        </form>
                      )}
                    </Formik>
                  )}
                </WithStripe>
              )}
            </WithSetupIntent>
          </WebUI.VStack>
        </WebUI.VStack>
      )}
    </WebUI.Modal>
  )
}

export default PaymentMethodsPage
