import chroma from 'chroma-js'

const palette = {
  white: '#fafafa',
  black: '#414042',
  gray100: '#f2f2f2',
  gray150: '#eeeeee',
  gray300: '#cccccc',
  gray400: '#9e9e9e',
  gray600: '#777777',
  gray700: '#5e5e5e',
  gray750: '#4a4a4a',
  gray800: '#373737',
  accent500: '#257a91',
  accent400: '#d7eef1',
  accent300: '#6194a4',
  accent200: '#b0dfe5',
  aqua: '#b0e0e6',
  aquaLight: '#d7eff2',
  primary: '#f36d36',
  tint: '#2c7b91',
  error: '#f36d36',
}

export const tokens = {
  colors: {
    ...palette,
    backgroundSecondary: palette.gray100,
    contentPrimary: palette.black,
    inputPlaceholder: palette.gray400,
    inputHoverBackground: chroma
      .mix(palette.white, palette.gray600, 0.05)
      .css(),
    buttonDefaultBackground: '#2c7b91',
    buttonSecondaryText: palette.gray800,
    buttonLinkText: '#2c7b91',
    buttonGhostText: palette.gray800,
    buttonGhostHoverBackground: chroma(palette.gray400).alpha(0.075).css(),
    buttonGhostActiveBackground: chroma(palette.gray600).alpha(0.1).css(),
    anchorHoverText: chroma('#2c7b91').alpha(0.5).css(),
    linkDefaultHoverText: chroma(palette.gray800).alpha(0.5).css(),
    linkPrimaryText: '#2c7b91',
    linkPrimaryHoverText: chroma('#2c7b91').alpha(0.5).css(),
    iconLinkHoverBackground: chroma(palette.gray400).alpha(0.075).css(),
    linkGhostText: palette.gray800,
    linkGhostHoverText: chroma(palette.gray800).alpha(0.075).css(),
    tooltipTextLight: palette.gray800,
    tooltipBackgroundDark: palette.gray800,
    tableRowHoverBackground: palette.gray100,
    tint: '#2c7b91',
  },
  fonts: {
    body: 'avenir, system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
    accent:
      'Merriweather, system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
    accentAlt:
      'glamour-absolute, system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  },
  fontSizes: {
    // Based on https://alistapart.com/article/more-meaningful-typography/
    0: '0',
    1: '10px',
    2: '12px',
    3: '14px',
    4: '16px',
    5: '18px',
    6: '20px',
    '6-5': '22px',
    7: '24px',
    '7-5': '26px',
    8: '32px',
    '8-25': '36px',
    '8-5': '38px',
    9: '48px',
    10: '64px',
    11: '72px',
  },
  fontWeights: {
    thin: '100',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  lineHeights: {
    compact: '1.2',
    heading: '1.33',
    default: '1.5',
    cosy: '2',
  },
  space: {
    0: '0px',
    '0_5': '2px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    13: '52px',
    14: '56px',
    15: '60px',
    16: '64px',
    17: '72px',
    18: '80px',
    32: '128px',
  },
  radii: {
    default: '4px',
    large: '10px',
    extended: '12px',
  },
  shadows: {
    z0: 'none',
    z1: '0 1px 1px 0 rgba(0, 0, 0, 0.10), 0 2px 1px -1px rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.16)',
    z2: '0 2px 2px 0 rgba(0, 0, 0, 0.10), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.16)',
    z3: '0 3px 4px 0 rgba(0, 0, 0, 0.10), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 1px 8px 0 rgba(0, 0, 0, 0.16)',
    z4: '0 4px 5px 0 rgba(0, 0, 0, 0.10), 0 1px 10px 0 rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.16)',
    z6: '0 6px 10px 0 rgba(0, 0, 0, 0.10), 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 3px 5px -1px rgba(0, 0, 0, 0.16)',
    z8: '0 8px 10px 1px rgba(0, 0, 0, 0.10), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 5px 5px -3px rgba(0, 0, 0, 0.16)',
    z9: '0 9px 12px 1px rgba(0, 0, 0, 0.10), 0 3px 16px 2px rgba(0, 0, 0, 0.08), 0 5px 6px -3px rgba(0, 0, 0, 0.16)',
    z12: '0 12px 17px 2px rgba(0, 0, 0, 0.10), 0 5px 22px 4px rgba(0, 0, 0, 0.08), 0 7px 8px -4px rgba(0, 0, 0, 0.16)',
    z16: '0 16px 24px 2px rgba(0, 0, 0, 0.10), 0 6px 30px 5px rgba(0, 0, 0, 0.08), 0 8px 10px -5px rgba(0, 0, 0, 0.16)',
    z24: '0 24px 38px 3px rgba(0, 0, 0, 0.10), 0 9px 46px 8px rgba(0, 0, 0, 0.08), 0 11px 15px -7px rgba(0, 0, 0, 0.16)',

    '00': 'none',
    '10': '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    '20': '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
    '30': '0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    '40': '0px 12px 16px -4px rgba(16, 24, 40, 0.10), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
    '50': '0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
    '60': '0px 24px 48px -12px rgba(16, 24, 40, 0.25)',
    '70': '0px 32px 64px -12px rgba(16, 24, 40, 0.20)',
  },
  zIndices: {
    toast: '1000030',
  },
}

export type Tokens = typeof tokens
