import React from 'react'

import {Alert, AlertProps} from './Alert'
import {DialogInstance} from './Dialog'
import {Heading} from './Heading'
import {ModalHeader} from './Modal'
import {VStack} from './Stack'
import {cn} from '../utils'

export interface PromptProps extends AlertProps {}

export const Prompt = React.forwardRef<DialogInstance, PromptProps>(
  ({className, role = 'dialog', ...restProps}, forwardedRef) => (
    <Alert
      ref={forwardedRef}
      className={cn(
        'Prompt',
        '[&_>_.ModalContentView]:px-8 [&_>_.ModalContentView]:py-6',
        className,
      )}
      role={role}
      {...restProps}
    />
  ),
)

// MARK: – PromptHeader

export interface PromptHeaderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading?: React.ReactNode
  subheading?: React.ReactNode
}

export const PromptHeader = React.forwardRef<HTMLDivElement, PromptHeaderProps>(
  ({className, heading, subheading, ...restProps}, forwardedRef) => (
    <ModalHeader
      ref={forwardedRef}
      className={cn(
        'PromptHeader',
        'items-stretch justify-start gap-1 [&.ModalHeader]:p-0',
        className,
      )}
      variant="compact"
      as={VStack}
      {...restProps}
    >
      {typeof heading === 'string' ? (
        <Heading
          className={cn(
            'PromptHeader-heading',
            '[&.PromptHeader-heading]:text-ds-md',
          )}
          as="h2"
        >
          {heading}
        </Heading>
      ) : (
        heading
      )}

      {typeof subheading === 'string' ? (
        <Heading
          className={cn('PromptHeader-subheading', 'font-light text-gray800')}
          as="h4"
        >
          {subheading}
        </Heading>
      ) : (
        subheading
      )}
    </ModalHeader>
  ),
)
