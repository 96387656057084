import React from 'react'
import {
  DelimitedNumericArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {MatchQueryParams} from 'src/components/WithQueryParams'
import {RoutableTab} from 'src/components/RoutableTabs'
import * as Util from '@cheddarup/util'

import {CategoryFilterDropdown} from '../PayerPage/components/CategoryFilterDropdown'
import CategoryPath from '../PayerPage/components/PayerPageToolbar/CategoryPath'
import VariantsFilterDropdown from '../PayerPage/components/PayerPageToolbar/VariantsFilterDropdown'
import usePublicCollection from '../hooks/usePublicCollection'
import {
  getPayerSegments,
  isPayerSegmentDisabled,
  useCurrentPayerSegment,
  usePayerNavigateToSegment,
} from '../utils/payer-navigation-utils'
import useCart from '../hooks/useCart'
import {PayerCartPopover} from '../PayerPage/components/PayerCartPopover'

export interface PayerToolbarProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {}

export const PayerToolbar: React.FC<PayerToolbarProps> = ({
  className,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const currentSegment = useCurrentPayerSegment()

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'relative justify-center overflow-hidden bg-natural-100',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack
        className={WebUI.cn(
          'mx-4 h-[54px] max-w-screen-xl flex-[1_0_auto] items-center justify-between gap-3 *:min-w-0 *:flex-[1_1_0px] sm:mx-8',
        )}
      >
        {media.sm && (
          <WebUI.HStack className="items-center">
            <PayerFitlers />
          </WebUI.HStack>
        )}
        <WebUI.HStack className="!flex-0 sm:!flex-auto items-center justify-center">
          <PayerTabList />
        </WebUI.HStack>
        <WebUI.HStack className="items-center justify-end">
          {(currentSegment !== 'checkout' || !media.sm) && (
            <MatchQueryParams queryParams={{preview: true}}>
              {(isMatched) =>
                isMatched ? (
                  <WebUI.Text className="font-semibold text-ds-sm uppercase">
                    Preview Only
                  </WebUI.Text>
                ) : (
                  <PayerCartPopover />
                )
              }
            </MatchQueryParams>
          )}
        </WebUI.HStack>
      </WebUI.HStack>
    </WebUI.HStack>
  )
}

// MARK: – PayerTabList

const PayerTabList: React.FC<
  WebUI.TabListProps & React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const navigateToSegment = usePayerNavigateToSegment()

  const segments = getPayerSegments({publicCollection})

  return (
    <WebUI.TabList
      aria-label="Navigation"
      className={WebUI.cn(
        'border-b-0 sm:aria-orientation-horizontal:overflow-x-hidden [&_.TabList-underline]:bg-orange-50 [&_.Tab]:text-ds-sm [&_.Tab_>.Anchor-content]:font-semibold',
        className,
      )}
      {...restProps}
    >
      {segments.map((s) => {
        const isDisabled = isPayerSegmentDisabled({
          publicCollection,
          cart,
          segment: s,
        })

        return (
          <RoutableTab
            key={s}
            aria-disabled={isDisabled}
            className="aria-disabled:pointer-events-none aria-disabled:opacity-50"
            to={s}
            onClick={(event) => {
              event.preventDefault()
              navigateToSegment(s)
            }}
          >
            {
              {
                items: 'Items',
                forms: 'Forms',
                checkout: segments.includes('items') ? 'Payment' : 'Submit',
              }[s]
            }
          </RoutableTab>
        )
      })}
    </WebUI.TabList>
  )
}

// MARK: – PayerFitlers

interface PayerFitlersProps extends React.ComponentPropsWithoutRef<'div'> {}

const PayerFitlers: React.FC<PayerFitlersProps> = ({
  className,
  ...restProps
}) => {
  const [_categoryPath] = useQueryParam(
    'categoryPath',
    withDefault(DelimitedNumericArrayParam, []),
  )
  const {publicCollection} = usePublicCollection()
  const currentSegment = useCurrentPayerSegment()

  const categoryPath = _categoryPath as [number?, string?]

  const anchoredCategories = publicCollection.categories.filter(
    (category) =>
      category.anchor &&
      publicCollection.items.some((i) => i.category?.id === category.id),
  )

  const order = Object.fromEntries(
    publicCollection.filterOrder?.map((x) => [x.key, x.order]) ?? [],
  )

  const categoryPathFilters =
    publicCollection.filters
      ?.filter(
        (i) =>
          i.categoryId === categoryPath[0] &&
          (!categoryPath[1] || i.subcategoryId === categoryPath[1]),
      )
      ?.map((v) => v.filters) ?? []

  const visibleFiltersCombined: Record<string, string[]> =
    categoryPathFilters &&
    Util.mergeWith(
      {},
      ...categoryPathFilters,
      (a: string[], b: string[]) =>
        (Array.isArray(a) && a.concat(b)) || undefined,
    )

  const visibleFilters = Object.fromEntries(
    Object.entries(visibleFiltersCombined).map(([k, v]) => [
      k,
      Util.sort(Util.unique(v)).asc((x) => (order[k] || []).indexOf(x)),
    ]),
  )

  return (
    <WebUI.HStack
      className={WebUI.cn('items-center gap-3', className)}
      {...restProps}
    >
      {anchoredCategories.length > 0 && currentSegment !== 'checkout' && (
        <>
          {categoryPath.length === 0 ? (
            <CategoryFilterDropdown categories={anchoredCategories as any[]} />
          ) : (
            <>
              <CategoryPath categories={anchoredCategories} />
              {Object.keys(visibleFilters ?? {}).length > 0 && (
                <VariantsFilterDropdown filters={visibleFilters} />
              )}
            </>
          )}
        </>
      )}
    </WebUI.HStack>
  )
}
