import {z} from '@cheddarup/util'

export const tabPaymentRefundStatusSchema = z.enum(['pending', 'succeeded'])

export const tabPaymentRefundDetailItemSchema = z.object({
  payment_item_id: z.number(),
  name: z.string().optional(),
  amount: z.number(),
  quantity: z.number(),
})

export const tabPaymentRefundDetailTaxSchema = z.object({
  name: z.string(),
  amount: z.number(),
  quantity: z.number().optional(),
})

export const tabPaymentRefundDetailSchema = z.object({
  items: tabPaymentRefundDetailItemSchema.array(),
  taxes: tabPaymentRefundDetailTaxSchema.array().optional(),
  shipping: z.number().optional(),
  additional: z.number().optional(),
  total: z.number().optional(),
})

export const tabPaymentRefundSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  status: tabPaymentRefundStatusSchema,
  amount: z.number(),
  detail: tabPaymentRefundDetailSchema,
})

declare global {
  namespace Api {
    type TabPaymentRefundStatus = z.infer<typeof tabPaymentRefundStatusSchema>
    type TabPaymentRefundDetail = z.infer<typeof tabPaymentRefundDetailSchema>
    type TabPaymentRefundDetailItem = z.infer<
      typeof tabPaymentRefundDetailItemSchema
    >
    type TabPaymentRefund = z.infer<typeof tabPaymentRefundSchema>
    type TabPaymentRefundDetailTax = z.infer<
      typeof tabPaymentRefundDetailTaxSchema
    >
  }
}
