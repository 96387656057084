import {SetOptional, SimpleMerge, mapToObj, pick, pickBy} from '@cheddarup/util'
import {
  CollectionActionDefault,
  CollectionItemId,
  CollectionStateDefault,
  CollectionStateProvider,
  CollectionStateProviderProps,
  genericMemoForwardRef,
  newGenericForwardRef,
  reactDeepEqual,
  useCollectionDispatchHelpers,
  useCollectionState,
  useForkRef,
  useLiveRef,
} from '@cheddarup/react-util'
import React, {
  Dispatch,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import {Shortcut} from '../hooks'
import {Checkbox, CheckboxProps} from './Checkbox'
import {
  DataListRow,
  DataListRowState,
  DataRowComponentType,
  getAdjacentRowKey,
} from './DataList'
import {FloatingToolbar} from './FloatingToolbar'
import type {
  ListRowComponentProps,
  ListRowComponentType,
  ListRowData,
  ListRowDataId,
} from './List'
import {
  AnySectionList,
  ListSectionComponentType,
  SectionList,
  SectionListData,
  SectionListInstance,
} from './SectionList'
import {cn} from '../utils'

export interface DataSectionListData<
  S extends ListRowData,
  R extends ListRowData,
> {
  sectionData: DataListRow<S>
  rowsData: Array<DataListRow<R>>
}

export interface DataSectionListState extends CollectionStateDefault {
  focusedSectionId: CollectionItemId | null
}

export type DataSectionListAction =
  | CollectionActionDefault
  | {
      type: 'SET_SECTION_SELECTED'
      key: CollectionItemId
      value: boolean
    }
  | {
      type: 'SET_SECTIONS_SELECTED'
      keys: CollectionItemId[]
      value: boolean
    }
  | {
      type: 'SET_SECTION_FOCUSED'
      key: CollectionItemId | null
    }

export type DataSectionListOwnProps<
  S extends ListRowData,
  R extends ListRowData,
> = SimpleMerge<
  DataSectionListInnerOwnProps<S, R>,
  SetOptional<
    Pick<
      CollectionStateProviderProps<DataSectionListState, DataSectionListAction>,
      'initialState' | 'onStateChange'
    >,
    'initialState'
  >
>

export type DataSectionListProps<
  S extends ListRowData,
  R extends ListRowData,
  L extends AnySectionList<DataListRow<S>, DataListRow<R>>,
> = SimpleMerge<
  Omit<React.ComponentPropsWithoutRef<L>, 'SectionComponent' | 'RowComponent'>,
  DataSectionListOwnProps<S, R> & {
    SectionListComponent?: L
  }
>

export const DataSectionList = newGenericForwardRef(
  <
    S extends ListRowData,
    R extends ListRowData,
    L extends AnySectionList<DataListRow<S>, DataListRow<R>>,
  >({
    initialState = {
      selectedItemIdsMap: {},
      focusedItemId: null,
      focusedSectionId: null,
    },
    onStateChange,
    data,
    ref: forwardedRef,
    ...restProps
  }: DataSectionListProps<S, R, L> & {
    ref?: React.Ref<SectionListInstance>
  }) => {
    const rowsData = useMemo(() => data.flatMap((i) => i.rowsData), [data])

    const reducer: Reducer<DataSectionListState, DataSectionListAction> =
      useCallback(
        (state, action) => {
          const sectionIdRowIdsMap = mapToObj(data, (d) => [
            d.sectionData.id,
            d.rowsData.map((r) => r.id),
          ])

          switch (action.type) {
            case 'SET_SECTION_SELECTED':
              return {
                ...state,
                selectedItemIdsMap: {
                  ...state.selectedItemIdsMap,
                  ...mapToObj(
                    sectionIdRowIdsMap[action.key as S['id']] ?? [],
                    (key) => [String(key), action.value],
                  ),
                },
              }
            case 'SET_SECTIONS_SELECTED':
              return {
                ...state,
                selectedItemIdsMap: {
                  ...state.selectedItemIdsMap,
                  ...mapToObj(
                    Object.values(
                      pick(sectionIdRowIdsMap, action.keys.map(String)),
                    ).flat(),
                    (key) => [String(key), action.value],
                  ),
                },
              }
            case 'SET_ALL_ITEMS_SELECTED':
              return {
                ...state,
                selectedItemIdsMap: action.value
                  ? mapToObj(
                      Object.values(sectionIdRowIdsMap).flat() as R['id'][],
                      (rowKey) => [rowKey, true],
                    )
                  : {},
                focusedItemId: null,
              }
            case 'SET_SECTION_FOCUSED':
              return {
                ...state,
                focusedItemId: null,
                focusedSectionId: action.key,
              }
            case 'SET_ITEM_FOCUSED':
              return {
                ...state,
                focusedItemId: action.key,
                focusedSectionId: null,
              }
            default:
              return undefined as any
          }
        },
        [data],
      )

    return (
      <CollectionStateProvider
        initialState={initialState}
        onStateChange={onStateChange}
        items={rowsData}
        reducer={reducer}
      >
        <DataSectionListInner<S, R, L>
          ref={forwardedRef}
          data={data}
          {...(restProps as any)}
        />
      </CollectionStateProvider>
    )
  },
)

// MARK: - DataSectionListInner

interface DataSectionListInnerOwnProps<
  S extends ListRowData,
  R extends ListRowData,
> {
  DataSectionComponent: DataSectionComponentType<S>
  DataRowComponent: DataRowComponentType<R>
  selectable?: boolean
  shouldRegisterShortcuts?: boolean
  // HACK: to render rows that aren't present in `data`
  hiddenRowsData?: R[]
  children?: (dataSectionList: {
    listElement: React.ReactElement
    state: DataSectionListState
    dispatch: Dispatch<DataSectionListAction>
  }) => React.ReactNode
}

type DataSectionListInnerProps<
  S extends ListRowData,
  R extends ListRowData,
  L extends AnySectionList<DataListRow<S>, DataListRow<R>>,
> = SimpleMerge<
  Omit<React.ComponentPropsWithoutRef<L>, 'SectionComponent' | 'RowComponent'>,
  DataSectionListInnerOwnProps<S, R> & {
    SectionListComponent?: L
  }
>

const DataSectionListInner = newGenericForwardRef(
  <
    S extends ListRowData,
    R extends ListRowData,
    L extends AnySectionList<DataListRow<S>, DataListRow<R>>,
  >({
    data,
    SectionListComponent: _SectionListComponent,
    DataSectionComponent,
    DataRowComponent,
    selectable,
    shouldRegisterShortcuts = true,
    hiddenRowsData,
    className,
    children,
    ref: forwardedRef,
    ...restProps
  }: DataSectionListInnerProps<S, R, L> & {
    ref?: React.Ref<SectionListInstance>
  }) => {
    const SectionListComponent = _SectionListComponent ?? SectionList

    const ownRef = useRef<SectionListInstance>(null)
    const ref = useForkRef(forwardedRef, ownRef)
    const {state, dispatch} = useCollectionState<
      DataSectionListState,
      DataSectionListAction
    >()
    const dispatchHelpers = useCollectionDispatchHelpers()
    const dispatchRef = useLiveRef(dispatch)

    const hiddenDataRowsMap: Record<ListRowDataId, DataListRow<R>> = useMemo(
      () =>
        mapToObj(hiddenRowsData ?? [], (r) => [
          r.id,
          {
            id: r.id,
            original: r,
            state: {
              selected: false,
              focused: false,
            },
          },
        ]),
      [hiddenRowsData],
    )

    const sections = useMemo(
      () =>
        data.map((i): SectionListData<DataListRow<S>, DataListRow<R>> => {
          const sectionRowKeys = i.rowsData.map((r) => r.id)
          return {
            sectionData: {
              id: i.sectionData.id,
              original: i.sectionData as any,
              state: {
                selected:
                  sectionRowKeys.length > 0 &&
                  sectionRowKeys.every(
                    (rowKey) => state.selectedItemIdsMap[rowKey] === true,
                  ),
                focused: state.focusedSectionId === i.sectionData.id,
              },
            },
            rowsData: i.rowsData.map(
              (r) =>
                ({
                  id: r.id,
                  original: r,
                  state: {
                    selected: state.selectedItemIdsMap[r.id] === true,
                    focused: state.focusedItemId === r.id,
                  },
                }) as any,
            ),
          }
        }),
      [
        data,
        state.focusedItemId,
        state.focusedSectionId,
        state.selectedItemIdsMap,
      ],
    )

    const setSectionFocused = useCallback((key: ListRowDataId | null) => {
      dispatchRef.current({type: 'SET_SECTION_FOCUSED', key})
    }, [])
    const setSectionSelected = useCallback(
      (key: ListRowDataId, value: boolean) =>
        dispatchRef.current({
          type: 'SET_SECTION_SELECTED',
          key,
          value,
        }),
      [],
    )

    const SectionComponent: ListSectionComponentType<DataListRow<S>> = useMemo(
      () =>
        React.memo(
          React.forwardRef(
            ({data: sectionData, ...sectionRestProps}, sectionForwardedRef) => (
              <DataSectionListSection
                ref={sectionForwardedRef}
                sectionKey={sectionData.original.id}
                DataSectionComponent={DataSectionComponent}
                sectionData={sectionData.original}
                state={sectionData.state}
                setSectionSelected={setSectionSelected}
                setSectionFocused={setSectionFocused}
                // setRangeToRowSelected={setRangeToRowSelected}
                {...sectionRestProps}
              />
            ),
          ),
        ),
      [DataSectionComponent, setSectionFocused, setSectionSelected],
    )

    const RowComponent = useMemo(
      () =>
        React.memo<ListRowComponentType<DataListRow<R>>>(
          React.forwardRef<
            HTMLDivElement,
            ListRowComponentProps<DataListRow<R>>
          >(({data: _rowData, index, ...rowRestProps}, rowForwardedRef) => {
            const rowId = _rowData.id
            const rowData = _rowData.original
              ? _rowData
              : hiddenDataRowsMap[rowId]

            return (
              <DataListRow
                ref={rowForwardedRef}
                rowKey={rowData.original.id}
                DataRowComponent={DataRowComponent}
                selectable={selectable}
                rowData={rowData.original}
                state={rowData.state}
                setRowSelected={dispatchHelpers.setItemSelected}
                setRowFocused={dispatchHelpers.setItemFocused}
                setRangeToRowSelected={dispatchHelpers.setRangeToItemSelected}
                {...rowRestProps}
              />
            )
          }),
          reactDeepEqual,
        ),
      [DataRowComponent, dispatchHelpers, hiddenDataRowsMap, selectable],
    )

    const AnySectionListComponent = SectionListComponent as any
    const sectionListElement = (
      <AnySectionListComponent
        ref={ref}
        className={cn('DataSectionList', className)}
        data={sections}
        hiddenRowsData={hiddenRowsData}
        SectionComponent={SectionComponent}
        RowComponent={RowComponent}
        {...restProps}
      />
    )

    return (
      <>
        {typeof children === 'function'
          ? children({listElement: sectionListElement, state, dispatch})
          : sectionListElement}
        {shouldRegisterShortcuts && (
          <DataSectionListShortcuts
            listInstance={ownRef.current}
            sectionKeyWithRowKeys={data.map((i) => ({
              sectionKey: i.sectionData.id,
              rowKeys: i.rowsData.map((r) => r.id),
            }))}
            listState={state}
            dispatch={dispatch}
          />
        )}
      </>
    )
  },
)

// MARK: – DataSectionListSection

export interface DataSectionComponentProps<T extends ListRowData>
  extends React.ComponentPropsWithoutRef<'div'> {
  section?: DataListRow<T>
  setSelected?: (value: boolean) => void
}

export type DataSectionComponentType<T extends ListRowData> =
  React.ComponentType<DataSectionComponentProps<T>>

export interface DataSectionListSectionProps<T extends ListRowData>
  extends React.ComponentPropsWithoutRef<'div'> {
  DataSectionComponent: DataSectionComponentType<T>
  sectionKey: ListRowDataId
  sectionData: T
  state: DataListRowState
  setSectionFocused: (key: ListRowDataId | null) => void
  setSectionSelected: (key: ListRowDataId, value: boolean) => void
}

export const DataSectionListSection = genericMemoForwardRef(
  <T extends ListRowData>(
    {
      DataSectionComponent,
      sectionKey,
      sectionData,
      state,
      setSectionFocused,
      setSectionSelected,
      onFocus,
      onBlur,
      className,
      children,
      ...restProps
    }: DataSectionListSectionProps<T>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (state.focused && ownRef.current) {
        ownRef.current.focus()
      }
    }, [])

    return (
      <div
        ref={ref}
        className={cn(
          'DataSectionListSection flex flex-row items-center',
          className,
        )}
        tabIndex={-1}
        data-sectionid={sectionKey}
        data-sectionfocused={state.focused}
        data-sectionselected={state.selected}
        {...restProps}
      >
        <DataSectionComponent
          className={`min-w-0 flex-[1] focus:outline-none [.DataSectionListSection[data-sectionfocused="true"]_&[class]]:border-teal-50`}
          tabIndex={0}
          section={useMemo(
            () => ({id: sectionData.id, original: sectionData, state}),
            [sectionData, state],
          )}
          setSelected={useCallback(
            (value: boolean) => setSectionSelected(sectionKey, value),
            [sectionKey, setSectionSelected],
          )}
          onFocus={useCallback(
            (event: React.FocusEvent<HTMLDivElement>) => {
              onFocus?.(event)

              if (event.defaultPrevented) {
                return
              }

              if (
                (event.target === event.currentTarget ||
                  (event.target.classList.contains('Action') &&
                    event.target.ariaHasPopup === 'dialog')) &&
                !state.focused
              ) {
                setSectionFocused(sectionKey)
              }
            },
            [onFocus, sectionKey, setSectionFocused, state.focused],
          )}
          onBlur={useCallback(
            (event: React.FocusEvent<HTMLDivElement>) => {
              onBlur?.(event)

              if (event.defaultPrevented) {
                return
              }

              if (!event.relatedTarget && state.focused) {
                setSectionFocused(null)
              }
            },
            [onBlur, setSectionFocused, state.focused],
          )}
        >
          {children}
        </DataSectionComponent>
      </div>
    )
  },
  reactDeepEqual,
)

// MARK: – DataSectionListCheckboxSelectAll

export interface DataSectionListCheckboxSelectAllProps
  extends Omit<
    SimpleMerge<React.ComponentPropsWithoutRef<'input'>, CheckboxProps>,
    'state' | 'children'
  > {
  children:
    | React.ReactNode
    | ((value: boolean | 'indeterminate') => React.ReactNode)
}

export const DataSectionListCheckboxSelectAll = React.forwardRef<
  HTMLInputElement,
  DataSectionListCheckboxSelectAllProps
>(({className, onChange, children, ...restProps}, forwardedRef) => {
  const {itemIds, state, dispatch} = useCollectionState<
    DataSectionListState,
    DataSectionListAction
  >()
  const selectedItemIdsCount = Object.values(state.selectedItemIdsMap).filter(
    (v) => v === true,
  ).length
  const checkboxState =
    selectedItemIdsCount === itemIds.length
      ? true
      : selectedItemIdsCount > 0
        ? 'indeterminate'
        : false
  return (
    <Checkbox
      ref={forwardedRef}
      className={cn('DataListCheckboxSelectAll', className)}
      state={checkboxState}
      onChange={(event) => {
        onChange?.(event)
        if (!event.defaultPrevented) {
          dispatch({
            type: 'SET_ALL_ITEMS_SELECTED',
            value: event.target.checked,
          })
        }
      }}
      {...restProps}
    >
      {typeof children === 'function' ? children(checkboxState) : children}
    </Checkbox>
  )
})

// MARK: – DataSectionListBulkActionToolbar

export interface DataSectionListBulkActionToolbarProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  children:
    | React.ReactNode
    | ((selectedDataIds: ListRowDataId[]) => React.ReactNode)
}

export const DataSectionListBulkActionToolbar = React.forwardRef<
  HTMLDivElement,
  DataSectionListBulkActionToolbarProps
>(({className, children, ...restProps}, forwardedRef) => {
  const {itemIds, state} = useCollectionState<
    DataSectionListState,
    DataSectionListAction
  >()
  const selctedRowKeys = Object.keys(
    pickBy(state.selectedItemIdsMap, (isSelected) => isSelected === true),
  )

  const selectedDataIds = itemIds.filter((iId) =>
    selctedRowKeys.includes(String(iId)),
  )
  return (
    <FloatingToolbar
      ref={forwardedRef}
      className={cn('DataSectionListBulkActionToolbar', className)}
      visible={selectedDataIds.length > 0}
      {...restProps}
    >
      <DataSectionListCheckboxSelectAll className="flex-0 text-ds-xs">
        {`${selectedDataIds.length} Selected`}
      </DataSectionListCheckboxSelectAll>
      {typeof children === 'function' ? children(selectedDataIds) : children}
    </FloatingToolbar>
  )
})

// MARK: – DataSectionListShortcuts

export interface DataSectionListShortcutsProps {
  listInstance: SectionListInstance | null
  sectionKeyWithRowKeys: Array<{
    sectionKey: ListRowDataId
    rowKeys: ListRowDataId[]
  }>
  listState: DataSectionListState
  dispatch: Dispatch<DataSectionListAction>
}

export const DataSectionListShortcuts = ({
  listInstance,
  sectionKeyWithRowKeys,
  listState,
  dispatch,
}: DataSectionListShortcutsProps) => {
  const {selectedItemIdsMap, focusedItemId, focusedSectionId} = listState
  const sectionKeys = sectionKeyWithRowKeys.map(({sectionKey}) => sectionKey)
  const rowKeys = sectionKeyWithRowKeys.flatMap(({rowKeys: rKeys}) => rKeys)

  const moveFocusedRow = (direction: 'up' | 'down') => {
    if (focusedItemId) {
      const adjacentRowKey = getAdjacentRowKey({
        direction,
        rowKeys,
        focusedRowKey: focusedItemId,
      })
      if (adjacentRowKey) {
        dispatch({type: 'SET_ITEM_FOCUSED', key: adjacentRowKey})
        listInstance?.scrollToRow(adjacentRowKey)
        return true
      }
    } else if (focusedSectionId) {
      const adjacentSectionKey = getAdjacentRowKey({
        direction,
        rowKeys: sectionKeys,
        focusedRowKey: focusedSectionId,
      })
      if (adjacentSectionKey) {
        dispatch({type: 'SET_SECTION_FOCUSED', key: adjacentSectionKey})
        listInstance?.scrollToSection(adjacentSectionKey)
        return true
      }
    }
    return false
  }
  const jumpFocusedRow = (position: 'top' | 'bottom') => {
    if (focusedItemId) {
      const rowIndex = position === 'top' ? 0 : rowKeys.length - 1
      const rowKey = rowKeys[rowIndex]
      if (rowKey) {
        dispatch({type: 'SET_ITEM_FOCUSED', key: rowKey})
        listInstance?.scrollToRow(rowKey)
        return true
      }
    } else if (focusedSectionId) {
      const sectionIndex = position === 'top' ? 0 : sectionKeys.length - 1
      const sectionKey = sectionKeys[sectionIndex]
      if (sectionKey) {
        dispatch({type: 'SET_SECTION_FOCUSED', key: sectionKey})
        listInstance?.scrollToSection(sectionKey)
        return true
      }
    }
    return false
  }
  const moveFocusedRowWithSelection = (direction: 'up' | 'down') => {
    if (focusedItemId) {
      const adjacentRowKey = getAdjacentRowKey({
        direction,
        rowKeys,
        focusedRowKey: focusedItemId,
      })
      if (!focusedItemId || !adjacentRowKey) {
        return false
      }

      if (selectedItemIdsMap[adjacentRowKey]) {
        dispatch({type: 'SET_ITEM_SELECTED', key: focusedItemId, value: false})
        listInstance?.scrollToRow(adjacentRowKey)
      } else {
        dispatch({
          type: 'SET_ITEMS_SELECTED',
          keys: [focusedItemId, adjacentRowKey],
          value: true,
        })
      }
      dispatch({type: 'SET_ITEM_FOCUSED', key: adjacentRowKey})
      listInstance?.scrollToRow(adjacentRowKey)
    } else if (focusedSectionId) {
      const adjacentSectionKey = getAdjacentRowKey({
        direction,
        rowKeys: sectionKeys,
        focusedRowKey: focusedSectionId,
      })
      if (!focusedItemId || !adjacentSectionKey) {
        return false
      }

      const sectionRowKeys =
        sectionKeyWithRowKeys.find(
          ({sectionKey}) => sectionKey === focusedSectionId,
        )?.rowKeys ?? []
      const areAllSectionRowsSelected = sectionRowKeys.every(
        (rowKey) => selectedItemIdsMap[rowKey] === true,
      )

      if (focusedSectionId && areAllSectionRowsSelected) {
        dispatch({
          type: 'SET_SECTION_SELECTED',
          key: focusedSectionId,
          value: false,
        })
        listInstance?.scrollToSection(adjacentSectionKey)
      } else if (focusedSectionId) {
        dispatch({
          type: 'SET_SECTIONS_SELECTED',
          keys: [focusedSectionId, adjacentSectionKey],
          value: true,
        })
      }
      dispatch({type: 'SET_SECTION_FOCUSED', key: adjacentSectionKey})
      listInstance?.scrollToSection(adjacentSectionKey)
    }
    return true
  }

  return (
    <>
      <Shortcut
        priority={10}
        keybinding="Escape"
        onHandle={() => {
          const selectedRowKeys = Object.keys(
            pickBy(selectedItemIdsMap, (selected) => selected === true),
          )

          if (selectedRowKeys.length > 0) {
            dispatch({type: 'SET_ALL_ITEMS_SELECTED', value: false})
            return true
          }
          if (focusedItemId) {
            dispatch({type: 'SET_ITEM_FOCUSED', key: null})
            return true
          }
          if (focusedSectionId) {
            dispatch({type: 'SET_SECTION_FOCUSED', key: null})
            return true
          }
          return false
        }}
      />
      <Shortcut
        priority={10}
        keybinding="$mod+a"
        onHandle={() => {
          dispatch({type: 'SET_ALL_ITEMS_SELECTED', value: true})
          return true
        }}
      />

      <Shortcut
        priority={10}
        keybinding="x"
        onHandle={() => {
          if (focusedItemId) {
            dispatch({
              type: 'SET_ITEM_SELECTED',
              key: focusedItemId,
              value: !selectedItemIdsMap[focusedItemId],
            })
            return false
          }
          if (focusedSectionId) {
            const sectionRowKeys =
              sectionKeyWithRowKeys.find(
                ({sectionKey}) => sectionKey === focusedSectionId,
              )?.rowKeys ?? []
            dispatch({
              type: 'SET_SECTION_SELECTED',
              key: focusedSectionId,
              value: !sectionRowKeys.every(
                (rowKey) => selectedItemIdsMap[rowKey] === true,
              ),
            })
            return false
          }
          return true
        }}
      />
      {['k', 'ArrowUp'].map((key) => (
        <React.Fragment key={key}>
          <Shortcut
            priority={10}
            keybinding={key}
            onHandle={() => moveFocusedRow('up')}
          />
          <Shortcut
            priority={10}
            keybinding={`Shift+${key}`}
            onHandle={() => moveFocusedRowWithSelection('up')}
          />
        </React.Fragment>
      ))}
      {['j', 'ArrowDown'].map((key) => (
        <React.Fragment key={key}>
          <Shortcut
            priority={10}
            keybinding={key}
            onHandle={() => moveFocusedRow('down')}
          />
          <Shortcut
            priority={10}
            keybinding={`Shift+${key}`}
            onHandle={() => moveFocusedRowWithSelection('down')}
          />
        </React.Fragment>
      ))}
      <Shortcut
        priority={10}
        keybinding="$mod+ArrowUp"
        onHandle={() => jumpFocusedRow('top')}
      />
      <Shortcut
        priority={10}
        keybinding="$mod+ArrowDown"
        onHandle={() => jumpFocusedRow('bottom')}
      />
    </>
  )
}
