import * as Yup from 'yup'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useRef} from 'react'
import {useFormik} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useContactPublicCollectionOrganizerMutation,
  useContactUserMutation,
} from '@cheddarup/api-client'
import {SharpImage} from 'src/components/SharpImage'
import {Link} from 'src/components/Link'

import usePublicCollection from './hooks/usePublicCollection'

const FAQ_LINKS = [
  {
    title: 'How do I know my payment was successful?',
    content: (
      <span>
        After paying you should receive a payment confirmation email. Be sure to
        check your spam or junk folders.
      </span>
    ),
  },
  {
    title: 'How do I request a refund?',
    content: (
      <span>
        Use the form above to contact your organizer to request a refund.
      </span>
    ),
  },
  {
    title: 'Where can I see my payment history?',
    content: (
      <span>
        Click{' '}
        <Link to="/guest/auth" target="_blank" variant="primary">
          here
        </Link>{' '}
        to request a temporary access link to view your Order History.
      </span>
    ),
  },
  {
    title: 'How do I create my own free collection page?',
    content: (
      <span>
        <Link to="/signup" target="_blank" variant="primary">
          Sign up
        </Link>{' '}
        here to create your own page in minutes. Watch a{' '}
        <WebUI.Anchor
          href="https://www.cheddarup.com/demo/"
          target="_blank"
          rel="noopener noreferrer"
        >
          demo
        </WebUI.Anchor>{' '}
        and check out our{' '}
        <Link to="/templates" target="_blank" variant="primary">
          Template Library
        </Link>{' '}
        for inspiration.
      </span>
    ),
  },
]

export interface PayerHelpPageProps {
  isUserSlug?: boolean
}

const PayerHelpPage = ({isUserSlug}: PayerHelpPageProps) => {
  const urlParams = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const {publicCollection} = usePublicCollection()
  const homeQuery = api.publicTabs.home.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.user!,
      },
    },
    {
      enabled: !!urlParams.user,
      select: (home) => home.user,
    },
  )

  // biome-ignore lint/style/noNonNullAssertion:
  const collectionSlug = (urlParams.tabSlug ?? urlParams.user)!
  const organizer = publicCollection
    ? publicCollection.organizer
    : homeQuery.data

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:overflow-y-auto sm:[&_>_.ModalContentView]:max-w-[480px] sm:[&_>_.ModalContentView]:rounded-extended"
      initialVisible
      onDidHide={() => navigate({pathname: '..', search: location.search})}
    >
      {(dialog) => (
        <>
          <WebUI.VStack className="gap-6 p-8 pb-4 sm:pt-12">
            <WebUI.HStack className="items-center gap-5">
              {!!organizer?.profile_pic && (
                <SharpImage
                  className="shrink-0 rounded-full"
                  width={50}
                  height={50}
                  image={organizer.profile_pic}
                  alt="Organizer Profile Picture"
                />
              )}
              <WebUI.VStack>
                <WebUI.Text>Send a message to</WebUI.Text>
                <WebUI.Text className="font-semibold text-ds-lg leading-compact">
                  {(organizer?.displayName as string | undefined) ??
                    organizer?.display_name ??
                    ''}
                </WebUI.Text>
              </WebUI.VStack>
            </WebUI.HStack>
            <OrganizerContactForm
              isUserSlug={isUserSlug}
              slug={collectionSlug}
              onDidContactOrganizer={() => dialog.hide()}
            />
          </WebUI.VStack>

          <WebUI.VStack className="grow gap-5 bg-teal-80 p-8">
            <WebUI.Heading as="h3" className="font-semibold">
              Frequently asked questions:
            </WebUI.Heading>
            {FAQ_LINKS.map((link, idx) => (
              <WebUI.Disclosure key={idx}>
                <WebUI.DisclosureButton
                  className="whitespace-normal"
                  variant="link"
                  arrow={false}
                >
                  {link.title}
                </WebUI.DisclosureButton>
                <WebUI.DisclosureContent className="py-2">
                  {link.content}
                </WebUI.DisclosureContent>
              </WebUI.Disclosure>
            ))}
          </WebUI.VStack>

          <WebUI.ModalCloseButton />
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – OrganizerContactForm

interface OrganizerContactFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  isUserSlug?: boolean
  slug: string
  onDidContactOrganizer?: () => void
}

const OrganizerContactForm = ({
  isUserSlug = false,
  slug,
  onDidContactOrganizer,
  ...restProps
}: OrganizerContactFormProps) => {
  const contactOrganizerMutation = useContactPublicCollectionOrganizerMutation()
  const contactUserMutation = useContactUserMutation()
  const growlActions = WebUI.useGrowlActions()
  const recaptchaRef = useRef<WebUI.RecaptchaInstance>(null)

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Invalid format'),
      message: Yup.string().required('Required'),
    }),
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    onSubmit: async (values) => {
      if (isUserSlug) {
        await contactUserMutation.mutateAsync({
          pathParams: {
            userSlug: slug,
          },
          body: {
            name: values.name,
            email: values.email,
            message: values.message,
          },
        })

        onDidContactOrganizer?.()

        growlActions.show('success', {
          title: 'Success!',
          body: 'Email sent to organizer. A confirmation has also been sent to the email address you provided.',
        })
      } else {
        const captchaToken = await recaptchaRef.current?.executeAsync()

        if (captchaToken) {
          await contactOrganizerMutation.mutateAsync({
            pathParams: {
              tabId: slug,
            },
            body: {
              name: values.name,
              email: values.email,
              message: values.message,
              captchaToken,
            },
          })

          onDidContactOrganizer?.()

          growlActions.show('success', {
            title: 'Success!',
            body: 'Email sent. A confirmation has also been sent to the email address you provided.',
          })
        }
      }
    },
  })

  return (
    <WebUI.Form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.FormField label="Name" error={formik.errors.name}>
        <WebUI.Input
          name="name"
          autoComplete="name"
          placeholder="Your name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField label="Email" error={formik.errors.email}>
        <WebUI.Input
          name="email"
          type="email"
          formNoValidate
          autoComplete="email"
          placeholder="Your email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField label="Message" error={formik.errors.message}>
        <WebUI.Textarea
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          rows={5}
        />
      </WebUI.FormField>
      <WebUI.Button
        className="w-[130px] self-start"
        roundness="capsule"
        type="submit"
        loading={formik.isSubmitting}
      >
        Send
      </WebUI.Button>
      <WebUI.Recaptcha ref={recaptchaRef} />
    </WebUI.Form>
  )
}

export default PayerHelpPage
