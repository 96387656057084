import {
  add as addDate,
  addDays,
  addMilliseconds,
  addMonths,
  addSeconds,
  addWeeks,
  differenceInDays,
  differenceInMilliseconds,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  endOfDay,
  endOfMonth,
  endOfYesterday,
  format,
  formatDistance,
  formatISO,
  getDate,
  getDay,
  getMonth,
  getYear,
  isDate,
  isSameDay,
  isValid,
  min as minDate,
  parse as parseDate,
  set as setDate,
  setDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  sub as subDate,
  subDays,
} from 'date-fns'
import {
  CalendarDate,
  CalendarDateTime,
  getLocalTimeZone,
  parseAbsolute,
  toCalendarDate,
  toCalendarDateTime,
  toTime,
} from '@internationalized/date'

import {catchAsNull} from './function-utils'

export {
  addDate,
  setDay,
  addDays,
  getDate,
  getDay,
  differenceInSeconds,
  isSameDay,
  addSeconds,
  endOfYesterday,
  minDate,
  differenceInDays,
  endOfDay,
  formatDistance as formatDateDistance,
  formatISO,
  startOfDay,
  getMonth,
  getYear,
  isValid as isValidDate,
  parseDate,
  subDate,
  startOfMonth,
  startOfYear,
  addMilliseconds,
  endOfMonth,
  addMonths,
  addWeeks,
  differenceInMilliseconds,
  differenceInMonths,
  differenceInWeeks,
  setDate,
  subDays,
}

export type DateValue = CalendarDate | Date | string | number

export function nativeFormatDate(
  dateValue: DateValue,
  options?: Intl.DateTimeFormatOptions,
) {
  if (typeof dateValue === 'string' && dateValue.trim() === '') {
    return 'No Value'
  }
  try {
    return getDateTimeFormatter('en-US', options).format(
      castDateValue(dateValue),
    )
  } catch (err) {
    return 'Invalid Date'
  }
}

export function formatDate(
  dateValue: DateValue,
  dateFormat = 'MM/dd/yyyy',
  options?: Parameters<typeof format>[2],
) {
  return catchAsNull(() =>
    format(castDateValue(dateValue), dateFormat, options),
  )
}

export function formatDateAs(
  dateValue: DateValue,
  as:
    | 'year'
    | 'date'
    | 'date_compact'
    | 'date_tabular'
    | 'time'
    | 'datetime' = 'date',
) {
  switch (as) {
    case 'year':
      return formatDate(dateValue, 'yyyy')
    case 'date':
      return formatDate(dateValue, 'MM/dd/yyyy')
    case 'date_compact':
      return formatDate(dateValue, 'MM/dd/yy')
    case 'date_tabular':
      return formatDate(dateValue, 'MMM dd, yyyy')
    case 'time':
      return getDateTimeFormatter('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      }).format(castDateValue(dateValue))
    case 'datetime':
      return formatDate(dateValue, 'MM/dd/yyyy hh:mm a')
    default:
      return formatDate(dateValue)
  }
}

// MARK: – Time

export function parseTime(isoValue: string, timeZone = getLocalTimeZone()) {
  const zonedDateTime = parseDateTime(isoValue, timeZone)
  if (zonedDateTime == null) {
    return null
  }

  return toTime(zonedDateTime)
}

export function parseDateTime(isoValue: string, timeZone = getLocalTimeZone()) {
  return catchAsNull(() => parseAbsolute(isoValue, timeZone))
}

export function parseCalendarDate(
  isoValue: string,
  timeZone = getLocalTimeZone(),
) {
  const zonedDateTime = catchAsNull(() => parseAbsolute(isoValue, timeZone))
  if (zonedDateTime == null) {
    return null
  }

  return toCalendarDate(zonedDateTime)
}

export function parseCalendarDateTime(
  isoValue: string,
  timeZone = getLocalTimeZone(),
) {
  const zonedDateTime = catchAsNull(() => parseAbsolute(isoValue, timeZone))
  if (zonedDateTime == null) {
    return null
  }

  return toCalendarDateTime(zonedDateTime)
}

// MARK: – Date range formatting

const defaultLocale = 'en-US'

const cachedDateTimeFormatterMap: Record<string, Intl.DateTimeFormat> = {}

export function getDateTimeFormatter(
  ...args: Parameters<typeof Intl.DateTimeFormat>
) {
  const cacheKey = JSON.stringify(args)

  const cachedFormatter = cachedDateTimeFormatterMap[cacheKey]
  if (cachedFormatter) {
    return cachedFormatter
  }

  const newFormatter = new Intl.DateTimeFormat(...args)
  cachedDateTimeFormatterMap[cacheKey] = newFormatter
  return newFormatter
}

const defaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: 'long',
  day: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export function formatDateRange(
  startDate: DateValue,
  endDate: DateValue | undefined,
  options: {
    safeCharSet?: boolean
    locales?: string | string[]
    dateTimeFormatOptions?: Intl.DateTimeFormatOptions
  } = {
    locales: defaultLocale,
  },
) {
  let res: string

  const dateTimeFormatter = getDateTimeFormatter(options.locales, {
    ...defaultDateTimeFormatOptions,
    ...options.dateTimeFormatOptions,
  })

  if (!endDate) {
    res = dateTimeFormatter.format(castDateValue(startDate))
  } else if ('formatRange' in dateTimeFormatter) {
    res = (dateTimeFormatter.formatRange as any)(
      castDateValue(startDate),
      castDateValue(endDate),
    )
  } else {
    res = `${formatDate(startDate, 'EEE, MMM d yyyy hh:mm aaa')} - ${formatDate(
      endDate,
      'EEE, MMM d yyyy hh:mm aaa',
    )}`
  }

  if (options?.safeCharSet) {
    res = res.replace(/\s/g, ' ')
  }

  return res
}

// MARK: – Helpers

export function castDateValue(dateValue: DateValue) {
  if (isDate(dateValue)) {
    return dateValue
  }
  if (dateValue instanceof CalendarDate) {
    return dateValue.toDate(getLocalTimeZone())
  }
  return new Date(dateValue)
}

export function convertDateToSeconds(date: DateValue) {
  return Math.round(castDateValue(date).getTime() / 1000)
}

export function todayDateTime() {
  const date = new Date()

  return new CalendarDateTime(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getSeconds(),
    date.getMilliseconds(),
  )
}
