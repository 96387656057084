// Based on https://magicui.design/docs/components/number-ticker
import React, {useEffect, useMemo, useRef} from 'react'
import {useInView, useMotionValue, useSpring} from 'framer-motion'
import {cn} from '../utils'
import {useForkRef} from '@cheddarup/react-util'

interface NumberTickerProps extends React.ComponentPropsWithoutRef<'span'> {
  value: number
  direction?: 'up' | 'down'
  delay?: number
  decimalPlaces?: number
}

export const NumberTicker = React.forwardRef<
  HTMLSpanElement,
  NumberTickerProps
>(
  (
    {
      className,
      value,
      direction = 'up',
      delay = 0,
      decimalPlaces = 2,
      ...restProps
    },
    forwardedRef,
  ) => {
    const ownRef = useRef<HTMLSpanElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const motionValue = useMotionValue(direction === 'down' ? value : 0)
    const springValue = useSpring(motionValue, {
      damping: 60,
      stiffness: 100,
    })
    const isInView = useInView(ownRef, {once: true, margin: '0px'})

    const formatter = useMemo(
      () =>
        new Intl.NumberFormat('en-US', {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }),
      [decimalPlaces],
    )

    useEffect(() => {
      if (isInView) {
        setTimeout(() => {
          motionValue.set(direction === 'down' ? 0 : value)
        }, delay * 1000)
      }
    }, [motionValue, isInView, delay, value, direction])

    useEffect(() => {
      const unsubscribe = springValue.on('change', (latest) => {
        if (ownRef.current) {
          ownRef.current.textContent = formatter.format(latest)
        }
      })

      return () => unsubscribe()
    }, [springValue, formatter])

    return (
      <span
        ref={ref}
        className={cn('inline-block tabular-nums tracking-wider', className)}
        {...restProps}
      />
    )
  },
)
