import {type To, useLocation, useNavigate} from 'react-router-dom'
import type React from 'react'
import {useState} from 'react'
import {
  useManagerRole,
  useManagerRoleQuery,
} from 'src/components/ManageRoleProvider'
import {
  api,
  useCloseTabMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton, type LinkButtonProps} from 'src/components/LinkButton'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'

import {CollectionPaymentsStats} from './CollectionManagePaymentsTable/components'

// MARK: – CollectionLinkPanel

export interface CollectionLinkPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const CollectionLinkPanel: React.FC<CollectionLinkPanelProps> = ({
  collection,
  className,
  ...restProps
}) => {
  const navigate = useNavigate()
  const [managerRole] = useManagerRole()
  const sessionQuery = api.auth.session.useQuery()
  const groupPageCollectionSectionQuery = api.groupPageSections.detail.useQuery(
    {pathParams: {sectionName: 'collection_section'}},
  )
  const updateCollectionMutation = useUpdateTabMutation()
  const growlActions = WebUI.useGrowlActions()
  const [closeCollectionAlertVisible, setCloseCollectionAlertVisible] =
    useState(false)

  const isTeamUser =
    !!managerRole || !!sessionQuery.data?.capabilities.subscribed_to_team

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-4 p-6 sm:min-w-[380px] lg:flex-[1]', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="text-gray400 uppercase" as="h6">
          Link
        </WebUI.Heading>
        {managerRole?.permissions?.role === 'viewer' && (
          <WebUI.Text className="text-ds-sm">
            Status:{' '}
            <span className="capitalize">
              {collection?.closed_at ? 'closed' : 'active'}
            </span>
          </WebUI.Text>
        )}
        {!managerRole && sessionQuery.data?.capabilities.plan === 'pause' ? (
          <WebUI.Text className="font-light text-ds-sm">
            This collection is paused. Upgrade your account to reactivate.
          </WebUI.Text>
        ) : (
          <WebUI.HStack className="gap-2">
            <WebUI.Ellipsis className="font-light text-ds-sm">
              https://{collection.slug}.cheddarup.com
            </WebUI.Ellipsis>
            <WebUI.Button
              className="text-ds-sm"
              variant="link"
              onClick={() => {
                WebUI.copyToClipboard(
                  `https://${collection.slug}.cheddarup.com`,
                )
                growlActions.show('success', {
                  title: 'Success',
                  body: 'Link copied',
                })
              }}
            >
              Copy
            </WebUI.Button>
          </WebUI.HStack>
        )}
      </WebUI.VStack>
      {managerRole?.permissions?.role !== 'viewer' && (
        <>
          <WebUI.Separator variant="primary" />
          <WebUI.VStack className="items-stretch justify-center gap-3 sm:flex-row sm:items-center sm:justify-between">
            {!managerRole &&
            sessionQuery.data?.capabilities.plan === 'pause' ? (
              <LinkButton variant="default" to="i/plans">
                Upgrade
              </LinkButton>
            ) : (
              <div className="flex items-center [&_button]:px-4 [&_button]:py-2 [&_span]:leading-[18px]">
                <WebUI.DropdownSelect
                  size="compact"
                  value={collection.closed_at ? 'closed' : 'active'}
                  onValueChange={(newValue) => {
                    if (newValue === 'active') {
                      updateCollectionMutation.mutate({
                        pathParams: {
                          tabId: collection.id,
                        },
                        body: {closed_at: null},
                      })
                    } else if (newValue === 'closed') {
                      setCloseCollectionAlertVisible(true)
                    }
                  }}
                >
                  <WebUI.DropdownSelectOption value="active">
                    Active
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value="closed">
                    Closed
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
                <CloseCollectionAlert
                  collectionId={collection.id}
                  visible={closeCollectionAlertVisible}
                />
              </div>
            )}
            {(!managerRole || managerRole.permissions.group_page) && (
              <WebUI.DeprecatedTooltip
                variant="light"
                label={
                  <div className="max-w-[240px] px-3 py-4 text-left">
                    {groupPageCollectionSectionQuery.data ? (
                      <>
                        The Group Page is a{' '}
                        <WebUI.Anchor
                          href="https://www.cheddarup.com/team-plan/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Team feature
                        </WebUI.Anchor>{' '}
                        and serves as a one-stop shop for communities, so
                        they’ll never have to hunt down different URLs again.
                        <br />
                        <WebUI.Anchor
                          href="https://support.cheddarup.com/hc/en-us/articles/360035226552-Find-and-share-your-Group-Page"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more
                        </WebUI.Anchor>{' '}
                      </>
                    ) : (
                      <>
                        You need to create Collection Section in{' '}
                        <Link to="/group" className="text-tint">
                          Group Page
                        </Link>{' '}
                        first
                      </>
                    )}
                  </div>
                }
              >
                <WebUI.Switch
                  size="compact"
                  disabled={!groupPageCollectionSectionQuery.data}
                  checked={!!collection.collection_section_category_id}
                  onChange={() => {
                    if (!isTeamUser || !sessionQuery.data) {
                      return
                    }
                    updateCollectionMutation.mutate({
                      pathParams: {tabId: collection.id},
                      body: {
                        collection_section_category_id:
                          collection.collection_section_category_id
                            ? null
                            : groupPageCollectionSectionQuery.data?.categories?.find(
                                (c) => c.name === 'default',
                              )?.id,
                      },
                    })
                  }}
                  onClick={() => {
                    if (!isTeamUser) {
                      navigate('i/plans?recommendedPlan=team')
                    }
                  }}
                >
                  Include link in Group Page
                </WebUI.Switch>
              </WebUI.DeprecatedTooltip>
            )}
          </WebUI.VStack>
        </>
      )}
    </WebUI.Panel>
  )
}

// MARK: – CollectionTotalsSummaryPanel

export interface CollectionTotalsSummaryPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.TabDetailed
}

export const CollectionTotalsSummaryPanel: React.FC<
  CollectionTotalsSummaryPanelProps
> = ({collection, className, ...restProps}) => {
  const [managerRole] = useManagerRole()
  const media = WebUI.useMedia()

  return (
    <WebUI.Panel
      className={WebUI.cn(
        'gap-6 p-6 *:flex-[1_0_0px] sm:min-w-[470px] sm:flex-row lg:flex-[1]',
        className,
      )}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="items-start gap-3">
        <CollectionPaymentsStats collection={collection} />
        <LinkButton
          variant="default"
          disabled={
            !collection.access.owner &&
            managerRole?.permissions.role !== 'admin'
          }
          to={`i/collection/${collection.id}/summary`}
        >
          Balance Summary
        </LinkButton>
      </WebUI.VStack>

      {managerRole?.permissions?.role !== 'viewer' && (
        <>
          <WebUI.Separator
            orientation={media.sm ? 'vertical' : 'horizontal'}
            variant="primary"
          />

          <WebUI.VStack className="gap-2">
            <WebUI.Heading className="text-gray400 uppercase" as="h6">
              Available to Transfer
            </WebUI.Heading>
            <WebUI.VStack className="gap-3">
              <p style={{fontSize: '30px'}}>
                {Util.formatAmount(
                  collection?.withdrawal_balance_available || 0,
                )}
              </p>
              <WithdrawButton
                className="self-start"
                disabled={
                  !collection.access.owner &&
                  managerRole?.permissions.role !== 'admin'
                }
              />
            </WebUI.VStack>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Panel>
  )
}

// MARK: – CollectionAccessPanel

export interface CollectionAccessPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const CollectionAccessPanel: React.FC<CollectionAccessPanelProps> = ({
  collection,
  className,
  ...restProps
}) => {
  const discountsCountQuery = api.tabDiscounts.list.useQuery(
    {
      pathParams: {
        tabId: collection.id,
      },
    },
    {
      select: (discounts) => discounts.length,
    },
  )

  const now = new Date()
  const isTimeAvailable =
    !!collection?.close_datetime && new Date(collection.close_datetime) > now
  const isStarted =
    isTimeAvailable && new Date(collection?.open_datetime ?? '') < now

  if (
    !(
      isTimeAvailable ||
      !!collection?.access_code ||
      (!!discountsCountQuery.data && collection?.discounts_enabled) ||
      !!collection?.payer_identify
    )
  ) {
    return null
  }

  return (
    <WebUI.Panel
      className={WebUI.cn('w-full p-6 xl:w-[min(100%,320px)]', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.Heading className="mb-2 text-gray400 uppercase" as="h6">
        Access and Discount Settings
      </WebUI.Heading>
      {isTimeAvailable && !isStarted && (
        <ManagerAccessEditLink
          label={`Timing: Starts ${Util.formatDateAs(
            collection?.open_datetime ?? '',
            'datetime',
          )}`}
          to="../details/settings?p=access-and-timing"
        />
      )}
      {isTimeAvailable && isStarted && (
        <ManagerAccessEditLink
          label={`Timing: Ends ${Util.formatDateAs(
            collection?.close_datetime ?? '',
            'datetime',
          )}`}
          to="../details/settings?p=access-and-timing"
        />
      )}
      {!!collection?.access_code && (
        <ManagerAccessEditLink
          label="Access: Requires Code"
          to="../details/settings?p=access-and-timing"
        />
      )}
      {!!discountsCountQuery.data && !!collection?.discounts_enabled && (
        <ManagerAccessEditLink
          label={`Discounts: ${discountsCountQuery.data} Code${
            discountsCountQuery.data > 1 ? 's' : ''
          }`}
          to="../details/settings?p=shipping-and-discounts"
        />
      )}
      {!!collection?.payer_identify && (
        <ManagerAccessEditLink
          label="Visitor Report: Enabled"
          to="../details/settings?p=access-and-timing"
        />
      )}
    </WebUI.Panel>
  )
}

// MARK: – CloseCollectionAlert

interface CloseCollectionAlertProps extends WebUI.AlertProps {
  collectionId: number
}

const CloseCollectionAlert: React.FC<CloseCollectionAlertProps> = ({
  collectionId,
  ...restProps
}) => {
  const closeCollectionMutation = useCloseTabMutation()
  return (
    <WebUI.Alert aria-label="Close collection confirmation" {...restProps}>
      <WebUI.AlertHeader>Close Collection?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={`Are you sure you'd like to close this collection and stop accepting payments? Please note, any scheduled recurring payments will continue.`}
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                closeCollectionMutation.mutateAsync({
                  pathParams: {
                    tabId: collectionId,
                  },
                })
              }
            >
              Close collection
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}

// MARK: – WithdrawButton

const WithdrawButton: React.FC<
  Omit<LinkButtonProps, 'to'> & React.ComponentPropsWithoutRef<'a'>
> = ({className, disabled, ...restProps}) => {
  const location = useLocation()
  const [managerRoleQuery] = useManagerRoleQuery()
  const {data: hasWithdrawalMethod} = api.externalAccounts.list.useQuery(
    undefined,
    {
      enabled:
        !managerRoleQuery.isPending &&
        (managerRoleQuery.data == null ||
          managerRoleQuery.data.permissions.role === 'admin'),
      select: ({banks, cards}) => banks.length > 0 || cards.length > 0,
    },
  )

  return (
    <LinkButton
      className={WebUI.cn(
        'h-[2.25rem] rounded border-none bg-orange-50 px-5 text-ds-sm text-natural-100',
        'hover:bg-orange-50 hover:text-natural-100 active:bg-orange-50 aria-haspopup:aria-expanded:bg-orange-50 data-[active]:bg-orange-50',
        className,
      )}
      variant="default"
      to={hasWithdrawalMethod ? '/withdraw' : 'my-account/withdrawal-settings'}
      state={{prevPath: location.pathname}}
      disabled={disabled || (!!managerRoleQuery.data && !hasWithdrawalMethod)}
      {...restProps}
    >
      {hasWithdrawalMethod ? 'Transfer Money' : 'Add Bank'}
    </LinkButton>
  )
}

// MARK: – ManagerAccessEditLink

interface ManagerAccessEditLinkProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label: React.ReactNode
  to: To
}

const ManagerAccessEditLink: React.FC<ManagerAccessEditLinkProps> = ({
  label,
  to,
  className,
  ...restProps
}) => (
  <WebUI.HStack className={WebUI.cn('gap-1', className)} {...restProps}>
    <p className="font-light text-ds-sm">{label}</p>
    <Link className="text-ds-sm" variant="primary" to={to}>
      edit
    </Link>
  </WebUI.HStack>
)
