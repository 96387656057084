import {forwardRef} from 'react'

import {PhosphorIcon} from '../icons'
import {DialogDisclosure, DialogInstance} from './Dialog'
import {IconButton} from './IconButton'
import {Modal, ModalProps} from './Modal'
import {cn} from '../utils'

export interface DrawerProps extends ModalProps {
  disclosureClassName?: string
}

export const Drawer = forwardRef<DialogInstance, DrawerProps>(
  (
    {
      'aria-label': ariaLabel = 'Navigation menu',
      initialVisible = false,
      contentViewAppearance = 'leftToRight',
      disclosureClassName,
      disclosure = (
        <DialogDisclosure
          className={cn(
            'absolute top-0 left-0 rounded-t-none rounded-l-none p-2 text-aquaLight shadow-[2px_2px_8px_0px_rgba(0,0,0,0.2)]',
            disclosureClassName,
          )}
          as={IconButton}
          variant="default"
        >
          <PhosphorIcon
            className="Drawer-disclosureIcon"
            icon="caret-right-fill"
          />
        </DialogDisclosure>
      ),
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <Modal
      ref={forwardedRef}
      aria-label={ariaLabel}
      className={cn('Drawer', className)}
      initialVisible={initialVisible}
      contentViewAppearance={contentViewAppearance}
      disclosure={disclosure}
      {...restProps}
    />
  ),
)
