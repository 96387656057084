import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {formatItemAmount, getItemAmountType} from './ItemAmountDisplay'

export interface ItemViewPriceProps
  extends React.ComponentPropsWithoutRef<'div'> {
  itemView: Api.PublicTabItem
  listing?: Api.VariantListing | null
}

const ItemViewPrice = ({
  itemView,
  listing,
  className,
  ...restProps
}: ItemViewPriceProps) => {
  const amountType = itemView.options.variants?.enabled
    ? 'listing'
    : itemView.amount_type

  if (amountType === 'open') {
    return (
      <span
        className={WebUI.cn('font-normal text-ds-sm', className)}
        {...restProps}
      >
        Enter Amount
      </span>
    )
  }

  // biome-ignore lint/style/noNonNullAssertion:
  const fomattedAmount = formatItemViewPrice({itemView, listing})!
  const retailPrice = listing ? listing.retailPrice : itemView.retailPrice
  const retailPriceVisible =
    !!retailPrice && Number(fomattedAmount) !== retailPrice

  return (
    <WebUI.HStack className={WebUI.cn('gap-2', className)} {...restProps}>
      {retailPriceVisible && (
        <span className="font-light text-ds-sm text-gray400 line-through">
          {Util.formatAmount(retailPrice)}
          {itemView.options.variants?.enabled &&
            Util.isArrayValuesIdentical(
              itemView.options.variants?.listings
                .map((l) => l.retailPrice)
                .filter((rp) => rp != null) ?? [],
            ) &&
            '+'}
        </span>
      )}
      <span className="font-normal text-ds-sm">
        {fomattedAmount}
        {itemView.options.variants?.enabled &&
          Util.isArrayValuesIdentical(
            itemView.options.variants.listings.map((l) => l.amount),
          ) &&
          '+'}
      </span>
    </WebUI.HStack>
  )
}

// MARK: – Helpers

function formatItemViewPrice({
  itemView,
  listing,
}: {
  itemView: Api.PublicTabItem
  listing?: Api.VariantListing | null
}) {
  if (getItemAmountType(itemView) === 'open') {
    return null
  }

  return formatItemAmount(itemView, {listingUuid: listing?.uuid})
}

export default ItemViewPrice
