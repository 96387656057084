import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik} from '@cheddarup/react-util'
import {api, useUpdateBrandingMutation} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import {useRef} from 'react'
import {brandKitPalettes, defaultBrandKitColorMap} from '@cheddarup/core'
import {Link} from 'src/components/Link'

const GroupPagePalettePage = () => {
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })

  return (
    <AccountSettingsContentLayout
      heading={
        <div className="flex flex-row items-center gap-4">
          <WebUI.Heading as="h2">Group Page Palette</WebUI.Heading>
          {!isSubscribedToTeamQuery.data && (
            <PlanUpgradeButton upgradeTo="team" asPaidBadge />
          )}
        </div>
      }
      body={
        <span className="text-ds-base">
          Match your branding with custom background and button colors on your
          Group Page.{' '}
          <Link variant="primary" to="group-page-quick-tour">
            Learn more
          </Link>
        </span>
      }
    >
      <BradingColorPickers />
    </AccountSettingsContentLayout>
  )
}

// MARK: – BradingColorPickers

interface BradingColorPickersProps
  extends React.ComponentPropsWithoutRef<'div'> {}

const BradingColorPickers = ({
  className,
  ...restProps
}: BradingColorPickersProps) => (
  <div className={WebUI.cn('flex flex-col gap-5', className)} {...restProps}>
    <AccountSettingsContentCard heading="Primary">
      <span className="text-ds-sm">
        A primary color will be used for buttons and select backgrounds on your
        Group Page
      </span>

      <BrandingColorPickerPopoverForm
        className="self-start"
        brandingColorKey="primary_color"
        heading="Primary"
        description="This color will be used for buttons. Choose a color that stands out:"
      />
    </AccountSettingsContentCard>
    <AccountSettingsContentCard heading="Secondary">
      <span className="text-ds-sm">
        A secondary color will be used for select backgrounds on your Group
        Page.
      </span>

      <BrandingColorPickerPopoverForm
        className="self-start"
        brandingColorKey="secondary_color"
        heading="Secondary"
        description="Select a compliment to your primary color or brand."
        placement="top"
      />
    </AccountSettingsContentCard>
    <AccountSettingsContentCard heading="Neutral">
      <span className="text-ds-sm">
        A neutral color for selected background sections of your on Group Page.
      </span>

      <BrandingColorPickerPopoverForm
        className="self-start"
        brandingColorKey="neutral_color"
        heading="Neutral"
        description="We recommend a lighter color for better legibility and visual balance."
        placement="top"
      />
    </AccountSettingsContentCard>
  </div>
)

// MARK: – BrandingColorPickerPopoverForm

interface BrandingColorPickerPopoverFormProps
  extends React.ComponentPropsWithoutRef<'button'> {
  brandingColorKey: keyof Pick<
    Api.UserBranding,
    'primary_color' | 'secondary_color' | 'neutral_color'
  >
  heading?: React.ReactNode
  placement?: WebUI.PopoverProps['placement']
  description: string
}

const BrandingColorPickerPopoverForm = ({
  brandingColorKey,
  heading,
  defaultValue,
  value: valueProp,
  style,
  onClick,
  placement = 'bottom',
  description,
  ...restProps
}: BrandingColorPickerPopoverFormProps) => {
  const navigate = useNavigate()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const brandingQuery = api.userBrandings.detail.useQuery()
  const updateBrandingMutation = useUpdateBrandingMutation()
  const popoverRef = useRef<WebUI.PopoverInstance>(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      color:
        brandingQuery.data?.[brandingColorKey] ??
        defaultBrandKitColorMap[brandingColorKey],
    },
    onSubmit: async (values) => {
      await updateBrandingMutation.mutateAsync({
        body: {
          [brandingColorKey]: values.color,
        },
      })

      popoverRef.current?.hide()
    },
  })

  return (
    <WebUI.ColorPickerPopover
      ref={popoverRef}
      disclosure={
        <WebUI.PopoverDisclosure
          style={{
            backgroundColor: formik.values.color,
            color: Util.getReadableColor(formik.values.color),
            ...style,
          }}
          onClick={(event) => {
            onClick?.(event)
            if (event.defaultPrevented) {
              return
            }

            if (!isSubscribedToTeamQuery.data) {
              event.preventDefault()
              navigate('i/plans?recommendedPlan=team')
            }
          }}
          {...restProps}
        >
          {formik.values.color}
        </WebUI.PopoverDisclosure>
      }
      value={formik.values.color}
      onValueChange={(newColor) => formik.setFieldValue('color', newColor)}
      placement={placement}
    >
      <form
        className="flex flex-col items-start gap-6 p-3 sm:max-w-72"
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        {typeof heading === 'string' ? (
          <WebUI.Heading className="mx-1 font-bold" as="h5">
            {heading}
          </WebUI.Heading>
        ) : (
          heading
        )}
        <WebUI.Text className="font-light text-ds-sm">{description}</WebUI.Text>

        <div className="flex flex-col gap-4">
          <WebUI.ColorSwatchPicker>
            {(brandingColorKey === 'neutral_color'
              ? brandKitPalettes.neutral
              : brandKitPalettes.tint
            ).map((color) => (
              <WebUI.ColorSwatchPickerItem
                key={color}
                className="h-6 w-6"
                color={color}
              />
            ))}
          </WebUI.ColorSwatchPicker>

          <WebUI.ColorSaturationArea className="!h-28 !w-28" />
          <WebUI.ColorHueSlider />

          <WebUI.ColorInput size="compact" />
        </div>

        <WebUI.Button type="submit" loading={formik.isSubmitting}>
          Save
        </WebUI.Button>
      </form>
    </WebUI.ColorPickerPopover>
  )
}

export default GroupPagePalettePage
