import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {SignupViewList} from 'src/components/SignupViewList'
import * as Util from '@cheddarup/util'
import {SignupSpotsViewFormModal} from 'src/components/SignupSpotsViewForm'

import useCart from './hooks/useCart'
import usePublicCollection from './hooks/usePublicCollection'

function SignupViewPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = useParams()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const signupSpotsViewFormModalRef = useRef<WebUI.DialogInstance>(null)
  const [spotsInEdit, setSpotsInEdit] = useState<Api.PublicSignUpSpot[]>([])
  const signupId = Number(urlParams.signup)

  // biome-ignore lint/style/noNonNullAssertion:
  const signup = publicCollection.signups.find((f) => f.id === signupId)!
  const signupTimeSlotIds = signup.visible_spots
    .flatMap((spot) => spot.time_slots)
    .map((ts) => ts.id)
  const isSignupInCart =
    cart?.time_slots.some((cTs) =>
      signupTimeSlotIds.includes(cTs.time_slot.id),
    ) ?? false

  return (
    <WebUI.Modal
      aria-label="Signup"
      ref={modalRef}
      className="[&_>_.ModalContentView]:overflow-auto sm:[&_>_.ModalContentView]:max-w-screen-md sm:[&_>_.ModalContentView]:rounded-extended"
      preventBodyScroll
      onDidHide={() => navigate({pathname: '..', search: location.search})}
    >
      {(rootDialog) => (
        <WebUI.SelectionStateProvider>
          {(selection) => {
            const isSelectionEmpty =
              Object.keys(
                Util.pickBy(selection.selectionMap, (value) => value === true),
              ).length === 0

            return (
              <>
                <WebUI.VStack className="min-h-0 shrink-0 grow basis-full">
                  <WebUI.VStack className="grow gap-8 py-4">
                    <WebUI.Text className="px-6 text-ds-sm">Sign Up</WebUI.Text>
                    <SignupViewList
                      cart={cart}
                      signup={signup}
                      onEdit={(timeSlot, spot) => {
                        setSpotsInEdit([{...spot, time_slots: [timeSlot]}])
                        signupSpotsViewFormModalRef.current?.show()
                      }}
                    />
                  </WebUI.VStack>
                  <WebUI.HStack
                    className={
                      'sticky bottom-0 justify-end border-t bg-natural-100 p-4'
                    }
                  >
                    <SignupSpotsViewFormModal
                      ref={signupSpotsViewFormModalRef}
                      disclosure={
                        <WebUI.DialogDisclosure
                          className="w-full sm:w-auto"
                          size="large"
                          roundness="capsule"
                          disabled={!isSignupInCart && isSelectionEmpty}
                          onClick={(event) => {
                            if (isSignupInCart && isSelectionEmpty) {
                              event.preventDefault()
                              rootDialog.hide()
                            }
                          }}
                        >
                          Next
                        </WebUI.DialogDisclosure>
                      }
                      signup={signup}
                      spots={
                        spotsInEdit.length > 0
                          ? spotsInEdit
                          : signup.visible_spots
                              .map((spot) => ({
                                ...spot,
                                time_slots: spot.time_slots.filter(
                                  (ts) => !!selection.selectionMap[ts.id],
                                ),
                              }))
                              .filter((spot) => spot.time_slots.length > 0)
                      }
                      onDidSubmit={() => rootDialog.hide()}
                    />
                  </WebUI.HStack>
                </WebUI.VStack>

                <WebUI.ModalCloseButton className="xs:fixed" />
              </>
            )
          }}
        </WebUI.SelectionStateProvider>
      )}
    </WebUI.Modal>
  )
}

export default SignupViewPage
