import {EmptyObject, ExtractParams, replacePathTokens} from '@cheddarup/util'
import qs from 'qs'

interface QueryMutationFnOptions<TPath> {
  method?: string
  pathParams?: ExtractParams<TPath>
  queryParams?: Record<string, any> | null
  body?: unknown
  signal?: AbortSignal
  headers?: Record<
    string,
    string | string[] | number | boolean | null | undefined
  >
}

export type CheddarUpApiFetcher = ReturnType<typeof makeCheddarUpApiFetcher>

export function makeCheddarUpApiFetcher(config: {
  apiHost: string
  baseFetcher: (
    url: string,
    options: QueryMutationFnOptions<string> & {path: string},
  ) => Promise<unknown>
}) {
  const enhancedFetcher = <TPath extends string>(
    pathTemplate: TPath,
    ...[options]: ExtractParams<TPath> extends EmptyObject
      ? [QueryMutationFnOptions<TPath>?]
      : [QueryMutationFnOptions<TPath>]
  ) => {
    const path = options?.pathParams
      ? replacePathTokens(pathTemplate, options.pathParams as any)
      : pathTemplate
    const queryParamsStringified = qs.stringify(options?.queryParams ?? {}, {
      skipNulls: true,
    })
    const url = `${config.apiHost}${path}${
      queryParamsStringified ? `?${queryParamsStringified}` : ''
    }`

    return config.baseFetcher(url, {...options, path})
  }

  return enhancedFetcher
}
