import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {withdrawalSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/withdrawals',
    responseSchema: withdrawalSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/withdrawals',
    method: 'POST',
    bodySchema: withdrawalSchema.partial(),
    responseSchema: withdrawalSchema.extend({error: z.any()}),
  }),
  resendToRecipient: makeEndpoint({
    path: 'users/tabs/:tabId/withdrawals/:withdrawalId/resend_to_recipient',
    method: 'POST',
    bodySchema: z.object({recipient_email: z.string()}),
    responseSchema: z.any(),
  }),
  testTango: makeEndpoint({
    path: 'users/tabs/:tabId/withdrawals/test_tango',
    method: 'POST',
    bodySchema: z.object({
      amount: z.number().or(z.string()),
      payout_method: z.literal('tango'),
      from: z.string(),
      recipient_name: z.string(),
      recipient_email: z.string(),
      recipient_message: z.string(),
      confirm_email: z.string(),
    }),
    responseSchema: withdrawalSchema.extend({error: z.any()}),
  }),
}
