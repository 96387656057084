import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  authTokenSchema,
  orgMetadataSchema,
  sessionSchema,
  userProfileSchema,
  userUiClientFlagsSchema,
} from '../schemas'

export default {
  authenticatedParty: makeEndpoint({
    path: 'authenticated_party',
    responseSchema: authTokenSchema,
  }),
  personaInquiry: makeEndpoint({
    path: 'users/persona_inquiry',
    responseSchema: sessionSchema,
  }),
  update: makeEndpoint({
    path: 'user',
    method: 'PATCH',
    bodySchema: userProfileSchema
      .extend({
        group_page_logo_id: z.number().nullish(),
        profile_pic_id: z.number().nullish(),
        profile: z.object({
          uiClientFlags: userUiClientFlagsSchema,
          phone: z.object({
            country_code: z.string().optional(),
            phone_number: z.string().optional(),
          }),
          security: userProfileSchema.shape.profile.shape.security.optional(),
        }),
        dob: z
          .object({
            day: z.number().nullable(),
            month: z.number().nullable(),
            year: z.number().nullable(),
          })
          .nullable(),
        security: z
          .object({
            token: z.string(),
          })
          .optional(),
        person_token: z.string().optional(),
        account_token: z.string().optional(),
      })
      .deepPartial()
      .passthrough(),
    responseSchema: sessionSchema,
  }),
  delete: makeEndpoint({
    path: 'user',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  updatePassword: makeEndpoint({
    path: 'password',
    method: 'PATCH',
    bodySchema: z.object({
      user: z.object({
        current_password: z.string(),
        password: z.string(),
        password_confirmation: z.string(),
      }),
      security: z
        .object({
          token: z.string(),
        })
        .optional(),
    }),
    responseSchema: z.any(),
  }),
  sendMobileAppLink: makeEndpoint({
    path: 'user/send_link',
    method: 'POST',
    bodySchema: z.object({
      phone: z.string(),
    }),
    responseSchema: z.object({success: z.boolean()}),
  }),
  resetAccount: makeEndpoint({
    path: 'user/reset_stripe_person',
    method: 'POST',
    bodySchema: z.object({
      first_name: z.string(),
      last_name: z.string(),
      email: z.string().optional(),
    }),
    responseSchema: z.any(),
  }),
  verifyEmail: makeEndpoint({
    path: 'users/email_verify/verify',
    method: 'POST',
    bodySchema: z.object({
      code: z.string(),
    }),
    responseSchema: z.any(),
  }),
  startVerification: makeEndpoint({
    path: 'users/verify/start',
    method: 'POST',
    bodySchema: z.object({
      via: z.enum(['sms', 'call']),
      security: z
        .object({
          emailToken: z.string(),
        })
        .optional(),
    }),
    responseSchema: z.any(),
  }),
  startCodeVerification: makeEndpoint({
    path: 'users/verify/code',
    method: 'POST',
    responseSchema: z.any(),
  }),
  verifyCode: makeEndpoint({
    path: 'users/verify/verify',
    method: 'POST',
    bodySchema: z.object({
      token: z.string(),
      security: z.object({
        emailToken: z.string(),
      }),
    }),
    responseSchema: z.object({
      reset_code: z.string(),
      success: z.boolean(),
    }),
  }),
  resetVerification: makeEndpoint({
    path: 'users/verify/reset',
    method: 'POST',
    bodySchema: z.object({
      reset_code: z.string().optional(),
      password: z.string(),
      resetSecondaryEmail: z.boolean().optional(),
      security: z
        .object({
          token: z.string(),
        })
        .optional(),
    }),
    responseSchema: z.any(),
  }),
  startEmailVerification: makeEndpoint({
    path: 'users/email_verify/start',
    method: 'POST',
    responseSchema: z.any(),
  }),
  updateClientFlags: makeEndpoint({
    path: 'user/client_flags',
    method: 'PATCH',
    bodySchema: userProfileSchema
      .extend({
        group_page_logo_id: z.number().nullable(),
      })
      .deepPartial(),
    responseSchema: sessionSchema,
  }),
  orgMetadata: makeEndpoint({
    path: 'users/org/:orgSlug/metadata',
    responseSchema: orgMetadataSchema,
  }),
}
