import * as WebUI from '@cheddarup/web-ui'
import React, {useImperativeHandle, useRef} from 'react'

import {AppHeaderMobile, AppHeaderMobileProps} from '../AppHeader'
import {
  CollectionsSidebar,
  CollectionsSidebarInstance,
} from './CollectionsSidebar'

export interface CollectionsMobileLayoutInstance {
  showFinishSetup: () => void
}

export interface CollectionsMobileLayoutProps
  extends React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode
}

const CollectionsMobileLayout = React.forwardRef<
  CollectionsMobileLayoutInstance,
  CollectionsMobileLayoutProps
>(({children, ...restProps}, forwardedRef) => {
  const appHeaderRef = useRef<CollectionsHeaderMobileInstance>(null)

  useImperativeHandle(forwardedRef, () => ({
    showFinishSetup: () => appHeaderRef.current?.showFinishSetup(),
  }))

  return (
    <WebUI.VStack {...restProps}>
      <CollectionsHeaderMobile ref={appHeaderRef} />
      {children}
    </WebUI.VStack>
  )
})

export interface CollectionsHeaderMobileInstance {
  showFinishSetup: () => void
}

export interface CollectionsHeaderMobileProps extends AppHeaderMobileProps {}

export const CollectionsHeaderMobile = React.forwardRef<
  CollectionsHeaderMobileInstance,
  CollectionsHeaderMobileProps
>((props, forwardedRef) => {
  const collectionsSidebarRef = useRef<CollectionsSidebarInstance>(null)

  useImperativeHandle(forwardedRef, () => ({
    showFinishSetup: () => collectionsSidebarRef.current?.showFinishSetup(),
  }))

  return (
    <AppHeaderMobile
      left={<CollectionsSidebar ref={collectionsSidebarRef} />}
      {...props}
    />
  )
})

export default CollectionsMobileLayout
