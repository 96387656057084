import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useRef} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageSectionMutation,
  useDeleteGroupPageSpotlightItemMutation,
  useUpdateGroupPageSectionMutation,
  useUpdateGroupPageSpotlightItemMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {SharpImage} from 'src/components/SharpImage'
import * as Util from '@cheddarup/util'
import {GroupPageSectionHeader} from '../components'
import {useUserSlug} from 'src/components/ManageRoleProvider'

interface CollectionSpotlightFormValues {
  details: {
    removeWhenClosed: boolean
  }
}

const AddCollectionSpotlightPage = () => {
  const navigate = useNavigate()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const userSlug = useUserSlug()
  const {data: collectionSpotlight} = api.groupPageSections.detail.useQuery({
    pathParams: {
      sectionName: 'collection_spotlight',
    },
  })

  const sortedSpotlightItems = useMemo(
    () =>
      Util.sort(collectionSpotlight?.spotlight_items ?? []).asc(
        (si) => si.position,
      ),
    [collectionSpotlight?.spotlight_items],
  )

  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()
  const updateGroupPageSpotlightItemMutation =
    useUpdateGroupPageSpotlightItemMutation()

  const formik = useFormik<CollectionSpotlightFormValues>({
    enableReinitialize: true,
    initialValues: {
      details: {
        removeWhenClosed:
          collectionSpotlight?.details.removeWhenClosed ?? false,
      },
    },
    onSubmit: async (values) => {
      const saveGroupPageSectionMutation = collectionSpotlight
        ? updateGroupPageSectionMutation
        : createGroupPageSectionMutation
      await saveGroupPageSectionMutation.mutateAsync({
        pathParams: {sectionName: 'collection_spotlight'},
        body: {
          ...values,
          userSlug,
        },
      })
      dialogRef.current?.hide()
    },
  })

  return (
    <WebUI.Modal
      ref={dialogRef}
      aria-label="Collection Spotlight form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-xl"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader>
        <GroupPageSectionHeader
          subheading="Inspire participation and giving with a slideshow of up to three
              collections."
          quickTourSlideId="spotlight"
        >
          Collection Spotlight Section
        </GroupPageSectionHeader>
      </WebUI.ModalHeader>
      <div className="flex grow flex-col overflow-y-auto">
        <div className="flex flex-row px-9 py-6">
          <WebUI.Switch
            checked={formik.values.details.removeWhenClosed}
            onChange={(event) =>
              formik.setFieldValue(
                'details.removeWhenClosed',
                event.target.checked,
              )
            }
            size="compact"
          >
            Remove spotlight from Group Page when collection has closed
          </WebUI.Switch>
        </div>
        <WebUI.Separator />
        <div className="flex flex-col gap-6 px-9 py-6">
          <span className="text-ds-sm">Click and drag to reorder.</span>
          <div className="flex flex-row flex-wrap gap-5">
            <WebUI.DragAndDrop
              dragOverlayPortal={false}
              modifiers={[WebUI.followMouseModifier]}
              onDragEnd={(event) => {
                if (!event.over) {
                  return
                }
                const oldOrder = event.over.data.current?.sortable.items ?? []
                const newOrder = WebUI.arrayMoveByValue(
                  oldOrder,
                  event.active.id,
                  event.over.id,
                )
                const spotlightItemId = event.active.id
                const spotlightItemNewPos =
                  newOrder.indexOf(spotlightItemId) + 1
                updateGroupPageSpotlightItemMutation.mutate({
                  pathParams: {id: spotlightItemId},
                  body: {position: spotlightItemNewPos},
                })
              }}
            >
              <WebUI.SortableContext
                items={sortedSpotlightItems}
                strategy={WebUI.rectSortingStrategy}
              >
                {({items: siIds}) =>
                  siIds.map((siId) => {
                    const si = sortedSpotlightItems.find((si) => si.id === siId)
                    if (!si) {
                      return null
                    }

                    return (
                      <CollectionSpotlightItemCard
                        key={siId}
                        id={siId}
                        spotlightItem={si}
                      />
                    )
                  })
                }
              </WebUI.SortableContext>
            </WebUI.DragAndDrop>

            {sortedSpotlightItems.length < 3 && <AddCollectionSpotlightPanel />}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end border-t bg-natural-100 px-4 py-5">
        <WebUI.Button
          type="submit"
          variant="primary"
          size="large"
          className="w-48"
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          Save
        </WebUI.Button>
      </div>
    </WebUI.Modal>
  )
}

// MARK: - CollectionSpotlightItemCard

interface CollectionSpotlightItemCardProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Pick<WebUI.SortableProps, 'id'>
  > {
  spotlightItem: Api.GroupPageCollectionSpotlightItem
}

const CollectionSpotlightItemCard: React.FC<
  CollectionSpotlightItemCardProps
> = ({spotlightItem, className, ...restProps}) => {
  const media = WebUI.useMedia()
  const deleteSpotlightItemMutation = useDeleteGroupPageSpotlightItemMutation()

  return (
    <WebUI.Sortable
      className={WebUI.cn('max-w-full', className)}
      draggable={false}
      {...restProps}
    >
      {({dragListeners}) => (
        <WebUI.Card
          className="flex w-96 max-w-full flex-col gap-6 p-7 sm:h-80"
          dragHandleVisible
          dragListeners={dragListeners}
          accessoryView={
            <WebUI.ActionGroup>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="x" />}
                execute={() =>
                  deleteSpotlightItemMutation.mutateAsync({
                    pathParams: {id: spotlightItem.id},
                  })
                }
              >
                Delete
              </WebUI.Action>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="pencil" />}
                as={LinkButton}
                to={`items/${spotlightItem.id}`}
              >
                Edit
              </WebUI.Action>
            </WebUI.ActionGroup>
          }
        >
          <WebUI.Text className="text-ds-sm">
            {spotlightItem.collection?.name}
          </WebUI.Text>
          <div className="flex grow flex-col gap-3">
            <Link to={`items/${spotlightItem.id}`}>
              {spotlightItem.headline}
            </Link>
            <div className="flex flex-col-reverse justify-between gap-2 sm:flex-row">
              <WebUI.Text className="font-light text-ds-sm">
                {spotlightItem.description}
              </WebUI.Text>
              {!!spotlightItem.image && (
                <SharpImage
                  className="shrink-0"
                  image={spotlightItem.image}
                  width={media.sm ? 130 : '100%'}
                  height={80}
                  alt="Spotlight image"
                />
              )}
            </div>
            <WebUI.Button
              className="mt-auto self-start"
              variant="secondary"
              disabled
            >
              {spotlightItem.button_text}
            </WebUI.Button>
          </div>
        </WebUI.Card>
      )}
    </WebUI.Sortable>
  )
}

// MARK: - AddCollectionSpotlightPanel

const AddCollectionSpotlightPanel: React.FC = () => (
  <WebUI.Panel className="flex h-80 w-96 flex-col items-center justify-center gap-6">
    <LinkButton
      as={WebUI.IconButton}
      to="add-item"
      variant="default"
      className="h-15 w-15 rounded-full"
    >
      <WebUI.PhosphorIcon
        icon="plus-bold"
        width={30}
        height={30}
        className="text-natural-100"
      />
    </LinkButton>
    <WebUI.Text className="text-ds-sm">Add Collection Spotlight</WebUI.Text>
  </WebUI.Panel>
)

export default AddCollectionSpotlightPage
