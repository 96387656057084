import {
  capitalize,
  firstBy,
  formatDateAs,
  keys,
  omit,
  sort,
  sumBy,
} from '@cheddarup/util'

export function getTabDateLabel(tab: Api.Tab) {
  if (tab.closed_at) {
    return `Closed ${formatDateAs(tab.closed_at)}`
  }
  if (tab.timing?.opens && tab.timing?.closes) {
    const current = new Date()

    const opens = new Date(tab.timing.opens)
    if (current < opens) {
      return `Opens ${formatDateAs(opens)}`
    }

    const closes = new Date(tab.timing.closes)
    if (closes < current) {
      return `Ended ${formatDateAs(closes)}`
    }
  }

  return `Created ${formatDateAs(tab.created_at)}`
}

// MARK: – Status

export function computeTabStatus(tab: Pick<Api.Tab, 'closed_at' | 'status'>) {
  return tab.closed_at ? 'closed' : tab.status ?? 'active'
}

export function formatTabStatus(...args: Parameters<typeof computeTabStatus>) {
  return capitalize(computeTabStatus(...args))
}

// MARK: – Disputes

export function isDisputedTab(tab: Api.Tab) {
  return !!tab.access.owner && tab.withdrawal_balance_available < 0
}

export function getDisputedTabs(tabs: Api.Tab[]) {
  return tabs.filter(isDisputedTab)
}

export function getTotalDisputableAmount(tabs: Api.Tab[]) {
  return -sumBy(getDisputedTabs(tabs), (t) => t.withdrawal_balance_available)
}

export function getMostSignificantDisputedTab(tabs: Api.Tab[]) {
  const disputedTabs = getDisputedTabs(tabs)

  return firstBy(disputedTabs, (t) => t.withdrawal_balance_available) ?? null
}

const defaultInfoBlocksOrder = [
  'promoteSharing',
  'nonProfitStatus',
  'totalCollected',
  'paymentGoal',
  'payerList',
  'time',
  'location',
  'faqs',
] as const
export function getTabInfoBlocks(tab: Api.Tab) {
  const infoBlocskKeys = keys({
    ...omit(tab.options.infoBlockSettings ?? {}, ['video']),
    paymentGoal: tab.options.paymentGoal,
  })

  const isUnsorted =
    tab.options.infoBlockSettings == null ||
    Object.values(tab.options.infoBlockSettings).every(
      (infoBlock) => !infoBlock?.order,
    )

  if (isUnsorted) {
    return infoBlocskKeys
  }

  const sortedInfoBlocksKeys = sort(infoBlocskKeys).asc((key) => {
    if (key === 'paymentGoal') {
      return (
        (tab.options.paymentGoal?.order as number | undefined) ??
        defaultInfoBlocksOrder.indexOf(key)
      )
    }

    return (
      tab.options.infoBlockSettings?.[key]?.order ??
      defaultInfoBlocksOrder.indexOf(key)
    )
  })

  return sortedInfoBlocksKeys
}
