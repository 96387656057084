import {useEffect} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import {Logo} from 'src/components'

const ThankYouPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <WebUI.VStack className="grow">
      <WebUI.HStack className="bg-natural-95] min-h-17 items-center px-3 shadow-[0_1px_3px_#0000000a">
        <LinkButton to="/">
          <Logo />
        </LinkButton>
      </WebUI.HStack>

      <WebUI.VStack className="grow items-center justify-center gap-6">
        <WebUI.Heading className="text-center font-accent text-gray400 italic">
          Thank you for using Cheddar Up
        </WebUI.Heading>
        <WebUI.Text className="max-w-[420px] text-center font-light text-black">
          Your account has been deleted. We’re sorry to see you go, but we’re
          here should you need us in the future.
        </WebUI.Text>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default ThankYouPage
