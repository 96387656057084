import {
  BinaryFilter,
  Filter as CubeFilter,
  TimeDimensionRanged,
  UnaryFilter,
} from '@cubejs-client/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import {forwardRef, useState} from 'react'

import {Dimension, dimensionToSchema} from '../helpers/cube-schema'

export interface CubeFiltersProps
  extends Omit<WebUI.FiltersProps, 'filters' | 'onFiltersApply'> {
  filters: CubeFilter[]
  timeDimensions?: TimeDimensionRanged[]
  onFiltersApply?: (filters: {
    filters: CubeFilter[]
    timeDimensions: TimeDimensionRanged[]
  }) => void
}

export const CubeFilters = forwardRef<WebUI.FiltersInstance, CubeFiltersProps>(
  (
    {
      filters: cubeFilters,
      timeDimensions,
      onFiltersApply,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [filters, setFilters] = useState(
      cubeFiltersToFilterFields(cubeFilters, timeDimensions),
    )

    useUpdateEffect(() => {
      setFilters(cubeFiltersToFilterFields(cubeFilters, timeDimensions))
    }, [cubeFilters, timeDimensions])

    return (
      <WebUI.Filters
        ref={forwardedRef}
        filters={filters}
        onFiltersApply={(newFilters) => {
          const newFilterEntries = Object.entries(newFilters)

          const newCubeFilters = newFilterEntries
            .map(([fDim, f]) => fieldFilterToCubeFilter(fDim as Dimension, f))
            .filter((f) => !!f)
          const newCubeTimeDimensions = newFilterEntries
            .map(([fDim, f]) =>
              fieldFilterToCubeTimeDimension(fDim as Dimension, f),
            )
            .filter((f) => !!f)

          onFiltersApply?.({
            filters: newCubeFilters,
            timeDimensions: newCubeTimeDimensions,
          })
        }}
        {...restProps}
      >
        {children}
      </WebUI.Filters>
    )
  },
)

// MARK: – CubeFilterField

export interface CubeFilterFieldProps
  extends Omit<
    WebUI.FilterFieldEntryProps,
    'filterId' | 'dataType' | 'enumValues'
  > {
  dimension: Dimension
}

export const CubeFilterField = ({
  dimension,
  children,
  ...restProps
}: CubeFilterFieldProps) => {
  const dimensionSchema = dimensionToSchema[dimension]
  const cubeDataType = dimensionSchema?.dataType ?? 'number'

  if (cubeDataType === 'string') {
    throw new Error('string dataType not supported in filters')
  }

  const dataType = ({
    paymentMethod: 'checkbox',
    paymentStatus: 'checkbox',
    withdrawalStatus: 'checkbox',
    paymentSource: 'radio',
    collectionStatus: 'checkbox',
  }[cubeDataType as string] ?? cubeDataType) as WebUI.FilterDataType

  const enumValues = {
    paymentMethod: [
      {title: 'Credit Card', value: 'card'},
      {title: 'eCheck', value: 'echeck'},
      {title: 'Cash/Check', value: 'cash'},
    ],
    paymentStatus: [
      {title: 'Cleared', value: 'available'},
      {title: 'Pending', value: 'pending'},
      {title: 'Refunded', value: 'refunded'},
      {title: 'Disputed', value: 'disputed'},
      {title: 'Failed', value: 'failed'},
    ],
    withdrawalStatus: [
      {title: 'Pending', value: 'Pending'},
      {title: 'Paid', value: 'Paid'},
      {title: 'Declined', value: 'Declined'},
    ],
    paymentSource: [
      {title: 'Point of Sale', value: 'true'},
      {title: 'Online', value: 'false'},
    ],
    collectionStatus: [
      {title: 'Active', value: 'Active'},
      {title: 'Closed', value: 'Closed'},
      {title: 'Draft', value: 'Draft'},
    ],
  }[cubeDataType as string] as WebUI.FilterEnumValue[]

  return (
    <WebUI.FilterFieldEntry
      filterId={dimension}
      dataType={dataType}
      enumValues={enumValues}
      {...restProps}
    >
      {children ?? dimensionSchema?.defaultInflection ?? dimension}
    </WebUI.FilterFieldEntry>
  )
}

// MARK: – Helpers

const cubeFilterToFilterField = (
  cubeFilter: CubeFilter | TimeDimensionRanged,
): WebUI.FilterField | null => {
  if (
    'or' in cubeFilter ||
    ('and' in cubeFilter && cubeFilter.and.some((f) => 'or' in f))
  ) {
    throw new Error('`FilterField` doesn`t support `or` cube filter')
  }

  if ('and' in cubeFilter && cubeFilter.and) {
    const and = cubeFilter.and as [
      BinaryFilter | UnaryFilter,
      BinaryFilter | UnaryFilter,
    ]

    const operators = and.map((f) => f.operator)

    if (
      and.length !== 2 ||
      !operators.includes('lte') ||
      !operators.includes('gte')
    ) {
      throw new Error(
        '`FilterField` only supports gte, lte subfilters in `and`',
      )
    }

    const dimensions = and.map((f) => {
      if (f.dimension == null) {
        throw new Error('`dimension` not defined')
      }
      return f.dimension
    }) as [Dimension, Dimension]
    const values = and.map((f) => {
      if (f.values?.[0] == null) {
        throw new Error('`values` not defined')
      }
      return f.values[0]
    }) as [string, string]

    if (dimensions[0] !== dimensions[1]) {
      throw new Error(
        '`FitlerField` only supports filtering by one dimension in `and`',
      )
    }

    return {
      operator: 'between',
      values,
    }
  }

  if ('dateRange' in cubeFilter && Array.isArray(cubeFilter.dateRange)) {
    if (cubeFilter.dateRange.length < 2) {
      return null
    }

    const dates = cubeFilter.dateRange.map((dISO) => new Date(dISO)) as [
      Date,
      Date,
    ]
    const isos = cubeFilter.dateRange

    let operator: WebUI.FilterFieldOperator = 'inDateRange'
    if (Util.differenceInDays(dates[1], dates[0]) <= 1) {
      operator = 'dateEquals'
    }
    if (Util.endOfDay(new Date()).toISOString() === isos[1]) {
      operator = 'afterDate'
    }
    if (Util.startOfDay(new Date(1970, 1, 31)).toISOString() === isos[0]) {
      operator = 'beforeDate'
    }

    return {
      operator,
      values: cubeFilter.dateRange,
    }
  }

  return cubeFilter as WebUI.FilterField
}

const fieldFilterToCubeFilter = (
  dimension: Dimension,
  filterFieldFilter: WebUI.FilterField,
): CubeFilter | null => {
  switch (filterFieldFilter.operator) {
    case 'between': {
      const [lowerValue, upperValue] = filterFieldFilter.values
      if (!lowerValue || !upperValue) {
        return null
      }

      return {
        and: [
          {
            dimension,
            operator: 'gte',
            values: [lowerValue],
          },
          {
            dimension,
            operator: 'lte',
            values: [upperValue],
          },
        ],
      }
    }
    case 'dateEquals':
    case 'inDateRange':
    case 'afterDate':
    case 'beforeDate':
      return null // these are handled by time dimensions
    default:
      if (filterFieldFilter.values.every((v) => !v)) {
        return null
      }

      return {
        dimension,
        ...filterFieldFilter,
      } as CubeFilter
  }
}

const fieldFilterToCubeTimeDimension = (
  dimension: Dimension,
  filterFieldFilter: WebUI.FilterField,
): TimeDimensionRanged | null => {
  switch (filterFieldFilter.operator) {
    case 'inDateRange':
    case 'afterDate':
    case 'beforeDate':
    case 'dateEquals': {
      const [lowerValue, upperValue] = filterFieldFilter.values.map((iso) =>
        iso ? Util.formatISO(new Date(iso)) : iso,
      )
      if (!lowerValue || !upperValue) {
        return null
      }
      return {
        dimension,
        dateRange: [lowerValue, upperValue],
      }
    }
    default:
      return null
  }
}

const cubeFiltersToFilterFields = (
  cubeFilters: CubeFilter[],
  timeDimensions: TimeDimensionRanged[] = [],
) =>
  Util.pickBy(
    {
      ...Util.mapToObj(cubeFilters, (cf) => {
        const dim = getCubeFilterDimension(cf)
        if (dim == null) {
          throw new Error('Failed to find `dimension` or `member` in filter')
        }
        return [dim, cubeFilterToFilterField(cf)]
      }),
      ...Util.mapToObj(timeDimensions, (td) => [
        td.dimension,
        cubeFilterToFilterField(td),
      ]),
    },
    (ff) => !!ff,
  )

const getCubeFilterDimension = (cubeFilter: CubeFilter): string | null => {
  if ('dimension' in cubeFilter && cubeFilter.dimension) {
    return cubeFilter.dimension
  }
  if ('member' in cubeFilter && cubeFilter.member) {
    return cubeFilter.member
  }
  if ('and' in cubeFilter && cubeFilter.and[0]) {
    return getCubeFilterDimension(cubeFilter.and[0])
  }
  if ('or' in cubeFilter && cubeFilter.or[0]) {
    return getCubeFilterDimension(cubeFilter.or[0])
  }

  return null
}
