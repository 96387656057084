import * as WebUI from '@cheddarup/web-ui'
import React, {useImperativeHandle, useRef} from 'react'

import {
  CollectionsHeaderMobile,
  CollectionsHeaderMobileInstance,
} from './CollectionsMobileLayout/CollectionsMobileLayout'
import {
  SlideOutNav,
  SlideOutNavLinkButton,
  SlideOutNavProps,
} from './SlideOutNav'
import {AppHeader} from './AppHeader'
import CollectionAppBanner from './CollectionAppBanner'
import {useManagerRole, useManagerRoleId} from './ManageRoleProvider'

export interface CollectionsLayoutInstance {
  showFinishSetup: () => void
}

export interface CollectionsLayoutProps
  extends React.ComponentPropsWithoutRef<'div'> {
  banner?: React.ReactNode
}

const CollectionsLayout = React.forwardRef<
  CollectionsLayoutInstance,
  CollectionsLayoutProps
>(({banner, className, children, ...restProps}, forwardedRef) => {
  const appHeaderMobileRef = useRef<CollectionsHeaderMobileInstance>(null)
  const media = WebUI.useMedia()

  useImperativeHandle(forwardedRef, () => ({
    showFinishSetup: () => appHeaderMobileRef.current?.showFinishSetup(),
  }))

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'CollectionsLayout',
        'max-h-full shrink-0 grow basis-auto',
        className,
      )}
      {...restProps}
    >
      <WebUI.VStack>
        {media.sm ? (
          <AppHeader />
        ) : (
          <CollectionsHeaderMobile ref={appHeaderMobileRef} />
        )}
        <CollectionAppBanner />
      </WebUI.VStack>

      {banner}

      {media.sm ? (
        <WebUI.HStack className="relative min-h-0 grow">
          <CollectionsNav />
          <WebUI.VStack className="CollectionsLayout-contentContainer min-w-0 grow px-10 py-8">
            {children}
          </WebUI.VStack>
        </WebUI.HStack>
      ) : (
        <WebUI.VStack className="CollectionsLayout-contentContainer grow py-6">
          {children}
        </WebUI.VStack>
      )}
    </WebUI.VStack>
  )
})

// MARK: – CollectionsNav

const CollectionsNav: React.FC<SlideOutNavProps> = (props) => {
  const [managerRoleId] = useManagerRoleId()
  const [managerRole] = useManagerRole()
  const [isCollapsed, setIsCollapsed] = WebUI.useLocalStorage(
    'collections_nav',
    false,
  )

  const isManager = managerRoleId != null

  return (
    <SlideOutNav
      variant={isManager ? 'default' : 'secondary'}
      isCollapsed={isCollapsed}
      {...props}
    >
      <WebUI.HStack
        className={WebUI.cn(
          'px-2 py-3',
          isCollapsed ? 'justify-center' : 'justify-end',
        )}
      >
        <WebUI.DeprecatedTooltip
          variant="light"
          placement="right"
          label={isCollapsed ? 'Show Menu' : 'Hide Menu'}
        >
          <WebUI.IconButton
            onClick={() =>
              setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed)
            }
          >
            <WebUI.PhosphorIcon
              icon={
                isCollapsed
                  ? 'arrow-circle-right-fill'
                  : 'arrow-circle-left-fill'
              }
              color="white"
              width={24}
            />
          </WebUI.IconButton>
        </WebUI.DeprecatedTooltip>
      </WebUI.HStack>
      <SlideOutNavLinkButton
        iconBefore={
          <WebUI.DeprecatedTooltip
            variant="light"
            placement="right"
            label="Collections"
            disabled={!isCollapsed}
          >
            <WebUI.PhosphorIcon icon="briefcase" />
          </WebUI.DeprecatedTooltip>
        }
        to="/collections"
      >
        Collections
      </SlideOutNavLinkButton>
      {!isManager && (
        <SlideOutNavLinkButton
          iconBefore={
            <WebUI.DeprecatedTooltip
              variant="light"
              placement="right"
              label="Order History"
              disabled={!isCollapsed}
            >
              <WebUI.PhosphorIcon icon="receipt" />
            </WebUI.DeprecatedTooltip>
          }
          to="/payments"
        >
          Order History
        </SlideOutNavLinkButton>
      )}
      {!isManager && (
        <SlideOutNavLinkButton
          iconBefore={
            <WebUI.DeprecatedTooltip
              variant="light"
              placement="right"
              label="Managers"
              disabled={!isCollapsed}
            >
              <WebUI.PhosphorIcon icon="user-circle-plus" />
            </WebUI.DeprecatedTooltip>
          }
          to="/managers"
        >
          Managers
        </SlideOutNavLinkButton>
      )}
      {(!isManager || managerRole?.permissions?.group_page) && (
        <SlideOutNavLinkButton
          iconBefore={
            <WebUI.DeprecatedTooltip
              variant="light"
              placement="right"
              label="Group Page"
              disabled={!isCollapsed}
            >
              <WebUI.PhosphorIcon icon="link" />
            </WebUI.DeprecatedTooltip>
          }
          to="/group"
        >
          Group Page
        </SlideOutNavLinkButton>
      )}
      {(!isManager || managerRole?.permissions.type === 'all') && (
        <SlideOutNavLinkButton
          iconBefore={
            <WebUI.DeprecatedTooltip
              variant="light"
              placement="right"
              label="Reports"
              disabled={!isCollapsed}
            >
              <WebUI.PhosphorIcon icon="chart-bar" />
            </WebUI.DeprecatedTooltip>
          }
          to="/reports"
        >
          Reports
        </SlideOutNavLinkButton>
      )}
      {(!isManager ||
        managerRole?.permissions?.address_book_and_message_center) && (
        <SlideOutNavLinkButton
          iconBefore={
            <WebUI.DeprecatedTooltip
              variant="light"
              placement="right"
              label="Address Book"
              disabled={!isCollapsed}
            >
              <WebUI.PhosphorIcon icon="envelope" />
            </WebUI.DeprecatedTooltip>
          }
          to="/address-book"
        >
          Address Book
        </SlideOutNavLinkButton>
      )}
    </SlideOutNav>
  )
}

export default CollectionsLayout
