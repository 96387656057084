import {api} from '@cheddarup/api-client'
import {getBlobFromUrl, makeNonSecureShortId} from '@cheddarup/util'

type ImageValue = string | File | Blob

export async function uploadImage(
  parentPath: string,
  originalImageValue: ImageValue,
  editedImageValue: ImageValue | undefined,
  options: {
    metadata: Api.S3ImageMetadata
    userId: number | undefined
  },
) {
  const [originalImage, editedImage] = await Promise.all(
    [
      makeBlobFromImageValue(originalImageValue),
      editedImageValue ? makeBlobFromImageValue(editedImageValue) : undefined,
    ].filter((p) => p != null) as [Promise<Blob>, Promise<Blob> | undefined],
  )

  const objectName = makeObjectName({prefixes: ['header'], name: ''})

  const createSignedUploadUrlRes =
    await api.fileUploads.createSignedUploadUrl.fetch({
      pathParams: {
        parentPath,
      },
      body: {
        objectName,
        metadata: {
          contentType: originalImage.type,
          ...options.metadata,
        },
      },
      headers: {
        'X-Manager-Account-Id': options.userId,
      },
    })
  await Promise.all(
    [
      editedImage
        ? uploadImageToS3(
            createSignedUploadUrlRes.editedImageSignedUrl,
            editedImage,
            {'Content-Type': originalImage.type},
          )
        : undefined,
      uploadImageToS3(createSignedUploadUrlRes.signedUrl, originalImage, {
        'Content-Type': originalImage.type,
      }),
    ].filter((p) => p != null),
  )

  return api.fileUploads.createImageRecord.fetch({
    pathParams: {
      parentPath,
    },
    body: {
      objectName,
      upload_path: createSignedUploadUrlRes.uploadPath,
      metadata: options.metadata,
    },
    headers: {
      'X-Manager-Account-Id': options.userId,
    },
  })
}

// MARK: – Helpers

function uploadImageToS3(
  signedUrl: string,
  image: Blob | File,
  headers: Record<string, string>,
) {
  return fetch(signedUrl, {
    body: image,
    mode: 'cors',
    method: 'PUT',
    headers: {
      'x-amz-acl': 'public-read',
      ...headers,
    },
  })
}

function makeBlobFromImageValue(imageValue: ImageValue) {
  if (typeof imageValue === 'string') {
    return getBlobFromUrl(imageValue)
  }

  return imageValue
}

function makeObjectName({
  prefixes = [],
  name,
}: {
  prefixes: string[]
  name: string
}) {
  return `${makeNonSecureShortId()}-${prefixes.join('-')}${
    name.length > 0 ? `-${name.replace(/[^\s\w.]/gi, '')}` : ''
  }`
}
