import {Link, useNavigate} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {Logo} from 'src/components'

import NavBarMobile from './NavBarMobile'

export interface NavBarProps extends React.ComponentPropsWithoutRef<'nav'> {
  published?: boolean
  branding?: Api.UserBranding
}

const NavBar = ({
  published,
  className,
  branding,
  ...restProps
}: NavBarProps) => {
  const media = WebUI.useMedia()
  const navigate = useNavigate()
  const linkClassName =
    'inline-block text-ds-sm text-gray800 font-normal focus:outline-none ml-4 md:ml-8 lg:ml-16'

  return media.sm ? (
    <div className={WebUI.cn('w-full border-b bg-natural-100', className)}>
      <nav
        className={WebUI.cn(
          'mx-auto table w-full max-w-screen-xl px-3 py-4',
          className,
        )}
        {...restProps}
      >
        <Link
          className="table-cell w-1/4 align-middle focus:outline-none"
          target="_blank"
          rel="noopener noreferrer"
          to={
            branding?.logo ? branding.footer.mainWebsiteLink?.link ?? '' : '/'
          }
        >
          <div className="flex">
            {branding?.logo ? (
              <img src={branding?.logo?.url} alt="logo" className="max-h-13" />
            ) : (
              <Logo />
            )}
          </div>
        </Link>
        {!published && (
          <div className="table-cell w-1/4 text-right align-middle">
            <WebUI.Text className="font-semibold text-ds-lg text-orange-50">
              Preview Only
            </WebUI.Text>
          </div>
        )}
        <div
          className={WebUI.cn(
            'flex items-center justify-end',
            published && 'table-cell text-right align-middle',
          )}
        >
          {published && (
            <>
              <Link className={linkClassName} to="help">
                {branding?.logo ? 'Contact' : 'Help'}
              </Link>
              <Link
                className={WebUI.cn(
                  linkClassName,
                  'aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
                )}
                to={published ? 'share' : ''}
              >
                Share
              </Link>
            </>
          )}
          {branding?.footer.mainWebsiteLink?.text && (
            <WebUI.Anchor
              className={linkClassName}
              href={branding.footer.mainWebsiteLink.link}
              target="_blank"
            >
              {branding.footer.mainWebsiteLink.text}
            </WebUI.Anchor>
          )}
          {!published && (
            <WebUI.IconButton
              className="ml-6 text-2xl"
              size="default_alt"
              onClick={() => navigate('/group')}
            >
              <WebUI.PhosphorIcon icon="x" />
            </WebUI.IconButton>
          )}
        </div>
      </nav>
    </div>
  ) : (
    <NavBarMobile className={className} branding={branding} />
  )
}

export default NavBar
