import {Navigate, Outlet, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import GroupIcon from 'src/images/team-upgrade-payments-image.png'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {
  RoutableTab,
  RoutableTabPanel,
  RoutableTabs,
} from 'src/components/RoutableTabs'
import {ManagerSummaryModal} from 'src/components/ManagerSummaryModal'
import {useManagerRole} from 'src/components/ManageRoleProvider'

import {
  CollectionAccessPanel,
  CollectionLinkPanel,
  CollectionTotalsSummaryPanel,
} from './CollectionManagePanels'

const CollectionManagePage = () => {
  const urlParams = useParams()
  const {data: session} = api.auth.session.useQuery()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const growlActions = WebUI.useGrowlActions()
  const [managerRole] = useManagerRole()

  if (collection?.status === 'draft') {
    return <Navigate to="../details" />
  }

  return (
    <WebUI.VStack className="mx-auto w-full max-w-[min(100%,1366px)] gap-6 overflow-y-auto px-4 py-8">
      <WebUI.VStack>
        <WebUI.Heading>Manage</WebUI.Heading>
        {managerRole && (
          <WebUI.Text className="text-ds-sm">
            {managerRole.name}:{' '}
            <ManagerSummaryModal
              className="text-ds-sm capitalize"
              managerRole={managerRole}
            >
              {managerRole.permissions?.role}
            </ManagerSummaryModal>
          </WebUI.Text>
        )}
      </WebUI.VStack>

      <WebUI.VStack className="gap-4 xl:flex-row xl:items-stretch">
        {!!collection && (
          <WebUI.VStack className="grow gap-4 lg:flex-row xl:max-w-[calc(100%-336px)]">
            <CollectionLinkPanel collection={collection} />
            <CollectionTotalsSummaryPanel collection={collection} />
          </WebUI.VStack>
        )}
        {managerRole?.permissions?.role !== 'viewer' && collection && (
          <CollectionAccessPanel
            className="xl:basis-[320px]"
            collection={collection}
          />
        )}
      </WebUI.VStack>

      {!!collection && (
        <RoutableTabs variant="underlined">
          <WebUI.VStack className="items-stretch gap-4 xl:flex-row xl:items-start">
            <WebUI.VStack className="grow gap-4">
              <div className="relative flex overflow-auto sm:block">
                <div className="flex items-center gap-4 sm:block">
                  <WebUI.TabList
                    aria-label="Navigation"
                    className="[&_.TabList-underline]:bg-orange-50"
                  >
                    <RoutableTab to="payments">Payments</RoutableTab>
                    {collection.reportsAvailable.activeItemsCount > 0 && (
                      <RoutableTab to="items">Items</RoutableTab>
                    )}
                    {collection.reportsAvailable.activeFormsCount +
                      collection.reportsAvailable.activeSignupsCount >
                      0 && (
                      <RoutableTab to="forms-signups">
                        Forms & Sign Ups
                      </RoutableTab>
                    )}
                    {collection.payer_identify && (
                      <RoutableTab to="visitors">Visitors</RoutableTab>
                    )}
                    {collection.reportsAvailable.shippingPayments && (
                      <RoutableTab to="shipping">Shipping</RoutableTab>
                    )}
                  </WebUI.TabList>
                  {(!managerRole ||
                    managerRole.permissions
                      .address_book_and_message_center) && (
                    <LinkButton
                      variant="default"
                      className="sm:-translate-y-1/2 sm:absolute sm:top-1/2 sm:right-0"
                      to="message-center?mcp=send"
                    >
                      Send Message
                    </LinkButton>
                  )}
                </div>
              </div>
              <RoutableTabPanel>
                <Outlet />
              </RoutableTabPanel>
            </WebUI.VStack>

            <WebUI.VStack className="gap-5 xl:mt-[calc(theme(spacing.4)+55px)] xl:w-[min(100%,320px)]">
              <WebUI.Panel className="w-full gap-5 p-6" as={WebUI.VStack}>
                <WebUI.Heading className="text-gray400 uppercase" as="h6">
                  Reports
                </WebUI.Heading>

                <WebUI.VStack>
                  <WebUI.Button
                    className="text-ds-sm"
                    variant="link"
                    onClick={async () => {
                      const res = await api.exports.paymentsXlsx.fetch({
                        pathParams: {
                          // biome-ignore lint/style/noNonNullAssertion:
                          tabId: urlParams.collection!,
                        },
                        body: {},
                      })
                      if (res.file_url) {
                        window.location.href = res.file_url
                      }

                      growlActions.show('success', {
                        title: 'Your report is in progress',
                        body:
                          res.message ||
                          'Your report was downloaded to your device.',
                      })
                    }}
                  >
                    Collection Summary (xlsx)
                  </WebUI.Button>
                  <WebUI.Text className="font-light text-ds-xs text-gray800">
                    All payment and item-related details
                  </WebUI.Text>
                </WebUI.VStack>

                <WebUI.Separator variant="primary" />

                <WebUI.VStack>
                  <Link
                    className="text-ds-sm"
                    variant="primary"
                    target="_blank"
                    to={`/pdf/collection/${urlParams.collection}/orders-report`}
                  >
                    Printable Payment Summaries (pdf)
                  </Link>
                  <WebUI.Text className="font-light text-ds-xs">
                    Great for invoices and packing slips
                  </WebUI.Text>
                </WebUI.VStack>

                {collection?.payer_identify && (
                  <>
                    <WebUI.Separator variant="primary" />
                    <WebUI.VStack>
                      <WebUI.Button
                        className="text-ds-sm"
                        variant="link"
                        onClick={async () => {
                          const res = await api.exports.visitorsXlsx.fetch({
                            pathParams: {
                              // biome-ignore lint/style/noNonNullAssertion:
                              tabId: urlParams.collection!,
                            },
                          })
                          if (res.file_url) {
                            window.location.href = res.file_url
                          }
                          growlActions.show('success', {
                            title: 'Your report is in progress',
                            body:
                              res.message ||
                              'Your report was downloaded to your device.',
                          })
                        }}
                      >
                        Visitor Summary (xlsx)
                      </WebUI.Button>
                      <WebUI.Text className="font-light text-ds-xs text-gray800">
                        Everyone who visited your collection
                      </WebUI.Text>
                    </WebUI.VStack>
                  </>
                )}

                <WebUI.Separator variant="primary" />

                <WebUI.VStack>
                  <Link
                    className="text-ds-sm"
                    variant="primary"
                    to={{
                      pathname: '/reports',
                      search: `?collectionIds=${collection.id}`,
                    }}
                  >
                    Top Payers & Items
                  </Link>
                  <WebUI.Text className="font-light text-ds-xs">
                    See top-ranking payers and items
                  </WebUI.Text>
                </WebUI.VStack>
              </WebUI.Panel>
              {managerRole == null &&
              !!session &&
              !session.capabilities.subscribed_to_team ? (
                <WebUI.Panel
                  className="w-full items-start gap-5 p-6"
                  as={WebUI.VStack}
                >
                  <WebUI.Heading
                    className="max-w-[230px] font-accent font-bold text-ds-md"
                    as="h3"
                  >
                    <img
                      className="float-left mr-[15px]"
                      src={GroupIcon}
                      width="74"
                      height="92"
                      alt="Cheddar group offer"
                    />
                    Account-wide reporting is a click away
                  </WebUI.Heading>
                  <WebUI.Text className="font-light text-ds-sm">
                    Tap into collecting trends{' '}
                    <span className="font-bold">
                      across your entire account
                    </span>{' '}
                    with our Team plan
                  </WebUI.Text>
                  <WebUI.RadioGroup
                    className={
                      '[&_>_.Radio_>_.Radio-icon]:mt-1 [&_>_.Radio_>_.Radio-icon]:h-[1em] [&_>_.Radio_>_.Radio-icon]:w-[1em] [&_>_.Radio_>_.Radio-icon]:self-start [&_>_.Radio_>_.Radio-icon_>_Radio-checkIcon]:text-[0.75em] [&_>_.Radio_>_.Radio-text]:w-[178px]'
                    }
                    size="compact"
                  >
                    <WebUI.Radio checked>
                      See top-trending payers and collections
                    </WebUI.Radio>
                    <WebUI.Radio checked>
                      Access reporting across all collections
                    </WebUI.Radio>
                    <WebUI.Radio checked>
                      Create and save reports with custom filters
                    </WebUI.Radio>
                  </WebUI.RadioGroup>

                  <LinkButton variant="primary" to="i/plans">
                    Upgrade to Team
                  </LinkButton>
                </WebUI.Panel>
              ) : null}
            </WebUI.VStack>
          </WebUI.VStack>
        </RoutableTabs>
      )}
    </WebUI.VStack>
  )
}

export default CollectionManagePage
