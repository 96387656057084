// Based on https://github.com/juliencrn/usehooks-ts/blob/master/lib/src/useLocalStorage/useLocalStorage.ts

import {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react'
import {useLiveRef} from '@cheddarup/react-util'

import {useEventListener} from './useEventListener'

type SetValue<T> = Dispatch<SetStateAction<T>>

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
): [T, SetValue<T>] {
  const readValue = () => readSessionStorageItem(key, initialValue)

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue)
  const storedValueRef = useLiveRef(storedValue)

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue: SetValue<T> = useCallback(
    (value) => {
      // Prevent build error "window is undefined" but keeps working
      if (typeof window === 'undefined') {
        console.warn(
          `Tried setting sessionStorage key “${key}” even though environment is not a client`,
        )
      }

      try {
        // Allow value to be a function so we have the same API as useState
        const newValue =
          value instanceof Function ? value(storedValueRef.current) : value

        // Save to session storage
        sessionStorage.setItem(key, JSON.stringify(newValue))

        // Save state
        setStoredValue(newValue)

        // We dispatch a custom event so every useLocalStorage hook are notified
        window.dispatchEvent(new Event('session-storage'))
      } catch (error) {
        console.warn(`Error setting sessionStorage key “${key}”:`, error)
      }
    },
    [key],
  )

  const handleStorageChange = () => {
    const newValue = readValue()
    setStoredValue(newValue)
  }

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange)

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEventListener('session-storage', handleStorageChange)

  return useMemo(() => [storedValue, setValue], [setValue, storedValue])
}

// Get from session storage then
// parse stored json or return initialValue
export function readSessionStorageItem<T>(key: string, defaultValue: T): T {
  // Prevent build error "window is undefined" but keep keep working
  if (typeof window === 'undefined') {
    return defaultValue
  }

  try {
    const item = sessionStorage.getItem(key)
    return item ? (parseJSON(item) as T) : defaultValue
  } catch (error) {
    console.warn(`Error reading sessionStorage key “${key}”:`, error)
    return defaultValue
  }
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.warn('parsing error on', {value})
    return undefined
  }
}

declare global {
  interface WindowEventMap {
    'session-storage': CustomEvent
  }
}
