import {genericForwardRef, newGenericForwardRef} from '@cheddarup/react-util'
import React, {useImperativeHandle, useMemo, useRef} from 'react'

import {
  DragAndDrop,
  DragAndDropProps,
  DraggableSyntheticListeners,
  Sortable,
  SortableContext,
  UniqueIdentifier,
  arrayMoveByValue,
  closestCenter,
  verticalListSortingStrategy,
} from './DragAndDrop'
import {Heading} from './Heading'
import {ViewportList, ViewportListInstnce} from './ViewportList'
import {VStack, VStackProps} from './Stack'
import {cn} from '../utils'

export type AnyList<T extends ListRowData> =
  | typeof List<T>
  | typeof SortableList<T>

export type ListRowDataId = string | number

export interface ListRowData {
  id: ListRowDataId
}

export interface ListRowComponentProps<T extends ListRowData>
  extends React.ComponentPropsWithoutRef<'div'> {
  data: T
  index: number
}

export type ListRowComponentType<T extends ListRowData> = React.ComponentType<
  ListRowComponentProps<T> & React.RefAttributes<HTMLDivElement>
>

// MARK: - List

export interface ListInstance {
  scrollToRow: (
    rowKey: ListRowDataId,
    scrollIntoViewOptions?: ScrollIntoViewOptions,
  ) => void
}

export interface ListProps<T extends ListRowData>
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>,
    VStackProps {
  HeaderComponent?: React.ElementType | React.ReactElement
  FooterComponent?: React.ElementType | React.ReactElement
  EmptyStateViewComponent?: React.ComponentType | null
  data: T[]
  RowComponent?: ListRowComponentType<T>
  children?: (item: T, index: number) => React.ReactNode
}

export const List = newGenericForwardRef(
  <T extends ListRowData>({
    data,
    HeaderComponent,
    FooterComponent,
    EmptyStateViewComponent = ListDefaultEmptyStateView,
    RowComponent,
    className,
    children,
    ref: forwardedRef,
    ...restProps
  }: ListProps<T> & {ref?: React.Ref<ListInstance>}) => {
    const itemRowRefMap = useRef<Record<string, HTMLDivElement | null>>({})

    useImperativeHandle(
      forwardedRef,
      (): ListInstance => ({
        scrollToRow: (rowKey, scrollIntoViewOptions) =>
          requestAnimationFrame(() =>
            itemRowRefMap.current?.[rowKey]?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              ...scrollIntoViewOptions,
            }),
          ),
      }),
      [],
    )

    if (data.length === 0) {
      return EmptyStateViewComponent == null ? (
        EmptyStateViewComponent
      ) : (
        <EmptyStateViewComponent />
      )
    }

    return (
      <VStack
        className={cn('List', 'h-full overflow-y-auto *:flex-0', className)}
        role="list"
        {...restProps}
      >
        {HeaderComponent && (
          <>
            {React.isValidElement<any>(HeaderComponent) ? (
              HeaderComponent
            ) : (
              <HeaderComponent />
            )}
          </>
        )}
        {children
          ? data.map(children)
          : RowComponent &&
            data.map((item: T, idx) => (
              <RowComponent
                key={item.id}
                ref={(rowRef) => {
                  itemRowRefMap.current[item.id] = rowRef
                }}
                className="List-item"
                data={item}
                index={idx}
                role="listitem"
              />
            ))}
        {FooterComponent && (
          <>
            {React.isValidElement<any>(FooterComponent) ? (
              FooterComponent
            ) : (
              <FooterComponent />
            )}
          </>
        )}
      </VStack>
    )
  },
)

// MARK: - VirtualizedList

export interface VirtualizedListProps<T extends ListRowData>
  extends ListProps<T> {
  overscan?: number
  estimateRowSize?: number
}

export const VirtualizedList = newGenericForwardRef(
  <T extends ListRowData>({
    data,
    HeaderComponent,
    FooterComponent,
    EmptyStateViewComponent = ListDefaultEmptyStateView,
    RowComponent,
    overscan = 10,
    estimateRowSize,
    className,
    ref: forwardedRef,
    ...restProps
  }: VirtualizedListProps<T> & {ref?: React.ForwardedRef<ListInstance>}) => {
    const viewportListRef = useRef<ViewportListInstnce>(null)

    useImperativeHandle(
      forwardedRef,
      (): ListInstance => ({
        scrollToRow: (rowKey, scrollIntoViewOptions) => {
          const rowIdx = data.findIndex((r) => r.id === rowKey)
          viewportListRef.current?.scrollToIndex({
            index: rowIdx,
            alignToTop: scrollIntoViewOptions?.block
              ? scrollIntoViewOptions.block === 'start'
              : false,
          })
        },
      }),
      [data],
    )

    if (data.length === 0 && EmptyStateViewComponent != null) {
      return <EmptyStateViewComponent />
    }

    return (
      <VStack
        className={cn('List', 'h-full overflow-y-auto *:flex-0', className)}
        role="list"
        {...restProps}
      >
        {HeaderComponent && (
          <>
            {React.isValidElement<any>(HeaderComponent) ? (
              HeaderComponent
            ) : (
              <HeaderComponent />
            )}
          </>
        )}
        {RowComponent && (
          <ViewportList<T>
            listRef={viewportListRef}
            className="List-rowsContainer"
            itemSize={estimateRowSize}
            items={data}
          >
            {(rowData, index) => (
              <RowComponent
                key={rowData.id}
                className="List-item"
                data={rowData}
                index={index}
                role="listitem"
              />
            )}
          </ViewportList>
        )}
        {FooterComponent && (
          <>
            {React.isValidElement<any>(FooterComponent) ? (
              FooterComponent
            ) : (
              <FooterComponent />
            )}
          </>
        )}
      </VStack>
    )
  },
)

// MARK: – ListDefaultEmptyStateView

export const ListDefaultEmptyStateView = ({
  className,
  children,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <VStack className={cn('p-8 text-center', className)} {...restProps}>
    {children ?? <Heading as="h2">Empty List</Heading>}
  </VStack>
)

// MARK: – SortableList

export interface SortableListRowComponentProps<T extends ListRowData>
  extends React.ComponentPropsWithoutRef<'div'> {
  dragListeners: DraggableSyntheticListeners
  data: T
  index: number
}

export type SortableListRowComponentType<T extends ListRowData> =
  React.ComponentType<
    SortableListRowComponentProps<T> & React.RefAttributes<HTMLDivElement>
  >

export interface SortableListProps<T extends ListRowData>
  extends Omit<ListProps<T>, 'RowComponent'>,
    Pick<DragAndDropProps, 'dragOverlayPortal' | 'modifiers' | 'touchEnabled'> {
  RowComponent: SortableListRowComponentType<T>
  rowsDraggable?: boolean
  onOrderChange?: (orderedIds: Array<T['id']>) => void
}

export const SortableList = genericForwardRef(
  <T extends ListRowData>(
    {
      dragOverlayPortal,
      rowsDraggable = true,
      touchEnabled = false,
      modifiers,
      data,
      RowComponent,
      onOrderChange,
      className,
      ...restProps
    }: SortableListProps<T> & {ref?: React.Ref<ListInstance>},
    forwardedRef: React.Ref<ListInstance>,
  ) => {
    const SortableRowComponent = useMemo(
      () =>
        React.forwardRef<HTMLDivElement, ListRowComponentProps<T>>(
          ({data: rowData, ...rowRestProps}, rowForwardedRef) => (
            <Sortable
              id={rowData.id}
              data={rowData}
              draggable={rowsDraggable}
              tabIndex={-1}
            >
              {({dragListeners}) => (
                <RowComponent
                  ref={rowForwardedRef}
                  data={rowData}
                  dragListeners={dragListeners}
                  {...rowRestProps}
                />
              )}
            </Sortable>
          ),
        ),
      [RowComponent, rowsDraggable],
    )

    return (
      <DragAndDrop
        collisionDetection={closestCenter}
        dragOverlayPortal={dragOverlayPortal}
        modifiers={modifiers}
        touchEnabled={touchEnabled}
        onDragEnd={({active, over}) => {
          if (!over) {
            return
          }

          const oldOrder = (over.data.current?.sortable.items ??
            []) as UniqueIdentifier[]
          const newOrder = arrayMoveByValue(oldOrder, active.id, over.id)

          onOrderChange?.(newOrder)
        }}
      >
        <SortableContext strategy={verticalListSortingStrategy} items={data}>
          <List<T>
            ref={forwardedRef}
            className={cn('SortableList', className)}
            data={data}
            RowComponent={SortableRowComponent}
            {...restProps}
          />
        </SortableContext>
      </DragAndDrop>
    )
  },
)
