import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'
import {groupTabSchema} from './public-tabs'
import {userBrandingSchema} from './user-brandings'

export const groupPageCategorySchema = z.object({
  id: z.union([z.number(), z.string()]),
  name: z.string(),
  uuid: z.string().nullable(),
  collections: groupTabSchema.array(),
  collectionOrder: z.number().array().optional(),
  position: z.number(),
})

export const topBannerDetailsSchema = z.object({
  backgroundImageId: z.number().nullish(),
  logoId: z.number().nullish(),
  complementaryColor: z.string().optional(),
})

export const groupPageCollectionSpotlightItemSchema = z.object({
  id: z.number(),
  position: z.number(),
  collection_id: z.number(),
  headline: z.string(),
  description: z.string(),
  button_text: z.string(),
  collection: z.object({
    id: z.number(),
    name: z.string(),
    user_id: z.number(),
    status: z.string(),
    slug: z.string(),
  }),
  image_id: z.number().nullable(),
  image: s3ImageSchema.optional(),
})

export const groupPageTeamMemberSchema = z.object({
  id: z.number(),
  position: z.number(),
  name: z.string(),
  title: z.string(),
  email: z.string().nullable(),
  profile_picture_id: z.number().nullable(),
  profile_picture: s3ImageSchema.optional(),
})

export const groupPageSponsorSchema = z.object({
  id: z.number(),
  position: z.number(),
  name: z.string(),
  url: z.string(),
  logo_id: z.number().nullable(),
  logo: s3ImageSchema.optional(),
})

export const collectionSpotlightDetailsSchema = z.object({
  removeWhenClosed: z.boolean().optional(),
})

export const groupPageAboutUsSectionSchema = z.object({
  includeWebsiteLink: z.boolean().optional(),
})

export const groupPageTotalCollectionTimePeriodSchema = z.enum([
  'all_time',
  'last_year',
  'last_month',
  'last_week',
])

export const groupPageTotalCollectedSectionSchema = z.object({
  timePeriod: groupPageTotalCollectionTimePeriodSchema.optional(),
})

export const groupPageSectionTypeSchema = z.enum([
  'TopBanner',
  'CollectionSection',
  'CollectionSpotlight',
  'AboutUsSection',
  'TotalCollected',
  'MeetTheTeam',
  'Sponsor',
])

export const groupPageSectionSchema = z.object({
  id: z.number(),
  position: z.number(),
  headline: z.string(),
  tagline: z.string(),
  description: z.string(),
  logo: s3ImageSchema.nullish(),
  details: z.object({
    ...topBannerDetailsSchema.shape,
    ...collectionSpotlightDetailsSchema.shape,
    ...groupPageAboutUsSectionSchema.shape,
    ...groupPageTotalCollectedSectionSchema.shape,
  }),
  background_color: z.string(),
  background_image: s3ImageSchema.nullish(),
  spotlight_items: groupPageCollectionSpotlightItemSchema.array().optional(),
  team_members: groupPageTeamMemberSchema.array().optional(),
  sponsor_items: groupPageSponsorSchema.array().optional(),
  categories: groupPageCategorySchema.array().optional(),
  type: groupPageSectionTypeSchema,
  total_collected: z.string().optional(),
})

export const groupPageSchema = z.object({
  logo: s3ImageSchema.nullish(),
  tagline: z.string(),
  twitter: z.string(),
  website: z.string(),
  facebook: z.string(),
  linkedin: z.string(),
  instagram: z.string(),
  categories: groupPageCategorySchema.array().optional(),
  showInactive: z.boolean().nullish(),
  organizationName: z.string(),
  uncategorizedCollectionOrder: z.number().array().optional(),
})

export const groupPageStatusSchema = z.enum(['draft', 'published'])
export const _groupPageSchema = z.object({
  user_id: z.number(),
  status: groupPageStatusSchema,
  published_at: z.string().nullable(),
  branding: userBrandingSchema.optional(),
  sections: z
    .object({
      collection_section: groupPageSectionSchema.nullable(),
      top_banner: groupPageSectionSchema.nullable(),
      collection_spotlight: groupPageSectionSchema.nullable(),
      about_us: groupPageSectionSchema.nullable(),
      total_collected: groupPageSectionSchema.nullable(),
    })
    .optional(),
})

declare global {
  namespace Api {
    type GroupPage = z.infer<typeof groupPageSchema>
    type NewGroupPage = z.infer<typeof _groupPageSchema>
    type GroupPageSectionType = z.infer<typeof groupPageSectionTypeSchema>
    type GroupPageCategory = z.infer<typeof groupPageCategorySchema>
    type GroupPageSection = z.infer<typeof groupPageSectionSchema>
    type GroupPageCollectionSpotlightItem = z.infer<
      typeof groupPageCollectionSpotlightItemSchema
    >
    type GroupPageTeamMember = z.infer<typeof groupPageTeamMemberSchema>
    type GroupPageSponsor = z.infer<typeof groupPageSponsorSchema>
    type GroupPageTotalCollectionTimePeriod = z.infer<
      typeof groupPageTotalCollectionTimePeriodSchema
    >
  }
}
