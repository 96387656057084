import React, {useEffect} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'

import {CollectionSettingsUpgradePlanButton} from './CollectionSettingsUpgradePlanButton'
import {CollectionSettingsPanel} from './CollectionSettingsPanel'
import Discounts from './Discounts'
import {useDebounceUpdateTab} from './CollectionSettingsPayment'
import {SettingDisclosureSwitch} from './SettingDisclosureSwitch'

// MARK: – ShippingSettings

export interface ShippingSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  collectionHasRecurringItems: boolean
}

export const ShippingSettings = ({
  collection,
  collectionHasRecurringItems,
  ...restProps
}: ShippingSettingsProps) => {
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()

  const shippingOptions =
    collection.shipping_options &&
    'shipToEnabled' in collection.shipping_options
      ? collection.shipping_options
      : undefined

  const updateCollection = updateCollectionMutation.mutate
  useEffect(() => {
    if (!collection.is_pro && collection.status !== 'draft') {
      updateCollection({
        pathParams: {
          tabId: collection.id,
        },
        body: {
          shipping_options: {
            shipToEnabled: false,
            cost: '',
            freeOver: '',
            localPickupInstructions: '',
            costTouched: false,
            freeOverTouched: false,
            freeOverEnabled: false,
            localPickupEnabled: false,
          },
        },
      })
    }
  }, [collection.id, collection.is_pro, collection.status, updateCollection])

  const label = 'Add flat shipping fee at checkout'

  return (
    <CollectionSettingsPanel
      heading="Shipping"
      settings={[
        <WebUI.Disclosure
          key="shipToEnabled"
          className="gap-4"
          visible={!!shippingOptions?.shipToEnabled}
          onVisibleChange={(newShipToEnabled) =>
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {
                shipping_options: {
                  shipToEnabled: newShipToEnabled,
                  cost: '',
                  freeOver: '',
                  localPickupInstructions: '',
                  costTouched: false,
                  freeOverTouched: false,
                  freeOverEnabled: false,
                  localPickupEnabled: false,
                },
              },
            })
          }
        >
          {collection.status === 'draft' || collection.is_pro ? (
            <SettingDisclosureSwitch
              featureKey={collection.is_pro ? undefined : 'shipping'}
            >
              {label}
            </SettingDisclosureSwitch>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="pro">
              {label}
            </CollectionSettingsUpgradePlanButton>
          )}
          <WebUI.DisclosureContent>
            <WebUI.VStack className="gap-4">
              <WebUI.VStack className="gap-3 text-ds-sm">
                <div>
                  Track shipping, purchase postage and print pre-filled labels
                  directly from your manage view.
                </div>
                {collectionHasRecurringItems && (
                  <div className="text-orange-50">
                    Note: The shipping fee for recurring payment items will only
                    apply to first checkout.
                  </div>
                )}
              </WebUI.VStack>
              <WebUI.FormField
                className="max-w-[200px]"
                required
                label="Shipping fee"
                error={shippingOptions?.cost ? undefined : 'Required'}
              >
                <WebUI.DeferredAmountInput
                  size="compact"
                  placeholder="$0"
                  defaultValue={
                    shippingOptions?.cost ? String(shippingOptions?.cost) : ''
                  }
                  commitValue={(newCost) =>
                    updateCollectionMutation.mutate({
                      pathParams: {
                        tabId: collection.id,
                      },
                      body: {
                        shipping_options: {
                          ...shippingOptions,
                          cost: Number(newCost),
                        },
                      },
                    })
                  }
                />
              </WebUI.FormField>
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
        shippingOptions?.shipToEnabled ? (
          <WebUI.Disclosure
            key="freeOverEnabled"
            className="gap-4"
            visible={!!shippingOptions.freeOverEnabled}
            onVisibleChange={(newFreeOverEnabled) =>
              debouncedUpdateTab({
                pathParams: {
                  tabId: collection.id,
                },
                body: {
                  shipping_options: {
                    ...shippingOptions,
                    freeOverEnabled: newFreeOverEnabled,
                  },
                },
              })
            }
          >
            <WebUI.DisclosureSwitch>
              Offer "free shipping" with minimum order:
            </WebUI.DisclosureSwitch>
            <WebUI.DisclosureContent>
              <WebUI.VStack className="gap-4">
                <div className="text-ds-sm">
                  Your customers will see “free shipping” if order is over a
                  specified amount.
                </div>
                <WebUI.FormField
                  className="max-w-[200px]"
                  label="Minimum Amount"
                  error={shippingOptions.freeOver ? undefined : 'Required'}
                >
                  <WebUI.DeferredAmountInput
                    size="compact"
                    placeholder="$0"
                    value={
                      shippingOptions.freeOver
                        ? String(shippingOptions.freeOver)
                        : ''
                    }
                    commitValue={(newFreeOver) =>
                      updateCollectionMutation.mutate({
                        pathParams: {
                          tabId: collection.id,
                        },
                        body: {
                          shipping_options: {
                            ...shippingOptions,
                            freeOver: Number(newFreeOver),
                          },
                        },
                      })
                    }
                  />
                </WebUI.FormField>
              </WebUI.VStack>
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        ) : null,
        shippingOptions?.shipToEnabled ? (
          <WebUI.Disclosure
            key="localPickupEnabled"
            className="gap-4"
            visible={!!shippingOptions.localPickupEnabled}
            onVisibleChange={(newLocalPickupEnabled) =>
              debouncedUpdateTab({
                pathParams: {
                  tabId: collection.id,
                },
                body: {
                  shipping_options: {
                    ...shippingOptions,
                    localPickupEnabled: newLocalPickupEnabled,
                  },
                },
              })
            }
          >
            <WebUI.DisclosureSwitch>
              Enable free local pickup
            </WebUI.DisclosureSwitch>
            <WebUI.DisclosureContent>
              <WebUI.FormField
                className="[&_>_.FormField-label]:font-light"
                label="Allow customer to opt out of shipping fee."
              >
                <WebUI.DeferredRichTextMarkdown
                  className="mb-2 min-h-[113px] max-w-[550px] text-ds-sm"
                  placeholder="Optional: Provide pickup instructions"
                  initialMarkdownValue={
                    shippingOptions.localPickupInstructions ?? ''
                  }
                  commitValue={(newLocalPickupInstructions) =>
                    updateCollectionMutation.mutate({
                      pathParams: {
                        tabId: collection.id,
                      },
                      body: {
                        shipping_options: {
                          ...shippingOptions,
                          localPickupInstructions: newLocalPickupInstructions,
                        },
                      },
                    })
                  }
                />
              </WebUI.FormField>
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        ) : null,
      ]}
      {...restProps}
    />
  )
}

// MARK: – DiscountsSettings

export interface DiscountsSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  collectionHasRecurringItems: boolean
}

export const DiscountsSettings = ({
  collection,
  collectionHasRecurringItems,
  ...restProps
}: DiscountsSettingsProps) => {
  const discountsQuery = api.tabDiscounts.list.useQuery({
    pathParams: {
      tabId: collection.id,
    },
  })
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()

  const updateCollection = updateCollectionMutation.mutate
  useEffect(() => {
    if (!collection.is_pro && collection.status !== 'draft') {
      updateCollection({
        pathParams: {
          tabId: collection.id,
        },
        body: {discounts_enabled: false},
      })
    }
  }, [collection.id, collection.is_pro, collection.status, updateCollection])

  const label = 'Create discount codes'

  return (
    <CollectionSettingsPanel
      heading="Discounts"
      settings={[
        <WebUI.Disclosure
          key="discounts_enabled"
          className="gap-4"
          visible={!!collection.discounts_enabled}
          onVisibleChange={(newDiscountsEnabled) =>
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {discounts_enabled: newDiscountsEnabled},
            })
          }
        >
          {collection.status === 'draft' || collection.is_pro ? (
            <SettingDisclosureSwitch
              featureKey={collection.is_pro ? undefined : 'discounts'}
            >
              {label}
            </SettingDisclosureSwitch>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="pro">
              {label}
            </CollectionSettingsUpgradePlanButton>
          )}
          <WebUI.DisclosureContent>
            <div className="text-ds-sm">
              Create discount codes that your customers can enter at checkout.
            </div>
            {collectionHasRecurringItems && (
              <div className="text-ds-sm text-orange-50">
                Note: Discount codes will only apply to recurring payment items
                set to occur immediately (not scheduled for a future day of the
                month) and will only apply to the first payment.
              </div>
            )}
            <Discounts discounts={discountsQuery.data ?? []} />
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}
