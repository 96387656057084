import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {
  FeatureKey,
  PremiumFeatureSideSheetDisclosure,
} from 'src/components/PremiumFeaturesSideSheet'

export interface SettingDisclosureSwitchProps
  extends Omit<WebUI.DisclosureSwitchProps, 'children'> {
  children?: React.ReactNode
  featureKey?: FeatureKey
  enforcePaidBadge?: boolean
}

export const SettingDisclosureSwitch = ({
  children,
  className,
  featureKey,
  enforcePaidBadge = false,
  ...restProps
}: SettingDisclosureSwitchProps) => (
  <WebUI.HStack className="max-w-full flex-[1_0_auto] items-center gap-4">
    <span className="max-w-full flex-[12_1_auto]">{children}</span>
    {enforcePaidBadge && featureKey ? (
      <WebUI.HStack className="flex-[1_0_68px]">
        <PremiumFeatureSideSheetDisclosure featureKeys={[featureKey]} />
      </WebUI.HStack>
    ) : featureKey ? (
      <WebUI.DisclosureContent className="flex-[1_0_76px]">
        <PremiumFeatureSideSheetDisclosure featureKeys={[featureKey]} />
      </WebUI.DisclosureContent>
    ) : null}
    <WebUI.DisclosureSwitch
      className={WebUI.cn('[&_>_.Switch-content]:min-w-0', className)}
      {...restProps}
    />
  </WebUI.HStack>
)
