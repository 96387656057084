import * as Yup from 'yup'
import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageSectionMutation,
  useCreateGroupPageSpotlightItemMutation,
  useUpdateGroupPageSpotlightItemMutation,
} from '@cheddarup/api-client'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {UploadPinturaImageFormModal} from 'src/components/UploadPinturaImageForm'
import {guessError} from 'src/helpers/error-utils'
import {uploadImage} from '@cheddarup/core'
import {SharpImage} from 'src/components/SharpImage'

const SPOTLIGHT_ITEM_HEADLINE_MAX_LEN = 46
const SPOTLIGHT_ITEM_DESCRIPTION_MAX_LEN = 140
const SPOTLIGHT_ITEM_BUTTON_TEXT_MAX_LEN = 46

interface SpotlightItemFormValues {
  headline: string
  description: string
  button_text: string
  collection_id: number
  image: Api.S3Image | WebUI.PinturaDefaultImageWriterResult | null
}

const SpotlightItemForm = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const [managerRoleId] = useManagerRoleId()
  const growlActions = WebUI.useGrowlActions()

  const {data: collectionSpotlight} = api.groupPageSections.detail.useQuery({
    pathParams: {
      sectionName: 'collection_spotlight',
    },
  })
  const {data: spotlightItem} = api.groupPageSpotlightItems.detail.useQuery(
    {
      pathParams: {id: Number(urlParams.id)},
    },
    {enabled: !!urlParams.id},
  )
  const {data: collections} = api.tabs.list.useQuery()
  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const createGroupPageSpotlightItemMutation =
    useCreateGroupPageSpotlightItemMutation()
  const updateGroupPageSpotlightItemMutation =
    useUpdateGroupPageSpotlightItemMutation()
  const uploadPinturaImageFormModalRef = useRef<WebUI.DialogInstance>(null)
  const [localImage, setLocalImage] = useState<Blob | null>(null)

  const formik = useFormik<SpotlightItemFormValues>({
    enableReinitialize: true,
    initialValues: {
      headline: spotlightItem?.headline ?? '',
      description: spotlightItem?.description ?? '',
      button_text: spotlightItem?.button_text ?? '',
      collection_id: spotlightItem?.collection_id ?? 0,
      image: spotlightItem?.image ?? null,
    },
    validationSchema: Yup.object().shape({
      headline: Yup.string()
        .required('Required')
        .max(
          SPOTLIGHT_ITEM_HEADLINE_MAX_LEN,
          'Headline can be ${max} characters at max',
        ),
      description: Yup.string()
        .required('Required')
        .max(
          SPOTLIGHT_ITEM_DESCRIPTION_MAX_LEN,
          'Description can be ${max} characters at max',
        ),
      button_text: Yup.string()
        .required('Required')
        .max(
          SPOTLIGHT_ITEM_BUTTON_TEXT_MAX_LEN,
          'Button text can be ${max} characters at max',
        ),
      collection_id: Yup.number()
        .required('Required')
        .notOneOf([0], 'Collection must be selected'),
    }),
    onSubmit: async (values) => {
      try {
        if (!collectionSpotlight) {
          await createGroupPageSectionMutation.mutateAsync({
            pathParams: {sectionName: 'collection_spotlight'},
            body: {details: {}},
          })
        }

        let savedItem = spotlightItem ?? null

        const payload = {
          headline: values.headline,
          description: values.description,
          button_text: values.button_text,
          collection_id: values.collection_id,
        }

        if (!savedItem) {
          savedItem = await createGroupPageSpotlightItemMutation.mutateAsync({
            body: payload,
          })
        }

        let imageId =
          values.image && 'id' in values.image ? values.image.id : null
        if (values.image && 'dest' in values.image) {
          const uploadedLogo = await uploadImage(
            `users/group_page/collection_spotlight/spotlight_items/${savedItem.id}`,
            values.image.dest,
            undefined,
            {
              userId: managerRoleId ?? undefined,
              metadata: {
                pintura: values.image.imageState,
              },
            },
          )

          if (imageId != null) {
            api.groupPageSpotlightItems.deleteImage.fetch({
              pathParams: {
                spotlightItemId: savedItem.id,
                imageId: imageId,
              },
            })
          }

          imageId = uploadedLogo.id
        }

        if (savedItem) {
          await updateGroupPageSpotlightItemMutation.mutateAsync({
            pathParams: {id: savedItem.id},
            body: {
              ...payload,
              image_id: imageId,
            },
          })
        }

        dialogRef.current?.hide()
      } catch (err) {
        growlActions.show('error', {body: guessError(err).message})
      }
    },
  })

  return (
    <WebUI.Modal
      ref={dialogRef}
      aria-label="Collection Spotlight Item form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-2xl"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader className="border-none pl-12">
        Collection Spotlight
      </WebUI.ModalHeader>
      <form
        className="flex min-h-0 grow flex-col divide-y divide-solid divide-borderPrimary"
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
        noValidate
      >
        <div className="flex grow flex-col gap-6 overflow-y-auto px-12 pb-6">
          <div className="flex flex-col gap-6">
            <WebUI.Text className="font-light text-ds-sm">
              Add an attention-grabbing headline and a short description of your
              collection. We’ll include a button linking to your selected
              collection.
            </WebUI.Text>
            <WebUI.FormField error={formik.errors.collection_id}>
              <WebUI.DropdownSelect
                name="collection_id"
                placeholder="Select Collection to Spotlight"
                value={formik.values.collection_id}
                onValueChange={(newCollectionId) =>
                  formik.setFieldValue('collection_id', newCollectionId)
                }
              >
                {collections?.map((c) => (
                  <WebUI.DropdownSelectOption key={c.id} value={c.id}>
                    {c.name}
                  </WebUI.DropdownSelectOption>
                ))}
              </WebUI.DropdownSelect>
            </WebUI.FormField>
          </div>
          <WebUI.Separator />
          <div className="flex flex-col gap-5">
            <WebUI.FormField
              label="Collection Spotlight Headline"
              error={formik.errors.headline}
              required
            >
              <WebUI.Input
                name="headline"
                placeholder="Collection Headline"
                value={formik.values.headline}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={SPOTLIGHT_ITEM_HEADLINE_MAX_LEN}
              />
            </WebUI.FormField>
            <WebUI.FormField
              label="Description"
              error={formik.errors.description}
              required
            >
              <WebUI.Textarea
                name="description"
                placeholder="Brief Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={3}
                maxLength={SPOTLIGHT_ITEM_DESCRIPTION_MAX_LEN}
              />
            </WebUI.FormField>
            <WebUI.FormField
              label="Button Text"
              error={formik.errors.button_text}
              required
            >
              <WebUI.Input
                name="button_text"
                placeholder="e.g., “Learn More” or “Register”"
                value={formik.values.button_text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={SPOTLIGHT_ITEM_BUTTON_TEXT_MAX_LEN}
              />
            </WebUI.FormField>
            <WebUI.FormField label="Add an image">
              <UploadPinturaImageFormModal
                key={spotlightItem?.image?.id}
                ref={uploadPinturaImageFormModalRef}
                imageCropAspectRatio={3 / 2}
                imageCropLimitToImage={false}
                utils={['crop', 'filter', 'finetune', 'fill']}
                uploaderOptions={['upload']}
                initialImage={spotlightItem?.image}
                image={localImage}
                onSubmitAsPinturaRes={(newPinturaRes) =>
                  formik.setFieldValue('image', newPinturaRes)
                }
                disclosure={
                  formik.values.image ? (
                    <div className="relative h-[200px] w-[300px]">
                      <WebUI.DialogDisclosure
                        className="cursor-pointer overflow-hidden rounded-default"
                        as="button"
                      >
                        {'id' in formik.values.image ? (
                          <SharpImage
                            alt=""
                            height={200}
                            width={300}
                            image={formik.values.image}
                          />
                        ) : (
                          <BlobImage
                            alt=""
                            height={200}
                            width={300}
                            blob={formik.values.image.dest}
                          />
                        )}
                      </WebUI.DialogDisclosure>
                      <WebUI.IconButton
                        className="absolute top-2 right-2 text-ds-md"
                        size="default_alt"
                        variant="secondary"
                        onClick={() => {
                          formik.setFieldValue('image', null)
                          setLocalImage(null)
                        }}
                      >
                        <WebUI.PhosphorIcon icon="x" />
                      </WebUI.IconButton>
                    </div>
                  ) : (
                    <WebUI.FileUploader
                      accept={{'image/*': []}}
                      onDropAccepted={([imageFile]) => {
                        if (imageFile) {
                          uploadPinturaImageFormModalRef.current?.show()
                          setLocalImage(imageFile)
                        }
                      }}
                    >
                      <WebUI.FileUploaderInput />
                      <WebUI.FileUploaderButton
                        className="!h-[200px] w-[300px] border border-solid [&_.Button-content]:max-w-24 [&_.Button-content]:flex-0 [&_.Button-content]:whitespace-normal"
                        orientation="vertical"
                        variant="secondary"
                        iconBefore={
                          <WebUI.PhosphorIcon
                            className="text-ds-2xl text-teal-50"
                            icon="upload-simple"
                          />
                        }
                      >
                        Upload Image
                      </WebUI.FileUploaderButton>
                    </WebUI.FileUploader>
                  )
                }
              />
            </WebUI.FormField>
          </div>
        </div>
        <div className="flex flex-row px-12 py-6">
          <WebUI.Button
            type="submit"
            loading={formik.isSubmitting}
            onClick={formik.submitForm}
          >
            Save Spotlight
          </WebUI.Button>
        </div>
      </form>
    </WebUI.Modal>
  )
}

// MARK: – BlobImage

interface BlobImageProps extends WebUI.ImageProps {
  blob: Blob
}

const BlobImage = ({blob, ...restProps}: BlobImageProps) => {
  const objectUrl = useMemo(() => URL.createObjectURL(blob), [blob])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [objectUrl])

  return <WebUI.Image src={objectUrl} {...restProps} />
}

export default SpotlightItemForm
