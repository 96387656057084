import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'

export interface CollectionPaymentsStatsProps {
  collection: Api.TabDetailed
}

const CollectionPaymentsStats = ({
  collection,
}: CollectionPaymentsStatsProps) => (
  <WebUI.VStack className="gap-2">
    <WebUI.Heading className="text-gray400 uppercase" as="h6">
      Total Collected
    </WebUI.Heading>
    <p className="font-light text-gray800 text-h-6">
      {Util.formatAmount(collection.payments_total)}
    </p>
  </WebUI.VStack>
)

export default CollectionPaymentsStats
