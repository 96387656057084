import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {
  UploadPinturaImageForm,
  UploadPinturaImageFormProps,
} from './UploadPinturaImageForm'
import {
  AlbumImagePickerForm,
  usePartnerAlbumsQuery,
} from './AlbumImagePickerForm'

// MARK: – HeaderImagePickerModal

export interface HeaderImagePickerModalProps
  extends WebUI.ModalProps,
    Pick<
      HeaderImagePickerProps,
      | 'placeholderSrc'
      | 'initialImage'
      | 'imageCropAspectRatio'
      | 'onImageSubmit'
      | 'imageType'
      | 'parentPath'
      | 'pinturaUtils'
    > {
  heading?: React.ReactNode
}

export const HeaderImagePickerModal = React.forwardRef<
  WebUI.DialogInstance,
  HeaderImagePickerModalProps
>(
  (
    {
      heading = 'Add Image',
      placeholderSrc,
      initialImage,
      imageCropAspectRatio,
      imageType,
      parentPath,
      pinturaUtils,
      onImageSubmit,
      initialVisible = false,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Image picker"
      className={WebUI.cn('[&_>.ModalContentView]:h-full', className)}
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />

          <WebUI.ModalHeader>{heading}</WebUI.ModalHeader>

          <HeaderImagePicker
            className="min-h-0 grow"
            placeholderSrc={placeholderSrc}
            initialImage={initialImage}
            imageCropAspectRatio={imageCropAspectRatio}
            imageType={imageType}
            pinturaUtils={pinturaUtils}
            parentPath={parentPath}
            onImageSubmit={(newImage) => {
              onImageSubmit?.(newImage)
              dialog.hide()
            }}
          />
        </>
      )}
    </WebUI.Modal>
  ),
)

// MARK: – HeaderImagePicker

export interface HeaderImagePickerProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<
      UploadPinturaImageFormProps,
      'placeholderSrc' | 'initialImage' | 'imageType' | 'imageCropAspectRatio'
    > {
  pinturaUtils?: UploadPinturaImageFormProps['utils']
  parentPath?: UploadPinturaImageFormProps['parentPath']
  onImageSubmit?: UploadPinturaImageFormProps['onSubmit']
}

export const HeaderImagePicker = ({
  placeholderSrc,
  initialImage,
  imageCropAspectRatio,
  pinturaUtils,
  imageType = 'header',
  parentPath = 'user',
  onImageSubmit,
  className,
  ...restProps
}: HeaderImagePickerProps) => {
  const media = WebUI.useMedia()
  const albumsQuery = usePartnerAlbumsQuery()

  return (
    <WebUI.Tabs
      className={WebUI.cn('flex-col divide-y', className)}
      orientation={media.lg ? 'vertical' : 'horizontal'}
      {...restProps}
    >
      {(tabs) => (
        <>
          <div className="flex min-h-0 grow flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0">
            <WebUI.TabList
              aria-label="Navigation"
              className="!border-r-0 !border-b-0 min-w-[256px] flex-0 overflow-x-auto lg:overflow-hidden"
            >
              <WebUI.Tab id="upload-image">Upload Image</WebUI.Tab>
              {albumsQuery.data?.map((p) => (
                <WebUI.Tab key={p.key} id={p.key}>
                  {p.name}
                </WebUI.Tab>
              ))}
            </WebUI.TabList>

            <WebUI.TabPanel className="min-h-0 grow" tabId="upload-image">
              <UploadPinturaImageForm
                className="h-full p-8"
                imageType={imageType}
                imageCropAspectRatio={imageCropAspectRatio}
                parentPath={parentPath}
                utils={pinturaUtils}
                active={tabs.selectedId === 'upload-image'}
                placeholderSrc={placeholderSrc}
                initialImage={initialImage}
                onSubmit={onImageSubmit}
              />
            </WebUI.TabPanel>

            {albumsQuery.data?.map((p) => (
              <WebUI.TabPanel
                key={p.key}
                className="min-h-0 grow"
                tabId={p.key}
              >
                <AlbumImagePickerForm
                  className="h-full"
                  active={tabs.selectedId === p.key}
                  images={p.images}
                  onSubmit={(selectedImageId) => {
                    const selectedImage = p.images.find(
                      (i) => i.id === selectedImageId,
                    )
                    if (selectedImage) {
                      onImageSubmit?.(selectedImage)
                    }
                  }}
                />
              </WebUI.TabPanel>
            ))}
          </div>

          {/** HACK: this is an element for portals to attach to */}
          <div id="image-picker-toolbar" />
        </>
      )}
    </WebUI.Tabs>
  )
}
