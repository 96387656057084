import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'

export interface EntityTypeFormValues {
  entityType: Api.UserEntityType | '' | null
}

interface EntityTypeFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  initialValues: EntityTypeFormValues
  onSubmit: (values: EntityTypeFormValues) => void
}

const EntityTypeForm = ({
  initialValues,
  onSubmit,
  ...restProps
}: EntityTypeFormProps) => {
  const [entityType, setEntityType] = useState<
    EntityTypeFormValues['entityType']
  >(initialValues.entityType)

  return (
    <WebUI.Form
      className="[&_>_.Form-inner]:gap-7"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit({entityType})
      }}
      {...restProps}
    >
      <WebUI.Text className="font-black text-ds-lg">
        How will you use Cheddar Up?
      </WebUI.Text>
      <WebUI.ToggleGroup
        className="flex-col gap-6"
        value={entityType}
        onValueChange={(value) => setEntityType(value as Api.UserEntityType)}
      >
        <WebUI.ToggleGroupItem
          as={EntityLabel}
          label="As an individual"
          text="You are collecting from or on behalf of a group."
          value="individual"
        />
        <WebUI.ToggleGroupItem
          as={EntityLabel}
          label="On behalf of an organization"
          text="You will be prompted to provide an associated tax ID later."
          value="company"
        />
        <WebUI.Disclosure initialVisible={entityType === ''}>
          {(disclosure) => (
            <WebUI.ToggleGroupItem
              as={GroupItemWrap}
              value=""
              className="aria-checked:bg-teal-80 aria-checked:shadow-[inset_0_0_0_1px_theme(colors.natural.80)]"
              onClick={disclosure.toggle}
              onBlur={disclosure.hide}
            >
              <WebUI.Text className="font-black text-ds-md">
                As a payer or participant
              </WebUI.Text>
              <WebUI.DisclosureContent className="[&_>_.DisclosureContent-inner]:mt-1">
                <WebUI.HStack className="gap-2">
                  <WebUI.PhosphorIcon
                    className="mt-0_5"
                    icon="warning-circle-fill"
                    color={tailwindConfig.theme.colors.orange[50]}
                    width={18}
                  />
                  <WebUI.Text className="font-light text-ds-base">
                    You don’t need to create an account. Just ask your group’s
                    organizer for a link.
                  </WebUI.Text>
                </WebUI.HStack>
              </WebUI.DisclosureContent>
            </WebUI.ToggleGroupItem>
          )}
        </WebUI.Disclosure>
      </WebUI.ToggleGroup>
      <WebUI.RoundedButton
        className="w-[180px]"
        type="submit"
        disabled={!entityType}
      >
        Continue
      </WebUI.RoundedButton>
    </WebUI.Form>
  )
}

// MARK: - EntityLabel

export interface EntityLabelProps
  extends React.ComponentPropsWithoutRef<'button'> {
  label: string
  text: string
}

export const EntityLabel: React.FC<EntityLabelProps> = ({
  label,
  text,
  ...restProps
}) => (
  <GroupItemWrap {...restProps}>
    <WebUI.Text className="font-black text-ds-md">{label}</WebUI.Text>
    <WebUI.Text className="font-light text-ds-base">{text}</WebUI.Text>
  </GroupItemWrap>
)

// MARK: - GroupItemWrap

const GroupItemWrap: React.FC<React.ComponentPropsWithoutRef<'button'>> = ({
  className,
  children,
  ...restProps
}) => (
  <WebUI.VStack
    as="button"
    className={WebUI.cn(
      'cursor-pointer rounded-extended border-none bg-natural-80 p-6 text-left text-gray800 aria-checked:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
      className,
    )}
    type="button"
    {...restProps}
  >
    {children}
  </WebUI.VStack>
)

export default EntityTypeForm
