import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import {useFormik} from '@cheddarup/react-util'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useMemo, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ParammedTabs} from 'src/components/ParammedTabs'
import {
  api,
  useCreateTabSignupMutation,
  useUpdateTabSignupMutation,
} from '@cheddarup/api-client'
import {
  SignupViewList,
  makeTransientSignup,
} from 'src/components/SignupViewList'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import {CalendarDate, getLocalTimeZone, today} from '@internationalized/date'
import {yupCalendarDateSchema} from 'src/helpers/YupInternationalizedDateSchema'
import ChecklistBox from 'src/images/ChecklistBox.svg'
import CalendarChecked from 'src/images/CalendarChecked.svg'

import {SignUpSpotList} from './SignUpSpotList'

export interface SignUpFormValues {
  type: Api.SignUpType
  name: string
  description: string
  signupListDate: CalendarDate | null
  groupedBy: Api.SignUpGroupedBy
  timeZone: {
    enabled: boolean
    value: string
  }
  hideNamesFromPublicView: {
    enabled: boolean
  }
  comment: {
    enabled: boolean
    required: boolean
    fieldName: string
    hiddenFromPublicView: boolean
  }
  perPersonSpotLimit: {
    enabled: boolean
    value: string
  }
  allowParticipantToCancel: boolean
  cancelBasedOnDateOptions: {
    enabled: boolean
    value: number | null // in seconds
  }
  signupReminder: {
    enabled: boolean
    value: number | null
  }
  notifyMeWhenParticipantEdits: boolean
  requireAtLeastOneSpot: boolean
}

export type SignUpFormik = ReturnType<typeof useFormik<SignUpFormValues>>

const SignUpFormPage = () => {
  const location = useLocation()
  const urlParams = useParams()
  const navigate = useNavigate()
  const [signUpTypeParam] = useQueryParam(
    'type',
    withDefault(StringParam, 'list'),
  )
  const tabsRef = useRef<WebUI.TabsInstance>(null)
  const dialogDefaultHideRef = useRef<(() => void) | null>(null)
  const alertRef = useRef<WebUI.DialogInstance>(null)
  const tabQuery = api.tabs.detail.useSuspenseQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const signUpQuery = api.tabSignups.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: urlParams.signup!,
      },
    },
    {
      enabled: urlParams.signup != null,
    },
  )
  const isSpotListEmptyQuery = api.tabSignupSpots.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: urlParams.signup!,
      },
    },
    {
      enabled: urlParams.signup != null,
      select: (spots) => spots.length === 0,
    },
  )
  const createTabSignupMutation = useCreateTabSignupMutation()
  const updateTabSignupMutation = useUpdateTabSignupMutation()
  const growlActions = WebUI.useGrowlActions()
  const noSpotsAlertRef = useRef<WebUI.DialogInstance>(null)

  const signUpType = signUpQuery.data?.options.signupType ?? signUpTypeParam

  const formik = useFormik<SignUpFormValues>({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      timeZone: Yup.object().shape({
        enabled: Yup.boolean(),
        value: Yup.string().when('enabled', {
          is: true,
          // biome-ignore lint/suspicious/noThenProperty:
          then: (schema) => schema.required('Required'),
        }),
      }),
      signupListDate: yupCalendarDateSchema()
        .min(today(getLocalTimeZone()), "Can't be earlier than today")
        .nullable(),
      comment: Yup.object().shape({
        enabled: Yup.boolean(),
        fieldName: Yup.string().when('enabled', {
          is: true,
          // biome-ignore lint/suspicious/noThenProperty:
          then: (schema) => schema.required('Required'),
        }),
        hiddenFromPublicView: Yup.boolean(),
      }),
      perPersonSpotLimit: Yup.object().shape({
        enabled: Yup.boolean(),
        value: Yup.number().when('enabled', {
          is: true,
          // biome-ignore lint/suspicious/noThenProperty:
          then: (schema) => schema.required('Required'),
        }),
      }),
      cancelBasedOnDateOptions: Yup.object().shape({
        enabled: Yup.boolean(),
        value: Yup.number()
          .nullable()
          .when('enabled', {
            is: true,
            // biome-ignore lint/suspicious/noThenProperty:
            then: (schema) => schema.required('Required'),
          }),
      }),
    }),
    initialValues: {
      type: signUpType === 'schedule' ? 'schedule' : 'list',
      name: signUpQuery.data?.name ?? '',
      description: signUpQuery.data?.description ?? '',
      signupListDate: signUpQuery.data?.options.signupListDate
        ? Util.parseCalendarDate(signUpQuery.data.options.signupListDate)
        : null,
      groupedBy: signUpQuery.data?.options.groupedBy ?? 'spot',
      timeZone: {
        enabled: signUpQuery.data?.options.timeZone != null,
        value:
          signUpQuery.data?.options.timeZone ??
          Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      hideNamesFromPublicView: {
        enabled: signUpQuery.data?.options.hideNamesFromPublicView ?? false,
      },
      comment: {
        enabled: signUpQuery.data?.options.comment.enabled ?? false,
        required: signUpQuery.data?.options.comment.required ?? false,
        fieldName: signUpQuery.data?.options.comment.fieldName || '',
        hiddenFromPublicView:
          signUpQuery.data?.options.comment.hiddenFromPublicView ?? false,
      },
      perPersonSpotLimit: {
        enabled: signUpQuery.data?.options.perPersonSpotLimit.enabled ?? false,
        value: String(
          signUpQuery.data?.options.perPersonSpotLimit.value === 0
            ? ''
            : signUpQuery.data?.options.perPersonSpotLimit.value ?? '',
        ),
      },
      allowParticipantToCancel:
        signUpQuery.data?.options.allowParticipantToCancel ?? false,
      cancelBasedOnDateOptions: {
        enabled: signUpQuery.data?.options.cancelBasedOnDate?.enabled ?? false,
        value: signUpQuery.data?.options.cancelBasedOnDate?.value ?? null,
      },
      signupReminder: {
        enabled: signUpQuery.data?.options.signupReminder?.enabled ?? false,
        value:
          signUpQuery.data?.options.signupReminder?.value ?? 24 * 60 * 60 * 2,
      },
      notifyMeWhenParticipantEdits:
        signUpQuery.data?.options.notifyMeWhenParticipantEdits ?? true,
      requireAtLeastOneSpot:
        signUpQuery.data?.options.requireAtLeastOneSpot ?? false,
    },
    onSubmit: async (values) => {
      const signUpBody = {
        name: values.name,
        description: values.description,
        // Hide form if no spots have been added. If the form is already hidden, don't change it.
        hidden: false,
        options: {
          groupedBy: values.groupedBy,
          signupType: values.type,
          timeZone: values.timeZone.value,
          hideNamesFromPublicView: values.hideNamesFromPublicView.enabled,
          comment: {
            enabled: values.comment.enabled,
            fieldName: values.comment.fieldName,
            required: values.comment.required,
            hiddenFromPublicView: values.hideNamesFromPublicView.enabled
              ? false
              : values.comment.hiddenFromPublicView,
          },
          perPersonSpotLimit: {
            enabled: values.perPersonSpotLimit.enabled,
            value: values.perPersonSpotLimit.value
              ? Number(values.perPersonSpotLimit.value)
              : 0,
          },
          allowParticipantToCancel: values.allowParticipantToCancel,
          cancelBasedOnDate: {
            enabled: values.allowParticipantToCancel
              ? values.cancelBasedOnDateOptions.enabled
              : false,
            value: values.cancelBasedOnDateOptions.value ?? 0,
          },
          signupReminder: {
            enabled: values.signupReminder.enabled ?? false,
            value: values.signupReminder.value ?? 0,
          },
          notifyMeWhenParticipantEdits: values.notifyMeWhenParticipantEdits,
          signupListDate:
            values.signupListDate
              ?.toDate(
                values.timeZone.enabled
                  ? values.timeZone.value
                  : getLocalTimeZone(),
              )
              .toISOString() ?? null,
          requireAtLeastOneSpot: values.requireAtLeastOneSpot,
        },
      }

      try {
        if (signUpQuery.data) {
          await updateTabSignupMutation.mutateAsync({
            pathParams: {
              // biome-ignore lint/style/noNonNullAssertion:
              tabId: urlParams.collection!,
              signupId: signUpQuery.data.id,
            },
            body: signUpBody,
          })

          dialogDefaultHideRef.current?.()
        } else {
          const newSignup = await createTabSignupMutation.mutateAsync({
            pathParams: {
              // biome-ignore lint/style/noNonNullAssertion:
              tabId: urlParams.collection!,
            },
            body: signUpBody,
          })
          navigate(String(newSignup.id))
        }
      } catch {
        growlActions.clear()
        growlActions.show('error', {
          title: `Couldn't save the signup!`,
        })
      }
    },
  })

  return (
    <WebUI.Modal
      aria-label="Sign up form"
      ref={(dialog) => {
        if (!dialog) {
          return
        }

        dialogDefaultHideRef.current = dialog.hide
        dialog.hide = () => {
          if (formik.dirty) {
            alertRef.current?.show()
          } else {
            dialogDefaultHideRef.current?.()
          }
        }
      }}
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate({pathname: '..', search: location.search})}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />

          <WebUI.ModalHeader className="border-b-0">
            <WebUI.PageHeader
              graphics={
                <img
                  src={signUpType === 'list' ? ChecklistBox : CalendarChecked}
                  alt="signup form icon"
                />
              }
              subheading={
                signUpType === 'list'
                  ? 'This simple sign up is perfect for potlucks and wish lists.'
                  : 'Include dates and/or times with your available spots.'
              }
            >
              {`Sign Up ${Util.capitalize(signUpType)}`}
            </WebUI.PageHeader>
          </WebUI.ModalHeader>

          <form
            className="flex h-full min-h-0 grow flex-col"
            noValidate
            onReset={formik.handleReset}
            onSubmit={(event) => {
              if (signUpQuery.data || isSpotListEmptyQuery.data) {
                formik.handleSubmit(event)
              } else {
                event.preventDefault()
              }
            }}
          >
            <ParammedTabs
              ref={tabsRef}
              className={`min-h-0 grow [&_>_.TabPanel:[id="preview"]]:py-6 [&_>_.TabPanel:not([id="details"]):not([id="preview"])]:p-6 [&_>_.TabPanel:not([id="details"]):not([id="preview"])]:py-6 sm:[&_>_.TabPanel:not([id="details"]):not([id="preview"])]:px-12 [&_>_.TabPanel]:grow [&_>_.TabPanel]:overflow-y-auto [&_>_.TabPanel]:overflow-x-hidden`}
              variant="underlined"
              defaultPaneKey="details"
              paneKeyName="view"
            >
              <WebUI.TabList
                aria-label="Sign up form navigation"
                className={
                  'mx-6 flex-0 border-b-0 sm:mx-12 [&_>_.TabList-underline]:bg-orange-50 [&_>_.Tab_>_.Button-content]:font-normal [&_>_.Tab_>_.Button-content]:text-ds-sm sm:[&_>_.Tab_>_.Button-content]:text-ds-md'
                }
              >
                <WebUI.Tab id="details">Details</WebUI.Tab>
                <WebUI.Tab id="settings">Settings</WebUI.Tab>
                <WebUI.Tab id="notifications">Notifications</WebUI.Tab>
                <WebUI.Tab id="preview">Preview</WebUI.Tab>
              </WebUI.TabList>

              <WebUI.Separator variant="primary" />

              <WebUI.TabPanel id="details">
                <div className="flex flex-col gap-5 *:px-6 sm:*:px-12">
                  <SignUpDetails
                    className="pt-6"
                    formik={formik}
                    signUpType={signUpType === 'list' ? 'list' : 'schedule'}
                  />
                  <SignUpSpotList
                    className="pb-6"
                    formik={formik}
                    signUp={signUpQuery.data ?? null}
                  />
                </div>
              </WebUI.TabPanel>
              <WebUI.TabPanel id="settings">
                <SignUpSettings formik={formik} />
              </WebUI.TabPanel>
              <WebUI.TabPanel id="notifications">
                <SignUpNotifications formik={formik} />
              </WebUI.TabPanel>
              <WebUI.TabPanel id="preview">
                <SignUpPreview
                  signupValues={formik.values}
                  spots={signUpQuery.data?.spots ?? []}
                />
              </WebUI.TabPanel>
            </ParammedTabs>

            <WebUI.Separator variant="primary" />

            <div className="flex flex-row justify-end bg-natural-100 px-4 py-5">
              <WebUI.Button
                disabled={isSpotListEmptyQuery.data || !signUpQuery.data}
                onClick={async () => {
                  const errors = await formik.validateForm()
                  if (Object.keys(errors).length > 0) {
                    const erroredPaneKey =
                      errors.name || errors.description
                        ? 'details'
                        : errors.comment ||
                            errors.hideNamesFromPublicView ||
                            errors.perPersonSpotLimit ||
                            errors.timeZone
                          ? 'settings'
                          : null

                    if (erroredPaneKey != null) {
                      tabsRef.current?.setCurrentId(erroredPaneKey)
                    }

                    return
                  }
                  if (
                    !signUpQuery.data ||
                    signUpQuery.data.spots.length === 0
                  ) {
                    noSpotsAlertRef.current?.show()
                  } else {
                    formik.submitForm()
                  }
                }}
                variant="primary"
                size="large"
                loading={formik.isSubmitting}
              >
                Save Sign Up
              </WebUI.Button>
              <WebUI.Alert ref={noSpotsAlertRef}>
                <WebUI.AlertHeader>
                  You haven't added any spots
                </WebUI.AlertHeader>
                <WebUI.AlertContentView
                  text={
                    "Are you sure you'd like to save this signup without any spots?"
                  }
                  actions={
                    <>
                      <WebUI.Button
                        variant="primary"
                        onClick={() => {
                          formik.submitForm()
                          noSpotsAlertRef.current?.hide()
                        }}
                      >
                        Save Sign Up
                      </WebUI.Button>
                      <WebUI.Button
                        variant="secondary"
                        onClick={() => noSpotsAlertRef.current?.hide()}
                      >
                        Cancel
                      </WebUI.Button>
                    </>
                  }
                />
              </WebUI.Alert>
            </div>
          </form>

          <UpgradeRequiredAlert
            key={urlParams.signup}
            visible={
              !urlParams.signup &&
              tabQuery.data &&
              tabQuery.data.status !== 'draft' &&
              !tabQuery.data.is_pro &&
              tabQuery.data.reportsAvailable.activeFormsCount +
                tabQuery.data.reportsAvailable.activeSignupsCount >=
                1
            }
            onDidHide={() => dialog.hide()}
          />
          <DirtyFormConfirmAlert
            ref={alertRef}
            onProceed={() => dialogDefaultHideRef.current?.()}
          />
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – SignUpDetails

interface SignUpDetailsProps extends React.ComponentPropsWithoutRef<'div'> {
  formik: SignUpFormik
  signUpType: 'list' | 'schedule'
}

const SignUpDetails: React.FC<SignUpDetailsProps> = ({
  formik,
  signUpType,
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn('flex max-w-[620px] flex-col gap-4', className)}
    {...restProps}
  >
    <WebUI.FormField label="Sign Up Name" error={formik.errors.name} required>
      <WebUI.Input
        name="name"
        placeholder="Sign Up Name"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </WebUI.FormField>
    <WebUI.FormField label="Description" error={formik.errors.description}>
      <WebUI.RichTextEditor
        key={formik.initialValues.description}
        className="min-h-[160px]"
        name="description"
        placeholder="Description (optional)"
        initialMarkdownValue={formik.values.description}
        onMarkdownValueChange={(newDescription) =>
          formik.setFieldValue('description', newDescription)
        }
      >
        <WebUI.RichTextEditorToolbar
          pick={[
            WebUI.MARK_BOLD,
            WebUI.MARK_ITALIC,
            WebUI.ELEMENT_LINK,
            WebUI.ELEMENT_OL,
            WebUI.ELEMENT_UL,
          ]}
          rootClassName="-order-1"
        />
      </WebUI.RichTextEditor>
    </WebUI.FormField>
    {formik.values.type === 'list' && (
      <WebUI.FormField
        label="Date (Optional)"
        error={formik.errors.signupListDate}
      >
        <WebUI.DatePicker
          size="compact"
          minValue={today(getLocalTimeZone())}
          value={formik.values.signupListDate}
          onValueChange={(newSignupListDate) =>
            formik.setFieldValue('signupListDate', newSignupListDate)
          }
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
    )}
  </div>
)

// MARK: – SignUpSettings

interface SignUpSettingsProps extends React.ComponentPropsWithoutRef<'div'> {
  formik: SignUpFormik
}

const SignUpSettings = ({
  formik,
  className,
  ...restProps
}: SignUpSettingsProps) => (
  <div
    className={WebUI.cn(
      'flex flex-col gap-6 [&_.DisclosureContent]:max-w-[540px] [&_.DisclosureSwitch_.Switch-label]:font-normal',
      className,
    )}
    {...restProps}
  >
    {formik.values.type === 'schedule' && (
      <>
        <WebUI.Disclosure
          className="gap-3"
          visible={formik.values.timeZone.enabled}
          onVisibleChange={(newVisible) =>
            formik.setFieldValue('timeZone.enabled', newVisible)
          }
        >
          <WebUI.DisclosureSwitch name="timeZone.enabled">
            <span className="Switch-label">
              Change Default Time Zone <span className="text-tint">(GMT)</span>
            </span>
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent className="text-ds-sm">
            <div className="flex flex-col gap-3">
              <span>Select the default time zone for your sign up.</span>
              <WebUI.FormField
                required
                label="Time Zone"
                error={formik.errors.timeZone?.value}
              >
                <WebUI.SupportedTimeZoneCombobox
                  className="max-w-[320px]"
                  inputSize="compact"
                  value={formik.values.timeZone.value}
                  onValueChange={(newTimeZoneValue) =>
                    formik.setFieldValue('timeZone.value', newTimeZoneValue)
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </div>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>

        <WebUI.Separator variant="primary" />
      </>
    )}
    <WebUI.Disclosure
      className="gap-3"
      visible={formik.values.hideNamesFromPublicView.enabled}
      onVisibleChange={(newVisible) =>
        formik.setFieldValue('hideNamesFromPublicView.enabled', newVisible)
      }
    >
      <WebUI.DisclosureSwitch>
        Hide names from public view
      </WebUI.DisclosureSwitch>
      <WebUI.DisclosureContent className="text-ds-sm">
        Your participants will always be required to enter their name and email
        when signing up but you can turn this on to hide names and comments from
        the public. (Emails are never made public.)
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>
    <WebUI.Separator variant="primary" />
    <WebUI.Disclosure
      className="gap-3"
      visible={formik.values.comment.enabled}
      onVisibleChange={(newVisible) =>
        formik.setFieldValue('comment.enabled', newVisible)
      }
    >
      <WebUI.DisclosureSwitch>
        Ask for participants to comment
      </WebUI.DisclosureSwitch>
      <WebUI.DisclosureContent className="text-ds-sm">
        <div className="flex flex-col gap-3">
          <span>
            Allow your participants to leave a comment when signing up (e.g.
            what they’re bringing, etc.).
          </span>

          <div className="flex flex-col gap-6">
            <WebUI.FormField
              required
              label="What do you want to ask?"
              error={formik.errors.comment?.fieldName}
            >
              <WebUI.Input
                size="compact"
                name="comment.fieldName"
                placeholder="e.g., “Comment”, “Child’s name”, “Food item”…"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.comment.fieldName}
              />
            </WebUI.FormField>

            <WebUI.Checkbox
              name="comment.required"
              size="compact"
              checked={formik.values.comment.required}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              Make comment required
            </WebUI.Checkbox>

            {!formik.values.hideNamesFromPublicView.enabled && (
              <WebUI.Checkbox
                name="comment.hiddenFromPublicView"
                size="compact"
                checked={formik.values.comment.hiddenFromPublicView}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                Hide comment from public view
              </WebUI.Checkbox>
            )}
          </div>
        </div>
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>
    <WebUI.Separator variant="primary" />

    <WebUI.Disclosure
      className="gap-3"
      visible={formik.values.perPersonSpotLimit.enabled}
      onVisibleChange={(newVisible) =>
        formik.setFieldValue('perPersonSpotLimit.enabled', newVisible)
      }
    >
      <WebUI.DisclosureSwitch>Participation Limits</WebUI.DisclosureSwitch>
      <WebUI.DisclosureContent className="text-ds-sm">
        <div className="flex flex-col gap-3">
          <span>
            Restrict your participants to a maximum number of spots across your
            entire sign up.
          </span>
          <WebUI.FormField
            required
            label="Maximum Spots"
            error={formik.errors.perPersonSpotLimit?.value}
          >
            <WebUI.NumberInput
              name="perPersonSpotLimit.value"
              size="compact"
              placeholder="Qty"
              value={formik.values.perPersonSpotLimit.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        </div>
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>

    <WebUI.Separator variant="primary" />

    <WebUI.Disclosure
      className="gap-3"
      visible={formik.values.requireAtLeastOneSpot}
      onVisibleChange={(newVisible) =>
        formik.setFieldValue('requireAtLeastOneSpot', newVisible)
      }
    >
      <WebUI.DisclosureSwitch>
        Require participants to sign up for at least one spot
      </WebUI.DisclosureSwitch>
      <WebUI.DisclosureContent className="text-ds-sm">
        Only toggle this on if everyone who visits your page is required to sign
        up. Visitors will not be allowed to proceed to checkout until they sign
        up for at least one spot.
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>

    <WebUI.Separator variant="primary" />

    <WebUI.Disclosure
      className="gap-3"
      visible={formik.values.allowParticipantToCancel}
      onVisibleChange={(newVisible) =>
        formik.setFieldValue('allowParticipantToCancel', newVisible)
      }
    >
      <WebUI.DisclosureSwitch>
        Allow participants to delete their spot(s)
      </WebUI.DisclosureSwitch>
      <WebUI.DisclosureContent className="text-ds-sm">
        You will receive an automatic notification when a participant deletes
        their spot and the spot will be made available to others.
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>

    {((formik.values.type === 'schedule' &&
      formik.values.allowParticipantToCancel) ||
      (formik.values.type === 'list' &&
        formik.values.signupListDate &&
        formik.values.allowParticipantToCancel)) && (
      <>
        <WebUI.Separator variant="primary" />
        <WebUI.Disclosure
          className="gap-3"
          visible={formik.values.cancelBasedOnDateOptions.enabled}
          onVisibleChange={(newVisible) =>
            formik.setFieldValue('cancelBasedOnDateOptions.enabled', newVisible)
          }
        >
          <WebUI.DisclosureSwitch>
            Prevent participants from deleting spots based on the date
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent className="text-ds-sm">
            <div className="flex flex-col gap-3">
              <span>
                Prevent people from deleting a spot if it's within a certain
                time period of the spot date.
              </span>
              <WebUI.FormField
                className="self-start"
                required
                label="No spot deletions"
                error={formik.errors.cancelBasedOnDateOptions?.value}
              >
                <WebUI.DropdownSelect<number>
                  name="cancelBasedOnDateOptions.value"
                  size="compact"
                  value={formik.values.cancelBasedOnDateOptions.value}
                  onValueChange={(newValue) =>
                    formik.setFieldValue(
                      'cancelBasedOnDateOptions.value',
                      newValue,
                    )
                  }
                  onBlur={formik.handleBlur}
                  defaultValue={
                    formik.values.type === 'list' &&
                    formik.values.signupListDate
                      ? 24 * 60 * 60 * 2
                      : 0
                  }
                >
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 1}>
                    One day before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 2}>
                    Two days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 3}>
                    Three days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 4}>
                    Four days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 5}>
                    Five days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 6}>
                    Six days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 7}>
                    One week before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 7 * 2}>
                    Two weeks before
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
              </WebUI.FormField>
            </div>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
      </>
    )}
  </div>
)

// MARK: – SignUpNotifications

interface SignUpNotificationsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: SignUpFormik
}

const SignUpNotifications: React.FC<SignUpNotificationsProps> = ({
  formik,
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'flex flex-col gap-6 [&_.DisclosureContent]:max-w-[540px] [&_.DisclosureSwitch_.Switch-label]:font-normal',
      className,
    )}
    {...restProps}
  >
    {(formik.values.type === 'schedule' ||
      (formik.values.type === 'list' && !!formik.values.signupListDate)) && (
      <>
        <WebUI.Disclosure
          className="gap-3"
          visible={formik.values.signupReminder.enabled}
          onVisibleChange={(newVisible) =>
            formik.setFieldValue('signupReminder.enabled', newVisible)
          }
        >
          <WebUI.DisclosureSwitch>
            Send participants a reminder
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent className="text-ds-sm">
            <div className="flex flex-col gap-3">
              <span>
                An email will be sent to participants in advance of their
                scheduled spot.
              </span>
              <WebUI.FormField
                className="self-start"
                required
                label="Send a reminder"
                error={formik.errors.signupReminder?.value}
              >
                <WebUI.DropdownSelect<number>
                  name="cancelBasedOnDateOptions.value"
                  size="compact"
                  value={formik.values.signupReminder.value}
                  onValueChange={(newValue) =>
                    formik.setFieldValue('signupReminder.value', newValue)
                  }
                  onBlur={formik.handleBlur}
                >
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 1}>
                    One day before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 2}>
                    Two days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 3}>
                    Three days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 4}>
                    Four days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 5}>
                    Five days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 6}>
                    Six days before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 7}>
                    One week before
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value={24 * 60 * 60 * 7 * 2}>
                    Two weeks before
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
              </WebUI.FormField>
            </div>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>

        <WebUI.Separator variant="primary" />
      </>
    )}
    <WebUI.Disclosure
      className="gap-3"
      visible={formik.values.notifyMeWhenParticipantEdits}
      onVisibleChange={(newVisible) =>
        formik.setFieldValue('notifyMeWhenParticipantEdits', newVisible)
      }
    >
      <WebUI.DisclosureSwitch>
        Notify me by email when participants edit their sign up spot
      </WebUI.DisclosureSwitch>
      <WebUI.DisclosureContent className="text-ds-sm">
        You'll receive an email if a participant changes the information in the
        spot's comments field. (You'll always be notified if a participant
        cancels their spot.)
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>

    <WebUI.Separator variant="primary" />
  </div>
)

// MARK: – SignUpPreview

interface SignUpPreviewProps extends React.ComponentPropsWithoutRef<'div'> {
  signupValues: SignUpFormValues
  spots: Api.SignUpSpot[]
}

const SignUpPreview: React.FC<SignUpPreviewProps> = ({
  signupValues,
  spots,
  ...restProps
}) => {
  const transientPublicSignup = useMemo(
    () => makeTransientSignup(signupValues, spots),
    [signupValues, spots],
  )
  return <SignupViewList signup={transientPublicSignup} {...restProps} />
}

// MARK: – DirtyFormConfirmAlert

export interface DirtyFormConfirmAlertProps extends WebUI.AlertProps {
  onProceed?: () => void
}

export const DirtyFormConfirmAlert = React.forwardRef<
  WebUI.DialogInstance,
  DirtyFormConfirmAlertProps
>(({onProceed, className, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Confirm closing form modal"
    className={WebUI.cn('[&_.Alert-closeButton]:invisible', className)}
    {...restProps}
  >
    {(dialog) => (
      <>
        <WebUI.AlertHeader>
          Are you sure you want to close this sign up?
        </WebUI.AlertHeader>
        <WebUI.AlertContentView
          text="You haven’t saved your form and your information will be lost."
          actions={
            <>
              <WebUI.AlertActionButton onClick={() => onProceed?.()}>
                Close Sign Up
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton
                onClick={(event) => {
                  event.preventDefault()
                  dialog.hide()
                }}
              >
                Cancel
              </WebUI.AlertCancelButton>
            </>
          }
        />
      </>
    )}
  </WebUI.Alert>
))

export default SignUpFormPage
