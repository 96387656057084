import {z} from '@cheddarup/util'

import {s3ImageMetadataSchema} from './common'

export const headerImageSchema = z.object({
  id: z.number(),
  file_name: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  uuid: z.string(),
  user_id: z.number(),
  imageable_type: z.string(),
  imageable_id: z.number(),
  upload_path: z.string(),
  metadata: s3ImageMetadataSchema,
})

// TODO: rename
export const headerListItemSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  upload_path: z.string(),
  edited_image_url: z.string(),
  url: z.string(),
  edit_path: z.string(),
  editable: z.boolean(),
  partner: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  metadata: s3ImageMetadataSchema,
})

declare global {
  namespace Api {
    type HeaderImage = z.infer<typeof headerImageSchema>
    type HeaderListItemSchema = z.infer<typeof headerListItemSchema>
  }
}
