import * as Yup from 'yup'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateUserMutation} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import {guessError} from 'src/helpers/error-utils'
import config from 'src/config'
import UserDobHelpers from 'src/helpers/UserDobHelpers'
import {CalendarDate} from '@internationalized/date'
import useStripeTokenizer from 'src/views/c/hooks/useStripeTokenizer'
import {AccountInputLock} from 'src/components/AccountInputLock'
import {Elements} from 'src/components/Stripe'
import {WithdrawFundsAlert} from './components/WithdrawFundsAlert'
import {DeleteAccountConfirmationAlert} from './components/DeleteAccountAlert'
import {LinkButton} from 'src/components/LinkButton'
import {AccountTransfer} from './components/AccountTransfer'

const AccountDetailsPage = () => {
  const [isAccountTransferVisible, setIsAccountTransferVisible] =
    useState(false)
  const sessionQuery = api.auth.session.useSuspenseQuery()

  const isOrg = sessionQuery.data.user.entity_type !== 'individual'

  if (isAccountTransferVisible) {
    return <AccountTransfer />
  }

  return (
    <AccountSettingsContentLayout
      heading="Account Details"
      body={
        sessionQuery.data.user.editable ? (
          <div className="flex flex-col gap-3">
            <span>
              When you collect using Cheddar Up, there’s a minimal amount of
              information needed to validate identity and prevent fraud.{' '}
              {isOrg
                ? 'Enter the information of the organization and the individual managing the account.'
                : 'Enter the information of the individual managing the account.'}
            </span>

            <WebUI.Tooltip>
              <WebUI.TooltipAnchor className="text-tint">
                Why do I need to provide this?
              </WebUI.TooltipAnchor>
              <WebUI.TooltipContent>
                Federal regulations require us to collect this information to
                protect against fraud and to verify the authenticity of the
                individual managing this account. If we are unable to verify
                your identity, we may need to request more information.
              </WebUI.TooltipContent>
            </WebUI.Tooltip>
          </div>
        ) : (
          <span>
            Your account has been verified. For changes, contact{' '}
            <WebUI.Anchor
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${config.supportEmail}?subject=Change To My Cheddar Up Account&body=Please make the following changes to my account details`}
            >
              Cheddar Up Support
            </WebUI.Anchor>
            .
          </span>
        )
      }
    >
      <AccountTypeView />
      <Elements>
        <MyDetailsForm />
        {isOrg && <OrgDetailsForm />}
      </Elements>

      <div className="flex flex-col items-start gap-5">
        {isOrg && (
          <>
            {sessionQuery.data.user.withdrawal_data?.total_available_balance >
            0 ? (
              <LinkButton
                variant="default"
                disabled={sessionQuery.data.user.editable}
                to="/withdraw?type=transfer"
              >
                Transfer Account
              </LinkButton>
            ) : (
              <WebUI.Button
                disabled={sessionQuery.data.user.editable}
                onClick={() => setIsAccountTransferVisible(true)}
              >
                Transfer Account
              </WebUI.Button>
            )}
          </>
        )}

        {sessionQuery.data.user.withdrawal_data.total_available_balance > 0 ? (
          <WithdrawFundsAlert
            intent="delete-account"
            disclosure={
              <WebUI.DialogDisclosure
                className="font-light text-ds-sm"
                variant="link"
              >
                Delete Account
              </WebUI.DialogDisclosure>
            }
          />
        ) : (
          <DeleteAccountConfirmationAlert
            disclosure={
              <WebUI.DialogDisclosure
                className="font-light text-ds-sm"
                variant="link"
              >
                Delete Account
              </WebUI.DialogDisclosure>
            }
          />
        )}
      </div>
    </AccountSettingsContentLayout>
  )
}

// MARK: – AccountTypeView

const AccountTypeView = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const sessionQuery = api.auth.session.useSuspenseQuery()
  return (
    <AccountSettingsContentCard heading="Account Type" {...props}>
      <WebUI.FormFieldGroup>
        <WebUI.FormField label="Type">
          <WebUI.Input
            disabledVariant="filled"
            disabled
            value={
              {
                individual: 'Individual',
                company: 'Organization',
                non_profit: 'Non-profit',
              }[sessionQuery.data.user.entity_type ?? 'individual']
            }
          />
        </WebUI.FormField>
        <WebUI.FormField label="Currency">
          <WebUI.Input
            disabledVariant="filled"
            disabled
            value={
              sessionQuery.data.user.currency === 'usd'
                ? 'US Dollar'
                : sessionQuery.data.user.currency === 'cad'
                  ? 'Canadian Dollar'
                  : '?'
            }
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>
    </AccountSettingsContentCard>
  )
}

// MARK: – MyDetailsForm

const MyDetailsForm = (props: React.ComponentPropsWithoutRef<'form'>) => {
  const sessionQuery = api.auth.session.useSuspenseQuery()
  const updateUserMutation = useUpdateUserMutation()
  const growlActions = WebUI.useGrowlActions()
  const {createPersonToken, createVerficationDocumentToken} =
    useStripeTokenizer()

  const isFullSSNInquired =
    !sessionQuery.data.user.personal_id_number_provided &&
    sessionQuery.data.stripe_data.fieldsNeeded.some((f) =>
      f.includes('.id_number'),
    )
  const isUserIDPhotoProvided =
    !sessionQuery.data.stripe_data.fieldsNeeded.some((f) =>
      f.includes('verification.document'),
    )

  const personalAddress = sessionQuery.data.user.personal_address

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().trim().required('Required'),
      lastName: Yup.string().trim().required('Required'),
      ssnLast4:
        sessionQuery.data.user.editable &&
        !sessionQuery.data.user.ssn_last_4_provided
          ? Yup.string().required('Required').matches(/\d{4}/, '1234')
          : Yup.mixed(),
      dateOfBirth: Yup.mixed<CalendarDate>().required('Required'),
      personalIdNumber: isFullSSNInquired
        ? Yup.string().required('Required')
        : Yup.mixed(),
      photoIDFront:
        sessionQuery.data.user.editable && !isUserIDPhotoProvided
          ? Yup.mixed().required('Required')
          : Yup.mixed().nullable(),
      photoIDBack:
        sessionQuery.data.user.editable && !isUserIDPhotoProvided
          ? Yup.mixed().required('Required')
          : Yup.mixed().nullable(),
    }),
    initialValues: {
      firstName: sessionQuery.data.user.first_name,
      lastName: sessionQuery.data.user.last_name,
      dateOfBirth: UserDobHelpers.toCalendarDate(sessionQuery.data.user.dob),
      personalAddress,
      ssnLast4: '',
      personalIdNumber: '',
      photoIDFront: null,
      photoIDBack: null,
    },
    onSubmit: async (values) => {
      if (!sessionQuery.data.user.editable) {
        return
      }

      try {
        let front_file_token: string | null = null
        let back_file_token: string | null = null

        if (values.photoIDFront && values.photoIDBack) {
          ;[front_file_token, back_file_token] = await Promise.all([
            createVerficationDocumentToken(values.photoIDFront),
            createVerficationDocumentToken(values.photoIDBack),
          ])
        }

        let personToken: string | null = null

        if (
          values.ssnLast4 ||
          values.personalIdNumber ||
          (front_file_token && back_file_token)
        ) {
          personToken = await createPersonToken({
            id_number: values.personalIdNumber || undefined,
            ssn_last_4: values.ssnLast4 || undefined,
            verification:
              front_file_token && back_file_token
                ? {
                    document: {
                      front: front_file_token,
                      back: back_file_token,
                    },
                  }
                : undefined,
          })
        }

        await updateUserMutation.mutateAsync({
          body: {
            first_name: values.firstName,
            last_name: values.lastName,
            personal_address: values.personalAddress,
            dob: values.dateOfBirth ?? undefined,
            person_token: personToken ?? undefined,
          },
        })
        growlActions.show('success', {
          title: 'Success!',
          body: 'Your account details have been updated.',
        })
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  const isFormDisabled =
    !sessionQuery.data.user.editable &&
    sessionQuery.data.stripe_data.payoutsEnabled &&
    sessionQuery.data.stripe_data.fieldsNeeded.length === 0

  return (
    <AccountSettingsContentCard
      as="form"
      heading="My Details"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <WebUI.FormFieldGroup>
        <WebUI.FormField
          required
          label="First Name"
          error={formik.errors.firstName}
        >
          <WebUI.Input
            name="firstName"
            disabled={isFormDisabled}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField
          required
          label="Last Name"
          error={formik.errors.lastName}
        >
          <WebUI.Input
            name="lastName"
            disabled={isFormDisabled}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormField required label="Address">
        <WebUI.AddressCombobox
          key={sessionQuery.dataUpdatedAt}
          disabled={isFormDisabled}
          defaultAddress={[personalAddress.line1, personalAddress.line2]
            .filter((ac) => !!ac)
            .join(' ')}
          withDetails
          onAddressChange={(location) => {
            const details = location.details
            if (details) {
              const addressComponents = extractAddressComponents(
                details,
                sessionQuery.data.user.currency,
              )
              formik.setFieldValue('personalAddress', addressComponents)
            }
          }}
        />
      </WebUI.FormField>

      <WebUI.FormFieldGroup>
        <WebUI.FormField
          required
          label="Date of Birth"
          error={formik.errors.dateOfBirth}
        >
          <AccountInputLock locked={!!sessionQuery.data.user.dob.month}>
            {sessionQuery.data.user.dob.month ? (
              <div />
            ) : (
              <WebUI.DateInput
                disabledVariant="filled"
                disabled={isFormDisabled}
                value={formik.values.dateOfBirth}
                onValueChange={(newDateOfBirth) =>
                  formik.setFieldValue('dateOfBirth', newDateOfBirth)
                }
                onBlur={formik.handleBlur}
              />
            )}
          </AccountInputLock>
        </WebUI.FormField>
        <WebUI.FormField
          label={`Last 4 of ${sessionQuery.data.user.currency === 'usd' ? 'SSN' : 'SIN'}`}
          error={formik.errors.ssnLast4}
        >
          <AccountInputLock
            locked={!!sessionQuery.data.user.ssn_last_4_provided}
          >
            <WebUI.PatternInput
              name="ssn_last_4"
              mask="_"
              format="####"
              as={WebUI.Input}
              disabledVariant="filled"
              disabled={isFormDisabled}
              value={formik.values.ssnLast4}
              onValueChange={(formattedValues) =>
                formik.setFieldValue('ssnLast4', formattedValues.value)
              }
              onBlur={formik.handleBlur}
            />
          </AccountInputLock>
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      {isFullSSNInquired && (
        <WebUI.FormField
          label={sessionQuery.data.user.currency === 'usd' ? 'SSN' : 'SIN'}
          error={formik.errors.personalIdNumber}
        >
          <WebUI.Input
            name="personalIdNumber"
            disabled={isFormDisabled}
            value={formik.values.personalIdNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      )}

      {!isUserIDPhotoProvided && (
        <div className="flex flex-col gap-2">
          <WebUI.FormField
            label="Photo ID (e.g. Driver's License)"
            error={formik.errors.photoIDFront}
          >
            <WebUI.FileUploader
              accept={{'image/*': []}}
              multiple={false}
              maxFiles={1}
              disabled={isFormDisabled}
              onDropAccepted={([newPhotoIDFront]) =>
                formik.setFieldValue('photoIDFront', newPhotoIDFront ?? null)
              }
            >
              {formik.values.photoIDFront ? (
                <WebUI.ImageFilePreview
                  alt="ID front"
                  width={240}
                  height={240}
                  imageFile={formik.values.photoIDFront}
                  onClear={() => formik.setFieldValue('photoIDFront', null)}
                />
              ) : (
                <>
                  <WebUI.FileUploaderInput name="photoIDFront" />
                  <WebUI.FileUploaderButton>
                    Upload Photo ID (Front)
                  </WebUI.FileUploaderButton>
                </>
              )}
            </WebUI.FileUploader>
          </WebUI.FormField>

          <WebUI.FileUploader
            accept={{'image/*': []}}
            multiple={false}
            maxFiles={1}
            disabled={isFormDisabled}
            onDropAccepted={([newPhotoIDBack]) =>
              formik.setFieldValue('photoIDBack', newPhotoIDBack ?? null)
            }
          >
            {formik.values.photoIDBack ? (
              <WebUI.ImageFilePreview
                alt="ID back"
                width={240}
                height={240}
                imageFile={formik.values.photoIDBack}
                onClear={() => formik.setFieldValue('photoIDBack', null)}
              />
            ) : (
              <>
                <WebUI.FileUploaderInput name="photoIDBack" />
                <WebUI.FileUploaderButton>
                  Upload Photo ID (Back)
                </WebUI.FileUploaderButton>
              </>
            )}
          </WebUI.FileUploader>
        </div>
      )}

      <WebUI.Disclosure visible={formik.dirty || formik.isSubmitting}>
        <WebUI.DisclosureContent>
          <WebUI.Button type="submit" loading={formik.isSubmitting}>
            Save
          </WebUI.Button>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </AccountSettingsContentCard>
  )
}

// MARK: – OrgDetailsForm

const OrgDetailsForm = (props: React.ComponentPropsWithoutRef<'form'>) => {
  const sessionQuery = api.auth.session.useSuspenseQuery()
  const updateUserMutation = useUpdateUserMutation()
  const growlActions = WebUI.useGrowlActions()
  const {createAccountToken} = useStripeTokenizer()

  const isEinMismatched = sessionQuery.data.user.requirement_errors?.some(
    (e) =>
      e.code === 'verification_failed_other' &&
      e.requirement === 'company.tax_id',
  )

  const businessAddress = sessionQuery.data.user.business_address

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      orgName: sessionQuery.data.user.editable
        ? Yup.string().required()
        : Yup.mixed(),
      business_tax_id:
        sessionQuery.data.user.editable &&
        !sessionQuery.data.user.tax_id_provided
          ? Yup.string()
              .required('Required')
              .concat(
                sessionQuery.data.user.currency === 'cad'
                  ? Yup.string().matches(
                      /^\d{9}\s[A-Z]{2}\d{4}$/,
                      'Incorrect format',
                    )
                  : Yup.string(),
              )
          : Yup.mixed(),
    }),
    initialValues: {
      orgName:
        sessionQuery.data.user.profile.uiClientFlags
          ?.underwritingOrganizationName ?? '',
      businessAddress,
      businessTaxId: '',
    },
    onSubmit: async (values) => {
      if (!sessionQuery.data.user.editable) {
        return
      }

      try {
        let accountToken: string | null = null
        if (
          values.businessTaxId ||
          values.businessAddress.city ||
          values.businessAddress.line1 ||
          values.businessAddress.postal_code ||
          values.businessAddress.state
        ) {
          accountToken = await createAccountToken({
            tax_id: values.businessTaxId || undefined,
            address: {
              city: values.businessAddress.city ?? undefined,
              line1: values.businessAddress.line1 ?? undefined,
              postal_code: values.businessAddress.postal_code ?? undefined,
              state: values.businessAddress.state ?? undefined,
            },
          })
        }

        await updateUserMutation.mutateAsync({
          body: {
            business_address: values.businessAddress,
            profile: {
              uiClientFlags: {
                underwritingOrganizationName: values.orgName,
              },
            },
            account_token: accountToken ?? undefined,
          },
        })
        growlActions.show('success', {
          title: 'Success!',
          body: 'Your organization details have been updated.',
        })
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  const isFormDisabled = !!(
    sessionQuery.data.user.display_name &&
    sessionQuery.data.user.tax_id_provided &&
    sessionQuery.data.user.business_address &&
    sessionQuery.data.user.business_address.city &&
    sessionQuery.data.user.business_address.line1 &&
    sessionQuery.data.user.business_address.state &&
    sessionQuery.data.user.business_address.postal_code
  )

  return (
    <AccountSettingsContentCard
      as="form"
      heading="Organization Details"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...props}
    >
      {isEinMismatched && (
        <span className="font-normal text-ds-sm text-error leading-compact">
          Your account has mismatched or missing information. Please make sure
          legal organization name and EIN match{' '}
          <span className="underline">exactly</span> with what is listed on your
          organization's IRS documents.{' '}
          <WebUI.Anchor
            href="https://support.cheddarup.com/hc/en-us/articles/10927889362068"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more here.
          </WebUI.Anchor>
        </span>
      )}
      <WebUI.FormField
        label="Legal Organization Name"
        error={formik.errors.orgName || isEinMismatched}
        suffix={<EINAlert>Include any capitalization</EINAlert>}
      >
        <WebUI.Input
          name="orgName"
          disabled={isFormDisabled}
          value={formik.values.orgName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>

      <WebUI.FormField required label="Organization Address">
        <WebUI.AddressCombobox
          key={sessionQuery.dataUpdatedAt}
          disabled={isFormDisabled}
          defaultAddress={[businessAddress.line1, businessAddress.line2]
            .filter((ac) => !!ac)
            .join(' ')}
          withDetails
          onAddressChange={(location) => {
            const details = location.details
            if (details) {
              const addressComponents = extractAddressComponents(
                details,
                sessionQuery.data.user.currency,
              )
              formik.setFieldValue('businessAddress', addressComponents)
            }
          }}
        />
      </WebUI.FormField>

      <WebUI.FormField
        label="Organization EIN/Tax ID"
        error={formik.errors.businessTaxId || isEinMismatched}
        suffix={<EINAlert>How to avoid a mismatch</EINAlert>}
      >
        <AccountInputLock locked={!!sessionQuery.data.user.tax_id_provided}>
          <WebUI.Input
            name="businessTaxId"
            disabledVariant="filled"
            disabled={isFormDisabled}
            placeholder="Organization EIN/Tax ID"
            value={formik.values.businessTaxId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </AccountInputLock>
      </WebUI.FormField>

      <WebUI.Disclosure visible={formik.dirty || formik.isSubmitting}>
        <WebUI.DisclosureContent>
          <WebUI.Button type="submit" loading={formik.isSubmitting}>
            Save
          </WebUI.Button>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </AccountSettingsContentCard>
  )
}

// MARK: – EINAlert

interface EINAlertProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {}

const EINAlert = ({className, ...restProps}: EINAlertProps) => (
  <WebUI.Alert
    disclosure={
      <WebUI.DialogDisclosure
        className={WebUI.cn('text-ds-xs', className)}
        variant="link"
        {...restProps}
      />
    }
  >
    <WebUI.AlertHeader>Let’s make a match</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text={
        <>
          The combination of the organization name and Employer Identification
          Number (EIN) must exactly match what is listed on the organization’s
          IRS documents (e.g., Letter 147C or SS-4 Confirmation letter),
          including capitalization and punctuation.
          <br />
          <br />
          <WebUI.Text className="font-bold">
            If the EIN is cleared out and requested again, you can assume an
            exact match has not been provided.
          </WebUI.Text>{' '}
          Please reference the documents mentioned above and update for
          verification.
        </>
      }
    />
  </WebUI.Alert>
)

// MARK: – Helpers

function extractAddressComponents(details: any, currency: string) {
  const postalCode = details.address_components.find((ac) =>
    ac.types.includes('postal_code'),
  )?.long_name

  const city = details.address_components.find(
    (ac) => ac.types.includes('locality') && ac.types.includes('political'),
  )?.long_name

  const state = details.address_components.find(
    (ac) =>
      ac.types.includes('administrative_area_level_1') &&
      ac.types.includes('political'),
  )?.short_name

  const canadian = currency !== 'usd'

  return {
    postal_code: postalCode ?? '',
    state: state ?? '',
    city: city ?? '',
    country: canadian ? 'CA' : 'US',
    line1: details.name,
  }
}

export default AccountDetailsPage
