import {z} from '@cheddarup/util'

import {
  paymentItemRefundDataSchema,
  tabItemPaymentSchema,
} from './payment-items'
import {tabItemSchema} from './tab-items'

export const ticketPaymentSchema = tabItemPaymentSchema
  .pick({
    id: true,
    payment_id: true,
    amount: true,
    quantity: true,
    detail: true,
    item_field_views: true,
    wallet_passes: true,
  })
  .extend({
    updated_at: z.string(),
    ticket_number: z.string(),
    payment: z.object({
      created_at: z.string(),
      updated_at: z.string(),
      tab: z.object({
        id: z.number(),
        name: z.string(),
      }),
      tab_member: z.object({
        name: z.string(),
        email: z.string(),
      }),
    }),
    tab_object: tabItemSchema.pick({
      name: true,
      options: true,
    }),
    refund_data: paymentItemRefundDataSchema.optional(),
  })

declare global {
  namespace Api {
    type TicketPayment = z.infer<typeof ticketPaymentSchema>
  }
}
