import {AutoformatRule, createAutoformatPlugin} from '@udecode/plate-autoformat'
import {
  ELEMENT_BLOCKQUOTE,
  createBlockquotePlugin,
} from '@udecode/plate-block-quote'
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  KEYS_HEADING,
  createHeadingPlugin,
} from '@udecode/plate-heading'
import {
  ELEMENT_HR,
  createHorizontalRulePlugin,
} from '@udecode/plate-horizontal-rule'
import {
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_OL,
  ELEMENT_UL,
  createListPlugin,
  toggleList,
  unwrapList,
} from '@udecode/plate-list'
import {ELEMENT_LINK, createLinkPlugin} from '@udecode/plate-link'
import {
  ELEMENT_PARAGRAPH,
  createParagraphPlugin,
} from '@udecode/plate-paragraph'
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  createBoldPlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
} from '@udecode/plate-basic-marks'
import {
  createDeserializeMdPlugin,
  deserializeMd,
  serializeMd,
} from '@udecode/plate-serializer-md'
import {
  createExitBreakPlugin,
  createSoftBreakPlugin,
} from '@udecode/plate-break'
import {createResetNodePlugin} from '@udecode/plate-reset-node'
import {createTrailingBlockPlugin} from '@udecode/plate-trailing-block'
import {createSelectOnBackspacePlugin} from '@udecode/plate-select'
import {
  CreateHOCOptions,
  ELEMENT_DEFAULT,
  PlateEditor,
  RenderAfterEditable,
  TElement,
  Value,
  collapseSelection,
  createPlateEditor,
  createPlugins,
  findNode,
  getParentNode,
  insertEmptyElement,
  insertNodes,
  isBlock,
  isBlockAboveEmpty,
  isElement,
  isSelectionAtBlockStart,
  setNodes,
  toggleNodeType,
  PlaceholderProps,
  Plate,
  PlateContent,
  PlateContentProps,
  PlateProps,
  focusEditor,
  useEditorRef,
} from '@udecode/plate-common'
import {Editor, Transforms} from 'slate'
import {useLiveRef} from '@cheddarup/react-util'
import React, {useImperativeHandle, useMemo} from 'react'

import {PhosphorIcon} from '../../icons'
import {HStack, HStackProps, VStack} from '../Stack'
import {Separator} from '../Separator'
import {
  PlateLinkFloatingToolbar,
  PlateLinkToolbarButton,
  PlateListToolbarButton,
  PlateMarkToolbarButton,
  PlateToolbarItem,
  createPlateUI,
} from './PlateElements'
import {Toolbar} from '../Toolbar'
import {cn, getPossibleLinks} from '../../utils'

export {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_DEFAULT,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
}

export interface RichTextEditorInstance extends PlateEditor {
  setMarkdownValue: (markdownValue: string) => void
}

export interface RichTextEditorProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>,
    Pick<
      RichTextEditorInnerProps,
      'name' | 'disabled' | 'readOnly' | 'autoFocus' | 'placeholder'
    > {
  plugins?: PlateProps['plugins']
  placeholders?: Array<CreateHOCOptions<PlaceholderProps>>
  footer?: React.ReactNode
  initialMarkdownValue?: string
  onMarkdownValueChange?: (newMarkdown: string) => void
}

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE],
  defaultType: ELEMENT_PARAGRAPH,
}

export const RichTextEditor = React.forwardRef<
  RichTextEditorInstance,
  RichTextEditorProps
>(
  (
    {
      'aria-invalid': ariaInvalid,
      disabled,
      readOnly,
      placeholder,
      plugins: pluginsProp = [],
      placeholders = [],
      initialMarkdownValue,
      onMarkdownValueChange,
      name,
      id,
      className,
      children,
      footer,
      ...restProps
    },
    forwardedRef,
  ) => {
    const placeholdersRef = useLiveRef(placeholders)

    const plugins = useMemo(
      () =>
        createPlugins(
          [
            // elements
            createParagraphPlugin(),
            createBlockquotePlugin(),
            createHeadingPlugin(),
            createLinkPlugin({
              options: {
                forceSubmit: true,
                getUrlHref: (url) =>
                  getPossibleLinks(url).find((l) => l.isLink)?.href,
                defaultLinkAttributes: {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
              },
              renderAfterEditable:
                PlateLinkFloatingToolbar as RenderAfterEditable,
            }),
            createListPlugin(),
            createHorizontalRulePlugin(),

            // marks
            createBoldPlugin(),
            createItalicPlugin(),
            createStrikethroughPlugin(),
            createUnderlinePlugin(),

            // serialization
            createDeserializeMdPlugin(),

            // utils
            createTrailingBlockPlugin({options: {type: ELEMENT_PARAGRAPH}}),
            createResetNodePlugin({
              options: {
                rules: [
                  {
                    ...resetBlockTypesCommonRule,
                    hotkey: 'Enter',
                    predicate: isBlockAboveEmpty,
                  },
                  {
                    ...resetBlockTypesCommonRule,
                    hotkey: 'Backspace',
                    predicate: isSelectionAtBlockStart,
                  },
                ],
              },
            }),
            createSoftBreakPlugin({
              options: {
                rules: [
                  {hotkey: 'shift+enter'},
                  {
                    hotkey: 'enter',
                    query: {
                      allow: [ELEMENT_BLOCKQUOTE],
                    },
                  },
                ],
              },
            }),
            createExitBreakPlugin({
              options: {
                rules: [
                  {
                    hotkey: 'mod+enter',
                  },
                  {
                    hotkey: 'mod+shift+enter',
                    before: true,
                  },
                  {
                    hotkey: 'enter',
                    query: {
                      start: true,
                      end: true,
                      allow: KEYS_HEADING,
                    },
                    relative: true,
                    level: 1,
                  },
                ],
              },
            }),
            createSelectOnBackspacePlugin({
              options: {query: {allow: [ELEMENT_HR]}},
            }),
            createAutoformatPlugin({
              options: {
                rules: [
                  ...autoformatBlocks,
                  ...autoformatLists,
                  ...autoformatMarks,
                ],
                enableUndoOnDelete: true,
              },
            }),

            ...pluginsProp,
          ],
          {
            components: createPlateUI(undefined, {
              placeholders: placeholdersRef.current,
            }),
          },
        ),
      [pluginsProp],
    )

    const plateEditor = useMemo(
      () => createPlateEditor({id, plugins}),
      [id, plugins],
    )

    const initialValue = useMemo(() => {
      const res: Value = initialMarkdownValue
        ? deserializeMd(plateEditor, initialMarkdownValue)
        : undefined

      if (!res || res.length === 0) {
        return undefined
      }

      return res
    }, [plateEditor, initialMarkdownValue])

    return (
      <VStack
        aria-disabled={disabled}
        aria-invalid={ariaInvalid}
        data-read-only={readOnly}
        id={id}
        className={cn(
          'min-h-[120px] rounded font-body font-light shadow-[0_0_0_1px_theme(colors.natural.70)]',
          'aria-invalid:shadow-[0_0_0_1px_theme(colors.orange.50)]',
          'aria-disabled:pointer-events-none aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
          'focus-within:shadow-[0_0_0_1px_theme(colors.teal.50)]',
          className,
        )}
        {...restProps}
      >
        <Plate
          id={id}
          editor={plateEditor}
          initialValue={initialValue}
          normalizeInitialValue
          onChange={(value) =>
            onMarkdownValueChange?.(
              normalizeMarkdownLineBreaks(
                serializeMd({...plateEditor, children: value}),
              ),
            )
          }
        >
          {children}
          <RichTextEditorInner
            ref={forwardedRef}
            aria-invalid={ariaInvalid}
            id={id}
            name={name}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
          />
          {footer}
        </Plate>
      </VStack>
    )
  },
)

// MARK: – RichTextEditorInner

interface RichTextEditorInnerProps extends PlateContentProps {}

const RichTextEditorInner = React.forwardRef<
  RichTextEditorInstance,
  RichTextEditorInnerProps
>(({disabled, readOnly, id, className, ...restProps}, forwardedRef) => {
  const editor = useEditorRef(id)

  useImperativeHandle(
    forwardedRef,
    () => ({
      setMarkdownValue: (markdownValue: string) => {
        try {
          if (editor) {
            Transforms.select(editor as any, [])
            Transforms.delete(editor as any, {
              at: {
                anchor: Editor.start(editor as any, []),
                focus: Editor.end(editor as any, []),
              },
            })
            Transforms.insertFragment(
              editor as any,
              deserializeMd(editor, markdownValue),
            )
          }
        } catch {
          // noop
        }
      },
      ...(editor as any),
    }),
    [editor],
  )

  return (
    <PlateContent
      aria-disabled={disabled}
      id={id}
      className={cn(
        'RichTextEditor',
        'grow px-2 py-2',
        'transition-colors duration-100 ease-in-out',
        'disabled:pointer-events-none aria-disabled:pointer-events-none',
        'hover:[&:not(:focus):not([aria-invalid=true]):not(:disabled):not([aria-disabled=true])]:bg-inputHoverBackground',
        '[&_[data-slate-placeholder]]:!w-[calc(100%-theme(spacing.2)*2)] [&_[data-slate-placeholder]]:!max-w-[calc(100%-theme(spacing.2)*2)] [&_[data-slate-placeholder]]:!py-2 [&_[data-slate-placeholder]]:!font-light [&_[data-slate-placeholder]]:!not-italic [&_[data-slate-placeholder]]:!text-inputPlaceholder [&_[data-slate-placeholder]]:!opacity-100',
        'focus-visible:outline-none',
        '*:before:!text-inputPlaceholder *:before:!opacity-100',
        '[&_ol]:!ml-[var(--ifm-list-left-padding)] [&_ol]:![padding-inline-start:24px]',
        '[&_ul]:!ml-[var(--ifm-list-left-padding)] [&_ul]:![padding-inline-start:24px] [&_ul]:[list-style-type:initial]',
        className,
      )}
      disabled={disabled}
      readOnly={readOnly || disabled}
      autoFocus={false}
      spellCheck={false}
      {...restProps}
    />
  )
})

// MARK: – RichTextEditorToolbar

export interface RichTextEditorToolbarProps
  extends HStackProps,
    React.ComponentPropsWithoutRef<'div'> {
  rootClassName?: string
  pick?: Array<
    | typeof ELEMENT_H1
    | typeof MARK_BOLD
    | typeof MARK_ITALIC
    | typeof ELEMENT_LINK
    | typeof ELEMENT_OL
    | typeof ELEMENT_UL
    | typeof ELEMENT_HR
  >
}

export const RichTextEditorToolbar = React.forwardRef<
  HTMLDivElement,
  RichTextEditorToolbarProps
>(({rootClassName, pick, className, children, ...restProps}, forwardedRef) => {
  const editor = useEditorRef()

  const currentBlockNodeEntry = findNode<TElement>(editor, {
    match: (n) => isBlock(editor, n),
  })

  return (
    <Toolbar className={cn('RichTextEditorToolbar', rootClassName)}>
      <VStack
        ref={forwardedRef}
        className={cn(
          'RichTextEditorToolbar-content',
          'grow justify-start gap-3 border-b p-3 sm:flex-row sm:justify-between',
          '[&_.slate-ToolbarButton-active]:!text-teal-50 [&_.slate-ToolbarButton:hover]:!text-teal-50',
          className,
        )}
        {...restProps}
      >
        <HStack className="RichTextEditorToolbar-contentMain flex-[2]">
          {(!pick || pick.includes(ELEMENT_H1)) && (
            <HStack className="flex-[1]">
              <PlateToolbarItem
                pressed={currentBlockNodeEntry?.[0].type === ELEMENT_H1}
                onClick={() => {
                  toggleNodeType(editor, {activeType: ELEMENT_H1})
                  collapseSelection(editor)
                  focusEditor(editor)
                }}
              >
                <PhosphorIcon icon="text-h" />
              </PlateToolbarItem>
            </HStack>
          )}

          <HStack className="RichTextEditorToolbar-decorators flex-[1] gap-3">
            <Separator orientation="vertical" variant="primary" />
            {(!pick || pick.includes(MARK_BOLD)) && (
              <PlateMarkToolbarButton nodeType={MARK_BOLD}>
                <PhosphorIcon icon="text-bolder" />
              </PlateMarkToolbarButton>
            )}
            {(!pick || pick.includes(MARK_ITALIC)) && (
              <PlateMarkToolbarButton nodeType={MARK_ITALIC}>
                <PhosphorIcon icon="text-italic" />
              </PlateMarkToolbarButton>
            )}
            {(!pick || pick.includes(ELEMENT_LINK)) && (
              <PlateLinkToolbarButton />
            )}
            <Separator orientation="vertical" variant="primary" />
            {(!pick || pick.includes(ELEMENT_OL)) && (
              <PlateListToolbarButton nodeType={ELEMENT_OL} />
            )}
            {(!pick || pick.includes(ELEMENT_UL)) && (
              <PlateListToolbarButton nodeType={ELEMENT_UL} />
            )}
            {(!pick || pick.includes(ELEMENT_HR)) && (
              <PlateToolbarItem
                onClick={() => {
                  insertEmptyElement(editor, ELEMENT_HR, {
                    select: true,
                    nextBlock: true,
                  })
                  focusEditor(editor)
                }}
              >
                <PhosphorIcon icon="arrows-out-line-vertical" />
              </PlateToolbarItem>
            )}
          </HStack>
        </HStack>

        <HStack className="RichTextEditorToolbar-extra flex-[1] xs:justify-start justify-end gap-3">
          {children}
        </HStack>
      </VStack>
    </Toolbar>
  )
})

// MARK: – Formatting helpers

function preFormat(editor: PlateEditor) {
  return unwrapList(editor)
}

function format(editor: PlateEditor, customFormatting: () => void) {
  if (editor.selection) {
    const parentEntry = getParentNode(editor, editor.selection)
    if (!parentEntry) {
      return
    }
    const [node] = parentEntry
    if (isElement(node)) {
      customFormatting()
    }
  }
}

function formatList(editor: PlateEditor, elementType: string) {
  return format(editor as PlateEditor, () =>
    toggleList(editor, {
      type: elementType,
    }),
  )
}

const autoformatBlocks: AutoformatRule[] = [
  {
    mode: 'block',
    type: ELEMENT_H1,
    match: '# ',
    preFormat,
  },
  {
    mode: 'block',
    type: ELEMENT_H2,
    match: '## ',
    preFormat,
  },
  {
    mode: 'block',
    type: ELEMENT_H3,
    match: '### ',
    preFormat,
  },
  {
    mode: 'block',
    type: ELEMENT_H4,
    match: '#### ',
    preFormat,
  },
  {
    mode: 'block',
    type: ELEMENT_H5,
    match: '##### ',
    preFormat,
  },
  {
    mode: 'block',
    type: ELEMENT_H6,
    match: '###### ',
    preFormat,
  },
  {
    mode: 'block',
    type: ELEMENT_BLOCKQUOTE,
    match: '> ',
    preFormat,
  },
  {
    mode: 'block',
    type: ELEMENT_HR,
    match: ['---', '—-', '___ '],
    preFormat,
    format: (editor) => {
      setNodes(editor, {type: ELEMENT_HR})
      insertNodes(editor, {type: ELEMENT_DEFAULT, children: [{text: ''}]})
    },
  },
]

const autoformatLists: AutoformatRule[] = [
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['* ', '- '],
    preFormat,
    format: (editor) => formatList(editor, ELEMENT_UL),
  },
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['1. ', '1) '],
    preFormat,
    format: (editor) => formatList(editor, ELEMENT_OL),
  },
]

const autoformatMarks: AutoformatRule[] = [
  {
    mode: 'mark',
    type: [MARK_BOLD, MARK_ITALIC],
    match: '***',
  },
  {
    mode: 'mark',
    type: MARK_BOLD,
    match: '**',
  },
  {
    mode: 'mark',
    type: MARK_ITALIC,
    match: '*',
  },
  {
    mode: 'mark',
    type: MARK_ITALIC,
    match: '_',
  },
]

// MARK: – Helpers

function normalizeMarkdownLineBreaks(md: string) {
  return md.replace(/\r\n/g, '\n').replace(/\r/g, '\n')
}
