import {z} from '@cheddarup/util'

import {tabAmountTypeSchema, tabItemSchema} from './tab-items'

export const categorySchema = z.object({
  id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  tab_id: z.number(),
  name: z.string(),
  amount: z.number(),
  description: z.string().nullable(),
  amount_type: tabAmountTypeSchema,
  allow_quantity: z.boolean(),
  deleted_at: z.string().nullish(),
  required: z.boolean(),
  position: z.number(),
  items: tabItemSchema.array().nullish(),
  anchor: z.boolean(),
  options: z.object({
    subcategories: z
      .array(z.object({name: z.string(), uuid: z.string()}))
      .nullish(),
  }),
})

declare global {
  namespace Api {
    type Category = z.infer<typeof categorySchema>
  }
}
