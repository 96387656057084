import {z} from '@cheddarup/util'

import {tabObjectFieldViewSchema} from './field-views'

export const basePaymentItemSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  payment_id: z.number(),
  tab_object_id: z.number(),
  amount: z.number(),
  quantity: z.number(),
  total: z.number(),
  amount_after_refund: z.number().optional(),
  item_field_views: tabObjectFieldViewSchema.array(),
})
