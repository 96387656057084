import queryString, {ParsedQuery} from 'query-string'

import {objectFromObject} from './object-utils'
import {getTokens, replaceTokens} from './string-utils'
import type {ExtractParams} from './type-utils'

// MARK: – Search params

type AnyParsedQuery = ParsedQuery<string | boolean | number>
type AnyParsedQueryKey = keyof AnyParsedQuery
type AnyParsedQueryValue = AnyParsedQuery[AnyParsedQueryKey]

export type Predicate =
  | AnyParsedQueryKey[]
  | ((key: AnyParsedQueryKey, value: AnyParsedQueryValue) => boolean)

export function pickSearchParams(search: string, predicate: Predicate) {
  const searchObj = queryString.parse(search)

  const filter = (key: AnyParsedQueryKey, value: AnyParsedQueryValue) =>
    typeof predicate === 'function'
      ? predicate(key, value)
        ? value
        : undefined
      : predicate.includes(key)
        ? value
        : undefined

  const filteredSearchObj = objectFromObject(searchObj, filter)

  return queryString.stringify(filteredSearchObj)
}

export function excludeSearchParams(search: string, predicate: Predicate) {
  return pickSearchParams(
    search,
    typeof predicate === 'function'
      ? (key, value) => !predicate(key, value)
      : (key) => !predicate.includes(key),
  )
}

export function mergeSearchParams(
  search: string,
  _newSearchParams:
    | Record<AnyParsedQueryKey, AnyParsedQueryValue | undefined>
    | string,
) {
  let newSearchParams = _newSearchParams
  if (typeof newSearchParams === 'string') {
    newSearchParams = queryString.parse(newSearchParams)
  }
  const searchObj = queryString.parse(search)

  return queryString.stringify({
    ...searchObj,
    ...newSearchParams,
  })
}

// MARK: – Path tokens

const pathParamRegex = /:[^/]+/g

export function replacePathTokens<T extends string>(
  path: T,
  pathParams: ExtractParams<T>,
) {
  return replaceTokens(path, pathParams, pathParamRegex, (match) =>
    match.slice(1),
  )
}

export function getPathTokens<T extends string>(path: T) {
  return getTokens(path, pathParamRegex)
}

export function normalizeUrl(url: string): string {
  let _url = url
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    _url = `https://${url}`
  }
  return _url
}
