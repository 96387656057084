import {z} from '@cheddarup/util'

import {fieldSetSchema} from './fields'

export const tabFormSchema = z.object({
  id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  tab_id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  deleted_at: z.string().nullish(),
  required: z.boolean(),
  position: z.number(),
  anchor: z.string().nullish(),
  parent_id: z.number().nullish(),
  hidden: z.boolean().nullish(),
  respondents: z.number().nullish(),
  options: z.object({
    isWaiver: z.boolean(),
    fieldSets: fieldSetSchema.array().nullish(),
  }),
  is_pro: z.boolean(),
  is_team: z.boolean(),
  requires_pro: z.boolean(),
  requires_team: z.boolean(),
})

declare global {
  namespace Api {
    type TabForm = z.infer<typeof tabFormSchema>
  }
}
