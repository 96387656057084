import {Navigate, useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {api, useDeleteAlertMutation} from '@cheddarup/api-client'
import config from 'src/config'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'

const AlertDetailsPage = () => {
  const navigate = useNavigate()
  const {id: idParam} = useParams()
  const alertQuery = api.alerts.list.useQuery(undefined, {
    select: (alerts) => alerts.find((a) => a.id === Number(idParam)),
  })
  const deleteAlertMutation = useDeleteAlertMutation()

  const handleDeleteAlert = () => {
    if (idParam != null) {
      deleteAlertMutation.mutate({
        pathParams: {
          alertId: idParam,
        },
      })
    }
  }

  if (alertQuery.isError) {
    return <Navigate to=".." />
  }
  if (!alertQuery.data) {
    return null
  }

  return (
    <WebUI.Alert
      aria-label="Alert details"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.AlertHeader>
        {alertQuery.data.detail.header ??
          alertCategoryToTitleMap[alertQuery.data?.category]}
      </WebUI.AlertHeader>
      <WebUI.VStack className="gap-4 p-6 text-ds-sm">
        {
          {
            need_more_information: <NeedMoreInformation />,
            payments_disabled: <PaymentsDisabled />,
            taxable_threshold_close: (
              <TaxableThresholdClose onDeleteAlert={handleDeleteAlert} />
            ),
            new_bank_added: <NewBankAdded onDeleteAlert={handleDeleteAlert} />,
            subscription_payment_failed: <SubscriptionPaymentFailed />,
            dispute_opened: <DisputeOpened alert={alertQuery.data} />,
            dispute_won: (
              <DisputeWon
                alert={alertQuery.data}
                onDeleteAlert={handleDeleteAlert}
              />
            ),
            dispute_lost: (
              <DisputeLost
                alert={alertQuery.data}
                onDeleteAlert={handleDeleteAlert}
              />
            ),
            stripe_terms_changed: (
              <StripeAgreementUpdated onDeleteAlert={handleDeleteAlert} />
            ),
            two_factor_required: (
              <TwoFactorRequired onDeleteAlert={handleDeleteAlert} />
            ),
            misc: (
              <Misc alert={alertQuery.data} onDeleteAlert={handleDeleteAlert} />
            ),
            secondary_email: (
              <AddSecondaryEmail onDeleteAlert={handleDeleteAlert} />
            ),
            dormant_account: (
              <AccountActionRequired
                onDeleteAlert={handleDeleteAlert}
                status="dormant"
              />
            ),
            inactive_account: (
              <AccountActionRequired
                onDeleteAlert={handleDeleteAlert}
                status="inactive"
              />
            ),
          }[alertQuery.data.category]
        }
      </WebUI.VStack>
    </WebUI.Alert>
  )
}

// MARK: – Content views

const changeAccountDetailsMailtoHref = `mailto:${config.supportEmail}?subject=Change To My Cheddar Up Account&body=Please make the following changes to my account details`

const NeedMoreInformation = () => (
  <>
    <WebUI.VStack className="gap-3">
      <span>
        Looks like you need to finish your account set up. Some required fields
        are still missing, or information previously entered may have been
        rejected.
      </span>
      <span className="font-bold">
        If you do not complete this information in a timely manner, you will not
        be able to receive payments and/or you may experience a delay in
        withdrawing funds.
      </span>
    </WebUI.VStack>
    <LinkButton
      className="self-start"
      variant="primary"
      to="../../../my-account/details"
    >
      Finish Setup
    </LinkButton>
  </>
)

const NewBankAdded = ({onDeleteAlert}: {onDeleteAlert: () => void}) => (
  <>
    <span>
      This is a notification that a bank account has been added to your{' '}
      <Link variant="primary" to="../../withdrawal-settings">
        Withdrawal Settings
      </Link>
      . If you did not update this information, please contact{' '}
      <WebUI.Anchor
        target="_blank"
        rel="noopener noreferrer"
        href={changeAccountDetailsMailtoHref}
      >
        Cheddar Up Support{' '}
      </WebUI.Anchor>{' '}
      immediately.
    </span>
    <WebUI.Button
      className="self-start"
      variant="secondary"
      onClick={onDeleteAlert}
    >
      Clear Alert
    </WebUI.Button>
  </>
)

const PaymentsDisabled = () => (
  <>
    <WebUI.VStack className="gap-3">
      <span>
        Looks like you need to finish your account set up. Some required fields
        are still missing, or information previously entered may have been
        rejected.
      </span>
      <span className="font-bold">
        If you do not complete this information in a timely manner, you will not
        be able to receive payments and/or you may experience a delay in
        withdrawing funds.
      </span>
    </WebUI.VStack>
    <LinkButton
      className="self-start"
      variant="primary"
      to="../../../my-account/details"
    >
      Finish Setup
    </LinkButton>
  </>
)

const SubscriptionPaymentFailed = () => (
  <>
    <WebUI.VStack className="gap-3">
      <span>
        The payment for your Cheddar Up subscription failed.{' '}
        <Link variant="primary" to="../../plan-billing">
          Update your billing information{' '}
        </Link>{' '}
        or your account will be automatically downgraded to our free plan after
        one or more attempts to process payment for this invoice.
      </span>
      <span className="font-bold">
        Any collections using upgraded features will be deleted upon downgrade.
      </span>
    </WebUI.VStack>
    <LinkButton className="self-start" variant="primary" to="../plan-billing">
      Update Billing Information
    </LinkButton>
  </>
)

const TaxableThresholdClose = ({
  onDeleteAlert,
}: {
  onDeleteAlert: () => void
}) => (
  <>
    <WebUI.VStack className="gap-3">
      <div>
        A head's up that you're close to hitting an IRS collecting threshold and
        that means that Cheddar Up may need to issue you a 1099-K.
      </div>
      <div>
        According to IRS guidance, a payment platform like Cheddar Up is
        required to send a 1099-K (prior to tax deadlines) to any user on our
        platform who collects more than $20,000 AND 200 transactions during the
        course of the year (with a copy sent to the IRS).
      </div>
      <div>
        If you have any questions, you may consider reaching out to your
        accountant. In addition, please feel free to contact{' '}
        <WebUI.Anchor
          target="_blank"
          rel="noopener noreferrer"
          href={changeAccountDetailsMailtoHref}
        >
          Cheddar Up Support{' '}
        </WebUI.Anchor>{' '}
        for more information.
      </div>
    </WebUI.VStack>
    <WebUI.Button
      className="self-start"
      variant="secondary"
      onClick={onDeleteAlert}
    >
      Clear Alert
    </WebUI.Button>
  </>
)

// MARK: – Content dispute views

const DisputeOpened = ({alert}: {alert: Api.Alert}) => (
  <WebUI.VStack className="gap-3">
    <DisputeHeader alert={alert} />
    <span>
      A dispute occurs when a cardholder / customer questions a payment on their
      credit card statement.The card issuer then creates a formal dispute, which
      immediately reverses the payment.The payment amount, along with a separate
      $15 dispute fee, levied by the card network, has been deducted from your
      account balance.{' '}
    </span>
    <span>
      There is a dispute resolution process through which we can respond and
      submit evidence to make your case that the payment was valid.If the
      dispute is found in your favor, the disputed amount and fee is returned
      back to you.If a dispute is upheld, the cardholder 's payment remains
      refunded.The process of resolution typically takes 60 - 90 days.{' '}
    </span>
    <span>
      We have reached out to your payer to clarify the reason for their dispute
      and to determine if it was disputed in error.To begin the dispute
      resolution process, we need to provide evidence to the payer 's card
      company illustrating that this payment was legitimate and that goods /
      services were indeed received by the payer.{' '}
    </span>
  </WebUI.VStack>
)

const DisputeWon = ({
  alert,
  onDeleteAlert,
}: {
  alert: Api.Alert
  onDeleteAlert: () => void
}) => (
  <>
    <WebUI.VStack className="gap-3">
      <DisputeHeader alert={alert} />
      <span>
        <span className="font-bold">We have good news for you!</span> The
        dispute on the above payment was successfully resolved in your favor by
        your customer's bank or credit card company.
      </span>
      <span>
        This means that the full amount and the $15 dispute fee will be refunded
        to your account in the next few days. There is no further action
        required, however, if you have any questions, please don't hesitate to
        contact{' '}
        <WebUI.Anchor
          target="_blank"
          rel="noopener noreferrer"
          href={changeAccountDetailsMailtoHref}
        >
          Cheddar Up Support{' '}
        </WebUI.Anchor>
        .
      </span>
    </WebUI.VStack>
    <WebUI.Button
      className="self-start"
      variant="secondary"
      onClick={onDeleteAlert}
    >
      Clear Alert
    </WebUI.Button>
  </>
)

const DisputeLost = ({
  alert,
  onDeleteAlert,
}: {
  alert: Api.Alert
  onDeleteAlert: () => void
}) => (
  <>
    <WebUI.VStack className="gap-3">
      <DisputeHeader alert={alert} />
      <span className="mt-3">
        Unfortunately, it looks like the dispute below was not resolved in your
        account's favor.
      </span>
      <span>
        The funds have been permanently returned to the payer and the disputed
        payment fee of $15 will remain deducted from your Cheddar Up account.
      </span>
      <span>
        Disputes are decided by your customer's bank, according to the bank's
        process. The steps Cheddar Up follows to respond to the dispute and
        submit evidence are rigidly defined, but the decision made by the bank
        can be affected by a number of factors. Often the decision on who wins a
        dispute comes down to a judgement call by the bank. Cheddar Up has no
        way to affect this decision beyond submitting evidence on your behalf.
      </span>
      <span>
        Please feel free to contact{' '}
        <WebUI.Anchor
          target="_blank"
          rel="noopener noreferrer"
          href={changeAccountDetailsMailtoHref}
        >
          Cheddar Up Support{' '}
        </WebUI.Anchor>{' '}
        if you have any questions.
      </span>
    </WebUI.VStack>
    <WebUI.Button
      className="self-start"
      variant="secondary"
      onClick={onDeleteAlert}
    >
      Clear Alert
    </WebUI.Button>
  </>
)

const DisputeHeader = ({alert}: {alert: Api.Alert}) => {
  const {data: payment} = api.tabPayments.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: alert.reference.tab_id!,
        // biome-ignore lint/style/noNonNullAssertion:
        paymentId: alert.detail.referenceId!,
      },
    },
    {
      enabled: !!alert.detail.referenceId,
    },
  )
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: alert.reference.tab_id!,
    },
  })

  return (
    <>
      <div>
        <span className="font-bold">Collection:</span> {collection?.name ?? ''}
        <br />
        <span className="font-bold">Payment Date:</span>{' '}
        {Util.formatDateAs(payment?.created_at ?? '', 'date_compact')}
        <br />
        <span className="font-bold">Payer:</span>{' '}
        {payment?.tab_member.name ?? ''}
        <br />
        <span className="font-bold">Amount:</span> ${payment?.total ?? 0}
        <br />
      </div>
    </>
  )
}

const StripeAgreementUpdated = ({
  onDeleteAlert,
}: {
  onDeleteAlert: () => void
}) => (
  <>
    <span>
      Stripe is updating its legal terms. You can read the Stripe Connected
      Account Agreement (incorporating the Stripe Services Agreement) and
      related terms{' '}
      <WebUI.Anchor
        rel="noopener noreferrer"
        href="https://stripe.com/legal/connect-account"
        target="_blank"
      >
        here
      </WebUI.Anchor>
      . The updates to the SSA will take effect on February 24, 2023. No action
      is needed from you, but by continuing to use Stripe’s services on or after
      that date, you are agreeing to the updated SSA. You can learn more about
      these updates on Stripe’s FAQ page{' '}
      <WebUI.Anchor
        rel="noopener noreferrer"
        href="https://support.stripe.com/questions/stripe-user-terms-update-17-november-2022"
        target="_blank"
      >
        here
      </WebUI.Anchor>
      .
    </span>
    <WebUI.Button
      className="self-start"
      variant="secondary"
      onClick={onDeleteAlert}
    >
      Clear Alert
    </WebUI.Button>
  </>
)

const TwoFactorRequired: React.FC<{onDeleteAlert: () => void}> = ({
  onDeleteAlert,
}) => (
  <>
    <span>
      Two-factor authentication is not currently set on your account, which can
      put your account balance at risk. Please enable two-factor authentication
      now.
    </span>
    <LinkButton
      className="self-start"
      variant="primary"
      to="../../security"
      onClick={() => onDeleteAlert()}
    >
      Set Two-Factor Authentication
    </LinkButton>
  </>
)

const AddSecondaryEmail: React.FC<{onDeleteAlert: () => void}> = ({
  onDeleteAlert,
}) => (
  <>
    <span>
      Adding a secondary email address can help keep your account secure by
      ensuring you don't miss any important alerts related to key account
      control actions taken on your account.
    </span>
    <LinkButton
      className="self-start"
      variant="primary"
      to="../../security"
      onClick={() => onDeleteAlert()}
    >
      Add a Secondary Email
    </LinkButton>
  </>
)

const AccountActionRequired: React.FC<{
  onDeleteAlert: () => void
  status: string
}> = ({onDeleteAlert, status}) => (
  <>
    <span>
      Your Cheddar Up account has not been accessed in over{' '}
      {status === 'dormant' ? 24 : 12} months, it is now considered{' '}
      {Util.capitalize(status)}. This puts your funds at risk of being deemed
      unclaimed property, which can result in your balance eventually being
      turned over to the state if not claimed.
    </span>
    <span>
      To avoid this, please withdraw your funds to a bank account of your choice
      as soon as possible.
    </span>
    <WebUI.Button
      className="self-start"
      variant="secondary"
      onClick={onDeleteAlert}
    >
      Clear Alert
    </WebUI.Button>
  </>
)

const Misc: React.FC<{alert: Api.Alert; onDeleteAlert: () => void}> = ({
  alert,
  onDeleteAlert,
}) => (
  <>
    <WebUI.MarkdownParagraph
      className="[&_a]:!text-tint [&_ul:first-child]:!mt-0 [&_ul:last-child]:!mb-0 [&_ul:not(:only-child)]:!my-1"
      markdown={alert.detail.body ?? ''}
    />
    <WebUI.Button
      className="self-start"
      variant={alert.detail.buttonText ? 'default' : 'secondary'}
      onClick={onDeleteAlert}
    >
      {alert.detail.buttonText ?? 'Clear Alert'}
    </WebUI.Button>
  </>
)

// MARK: – Helpers

export const alertCategoryToTitleMap = {
  need_more_information: 'We need more information',
  dispute_opened: 'Payment disputed',
  dispute_lost: 'Dispute has been lost',
  payments_disabled: 'Payments disabled',
  taxable_threshold_close: 'IRS 1099-K: Heads Up',
  new_bank_added: 'New bank account added',
  stripe_terms_changed: 'Update to Stripe Services Agreement',
  subscription_payment_failed: 'Subscription payment failed',
  dispute_won: 'Dispute has been won',
  two_factor_required: 'High Risk: Two-factor not enabled',
  secondary_email: 'Add a secondary email address',
  dormant_account: 'Action Required: Dormant Account',
  inactive_account: 'Action Required: Inactive Account',
  misc: '',
}

export default AlertDetailsPage
