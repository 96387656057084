import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import React, {useMemo, useRef} from 'react'
import ReactDOM from 'react-dom/server'
import {useUpdateTabMutation} from '@cheddarup/api-client'
import config from 'src/config'
import {LinkButton} from 'src/components/LinkButton'
import CheddarUpPay from 'src/images/CheddarUpPay.svg'
import {ShareQRCodeLinkModal} from 'src/components/ShareQRCodeLinkModal'
import {shareEventTracking} from 'src/helpers/analytics'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import FacebookLogo from 'src/images/facebook-logo.svg'

export interface ShareLinkPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ShareLinkPanel = ({
  collection,
  className,
  ...restProps
}: ShareLinkPanelProps) => {
  const growlActions = WebUI.useGrowlActions()

  const link =
    collection.status === 'draft'
      ? null
      : config.helpers.shareUrl(collection.slug)

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-4 p-7', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <SharePanelHeading iconName="link">Share with a link</SharePanelHeading>

      <WebUI.VStack className="gap-2">
        <WebUI.Text className="font-light">
          Copy and share your collection URL or choose another sharing method
          below.{' '}
          <WebUI.Anchor
            href={config.links.shareCollection}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </WebUI.Anchor>
          .
        </WebUI.Text>
        <WebUI.VStack className="max-w-screen-md gap-2 sm:flex-row">
          {link != null && (
            <WebUI.Input
              className="grow"
              readOnly
              size="compact"
              value={link}
            />
          )}
          <WebUI.HStack className="gap-2">
            <WebUI.Button
              disabled={!link}
              onClick={() => {
                WebUI.copyToClipboard(link ?? '')
                shareEventTracking(collection, 'link', 'link')
                growlActions.show('success', {
                  title: 'Success',
                  body: 'Link copied',
                })
              }}
            >
              Copy
            </WebUI.Button>
            <WebUI.IconButton
              className="h-auto w-auto p-0"
              size="default_alt"
              variant="text"
              disabled={!link}
              as={WebUI.Anchor}
              rel="noopener noreferrer"
              target="_blank"
              href={
                link
                  ? `https://www.facebook.com/sharer/sharer.php?u=${link}`
                  : ''
              }
              onClick={() =>
                shareEventTracking(collection, 'facebook', 'collection link')
              }
            >
              <WebUI.Image
                width={36}
                height={36}
                alt="Facebook logo"
                src={FacebookLogo}
              />
            </WebUI.IconButton>
          </WebUI.HStack>
        </WebUI.VStack>
      </WebUI.VStack>
      <EditLinkPrompt
        collection={collection}
        disclosure={
          <WebUI.DialogDisclosure
            className="text-ds-sm"
            variant="link"
            disabled={!link}
          >
            Edit Link
          </WebUI.DialogDisclosure>
        }
      />
    </WebUI.VStack>
  )
}

// MARK: – EditLinkPrompt

interface EditLinkPromptProps extends WebUI.PromptProps {
  collection: Api.Tab
}

const EditLinkPrompt = React.forwardRef<
  WebUI.DialogInstance,
  EditLinkPromptProps
>(({collection, ...restProps}, forwardedRef) => {
  const ownRef = useRef<WebUI.DialogInstance>(null)
  const ref = useForkRef(ownRef, forwardedRef)
  const growlActions = WebUI.useGrowlActions()
  const updateTabMutation = useUpdateTabMutation()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      slug: Yup.string()
        .required('Required')
        .max(63, 'Must be 63 characters or less')
        .matches(/^[\da-z]+(?:-[\da-z]+)*$/i, 'Invalid format'),
    }),
    initialValues: {
      slug: collection.slug,
    },
    onSubmit: async (values) => {
      try {
        if (collection.slug !== values.slug) {
          await updateTabMutation.mutateAsync({
            pathParams: {
              tabId: collection.id,
            },
            body: {slug: values.slug},
          })
        }
        ownRef.current?.hide()
      } catch (err: any) {
        if (err.response?.data?.errors?.[0]?.error === 'slug_taken') {
          growlActions.show('error', {
            title: 'This link is already in use',
            body: 'Please try something else.',
          })
        } else {
          growlActions.show('error', {
            title: 'Something went wrong',
            body: `Error: ${err.message}`,
          })
        }
      }
    },
  })

  return (
    <WebUI.Prompt ref={ref} aria-label="Edit collection link" {...restProps}>
      {(dialog) => (
        <form onSubmit={formik.handleSubmit}>
          <WebUI.VStack className="gap-3">
            <WebUI.PromptHeader
              className="[&_>_.PromptHeader-heading]:text-ds-lg"
              heading="Edit Link:"
            />
            <WebUI.VStack className="gap-4">
              <WebUI.HStack className="justify-center gap-2">
                <WebUI.VStack
                  className={
                    'justify-center rounded bg-teal-80 px-4 text-center text-ds-sm text-gray600'
                  }
                >
                  https://
                </WebUI.VStack>
                <WebUI.FormField className="grow" error={formik.errors.slug}>
                  <WebUI.Input
                    className="rounded-none text-center"
                    name="slug"
                    size="compact"
                    value={formik.values.slug}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                <WebUI.VStack className="justify-center rounded bg-teal-80 px-4 text-center text-ds-sm text-gray600">
                  .cheddarup.com
                </WebUI.VStack>
              </WebUI.HStack>
              <div className="text-ds-sm">
                <span className="font-bold text-orange-50">NOTE:</span> Editing
                this URL will deactivate any QR codes you have shared for this
                collection.
              </div>
              <WebUI.HStack className="gap-3">
                <WebUI.Button type="submit">Save</WebUI.Button>
                <WebUI.Button
                  type="button"
                  variant="secondary"
                  onClick={() => dialog.hide()}
                >
                  Cancel
                </WebUI.Button>
              </WebUI.HStack>
            </WebUI.VStack>
          </WebUI.VStack>
        </form>
      )}
    </WebUI.Prompt>
  )
})

// MARK: – ShareByEmailPanel

export interface ShareByEmailPanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection?: Api.Tab
}

export const ShareByEmailPanel = ({
  className,
  collection,
  ...restProps
}: ShareByEmailPanelProps) => {
  const [managerRole] = useManagerRole()
  return (
    <WebUI.VStack
      className={WebUI.cn('gap-5 p-7', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <SharePanelHeading iconName="envelope">Share by email</SharePanelHeading>
      <WebUI.Text className="grow font-light">
        Send invitations and track payments and responses.
      </WebUI.Text>
      <LinkButton
        variant="default"
        disabled={
          collection?.status === 'draft' ||
          (!!managerRole &&
            (managerRole.permissions?.role === 'viewer' ||
              !managerRole.permissions.address_book_and_message_center))
        }
        to="message-center?messageType=invite"
      >
        Send a Custom Invitation
      </LinkButton>
    </WebUI.VStack>
  )
}

// MARK: – ShareByQRCodePanel

export interface ShareByQRCodePanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ShareByQRCodePanel = ({
  collection,
  className,
  ...restProps
}: ShareByQRCodePanelProps) => (
  <WebUI.VStack
    className={WebUI.cn('gap-5 p-7', className)}
    as={WebUI.Panel}
    {...restProps}
  >
    <SharePanelHeading iconName="qr-code">
      Share with a QR Code
    </SharePanelHeading>
    <WebUI.Text className="grow font-light">
      Download a QR code that links to your collection.{' '}
      <WebUI.Anchor
        href={config.links.shareCollection}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </WebUI.Anchor>
      .
    </WebUI.Text>
    <ShareQRCodeLinkModal
      link={config.helpers.shareUrl(collection.slug)}
      text={
        <>
          Share this QR code that will send people to your Collection Page URL.
          Download it or take a screenshot, then add it to any of your marketing
          materials.{' '}
          <WebUI.Anchor
            href={config.links.shareCollection}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </WebUI.Anchor>
          .
        </>
      }
      disclosure={
        <WebUI.DialogDisclosure
          disabled={collection?.status === 'draft'}
          onClick={() =>
            shareEventTracking(collection, 'qr', 'collection link')
          }
        >
          Create a QR Code
        </WebUI.DialogDisclosure>
      }
    />
  </WebUI.VStack>
)

// MARK: – ShareByHTMLButtonPanel

export interface ShareByHTMLButtonPanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ShareByHTMLButtonPanel = ({
  collection,
  className,
  ...restProps
}: ShareByHTMLButtonPanelProps) => {
  const growlActions = WebUI.useGrowlActions()

  const websiteButton = useMemo(() => {
    const url =
      import.meta.env.PUBLIC_URL ||
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`

    return (
      <a
        href={`${url}/c/${collection.slug}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt="Pay with Cheddar Up"
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '172px',
          }}
          width="auto"
          height="auto"
          src={import.meta.env.DEV ? `${url}${CheddarUpPay}` : CheddarUpPay}
        />
      </a>
    )
  }, [collection.slug])

  const code = useMemo(
    () => ReactDOM.renderToString(websiteButton),
    [websiteButton],
  )

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-5 p-7', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <SharePanelHeading iconName="code">Share on website</SharePanelHeading>
      <WebUI.Text className="grow font-light">
        Add a button to your website that links to your collection.
      </WebUI.Text>
      <WebUI.Prompt
        aria-label="Embed cheddar up pay button"
        disclosure={
          <WebUI.DialogDisclosure
            disabled={collection.status === 'draft'}
            onClick={() =>
              shareEventTracking(collection, 'embed', 'collection link')
            }
          >
            Add a Website Button
          </WebUI.DialogDisclosure>
        }
      >
        <WebUI.VStack className="gap-4">
          <WebUI.PromptHeader
            className={
              '[&_>_.PromptHeader-heading]:text-ds-md [&_>_.PromptHeader-heading]:text-gray800 [&_>_.PromptHeader-subheading]:mt-4 [&_>_.PromptHeader-subheading]:font-light [&_>_.PromptHeader-subheading]:text-ds-sm'
            }
            heading="Copy and paste this code"
            subheading="Because each webpage is different, you may need to work with your site’s administrator or developer to embed the HTML button code onto your blog or website."
          />
          <WebUI.VStack className="gap-2 py-2">
            <div className="font-medium text-ds-sm">BUTTON PREVIEW</div>
            {websiteButton}
          </WebUI.VStack>
          <WebUI.Textarea
            readOnly
            className="font-light text-ds-sm"
            value={code}
            rows={5}
          />
          <WebUI.HStack>
            <WebUI.Button
              onClick={() => {
                WebUI.copyToClipboard(code)
                growlActions.show('success', {
                  title: 'Success!',
                  body: 'Code copied',
                })
              }}
            >
              Copy
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      </WebUI.Prompt>
    </WebUI.VStack>
  )
}

// MARK: – Helpers

interface SharePanelHeadingProps extends React.ComponentPropsWithoutRef<'div'> {
  iconName: WebUI.PhosphorIconName
  children: React.ReactNode
}

const SharePanelHeading = ({
  iconName,
  className,
  children,
  ...restProps
}: SharePanelHeadingProps) => (
  <WebUI.HStack
    className={WebUI.cn('SharePanelHeading', 'items-center gap-3', className)}
    {...restProps}
  >
    <WebUI.PhosphorIcon
      icon={iconName}
      className="SharePanelHeading-icon text-ds-xl text-tint"
    />
    <WebUI.Heading className="SharePanelHeading-heading text-ds-md" as="h2">
      {children}
    </WebUI.Heading>
  </WebUI.HStack>
)
