import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabAttachmentSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/attachments',
    responseSchema: tabAttachmentSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/attachments',
    method: 'POST',
    bodySchema: z.any(), // form data
    responseSchema: tabAttachmentSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/attachments/:attachmentId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
