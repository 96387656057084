import * as Util from '@cheddarup/util'
import {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useDeleteRecurringPaymentContractMutation,
  useUpdateRecurringPaymentContractCard,
} from '@cheddarup/api-client'

import RecurringPaymentFormatter from '../helpers/RecurringPaymentFormatter'
import {Link} from './Link'

export interface OrderSummaryTermsHistoryProps {
  tabItemId?: number
  payment?: Api.TabPayment | Api.PaymentToOthersDetailed
  paymentMethodSelection?: boolean
}

const OrderSummaryTermsHistory = ({
  tabItemId,
  payment,
  paymentMethodSelection,
}: OrderSummaryTermsHistoryProps) => {
  const deleteContractMutation = useDeleteRecurringPaymentContractMutation()

  const scheduledInvoice = payment?.scheduled_invoices.find(
    (si) => si.tab_object_id === tabItemId,
  )
  const recurringPaymentContractId =
    payment?.recurring_payment_invoice?.recurring_payment_contract?.id ??
    scheduledInvoice?.recurring_payment_contract_id ??
    null

  const {data: recurringContract, isPending: isRecurringContractPending} =
    api.recurringPaymentContracts.detail.useQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          contractId: recurringPaymentContractId!,
        },
      },
      {
        enabled: recurringPaymentContractId != null,
      },
    )
  const {data: recurringPaymentContracts} =
    api.recurringPaymentContracts.list.useQuery()
  const initiatingPaymentQuery = api.payments.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        paymentId: recurringContract?.metadata.initiating_payment!,
      },
    },
    {
      enabled:
        payment != null &&
        !('can_delete' in payment) &&
        recurringContract?.metadata.initiating_payment != null,
    },
  )
  const initiatingTabPaymentQuery = api.tabPayments.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: payment?.tab_id!,
        // biome-ignore lint/style/noNonNullAssertion:
        paymentId: recurringContract?.metadata.initiating_payment!,
      },
    },
    {
      enabled:
        payment != null &&
        'can_delete' in payment &&
        recurringContract?.metadata.initiating_payment != null,
    },
  )

  const initiatingPayment =
    initiatingPaymentQuery.data ?? initiatingTabPaymentQuery.data
  const initiatingPaymentItem = initiatingPayment?.payment_items.find(
    (pi) => pi.tab_item?.id === tabItemId,
  )

  const recurringLabels = recurringContract?.options
    ? RecurringPaymentFormatter.getLabels(recurringContract.options)
    : null

  const nextPaymentDate =
    recurringContract?.recurring_payment_invoices.find(
      (rpi) => rpi.status === 'scheduled',
    )?.metadata.next_attempt ?? recurringContract?.metadata.next_invoice

  const hasNextPayment =
    recurringContract?.status !== 'cancelled' &&
    (recurringContract?.status !== 'completed' || nextPaymentDate)

  const completedPayments = useMemo(
    () =>
      [
        initiatingPaymentItem
          ? {
              ...initiatingPaymentItem,
              paid: initiatingPayment?.status !== 'failed',
              status: initiatingPayment?.status ?? 'failed',
            }
          : undefined,
        ...(recurringContract?.recurring_payment_invoices.flatMap(
          (rpi) => rpi.payments,
        ) ?? []),
      ].filter((p) => p != null),
    [
      initiatingPayment,
      initiatingPaymentItem,
      recurringContract?.recurring_payment_invoices,
    ],
  )

  const upcomingPaymentDates = useMemo(() => {
    if (
      !recurringContract ||
      recurringContract.status === 'cancelled' ||
      recurringContract.status === 'completed'
    ) {
      return []
    }

    const paidInvoicesCount =
      recurringContract.recurring_payment_invoices.filter(
        (rpi) => rpi.status === 'paid',
      ).length || (initiatingPaymentItem ? 1 : 0)
    const upcomingPaymentsCount =
      (recurringContract.options.ends.payment_count ?? 0) - paidInvoicesCount

    const dates = []

    for (let i = 0; i < upcomingPaymentsCount; i++) {
      dates.push(
        Util.addSeconds(
          new Date(nextPaymentDate ?? recurringContract.metadata.next_invoice),
          i * (recurringContract.options.repeatIntervalSeconds ?? 0),
        ),
      )
    }

    return dates
  }, [initiatingPaymentItem, nextPaymentDate, recurringContract])

  if (!payment || isRecurringContractPending) {
    return (
      <WebUI.VStack className="h-[180px] items-center justify-center">
        <WebUI.Loader size={40} />
      </WebUI.VStack>
    )
  }

  if (!recurringContract) {
    return null
  }

  const recurringAmount = recurringContract.metadata.amount_pennies / 100

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.Heading as="h4">Recurring Payment</WebUI.Heading>
      <WebUI.Separator variant="primary" />
      {!!recurringContract && !isRecurringContractPending && (
        <WebUI.VStack className="gap-2">
          <WebUI.Heading as="h4">
            {recurringContract.tab_item.name}
          </WebUI.Heading>
          <div>
            <div className="text-ds-sm">
              Recurring Amount: {Util.formatAmount(recurringAmount)}
            </div>
            <div className="text-ds-sm">
              Repeating Every:{' '}
              {recurringLabels?.repeat
                ? Util.startCase(
                    `${
                      Number.isNaN(Number.parseInt(recurringLabels.repeat, 10))
                        ? '1 '
                        : ''
                    }${recurringLabels.repeat}`,
                  )
                : ''}
            </div>
            <div className="text-ds-sm">
              Starting:{' '}
              {Util.formatDateAs(
                completedPayments[0]?.created_at ??
                  recurringContract.metadata.scheduled_start,
              )}
            </div>
            <div className="text-ds-sm">
              Ending: {recurringLabels?.end ?? ''}
            </div>
            <div className="text-ds-sm">
              Next Payment:{' '}
              {recurringContract.status === 'cancelled' ? (
                <span className="text-orange-50">Cancelled</span>
              ) : nextPaymentDate ? (
                Util.formatDateAs(nextPaymentDate)
              ) : (
                Util.capitalize(recurringContract.status)
              )}
            </div>
          </div>
        </WebUI.VStack>
      )}
      <WebUI.Separator variant="primary" />
      {paymentMethodSelection && (
        <>
          <PaymentMethodSelectDropdown
            recurringContract={recurringContract}
            banks={recurringPaymentContracts?.banks}
            cards={recurringPaymentContracts?.cards}
          />
          <WebUI.Separator variant="primary" />
        </>
      )}
      {completedPayments.length > 0 && (
        <>
          <WebUI.VStack className="gap-2">
            <WebUI.Heading as="h4">
              Total Paid To-Date:{' '}
              {Util.formatAmount(
                Util.sum(
                  completedPayments.filter((p) => p.paid).map((p) => p.total),
                ),
              )}
            </WebUI.Heading>
            <WebUI.VStack className="gap-2">
              <WebUI.HStack className="justify-between">
                <span className="font-medium text-ds-sm">Payment Date</span>
                <span className="font-medium text-ds-sm">Amount</span>
              </WebUI.HStack>
              {Util.sort(completedPayments)
                .desc((p) => p.created_at)
                .map((p) => (
                  <WebUI.HStack key={p.id} className="justify-between">
                    <span className="text-ds-sm">
                      {Util.formatDateAs(p.created_at)}

                      {p.status === 'failed' && (
                        <span className="ml-2 text-orange-50">
                          {Util.capitalize(p.status)}
                        </span>
                      )}
                    </span>
                    <span className="text-ds-sm">
                      {Util.formatAmount(p.total)}
                    </span>
                  </WebUI.HStack>
                ))}
            </WebUI.VStack>
          </WebUI.VStack>
          <WebUI.Separator variant="primary" />
        </>
      )}

      {upcomingPaymentDates.length > 0 && (
        <>
          <WebUI.VStack className="gap-2">
            <WebUI.Heading as="h4">
              Total Outstanding:{' '}
              {Util.formatAmount(recurringAmount * upcomingPaymentDates.length)}
            </WebUI.Heading>
            <WebUI.VStack className="gap-2">
              <WebUI.HStack className="justify-between">
                <span className="font-medium text-ds-sm">
                  Upcoming Payment Date
                </span>
                <span className="font-medium text-ds-sm">Amount</span>
              </WebUI.HStack>
              {upcomingPaymentDates.map((date) => (
                <WebUI.HStack
                  key={date.toISOString()}
                  className="justify-between"
                >
                  <span className="text-ds-sm">{Util.formatDateAs(date)}</span>
                  <span className="text-ds-sm">
                    {Util.formatAmount(recurringAmount)}
                  </span>
                </WebUI.HStack>
              ))}
            </WebUI.VStack>
          </WebUI.VStack>

          <WebUI.Separator variant="primary" />
        </>
      )}

      {hasNextPayment && (
        <WebUI.Alert
          aria-label="Cancel future payments confirmation"
          disclosure={
            <WebUI.DialogDisclosure className="text-ds-sm" variant="link">
              Cancel future payments
            </WebUI.DialogDisclosure>
          }
        >
          <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text={
              <>
                All future payments from {payment?.tab_member?.name} for this
                item with be cancelled and an email confirming cancellation will
                be sent to{' '}
                <span className="text-teal-50">
                  {payment?.tab_member?.email}.
                </span>
              </>
            }
            actions={
              <>
                <WebUI.AlertActionButton
                  execute={() =>
                    deleteContractMutation.mutateAsync({
                      pathParams: {
                        contractId: recurringContract.id,
                      },
                    })
                  }
                >
                  Delete Item
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton>Cancel</WebUI.AlertCancelButton>
              </>
            }
          />
        </WebUI.Alert>
      )}
    </WebUI.VStack>
  )
}

// MARK: - PaymentMethodSelectDropdown

interface PaymentMethodSelectDropdownProps {
  recurringContract?: Api.RecurringPaymentContract
  cards?: Api.CreditCard[]
  banks?: Api.BankAccount[]
}

export const PaymentMethodSelectDropdown = ({
  recurringContract,
  cards = [],
  banks = [],
}: PaymentMethodSelectDropdownProps) => {
  const updateContractCardMutation = useUpdateRecurringPaymentContractCard()

  return (
    <WebUI.DropdownSelect<string>
      className="mt-[1rem] w-[232px]"
      size="compact"
      placeholder="Select payment method"
      defaultValue={
        recurringContract?.stripe_source ?? cards[0]?.id ?? banks[0]?.id
      }
      onValueChange={(cardId) => {
        if (cardId && recurringContract) {
          updateContractCardMutation.mutate({
            pathParams: {
              contractId: recurringContract.id,
            },
            body: {stripe_source: cardId},
          })
        }
      }}
      loading={updateContractCardMutation.isPending}
    >
      {cards.map((c) => (
        <WebUI.DropdownSelectOption key={c.id} value={c.id}>
          {c.nickname || `Credit card ending in ${c.last4}`}
        </WebUI.DropdownSelectOption>
      ))}
      {banks.map((b) => (
        <WebUI.DropdownSelectOption key={b.id} value={b.id}>
          {`Bank account ending in ${b.last4}`}
        </WebUI.DropdownSelectOption>
      ))}
      <WebUI.DropdownSelectOption
        value=""
        as={Link}
        variant="primary"
        to="my-account/payment-methods"
      >
        Add a new payment method
      </WebUI.DropdownSelectOption>
    </WebUI.DropdownSelect>
  )
}

export default OrderSummaryTermsHistory
