import {
  MutateFunction,
  Query,
  QueryClient,
  UseMutationOptions,
  UseMutationResult as UseReactMutationResult,
  useQueryClient,
  useMutation as useReactMutation,
} from '@tanstack/react-query'
import {EmptyObject, HasRequiredKeys, hasSubObject, z} from '@cheddarup/util'
import {useRef} from 'react'

import {
  AnyEndpoint,
  EndpointKey,
  FetchInput,
  GetEndpointKeyInput,
  fetchEndpoint,
  getEndpointKey,
} from '../utils'

export type {MutateFunction, UseMutationOptions}

export type UseMutationResult<
  TEndpoint extends AnyEndpoint,
  TVariables extends FetchInput<TEndpoint>,
  TTimeout extends number | undefined,
> = UseReactMutationResult<
  z.infer<TEndpoint['responseSchema']>,
  unknown,
  NonNullable<TVariables['body']> extends EmptyObject
    ? NonNullable<TVariables['queryParams']> extends EmptyObject
      ? NonNullable<TVariables['pathParams']> extends EmptyObject
        ? void
        : TVariables
      : TVariables
    : TVariables
> & {
  timeout: TTimeout
  isCancelPendingRef: React.RefObject<boolean>
  cancelRef: React.RefObject<() => void>
  forceRunRef: React.RefObject<() => void>
}

export interface QueryUpdate<TEndpoint extends AnyEndpoint = AnyEndpoint> {
  queryKey: EndpointKey<TEndpoint>
  update: (
    prevData: z.infer<TEndpoint['responseSchema']> | undefined,
  ) => z.infer<TEndpoint['responseSchema']> | undefined
}

export function makeQueryUpdate<
  TEndpoint extends AnyEndpoint,
  TInput extends GetEndpointKeyInput<TEndpoint>,
>(
  endpoint: TEndpoint,
  update: QueryUpdate<TEndpoint>['update'],
  ...[queryKeyOptions]: HasRequiredKeys<TInput> extends true
    ? [TInput]
    : [TInput?]
): QueryUpdate<TEndpoint> {
  return {
    queryKey: getEndpointKey(endpoint, queryKeyOptions as any),
    update,
  }
}

export function makeUseMutation<
  TEndpoint extends AnyEndpoint,
  TVariables extends FetchInput<TEndpoint>,
  TData extends z.infer<TEndpoint['responseSchema']>,
>(
  endpoint: TEndpoint,
  cacheUpdatesBuilder?: (
    vars: TVariables,
    queryClient: QueryClient,
  ) => {
    regular?: (newData: TData) => QueryUpdate[]
    optimistic?: QueryUpdate[]
  },
  patchMutationOptions?: (
    queryClient: QueryClient,
  ) => Omit<
    UseMutationOptions<TData, unknown, TVariables, unknown>,
    'mutationFn'
  >,
) {
  const useMutation = <TTimeout extends number | undefined = undefined>(
    mutationOptions?: Omit<
      UseMutationOptions<TData, unknown, TVariables, unknown>,
      'mutationFn'
    > & {timeout?: TTimeout},
  ) => {
    const queryClient = useQueryClient()
    const isCancelPendingRef = useRef(false)
    const cancelMutateRef = useRef(() => {})
    const forceRunRef = useRef(() => {})
    const headers = useApiHeaders?.()

    const patchedMutationOptions = patchMutationOptions?.(queryClient)

    const mutation = useReactMutation({
      mutationFn: (vars) => {
        const varsWithHeaders = {
          ...vars,
          headers: {
            ...headers,
            ...vars?.headers,
          },
        }

        return mutationOptions?.timeout == null
          ? fetchEndpoint(endpoint, varsWithHeaders)
          : new Promise((resolve, reject) => {
              isCancelPendingRef.current = true

              function runMutation() {
                isCancelPendingRef.current = false

                fetchEndpoint(endpoint, varsWithHeaders)
                  .then((res) => resolve(res))
                  .catch((err) => reject(err))
              }

              const timeoutId = setTimeout(
                runMutation,
                mutationOptions?.timeout,
              )

              forceRunRef.current = () => {
                if (isCancelPendingRef.current) {
                  clearTimeout(timeoutId)
                  runMutation()
                }
              }

              cancelMutateRef.current = () => {
                clearTimeout(timeoutId)

                isCancelPendingRef.current = false

                reject(new Error('Undone by user'))
              }
            })
      },
      ...mutationOptions,
      onMutate: async (vars) => {
        patchedMutationOptions?.onMutate?.(vars)
        mutationOptions?.onMutate?.(vars)

        const optimisticCacheUpdates =
          cacheUpdatesBuilder?.(vars, queryClient).optimistic ?? []

        await Promise.all(
          optimisticCacheUpdates.map((update) =>
            queryClient.cancelQueries({predicate: makeQueryPredicate(update)}),
          ),
        )

        const optimisticUpdateRollbackInfos = optimisticCacheUpdates.flatMap(
          (update) =>
            queryClient
              .getQueriesData({
                predicate: makeQueryPredicate(update),
              })
              .map(([queryKey, prevQueryData]) => {
                queryClient.setQueryData(queryKey, update.update(prevQueryData))

                return {
                  queryKey,
                  prevQueryData,
                }
              }),
        )

        return {
          optimisticUpdateRollbackInfos,
        }
      },
      onSettled: (res, err, vars, ctx) => {
        patchedMutationOptions?.onSettled?.(res, err, vars, ctx)
        mutationOptions?.onSettled?.(res, err, vars, ctx)

        const optimisticUpdateRollbackInfos =
          ctx?.optimisticUpdateRollbackInfos ?? []

        optimisticUpdateRollbackInfos.forEach((rollbackInfo) => {
          if (err) {
            queryClient.setQueryData(
              rollbackInfo.queryKey,
              rollbackInfo.prevQueryData,
            )
          } else {
            queryClient.invalidateQueries({queryKey: rollbackInfo.queryKey})
          }
        })
      },
      onSuccess: (newData, vars, ctx) => {
        patchedMutationOptions?.onSuccess?.(newData, vars, ctx)
        mutationOptions?.onSuccess?.(newData, vars, ctx)

        const regularCacheUpdates =
          cacheUpdatesBuilder?.(vars, queryClient).regular?.(newData) ?? []

        regularCacheUpdates.forEach((cacheUpdate) => {
          queryClient.setQueriesData(
            {predicate: makeQueryPredicate(cacheUpdate)},
            (prev) => cacheUpdate.update(prev),
          )
        })
      },
    }) as any as UseMutationResult<TEndpoint, TVariables, TTimeout>
    ;(mutation as any).timeout = mutationOptions?.timeout
    ;(mutation as any).isCancelPendingRef = isCancelPendingRef
    ;(mutation as any).cancelRef = cancelMutateRef
    ;(mutation as any).forceRunRef = forceRunRef

    return mutation
  }

  return useMutation
}

// MARK: – Helpers

function makeQueryPredicate(update: QueryUpdate) {
  return function predicate(query: Query) {
    const [updatePath, updateInput] = update.queryKey
    const [queryPath, queryInput] = query.queryKey as EndpointKey

    const isBaseEqual =
      queryPath === updatePath &&
      hasSubObject(queryInput.pathParams ?? {}, updateInput.pathParams ?? {})

    if ('queryParams' in updateInput) {
      return (
        isBaseEqual &&
        'queryParams' in queryInput &&
        hasSubObject(
          (queryInput.queryParams as any) ?? {},
          (updateInput.queryParams as any) ?? {},
        )
      )
    }

    return isBaseEqual
  }
}
