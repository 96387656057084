import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import * as WebUI from '@cheddarup/web-ui'
import {uploadImage} from '@cheddarup/core'
import {
  api,
  useDeleteBrandingLogoMutation,
  useUpdateBrandingMutation,
} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'

const BrandKitLogoPage = () => {
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })

  return (
    <AccountSettingsContentLayout
      heading={
        <div className="flex flex-row items-center gap-4">
          <WebUI.Heading as="h2">Logo</WebUI.Heading>
          {!isSubscribedToTeamQuery.data && (
            <PlanUpgradeButton upgradeTo="team" asPaidBadge />
          )}
        </div>
      }
      body="Put your brand front and center with premium branding. Upload a logo that will appear in the page header of all of your collection pages and Group Page. You can also use this logo to customize email receipts and messages."
    >
      <UploadBrandLogo />
    </AccountSettingsContentLayout>
  )
}

// MARK: – UploadBrandLogo

interface UploadBrandLogoProps extends React.ComponentPropsWithoutRef<'div'> {}

const UploadBrandLogo = (props: UploadBrandLogoProps) => {
  const [uploading, setUploading] = useState(false)
  const navigate = useNavigate()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const {data: branding} = api.userBrandings.detail.useQuery()
  const updateBrandingMutation = useUpdateBrandingMutation()
  const deleteBrandingLogoMutation = useDeleteBrandingLogoMutation()

  return (
    <AccountSettingsContentCard heading="Upload Your Logo" {...props}>
      <div className="flex flex-col gap-3">
        <WebUI.FileUploader
          accept={{'image/*': []}}
          multiple={false}
          noClick={!isSubscribedToTeamQuery.data}
          noDrag={!isSubscribedToTeamQuery.data}
          noKeyboard={!isSubscribedToTeamQuery.data}
          onDropAccepted={async ([newImage]) => {
            if (newImage) {
              setUploading(true)
              const image = await uploadImage(
                'users/branding',
                newImage,
                undefined,
                {
                  metadata: {
                    contentType: newImage.type,
                  },
                  userId: undefined,
                },
              )
              await updateBrandingMutation.mutateAsync({
                body: {
                  logo_id: image?.id,
                },
              })
              setUploading(false)
            }
          }}
        >
          <WebUI.FileUploaderDropzone
            className="flex w-full max-w-[280px] flex-row items-center justify-center border py-5"
            onClick={() => {
              if (!isSubscribedToTeamQuery.data) {
                navigate('i/plans?recommendedPlan=team')
              }
            }}
          >
            {uploading ? (
              <WebUI.Loader />
            ) : branding?.logo ? (
              <div className="relative">
                <WebUI.Image alt="" src={branding.logo.url} />
                <WebUI.IconButton
                  variant="secondary"
                  className="-top-4 absolute right-1"
                  size="default_alt"
                  loading={deleteBrandingLogoMutation.isPending}
                  onClick={async (e) => {
                    e.stopPropagation()
                    if (branding.logo?.id) {
                      await deleteBrandingLogoMutation.mutateAsync({
                        pathParams: {id: branding.logo.id},
                      })
                    }
                  }}
                >
                  <WebUI.PhosphorIcon icon="x" />
                </WebUI.IconButton>
              </div>
            ) : (
              <WebUI.PhosphorIcon className="text-8xl" icon="upload-simple" />
            )}
          </WebUI.FileUploaderDropzone>
          {!!isSubscribedToTeamQuery.data && <WebUI.FileUploaderInput />}
        </WebUI.FileUploader>
        <span className="font-body text-ds-xs text-gray600">
          We recommend a horizontal format for your logo.
        </span>
      </div>

      <WebUI.Disclosure visible={!!branding?.logo}>
        <WebUI.DisclosureContent>
          <div className="flex flex-col items-start gap-6">
            <WebUI.Separator className="-mx-96" variant="primary" />
            <WebUI.Switch
              name="customBrandEmails"
              size="compact"
              checked={!!branding?.custom_brand_emails}
              onChange={(e) =>
                updateBrandingMutation.mutate({
                  body: {
                    custom_brand_emails: e.target.checked,
                  },
                })
              }
            >
              Custom brand your emails
            </WebUI.Switch>
            <span className="text-ds-sm">
              Replace Cheddar Up branding at the top of all email receipts and
              messages with your logo.
            </span>
          </div>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </AccountSettingsContentCard>
  )
}

export default BrandKitLogoPage
