import {z} from '@cheddarup/util'

import {currencySchema, s3ImageSchema} from './common'

export const userUiClientFlagsCategorySchema = z.object({
  uuid: z.string(),
  name: z.string(),
})

export const userUiClientFlagsSchema = z.object({
  onboardingChecklist: z
    .object({
      overviewWatched: z.boolean().optional(),
      appDownloaded: z.boolean().optional(),
      finishSetupComplete: z.boolean().optional(),
      statementDescriptorComplete: z.boolean().optional(),
      withdrawalMethodComplete: z.boolean().optional(),
      profilePictureComplete: z.boolean().optional(),
      show: z.boolean().optional(),
    })
    .optional(),
  customCardDescriptor: z.string(),
  underwritingOrganizationName: z.string().nullish(),
  customDefaultFolderName: z.string().nullish(),
  disablePointOfSaleSignatures: z.boolean().nullish(),
  requireSignatureForPosCode: z.boolean().nullish(),
  timeZone: z.string().nullish(),
  zip: z.string().nullish(),
  categories: userUiClientFlagsCategorySchema.array().nullish(),
  finishSetupComplete: z.boolean().nullish(),
  disabledEmailNotifications: z
    .object({
      paymentsReceived: z.boolean(),
      remindersToPay: z.boolean(),
      marketingOptIn: z.boolean().optional(),
    })
    .nullish(),
})

export const customerByEmailPaymentSchema = z.object({
  id: z.number(),
  uuid: z.string(),
  date: z.string(),
  items: z.number(),
  total: z.number(),
  collection: z.object({
    id: z.number(),
    name: z.string(),
  }),
  organizer: z.object({
    id: z.number(),
    uuid: z.string(),
    name: z.string(),
  }),
})

export const customerByEmailVisitSchema = z.object({
  first_visit: z.string(),
  total_spent: z.number(),
  total_visits: z.number(),
  collection: z.object({
    id: z.number(),
    name: z.string(),
  }),
  organizer: z.object({
    id: z.number(),
    uuid: z.string(),
    name: z.string(),
  }),
})
export const customerByEmailSchema = z.object({
  email: z.string(),
  total_items_purchased: z.number(),
  total_spent: z.number(),
  payments: customerByEmailPaymentSchema.array(),
  visits: customerByEmailVisitSchema.array(),
})

export const affiliateReferrerSchema = z.object({
  id: z.number().nullish(),
  firstname: z.string().nullish(),
  lastname: z.string().nullish(),
})

export const personaSchema = z.object({
  required: z.boolean(),
  completed: z.boolean(),
  status: z.string().nullable(),
  currentInquiry: z.object({
    meta: z
      .object({
        'session-token': z.string(),
      })
      .nullish(),
    data: z
      .object({
        id: z.string(),
        attributes: z
          .object({
            status: z.string(),
            'created-at': z.string(),
          })
          .nullish(),
      })
      .nullish(),
    phone: z
      .object({
        verified: z.boolean(),
        verifiedAt: z.string().nullish(),
        lastTwoNumbers: z.string().nullish(),
        fullNumber: z.string().nullish(),

        // TODO: check which one is correct
        countryCode: z.string().nullish(),
        phoneNumber: z.string().nullish(),
        country_code: z.string().nullish(),
        phone_number: z.string().nullish(),
      })
      .nullish(),
    uiClientFlags: userUiClientFlagsSchema.optional(),
    affiliateReferrer: affiliateReferrerSchema.nullish(),
  }),
})
export const withdrawalDataSchema = z.object({
  total_available_balance: z.number(),
})

export const userAddressSchema = z.object({
  city: z.string().nullish(),
  country: z.string(),
  line1: z.string().nullish(),
  line2: z.string().nullish(),
  postal_code: z.string().nullish(),
  state: z.string().nullish(),
})

export const userEntityTypeSchema = z.enum([
  'individual',
  'company',
  'non_profit',
])

export const userDateOfBirthSchema = z.object({
  day: z.number().nullish(),
  month: z.number().nullish(),
  year: z.number().nullish(),
})

export const userCustomerCategorySchema = z.enum([
  'BUSINESS',
  'CLUB',
  'FINE ARTS',
  'FRIENDS',
  'NON-PROFIT',
  'PERSONAL',
  'RELIGIOUS',
  'SCHOOL',
  'SCOUT',
  'SPORT',
  'DISABLED',
  'TEST',
  'UNKNOWN',
  'NONE_OF_THE_ABOVE',
  'MANAGER',
])

export const userDisabledReasonSchema = z.enum([
  'requirements.past_due',
  'requirements.pending_verification',
])

export const userRequirementErrorSchema = z.object({
  code: z.string(),
  requirement: z.string(),
  reason: z.string(),
})

export const profileFeaturesSchema = z.object({
  hasTeamEcheckFees: z.boolean().nullish(),
  transferAccountEnabled: z.boolean().nullish(),
  customFeeRefundSchedule: z
    .object({
      enabled: z.boolean(),
      percentRefundable: z.number(),
    })
    .nullish(),
  subscriptionEcheckEnabled: z.boolean().optional(),
  zapierEnabled: z.boolean().nullish(),
})

export const organizationIdentifierSchema = z.object({
  fieldName: z.string(),
  fieldValue: z.string(),
})

export const profilePhoneSchema = z.object({
  verified: z.boolean(),
  verifiedAt: z.string().nullish(),
  lastTwoNumbers: z.string().nullish(),
  fullNumber: z.string().nullish(),
  country_code: z.any().nullish(),
  phone_number: z.any().nullish(),
})

export const userProfileSchema = z.object({
  id: z.number(),
  uuid: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  email: z.string(),
  display_name: z.string().nullable(),
  profile: z.object({
    persona: personaSchema,
    phone: profilePhoneSchema,
    returnAddress: z.any().nullish(),
    uiClientFlags: userUiClientFlagsSchema,
    affiliateReferrer: affiliateReferrerSchema,
    clientMetadata: z.any(),
    features: profileFeaturesSchema,
    organizationIdentifier: organizationIdentifierSchema.nullable(),
    security: z.object({
      secondaryEmail: z.object({
        enabled: z.boolean().nullable(),
        value: z.string().nullable(),
      }),
    }),
  }),
  profile_pic: s3ImageSchema.nullish(),
  withdrawal_data: withdrawalDataSchema,
  group_page_logo: s3ImageSchema.nullish(),
  slug: z.string(),
  full_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  business_address: userAddressSchema,
  personal_address: userAddressSchema,
  personal_id_number_provided: z.boolean(),
  ssn_last_4_provided: z.boolean(),
  entity_type: userEntityTypeSchema.nullish(),
  dob: userDateOfBirthSchema,
  currency: currencySchema,
  editable: z.boolean(),
  availableQuantityOneByDefault: z.boolean(),
  tax_id_provided: z.boolean(),
  customer_category: userCustomerCategorySchema.nullish(),
  disabled_reason: userDisabledReasonSchema.nullish(),
  requirement_errors: userRequirementErrorSchema.array(),
  has_recurring_items: z.any(),
  canRequestSecurityCode: z.boolean(),
  securityCodeRequested: z.boolean(),
  requirePhoneVerification: z.boolean(),
})

export const clientUserByEmailSchema = z.object({
  customer: customerByEmailSchema,
  user: userProfileSchema,
})

declare global {
  namespace Api {
    type ClientUserByEmail = z.infer<typeof clientUserByEmailSchema>
    type UserProfile = z.infer<typeof userProfileSchema>
    type ProfileFeatures = z.infer<typeof profileFeaturesSchema>
    type UserRequirementError = z.infer<typeof userRequirementErrorSchema>
    type UserDisabledReason = z.infer<typeof userDisabledReasonSchema>
    type UserCustomerCategory = z.infer<typeof userCustomerCategorySchema>
    type UserDateOfBirth = z.infer<typeof userDateOfBirthSchema>
    type UserEntityType = z.infer<typeof userEntityTypeSchema>
    type UserAddress = z.infer<typeof userAddressSchema>
    type WithdrawalData = z.infer<typeof withdrawalDataSchema>
    type Persona = z.infer<typeof personaSchema>
    type AffiliateReferrer = z.infer<typeof affiliateReferrerSchema>
    type CustomerByEmail = z.infer<typeof customerByEmailSchema>
    type CustomerByEmailVisit = z.infer<typeof customerByEmailVisitSchema>
    type CustomerByEmailPayment = z.infer<typeof customerByEmailPaymentSchema>
    type UserUiClientFlags = z.infer<typeof userUiClientFlagsSchema>
    type UserUiClientFlagsCategory = z.infer<
      typeof userUiClientFlagsCategorySchema
    >
    type OrganizationIdentifier = z.infer<typeof organizationIdentifierSchema>
  }
}
