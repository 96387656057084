import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'
import {tabObjectFieldViewSchema} from './field-views'
import {itemTypeSchema} from './tab-items'

export const tabMemberPaymentItemFieldViewSchema =
  tabObjectFieldViewSchema.pick({
    id: true,
    item_field_id: true,
    field_type: true,
    name: true,
    value: true,
    position: true,
    metadata: true,
  })

export const tabMemberPaymentItemSchema = z.object({
  id: z.number(),
  detail: z.object({
    itemType: itemTypeSchema.optional(),
    variant: z
      .object({
        amount: z.number(),
        description: z.string(),
        imageId: z.string().nullish(),
        optionValues: z.record(z.string()),
        sku: z.string(),
        uuid: z.string(),
      })
      .nullish(),
  }),
  quantity: z.number(),
  total: z.number(),
  amount_after_refund: z.number().optional(),
  item_field_views: tabMemberPaymentItemFieldViewSchema.array(),
  tab_object_id: z.number(),
})

export const tabMemberPaymentSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  paid: z.boolean(),
  payment_items: tabMemberPaymentItemSchema.array(),
  total: z.number(),
})

export const tabMemberSchema = z.object({
  id: z.number(),
  tab_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullish(),
  unsubscribed_at: z.string().nullable(),
  name: z.string(),
  email: z.string(),
  phone: z.string().nullish(),
  invites: z.unknown().array(),
  identify_count: z.number(),
  first_visit: z.string().optional(),
  profile_pic: s3ImageSchema.nullable(),
  payments: tabMemberPaymentSchema.array(),
})

declare global {
  namespace Api {
    type TabMember = z.infer<typeof tabMemberSchema>
    type TabMemberPayment = z.infer<typeof tabMemberPaymentSchema>
  }
}
