import {z} from '@cheddarup/util'

import {groupPageSectionSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  detail: makeEndpoint({
    path: 'users/group_page/:sectionName',
    responseSchema: groupPageSectionSchema,
  }),
  create: makeEndpoint({
    path: 'users/group_page/:sectionName',
    method: 'POST',
    bodySchema: groupPageSectionSchema.deepPartial().extend({
      // for cache updates
      userSlug: z.string().optional(),
    }),
    responseSchema: groupPageSectionSchema,
  }),
  update: makeEndpoint({
    path: 'users/group_page/:sectionName',
    method: 'PATCH',
    bodySchema: groupPageSectionSchema.deepPartial().extend({
      // for cache updates
      userSlug: z.string().optional(),
    }),
    responseSchema: groupPageSectionSchema,
  }),
  delete: makeEndpoint({
    path: 'users/group_page/:sectionName',
    method: 'DELETE',
    responseSchema: z.void(),
  }),
}
