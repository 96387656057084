import * as WebUI from '@cheddarup/web-ui'
import React, {useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useUpdateEffect} from '@cheddarup/react-util'
import {LinkButton} from 'src/components/LinkButton'

import useCart from './hooks/useCart'
import usePublicCollection from './hooks/usePublicCollection'
import {AddPaymentFormViewsBanner} from './components/AddPaymentBanners'
import {PayerObjectsLayout} from './components/PayerLayouts'
import {isFormsBeforeItems} from './utils/public-collection-utils'
import {CollectionOverview} from './components/CollectionOverview'
import {
  getMissingRequiredForms,
  getMissingRequiredSignups,
} from '@cheddarup/core'
import {usePayerUIState} from './PayerUIStateProvider'

const PayerFormsPage = () => {
  const {publicCollection} = usePublicCollection()
  const payerUIState = usePayerUIState()

  return (
    <PayerObjectsLayout>
      <AddPaymentFormViewsBanner />
      {isFormsBeforeItems(publicCollection) && <CollectionOverview />}
      <FormList ref={payerUIState.formPickerRef} />
    </PayerObjectsLayout>
  )
}

// MARK: – FormList

export interface FormListInstance {
  viewRequiredForms: () => void
}

export const FormList = React.forwardRef<
  FormListInstance,
  Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>
>(({className, ...restProps}, forwardedRef) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [isInErrorMode, setIsInErrorMode] = useState(false)
  const listRef = useRef<WebUI.ListInstance>(null)

  useImperativeHandle(
    forwardedRef,
    () => ({
      viewRequiredForms: () => {
        const [firstMissingRequiredFormOrSignup] = [
          ...getMissingRequiredForms({
            publicTab: publicCollection,
            cart,
          }),
          ...getMissingRequiredSignups({
            publicTab: publicCollection,
            cart,
          }),
        ]

        if (firstMissingRequiredFormOrSignup != null) {
          listRef.current?.scrollToRow(firstMissingRequiredFormOrSignup.id)
          setIsInErrorMode(true)
        }
      },
    }),
    [publicCollection, cart],
  )

  const listData = useMemo(
    () => [
      ...publicCollection.forms.filter((f) => f.fields.length > 0),
      ...publicCollection.signups.filter((f) => f.visible_spots.length > 0),
    ],
    [publicCollection.forms, publicCollection.signups],
  )

  const missingRequiredFormIds = useMemo(
    () =>
      getMissingRequiredForms({publicTab: publicCollection, cart}).map(
        (f) => f.id,
      ),
    [cart, publicCollection],
  )
  const missingRequiredSignUpIds = useMemo(
    () =>
      getMissingRequiredSignups({publicTab: publicCollection, cart}).map(
        (s) => s.id,
      ),
    [cart, publicCollection],
  )

  const missingRequiredForms = missingRequiredFormIds.length > 0
  const missingRequiredSignups = missingRequiredSignUpIds.length > 0

  useUpdateEffect(() => {
    if (!missingRequiredForms && !missingRequiredSignups) {
      setIsInErrorMode(false)
    }
  }, [missingRequiredForms, missingRequiredSignups])

  const FormListRowWithHandlers: WebUI.ListRowComponentType<
    Api.PublicTabForm | Api.PublicTabSignup
  > = useMemo(
    () =>
      React.forwardRef((rowProps, forwardedRef) => (
        <FormListRow
          ref={forwardedRef}
          aria-invalid={
            isInErrorMode &&
            [...missingRequiredSignUpIds, ...missingRequiredFormIds].includes(
              rowProps.data.id,
            )
          }
          isInCart={
            cart?.forms.some((cf) => cf.tab_form.id === rowProps.data.id) ||
            cart?.time_slots.some(
              (cTs) =>
                'visible_spots' in rowProps.data &&
                rowProps.data.visible_spots.some((s) =>
                  s.time_slots.some((ts) => cTs.time_slot.id === ts.id),
                ),
            )
          }
          {...rowProps}
        />
      )),
    [
      isInErrorMode,
      missingRequiredSignUpIds,
      missingRequiredFormIds,
      cart?.forms,
      cart?.time_slots,
    ],
  )

  return (
    <WebUI.Panel variant="capsule">
      <WebUI.List
        ref={listRef}
        className="grow gap-4 overflow-visible py-3 sm:px-8 sm:py-6"
        data={listData}
        HeaderComponent={() => null}
        EmptyStateViewComponent={() => null}
        RowComponent={FormListRowWithHandlers}
        {...restProps}
      />
    </WebUI.Panel>
  )
})

// MARK: – FormListRow

interface FormListRowProps
  extends WebUI.ListRowComponentProps<Api.PublicTabForm | Api.PublicTabSignup> {
  isInCart?: boolean
}

const FormListRow = React.forwardRef<HTMLDivElement, FormListRowProps>(
  ({isInCart, data, index, className, ...restProps}, forwardedRef) => {
    const isRequired =
      'signupType' in data.options && !data.options.requireAtLeastOneSpot
        ? false
        : data.fields.some((f) => f.required)

    return (
      <WebUI.VStack
        ref={forwardedRef}
        className={WebUI.cn(
          'items-stretch aria-invalid:border-orange-50 sm:flex-row sm:items-center sm:rounded',
          className,
        )}
        as={WebUI.Panel}
        {...restProps}
      >
        <WebUI.VStack className="flex-auto px-4 pt-6 pb-0 sm:p-8">
          <WebUI.Text>
            {data.name}
            <span
              className="text-orange-50"
              style={{display: isRequired ? 'inline' : 'none'}}
            >
              *
            </span>
          </WebUI.Text>
          <WebUI.Text className="pt-1 text-ds-sm">
            {'isWaiver' in data.options && data.options.isWaiver
              ? 'Waiver'
              : 'signupType' in data.options
                ? 'Sign Up'
                : 'Form'}
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.Separator orientation="vertical" variant="primary" />
        <div className="p-4 sm:p-8">
          <LinkButton
            className="w-[130px]"
            variant={isInCart ? 'secondary' : 'default'}
            roundness="capsule"
            iconBefore={
              isInCart && (
                <WebUI.PhosphorIcon
                  className="text-ds-lg text-teal-50"
                  icon="check-circle-fill"
                />
              )
            }
            preserveSearch
            to={`form/${data.id}`}
          >
            {isInCart
              ? 'Edit'
              : `View ${
                  'isWaiver' in data.options && data.options.isWaiver
                    ? 'Waiver'
                    : 'signupType' in data.options
                      ? 'Sign Up'
                      : 'Form'
                }`}
          </LinkButton>
        </div>
      </WebUI.VStack>
    )
  },
)

export default PayerFormsPage
