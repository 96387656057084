import {BrandKitColors} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {LinkButton} from 'src/components/LinkButton'
import {SharpImage} from 'src/components/SharpImage'

interface CollectionSpotlightProps
  extends React.ComponentPropsWithoutRef<'div'> {
  brandKitColors: BrandKitColors
  collectionSpotlight: Api.GroupPageSection
}

const CollectionSpotlight: React.FC<CollectionSpotlightProps> = ({
  brandKitColors,
  collectionSpotlight,
  className,
  style,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const sortedSpotlightItems = collectionSpotlight.spotlight_items
    ? Util.sort(collectionSpotlight.spotlight_items).asc(
        (item) => item.position,
      )
    : []

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'gap-8 px-8 py-17 sm:gap-0 sm:pt-16 sm:pb-[100px] xl:px-0',
        className,
      )}
      style={{
        color: Util.getReadableColor(
          collectionSpotlight.background_color,
          undefined,
          true,
        ),
        backgroundColor: collectionSpotlight.background_color,
        ...style,
      }}
      {...restProps}
    >
      {media.sm ? (
        <WebUI.Carousel
          className="mx-auto w-full max-w-[1100px] px-6 [&_>_.Carousel-arrowBtn]:bg-natural-100"
          hideArrowsAtEdges
        >
          <WebUI.CarouselContent>
            {sortedSpotlightItems.map((item) => (
              <WebUI.CarouselItem key={item.id}>
                <SpotlightItem spotlightItem={item} />
              </WebUI.CarouselItem>
            ))}
          </WebUI.CarouselContent>
          {sortedSpotlightItems.length > 1 && (
            <>
              <WebUI.CarouselStepper className="-bottom-15 h-5 [&_>_.CarouselStep]:min-w-5 [&_>_.CarouselStep]:max-w-5" />
              {media.lg && (
                <>
                  <WebUI.CarouselNext className="-right-20">
                    <WebUI.PhosphorIcon
                      color={brandKitColors.primary_color}
                      height={30}
                      width={30}
                      icon="arrow-fat-right-fill"
                    />
                  </WebUI.CarouselNext>
                  <WebUI.CarouselPrevious className="-left-20">
                    <WebUI.PhosphorIcon
                      color={brandKitColors.primary_color}
                      height={30}
                      width={30}
                      icon="arrow-fat-left-fill"
                    />
                  </WebUI.CarouselPrevious>
                </>
              )}
            </>
          )}
        </WebUI.Carousel>
      ) : (
        sortedSpotlightItems.map((item, idx) => (
          <React.Fragment key={idx}>
            {idx > 0 && (
              <WebUI.Separator className="!border-b-2" color="#FFFFFF" />
            )}
            <SpotlightItem spotlightItem={item} />
          </React.Fragment>
        ))
      )}
    </WebUI.VStack>
  )
}

// MARK: - SpotlightItem

interface SpotlightItemProps {
  spotlightItem: Api.GroupPageCollectionSpotlightItem
}

const SpotlightItem: React.FC<SpotlightItemProps> = ({spotlightItem}) => {
  const media = WebUI.useMedia()

  return (
    <WebUI.VStack className="items-start justify-between gap-8 sm:items-center md:flex-row">
      <WebUI.VStack className="gap-6">
        <WebUI.Heading className="font-accentAlt text-h-6 sm:text-h-4">
          {spotlightItem.headline}
        </WebUI.Heading>
        <WebUI.Text className="font-light sm:text-ds-md">
          {spotlightItem.description}
        </WebUI.Text>
        {media.md && (
          <WebUI.RoundedButton
            as={LinkButton}
            to={`/c/${spotlightItem.collection.slug}`}
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            className="self-start hover:bg-white"
          >
            {spotlightItem.button_text}
          </WebUI.RoundedButton>
        )}
      </WebUI.VStack>
      <SharpImage
        className="shrink-0 rounded-[15px]"
        alt=""
        image={spotlightItem.image}
        ignoreSizeStyling
        height={295}
        width={530}
      />
      {!media.md && (
        <WebUI.RoundedButton
          className="max-w-full self-start overflow-hidden"
          as={LinkButton}
          to={`/c/${spotlightItem.collection.slug}`}
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          size="default"
        >
          {spotlightItem.button_text}
        </WebUI.RoundedButton>
      )}
    </WebUI.VStack>
  )
}

export default CollectionSpotlight
