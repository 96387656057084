import * as Util from '@cheddarup/util'

import {formatEmailStatus} from './email-status-helpers'

export const formatGiftCardStatus = (gift: Api.Withdrawal | null) => {
  if (!gift) {
    return 'unknown'
  }
  return gift.tango_card?.redemption_email
    ? formatEmailStatus(gift.tango_card?.redemption_email.status)
    : Util.capitalize(gift.status)
}
