import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageSectionMutation,
  useDeleteGroupPageSponsorMutation,
  useUpdateGroupPageSectionMutation,
  useUpdateGroupPageSponsorMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {SharpImage} from 'src/components/SharpImage'
import * as Util from '@cheddarup/util'
import {GroupPageSectionHeader} from '../components'
import {useUserSlug} from 'src/components/ManageRoleProvider'

interface SponsorFormValues {
  headline: string
  description: string
}

const SponsorsPage = () => {
  const navigate = useNavigate()
  const userSlug = useUserSlug()
  const {data: sponsorSection} = api.groupPageSections.detail.useQuery({
    pathParams: {sectionName: 'sponsor'},
  })

  const sortedSponsors = useMemo(
    () =>
      Util.sort(sponsorSection?.sponsor_items ?? []).asc((si) => si.position),
    [sponsorSection?.sponsor_items],
  )

  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()
  const updateGroupPageSponsorMutation = useUpdateGroupPageSponsorMutation()

  const formik = useFormik<SponsorFormValues>({
    enableReinitialize: true,
    initialValues: {
      headline: sponsorSection?.headline ?? '',
      description: sponsorSection?.description ?? '',
    },
    validationSchema: Yup.object().shape({
      headline: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const saveGroupPageSectionMutation = sponsorSection
        ? updateGroupPageSectionMutation
        : createGroupPageSectionMutation
      const savedSection = await saveGroupPageSectionMutation.mutateAsync({
        pathParams: {sectionName: 'sponsor'},
        body: {
          ...values,
          userSlug,
        },
      })
      return savedSection
    },
  })

  return (
    <WebUI.Modal
      aria-label="Group page sponsor form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-5xl"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.ModalHeader>
            <GroupPageSectionHeader
              subheading="Showcase sponsors by placing their logos and URLs on your
                  Group Page."
              quickTourSlideId="sponsors"
            >
              Sponsors Section
            </GroupPageSectionHeader>
          </WebUI.ModalHeader>
          <div className="flex grow flex-col gap-6 overflow-y-auto px-9 py-6">
            <form
              className="flex max-w-96 flex-col gap-6"
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <WebUI.FormField
                label="Headline"
                error={formik.errors.headline}
                required
              >
                <WebUI.Input
                  name="headline"
                  placeholder="Large headline text"
                  value={formik.values.headline}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField label="Description">
                <WebUI.Textarea
                  name="description"
                  placeholder="Smaller text under headline"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={3}
                />
              </WebUI.FormField>
            </form>
            <WebUI.Separator />
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <WebUI.Text>Add sponsors</WebUI.Text>
                <WebUI.Text className="font-light text-ds-sm">
                  Click and drag to reorder.
                </WebUI.Text>
              </div>
              <div className="flex flex-row flex-wrap gap-5">
                <WebUI.DragAndDrop
                  dragOverlayPortal={false}
                  modifiers={[WebUI.followMouseModifier]}
                  onDragEnd={(event) => {
                    if (!event.over) {
                      return
                    }
                    const oldOrder =
                      event.over.data.current?.sortable.items ?? []
                    const newOrder = WebUI.arrayMoveByValue(
                      oldOrder,
                      event.active.id,
                      event.over.id,
                    )
                    const sponsorId = event.active.id
                    const sponsorNewPos = newOrder.indexOf(sponsorId) + 1
                    updateGroupPageSponsorMutation.mutate({
                      pathParams: {id: sponsorId},
                      body: {position: sponsorNewPos},
                    })
                  }}
                >
                  <WebUI.SortableContext
                    items={sortedSponsors}
                    strategy={WebUI.rectSortingStrategy}
                  >
                    {({items: sIds}) =>
                      sIds.map((sId) => {
                        const s = sortedSponsors.find((s) => s.id === sId)
                        if (!s) {
                          return
                        }

                        return <SponsorCard id={sId} key={sId} sponsor={s} />
                      })
                    }
                  </WebUI.SortableContext>
                </WebUI.DragAndDrop>

                <AddSponsorCta
                  role="button"
                  onClick={async () => {
                    let savedSection = sponsorSection
                    if (!savedSection) {
                      savedSection = await formik.submitForm()
                    }
                    if (savedSection) {
                      navigate('add-sponsor')
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end border-t border-t-borderPrimary border-r-0 border-b-0 border-l-0 border-solid bg-natural-100 px-4 py-5">
            <WebUI.Button
              type="submit"
              variant="primary"
              size="large"
              className="w-48"
              loading={formik.isSubmitting}
              onClick={async () => {
                await formik.submitForm()
                dialog.hide()
              }}
            >
              Save
            </WebUI.Button>
          </div>
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: - SponsorCard

interface SponsorCardProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Pick<WebUI.SortableProps, 'id'>
  > {
  sponsor: Api.GroupPageSponsor
}

const SponsorCard: React.FC<SponsorCardProps> = ({
  sponsor,
  className,
  ...restProps
}) => {
  const deleteGroupPageSponsorMutation = useDeleteGroupPageSponsorMutation()

  return (
    <WebUI.Sortable
      className={WebUI.cn('h-48 w-full sm:w-56', className)}
      draggable={false}
      {...restProps}
    >
      {({dragListeners}) => (
        <WebUI.Card
          className="flex h-full w-full flex-col items-center gap-3 px-6 py-4"
          dragHandleVisible
          dragListeners={dragListeners}
          accessoryView={
            <WebUI.ActionGroup>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="x" />}
                execute={() =>
                  deleteGroupPageSponsorMutation.mutateAsync({
                    pathParams: {id: sponsor.id},
                  })
                }
              >
                Delete
              </WebUI.Action>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="pencil" />}
                as={LinkButton}
                to={`sponsors/${sponsor.id}`}
              >
                Edit
              </WebUI.Action>
            </WebUI.ActionGroup>
          }
        >
          <SharpImage
            image={sponsor.logo}
            width={120}
            height="auto"
            alt="logo"
          />
          <WebUI.Text className="mt-auto text-ds-sm">{sponsor.name}</WebUI.Text>
        </WebUI.Card>
      )}
    </WebUI.Sortable>
  )
}

// MARK: - AddSponsorCta

const AddSponsorCta = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.Panel
    className={WebUI.cn(
      'flex h-48 w-full flex-row items-center justify-center gap-6 px-7 py-6 sm:w-56',
      className,
    )}
    {...restProps}
  >
    <WebUI.IconButton
      size="default_alt"
      variant="default"
      className="h-15 w-15 rounded-full"
    >
      <WebUI.PhosphorIcon
        className="text-natural-100"
        icon="plus-bold"
        width={30}
        height={30}
      />
    </WebUI.IconButton>
  </WebUI.Panel>
)

export default SponsorsPage
