import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {Link} from 'src/components/Link'
import {TabTemplatesGrid} from 'src/components/TabTemplatesGrid'
import {StringParam, useQueryParam} from 'use-query-params'

const TemplatesPage = () => {
  const urlParams = useParams()
  const [searchTerm] = useQueryParam('q', StringParam)
  const [_orderBy, setOrderBy] = useQueryParam('order-by', StringParam)
  const media = WebUI.useMedia()

  const validOrderByValues: Api.TabTemplatesOrderBy[] = [
    'recentlyAdded',
    'mostUsed',
  ]
  const orderBy = validOrderByValues.includes(_orderBy ?? ('' as any))
    ? (_orderBy as Api.TabTemplatesOrderBy)
    : 'recentlyAdded'

  const templatesQuery = api.templates.list.useQuery(
    {
      queryParams: {
        orderBy: {
          key: orderBy,
        },
      },
    },
    {
      select: (templates) =>
        Util.sort(
          templates
            .filter(
              Util.fuzzyFilterIterator(searchTerm ?? '', {
                iterator: (template) => template.name,
              }),
            )
            .filter(
              (template) =>
                urlParams.filterType !== 'group-type' ||
                !urlParams.filterValue ||
                template.options.template.groups?.some(
                  (g) => g.type === urlParams.filterValue,
                ),
            )
            .filter(
              (template) =>
                urlParams.filterType !== 'category' ||
                !urlParams.filterValue ||
                template.options.template.categories?.some(
                  (c) => c.type === urlParams.filterValue,
                ),
            ),
        ).asc((template) => {
          if (
            !urlParams.filterValue ||
            (urlParams.filterType !== 'category' &&
              urlParams.filterType !== 'group-type')
          ) {
            return 0
          }

          const order = (
            template.options.template[
              urlParams.filterType === 'category' ? 'categories' : 'groups'
            ] as Array<Api.TabTemplateCategory | Api.TabTemplateGroup>
          ).find((catOrGr) => catOrGr.type === urlParams.filterValue)?.order

          return order ?? Number.MAX_SAFE_INTEGER
        }),
    },
  )

  const description =
    urlParams.filterValue &&
    (urlParams.filterType === 'group-type' ||
      urlParams.filterType === 'category')
      ? ((
          {
            'group-type': groupTypeToDescriptionMap,
            category: categoryToDescriptionMap,
          }[urlParams.filterType] as any
        )[urlParams.filterValue] as string | undefined)
      : null

  const categoryContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (media.sm) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    } else {
      categoryContainerRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [media])

  return (
    <WebUI.VStack
      ref={categoryContainerRef}
      className="items-center bg-natural-100 px-4 pt-12 pb-16"
    >
      <WebUI.VStack className="w-full max-w-[calc(1080px+2*theme(spacing.4))] gap-4">
        <Link
          variant="primary"
          to=".."
          iconBefore={<WebUI.PhosphorIcon icon="arrow-left" />}
        >
          View all
        </Link>

        {!!urlParams.filterValue && (
          <WebUI.VStack className="gap-1">
            <WebUI.Heading
              className="font-accentAlt text-ds-5xl leading-[54px]"
              as="h2"
            >
              {urlParams.filterValue}
            </WebUI.Heading>
            {!!description && (
              <WebUI.Text className="text-ds-md">{description}</WebUI.Text>
            )}
          </WebUI.VStack>
        )}

        <WebUI.VStack className="gap-6">
          <WebUI.HStack className="justify-end gap-3">
            <WebUI.HStack className="items-baseline gap-1 font-normal text-ds-sm">
              <span>Sort by:</span>
              <WebUI.DropdownSelectText
                value={orderBy}
                onValueChange={(newOrderBy) => {
                  if (newOrderBy != null) {
                    setOrderBy(newOrderBy)
                  }
                }}
              >
                <WebUI.DropdownSelectOption value="recentlyAdded">
                  Recently Added
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="mostUsed">
                  Most Used
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelectText>
            </WebUI.HStack>
          </WebUI.HStack>
          <TabTemplatesGrid
            loading={templatesQuery.isLoading}
            templates={templatesQuery.data ?? []}
          />
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – Helpers

const categoryToDescriptionMap: Record<Api.TemplateCollectionCategory, string> =
  {
    Fundraisers:
      'Elevate your next big cause with templates that make fundraising a walk in the park.',
    Events:
      'Our events and activities templates will get your party started -- minus the stress.',
    'Online Stores':
      'Move your merch like hotcakes with these online store templates.',
    'Dues & Fees':
      'Simplify collecting membership dues and other fees with these templates.',
    Registrations:
      'From fun runs to workshops, let these registration templates do the heavy lifting.',
    'Staff Picks': "We've seen them all and these are some of our favorites.",
    'Seasonal Collections':
      "From New Year's to Christmas, we've got templates for year-round fun.",
    'Group Gifts': 'Pooling resources for a group gift has never been easier.',
    'Camps & Classes':
      'Enrollments are a cinch with templates for your camps and classes.',
    'Group Travel':
      'Get on the road fast with templates that handle the details for your next group trip.',
    'Sign Ups':
      'From snack schedules to teacher conferences, create beautiful, clutter-free sign-ups.',
  }

const groupTypeToDescriptionMap: Record<Api.TabTemplateGroupType, string> = {
  'Schools & PTAs':
    'Easily manage fundraising, dues, events, and more with these templates for schools.',
  Scouts:
    "Simplify your troop's dues, events, and fundraising with these templates.",
  Sports: 'Get in the game faster with these templates for sports teams.',
  Nonprofits:
    'Jumpstart your fundraising with templates built to amplify your impact.',
  Clubs:
    'Manage club membership dues and events efficiently with our templates.',
  Reunions: 'Hassle-free reunion registrations, events, and t-shirt sales.',
  'Bands & Choir':
    'Hit the right note with these templates for concerts, trips, and fundraising.',
  Cheer: 'Flip over templates designed specifically for cheer teams.',
  'Fine Arts':
    'Showcase your talents with templates for performances and fundraising.',
  HOAs: 'Neighborhood governance is a walk in the park with templates for dues, events, and more.',
  Religious:
    'Simplify tithes, event registrations, and community outreach with these templates.',
  'Small Business':
    'Run your business with templates designed for small business owners.',
  'Greek Life':
    'Templates for sorority and fraternity formals, fundraising, and more.',
  'Friends & Family':
    'From group gifts to family reunions, templates make pooling resources simple.',
  Workplace:
    'Elevate office camaraderie with easy templates for social events and group gifts.',
}

export default TemplatesPage
