import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

import {
  deleteCollectionItemImage,
  uploadCollectionItemImage,
} from './file-uploads'

export function getItemType(item: Api.TabItem): Api.ItemType {
  if (item.options.itemSubType && item.amount_type !== 'open') {
    return item.options.itemSubType
  }
  if (item.options.recurring?.enabled) {
    return 'recurring'
  }

  return (
    {
      open: 'donation',
      fixed: 'fixed',
    } as const
  )[item.amount_type]
}

export const itemTypes: Api.ItemType[] = [
  'fixed',
  'donation',
  'recurring',
  'ticket',
]

export function parseItemType(candidate: string) {
  return itemTypes.find((it) => it === candidate) ?? 'fixed'
}

export interface LocalItemImage {
  id?: number | null
  contentType: string
  thumbnailCrop: Api.CropDetails | null
  image: Blob
}

export interface ListingLocalImage {
  uuid: string
  imageId: number | null
  localImage: {
    image: File
    thumbnail: {
      cropDetails: Api.CropDetails | null
    }
  } | null
}

export async function saveItemImages({
  tabId,
  item,
  images,
  listings = [],
}: {
  tabId: number
  item: Api.TabItem
  images: Array<LocalItemImage | null>
  listings?: ListingLocalImage[]
}) {
  const imagesToDelete = Util.differenceWith(
    item.images ?? [],
    images,
    (a, b) => a.id === b?.id,
  )
  const deleteImagePromises = imagesToDelete
    .filter((i): i is Api.S3Image & {id: number} => i.id != null)
    .map((image) =>
      deleteCollectionItemImage({
        id: image.id,
        collectionId: tabId,
        itemId: item.id,
      }),
    )

  const imagesToUpload = images
    .filter((image) => image?.id != null)
    .map((valueImage, idx) => ({...valueImage, order: idx}))
  const uploadImagePromises = imagesToUpload.map((image) =>
    uploadCollectionItemImage({
      tabId,
      itemId: item.id,
      imageId: image.id,
      image: image.image,
      thumbnail: {
        order: image.order,
        cropDetails: image.thumbnailCrop ?? {},
      },
    }),
  )

  await Promise.all(deleteImagePromises)
  const uploadedImages = await Promise.all(uploadImagePromises)

  const valuesListingsImages = listings.reduce<
    Array<[string[], NonNullable<ListingLocalImage['localImage']>]>
  >((acc, listing) => {
    if (
      !listing.localImage ||
      acc.some(([uuids]) => uuids.includes(listing.uuid))
    ) {
      return acc
    }
    const uuidsWithSameLocalImage = listings
      .filter(
        (l) =>
          l.localImage &&
          listing.localImage &&
          `${WebUI.getFileId(l.localImage.image)}-${Util.sort(
            Object.values(l.localImage.thumbnail?.cropDetails ?? {}),
          ).asc()}` ===
            `${WebUI.getFileId(listing.localImage.image)}-${Util.sort(
              Object.values(listing.localImage.thumbnail?.cropDetails ?? {}),
            ).asc()}`,
      )
      .map((l) => l.uuid)
    return [...acc, [uuidsWithSameLocalImage, listing.localImage]]
  }, [])

  const uploadListingImagePromises = valuesListingsImages.map(
    ([, image], idx) =>
      uploadCollectionItemImage({
        tabId,
        itemId: item.id,
        image: image.image,
        thumbnail: {
          order: images.length + idx,
          cropDetails: image.thumbnail.cropDetails ?? {},
        },
      }),
  )
  const uploadedListingImages = await Promise.all(uploadListingImagePromises)
  const uploadedListingsImageIdsEntries = valuesListingsImages.map(
    ([listingUuids], idx) =>
      [listingUuids, uploadedListingImages[idx].id] as [string[], string],
  )
  const updatedListingImageIdsEntries = imagesToUpload
    .map((image, idx) => {
      const listing = listings.find((l) => l.imageId === image.id)

      return listing
        ? ([[listing.uuid], uploadedImages[idx].id] as [string[], string])
        : null
    })
    .filter((image) => !!image)

  return {
    updatedListingImageIdsEntries,
    uploadedListingsImageIdsEntries,
  }
}
