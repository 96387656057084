import {Helmet} from 'react-helmet-async'

export interface GroupPageHelmetProps {
  groupPage?: Api.NewGroupPage
}

const GroupPageHelmet = ({groupPage}: GroupPageHelmetProps) => {
  const tagLine = groupPage?.sections.top_banner?.tagline
  const groupName = groupPage?.sections.top_banner?.headline
    ? `${groupPage.sections.top_banner.headline} - Cheddar Up`
    : 'Cheddar Up'

  return (
    <Helmet defer={false}>
      {!!tagLine && [
        <meta key="description" property="description" content={tagLine} />,
        <meta
          key="og:description"
          property="og:description"
          content={tagLine}
        />,
        <meta
          key="twitter:description"
          property="twitter:description"
          content={tagLine}
        />,
      ]}
      <meta itemProp="name" content={groupName} />
      <title>{groupName}</title>
      <meta property="og:title" content={groupName} />
      <meta property="twitter:title" content={groupName} />
    </Helmet>
  )
}

export default GroupPageHelmet
