import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  CalendarDate,
  Time,
  getLocalTimeZone,
  today,
} from '@internationalized/date'

import type {SpotFormFormik} from './SpotFormModal'

export type TimeSlotsRepeatType = 'none' | 'daily' | 'weekly' | 'monthly'

export interface TimeSlotsFormTimeValue {
  start: Time | null
  end: Time | null
  available_quantity: string
}

export interface TimeSlotsFormValues {
  uuid: string
  repeatType: TimeSlotsRepeatType
  date: CalendarDate | null
  endDate: CalendarDate | null
  times: TimeSlotsFormTimeValue[]
}

export interface TimeSlotsFormProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: SpotFormFormik
  timeSlot: TimeSlotsFormValues
}

export const TimeSlotsForm: React.FC<TimeSlotsFormProps> = ({
  formik,
  timeSlot,
  className,
  ...restProps
}) => {
  const timeSlots = formik.values.time_slots as TimeSlotsFormValues[]
  const idx = timeSlots.findIndex((ts) => ts.uuid === timeSlot.uuid)
  const errors = formik.errors.time_slots?.[idx] as Record<string, any>
  return (
    <WebUI.Panel
      className={WebUI.cn('relative border-none bg-gray100 p-7', className)}
      {...restProps}
    >
      {idx > 0 && (
        <WebUI.IconButton
          className="absolute top-2 right-2 text-ds-lg"
          size="default_alt"
          variant="link"
          onClick={() =>
            formik.setFieldValue(
              'time_slots',
              timeSlots.filter((ts) => ts.uuid !== timeSlot.uuid),
            )
          }
        >
          <WebUI.PhosphorIcon icon="x-circle-fill" />
        </WebUI.IconButton>
      )}
      <WebUI.VStack className="gap-4">
        <WebUI.FormFieldGroup className="gap-3">
          <WebUI.FormField
            size="compact"
            required
            label="Date"
            error={errors?.date}
          >
            <WebUI.DatePicker
              size="compact"
              minValue={today(getLocalTimeZone())}
              value={timeSlot.date}
              onValueChange={(newCalendarDate) =>
                formik.setFieldValue(`time_slots[${idx}].date`, newCalendarDate)
              }
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <WebUI.FormField
            size="compact"
            label="Repeats"
            error={errors?.repeatType}
          >
            <WebUI.DropdownSelect<TimeSlotsRepeatType>
              name={`time_slots[${idx}].repeatType`}
              size="compact"
              value={timeSlot.repeatType}
              onValueChange={(newRepeatType) => {
                if (newRepeatType) {
                  formik.setFieldValue(
                    `time_slots[${idx}].repeatType`,
                    newRepeatType,
                  )
                }
              }}
              onBlur={formik.handleBlur}
            >
              <WebUI.DropdownSelectOption value="none">
                Does Not Repeat
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="daily">
                Daily
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="weekly">
                {`Weekly${
                  timeSlot.date
                    ? ` on ${Util.formatDate(timeSlot.date, 'EEEE')}`
                    : ''
                }`}
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="monthly">
                {`Monthly${
                  timeSlot.date
                    ? ` on the ${Util.formatDate(timeSlot.date, 'do')}`
                    : ''
                }`}
              </WebUI.DropdownSelectOption>
            </WebUI.DropdownSelect>
          </WebUI.FormField>
          {timeSlot.repeatType !== 'none' && (
            <WebUI.FormField
              size="compact"
              label="End Repeat"
              error={errors?.endDate}
            >
              <WebUI.DatePicker
                size="compact"
                disabled={!timeSlot.date}
                minValue={timeSlot.date ?? today(getLocalTimeZone())}
                value={timeSlot.endDate}
                onValueChange={(newISO) =>
                  formik.setFieldValue(`time_slots[${idx}].endDate`, newISO)
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          )}
        </WebUI.FormFieldGroup>

        <WebUI.Separator className="-mx-7 !border-2" variant="light" />

        {timeSlot.times.map((t, index) => (
          <WebUI.FormFieldGroup
            key={String(index)}
            className="gap-3 [&_.FormField]:max-w-[140px]"
          >
            <WebUI.FormField
              size="compact"
              label="Start Time"
              error={errors?.times?.[index]?.start}
            >
              <WebUI.TimeInput
                size="compact"
                value={t.start}
                onValueChange={(newStartTime) =>
                  formik.setFieldValue(
                    `time_slots[${idx}].times[${index}].start`,
                    newStartTime,
                  )
                }
              />
            </WebUI.FormField>
            <WebUI.FormField
              size="compact"
              label="End Time"
              error={errors?.times?.[index]?.end}
            >
              <WebUI.TimeInput
                size="compact"
                value={t.end}
                onValueChange={(newEndTime) =>
                  formik.setFieldValue(
                    `time_slots[${idx}].times[${index}].end`,
                    newEndTime,
                  )
                }
              />
            </WebUI.FormField>
            <WebUI.FormField
              size="compact"
              label="Available Spots"
              error={errors?.times?.[index]?.available_quantity}
            >
              <WebUI.NumberInput
                name={`time_slots[${idx}].times[${index}].available_quantity`}
                size="compact"
                placeholder="Qty."
                value={t.available_quantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>

            {index > 0 && (
              <WebUI.FormField
                className="!h-[65px] relative flex-0 self-stretch"
                size="compact"
              >
                <WebUI.IconButton
                  className="-translate-y-1/2 absolute top-[calc(50%+29px/2)] h-9 w-auto text-ds-md text-gray400"
                  size="default_alt"
                  onClick={() =>
                    formik.setFieldValue(
                      `time_slots[${idx}].times`,
                      timeSlot.times.filter((_, i) => i !== index),
                    )
                  }
                >
                  <WebUI.PhosphorIcon icon="x-circle-fill" />
                </WebUI.IconButton>
              </WebUI.FormField>
            )}
          </WebUI.FormFieldGroup>
        ))}
        <WebUI.Button
          className="text-ds-sm"
          variant="link"
          onClick={() =>
            formik.setFieldValue(`time_slots[${idx}].times`, [
              ...timeSlot.times,
              {
                start: null,
                end: null,
                available_quantity: '',
              },
            ])
          }
        >
          Add time
        </WebUI.Button>
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

export interface TransientTimeSlot
  extends Omit<
    Api.TimeSlot,
    | 'id'
    | 'name'
    | 'position'
    | 'payment_items'
    | 'errors'
    | 'active_participant_count'
  > {}

export function makeTransientTimeSlot(
  timeSlotsValues: TimeSlotsFormValues,
): TransientTimeSlot[] {
  if (
    !timeSlotsValues.date ||
    (timeSlotsValues.repeatType !== 'none' && !timeSlotsValues.endDate)
  ) {
    return []
  }

  const times = timeSlotsValues.times.filter(
    (t): t is Util.SetNonNullable<typeof t, 'start' | 'end'> =>
      !!t.start && !!t.end,
  )

  const startDate = timeSlotsValues.date.toDate(getLocalTimeZone())
  const endDate =
    timeSlotsValues.repeatType === 'none'
      ? timeSlotsValues.date.toDate(getLocalTimeZone())
      : timeSlotsValues.endDate?.toDate(getLocalTimeZone()) ?? null
  const {repeatNumber, addFn} = getRepeatNumber(
    startDate,
    endDate,
    timeSlotsValues.repeatType,
  )

  return times.flatMap((t) =>
    Array.from({length: repeatNumber}).map((_, idx) => {
      const startTime = Util.setDate(addFn(startDate, idx), {
        hours: t.start.hour,
        minutes: t.start.minute,
        seconds: t.start.second,
      }).toISOString()
      const endTime = Util.setDate(addFn(startDate, idx), {
        hours: t.end.hour,
        minutes: t.end.minute,
        seconds: t.end.second,
      }).toISOString()

      return {
        options: {
          startTime,
          endTime,
        },
        available_quantity:
          t.available_quantity === '' ? null : Number(t.available_quantity),
      }
    }),
  )
}

function getRepeatNumber(
  startDate: Date,
  endDate: Date | null,
  repeatType: TimeSlotsRepeatType,
) {
  switch (repeatType) {
    case 'daily':
      return {
        // biome-ignore lint/style/noNonNullAssertion:
        repeatNumber: Util.differenceInDays(endDate!, startDate),
        addFn: Util.addDays,
      }
    case 'weekly':
      return {
        // biome-ignore lint/style/noNonNullAssertion:
        repeatNumber: Util.differenceInWeeks(endDate!, startDate),
        addFn: Util.addWeeks,
      }
    case 'monthly':
      return {
        // biome-ignore lint/style/noNonNullAssertion:
        repeatNumber: Util.differenceInMonths(endDate!, startDate),
        addFn: Util.addMonths,
      }
    case 'none':
      return {
        repeatNumber: 1,
        addFn: (date: Date, ..._args: any[]) => date,
      }
  }
}
