import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {RecurringOptionsAnnotation} from 'src/components'

import RecurringOptionsInput from './RecurringOptionsInput'
import RecurringOptionsStartField from './RecurringOptionsStartField'
import type {RecurringItemFormFormik} from '../../../containers/RecurringItemForm/RecurringItemForm'

export interface RecurringPaymentFieldProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: RecurringItemFormFormik
}

const RecurringPaymentField = ({
  formik,
  className,
  ...restProps
}: RecurringPaymentFieldProps) => (
  <WebUI.VStack
    className={WebUI.cn('gap-3 overflow-x-auto', className)}
    {...restProps}
  >
    <WebUI.VStack
      className="relative w-fit border border-natural-70 text-ds-sm [&_>_*_+_*]:border-natural-70 [&_>_*_+_*]:border-t [&_>_.Stack_>_*_+_*]:border-natural-70 [&_>_.Stack_>_*_+_*]:border-l"
      {...restProps}
    >
      <WebUI.HStack>
        <RecurrintOptionsFieldTitle>
          Amount charged each cycle
        </RecurrintOptionsFieldTitle>
        <RecurringOptionsAmountInfoField className="grow" formik={formik} />
      </WebUI.HStack>
      <WebUI.HStack>
        <RecurrintOptionsFieldTitle>
          Repeat payment every
        </RecurrintOptionsFieldTitle>
        <RecurringOptionsRepeatIntervalField className="grow" formik={formik} />
      </WebUI.HStack>
      <WebUI.HStack>
        <RecurrintOptionsFieldTitle>
          Payment happens on
        </RecurrintOptionsFieldTitle>
        <RecurringOptionsStartField className="grow" formik={formik} />
      </WebUI.HStack>
      <WebUI.HStack>
        <RecurrintOptionsFieldTitle>Payments end</RecurrintOptionsFieldTitle>
        <RecurringOptionsEndsField className="grow" formik={formik} />
      </WebUI.HStack>
    </WebUI.VStack>
    <RecurringOptionsAnnotation
      recurringOptions={formik.values.options.recurring.options}
    />
  </WebUI.VStack>
)

// MARK: – RecurrintOptionsFieldTitle

const RecurrintOptionsFieldTitle = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.HStack
    className={WebUI.cn(
      'w-[234px] shrink-0 items-center bg-gray100 px-4 py-2 font-bold text-gray800 leading-none',
      className,
    )}
    {...restProps}
  />
)

// MARK: – RecurringOptionsAmountInfoField

interface RecurringOptionsAmountInfoFieldProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  formik: RecurringItemFormFormik
}

const RecurringOptionsAmountInfoField = ({
  formik,
  ...restProps
}: RecurringOptionsAmountInfoFieldProps) => (
  <RecurringOptionsInput
    hideTextInput={formik.values.amount_type !== 'fixed'}
    typeOptions={[
      {
        label: 'Exact amount',
        value: 'fixed',
      },
      {
        label: 'Any amount',
        value: 'open',
      },
    ]}
    textInputPlaceholder="$0"
    amountInput
    value={{
      type: formik.values.amount_type,
      text: formik.values.amount,
    }}
    onChange={(newValue) => {
      const fixedAmountTypeSelected =
        newValue.type === 'fixed' && formik.values.amount_type !== 'fixed'
      formik.setFieldValue('amount_type', newValue.type)
      formik.setFieldValue(
        'amount',
        fixedAmountTypeSelected
          ? ''
          : newValue.text
            ? normalizeAmount(newValue.text)
            : '',
      )
    }}
    {...restProps}
  />
)

// MARK: – RecurringOptionsEndsField

interface RecurringOptionsEndsFieldProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  formik: RecurringItemFormFormik
}

const RecurringOptionsEndsField = ({
  formik,
  ...restProps
}: RecurringOptionsEndsFieldProps) => {
  const ends = formik.values.options.recurring.options.ends
  return (
    <RecurringOptionsInput
      inputAriaInvalid={
        !!formik.errors.options?.recurring?.options?.ends?.payment_count
      }
      hideTextInput={ends.type !== 'payment_count'}
      typeOptions={[
        {
          label: 'never',
          value: 'never',
        },
        {
          label: 'after this many payments',
          value: 'payment_count',
        },
      ]}
      value={{
        type: ends.type,
        text: ends.payment_count,
      }}
      onChange={(v) => {
        const paymentCount = v.text ? Number.parseInt(v.text, 10) : ''
        if (!Number.isNaN(paymentCount)) {
          formik.setFieldValue('options.recurring.options.ends', {
            type: v.type,
            payment_count: v.type === 'payment_count' ? paymentCount : null,
          })
        }
      }}
      {...restProps}
    />
  )
}

// MARK: – RecurringOptionsRepeatIntervalField

interface RecurringOptionsRepeatIntervalFieldProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  formik: RecurringItemFormFormik
}

const RecurringOptionsRepeatIntervalField = ({
  formik,
  ...restProps
}: RecurringOptionsRepeatIntervalFieldProps) => {
  const options = formik.values.options.recurring.options
  const repeatInterval = options.repeatInterval
  const valueParsed = (() => {
    const duration = Util.parseDuration(repeatInterval)
    return {
      period: duration.months ? 'month' : 'week',
      count: duration.months || duration.days / 7,
    }
  })()
  return (
    <RecurringOptionsInput
      reversed
      typeOptions={[
        {
          label: 'week(s)',
          value: 'week',
        },
        {
          label: 'month(s)',
          value: 'month',
        },
      ]}
      value={{
        type: valueParsed.period,
        text: valueParsed.count === 0 ? '' : String(valueParsed.count),
      }}
      onChange={(newValue) => {
        const _count = Number.parseInt(newValue.text || '0', 10)
        const count = Number.isNaN(_count) ? 0 : _count

        const startType =
          valueParsed.period === newValue.type
            ? options.start.type
            : 'first_payment'

        formik.setFieldValue('options.recurring.options.start.type', startType)
        formik.setFieldValue(
          'options.recurring.options.start.date',
          startType === 'date' ? options.start.date : undefined,
        )
        formik.setFieldValue(
          'options.recurring.options.repeatInterval',
          Util.formatISODuration(
            newValue.type === 'month' ? {months: count} : {days: count * 7},
          ),
        )
      }}
      {...restProps}
    />
  )
}

// MARK: – Helpers

const normalizeAmount = (value: string | number) => {
  const val = value.toString().replace(/[^\d.]/g, '')
  const numeral = Number.parseFloat(val)
  const fixedString = numeral.toFixed(2)
  const fixed = Number.parseFloat(fixedString)
  if (numeral === fixed || Number.isNaN(numeral)) {
    return val
  }

  return fixedString
}

export default RecurringPaymentField
