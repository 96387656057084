import {z} from '@cheddarup/util'

import {creditCardSchema} from './payment-methods'
import {tabShippingOptionsSchema} from './tabs'

export const paymentShipmentServiceSchema = z.enum([
  'First',
  'Express',
  'Priority',
])

export const shipmentRateSchema = z.object({
  id: z.string(),
  service: paymentShipmentServiceSchema,
  rate: z.string(),
  charge: z.number(),
})

export const paymentShipmentTrackingSchema = z.object({
  code: z.string(),
})

export const paymentShipmentOptionsSchema = z.object({
  delivery_confirmation: z.literal('SIGNATURE'),
})

export const paymentShippingInfoSchema = z.object({
  charge: z.number().nullish(),
  shippingMethod: z.enum(['toMe', 'localPickup']).nullable(),
  currentOptions: tabShippingOptionsSchema.partial(),
  shipTo: z.object({
    name: z.string().optional(),
    street1: z.string().optional(),
    address: z.string().nullish(),
    phone: z.string().nullish(),
    email: z.string().nullish(),
    city: z.string().optional(),
    state: z.string().optional(),
    country: z.string().optional(),
    zip: z.string().optional(),
  }),
})

export const paymentShipmentSchema = z.object({
  purchased: z.boolean(),
  shipTo: paymentShippingInfoSchema.shape.shipTo,
  rates: shipmentRateSchema.array().optional(),
  service: paymentShipmentServiceSchema.optional(),
  label: z.object({url: z.string()}).optional(),
  tracking: paymentShipmentTrackingSchema.optional(),
  selected_rate: shipmentRateSchema.optional(),
  options: paymentShipmentOptionsSchema.optional(),
  paymentMethod: creditCardSchema.optional(),
  charge: z.number().nullish(),
})

declare global {
  namespace Api {
    type PaymentShippingInfo = z.infer<typeof paymentShippingInfoSchema>
    type PaymentShipment = z.infer<typeof paymentShipmentSchema>
    type PaymentShipmentOptions = z.infer<typeof paymentShipmentOptionsSchema>
    type PaymentShipmentTracking = z.infer<typeof paymentShipmentTrackingSchema>
    type ShipmentRate = z.infer<typeof shipmentRateSchema>
    type PaymentShipmentService = z.infer<typeof paymentShipmentServiceSchema>
  }
}
