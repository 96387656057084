import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {usePayerNavigateToNextSegment} from '../utils/payer-navigation-utils'

export const PayerContinueButton: React.FC<
  WebUI.ButtonProps & React.ComponentPropsWithoutRef<'button'>
> = ({
  variant = 'primary',
  roundness = 'capsule',
  onClick,
  children = 'Continue',
  ...restProps
}) => {
  const navigateToNextSegment = usePayerNavigateToNextSegment()

  return (
    <WebUI.Button
      variant={variant}
      roundness={roundness}
      onClick={(event) => {
        onClick?.(event)

        if (!event.defaultPrevented) {
          navigateToNextSegment()
        }
      }}
      {...restProps}
    >
      {children}
    </WebUI.Button>
  )
}
