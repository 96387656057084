import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo} from 'react'
import {
  api,
  useDeleteTabAttachmentMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {useCroppedImage} from 'src/hooks/useCroppedImage'

import {CollectionLogoUploadPrompt} from './CollectionLogoUploadPrompt'
import {FolderSelectField} from './FolderSelectField'
import {ImagePanel} from './ImagePanel'
import type {CollectionFormFormik} from './CollectionDetailsPage'

export interface CollectionFormProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection?: Api.TabDetailed
  formik: CollectionFormFormik
}

export const CollectionForm = ({
  collection,
  formik,
  className,
  ...restProps
}: CollectionFormProps) => {
  const {data: session} = api.auth.session.useQuery()
  const updateCollectionMutation = useUpdateTabMutation()
  const uploadedLogoWithCrop = useMemo(
    () =>
      formik.values.uploadedLogo
        ? {
            image: formik.values.uploadedLogo,
            crop: formik.values.uploadedLogoCrop,
          }
        : null,
    [formik.values.uploadedLogo, formik.values.uploadedLogoCrop],
  )
  const localUploadedLogoSrc = useCroppedImage(uploadedLogoWithCrop)
  const logoSrc =
    localUploadedLogoSrc ??
    (collection?.logo ? ImagesUtils.getCroppedImageUrl(collection.logo) : null)

  return (
    <div className={WebUI.cn('flex flex-col gap-5', className)} {...restProps}>
      <WebUI.Panel className="flex flex-0 flex-col gap-4 p-6">
        <WebUI.Text className="text-black text-ds-md">Banner</WebUI.Text>
        <ImagePanel formik={formik} collection={collection} />
      </WebUI.Panel>

      <WebUI.Panel className="flex flex-0 flex-col gap-4 p-6">
        <WebUI.Text className="mb-1 text-black text-ds-md">
          Main Details
        </WebUI.Text>
        <WebUI.FormFieldGroup>
          <WebUI.FormField
            className="flex-[3_0_auto]"
            required
            label="Title"
            error={formik.errors.name}
          >
            <WebUI.Input
              name="name"
              placeholder="Give your collection page a title"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <FolderSelectField
            className="flex-[2_0_auto]"
            name="folderId"
            collection={collection}
            error={formik.errors.folderId}
            value={formik.values.folderId}
            onValueChange={(newFolderId) =>
              formik.setFieldValue('folderId', newFolderId)
            }
            onOwnerIdChange={(newOwnerId) =>
              formik.setFieldValue('ownerId', newOwnerId)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormFieldGroup>

        <div className="flex flex-col gap-4 sm:flex-row">
          <WebUI.FormField
            className="flex-[3_0_0px]"
            error={formik.errors.description}
          >
            <WebUI.RichTextEditor
              key={formik.initialValues.description}
              className="min-h-[240px] text-ds-base"
              name="description"
              placeholder="Add a description"
              initialMarkdownValue={formik.values.description}
              onMarkdownValueChange={(newDescription) =>
                formik.setFieldValue('description', newDescription)
              }
            >
              <WebUI.RichTextEditorToolbar rootClassName="-order-1" />
            </WebUI.RichTextEditor>
          </WebUI.FormField>

          {!!session?.partner_admin?.org?.id && (
            <CollectionLogoUploadPrompt
              disclosure={
                <WebUI.DialogDisclosure
                  className="max-w-[370px] flex-[2_0_0px]"
                  orientation="vertical"
                  size="large"
                  variant="outlined"
                >
                  {logoSrc ? (
                    <>
                      <img
                        className="max-w-full"
                        alt="Org logo"
                        src={logoSrc}
                      />
                      <WebUI.IconButton
                        className="absolute top-3 right-3 text-ds-lg"
                        size="default_alt"
                        variant="secondary"
                        onClick={(event) => {
                          event.preventDefault()
                          if (formik.values.uploadedLogo) {
                            formik.setValues((prevValues) => ({
                              ...prevValues,
                              uploadedLogo: null,
                              uploadedLogoCrop: null,
                            }))
                          } else if (collection) {
                            updateCollectionMutation.mutate({
                              pathParams: {
                                tabId: collection.id,
                              },
                              body: {logo_id: null},
                            })
                          }
                        }}
                      >
                        <WebUI.PhosphorIcon icon="x" />
                      </WebUI.IconButton>
                    </>
                  ) : (
                    <AddImageIconText>Add Logo</AddImageIconText>
                  )}
                </WebUI.DialogDisclosure>
              }
              onLogoAndCropSubmit={(logoImage, logoCrop) =>
                formik.setValues((prevValues) => ({
                  ...prevValues,
                  uploadedLogo: logoImage,
                  uploadedLogoCrop: logoCrop,
                }))
              }
            />
          )}
        </div>
        <WebUI.FileUploader
          onDropAccepted={(newFiles) => {
            formik.setValues((prevValues) => ({
              ...prevValues,
              attachments: [...prevValues.attachments, ...newFiles],
            }))
          }}
        >
          <WebUI.FileUploaderInput />
          <WebUI.FileUploaderButton
            className="self-start [&_>_.Button-iconBefore]:text-ds-base"
            size="compact"
            variant="outlined"
            iconBefore={<WebUI.PhosphorIcon icon="paperclip" />}
          >
            Add Attachment
          </WebUI.FileUploaderButton>
        </WebUI.FileUploader>

        <AttachmentList formik={formik} collection={collection} />
      </WebUI.Panel>
    </div>
  )
}

// MARK: – AttachmentList

interface AttachmentListProps extends React.ComponentPropsWithoutRef<'div'> {
  collection?: Api.TabDetailed
  formik: CollectionFormFormik
}

const AttachmentList = ({formik, collection}: AttachmentListProps) => {
  const deleteAttachmentMutation = useDeleteTabAttachmentMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <>
      {formik.values.attachments.length > 0 ? (
        <WebUI.HStack className="inline-flex flex-wrap gap-2 *:mr-2 *:mb-2 *:ml-0">
          {formik.values.attachments.map((attachment) => (
            <WebUI.Tag
              key={'id' in attachment ? attachment.id : attachment.lastModified}
              className="text-ds-xs"
              onClear={async () => {
                try {
                  formik.setValues((prevValues) => ({
                    ...prevValues,
                    attachments: prevValues.attachments.filter(
                      (a) => a !== attachment,
                    ),
                  }))

                  if ('id' in attachment && collection) {
                    await deleteAttachmentMutation.mutateAsync({
                      pathParams: {
                        tabId: collection.id,
                        attachmentId: attachment.id,
                      },
                    })
                  }
                } catch (err: any) {
                  growlActions.show('error', {
                    title: 'Error',
                    body: err.message,
                  })
                }
              }}
            >
              {'id' in attachment ? (
                <WebUI.Anchor
                  className="max-w-[180px] font-light text-ds-xs text-gray800"
                  href={attachment.file_name.url}
                >
                  {Util.getFileNameFromUrl(attachment.file_name.url)}
                </WebUI.Anchor>
              ) : (
                attachment.name
              )}
            </WebUI.Tag>
          ))}
        </WebUI.HStack>
      ) : null}
    </>
  )
}

// MARK: – AddImageIconText

const AddImageIconText = ({
  className,
  children = 'Add a banner image',
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div
    className={WebUI.cn(
      'flex flex-col items-center justify-center gap-2 text-center text-gray400',
      className,
    )}
    {...restProps}
  >
    <WebUI.PhosphorIcon className="text-ds-5xl sm:text-ds-6xl" icon="camera" />
    <p className="whitespace-nowrap sm:text-ds-md">{children}</p>
  </div>
)
