import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik, useUpdateEffect} from '@cheddarup/react-util'
import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParam,
} from 'use-query-params'
import * as Util from '@cheddarup/util'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  api,
  useCreateMessageMutation,
  useCreateReminderMutation,
  useSendTestMessageMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {RecipientsModal} from 'src/components/RecipientsModal/RecipientsModal'
import {CollectionCombobox} from 'src/components/CollectionCombobox'
import {
  uploadBannerImage,
  uploadMessageAttachment,
} from 'src/helpers/file-uploads'
import {shareEventTracking} from 'src/helpers/analytics'
import {
  useManagerRoleId,
  useManagerRoleQuery,
} from 'src/components/ManageRoleProvider'
import {useCroppedImage} from 'src/hooks/useCroppedImage'
import {CalendarDate, getLocalTimeZone, today} from '@internationalized/date'
import {yupCalendarDateSchema} from 'src/helpers/YupInternationalizedDateSchema'
import {bannedWordIncludedIn} from 'src/helpers/inputHelpers'

import ImageUploadPage from '../../../media/ImageUploadPage'
import ImageCropPage from '../../../media/ImageCropPage'
import ImageAlbumPage from '../../../media/ImageAlbumPage'

export interface SendMessageFormValues {
  collectionId: number | null | undefined
  messageType: Api.MessageType | 'reminder'
  from: string
  subject: string
  headerUrl: string | null
  uploadedImage: Blob | null
  uploadedImageCrop: Api.CropDetails | null
  message: string
  includeLink: boolean
  linkText: string
  recipients: Api.Recipient[]
  remindersStartAt: CalendarDate | null
  remindersStopAt: CalendarDate | null
  repeatSeconds: number | null
  remindersTo: Api.MessageRemindersTo
  attachments: File[]
}

export interface SendMessageFormProps
  extends React.ComponentPropsWithoutRef<'form'> {
  initialValues?: SendMessageFormValues
}

export const SendMessageForm = ({
  initialValues,
  className,
  ...restProps
}: SendMessageFormProps) => {
  const urlParams = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const messageEditorRef = useRef<MessageEditorInstance>(null)
  const [messageType] = useQueryParam('messageType', StringParam)
  const [isGroupPage] = useQueryParam('groupPage', BooleanParam)
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false)
  const [messageIdToDuplicate] = useQueryParam(
    'messageIdToDuplicate',
    NumberParam,
  )
  const [messageToDuplicateTabId] = useQueryParam(
    'messageToDuplicateTabId',
    NumberParam,
  )
  const [to] = useQueryParam('to', ObjectParam)
  const [managerRoleId] = useManagerRoleId()
  const [managerRoleQuery] = useManagerRoleQuery()
  const sessionQuery = api.auth.session.useSuspenseQuery()
  const collectionsQuery = api.tabs.list.useQuery(undefined, {
    select: (collections) =>
      collections.filter((c) =>
        managerRoleId == null ? c.access.owner : c.user_id === managerRoleId,
      ),
  })
  const organizationNameQuery = api.publicTabs.home.useQuery(
    {
      pathParams: {
        tabId: managerRoleId ?? sessionQuery.data.user.id,
      },
    },
    {
      enabled: !!(managerRoleId || sessionQuery.data.user.slug),
      select: (home) => home.sections.top_banner?.headline,
    },
  )
  const messageQuery = api.messages.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        messageId: messageIdToDuplicate!,
      },
      queryParams: {
        tab_id: messageToDuplicateTabId,
      },
    },
    {
      enabled:
        messageIdToDuplicate != null &&
        !managerRoleQuery.isPending &&
        (!managerRoleQuery.data ||
          !!managerRoleQuery.data.permissions.address_book_and_message_center),
    },
  )
  const createMessageMutation = useCreateMessageMutation()
  const createReminderMutation = useCreateReminderMutation()
  const sendTestMessageMutation = useSendTestMessageMutation()
  const growlActions = WebUI.useGrowlActions()
  const [isSendingTestMessage, setIsSendingTestMessage] = useState(false)

  const initialRecipients: Api.Recipient[] = useMemo(
    () =>
      to
        ? Util.arrayFromObject(to ?? {}, (email, name) => ({
            uuid: Util.makeUuid(),
            email,
            name: name ?? '',
          }))
        : location.state?.recipients ?? [],
    [location.state?.recipients, to],
  )

  const session = sessionQuery.data

  const buildMessagePayload = async (values: SendMessageFormValues) => {
    let s3Image: Api.S3Image | null = null
    let attachments: any[] = []

    if (values.uploadedImage && values.uploadedImageCrop) {
      s3Image = await uploadBannerImage({
        image: values.uploadedImage,
        thumbnail: {
          cropDetails: values.uploadedImageCrop,
        },
      })
    }
    if (values.attachments.length > 0) {
      const uploadAttachmentPromises = values.attachments.map((a) =>
        uploadMessageAttachment(a, {
          tabId: formik.values.collectionId,
        }),
      )
      attachments = await Promise.all(uploadAttachmentPromises).then((as) =>
        as.filter((a) => a != null),
      )
    }

    return {
      tab_id: values.collectionId,
      message_type:
        values.messageType === 'reminder' ? 'message' : values.messageType,
      detail: {
        includeLink: values.includeLink,
        linkText: values.linkText,
        subject: values.subject,
        attachments,
        body: values.message,
        header: s3Image?.url ?? values.headerUrl ?? undefined,
        from: {
          name: values.from,
          email: session.user.email,
        },
        recipients: values.messageType === 'reminder' ? [] : values.recipients,
      },
    }
  }

  const createMessage = async (values: SendMessageFormValues) => {
    if (values.collectionId == null) {
      return null
    }

    const body = await buildMessagePayload(values)
    return createMessageMutation.mutateAsync({
      queryParams: {
        tab_id: values.collectionId,
      },
      body,
    })
  }

  const formik = useFormik<SendMessageFormValues>({
    validateOnMount: false,
    validateOnChange: validateAfterSubmit,
    validationSchema: Yup.object().shape({
      collectionId: isGroupPage
        ? Yup.mixed()
        : Yup.number().nullable().required('Required'),
      messageType: isGroupPage
        ? Yup.mixed()
        : Yup.string().required('Required'),
      from: Yup.string().required('Required'),
      subject: Yup.string().required('Required'),
      message: Yup.string()
        .required('Required')
        .test(
          'misses-banned-words',
          'Your description contains restricted language (mention of another payment platform). Please remove this language to send your message. If a workaround is entered, your collection may be at risk of being shut down, with payments auto refunded.',
          (v) => !bannedWordIncludedIn(v),
        ),
      linkText: Yup.string().when('includeLink', {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.nullable(),
      }),
      remindersStartAt: yupCalendarDateSchema().when('messageType', {
        is: 'reminder',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .typeError('Required')
            .required('Required')
            .min(today(getLocalTimeZone()), `Can't be earlier than today`),
        otherwise: (schema) => schema.nullable(),
      }),
      remindersStopAt: yupCalendarDateSchema().when('messageType', {
        is: 'reminder',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .typeError('Required')
            .required('Required')
            .when('remindersStartAt', (remindersStartAt, innerSchema) =>
              remindersStartAt instanceof CalendarDate
                ? innerSchema.min(
                    remindersStartAt,
                    `Can't be earlier than start date`,
                  )
                : innerSchema,
            ),
        otherwise: (schema) => schema.nullable(),
      }),
      repeatSeconds: Yup.number().when('messageType', {
        is: 'reminder',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
    initialValues: {
      collectionId:
        messageQuery.data?.tab_id ??
        (isGroupPage
          ? null
          : urlParams.collection
            ? Number(urlParams.collection)
            : undefined),
      messageType:
        messageQuery.data?.message_type ??
        (messageType
          ? (
              {
                message: 'message',
                invite: 'invite',
                reminder: 'reminder',
              } as const
            )[messageType] ?? 'message'
          : 'message'),
      from:
        messageQuery.data?.detail.from.name ?? session.user.display_name ?? '',
      subject: messageQuery.data?.detail.subject ?? '',
      message: messageQuery.data?.detail.body ?? '',
      headerUrl: messageQuery.data?.detail.header ?? null,
      uploadedImage: null,
      uploadedImageCrop: null,
      includeLink: messageQuery.data?.detail.includeLink ?? true,
      linkText:
        messageQuery.data?.detail.linkText ??
        (isGroupPage ? 'View Group Page' : 'View Collection'),
      recipients: initialRecipients,
      remindersStartAt: Util.parseCalendarDate(
        messageQuery.data?.child_reminder?.start_at ?? '',
      ),
      remindersStopAt: Util.parseCalendarDate(
        messageQuery.data?.child_reminder?.end_at ?? '',
      ),
      repeatSeconds: messageQuery.data?.child_reminder?.repeat_seconds ?? null,
      remindersTo: messageQuery.data?.child_reminder?.detail.to ?? 'everyone',
      attachments: [],
      ...initialValues,
    },
    onSubmit: async (values) => {
      try {
        if (values.collectionId === undefined) {
          return
        }

        if (
          values.messageType !== 'reminder' &&
          values.recipients.length === 0
        ) {
          growlActions.show('error', {
            title: 'Error!',
            body: 'Please select recipients.',
          })
          return
        }

        const message = await createMessage(values)

        if (!message) {
          return
        }

        if (values.messageType === 'reminder' && values.repeatSeconds) {
          await createReminderMutation.mutateAsync({
            pathParams: {
              messageId: message.id,
            },
            body: {
              recipients: values.remindersTo,
              start_at:
                values.remindersStartAt
                  ?.toDate(getLocalTimeZone())
                  .toISOString() ?? null,
              end_at:
                values.remindersStopAt
                  ?.toDate(getLocalTimeZone())
                  .toISOString() ?? null,
              repeat_seconds: values.repeatSeconds,
            },
          })
        }
        if (selectedCollection) {
          shareEventTracking(
            selectedCollection,
            'email',
            'collection link',
            values.recipients.length,
          )
        }
        formik.resetForm()
        messageEditorRef.current?.clearMessage()
        growlActions.show('success', {
          title: 'Success!',
          body: 'Email sent successfully.',
        })
      } catch {
        growlActions.show('error', {
          body: 'Something went wrong when sending your messages.',
          title: 'Oops!',
        })
      }
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    formik.resetForm()
  }, [messageQuery.data])

  const hasInvitesQuery = api.messages.list.useQuery(
    {
      queryParams: {
        message_type: 'invite',
        tab_id: formik.values.collectionId,
        per_page: 1,
      },
    },
    {
      enabled:
        !!formik.values.collectionId &&
        !managerRoleQuery.isPending &&
        (!managerRoleQuery.data ||
          !!managerRoleQuery.data.permissions.address_book_and_message_center),
      select: (res) => res.pagination.total > 0,
    },
  )

  const hasPayersQuery = api.tabMembers.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: formik.values.collectionId!,
      },
    },
    {
      select: (members) =>
        members.some((m) => m.payments && m.payments.length > 0),
    },
  )

  const selectedCollection = collectionsQuery.data?.find(
    (c) => c.id === Number(formik.values.collectionId),
  )

  const setFieldValueFormik = formik.setFieldValue
  useEffect(() => {
    if (
      formik.values.messageType === 'invite' &&
      !formik.values.uploadedImage &&
      selectedCollection?.featured_image
    ) {
      setFieldValueFormik(
        'headerUrl',
        selectedCollection.featured_image.edited_image_url ??
          selectedCollection.featured_image.url,
      )
    }
  }, [
    setFieldValueFormik,
    selectedCollection?.featured_image,
    formik.values.messageType,
    formik.values.uploadedImage,
  ])

  const [body, setBody] = useState<string | null>(null)
  const disableFormButton =
    isSendingTestMessage || formik.isSubmitting || !!formik.errors.message

  useUpdateEffect(() => {
    if (messageQuery.data?.detail.body) {
      setBody(messageQuery.data.detail.body)
    } else {
      setBody(null)
    }
  }, [messageQuery.data?.detail.body])

  return (
    <WebUI.Form
      className={WebUI.cn('[&_>_.Form-inner]:gap-5', className)}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      noValidate
      {...restProps}
    >
      <WebUI.VStack className="gap-4 p-7 *:max-w-screen-sm" as={WebUI.Panel}>
        {!isGroupPage && (
          <>
            <WebUI.Heading className="font-light text-ds-md" as="h2">
              Invite others to your collection, send a message or set up an
              automatic reminder
            </WebUI.Heading>
            <WebUI.FormFieldGroup>
              <WebUI.FormField
                required
                label="Select a collection"
                error={formik.errors.collectionId}
              >
                <CollectionCombobox
                  popoverClassName="max-w-[480px]"
                  inputSize="default"
                  placeholder="Select One"
                  collections={collectionsQuery.data ?? []}
                  selectedCollectionId={formik.values.collectionId ?? null}
                  onSelectedCollectionIdChange={(newCollectionId) => {
                    setBody(null)
                    formik.setFieldValue(
                      'collectionId',
                      newCollectionId || null,
                    )
                  }}
                />
              </WebUI.FormField>
              <WebUI.FormField
                required
                label="Message Type"
                error={formik.errors.messageType}
              >
                <WebUI.DropdownSelect
                  name="messageType"
                  value={formik.values.messageType}
                  onValueChange={(newMessageType) =>
                    formik.setFieldValue('messageType', newMessageType)
                  }
                  onBlur={formik.handleBlur}
                >
                  <WebUI.DropdownSelectOption value="message">
                    Message
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption
                    as={WebUI.Button}
                    value="reminder"
                    disabled={!hasPayersQuery.data && !hasInvitesQuery.data}
                  >
                    Automatic Reminder
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value="invite">
                    Invitation
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
              </WebUI.FormField>
            </WebUI.FormFieldGroup>
          </>
        )}
        {formik.values.messageType === 'reminder' && (
          <>
            <WebUI.FormFieldGroup>
              <WebUI.FormField
                label="Start Reminders"
                error={formik.errors.remindersStartAt}
                required
              >
                <WebUI.DatePicker
                  value={formik.values.remindersStartAt}
                  onValueChange={(newDate) =>
                    formik.setFieldValue('remindersStartAt', newDate)
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField
                label="Stop Reminders"
                error={formik.errors.remindersStopAt}
                required
              >
                <WebUI.DatePicker
                  value={formik.values.remindersStopAt}
                  onValueChange={(newDate) =>
                    formik.setFieldValue('remindersStopAt', newDate)
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </WebUI.FormFieldGroup>
            <WebUI.FormField
              className="sm:max-w-[312px]"
              label="Repeats"
              error={formik.errors.repeatSeconds}
              required
            >
              <WebUI.DropdownSelect
                name="repeatSeconds"
                value={formik.values.repeatSeconds ?? ''}
                onValueChange={(newRepeatSeconds) =>
                  formik.setFieldValue('repeatSeconds', newRepeatSeconds)
                }
                onBlur={formik.handleBlur}
              >
                <WebUI.DropdownSelectOption value="">
                  Select
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="604800">
                  Every Week
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="2592000">
                  Every Month
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="31536000">
                  Every Year
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelect>
            </WebUI.FormField>
          </>
        )}
        <WebUI.FormField required label="To:">
          {formik.values.messageType === 'reminder' ? (
            <WebUI.RadioGroup
              aria-label="Set reminder for"
              name="remindersTo"
              state={formik.values.remindersTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <WebUI.Radio size="compact" value="everyone">
                <WebUI.HStack className="items-center gap-1">
                  <span className="text-ds-sm">Everyone</span>
                  <WebUI.DeprecatedTooltip
                    className="h-[1em] w-[1em]"
                    variant="light"
                    label="Reminders will be sent to everyone (anyone who was invited and/or has made a payment)."
                  >
                    <WebUI.PhosphorIcon
                      icon="question-fill"
                      className="text-gray400"
                    />
                  </WebUI.DeprecatedTooltip>
                </WebUI.HStack>
              </WebUI.Radio>
              <WebUI.Radio
                size="compact"
                disabled={!hasInvitesQuery.data}
                value="invited_but_not_paid"
              >
                <WebUI.HStack className="items-center gap-1">
                  <span className="text-ds-sm">Invited but not paid</span>
                  <WebUI.DeprecatedTooltip
                    className="h-[1em] w-[1em]"
                    variant="light"
                    label={
                      <>
                        This is an option when you use
                        <br />
                        Cheddar Up’s{' '}
                        <WebUI.Anchor
                          href="https://support.cheddarup.com/hc/en-us/articles/360035586891-Invite-remind-and-message-payers"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Invite
                        </WebUI.Anchor>{' '}
                        feature. Reminders will be sent only to those who were
                        invited and have not paid.
                      </>
                    }
                  >
                    <WebUI.PhosphorIcon
                      icon="question-fill"
                      className="text-gray400"
                    />
                  </WebUI.DeprecatedTooltip>
                </WebUI.HStack>
              </WebUI.Radio>
            </WebUI.RadioGroup>
          ) : (
            <RecipientsModal
              disclosure={
                <WebUI.DialogDisclosure
                  className={WebUI.cn(
                    formik.values.recipients.length === 0
                      ? 'text-natural-95'
                      : 'text-linkPrimaryText',
                    '[&.Button--secondary]:text-linkPrimaryText',
                  )}
                  variant={
                    formik.values.recipients.length === 0
                      ? 'default'
                      : 'secondary'
                  }
                >
                  {formik.values.recipients.length > 0
                    ? `${formik.values.recipients
                        .slice(0, 3)
                        .map((r) => r.name || r.email)
                        .join(', ')}${
                        formik.values.recipients.length > 3
                          ? ` & ${formik.values.recipients.length - 3} more`
                          : ''
                      }`
                    : 'Add Recipients'}
                </WebUI.DialogDisclosure>
              }
              initialRecipients={formik.values.recipients}
              onRecipientsSave={(newRecipients) => {
                formik.setFieldValue(
                  'recipients',
                  Util.uniqueBy(newRecipients, (r) => r.email).map((r) => ({
                    ...r,
                    uuid: Util.makeUuid(),
                  })),
                )
              }}
            />
          )}
        </WebUI.FormField>
      </WebUI.VStack>

      <WebUI.VStack className="gap-4 p-7 *:max-w-screen-sm" as={WebUI.Panel}>
        <WebUI.FormFieldGroup>
          <WebUI.FormField label="From:" error={formik.errors.from} required>
            <WebUI.Input
              name="from"
              value={formik.values.from}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <WebUI.FormField
            label="Subject"
            error={formik.errors.subject}
            required
          >
            <WebUI.Input
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        </WebUI.FormFieldGroup>

        <WebUI.VStack className="gap-3">
          <WebUI.FormField error={formik.errors.message}>
            <MessageEditor
              ref={messageEditorRef}
              name="message"
              initialMarkdownValue={
                body === null
                  ? `# ${
                      formik.values.collectionId === null
                        ? organizationNameQuery.data ?? ''
                        : selectedCollection?.name ?? ''
                    }
<br>
`
                  : body
              }
              formik={formik}
              headerUrl={formik.values.headerUrl}
              uploadedBannerImage={formik.values.uploadedImage}
              uploadedBannerImageCrop={formik.values.uploadedImageCrop}
              collection={selectedCollection}
              onMarkdownValueChange={(newMessage) =>
                formik.setFieldValue('message', newMessage)
              }
              footer={
                <WebUI.VStack className="gap-1">
                  {formik.values.includeLink && (
                    <WebUI.Button
                      className="mx-5 my-1 self-start rounded-[20px]"
                      size="compact"
                      disabled
                    >
                      {formik.values.linkText}
                    </WebUI.Button>
                  )}
                  <WebUI.VStack
                    className={
                      'items-stretch justify-center gap-3 border-t px-6 py-3 sm:flex-row sm:items-center sm:justify-between'
                    }
                  >
                    <WebUI.Disclosure
                      className="gap-5"
                      visible={formik.values.includeLink}
                    >
                      <WebUI.Switch
                        size="compact"
                        name="includeLink"
                        checked={formik.values.includeLink}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {isGroupPage
                          ? 'Include button with link to your Group Page'
                          : 'Include button with link to your collection'}
                      </WebUI.Switch>
                      <WebUI.DisclosureContent>
                        <WebUI.HStack className="items-end gap-3">
                          <WebUI.FormField
                            className="flex-[3]"
                            label="Button Text"
                            error={formik.errors.linkText}
                            required
                          >
                            <WebUI.Input
                              type="text"
                              maxLength={28}
                              size="compact"
                              name="linkText"
                              placeholder="Button Text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.linkText}
                            />
                          </WebUI.FormField>
                          <WebUI.Text className="flex-[2] font-light text-ds-sm text-gray400">
                            You are limited to 28 characters.
                          </WebUI.Text>
                        </WebUI.HStack>
                      </WebUI.DisclosureContent>
                    </WebUI.Disclosure>
                  </WebUI.VStack>
                </WebUI.VStack>
              }
              onDeleteBannerImage={() =>
                formik.setValues((prevValues) => ({
                  ...prevValues,
                  uploadedImage: null,
                  uploadedImageCrop: null,
                  headerUrl: null,
                }))
              }
            />
          </WebUI.FormField>
          <WebUI.HStack className="inline-flex flex-wrap gap-2 *:mr-2 *:mb-2 *:ml-0">
            {formik.values.attachments.map((file, idx) => (
              <WebUI.Tag
                key={idx}
                className="max-w-[180px] text-ds-sm"
                onClear={() =>
                  formik.setFieldValue(
                    'attachments',
                    formik.values.attachments.filter((a) => a !== file),
                  )
                }
              >
                {file.name}
              </WebUI.Tag>
            ))}
          </WebUI.HStack>

          <WebUI.HStack className="flex-0 items-center justify-end gap-4">
            <WebUI.Button
              className="text-ds-sm"
              variant="link"
              type="button"
              loading={isSendingTestMessage}
              disabled={disableFormButton}
              onClick={async () => {
                setValidateAfterSubmit(true)
                if (formik.values.collectionId == null) {
                  return
                }

                const errors = await formik.validateForm()
                if (Object.keys(errors).length > 0) {
                  return
                }

                try {
                  setIsSendingTestMessage(true)
                  const payload = await buildMessagePayload(formik.values)
                  await sendTestMessageMutation.mutateAsync({
                    queryParams: {
                      tab_id: formik.values.collectionId,
                    },
                    body: payload,
                  })

                  growlActions.show('success', {
                    title: 'Success!',
                    body: 'Email sent successfully.',
                  })
                } catch {
                  growlActions.show('error', {
                    body: 'Something went wrong when sending your messages.',
                    title: 'Oops!',
                  })
                } finally {
                  setIsSendingTestMessage(false)
                }
              }}
            >
              Send me a test
            </WebUI.Button>
            <WebUI.Button
              type="submit"
              disabled={disableFormButton}
              variant="primary"
              loading={formik.isSubmitting}
              onClick={() => setValidateAfterSubmit(true)}
            >
              {formik.values.messageType === 'reminder' ? 'Save' : 'Send Now'}
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      </WebUI.VStack>

      <Routes>
        <Route path="media">
          <Route
            path="edit"
            element={
              <>
                <ImageUploadPage
                  onImageSubmit={(newImage) => {
                    formik.setValues((prevValues) => ({
                      ...prevValues,
                      uploadedImage: newImage,
                      uploadedImageCrop: null,
                      headerUrl: null,
                    }))
                    navigate({
                      pathname: 'media/edit/crop',
                      search: location.search,
                    })
                  }}
                />
                <Outlet />
              </>
            }
          >
            <Route
              path="crop"
              element={
                formik.values.uploadedImage ? (
                  <ImageCropPage
                    image={formik.values.uploadedImage}
                    onCropSubmit={(newCrop) => {
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        uploadedImageCrop: newCrop,
                        headerUrl: null,
                      }))
                      navigate({pathname: '.', search: location.search})
                    }}
                  />
                ) : (
                  <Navigate to={{pathname: '.', search: location.search}} />
                )
              }
            />
          </Route>
          <Route
            path="albums/:album"
            element={
              <ImageAlbumPage
                formik={formik as any}
                onHeaderSelect={(newHeader) => {
                  formik.setValues((prevValues) => ({
                    ...prevValues,
                    uploadedImage: null,
                    uploadedImageCrop: null,
                    headerUrl:
                      newHeader.image.edited_image_url ?? newHeader.image.url,
                  }))
                  navigate({pathname: '.', search: location.search})
                }}
                onUpload={() =>
                  navigate({pathname: '.', search: location.search})
                }
              />
            }
          />
        </Route>
      </Routes>
    </WebUI.Form>
  )
}

// MARK: – MessageEditor

interface MessageEditorProps
  extends Omit<WebUI.RichTextEditorProps, 'plugins'> {
  collection: Api.Tab | null | undefined
  headerUrl?: string | null
  uploadedBannerImage?: Blob | null
  uploadedBannerImageCrop?: Api.CropDetails | null
  onDeleteBannerImage: () => void
  formik: ReturnType<typeof useFormik<SendMessageFormValues>>
}

interface MessageEditorInstance {
  clearMessage: () => void
}

const MessageEditor = React.forwardRef<
  MessageEditorInstance,
  MessageEditorProps
>(
  (
    {
      collection,
      initialMarkdownValue,
      headerUrl,
      uploadedBannerImage,
      uploadedBannerImageCrop,
      onDeleteBannerImage,
      className,
      formik,
      ...restProps
    },
    forwardedRef,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const richTextEditorRef = useRef<WebUI.RichTextEditorInstance>(null)
    const croppedLocalImageUrl = useCroppedImage(
      useMemo(
        () =>
          uploadedBannerImage
            ? {
                image: uploadedBannerImage,
                crop: uploadedBannerImageCrop ?? null,
              }
            : null,
        [uploadedBannerImage, uploadedBannerImageCrop],
      ),
    )

    useImperativeHandle(forwardedRef, () => ({
      clearMessage: () => {
        if (initialMarkdownValue) {
          richTextEditorRef.current?.setMarkdownValue(initialMarkdownValue)
        }
      },
    }))

    useUpdateEffect(() => {
      if (initialMarkdownValue) {
        richTextEditorRef.current?.setMarkdownValue(initialMarkdownValue)
      }
    }, [initialMarkdownValue])

    const hasSelectedImage = !!headerUrl || !!uploadedBannerImage
    const richTextEditorBoundingClientRect =
      containerRef.current?.getBoundingClientRect()

    return (
      <WebUI.VStack
        ref={containerRef}
        className={WebUI.cn('gap-0_5', className)}
      >
        {!!hasSelectedImage && !!richTextEditorBoundingClientRect && (
          <div className="relative">
            {headerUrl || croppedLocalImageUrl ? (
              <WebUI.Image
                alt="Banner"
                width={richTextEditorBoundingClientRect.width}
                height={richTextEditorBoundingClientRect.width / 3}
                src={headerUrl || croppedLocalImageUrl}
              />
            ) : null}
            <WebUI.IconButton
              className="absolute top-3 right-3 rounded bg-gray400 text-ds-lg text-natural-100"
              size="default_alt"
              variant="secondary"
              onClick={() => onDeleteBannerImage()}
            >
              <WebUI.PhosphorIcon icon="x-fill" />
            </WebUI.IconButton>
          </div>
        )}
        <WebUI.RichTextEditor
          ref={richTextEditorRef}
          data-includelink={formik.values.includeLink}
          className="min-h-[280px] data-[includelink=true]:min-h-[320px]"
          initialMarkdownValue={initialMarkdownValue}
          placeholders={[
            {
              key: 'h1',
              placeholder: 'Heading',
              hideOnBlur: false,
            },
            {
              key: 'p',
              placeholder: 'Your message here',
              hideOnBlur: true,
              query: {
                maxLevel: 1,
              },
            },
          ]}
          {...restProps}
        >
          <WebUI.RichTextEditorToolbar
            className="[&_>_.RichTextEditorToolbar-contentMain]:flex-[1]"
            rootClassName="-order-1"
          >
            <WebUI.FileUploader
              onDropAccepted={(newFiles) => {
                formik.setValues((prevValues) => ({
                  ...prevValues,
                  attachments: [...prevValues.attachments, ...newFiles],
                }))
              }}
            >
              <WebUI.FileUploaderInput />
              <WebUI.FileUploaderButton
                className="self-start [&_>_.Button-iconBefore:text-ds-base]"
                size="compact"
                variant="outlined"
                iconBefore={<WebUI.PhosphorIcon icon="paperclip" />}
              >
                Add Attachment
              </WebUI.FileUploaderButton>
            </WebUI.FileUploader>
            <LinkButton
              size="compact"
              variant="secondary"
              preserveSearch
              to="media/albums/1"
            >
              Add Banner Image
            </LinkButton>
          </WebUI.RichTextEditorToolbar>
        </WebUI.RichTextEditor>
      </WebUI.VStack>
    )
  },
)
