import {NumberParam, useQueryParam} from 'use-query-params'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import {ParammedTabs} from 'src/components/ParammedTabs'
import {useNavigate} from 'react-router-dom'
import {api} from '@cheddarup/api-client'
import {
  useManagerRole,
  useManagerRoleId,
} from 'src/components/ManageRoleProvider'

import {MessageHistoryView} from './MessageHistoryView'
import {SendMessageForm} from './SendMessageForm'

export const MessageCenterPage = () => {
  const media = WebUI.useMedia()
  const [, setMessageIdToDuplicate] = useQueryParam(
    'messageIdToDuplicate',
    NumberParam,
  )
  const [, setMessageToDuplicateTabId] = useQueryParam(
    'messageToDuplicateTabId',
    NumberParam,
  )
  const [managerRoleId] = useManagerRoleId()
  const isPersonaVerificationRequiredQuery = api.auth.session.useQuery(
    undefined,
    {
      select: (session) => {
        if (managerRoleId) {
          const managerRole = session.manager_roles.find(
            (mr) => mr.id === managerRoleId,
          )
          return managerRole?.persona_required && !managerRole.persona_completed
        }

        return (
          session.user.profile.persona.required &&
          !session.user.profile.persona.completed
        )
      },
    },
  )

  return (
    <WebUI.Modal
      aria-label="Message center"
      className={
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-h-full [&_>_.ModalContentView]:w-full [&_>_.ModalContentView]:overflow-y-auto [&_>_.ModalContentView]:rounded-none [&_>_.ModalContentView]:bg-natural-95 [&_>_.ModalContentView]:pb-12'
      }
    >
      {!media.sm && <WebUI.ModalCloseButton />}

      <WebUI.VStack className="gap-10">
        <MessageCenterPageHeader />

        <WebUI.HStack className="items-start justify-center gap-4">
          <ParammedTabs
            className="min-w-[min(100%,960px)] max-w-[min(100%,960px)] flex-0"
            paneKeyName="mcp"
            defaultPaneKey="send"
            variant="underlined"
            onChangeCurrentId={(newPaneKey) => {
              if (newPaneKey === 'history') {
                setMessageIdToDuplicate(null)
                setMessageToDuplicateTabId(null)
              }
            }}
          >
            <WebUI.VStack className="gap-4">
              <WebUI.TabList
                aria-label="Message center navigation"
                className="mx-4 sm:mx-0"
              >
                <WebUI.Tab id="send">Send a Message</WebUI.Tab>
                <WebUI.Tab id="history">Message History</WebUI.Tab>
              </WebUI.TabList>

              <WebUI.TabPanel tabId="send">
                <SendMessageForm />
              </WebUI.TabPanel>
              <WebUI.TabPanel tabId="history">
                <MessageHistoryView />
              </WebUI.TabPanel>
            </WebUI.VStack>
          </ParammedTabs>
        </WebUI.HStack>
      </WebUI.VStack>

      {isPersonaVerificationRequiredQuery.data && (
        <MessageCenterPersonaCTAModal />
      )}
    </WebUI.Modal>
  )
}

// MARK: – MessageCenterPageHeader

const MessageCenterPageHeader = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const media = WebUI.useMedia()
  return (
    <WebUI.HStack
      className={WebUI.cn(
        'items-center justify-between gap-3 border-b bg-natural-100 px-4 py-8 md:px-16 md:py-10',
        className,
      )}
      {...restProps}
    >
      <WebUI.Heading className="xs:text-ds-xl">Message Center</WebUI.Heading>
      {media.sm && (
        <LinkButton className="w-[160px]" variant="default" to="..">
          Close
        </LinkButton>
      )}
    </WebUI.HStack>
  )
}

// MARK: – MessageCenterPersonaCTAModal

const MessageCenterPersonaCTAModal = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.ModalProps
>(({className, onDidHide, ...restProps}, forwardedRef) => {
  const navigate = useNavigate()
  const [managerRole] = useManagerRole()
  return (
    <WebUI.Modal
      role="alertdialog"
      aria-label="Persona inquiry"
      ref={forwardedRef}
      className={WebUI.cn(
        '[&_>_.ModalContentView]:h-auto [&_>_.ModalContentView]:rounded-xl sm:[&_>_.ModalContentView]:max-w-screen-sm',
        className,
      )}
      onDidHide={() => {
        navigate('..')
        onDidHide?.()
      }}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <WebUI.VStack className="items-center gap-4 p-16">
        <WebUI.Heading className="text-center">
          {managerRole ? 'Account owner action necessary' : 'One more step'}
        </WebUI.Heading>

        <WebUI.Text className="text-center font-light text-ds-md">
          {managerRole
            ? `To access this feature, the account owner (${managerRole.email}) of ${managerRole.full_name} needs to finish their account setup. Click below to send the account owner an email asking for help.`
            : "To send messages using our Message Center, you first need to finish your account setup. Account setup is a one-time task and only takes a few minutes. And by doing it now, you'll be ready to go when it's time to withdraw funds later."}
        </WebUI.Text>

        {managerRole ? (
          <WebUI.AnchorButton
            size="large"
            variant="default"
            href={`mailto:${managerRole.email}?subject=${encodeURIComponent(
              'I need your help verifying our Cheddar Up account',
            )}&body=${encodeURIComponent(
              `As the account owner of our ${managerRole.full_name} Cheddar Up account, it looks like I need your help. Some of the platform's functionality is currently blocked because the "Finish Setup" process is not yet complete. Apparently, this only takes a few minutes to complete. Would you mind completing our account setup so I can move forward with this feature?

Thanks for your help!`,
            )}`}
          >
            Contact Account Owner
          </WebUI.AnchorButton>
        ) : (
          <LinkButton
            size="large"
            variant="default"
            preserveSearch
            // TODO: there's no more finish-setup route
            to="onboard/finish-setup/persona"
          >
            Finish Setup
          </LinkButton>
        )}
      </WebUI.VStack>
    </WebUI.Modal>
  )
})
