import {useCallback} from 'react'
import {useElements, useStripe} from '@stripe/react-stripe-js'
import type {CreateTokenBankAccountData, PaymentMethod} from '@stripe/stripe-js'
import {BooleanParam, useQueryParam, withDefault} from 'use-query-params'
import {useLocation, useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import CartHelpers, {
  extractPaymentUuidFromCartUuid,
} from 'src/helpers/CartHelpers'
import {
  api,
  endpoints,
  getEndpointKey,
  usePayForCartMutation,
  useQueryClient,
  useUpdateCartMutation,
  useUpdateCartTimeSlotMutation,
} from '@cheddarup/api-client'
import {trackPurchaseEvent} from 'src/helpers/analytics'
import {SIGNUP_NAME_FIELD_SENTINEL_VALUE} from 'src/components/SignupSpotsViewForm'
import {useLogout} from 'src/hooks/useAuth'

import useCart, {
  useEnhancedCancelPaymentIntentMutation,
  useEnhancedUpdateCartMutation,
} from './useCart'
import usePublicCollection from './usePublicCollection'
import type {CheckoutValues} from '../CheckoutPage/CheckoutPage'
import {useIsAuthed} from 'src/hooks/useAuthToken'

export const usePayForCart = (paymentIntentClientSecret?: string) => {
  const [addPayment] = useQueryParam(
    'add-payment',
    withDefault(BooleanParam, false),
  )
  const navigate = useNavigate()
  const location = useLocation()
  const {publicCollection, clearPublicCollectionPayload} = usePublicCollection()
  const {cart, reset: resetCart, refetch: refetchCart} = useCart()
  const growlActions = WebUI.useGrowlActions()
  const prePayUpdateCart = usePrePayUpdateCart()
  const queryClient = useQueryClient()
  const payCartMutation = usePayForCartMutation()
  const isLoggedIn = useIsAuthed()
  const userQuery = api.auth.session.useQuery(undefined, {
    enabled: isLoggedIn,
    select: (session) => session.user,
  })
  const [logout] = useLogout()
  const [, cancelPaymentIntentAsync] = useEnhancedCancelPaymentIntentMutation()

  const stripe = paymentIntentClientSecret ? useStripe() : null

  const elements = paymentIntentClientSecret ? useElements() : null

  const payForCart = useCallback(
    async (
      values?: Partial<CheckoutValues> & {
        email?: string
        name?: string
        phoneNumber?: string
      },
      options?: {onShouldReload?: () => void},
    ) => {
      function getStripe() {
        if (!stripe) {
          throw new Error(
            'Something went wrong loading your payment methods, please refresh and try again.',
          )
        }

        return stripe
      }
      function getElements() {
        if (!elements) {
          throw new Error(
            'Something went wrong loading your payment methods, please refresh and try again.',
          )
        }

        return elements
      }

      growlActions.clear()

      if (!cart) {
        return
      }

      const isZeroCart = CartHelpers.isZeroCart({
        cart,
        paymentMethod: values?.paymentMethod?.method || 'card',
      })

      const saveSource =
        (values?.paymentMethod?.saveSource ||
          values?.paymentMethod?.useSaved) ??
        false

      let source = undefined
      let paidCart = null
      let invalidateIntent = false

      try {
        if (!isZeroCart && values?.paymentMethod?.method === 'echeck') {
          if (values?.paymentMethod.useSaved) {
            source = {
              savedMethodId: values.paymentMethod.echeck.id,
            }
          } else {
            const res = await getStripe().createToken('bank_account', {
              country: 'us',
              currency: 'usd',
              account_holder_name: cart.member?.name ?? '',
              account_number: values?.paymentMethod.echeck.accountNumber,
              routing_number: values?.paymentMethod.echeck.routingNumber,
            } as CreateTokenBankAccountData)
            if (res.error) {
              CartHelpers.trackEvent('stripeCreateBankTokenError', {
                error: res.error.message,
              })
              throw new Error(res.error.message)
            }
            source = {token: res.token.id}
          }
        }

        const usesPaymentIntents =
          !isZeroCart && values?.paymentMethod?.method === 'card'

        const phoneNumber =
          userQuery.data?.profile.phone.country_code &&
          userQuery.data.profile.phone.fullNumber
            ? `+${userQuery.data.profile.phone.country_code}${userQuery.data.profile.phone.fullNumber}`
            : null

        const updateBody: any = {
          dripOptIn: values?.dripOptIn,
          method: isZeroCart
            ? undefined
            : values?.paymentMethod?.method || 'card',
          saveSource,
          pointOfSale: {
            usesPaymentIntents,
          },
          setCustomer:
            usesPaymentIntents &&
            !isZeroCart &&
            ((values?.paymentMethod?.useSaved && values?.paymentMethod.card) ||
              saveSource ||
              cart?.recurringTotal > 0 ||
              Boolean(cart.member?.email)),
          source,
          name:
            values?.name ??
            cart.member?.name ??
            (addPayment ? undefined : userQuery.data?.full_name),
          email:
            values?.email ??
            cart.member?.email ??
            (addPayment ? undefined : userQuery.data?.email),
          shippingMethod: cart.shippingInfo.shippingMethod,
          shipTo: cart.shippingInfo.shipTo,
          phoneNumber: values?.phoneNumber ?? (cart.phoneNumber || phoneNumber),
        }

        try {
          await prePayUpdateCart(updateBody)
        } catch (err) {
          invalidateIntent = updateBody.pointOfSale.usesPaymentIntents
          throw err
        }

        if (!isZeroCart && values?.paymentMethod?.method === 'card') {
          if (values.paymentMethod.useSaved && paymentIntentClientSecret) {
            if (!values.paymentMethod.card) {
              throw new Error('Card not selected')
            }

            CartHelpers.trackEvent('beforeStripeConfirmSavedCard')
            const confirmResult = await getStripe().confirmCardPayment(
              paymentIntentClientSecret,
              {payment_method: values.paymentMethod.card.id},
            )

            CartHelpers.trackEvent('afterStripeConfirmSavedCard')
            if (confirmResult.error) {
              CartHelpers.trackEvent('stripeConfirmError', {
                error: confirmResult.error.message,
              })
              throw new Error(confirmResult.error.message)
            }
            invalidateIntent = true
          } else {
            CartHelpers.trackEvent('beforeStripeConfirmNewCard')
            const confirmResult = await getStripe().confirmPayment({
              elements: getElements(),
              confirmParams: {
                return_url: window.location.href,
                expand: ['payment_method'],
              },
              redirect: 'if_required',
            })

            CartHelpers.trackEvent('afterStripeConfirmNewCard')
            if (confirmResult.error) {
              CartHelpers.trackEvent('stripeConfirmError', {
                error: confirmResult.error.message,
              })
              throw new Error(confirmResult.error.message)
            }
            invalidateIntent = true

            if (!updateBody.email) {
              const paymentMethod = confirmResult.paymentIntent
                .payment_method as PaymentMethod
              if (
                'link' in paymentMethod &&
                paymentMethod.link instanceof Object &&
                'email' in paymentMethod.link &&
                typeof paymentMethod.link.email === 'string'
              ) {
                updateBody.email = paymentMethod.link.email
              }
            }
            if (!updateBody.name) {
              // `getValue()` is not typed, it triggers element's validations
              const addressElementValues = await getElements()
                .getElement('address')
                ?.getValue()

              updateBody.name = addressElementValues?.value.name
            }
          }
        }
        CartHelpers.trackEvent('beforePayCart')
        try {
          paidCart = await payCartMutation.mutateAsync({
            pathParams: {
              tabId: publicCollection.slug,
              cartUuid: cart.uuid,
            },
            body: updateBody,
          })
        } catch (err: any) {
          if (invalidateIntent) {
            const terminalIntentQueryKey = getEndpointKey(
              endpoints.carts.intentDetail,
              {
                pathParams: {
                  tabId: publicCollection.id,
                  cartUuid: cart.uuid,
                },
              },
            )
            queryClient.invalidateQueries({queryKey: terminalIntentQueryKey})

            CartHelpers.trackEvent('invalidatingQueries', {
              error: CartHelpers.getPayErrorBody(err),
              responseJson:
                typeof err.toJSON === 'function' ? err.toJSON() : undefined,
            })
          }
          throw err
        }
        CartHelpers.trackEvent('afterPayCart')
      } catch (err: any) {
        growlActions.clear()
        const errorMessage = CartHelpers.getPayErrorBody(err)

        if (err.response?.data?.error === 'items_sold_out') {
          await refetchCart()
          options?.onShouldReload?.()
        } else {
          CartHelpers.trackEvent('errorPaying', {
            error: errorMessage,
            code: err.code,
            request: err.request,
            response: err.response,
            json: typeof err.toJSON === 'function' ? err.toJSON() : undefined,
          })
          growlActions.show('error', {
            title: 'Error',
            body: errorMessage,
            timeout: 0,
          })
          if (invalidateIntent) {
            growlActions.show('error', {
              title: 'Card Not Charged',
              body: 'Although a hold may appear on your card, it has not been charged.',
              timeout: 0,
            })
          }

          // `!err.response` means we didn't recieve a response from the server
          // meaning there's a chance the request didn't even fire
          if (err.code === 'ERR_NETWORK' || (err.request && !err.response)) {
            cancelPaymentIntentAsync()
          }
        }
      }

      if (!paidCart) {
        return
      }

      CartHelpers.trackEvent('redirectingToThankYou')
      navigate({
        pathname: '../checkout/thank-you',
        search: Util.mergeSearchParams(location.search, {
          'add-to-calendar': paidCart.time_slots.some(
            (ts) =>
              ts.time_slot.spot.signup.options.signupType === 'schedule' ||
              ts.time_slot.spot.signup.options.signupListDate,
          )
            ? 1
            : 0,
          'payment-uuid': extractPaymentUuidFromCartUuid(paidCart.uuid),
          'payer-name': paidCart?.member?.name,
          payere: btoa(btoa((paidCart?.member?.email ?? '') as string)),
        }),
      })

      if (!addPayment) {
        await logout()
      }

      try {
        trackPurchaseEvent(
          cart,
          publicCollection,
          values?.paymentMethod?.method,
        )
        resetCart()
        clearPublicCollectionPayload()
      } catch (err: any) {
        const errMsg = err.message || err.response?.data?.error
        CartHelpers.trackEvent('trackResetCartError', {error: errMsg})
      }
    },
    [
      growlActions,
      cart,
      navigate,
      location.search,
      addPayment,
      stripe,
      elements,
      userQuery.data?.profile.phone.country_code,
      userQuery.data?.profile.phone.fullNumber,
      userQuery.data?.full_name,
      userQuery.data?.email,
      prePayUpdateCart,
      paymentIntentClientSecret,
      payCartMutation,
      publicCollection,
      queryClient,
      logout,
      resetCart,
      clearPublicCollectionPayload,
      cancelPaymentIntentAsync,
      refetchCart,
    ],
  )

  return payForCart
}

// MARK: – usePrePayUpdateCart

const usePrePayUpdateCart = () => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [, updateCartAsync] = useEnhancedUpdateCartMutation()
  const updateCartTimeSlotMutation = useUpdateCartTimeSlotMutation()

  const updateCartTimeSlotAsync = updateCartTimeSlotMutation.mutateAsync

  const prePayUpdateCart = useCallback(
    async (
      payload: Util.SetRequired<
        Parameters<ReturnType<typeof useUpdateCartMutation>['mutateAsync']>[0],
        'body'
      >['body'],
    ) => {
      const [cartFirstName, cartLastName] =
        (payload.name ?? cart?.member?.name)?.trim().split(/\s+/) ?? []

      const updateTimeSlotsPromises = (cart?.time_slots ?? [])
        .filter((cTs) =>
          cTs.cart_field_views.some(
            (ifv) =>
              (ifv.metadata.timeSlotFieldType === 'first_name' ||
                ifv.metadata.timeSlotFieldType === 'last_name') &&
              ifv.value === SIGNUP_NAME_FIELD_SENTINEL_VALUE,
          ),
        )
        .map((cTs) =>
          updateCartTimeSlotAsync({
            pathParams: {
              tabId: publicCollection.slug,
              cartUuid: cart?.uuid ?? '',
              timeSlotId: cTs.id,
            },
            body: {
              time_slot_id: cTs.time_slot.id,
              quantity: cTs.quantity,
              cart_field_values: cTs.cart_field_views.map((ifv) => ({
                ...ifv,
                value: ifv.metadata.timeSlotFieldType
                  ? {
                      first_name: cartFirstName || '',
                      last_name: cartLastName || cartFirstName || '',
                      comment: ifv.value,
                    }[ifv.metadata.timeSlotFieldType] ?? ifv.value
                  : ifv.value,
              })),
            },
          }),
        )

      CartHelpers.trackEvent('beforeCartUpdate')
      await Promise.all([
        updateCartAsync({body: payload}),
        ...updateTimeSlotsPromises,
      ])
      CartHelpers.trackEvent('afterCartUpdate')
    },
    [
      cart?.member?.name,
      cart?.time_slots,
      cart?.uuid,
      publicCollection.slug,
      updateCartAsync,
      updateCartTimeSlotAsync,
    ],
  )

  return prePayUpdateCart
}
