import {ForwardRefComponent, useForkRef} from '@cheddarup/react-util'
import React, {useId, useRef} from 'react'
import {tokens} from '@cheddarup/tokens'

import {HStackProps, Stack, StackProps, VStack} from './Stack'
import {Text} from './Text'
import {cn} from '../utils'

// MARK: - Form

export interface FormProps extends StackProps {}

/**
 * @deprecated Use native form tag instead
 */
export const Form = React.forwardRef(
  (
    {
      as: Comp = 'form',
      children,
      className,
      direction = 'vertical',
      ...restProps
    },
    forwardedRef,
  ) => (
    <Comp ref={forwardedRef} className={cn('Form', className)} {...restProps}>
      <Stack className={cn('Form-inner', 'gap-4')} direction={direction}>
        {children}
      </Stack>
    </Comp>
  ),
) as ForwardRefComponent<'form', FormProps>

// MARK: - FormField

export type FormFieldSize = 'default' | 'compact'

export interface FormFieldProps {
  size?: FormFieldSize
  label?: React.ReactNode
  caption?: React.ReactNode
  error?: React.ReactNode
  suffix?: React.ReactNode
  id?: string
  required?: boolean
}

export const FormField = React.forwardRef(
  (
    {
      as = 'fieldset',
      children: childrenProp,
      className,
      size = 'default',
      label,
      caption,
      error,
      suffix,
      id: idProp,
      required,
      ...restProps
    },
    forwardedRef,
  ) => {
    const child = React.isValidElement(childrenProp)
      ? (childrenProp as React.ReactElement<any>)
      : null
    const ref = useForkRef((child as any)?.ref, forwardedRef)
    const suffixRef = useRef<HTMLDivElement>(null)

    const inputId = idProp ?? useId()
    const labelId = `${inputId}-label`
    const captionId = `${inputId}-caption`
    const errorId = `${inputId}-error`

    const suffixWidth = suffixRef.current?.getBoundingClientRect().width ?? 0

    return (
      <VStack
        as={as}
        className={cn('FormField', 'min-w-0 gap-2 border-none p-0', className)}
        {...restProps}
      >
        {label && (
          <FormFieldLabel
            size={size}
            id={labelId}
            htmlFor={inputId}
            required={required}
          >
            {label}
          </FormFieldLabel>
        )}

        {child && (
          <div className="FormField-inputWrapper peer/inputWrapper relative w-full grow">
            {React.cloneElement(child, {
              ref,
              className: cn(
                'FormField-input',
                'w-full grow',
                child.props.className,
              ),
              style:
                suffixWidth > 0
                  ? {
                      paddingRight: `calc(${suffixWidth}px + ${tokens.space[4]} * 2)`,
                      ...child.props.style,
                    }
                  : child.props.style,
              id: inputId,
              required: child.props.required ?? required,
              ...(label && {'aria-labelledby': labelId}),
              ...(caption && {'aria-describedby': captionId}),
              ...(error && {
                'aria-invalid': true,
                'aria-errormessage': errorId,
              }),
            })}
            {!!suffix && (
              <div
                ref={suffixRef}
                className="FormField-suffix -translate-y-1/2 absolute top-1/2 right-4"
              >
                {suffix}
              </div>
            )}
          </div>
        )}

        {caption && (
          <Text
            className="FormField-caption hidden text-ds-xs peer-focus-within/inputWrapper:inline"
            id={captionId}
            variant="subtle"
          >
            {caption}
          </Text>
        )}

        {!!error && error !== true && (
          <Text
            className="FormField-error border-l-0 bg-[unset] p-0 pl-0 text-ds-xs text-orange-50"
            variant="danger"
            id={errorId}
          >
            {error}
          </Text>
        )}
      </VStack>
    )
  },
) as ForwardRefComponent<'fieldset', FormFieldProps>

// MARK: - FormFieldLabel

export interface FormFieldLabelProps {
  required?: boolean
  size?: FormFieldSize
}

export const FormFieldLabel = React.forwardRef(
  (
    {as: Comp = 'label', children, required, size = 'default', ...restProps},
    forwardedRef,
  ) => (
    <Comp
      ref={forwardedRef}
      className={cn(
        'FormField-label',
        'font-body font-normal',
        ({default: 'text-ds-sm', compact: 'text-ds-xs'} as const)[size],
      )}
      {...restProps}
    >
      {children} {required && <span className="text-orange-50">*</span>}
    </Comp>
  ),
) as ForwardRefComponent<'label', FormFieldLabelProps>

// MARK: – FormFieldGroup

export interface FormFieldGroupProps
  extends HStackProps,
    React.ComponentPropsWithoutRef<'div'> {}

export const FormFieldGroup = React.forwardRef<
  HTMLDivElement,
  FormFieldGroupProps
>(({className, ...restProps}, forwardedRef) => (
  <VStack
    ref={forwardedRef}
    className={cn(
      'FormFieldGroup',
      'items-stretch justify-start gap-4 sm:flex-row sm:items-start',
      '*:flex-[1]',
      className,
    )}
    {...restProps}
  />
))
