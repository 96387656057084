import {useLocation, useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {useEffect} from 'react'
import {Elements} from 'src/components/Stripe'
import {extractPaymentUuidFromCartUuid} from 'src/helpers/CartHelpers'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {useIsAuthed} from 'src/hooks/useAuthToken'

import usePublicCollection from './hooks/usePublicCollection'
import useCart, {
  useEnhancedCancelPaymentIntentMutation,
  useEnhancedCreatePaymentIntentMutation,
} from './hooks/useCart'

export interface CheckoutElementsProps {
  children:
    | React.ReactNode
    | ((args: {clientSecret?: string}) => React.ReactNode)
}

export const CheckoutElements = ({children}: CheckoutElementsProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [createPaymentIntentMutation, createPaymentIntentAsync] =
    useEnhancedCreatePaymentIntentMutation()
  const [, cancelPaymentIntentAsync] = useEnhancedCancelPaymentIntentMutation()
  const growlActions = WebUI.useGrowlActions()
  const isLoggedIn = useIsAuthed()

  const totalMax =
    cart?.total ?? 0 + Math.max(...Object.values(cart?.fees?.card ?? {}))

  const paymentIntentQuery = api.carts.intentDetail.useQuery(
    {
      pathParams: {
        tabId: publicCollection.slug,
        // biome-ignore lint/style/noNonNullAssertion:
        cartUuid: cart?.uuid!,
      },
    },
    {
      enabled: !!cart && totalMax > 0,
      refetchOnMount: 'always',
    },
  )

  const shouldCreateNewPaymentIntent =
    (paymentIntentQuery.error as any)?.response?.status === 404 ||
    paymentIntentQuery.data?.status === 'requires_capture'
  const createPaymentIntentAsyncRef = useLiveRef(createPaymentIntentAsync)
  useEffect(() => {
    if (shouldCreateNewPaymentIntent) {
      createPaymentIntentAsyncRef.current()
    }
  }, [shouldCreateNewPaymentIntent])

  const cancelPaymentIntentAsyncRef = useLiveRef(cancelPaymentIntentAsync)
  const isZeroAmountCart = totalMax === 0
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useUpdateEffect(() => {
    if (!isZeroAmountCart) {
      cancelPaymentIntentAsyncRef.current()
    }
  }, [isLoggedIn, isZeroAmountCart])

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (cart?.paymentComplete) {
      navigate({
        pathname: 'thank-you',
        search: Util.mergeSearchParams(location.search, {
          'add-to-calendar': cart.time_slots.some(
            (ts) =>
              ts.time_slot.spot.signup.options.signupType === 'schedule' ||
              ts.time_slot.spot.signup.options.signupListDate,
          )
            ? 1
            : 0,
          'payment-uuid': extractPaymentUuidFromCartUuid(cart.uuid),
          'payer-name': cart?.member?.name,
          payere: btoa(btoa((cart?.member?.email ?? '') as string)),
        }),
      })
    } else if (totalMax > 999999.99) {
      growlActions.show('error', {
        title: 'Error',
        body: "Total can't be greater than $999,999.99",
      })

      navigate({
        pathname: '..',
        search: location.search,
      })
    }
  }, [
    growlActions,
    location.search,
    navigate,
    publicCollection.slug,
    totalMax,
    cart?.paymentComplete,
    cart?.member?.name,
    cart?.member?.email,
    cart?.time_slots,
    cart?.uuid,
  ])

  if (paymentIntentQuery.isFetching || createPaymentIntentMutation.isPending) {
    return (
      <div
        className={
          'flex h-screen min-h-0 min-w-0 shrink-0 grow basis-auto items-center justify-center'
        }
      >
        <WebUI.Loader />
      </div>
    )
  }
  if (totalMax === 0 || createPaymentIntentMutation.isError) {
    return (
      <>
        {typeof children === 'function'
          ? children({clientSecret: undefined})
          : children}
      </>
    )
  }

  if (paymentIntentQuery.data) {
    return (
      <Elements options={{clientSecret: paymentIntentQuery.data?.clientSecret}}>
        {typeof children === 'function'
          ? children({
              clientSecret: paymentIntentQuery.data?.clientSecret,
            })
          : children}
      </Elements>
    )
  }

  return null
}
