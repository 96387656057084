import * as WebUI from '@cheddarup/web-ui'
import type {AxiosRequestConfig} from 'axios'

import {apiClient} from './client'

export const fetchAndSave = async ({
  url,
  fileName,
  config: axiosConfig,
}: {
  url: string
  fileName?: string
  config?: AxiosRequestConfig
}) => {
  const response = await apiClient(url, {
    timeout: 90000,
    responseType: 'blob',
    ...axiosConfig,
  })

  return WebUI.downloadFile(response.data, fileName)
}
