import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {useLocation} from 'react-router-dom'
import {api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {
  getMostSignificantDisputedTab,
  getTotalDisputableAmount,
} from '@cheddarup/core'

import {WithdrawPageContentContainer} from './containers'

const WithdrawPage = () => {
  const location = useLocation()
  return (
    <WebUI.VStack className="grow overflow-hidden bg-natural-100">
      <WebUI.VStack className="p-4 pb-4 text-gray800 sm:p-8">
        <WebUI.Heading as="h2">Transfer Money</WebUI.Heading>
        <WebUI.Text className="font-light">
          Transfer your collected funds to a bank account or to a gift card
          link. Transfer speeds vary but typically take 3 business days.
          Transfers are reviewed which may result in delays or the need for
          additional information.&nbsp;
        </WebUI.Text>
      </WebUI.VStack>

      <WebUI.Separator />

      <DisputesBanner />

      <div className="grow overflow-auto p-4 sm:p-8">
        <WithdrawPageContentContainer />
      </div>

      <WebUI.IconButton
        className="absolute top-4 right-4 text-ds-xl"
        as={Link}
        size="default_alt"
        to={location.state?.prevPath || '/collections'}
      >
        <WebUI.PhosphorIcon icon="x" />
      </WebUI.IconButton>
    </WebUI.VStack>
  )
}

// MARK: – DisputesBanner

const DisputesBanner = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const {data: collections} = api.tabs.list.useQuery()

  const disputedCollectionId = getMostSignificantDisputedTab(
    collections ?? [],
  )?.id
  const totalDisputeAmount = getTotalDisputableAmount(collections ?? [])

  return totalDisputeAmount > 0 ? (
    <div
      className={WebUI.cn(
        'bg-orange-50 py-4 text-center text-natural-100',
        className,
      )}
      {...restProps}
    >
      Collection Balance Alert: {Util.formatAmount(totalDisputeAmount)} is
      currently unable to be withdrawn due to a dispute.{' '}
      <Link
        className="underline"
        to={`/collection/${disputedCollectionId}/manage/i/collections/${disputedCollectionId}/summary`}
      >
        Go to collection
      </Link>
      .
    </div>
  ) : null
}

export default WithdrawPage
