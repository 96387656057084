import {ForwardRefComponent, genericForwardRef} from '@cheddarup/react-util'
import {Input as ReakitInput} from 'reakit'
import React from 'react'
import {SimpleMerge} from '@cheddarup/util'
import {cva} from 'class-variance-authority'

import {InlineEdit, InlineEditProps, _InlineEditProps} from './InlineEdit'
import {VariantsProps, cn} from '../utils'

export const input = cva(
  [
    `min-w-[4rem] max-w-full appearance-none border-none font-body font-light
    leading-[inherit]`,
    'transition-colors duration-100 ease-in-out',
    'placeholder:text-inputPlaceholder',
    'aria-invalid:border-orange-50',
    'focus:outline-none',
    'disabled:cursor-not-allowed aria-disabled:cursor-not-allowed',
  ],
  {
    variants: {
      variant: {
        default: [
          'px-2 text-gray800 shadow-[inset_0_0_0_1px_theme(colors.natural.70)]',
          'aria-invalid:shadow-[inset_0_0_0_1px_theme(colors.orange.50)]',
          'focus:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
          'hover:[&:not(:focus):not([aria-invalid=true]):not(:disabled):not([aria-disabled=true])]:bg-inputHoverBackground',
        ],
        underlined: [
          'shadow-[inset_0px_-2px_0px_0px_theme(colors.natural.70)]',
          'aria-invalid:shadow-[inset_0px_-2px_0px_0px_theme(colors.orange.50)]',
          'focus:shadow-[inset_0px_-2px_0px_0px_theme(colors.teal.50)]',
          'hover:[&:not(:focus):not([aria-invalid=true]):not(:disabled):not([aria-disabled=true])]:bg-inputHoverBackground',
        ],
        headless: '',
      },
      disabledVariant: {
        default: [
          'disabled:bg-inherit disabled:opacity-50',
          'aria-disabled:bg-inherit aria-disabled:opacity-50',
        ],
        filled: ['disabled:bg-natural-80', 'aria-disabled:bg-natural-80'],
      },
      size: {
        default: 'min-h-10 py-2 text-ds-base',
        compact: 'min-h-9 py-1 text-ds-sm',
        small: 'min-h-[30px] py-1 text-ds-xs',
        large: 'min-h-11 py-sm text-ds-md',
        xl: 'h-13 px-7 text-ds-base',
      },
      roundness: {
        default: 'rounded',
        capsule: 'rounded-extended',
      },
    },
    defaultVariants: {
      variant: 'default',
      disabledVariant: 'default',
      size: 'default',
      roundness: 'default',
    },
  },
)

export interface InputProps extends VariantsProps<typeof input> {}

export const Input = React.forwardRef(
  (
    {
      className,
      roundness = 'default',
      variant = 'default',
      size = 'default',
      disabledVariant = 'default',
      ...restProps
    },
    forwardedRef,
  ) => (
    <ReakitInput
      ref={forwardedRef}
      className={cn(
        `Input Input--${variant}-${size}`,
        input({variant, size, roundness, disabledVariant}),
        className,
      )}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'input', InputProps>

// MARK: - InlineEditInput

export type InlineEditInputProps<P, T> = SimpleMerge<
  Omit<InlineEditProps<P>, 'DisplayComponent' | 'InputComponent'>,
  {
    defaultValue?: string
    value?: string
    formatValue?: (value: string) => string
    DisplayComponent?: _InlineEditProps<P>['DisplayComponent']
    InputComponent?: T
  }
>

export const InlineEditInput = genericForwardRef(
  <T, P = T extends React.ComponentType<infer _P> ? _P : never>(
    {
      className,
      DisplayComponent = ({DefaultDisplayComponent}) => (
        <DefaultDisplayComponent />
      ),
      InputComponent = Input as any,
      inputSize = 'compact',
      inputVariant = 'default',
      formatValue,
      ...restProps
    }: InlineEditInputProps<P, T> & {ref?: React.Ref<T>},
    forwardedRef: React.Ref<T>,
  ) => {
    const value = restProps.value ?? restProps.defaultValue ?? ''
    return (
      <InlineEdit<T, P>
        ref={forwardedRef}
        InputComponent={InputComponent}
        DisplayComponent={({DefaultDisplayComponent}) => (
          <DisplayComponent
            DefaultDisplayComponent={(displayProps) => (
              <DefaultDisplayComponent
                content={value ? formatValue?.(value) ?? value : value}
                {...displayProps}
              />
            )}
          />
        )}
        className={cn(
          'InlineEditInput',
          '[&_>_.InlineEdit-input_>_.Input[class]]:!h-auto [&_>_.InlineEdit-input_>_.Input[class]]:min-h-full [&_>_.InlineEdit-input_>_.Input[class]]:min-w-full',
          className,
        )}
        inputSize={inputSize}
        inputVariant={inputVariant}
        {...(restProps as any)}
      />
    )
  },
)
