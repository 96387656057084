import React, {useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {
  useReplicateTabMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {CollectionListItemImage} from 'src/components'
import {
  computeTabStatus,
  formatTabStatus,
  getTabDateLabel,
} from '@cheddarup/core'
import {useForkRef} from '@cheddarup/react-util'

export interface CollectionListItemProps
  extends WebUI.DataRowComponentProps<Api.Tab> {
  collectionCreateAllowed: boolean
  managerPermissionRole?: Api.ManagerPermissionRole
  onClose: () => void
  onDelete: () => void
}

const CollectionListItem: React.FC<CollectionListItemProps> = React.forwardRef(
  (
    {
      collectionCreateAllowed,
      onClose,
      onDelete,
      managerPermissionRole = 'admin',
      row,
      setSelected,
      ...restProps
    },
    forwardedRef,
  ) => {
    const media = WebUI.useMedia()
    const growlActions = WebUI.useGrowlActions()
    const updateCollectionMutation = useUpdateTabMutation()
    const replicateCollectionMutation = useReplicateTabMutation()
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    const collection = row?.original

    const collectedLabel = (
      <WebUI.Text className="text-ds-sm">
        {collection ? (
          collection.status === 'draft' ? (
            <>&nbsp;</>
          ) : (
            <WebUI.HStack className="gap-2">
              {(collection.active_items || !collection.forms_and_signups) && (
                <span>
                  Collected: {Util.formatAmount(collection.payments_total)}
                </span>
              )}
              {collection.forms_and_signups && (
                <span>Respondents: {collection.respondents}</span>
              )}
            </WebUI.HStack>
          )
        ) : (
          <WebUI.Skeleton />
        )}
      </WebUI.Text>
    )

    return (
      <WebUI.Card
        ref={ref}
        dragHandleVisible
        as={WebUI.Draggable}
        dragData={collection}
        accessoryView={
          replicateCollectionMutation.isPending ||
          updateCollectionMutation.isPending ? (
            <WebUI.VStack className="min-w-0 grow gap-1 sm:gap-2">
              <WebUI.Loader size="2em" />
            </WebUI.VStack>
          ) : (
            collection && (
              <WebUI.HStack className="items-start gap-2">
                {!media.sm && (
                  <WebUI.VStack className="gap-2">
                    <TabStatusTag tab={collection} />
                    {collection.template_id != null &&
                      collection.status === 'draft' && (
                        <TabStatusTag
                          className="[&.TabStatusTag]:bg-[#F9C442]"
                          tab={collection}
                        >
                          Template
                        </TabStatusTag>
                      )}
                  </WebUI.VStack>
                )}
                <WebUI.ActionGroup>
                  <WebUI.Action
                    as={LinkButton}
                    icon={<WebUI.PhosphorIcon icon="pencil" />}
                    to={
                      managerPermissionRole === 'viewer'
                        ? `/collection/${collection.id}/manage`
                        : `/collection/${collection.id}/details`
                    }
                  >
                    Edit
                  </WebUI.Action>

                  <WebUI.Action
                    icon={<WebUI.PhosphorIcon icon="link-simple" />}
                    onClick={() => {
                      WebUI.copyToClipboard(
                        `${window.location.host}/c/${collection.slug}`,
                      )

                      growlActions.show('success', {
                        title: 'Success',
                        body: 'Link copied',
                      })
                    }}
                  >
                    <span>Copy URL</span>
                  </WebUI.Action>
                  {managerPermissionRole !== 'viewer' && (
                    <>
                      {collectionCreateAllowed && (
                        <WebUI.Action
                          icon={<WebUI.PhosphorIcon icon="copy" />}
                          loading={replicateCollectionMutation.isPending}
                          execute={async () => {
                            if (collection.is_pro || !collection.requires_pro) {
                              await replicateCollectionMutation.mutateAsync({
                                pathParams: {
                                  tabId: collection.id,
                                },
                                body: {
                                  name: `${collection.name} (Copy)`,
                                  updated_at: new Date().toISOString(),
                                },
                              })
                            } else {
                              growlActions.show('error', {
                                body: 'This collection uses PRO features. To replicate it, upgrade to PRO.',
                                title: 'You have to be PRO',
                              })
                            }
                          }}
                        >
                          Replicate
                        </WebUI.Action>
                      )}

                      <WebUI.Action
                        icon={
                          collection.closed_at ? (
                            <WebUI.PhosphorIcon icon="eye-closed" />
                          ) : (
                            <WebUI.PhosphorIcon icon="eye" />
                          )
                        }
                        execute={async () => {
                          if (collection.closed_at) {
                            await updateCollectionMutation.mutateAsync({
                              pathParams: {
                                tabId: collection.id,
                              },
                              body: {
                                closed_at: null,
                              },
                            })
                          } else {
                            onClose()
                          }
                        }}
                      >
                        {collection.closed_at ? 'Reopen' : 'Close'}
                      </WebUI.Action>
                      <WebUI.Action
                        aria-disabled={!collection.access.canDelete}
                        icon={<WebUI.PhosphorIcon icon="trash" />}
                        onClick={() => {
                          if (collection.access.canDelete) {
                            onDelete()
                          }
                        }}
                      >
                        {collection.access.canDelete ? (
                          'Delete'
                        ) : (
                          <WebUI.DeprecatedTooltip
                            label={
                              collection.access.owner
                                ? 'Pending balance available'
                                : 'Only the owner can delete this collection'
                            }
                          >
                            <span>Delete</span>
                          </WebUI.DeprecatedTooltip>
                        )}
                      </WebUI.Action>
                    </>
                  )}
                </WebUI.ActionGroup>
              </WebUI.HStack>
            )
          )
        }
        {...restProps}
      >
        <Link
          className="flex items-stretch justify-start py-4 pr-8 pl-3"
          to={collection ? `/collection/${collection.id}/manage` : '.'}
        >
          <WebUI.HStack className="min-w-0 max-w-full shrink-0 grow basis-auto items-center justify-between gap-3">
            <WebUI.VStack className="flex-auto items-start justify-center gap-6 overflow-hidden sm:flex-row sm:items-center sm:justify-start">
              <CollectionListItemImage
                width={94}
                height={94}
                image={collection?.featured_image}
              />
              <WebUI.VStack className="min-w-0 grow gap-3">
                <WebUI.VStack>
                  {collection ? (
                    <WebUI.Ellipsis className="text-ds-md text-linkPrimaryText">
                      {collection.name}
                    </WebUI.Ellipsis>
                  ) : (
                    <WebUI.Skeleton width={220} height={12} />
                  )}
                  <WebUI.HStack className="inline gap-2 font-light text-ds-xs">
                    <WebUI.Ellipsis className="font-light text-ds-xs">
                      {collection ? (
                        getTabDateLabel(collection)
                      ) : (
                        <WebUI.Skeleton width={160} height={12} />
                      )}
                    </WebUI.Ellipsis>
                    {collection && <TabUpgradeRequiredLabel tab={collection} />}
                  </WebUI.HStack>
                </WebUI.VStack>

                {collection?.access.owner &&
                !!collection?.withdrawal_balance_available ? (
                  <WebUI.DeprecatedTooltip
                    className="self-start"
                    variant="light"
                    placement="bottom"
                    label={
                      <>
                        Available to withdraw:{' '}
                        <Link variant="primary" to="/withdraw">
                          {Util.formatAmount(
                            collection.withdrawal_balance_available,
                          )}
                        </Link>
                      </>
                    }
                  >
                    {collectedLabel}
                  </WebUI.DeprecatedTooltip>
                ) : (
                  collectedLabel
                )}
              </WebUI.VStack>
            </WebUI.VStack>
            {media.sm && (
              <>
                {collection ? (
                  <WebUI.VStack className="gap-2">
                    <TabStatusTag tab={collection} />
                    {collection.template_id != null &&
                      collection.status === 'draft' && (
                        <TabStatusTag
                          className="[&.TabStatusTag]:bg-[#F9C442]"
                          tab={collection}
                        >
                          Template
                        </TabStatusTag>
                      )}
                  </WebUI.VStack>
                ) : (
                  <WebUI.Skeleton width={80} height={20} />
                )}
              </>
            )}
          </WebUI.HStack>
        </Link>
      </WebUI.Card>
    )
  },
)

// MARK: – TabStatusTag

export interface TabStatusTagProps extends WebUI.TagProps {
  tab: Api.Tab
}

export const TabStatusTag = React.forwardRef<HTMLDivElement, TabStatusTagProps>(
  (
    {tab, className, children = formatTabStatus(tab), ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Tag
      data-status={computeTabStatus(tab)}
      ref={forwardedRef}
      className={WebUI.cn(
        'TabStatusTag',
        'h-[30px] w-18 border-none text-ds-xs text-gray800',
        'data-[status=active]:bg-teal-70',
        'data-[status=closed]:bg-natural-70',
        'data-[status=draft]:bg-natural-80',
        className,
      )}
      {...restProps}
    >
      {children}
    </WebUI.Tag>
  ),
)

// MARK: – TabUpgradeRequiredLabel

interface TabUpgradeRequiredLabelProps
  extends Omit<React.ComponentPropsWithoutRef<'span'>, 'children'> {
  tab: Api.Tab
}

const TabUpgradeRequiredLabel = ({
  tab,
  className,
  ...restProps
}: TabUpgradeRequiredLabelProps) => {
  if (
    (!tab.requires_pro || tab.is_pro) &&
    (!tab.requires_team || tab.is_team)
  ) {
    return null
  }
  if (tab.status === 'draft') {
    return null
  }

  return (
    <WebUI.DeprecatedTooltip
      className={WebUI.cn('text-orange-50', className)}
      label={`This collection uses ${
        tab.requires_team && !tab.is_team ? 'team' : 'pro'
      } features. Upgrade now to reactivate this collection.`}
      {...restProps}
    >
      <WebUI.HStack className="inline items-center gap-0_5">
        <WebUI.PhosphorIcon
          className="align-middle"
          icon="warning-circle-fill"
        />
        <span>Upgrade Required</span>
      </WebUI.HStack>
    </WebUI.DeprecatedTooltip>
  )
}

export default CollectionListItem
