import * as Util from '@cheddarup/util'
import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useMemo, useRef, useState} from 'react'
import {useUpdateEffect} from '@cheddarup/react-util'
import {
  api,
  useDeleteRecurringPaymentContractMutation,
  useUpdateRecurringPaymentContractCard,
} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'

import RecurringPaymentFormatter from '../helpers/RecurringPaymentFormatter'

export interface InvoiceDetailsProps extends WebUI.ModalProps {
  payment: Api.TabPayment | Api.PaymentToOthers
  cards?: Api.CreditCard[]
  banks?: Api.BankAccount[]
}

const InvoiceDetails = ({
  payment,
  cards = [],
  banks = [],
  ...restProps
}: InvoiceDetailsProps) => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const cancelFuturePaymentsAlertRef = useRef<WebUI.DialogInstance>(null)

  const scheduledInvoice =
    urlParams.invoice == null
      ? null
      : payment?.scheduled_invoices[Number(urlParams.invoice)]
  const recurringPaymentContractId =
    payment?.recurring_payment_invoice?.recurring_payment_contract?.id ??
    scheduledInvoice?.recurring_payment_contract_id ??
    null

  const {data: recurringContract, isPending: isRecurringContractPending} =
    api.recurringPaymentContracts.detail.useQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          contractId: recurringPaymentContractId!,
        },
      },
      {
        enabled: recurringPaymentContractId != null,
      },
    )
  const deleteContractMutation = useDeleteRecurringPaymentContractMutation()
  const updateContractCardMutation = useUpdateRecurringPaymentContractCard()

  const recurringLabels = recurringContract?.options
    ? RecurringPaymentFormatter.getLabels(recurringContract.options)
    : null

  const nextInvoice = useMemo(
    () =>
      recurringContract?.metadata.next_invoice ||
      Util.firstBy(
        recurringContract?.recurring_payment_invoices?.filter(
          ({status}) => status === 'scheduled',
        ) ?? [],
        ({metadata}) => new Date(metadata.due_date).getTime(),
      )?.metadata.due_date,
    [recurringContract],
  )

  const hasNextPayment =
    recurringContract?.status !== 'cancelled' &&
    (recurringContract?.status !== 'completed' || nextInvoice)

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    recurringContract?.stripe_source ?? cards[0]?.id ?? banks[0]?.id,
  )

  useUpdateEffect(() => {
    setSelectedPaymentMethodId(recurringContract?.stripe_source)
  }, [recurringContract?.stripe_source])

  return (
    <WebUI.Modal
      aria-label="Scheduled invoice details"
      className="[&_>_.ModalContentView]:p-8 sm:[&_>_.ModalContentView]:max-w-[512px]"
      onDidHide={() => navigate('..')}
      {...restProps}
    >
      {(modal) => (
        <>
          <WebUI.ModalCloseButton />

          {(!recurringContract || isRecurringContractPending) && (
            <WebUI.VStack className="h-[280px] items-center justify-center">
              <WebUI.Loader />
            </WebUI.VStack>
          )}
          {!!recurringContract && !isRecurringContractPending && (
            <WebUI.VStack className="gap-2">
              <WebUI.Heading as="h4">
                {recurringContract.tab_item.name}
              </WebUI.Heading>
              <main>
                <div className="text-ds-sm">
                  Amount:{' '}
                  {Util.formatAmount(
                    recurringContract.metadata.amount_pennies / 100,
                  )}
                </div>
                <div className="text-ds-sm">
                  Repeating Every:{' '}
                  {recurringLabels?.repeat
                    ? Util.startCase(
                        `${
                          Number.isNaN(
                            Number.parseInt(recurringLabels.repeat, 10),
                          )
                            ? '1 '
                            : ''
                        }${recurringLabels.repeat}`,
                      )
                    : ''}
                </div>
                <div className="text-ds-sm">
                  Starting: {recurringLabels?.start ?? ''}
                </div>
                <div className="text-ds-sm">
                  Ending: {recurringLabels?.end ?? ''}
                </div>
                <div className="text-ds-sm">
                  Next Payment:{' '}
                  {hasNextPayment
                    ? nextInvoice
                      ? Util.formatDateAs(nextInvoice)
                      : RecurringPaymentFormatter.getLabelsNextFormatted({
                          start: recurringContract.options.start,
                          repeat: recurringContract.options.repeatInterval,
                        })
                    : Util.capitalize(recurringContract.status)}
                </div>
                {hasNextPayment ? (
                  <WebUI.Button
                    className="text-ds-sm"
                    variant="link"
                    onClick={() => cancelFuturePaymentsAlertRef.current?.show()}
                  >
                    Cancel future payments
                  </WebUI.Button>
                ) : null}
                <WebUI.DropdownSelect<string>
                  className="mt-[1.5rem] mb-[2rem] w-[232px]"
                  size="compact"
                  placeholder="Select payment method"
                  value={selectedPaymentMethodId}
                  onValueChange={(cardId) => setSelectedPaymentMethodId(cardId)}
                >
                  {cards.map((c) => (
                    <WebUI.DropdownSelectOption key={c.id} value={c.id}>
                      {c.nickname || `Credit card ending in ${c.last4}`}
                    </WebUI.DropdownSelectOption>
                  ))}
                  {banks.map((b) => (
                    <WebUI.DropdownSelectOption key={b.id} value={b.id}>
                      {`Bank account ending in ${b.last4}`}
                    </WebUI.DropdownSelectOption>
                  ))}
                  <WebUI.DropdownSelectOption
                    value=""
                    as={Link}
                    variant="primary"
                    to="my-account/payment-methods"
                  >
                    Add a new payment method
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
                <WebUI.HStack className="gap-3">
                  <WebUI.Button
                    type="submit"
                    loading={
                      deleteContractMutation.isPending ||
                      updateContractCardMutation.isPending
                    }
                    onClick={async () => {
                      if (selectedPaymentMethodId) {
                        await updateContractCardMutation.mutateAsync({
                          pathParams: {
                            contractId: recurringContract.id,
                          },
                          body: {stripe_source: selectedPaymentMethodId},
                        })
                        modal.hide()
                      }
                    }}
                  >
                    Save
                  </WebUI.Button>
                  <WebUI.Button
                    type="button"
                    variant="secondary"
                    onClick={() => modal.hide()}
                  >
                    Cancel
                  </WebUI.Button>
                </WebUI.HStack>
                <WebUI.Alert
                  ref={cancelFuturePaymentsAlertRef}
                  aria-label="Cancel future payments confirmation"
                >
                  <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
                  <WebUI.AlertContentView
                    text={
                      <>
                        You’re about to cancel all future payments for{' '}
                        <strong className="font-bold">
                          {recurringContract.tab_item.name}
                        </strong>
                      </>
                    }
                    actions={
                      <>
                        <WebUI.AlertActionButton
                          execute={() =>
                            deleteContractMutation.mutateAsync({
                              pathParams: {
                                contractId: recurringContract.id,
                              },
                            })
                          }
                        >
                          Yes, Cancel Payments
                        </WebUI.AlertActionButton>
                        <WebUI.AlertCancelButton>
                          No, Don't Cancel
                        </WebUI.AlertCancelButton>
                      </>
                    }
                  />
                </WebUI.Alert>
              </main>
            </WebUI.VStack>
          )}
        </>
      )}
    </WebUI.Modal>
  )
}

export default InvoiceDetails
