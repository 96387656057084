import * as Yup from 'yup'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  useAsyncEffect,
  useFormik,
  useLiveRef,
  usePrevious,
  useUpdateEffect,
} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageSectionMutation,
  useDeleteTopBannerImageMutation,
  useUpdateGroupPageSectionMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {guessError} from 'src/helpers/error-utils'
import {SharpImage} from 'src/components/SharpImage'
import AddBannerImageIcon from 'src/images/AddBannerImageIcon.svg'
import {TopBannerBackgroundImagePickerModal} from './TopBannerBackgroundImagePickerModal'
import {UploadPinturaImageFormModal} from 'src/components/UploadPinturaImageForm'
import {getBrandKitColors, uploadImage} from '@cheddarup/core'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {BlobImage} from '../TeamSectionPage/TeamMemberForm'
import {useUserSlug} from 'src/components/ManageRoleProvider'

export interface TopBannerFormValues {
  headline: string
  tagline: string
  backgroundImage: Api.S3Image | WebUI.PinturaDefaultImageWriterResult | null
  complementaryColor: string | null
  bannerLogo: Api.S3Image | WebUI.PinturaDefaultImageWriterResult | null
}

export interface TopBannerFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  onDidSucceed?: () => void
}

export const AddTopBannerForm: React.FC<TopBannerFormProps> = ({
  onDidSucceed,
  className,
  ...restProps
}) => {
  const [managerRoleId] = useManagerRoleId()
  const userSlug = useUserSlug()
  const topBannerQuery = api.groupPageSections.detail.useQuery({
    pathParams: {
      sectionName: 'top_banner',
    },
  })
  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()
  const deleteTopBannerImageMutation = useDeleteTopBannerImageMutation()
  const growlActions = WebUI.useGrowlActions()
  const uploadPinturaImageFormModalRef = useRef<WebUI.DialogInstance>(null)
  const [localLogo, setLocalLogo] = useState<Blob | null>(null)
  const brandKitColorsQuery = api.groupPages.detail.useQuery(undefined, {
    select: (groupPage) => getBrandKitColors(groupPage.branding),
  })

  const topBanner = topBannerQuery.data

  const formik = useFormik<TopBannerFormValues>({
    validationSchema: Yup.object().shape({
      headline: Yup.string().required(),
      tagline: Yup.string(),
    }),
    initialValues: {
      headline: topBanner?.headline || '',
      tagline: topBanner?.tagline || '',
      bannerLogo: topBanner?.logo ?? null,
      backgroundImage: topBanner?.background_image ?? null,
      complementaryColor: topBanner?.details.complementaryColor ?? null,
    },
    onSubmit: async (values) => {
      try {
        const payload = {
          tagline: values.tagline,
          headline: values.headline,
          details: {
            backgroundImageId: topBanner?.details.backgroundImageId,
            complementaryColor: values.complementaryColor ?? undefined,
            logoId: topBanner?.details.logoId,
          },
        }

        if (!topBanner) {
          await createGroupPageSectionMutation.mutateAsync({
            pathParams: {sectionName: 'top_banner'},
            body: {
              ...payload,
              background_color: brandKitColorsQuery.data?.primary_color,
            },
          })
        }

        if (
          ((values.bannerLogo && 'dest' in values.bannerLogo) ||
            !values.bannerLogo) &&
          topBanner?.details.logoId
        ) {
          await deleteTopBannerImageMutation.mutateAsync({
            pathParams: {id: topBanner.details.logoId},
          })
          payload.details.logoId = undefined
        }
        if (values.bannerLogo && 'dest' in values.bannerLogo) {
          const uploadedLogo = await uploadImage(
            'users/group_page/top_banner',
            values.bannerLogo.dest,
            undefined,
            {
              userId: managerRoleId ?? undefined,
              metadata: {
                type: 'logo',
                pintura: values.bannerLogo.imageState,
              },
            },
          )

          payload.details.logoId = uploadedLogo.id
        }

        if (
          ((values.backgroundImage && 'dest' in values.backgroundImage) ||
            !values.backgroundImage) &&
          topBanner?.details.backgroundImageId
        ) {
          await deleteTopBannerImageMutation.mutateAsync({
            pathParams: {id: topBanner.details.backgroundImageId},
          })
          payload.details.backgroundImageId = undefined
        }
        if (values.backgroundImage && 'dest' in values.backgroundImage) {
          const uploadedBackgroundImage = await uploadImage(
            'users/group_page/top_banner',
            values.backgroundImage.dest,
            undefined,
            {
              userId: managerRoleId ?? undefined,
              metadata: {
                type: 'backgroundImage',
                pintura: values.backgroundImage.imageState,
              },
            },
          )

          payload.details.backgroundImageId = uploadedBackgroundImage.id
        }

        await updateGroupPageSectionMutation.mutateAsync({
          pathParams: {sectionName: 'top_banner'},
          body: {
            ...payload,
            userSlug,
          },
        })

        onDidSucceed?.()
      } catch (err) {
        growlActions.show('error', {
          body: guessError(err).message,
        })
      }
    },
  })

  const topBannerRef = useLiveRef(topBannerQuery.data)
  useUpdateEffect(() => {
    if (topBannerQuery.isSuccess) {
      formik.setValues({
        headline: topBannerRef.current?.headline ?? '',
        tagline: topBannerRef.current?.tagline ?? '',
        bannerLogo: topBannerRef.current?.logo ?? null,
        backgroundImage: topBannerRef.current?.background_image ?? null,
        complementaryColor:
          topBannerRef.current?.details.complementaryColor ?? null,
      })
    }
  }, [topBannerQuery.isSuccess, formik.setValues])

  const backgroundImageUrl = useMemo(() => {
    if (!formik.values.backgroundImage) {
      return null
    }

    return 'id' in formik.values.backgroundImage
      ? formik.values.backgroundImage.edited_image_url ??
          formik.values.backgroundImage.url
      : URL.createObjectURL(formik.values.backgroundImage.dest)
  }, [formik.values.backgroundImage])

  useEffect(() => {
    return () => {
      if (backgroundImageUrl) {
        URL.revokeObjectURL(backgroundImageUrl)
      }
    }
  }, [backgroundImageUrl])

  const currentBgImageDominantColors = useAsyncEffect(async () => {
    if (!backgroundImageUrl) {
      throw new Error('`backgroundImageUrl` missing')
    }

    const bgImageColors = (await WebUI.getDominantColorsFromImage(
      backgroundImageUrl,
      {
        amount: 6,
        group: 50,
        format: 'hex',
      },
    )) as string[]

    return bgImageColors
  }, [backgroundImageUrl])

  const prevBgImageDominantColors = usePrevious(currentBgImageDominantColors)
  const bgImageDominantColors = Util.isSuccess(currentBgImageDominantColors)
    ? currentBgImageDominantColors
    : prevBgImageDominantColors ?? currentBgImageDominantColors

  useEffect(() => {
    if (
      Util.isSuccess(bgImageDominantColors) &&
      (formik.values.complementaryColor == null ||
        !bgImageDominantColors.value.includes(formik.values.complementaryColor))
    ) {
      formik.setFieldValue(
        'complementaryColor',
        bgImageDominantColors.value[0] ?? null,
      )
    }
  }, [
    bgImageDominantColors,
    formik.values.complementaryColor,
    formik.setFieldValue,
  ])

  return (
    <form
      className={WebUI.cn('flex min-h-0 grow flex-col', className)}
      noValidate
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <div className="flex min-h-0 flex-auto flex-col gap-6 overflow-y-auto px-12 py-8">
        <WebUI.Panel className="flex max-w-screen-sm flex-0 flex-col gap-8 px-9 py-8">
          <WebUI.Heading as="h5">Headline Block</WebUI.Heading>

          <div className="flex flex-row gap-6">
            <UploadPinturaImageFormModal
              key={topBanner?.logo?.id}
              ref={uploadPinturaImageFormModalRef}
              parentPath="users/group_page/top_banner"
              imageType="logo"
              imageCropAspectRatio={1 / 1}
              imageCropLimitToImage={false}
              noOriginalImage
              utils={['crop', 'filter', 'finetune', 'fill']}
              uploaderOptions={['upload']}
              initialImage={topBanner?.logo}
              image={localLogo ?? topBanner?.logo}
              onSubmitAsPinturaRes={(pinturaRes) =>
                formik.setFieldValue('bannerLogo', pinturaRes)
              }
              disclosure={
                formik.values.bannerLogo ? (
                  <div className="relative">
                    <WebUI.DialogDisclosure
                      className="cursor-pointer overflow-hidden rounded-default"
                      as="button"
                    >
                      {'id' in formik.values.bannerLogo ? (
                        <SharpImage
                          alt="Top banner logo"
                          height={170}
                          width={170}
                          image={formik.values.bannerLogo}
                        />
                      ) : (
                        <BlobImage
                          alt="Top banner logo"
                          height={170}
                          width={170}
                          blob={formik.values.bannerLogo.dest}
                        />
                      )}
                    </WebUI.DialogDisclosure>
                    <WebUI.IconButton
                      className="absolute top-2 right-2 text-ds-md"
                      size="default_alt"
                      variant="secondary"
                      onClick={() => {
                        formik.setFieldValue('bannerLogo', null)
                        setLocalLogo(null)
                      }}
                    >
                      <WebUI.PhosphorIcon icon="x" />
                    </WebUI.IconButton>
                  </div>
                ) : (
                  <WebUI.FileUploader
                    accept={{'image/*': []}}
                    onDropAccepted={([imageFile]) => {
                      if (imageFile) {
                        uploadPinturaImageFormModalRef.current?.show()
                        setLocalLogo(imageFile)
                      }
                    }}
                  >
                    <WebUI.FileUploaderInput />
                    <WebUI.FileUploaderButton
                      className="!h-[170px] w-[170px] border border-solid [&_.Button-content]:max-w-24 [&_.Button-content]:flex-0 [&_.Button-content]:whitespace-normal"
                      orientation="vertical"
                      variant="secondary"
                      iconBefore={
                        <WebUI.PhosphorIcon
                          className="text-ds-2xl text-teal-50"
                          icon="upload-simple"
                        />
                      }
                    >
                      Upload a small image or logo
                    </WebUI.FileUploaderButton>
                  </WebUI.FileUploader>
                )
              }
            />

            <div className="flex grow flex-col gap-5">
              <WebUI.FormField
                required
                label="Headline"
                error={formik.errors.headline}
              >
                <WebUI.Input
                  name="headline"
                  maxLength={45}
                  placeholder="Large headline text"
                  value={formik.values.headline}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField label="Tagline" error={formik.errors.tagline}>
                <WebUI.Input
                  name="tagline"
                  maxLength={80}
                  placeholder="Smaller text under headline"
                  value={formik.values.tagline}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </div>
          </div>
        </WebUI.Panel>

        <WebUI.Panel className="flex max-w-screen-sm flex-0 flex-col gap-8 px-9 py-8">
          <div className="flex flex-col gap-4">
            <WebUI.Heading as="h5">Background Image</WebUI.Heading>
            <span>
              We’ll show this image behind your headline block. If you don’t
              upload an image, a solid color will be used.
            </span>
          </div>

          <TopBannerBackgroundImagePickerModal
            initialImage={topBanner?.background_image}
            onImageSubmit={(newImage) =>
              formik.setFieldValue('backgroundImage', newImage)
            }
            disclosure={
              formik.values.backgroundImage ? (
                <div className="relative">
                  <WebUI.DialogDisclosure
                    className="cursor-pointer overflow-hidden rounded-default"
                    as="button"
                  >
                    {'id' in formik.values.backgroundImage ? (
                      <SharpImage
                        alt="Top banner background"
                        image={formik.values.backgroundImage}
                      />
                    ) : (
                      <BlobImage
                        alt="Top banner background"
                        blob={formik.values.backgroundImage.dest}
                      />
                    )}
                  </WebUI.DialogDisclosure>
                  <WebUI.IconButton
                    className="absolute top-4 right-4 text-ds-lg"
                    size="default_alt"
                    variant="secondary"
                    onClick={() =>
                      formik.setFieldValue('backgroundImage', null)
                    }
                  >
                    <WebUI.PhosphorIcon icon="x" />
                  </WebUI.IconButton>
                </div>
              ) : (
                <WebUI.DialogDisclosure
                  className="[&_>_.Button-iconBefore]:!mr-4 aspect-[3/1] h-auto w-full justify-start bg-gray100 px-12 [&_>_.Button-content]:flex-0"
                  variant="secondary"
                  iconBefore={
                    <WebUI.Image
                      height="3.5em"
                      alt=""
                      src={AddBannerImageIcon}
                    />
                  }
                >
                  Upload a banner image for your banner
                </WebUI.DialogDisclosure>
              )
            }
          />
        </WebUI.Panel>

        {formik.values.backgroundImage &&
          Util.isSuccess(bgImageDominantColors) && (
            <WebUI.Panel className="flex max-w-screen-sm flex-0 flex-col gap-6 px-8 py-7">
              <div className="flex flex-col gap-3">
                <WebUI.Heading as="h5">
                  Select a complementary color
                </WebUI.Heading>
                <span className="text-ds-sm">
                  We’ll display a solid color on either side of your image on
                  larger screen sizes. Select from the palette below:
                </span>
              </div>

              <WebUI.ColorSwatchPicker
                className="items-center gap-4"
                name="complementaryColor"
                value={formik.values.complementaryColor ?? undefined}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {!!bgImageDominantColors.value[0] && (
                  <WebUI.ColorSwatchPickerItem
                    className="size-16 rounded-full"
                    color={bgImageDominantColors.value[0]}
                  />
                )}
                <div className="flex flex-row gap-3">
                  {bgImageDominantColors.value.slice(1).map((color) => (
                    <WebUI.ColorSwatchPickerItem
                      key={color}
                      className="size-9 rounded-full"
                      color={color}
                    />
                  ))}
                </div>
              </WebUI.ColorSwatchPicker>
              <div
                className="flex items-center justify-center"
                style={{
                  backgroundColor:
                    formik.values.complementaryColor ?? '#FFFFFF',
                }}
              >
                {'id' in formik.values.backgroundImage ? (
                  <SharpImage
                    className="[&_img]:!w-full object-contain"
                    alt="Banner preview"
                    width="90%"
                    height="100%"
                    image={formik.values.backgroundImage}
                  />
                ) : (
                  <BlobImage
                    className="[&_img]:!w-full object-contain"
                    alt="Banner preview"
                    width="90%"
                    height="100%"
                    blob={formik.values.backgroundImage.dest}
                  />
                )}
              </div>
            </WebUI.Panel>
          )}
      </div>

      <div className="flex flex-row justify-end border-t bg-natural-100 px-4 py-5">
        <WebUI.Button
          type="submit"
          variant="primary"
          size="large"
          loading={formik.isSubmitting}
        >
          Save
        </WebUI.Button>
      </div>
    </form>
  )
}
