import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {uploadCartFile, uploadSignatureBase64} from 'src/helpers/file-uploads'
import {useLiveRef, withProps} from '@cheddarup/react-util'
import {
  getLocalTimeZone,
  toCalendarDateTime,
  today,
} from '@internationalized/date'

const fakeFieldSetId = Util.makeShortId()

export type FieldSetListVariant = 'payer' | 'organizer'
export type FieldSetListSize = 'default' | 'compact'

export type FieldValue = string | File | null

export interface FieldSetListDatum
  extends Util.SetOptional<Api.FieldSet, 'uuid'> {
  id: string
  fields: FieldSetListField[]
}

export interface FieldSetListField extends Api.PublicTabObjectField {
  error?: string
}

export interface FieldSetListInstance {
  scrollToField: (fieldId: number) => void
}

export interface FieldSetListProps
  extends Omit<WebUI.ListProps<FieldSetListDatum>, 'data' | 'RowComponent'> {
  size?: FieldSetListSize
  variant?: FieldSetListVariant
  fieldSets?: Api.FieldSet[]
  fields: Api.PublicTabObjectField[] | Api.TabObjectField[]
  defaultValues: Record<number, string>
  errors?: Record<number, string>
  onValuesChange?: (fieldId: number, value: FieldValue) => void
}

export const FieldSetList = React.forwardRef<
  FieldSetListInstance,
  FieldSetListProps
>(
  (
    {
      variant = 'payer',
      size = 'default',
      fieldSets = [],
      fields,
      defaultValues,
      errors,
      onValuesChange,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const listRef = useRef<WebUI.ListInstance>(null)

    const [frozenDefaultValues] = useState(defaultValues)
    const onValuesChangeRef = useLiveRef(onValuesChange)

    const data: FieldSetListDatum[] = useMemo(
      () =>
        normalizeFieldSets({fields, fieldSets}).map((fs) => ({
          ...fs,
          fields: fs.fields.map((f) => ({
            ...f,
            error: errors?.[f.id],
          })),
        })),
      [errors, fields, fieldSets],
    )

    useImperativeHandle(
      forwardedRef,
      () => ({
        scrollToField: (fieldId) => {
          const fieldSetUuid = data.find((fs) =>
            fs.fields.some((f) => f.id === fieldId),
          )?.uuid

          if (fieldSetUuid != null) {
            listRef.current?.scrollToRow(fieldSetUuid)
          }
        },
      }),
      [data],
    )

    const handleValuesChange = onValuesChangeRef.current
      ? useCallback(
          (fieldId: number, value: FieldValue) =>
            onValuesChangeRef.current?.(fieldId, value),
          [],
        )
      : undefined

    const FieldSetListRowWithHandlers: WebUI.ListRowComponentType<FieldSetListDatum> =
      useMemo(
        () =>
          React.forwardRef((rowProps, rowForwardedRef) => (
            <FieldSetListRow
              ref={rowForwardedRef}
              size={size}
              variant={variant}
              defaultValues={frozenDefaultValues}
              onValuesChange={handleValuesChange}
              {...rowProps}
            />
          )),
        [frozenDefaultValues, handleValuesChange, size, variant],
      )

    return (
      <WebUI.List
        ref={listRef}
        className={WebUI.cn('gap-10 overflow-hidden', className)}
        data={data}
        RowComponent={FieldSetListRowWithHandlers}
        {...restProps}
      />
    )
  },
)

// MARK: FieldSetListRow

interface FieldSetListRowProps
  extends WebUI.ListRowComponentProps<FieldSetListDatum> {
  size?: FieldSetListSize
  variant?: FieldSetListVariant
  defaultValues: Record<number, string>
  onValuesChange?: (fieldId: number, value: FieldValue) => void
}

const FieldSetListRow = React.forwardRef<HTMLDivElement, FieldSetListRowProps>(
  (
    {
      size = 'default',
      variant = 'payer',
      defaultValues,
      onValuesChange,
      data,
      index,
      ...restProps
    },
    forwardedRef,
  ) => {
    const onValuesChangeRef = useLiveRef(onValuesChange)

    const getFieldsByFieldIdentifiers = (
      fieldIdentifiers: Api.TabObjectFieldIdentifier[],
    ) =>
      fieldIdentifiers
        .map((fieldId) =>
          data.fields.find(
            (f) => f.metadata.fieldTypeMetadata?.fieldIdentifier === fieldId,
          ),
        )
        .filter((f) => f != null)

    const renderField = (field: FieldSetListField) => (
      <Field
        key={field.id}
        variant={variant}
        field={field}
        defaultValue={defaultValues[field.id]}
        onValueChange={onValuesChangeRef.current}
      />
    )

    const GroupComp = {
      default: VStackGap10,
      compact: VStackGap4,
    }[size]

    let fieldsElement: React.ReactNode | null = null

    switch (data.type) {
      case 'full_name':
        fieldsElement = (
          <GroupComp>
            {getFieldsByFieldIdentifiers(['first_name', 'last_name']).map(
              renderField,
            )}
          </GroupComp>
        )
        break
      case 'address':
        fieldsElement = (
          <WebUI.VStack className="gap-10">
            {getFieldsByFieldIdentifiers(['line1', 'line2']).map(renderField)}
            <GroupComp>
              {getFieldsByFieldIdentifiers(['city', 'state']).map(renderField)}
            </GroupComp>
            {getFieldsByFieldIdentifiers(['zip']).map(renderField)}
          </WebUI.VStack>
        )
        break
      default:
        fieldsElement = (
          <WebUI.VStack className="gap-10">
            {data.fields.map(renderField)}
          </WebUI.VStack>
        )
    }

    return (
      <div ref={forwardedRef} {...restProps}>
        {fieldsElement}
      </div>
    )
  },
)

// MARK: – Field

export interface FieldProps {
  variant?: FieldSetListVariant
  field: FieldSetListField
  defaultValue?: string
  onValueChange?: (fieldId: number, value: FieldValue) => void
}

export const Field = React.memo(
  ({variant = 'payer', field, defaultValue, onValueChange}: FieldProps) => {
    let fieldElement: React.ReactElement | null = null

    switch (field.field_type) {
      case 'text':
        fieldElement = (
          <WebUI.Input
            autoComplete={
              {
                first_name: 'given-name',
                last_name: 'family-name',
                line1: 'address-line1',
                line2: 'address-line2',
                city: 'address-level2',
                state: 'address-level1',
                zip: 'postal-code',
                none: undefined,
              }[field.metadata.fieldTypeMetadata?.fieldIdentifier ?? 'none']
            }
            readOnly={!onValueChange}
            maxLength={260}
            defaultValue={defaultValue}
            onChange={(event) => onValueChange?.(field.id, event.target.value)}
          />
        )
        break
      case 'email':
        fieldElement = (
          <WebUI.Input
            type="email"
            autoComplete="email"
            readOnly={!onValueChange}
            defaultValue={defaultValue}
            onChange={(event) => onValueChange?.(field.id, event.target.value)}
          />
        )
        break
      case 'phone':
        fieldElement = (
          <WebUI.PhoneInput
            defaultValue={defaultValue}
            readOnly={!onValueChange}
            onChange={(value) => onValueChange?.(field.id, value ?? '')}
            countries={[...WebUI.availableCountries, 'PA', 'PE']}
          />
        )
        break
      case 'text_multiline':
        fieldElement = (
          <WebUI.Textarea
            defaultValue={defaultValue}
            maxLength={3000}
            readOnly={!onValueChange}
            onChange={(event) => onValueChange?.(field.id, event.target.value)}
          />
        )
        break
      case 'date':
        fieldElement = (
          <WebUI.DatePicker
            isDisabled={field.metadata.isWaiverField ?? false}
            readOnly={!onValueChange}
            defaultValue={
              defaultValue
                ? Util.parseCalendarDate(
                    defaultValue,
                    field.metadata.fieldTypeMetadata?.timeZone,
                  )
                : undefined
            }
            onValueChange={(value) =>
              onValueChange?.(
                field.id,
                value
                  .toDate(
                    field.metadata.fieldTypeMetadata?.timeZone ??
                      getLocalTimeZone(),
                  )
                  .toISOString(),
              )
            }
          />
        )
        break
      case 'time':
        fieldElement = (
          <WebUI.TimeInput
            defaultValue={
              defaultValue ? Util.parseTime(defaultValue) : undefined
            }
            readOnly={!onValueChange}
            onValueChange={(value) =>
              onValueChange?.(
                field.id,
                toCalendarDateTime(today(getLocalTimeZone()), value)
                  .toDate(getLocalTimeZone())
                  .toISOString(),
              )
            }
          />
        )
        break
      case 'multiple_choice':
        fieldElement = (
          <WebUI.DropdownSelect<string>
            defaultValue={defaultValue}
            disabled={!onValueChange}
            onValueChange={(value) => onValueChange?.(field.id, value ?? '')}
          >
            <WebUI.DropdownSelectOption value="">
              Select One
            </WebUI.DropdownSelectOption>
            {field.values?.split('|||').map((v) => (
              <WebUI.DropdownSelectOption
                key={v}
                className="max-w-[26.5rem]"
                value={v}
              >
                {v}
              </WebUI.DropdownSelectOption>
            ))}
          </WebUI.DropdownSelect>
        )
        break
      case 'checkbox':
        fieldElement = (
          <WebUI.CheckboxGroup
            name={field.name}
            defaultState={defaultValue ? defaultValue.split('|||') : []}
            onStateChange={(state) =>
              onValueChange?.(
                field.id,
                Array.isArray(state) ? state.join('|||') : '',
              )
            }
          >
            {field.values?.split('|||').map((v) => (
              <WebUI.Checkbox key={v} readOnly={!onValueChange} value={v}>
                {v}
              </WebUI.Checkbox>
            ))}
          </WebUI.CheckboxGroup>
        )
        break
      case 'initials':
        fieldElement =
          defaultValue || variant === 'payer' ? (
            <WebUI.InitialsInput
              defaultValue={defaultValue}
              disabled={!onValueChange || variant === 'organizer'}
              onValueChange={(value) => onValueChange?.(field.id, value)}
            />
          ) : (
            <NoResponseText />
          )
        break
      case 'image':
      case 'signature':
        fieldElement =
          defaultValue || variant === 'payer' ? (
            <WebUI.SignatureCanvas
              disabled={!onValueChange || variant === 'organizer'}
              defaultDataURL={defaultValue}
              onDataURLChange={(value) =>
                onValueChange?.(field.id, value ?? '')
              }
            />
          ) : (
            <NoResponseText />
          )
        break
      case 'file': {
        if (variant === 'payer' || !defaultValue) {
          fieldElement = (
            <WebUI.FilesManager
              className="[&_>_.FilesManager-dropzone_>_.FilesManager-uploadButton]:min-h-[120px]"
              defaultFiles={defaultValue ? [defaultValue] : undefined}
              onFilesChange={([file]) =>
                onValueChange?.(field.id, file ?? null)
              }
            />
          )
        } else if (variant === 'organizer') {
          fieldElement = (
            <WebUI.RemoteFileOverview
              deleteable
              url={defaultValue}
              onDelete={() => onValueChange?.(field.id, null)}
            />
          )
        }

        break
      }
      case 'layout_description':
        return <WebUI.MarkdownParagraph markdown={field.values ?? ''} />
      case 'layout_divider':
        return <WebUI.Separator variant="primary" />
    }

    return (
      <WebUI.FormField
        className={
          'sm:[&_.DatePicker-content]:w-[260px] sm:[&_.TimeInput]:w-[260px] [&_>_.FormField-caption]:inline [&_>_div]:w-auto [&_>_div]:grow-0 [&_>_div_>_.InitialsInput]:w-[calc(4em+theme(spacing.2))] sm:[&_>_div_>_.PhoneInput]:w-[260px]'
        }
        required={field.required}
        label={field.name}
        caption={
          field.field_type === 'file'
            ? 'File size must be under 10mb.'
            : undefined
        }
        error={field.error}
      >
        {fieldElement}
      </WebUI.FormField>
    )
  },
)

// MARK: – NoResponseText

const NoResponseText = ({
  className,
  children = 'No Response',
  ...restProps
}: React.ComponentPropsWithoutRef<'span'>) => (
  <WebUI.Text className={WebUI.cn('text-gray400', className)} {...restProps}>
    {children}
  </WebUI.Text>
)

// MARK: – Helpers

export const makeFieldInitialValues = (
  fields: Api.PublicTabObjectField[] | Api.TabObjectField[],
  fieldViews?: Api.CheddarUpCartObjectTabFormFieldView[],
): Record<number, string | null> =>
  Util.mapToObj(fields, (field) => {
    let v: string | null = ''

    const fieldView = fieldViews?.find((fv) => fv.item_field_id === field.id)
    if (fieldView) {
      v = fieldView.value
    }
    if (field.metadata.isWaiverField && field.field_type === 'date') {
      v = new Date().toISOString()
    }
    if (field.field_type === 'signature') {
      v = null
    }

    return [field.id, v]
  })

export const normalizeFieldSets = <
  T extends Api.TabObjectField | Api.PublicTabObjectField,
>(input: {
  fields: T[]
  fieldSets: Api.FieldSet[]
}) => {
  const fieldSetsWithFields = Util.sort(input.fieldSets)
    .asc((fs) => fs.position ?? Number.MAX_SAFE_INTEGER)
    .map((fs) => ({
      ...fs,
      id: fs.uuid,
      fields: sortFieldsByFieldIdentifiers(
        input.fields.filter((f) => f.metadata.fieldSetId === fs.uuid),
      ),
    }))
  const fieldsWithoutFieldSet = input.fields
    .filter(
      (f) =>
        !f.metadata.fieldSetId ||
        (f.metadata.fieldSetId && input.fieldSets.length === 0) ||
        (f.metadata.description?.enabled && f.metadata.description.value),
    )
    .flatMap((f) => {
      if (f.metadata.description?.enabled && f.metadata.description.value) {
        return [
          f,
          {
            id: -f.id,
            name: 'layout_description',
            required: false,
            position: f.position,
            field_type: 'layout_description' as const,
            values: f.metadata.description.value,
            metadata: f.metadata,
          } as T,
        ]
      }
      return [f]
    })

  return fieldsWithoutFieldSet.length === 0
    ? fieldSetsWithFields
    : [
        ...fieldSetsWithFields,
        {
          id: fakeFieldSetId,
          label: '' as const,
          type: 'general' as const,
          fields: fieldsWithoutFieldSet,
          uuid: fakeFieldSetId,
        },
      ]
}

export const sortFieldsByFieldIdentifiers = <
  T extends Api.TabObjectField | Api.PublicTabObjectField,
>(
  fields: T[],
) =>
  Util.sort(fields).asc((f) =>
    [
      'first_name',
      'last_name',
      'line1',
      'line2',
      'city',
      'state',
      'zip',
    ].indexOf(f.metadata.fieldTypeMetadata?.fieldIdentifier ?? ''),
  )

export const makeFieldsYupSchema = (
  fields: Api.PublicTabObjectField[] | Api.TabObjectField[],
) =>
  Util.mapToObj(fields, (field) => {
    if (field.field_type === 'file') {
      const fileSchema = field.required
        ? Yup.mixed().required('Required')
        : Yup.mixed().nullable()

      return [
        field.id,
        fileSchema.test(
          'file-size',
          'The file size is too big',
          (value: unknown) => {
            if (value instanceof File) {
              const sizeInMb = value.size / 1024 / 1024
              return sizeInMb <= 10
            }

            return true
          },
        ),
      ]
    }

    let schema: Yup.StringSchema<string | null | undefined> = Yup.string()

    if (field.required) {
      schema = schema.required('Required')
    } else {
      schema = schema.nullable()
    }

    switch (field.field_type) {
      case 'email':
        schema = schema.email('Invalid format')
        break
      case 'initials':
        schema = schema.min(2, 'Initials require 2 letters')
        break
    }

    return [field.id, schema as Yup.MixedSchema]
  })

export const resolveCartFieldValues = async ({
  fields,
  values,
  collectionSlug,
  cartUuid,
}: {
  fields: Api.PublicTabObjectField[] | Api.TabObjectField[]
  values: Record<number, FieldValue>
  collectionSlug: string
  cartUuid: string
}) => {
  const fileFields = fields.filter(
    (f) => f.field_type === 'file' && values[f.id] instanceof File,
  )
  const base64Fields = fields.filter((f) => {
    const value = values[f.id]
    return (
      (f.field_type === 'image' || f.field_type === 'signature') &&
      typeof value === 'string' &&
      isBase64(value, {mime: 'allowed'})
    )
  })

  const fileFieldsUploadPromises = Util.mapToObj(fileFields, (f) => [
    f.id,
    // biome-ignore lint/style/noNonNullAssertion:
    uploadCartFile(values[f.id]! as File, {
      collectionSlug,
      cartUuid,
    }),
  ])

  const base64FieldsUploadPromises = Util.mapToObj(base64Fields, (f) => [
    f.id,
    uploadSignatureBase64(
      {
        // biome-ignore lint/style/noNonNullAssertion:
        dataUri: values[f.id]!,
        contentType: 'image/png',
        objectName: `${Util.makeShortId()}-signature-${cartUuid}-${f.id}.png`,
      },
      {collectionSlug, cartUuid},
    ),
  ])

  const resolvedFieldsValues = await Util.combinePromises({
    ...fileFieldsUploadPromises,
    ...base64FieldsUploadPromises,
  })

  const cartFieldValues = Util.arrayFromObject(
    {
      ...values,
      ...resolvedFieldsValues,
    },
    (fieldId, fieldValue) =>
      typeof fieldValue === 'string' && fieldValue
        ? {
            item_field_id: Number(fieldId),
            value: fieldValue,
          }
        : undefined,
  ).filter((fv) => fv != null)

  return cartFieldValues
}

const VStackGap4 = withProps(WebUI.VStack, ({className, ...restProps}) => ({
  className: WebUI.cn('gap-4', className),
  ...restProps,
}))

const VStackGap10 = withProps(WebUI.VStack, ({className, ...restProps}) => ({
  className: WebUI.cn('gap-10', className),
  ...restProps,
}))

function isBase64(
  candidate: string,
  options?: {mime?: 'required' | 'allowed' | 'none'},
) {
  let regex =
    '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+/]{3}=)?'
  const mimeRegex = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)'

  if (options?.mime === 'required') {
    regex = mimeRegex + regex
  } else if (options?.mime === 'allowed') {
    regex = `${mimeRegex}?${regex}`
  }

  return new RegExp(`^${regex}$`, 'gi').test(candidate)
}
