import React, {useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'
import {api} from '@cheddarup/api-client'

import {ItemSettingDisclosureSwitch} from '../../components/ItemSettingsDisclosureSwitch'
import type {DonationItemFormFormik} from './DonationItemForm'
import {ItemSettingsUpgradePlanButton} from '../../components/ItemSettingsUpgradePlanButton'
import {ItemFormAdvancedSettingsSwitch} from '../../components/ItemFormAdvancedSettings'

export interface DonationItemFormSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: DonationItemFormFormik
  tabId: number
  item?: Api.TabItem
}

const DonationItemFormSettings: React.FC<DonationItemFormSettingsProps> = ({
  formik,
  tabId,
  item,
  className,
  ...restProps
}) => {
  const [enforcedPremiumMeta, setEnforcedPremiumMeta] = useState<
    Util.PartialDeep<Api.TabPremiumMeta>
  >({})
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      tabId,
    },
  })
  const isBasic = !collection?.is_pro
  const collectionPublished = collection && collection.status !== 'draft'

  const fundraisingGoalLabel = 'Set a fundraising goal for this item'
  const fundraisingGoalDescription =
    'Motivate donors to give with a real-time progress bar toward your fundraising goal.'
  return (
    <PremiumFeaturesSideSheet
      enforcedPremiumMeta={enforcedPremiumMeta}
      tabId={tabId}
    >
      <WebUI.VStack
        className={WebUI.cn('[&_>_.Separator]:-mx-2 gap-6', className)}
        {...restProps}
      >
        <WebUI.VStack className="gap-2">
          <ItemFormAdvancedSettingsSwitch
            name="available_quantity_enabled"
            title="Set available quantity"
            description="“Sold out” will show when all available items have been purchased."
            checked={formik.values.available_quantity_enabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.values.available_quantity_enabled && (
            <WebUI.FormField
              className="mx-3"
              label="Total Available"
              required
              error={formik.errors.available_quantity}
            >
              <WebUI.Input
                className="max-w-32"
                name="available_quantity"
                placeholder="Qty."
                type="number"
                value={formik.values.available_quantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          )}
        </WebUI.VStack>
        <WebUI.Separator />

        {formik.values.available_quantity_enabled && (
          <>
            <ItemFormAdvancedSettingsSwitch
              name="options.makeAvailableQuantityPublic"
              title="Make available quantity public"
              description="Payers will see how many items are left to purchase."
              checked={formik.values.options.makeAvailableQuantityPublic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <WebUI.Separator />
          </>
        )}
        <ItemFormAdvancedSettingsSwitch
          name="options.makeTotalCollectedPublic"
          title="Make total collected public"
          description="Display the total collected for this donation."
          checked={formik.values.options.makeTotalCollectedPublic}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <WebUI.Separator />
        <WebUI.Disclosure
          className="gap-3"
          visible={formik.values.options.fundraisingGoal.enabled}
          onVisibleChange={(newPaymentGoalEnabled) => {
            formik.setFieldValue(
              'options.fundraisingGoal.enabled',
              newPaymentGoalEnabled,
            )
            setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
              ...prevEnforcedPremiumMeta,
              pro: {
                ...prevEnforcedPremiumMeta?.pro,
                fundraisingGoal: newPaymentGoalEnabled,
              },
            }))
          }}
        >
          {isBasic && collectionPublished ? (
            <ItemSettingsUpgradePlanButton
              description={fundraisingGoalDescription}
              plan="pro"
            >
              {fundraisingGoalLabel}
            </ItemSettingsUpgradePlanButton>
          ) : (
            <ItemSettingDisclosureSwitch
              featureKey={isBasic ? 'fundraisingGoal' : undefined}
              label={fundraisingGoalLabel}
              description={fundraisingGoalDescription}
            />
          )}

          <WebUI.DisclosureContent className="text-ds-sm">
            <WebUI.VStack className="gap-2">
              <WebUI.FormField
                label="Goal Amount:"
                required
                error={formik.errors.options?.fundraisingGoal?.value}
              >
                <WebUI.AmountInput
                  className="max-w-[256px]"
                  name="options.fundraisingGoal.value"
                  placeholder="$0"
                  value={formik.values.options.fundraisingGoal.value}
                  onValueChange={(newValue) =>
                    formik.setFieldValue(
                      'options.fundraisingGoal.value',
                      newValue,
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
      </WebUI.VStack>
    </PremiumFeaturesSideSheet>
  )
}

export default DonationItemFormSettings
