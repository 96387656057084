import * as Yup from 'yup'
import React, {useState} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {BooleanParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {Banner} from 'src/components/Banner'

import usePublicCollection from '../hooks/usePublicCollection'
import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import {useActiveCheckoutStep} from '../CheckoutPage/CheckoutPageFlowContext'
import {CheckoutSteppedPanel} from './CheckoutSteppedPanel'

export interface RecordPaymentMemberFormPanelProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<MemberEmailFormProps, 'onDidSubmit'> {}

export const RecordPaymentMemberFormPanel = ({
  onDidSubmit,
  className,
  ...restProps
}: RecordPaymentMemberFormPanelProps) => {
  const isActive = useActiveCheckoutStep() === 'auth'
  const {cart} = useCart()
  return (
    <div className={WebUI.cn('flex flex-col gap-3', className)} {...restProps}>
      <CheckoutSteppedPanel
        step="auth"
        expanded
        heading={cart && cart.itemCount > 0 ? 'Checkout' : 'Submit'}
      >
        <RecordPaymentBanner />
      </CheckoutSteppedPanel>
      {isActive && (
        <CheckoutSteppedPanel step="auth">
          <MemberEmailForm onDidSubmit={onDidSubmit} />
        </CheckoutSteppedPanel>
      )}
    </div>
  )
}

// MARK: – RecordPaymentBanner

const RecordPaymentBanner = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const {cart} = useCart()
  return cart?.member?.name ? (
    <Banner className={WebUI.cn('text-left', className)} {...restProps}>
      You are recording an order on behalf of{' '}
      <span className="font-semibold">{cart.member.name}</span>.
    </Banner>
  ) : null
}

// MARK: – MemberEmailForm

interface MemberEmailFormValues {
  email: string
}

interface MemberEmailFormProps extends React.ComponentPropsWithoutRef<'div'> {
  onDidSubmit?: () => void
}

const MemberEmailForm: React.FC<MemberEmailFormProps> = ({
  onDidSubmit,
  className,
  ...restProps
}) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [, updateCartAsync] = useEnhancedUpdateCartMutation()
  const growlActions = WebUI.useGrowlActions()
  const ownEmailsQuery = api.auth.session.useQuery(undefined, {
    select: (session) => [
      session.user.email,
      ...session.manager_roles.map((mr) => mr.email),
    ],
  })
  const otherMembersQuery = api.tabMembers.list.useQuery(
    {
      pathParams: {
        tabId: publicCollection.id,
      },
    },
    {
      select: (members) =>
        members.filter(
          (member) => !ownEmailsQuery.data?.includes(member.email),
        ),
    },
  )
  const [newTabMember, setNewTabMember] = useState<Api.TabMember | null>(null)

  const processCartUpdate = async (values: MemberEmailFormValues) => {
    try {
      await updateCartAsync({
        body: {
          name: cart?.member?.name ?? '',
          email: values.email,
        },
      })

      onDidSubmit?.()
    } catch (err: any) {
      growlActions.show('error', {
        title: 'Error!',
        body:
          (Object.values(err.response?.data?.errors ?? {})?.[0] as
            | string
            | undefined) || 'Something went wrong!',
      })
    }
  }

  const formik = useFormik<MemberEmailFormValues>({
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid format')
        .notOneOf(
          ownEmailsQuery.data ?? [],
          'Email can’t match the email on account',
        ),
    }),
    initialValues: {
      email: cart?.member?.email ?? '',
    },
    onSubmit: async (values) => {
      const existingTabMember = otherMembersQuery.data?.find(
        (member) => member.email === values.email,
      )
      if (existingTabMember) {
        setNewTabMember(existingTabMember)
      } else if (values.email) {
        await processCartUpdate({email: values.email})
      } else {
        onDidSubmit?.()
      }
    },
  })

  return (
    <div className={WebUI.cn('flex flex-col gap-4', className)} {...restProps}>
      <WebUI.Text className="text-ds-lg">
        Would you like to send a receipt?
      </WebUI.Text>

      <form
        className="flex flex-col gap-4"
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        <WebUI.FormField label="Email" error={formik.errors.email}>
          <WebUI.Input
            name="email"
            type="email"
            autoComplete={addPayment ? 'off' : 'email'}
            placeholder="Email (Optional)"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        <WebUI.Button
          className="self-end"
          type="submit"
          size="large"
          roundness="capsule"
          loading={formik.isSubmitting}
        >
          Continue
        </WebUI.Button>
      </form>
      <TabMemberConfirmationAlert
        visible={newTabMember != null}
        tabMember={newTabMember}
        onConfirm={processCartUpdate}
        onDidHide={() => setNewTabMember(null)}
      />
    </div>
  )
}

// MARK: – TabMemberConfirmationAlert

interface TabMemberConfirmationAlertProps extends WebUI.AlertProps {
  tabMember: Api.TabMember | null
  onConfirm: (payload: any) => void
}

const TabMemberConfirmationAlert = React.forwardRef<
  WebUI.DialogInstance,
  TabMemberConfirmationAlertProps
>(({tabMember, onConfirm, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Update tab member confirmation"
    {...restProps}
  >
    {(dialog) => (
      <>
        <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <WebUI.VStack className="gap-4">
              <span>
                This email address has been used on a previous order. Using this
                email again will change the name on this order to:{' '}
                <strong>{tabMember?.name}</strong>.
              </span>
              <span>
                If this order is for a different user, use a different email or
                leave blank.
              </span>
            </WebUI.VStack>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                execute={async () => {
                  if (!tabMember) {
                    return
                  }
                  await onConfirm({
                    name: tabMember.name ?? '',
                    email: tabMember.email,
                  })
                  dialog.hide()
                }}
              >
                Save and Change Name
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton />
            </>
          }
        />
      </>
    )}
  </WebUI.Alert>
))
