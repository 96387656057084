import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {isAccountDetailsFieldsFilled} from '@cheddarup/core'

import {SideNavNavLinkButton} from '../SideNavNavLinkButton'

interface MyAccountSidebarProps {
  initialPathString?: string
}

export const MyAccountSidebar = ({
  initialPathString = '',
}: MyAccountSidebarProps) => {
  const isAnyFieldNeededQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.stripe_data.fieldsNeeded.length > 0 &&
      isAccountDetailsFieldsFilled(session.user),
  })
  const isCanadaFieldNeededQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.user.currency === 'cad' && session.user.editable,
  })
  const isAlertsNotEmptyQuery = api.alerts.list.useQuery(undefined, {
    select: (alerts) => alerts.length > 0,
  })

  return (
    <>
      <SideNavNavLinkButton to={`${initialPathString}display-name`}>
        Profile
      </SideNavNavLinkButton>
      <SideNavNavLinkButton
        iconAfter={
          isAnyFieldNeededQuery.data && (
            <WebUI.PhosphorIcon
              className="text-3xl text-orange-50"
              icon="warning-circle-fill"
            />
          )
        }
        to={`${initialPathString}account-details`}
      >
        Account Details
      </SideNavNavLinkButton>
      {isCanadaFieldNeededQuery.data && (
        <SideNavNavLinkButton
          iconAfter={
            <WebUI.PhosphorIcon
              className="text-orange-50"
              width={22}
              icon="warning-circle-fill"
            />
          }
          to={`${initialPathString}canada-verification`}
        >
          Canada Verification
        </SideNavNavLinkButton>
      )}
      <SideNavNavLinkButton to={`${initialPathString}plan-billing`}>
        Plan and Billing
      </SideNavNavLinkButton>
      <SideNavNavLinkButton to={`${initialPathString}security`}>
        Security
      </SideNavNavLinkButton>
      <SideNavNavLinkButton to={`${initialPathString}withdrawal-settings`}>
        Withdrawal Settings
      </SideNavNavLinkButton>
      <SideNavNavLinkButton to={`${initialPathString}payment-methods`}>
        Payment Methods
      </SideNavNavLinkButton>
      <SideNavNavLinkButton to={`${initialPathString}notification-settings`}>
        Notification Settings
      </SideNavNavLinkButton>
      {isAlertsNotEmptyQuery.data && (
        <SideNavNavLinkButton
          iconAfter={
            <WebUI.PhosphorIcon
              className="text-3xl text-orange-50"
              icon="warning-circle-fill"
            />
          }
          to={`${initialPathString}alerts`}
        >
          Alerts
        </SideNavNavLinkButton>
      )}
      <SideNavNavLinkButton to={`${initialPathString}zapier-key`}>
        Integrations
      </SideNavNavLinkButton>
    </>
  )
}
