import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  paginationSchema,
  paymentItemSchema,
  signUpTypeSchema,
  tabMemberSchema,
  tabObjectFieldMetadataSchema,
  tabObjectFieldSchema,
  tabObjectFieldViewSchema,
  tabPaymentRefundDetailSchema,
  tabPaymentRefundSchema,
  tabPaymentSchema,
  timeSlotOptionsSchema,
  timeSlotSchema,
} from '../schemas'

const paymentsFilterTypeSchema = z.enum(['recurring', 'once'])
const paymentsFilterMethodSchema = z.enum(['echeck', 'cash', 'card'])
const paymentsFilterStatusSchema = z.enum([
  'available',
  'pending',
  'failed',
  'refunded',
  'disputed',
])

const paymentFiltersSchema = z
  .object({
    amount: z.object({min: z.number(), max: z.number()}).partial(),
    date: z.object({min: z.string(), max: z.string()}).partial(),
    method: paymentsFilterMethodSchema.array(),
    status: paymentsFilterStatusSchema.array(),
    payment_type: paymentsFilterTypeSchema.array(),
  })
  .partial()

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/payments',
    queryParamsSchema: z
      .object({
        perPage: z.number(),
        page: z.number(),
        direction: z.string(),
        sort: z.enum([
          'id',
          'note',
          'payment_method',
          'tab_members.name',
          'tab_members.last_name',
          'tab_members.email',
          'created_at',
          'status',
          'shipments.purchased',
          'total',
        ]),
        search: z.string(),
        shippable: z.boolean(),
        status: z.string(),
        code: z.string(),
        collectorEmail: z.string(),
        onlyTickets: z.boolean(),
        ever_disputed: z.boolean(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: tabPaymentSchema
        .pick({
          id: true,
          created_at: true,
          available_on: true,
          note: true,
          payment_method: true,
          status: true,
          tab_id: true,
          total: true,
          user_fee: true,
          uuid: true,
          total_discounts: true,
          subtotal: true,
          items_total: true,
          total_taxes: true,
          total_refund: true,
          shipping_info: true,
          taxes: true,
          can_refund: true,
          fully_refunded: true,
          refunds: true,
          metadata_exposed: true,
          shipping_charge: true,
          shipment: true,
          dispute: true,
        })
        .extend({
          tab_member: tabMemberSchema.pick({
            id: true,
            created_at: true,
            deleted_at: true,
            phone: true,
            name: true,
            first_visit: true,
            profile_pic: true,
          }),
          payment_items: paymentItemSchema
            .pick({
              id: true,
              amount: true,
              quantity: true,
              total: true,
              detail: true,
            })
            .extend({
              ticket_number: z.string().nullish(),
              tab_item: z
                .object({
                  name: z.string(),
                  amount: z.number(),
                  waitlist_count: z.number(),
                })
                .optional(),
              item_field_views: tabObjectFieldViewSchema
                .pick({
                  id: true,
                  field_type: true,
                  metadata: true,
                  value: true,
                  name: true,
                })
                .array(),
            })
            .array(),
        })
        .array(),
      pagination: paginationSchema,
      search: z.string().nullable(),
      sort: z.string(),
    }),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId',
    responseSchema: tabPaymentSchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId',
    method: 'PATCH',
    bodySchema: tabPaymentSchema
      .extend({
        name: z.string(),
        email: z.string().nullable(),
      })
      .partial(),
    responseSchema: tabPaymentSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId',
    method: 'DELETE',
    bodySchema: tabPaymentSchema.partial(),
    responseSchema: tabPaymentSchema,
  }),
  variantStats: makeEndpoint({
    path: 'users/tabs/:tabId/objects/:tabObjectId/variants_stats',
    queryParamsSchema: z
      .object({
        include_refunds: z.boolean(),
      })
      .partial()
      .optional(),
    responseSchema: z
      .object({
        uuid: z.string(),
        optionValues: z.record(z.string()),
        payments: z
          .object({
            id: z.number(),
            created_at: z.string(),
            tab_member: z.object({
              id: z.number(),
              name: z.string(),
            }),
            payment_items: z
              .object({
                id: z.number(),
                amount_after_refund: z.number().optional(),
              })
              .array(),
          })
          .array(),
      })
      .array(),
  }),
  responseList: makeEndpoint({
    path: 'users/tabs/:tabId/objects/:tabObjectId/fields/responses',
    responseSchema: tabObjectFieldSchema
      .pick({
        id: true,
        name: true,
        field_type: true,
        values: true,
        metadata: true,
      })
      .extend({
        payments: z
          .object({
            id: z.number(),
            created_at: z.string(),
            total: z.number(),
            tab_member: z.object({
              id: z.number(),
              name: z.string(),
            }),
            payment_items: z
              .object({
                id: z.number(),
                total: z.number(),
                amount_after_refund: z.number(),
                item_field_views: z
                  .object({
                    id: z.number(),
                    item_field_id: z.number(),
                    value: z.string(),
                  })
                  .array(),
              })
              .array(),
          })
          .array(),
      })
      .array(),
  }),
  formsAndSignups: makeEndpoint({
    path: 'users/tabs/:tabId/objects/forms_and_signups',
    queryParamsSchema: z
      .object({
        type: z.enum(['TabForm', 'Signup']),
      })
      .partial()
      .optional(),
    responseSchema: z
      .object({
        id: z.number(),
        tab_id: z.number(),
        name: z.string(),
        item_field_views: tabObjectFieldViewSchema.array(),
        options: z.object({
          isWaiver: z.boolean(),
          signupType: signUpTypeSchema.nullable(),
        }),
        item_field_views_count: z.number(),
        respondents: z.number(),
      })
      .array(),
  }),
  signupPayments: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/payments',
    responseSchema: z
      .object({
        id: z.number(),
        created_at: z.string(),
        payment_items: z
          .object({
            id: z.number(),
            quantity: z.number(),
            item_field_views: z
              .object({
                id: z.number(),
                value: z.string(),
                metadata: tabObjectFieldMetadataSchema.pick({
                  isTimeSlotField: true,
                  timeSlotFieldType: true,
                }),
              })
              .array(),
            time_slot: timeSlotSchema
              .pick({options: true, available_quantity: true})
              .extend({
                options: timeSlotOptionsSchema,
                spot: z.object({
                  name: z.string(),
                  available_quantity: z.number(),
                  signup: z.object({
                    id: z.number(),
                    name: z.string(),
                  }),
                }),
              })
              .optional(),
          })
          .array(),
        tab_member: z.object({
          id: z.number(),
          name: z.string(),
          email: z.string(),
        }),
      })
      .array(),
  }),
  //   response: Api.SignUpPaymentResponse[]
  resendReceipt: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/resend_receipt',
    method: 'POST',
    bodySchema: z.object({email: z.string()}),
    responseSchema: z.any(),
  }),
  createRefund: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/refunds',
    method: 'POST',
    bodySchema: tabPaymentRefundDetailSchema.partial(),
    responseSchema: tabPaymentRefundSchema,
  }),
  resendEmails: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/refunds/:refundId/resend_emails',
    method: 'POST',
    bodySchema: tabPaymentRefundDetailSchema.partial(),
    responseSchema: tabPaymentRefundSchema,
  }),
  search: makeEndpoint({
    isMutation: false,
    path: 'users/tabs/:tabId/payments/search',
    method: 'POST',
    bodySchema: z
      .object({
        page: z.number(),
        perPage: z.number(),
        sort: z.string(),
        direction: z.string(),
        search: z.string(),
        filters: paymentFiltersSchema,
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: tabPaymentSchema.array(),
      pagination: paginationSchema,
      search: z.string(),
      sort: z.string(),
    }),
  }),
  exportData: makeEndpoint({
    path: 'users/tabs/:tabId/payments/export_data',
    responseSchema: tabPaymentSchema.array(),
  }),
}
