import {useFormik, useUpdateEffect} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useState} from 'react'
import * as Util from '@cheddarup/util'
import {
  useCreateFieldValueMutation,
  useUpdateFieldValueMutation,
} from '@cheddarup/api-client'

import {FieldSetList} from '../../components/FieldSetList'
import {uploadFieldViewFile} from 'src/helpers/file-uploads'

export interface PaymentObjectFieldsEditProps
  extends React.ComponentPropsWithoutRef<'form'> {
  collectionId: number
  paymentId: number
  paymentObject: Api.PaymentItem
}

export const PaymentObjectFieldsEdit = ({
  collectionId,
  paymentId,
  paymentObject,
  ...restProps
}: PaymentObjectFieldsEditProps) => {
  const createFieldValueMutation = useCreateFieldValueMutation()
  const updateFieldValueMutation = useUpdateFieldValueMutation()
  const [fieldSetListKey, setFieldSetListKey] = useState(Util.makeShortId())

  const tabObject =
    paymentObject.tab_item ?? paymentObject.tab_form ?? paymentObject.time_slot
  const fields =
    paymentObject.tab_item?.fields ??
    paymentObject.tab_form?.fields ??
    paymentObject.time_slot?.spot.signup.fields ??
    []

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    setFieldSetListKey(Util.makeShortId())
  }, [paymentObject])

  const formik = useFormik<Record<number, string | File | null>>({
    enableReinitialize: true,
    initialValues: Util.mapToObj(paymentObject.item_field_views, (ifv) => [
      ifv.item_field_id,
      ifv.value,
    ]),
    onSubmit: async (values) => {
      const fileFields = fields.filter((f) => f.field_type === 'file')

      const fileFieldsUploadPromises = Util.mapToObj(fileFields, (f) => {
        const val = values[f.id]

        return val instanceof Blob
          ? [f.id, uploadFieldViewFile(val, {tabId: collectionId, paymentId})]
          : [f.id, Promise.resolve(undefined)]
      })

      const resolvedFileFieldsValues = await Util.combinePromises(
        fileFieldsUploadPromises,
      )

      const normalizedValues: Record<string, string | null | undefined> = {
        ...values,
        ...resolvedFileFieldsValues,
      }

      const createAndUpdatePromises = Util.arrayFromObject(
        normalizedValues,
        async (fieldIdAsStr, value) => {
          const fieldId = Number(fieldIdAsStr)
          const correspondingFieldView = paymentObject.item_field_views.find(
            (ifv) => ifv.item_field_id === fieldId,
          )
          const field = fields.find((f) => f.id === fieldId)
          const fieldType =
            correspondingFieldView?.field_type ?? field?.field_type

          if (
            value === undefined ||
            (!value && fieldType !== 'file') ||
            fieldType === 'signature' ||
            fieldType === 'image'
          ) {
            return undefined
          }

          if (
            correspondingFieldView &&
            value === correspondingFieldView.value
          ) {
            return undefined
          }

          if (correspondingFieldView) {
            return updateFieldValueMutation.mutateAsync({
              pathParams: {
                tabId: collectionId,
                paymentId,
                fieldValueId: correspondingFieldView.id,
              },
              body: {
                item_field_value: {
                  value,
                },
              },
            })
          }

          return createFieldValueMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              paymentId,
            },
            body: {
              value,
              payment_id: paymentId,
              payment_item_id: paymentObject.id,
              // biome-ignore lint/style/noNonNullAssertion:
              tab_object_id: (paymentObject.tab_item?.id ??
                paymentObject.tab_form?.id ??
                paymentObject.time_slot?.id)!,
              item_field_id: fieldId,
            },
          })
        },
      ).filter((p) => p != null)

      await Promise.all(createAndUpdatePromises)
    },
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      {!!tabObject && (
        <FieldSetList
          key={fieldSetListKey}
          size="compact"
          variant="organizer"
          EmptyStateViewComponent={React.Fragment}
          defaultValues={formik.values as any}
          errors={formik.errors as Record<number, string>}
          fieldSets={
            'fieldSets' in tabObject.options
              ? tabObject.options.fieldSets ?? []
              : []
          }
          fields={fields}
          onValuesChange={(fieldId, value) => {
            formik.setFieldValue(String(fieldId), value)

            const field = fields.find((f) => f.id === fieldId)
            if (
              field?.field_type === 'file' &&
              formik.values[fieldId] !== value
            ) {
              setFieldSetListKey(Util.makeShortId())
            }
          }}
        />
      )}
      <WebUI.Disclosure visible={formik.dirty}>
        <WebUI.DisclosureContent>
          <WebUI.HStack className="justify-end gap-3">
            <WebUI.Button
              className="text-ds-sm"
              type="reset"
              variant="text"
              disabled={formik.isSubmitting}
              onClick={() => setFieldSetListKey(Util.makeShortId())}
            >
              Cancel
            </WebUI.Button>
            <WebUI.Button type="submit" loading={formik.isSubmitting}>
              Save
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </form>
  )
}
