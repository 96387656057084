import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

interface TotalCollectedProps extends React.ComponentPropsWithoutRef<'div'> {
  totalCollected: Api.GroupPageSection
}

const TotalCollected = ({
  totalCollected,
  className,
  style,
  ...restProps
}: TotalCollectedProps) => (
  <WebUI.VStack
    className={WebUI.cn('px-2 py-18 xl:px-0', className)}
    style={{
      color: Util.getReadableColor(
        totalCollected.background_color,
        undefined,
        true,
      ),
      backgroundColor: totalCollected.background_color,
      ...style,
    }}
    {...restProps}
  >
    <WebUI.VStack className="mx-auto w-full max-w-[1100px] gap-5 px-6">
      <WebUI.Heading className="font-accentAlt text-h-6 sm:text-h-4">
        {totalCollected.headline}
      </WebUI.Heading>
      <WebUI.Text className="font-light text-ds-md">
        {totalCollected.description}
      </WebUI.Text>
      <div className="flex flex-col gap-2">
        <WebUI.Heading className="font-body font-extrabold text-h-4 sm:text-h2">
          {totalCollected.total_collected}
        </WebUI.Heading>
        {totalCollected.details.timePeriod && (
          <WebUI.Text className="text-ds-lg">
            {timePeriodToLabelMap[totalCollected.details.timePeriod]}
          </WebUI.Text>
        )}
      </div>
    </WebUI.VStack>
  </WebUI.VStack>
)

// MARK: - Helpers

export const timePeriodToLabelMap = {
  all_time: 'All-Time',
  last_year: 'Year-to-Date',
  last_month: 'Month-to-Date',
  last_week: 'Week-to-Date',
}

export default TotalCollected
