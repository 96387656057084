import {useLocation, useNavigate} from 'react-router-dom'
import CartHelpers from 'src/helpers/CartHelpers'
import * as WebUI from '@cheddarup/web-ui'

import {isFormsBeforeItems, isFormsEmpty} from './public-collection-utils'
import usePublicCollection from '../hooks/usePublicCollection'
import useCart from '../hooks/useCart'
import {usePayerUIState} from '../PayerUIStateProvider'
import {
  hasMissingRequiredFormFields,
  hasMissingRequiredItems,
  hasMissingRequiredSignups,
} from '@cheddarup/core'

export type PayerPathSegment = 'items' | 'forms' | 'checkout'

export function getPayerSegments({
  publicCollection,
}: {
  publicCollection: Api.PublicTab
}): PayerPathSegment[] {
  const hasItems = publicCollection.items.length > 0
  const hasForms = !isFormsEmpty(publicCollection)

  if (!hasForms) {
    return ['items', 'checkout']
  }
  if (hasForms && !hasItems) {
    return ['forms', 'checkout']
  }

  return isFormsBeforeItems(publicCollection)
    ? ['forms', 'items', 'checkout']
    : ['items', 'forms', 'checkout']
}

// TODO: Refactor this, has duplicate logic in the `usePayerNavigateToSegment`
export function isPayerSegmentDisabled({
  segment,
  publicCollection,
  cart,
}: {
  segment: PayerPathSegment
  publicCollection: Api.PublicTab
  cart?: Api.Cart
}) {
  const segments = getPayerSegments({publicCollection})

  const requiredFormFieldsMissing = hasMissingRequiredFormFields({
    publicTab: publicCollection,
    cart,
  })
  const requiredSignupsMissing = hasMissingRequiredSignups({
    publicTab: publicCollection,
    cart,
  })

  const requiredFieldsMissing =
    requiredFormFieldsMissing || requiredSignupsMissing

  const isPaymentRequiredFulfilled = isPaymentRequirementFulfilled({
    cart,
    publicCollection,
  })
  const missingRequiredItems = hasMissingRequiredItems({
    publicTab: publicCollection,
    cart,
  })

  if (segment === 'checkout') {
    const isCartEmpty =
      !cart ||
      (cart.items.length === 0 &&
        cart.forms.length === 0 &&
        cart.time_slots.length === 0)

    return (
      isCartEmpty ||
      !isPaymentRequiredFulfilled ||
      requiredFieldsMissing ||
      missingRequiredItems
    )
  }

  if (segments[0] === 'items' && segment === 'forms') {
    return !isPaymentRequiredFulfilled || missingRequiredItems
  }
  if (segments[0] === 'forms' && segment === 'items') {
    return requiredFieldsMissing
  }

  return false
}

export function useCurrentPayerSegment(): PayerPathSegment | null {
  const pathname = useLocation().pathname

  const segments = pathname.split('/')
  const cSegmentIdx = segments.indexOf('c')
  const payerSegment = segments[cSegmentIdx + 2]

  if (
    payerSegment != null &&
    ['items', 'forms', 'checkout'].includes(payerSegment)
  ) {
    return payerSegment as PayerPathSegment
  }

  return null
}

export function usePayerSegmentSelector<T>(
  selector: (
    currentSegment: PayerPathSegment | null,
    segments: PayerPathSegment[],
  ) => T,
) {
  const currentSegment = useCurrentPayerSegment()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()

  const segments = getPayerSegments({publicCollection}).filter(
    (segment) => !isPayerSegmentDisabled({segment, publicCollection, cart}),
  )

  return selector(currentSegment, segments)
}

export function useNextPayerSegment() {
  return usePayerSegmentSelector((currentSegment, segments) =>
    currentSegment
      ? segments[segments.indexOf(currentSegment) + 1] ?? null
      : null,
  )
}

export function usePayerNavigateToSegment() {
  const location = useLocation()
  const navigate = useNavigate()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const growlActions = WebUI.useGrowlActions()
  const currentSegment = useCurrentPayerSegment()
  const payerUIState = usePayerUIState()

  function navigateToSegment(targetSegment: PayerPathSegment) {
    if (currentSegment === targetSegment) {
      return
    }

    const payerSegments = getPayerSegments({publicCollection})
    const currentSegmentIdx = currentSegment
      ? payerSegments.indexOf(currentSegment)
      : 0
    const nextSegments = payerSegments.slice(currentSegmentIdx)

    if (currentSegment === 'items' && nextSegments.includes(targetSegment)) {
      if (!isPaymentRequirementFulfilled({publicCollection, cart})) {
        growlActions.show('error', {
          title: 'Error',
          body: 'Payment is required. Please add an item with a value greater than zero to your cart to proceed.',
        })
        return
      }
      if (hasMissingRequiredItems({publicTab: publicCollection, cart})) {
        payerUIState.itemPickerRef.current?.viewRequiredItems()
        return
      }
      if (
        (!cart || cart.itemCount === 0) &&
        !payerUIState.noItemsInCartAlertVisible
      ) {
        payerUIState.setNoItemsInCartAlertVisible(true)
        return
      }
    } else if (
      currentSegment === 'forms' &&
      nextSegments.includes(targetSegment)
    ) {
      if (
        hasMissingRequiredFormFields({publicTab: publicCollection, cart}) ||
        hasMissingRequiredSignups({publicTab: publicCollection, cart})
      ) {
        payerUIState.formPickerRef.current?.viewRequiredForms()
        return
      }
    }

    navigate({
      pathname: `../${targetSegment}`,
      search: location.search,
    })
  }

  return navigateToSegment
}

export function usePayerNavigateToNextSegment() {
  const navigateToSegment = usePayerNavigateToSegment()
  const nextSegment = useNextPayerSegment()

  function navigateToNextSegment() {
    if (!nextSegment) {
      return
    }

    navigateToSegment(nextSegment)
  }

  return navigateToNextSegment
}

// MARK: – Validations

export function isPaymentRequirementFulfilled({
  publicCollection,
  cart,
}: {
  publicCollection: Api.PublicTab
  cart?: Api.Cart
}) {
  return (
    !publicCollection.requirePayment || CartHelpers.hasNonZeroAmountItems(cart)
  )
}
