import {
  toRgba as unsafeToRgba,
  toHex as unsafeToHex,
  parseToRgba as unsafeParseToRgba,
  rgba,
  getContrast,
} from 'color2k'

export const MIN_COLOR = '#000000'
export const MAX_COLOR = '#FFFFFF'
export const MIN_COLOR_INT = Number.parseInt(MIN_COLOR, 16)
export const MAX_COLOR_INT = Number.parseInt(MAX_COLOR, 16)

export function getReadableColor(
  color: string,
  defaults?: {lightColor?: string; darkColor?: string},
  mapBlackToDarkGray?: boolean,
) {
  try {
    const readableColor = getReadableColorIsBlack(color, defaults?.darkColor)
      ? defaults?.lightColor ?? '#FFFFFF'
      : defaults?.darkColor ?? '#000000'
    return mapBlackToDarkGray && readableColor === '#000000'
      ? '#373737'
      : readableColor
  } catch {
    return undefined
  }
}

export function getReadableColorIsBlack(
  backgroundColor: string,
  contentColor = '#FFFFFF',
) {
  try {
    // See https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
    // adjusted the w3 algo to incline towards white text
    return getContrast(contentColor, backgroundColor) > 2
  } catch {
    return undefined
  }
}

export function toHex(value: string) {
  try {
    return unsafeToHex(value)
  } catch {
    return undefined
  }
}

export function hexToInt(hex: string) {
  const cleanHex = hex.startsWith('#') ? hex.slice(1) : hex
  return Number.parseInt(cleanHex, 16)
}

export function addHexValue(hex: string, step: number) {
  const colorInt = hexToInt(hex)

  const clampInt = Math.min(
    Math.max(colorInt + step, MIN_COLOR_INT),
    MAX_COLOR_INT,
  )
  if (clampInt !== colorInt) {
    return `#${clampInt.toString(16).padStart(6, '0').toUpperCase()}`
  }

  return hex
}

export function toRgba(value: string) {
  try {
    return unsafeToRgba(value)
  } catch {
    return undefined
  }
}

export function parseRgba(value: string) {
  try {
    return unsafeParseToRgba(value)
  } catch {
    return undefined
  }
}

export function setRgba(
  value: string,
  updateValue: [number | undefined, number?, number?, number?],
) {
  const parsedRgba = parseRgba(value)
  if (!parsedRgba) {
    return undefined
  }

  return rgba(
    updateValue[0] ?? parsedRgba[0],
    updateValue[1] ?? parsedRgba[1],
    updateValue[2] ?? parsedRgba[2],
    updateValue[3] ?? parsedRgba[3],
  )
}
