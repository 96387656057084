import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export const InitialsFieldsEdit = ({
  required,
  focused,
  initialLabels,
  placeholders,
  onLabelChange,
}: Omit<
  FieldGroupFieldsEditProps<'initials'>,
  'fieldGroupType' | 'initialValue' | 'onValueChange'
>) => (
  <FormFieldInlineLabelEdit
    className="[&_>_div_>_.FormField-input]:w-[calc(4em+theme(spacing.2))]"
    labelInputFocused={focused}
    required={required}
    initialLabel={initialLabels.value}
    placeholder={placeholders.value}
    onLabelChange={(label) => onLabelChange('value', label)}
  >
    <WebUI.InitialsInput disabled />
  </FormFieldInlineLabelEdit>
)
