import {z} from '@cheddarup/util'

const baseInventoryGroupItemSchema = z.object({
  id: z.number(),
  tab_object_id: z.number(),
  available_quantity: z.number().nullable(),
  variant_uuid: z.string().or(z.literal('NONE')),
})

const baseInventoryGroupSchema = z.object({
  id: z.number(),
  tab_object_id: z.number(),
  tab_id: z.number(),
  name: z.string().nullable(),
  available_quantity: z.number(),
})

export const inventoryGroupItemSchema = baseInventoryGroupItemSchema.extend({
  inventory_groups: baseInventoryGroupSchema.array(),
})
export const inventoryGroupSchema = baseInventoryGroupSchema.extend({
  inventory_items: baseInventoryGroupItemSchema.array(),
})

declare global {
  namespace Api {
    type InventoryGroup = z.infer<typeof inventoryGroupSchema>
    type InventoryGroupItem = z.infer<typeof inventoryGroupItemSchema>
  }
}
