import {initSentry} from './helpers/sentry-helpers'
import {requestApi, useApiHeaders} from './helpers/client'
import {extendYup} from './helpers/yup-extensions'

const fixDataLayer = () => {
  // dataLayer navigates to about:blank on localhost and amplify
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('amplifyapp.com') ||
    window.location.hostname.endsWith('netlify.app')
  ) {
    window.dataLayer = window.dataLayer || []
    // @ts-expect-error
    window.dataLayer.push = () => {
      // noop
    }
  }
}

const init = () => {
  fixDataLayer()
  extendYup()
  initSentry()

  // @ts-expect-error
  globalThis.fetchApi = requestApi
  // @ts-expect-error
  globalThis.useApiHeaders = useApiHeaders
}

init()
