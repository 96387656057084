export type BrandKitColors = ReturnType<typeof getBrandKitColors>

export const brandKitPalettes = {
  tint: [
    '#277B90',
    '#26B8CF',
    '#3FBEA6',
    '#42B05B',
    '#F36D36',
    '#E078BB',
    '#9E9E9E',
    '#414042',
  ],
  neutral: [
    '#EEEEEE',
    '#D0E9ED',
    '#F1ECE3',
    '#CCE6D2',
    '#E9CDF2',
    '#D3B2F5',
    '#DEDEDE',
    '#EEEEEE',
  ],
}

export function getBrandKitColors(
  branding:
    | Pick<
        Api.UserBranding,
        'primary_color' | 'secondary_color' | 'neutral_color'
      >
    | undefined,
) {
  return {
    primary_color:
      branding?.primary_color ?? defaultBrandKitColorMap.primary_color,
    secondary_color:
      branding?.secondary_color ?? defaultBrandKitColorMap.secondary_color,
    neutral_color:
      branding?.neutral_color ?? defaultBrandKitColorMap.neutral_color,
  }
}

export const defaultBrandKitColorMap = {
  primary_color: '#277B90',
  secondary_color: '#F36D36',
  neutral_color: '#EEEEEE',
}
