import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {ConvertTemplateToTabButton} from 'src/components/ConvertTemplateToTabButton'

import usePublicCollection from '../../c/hooks/usePublicCollection'
import PayerRouter from '../../c/PayerRouter'

export const CollectionDraftPreviewPage = () => {
  const media = WebUI.useMedia()
  const navigate = useNavigate()
  const {publicCollection} = usePublicCollection()

  return (
    <WebUI.Modal
      aria-label="Collection payer preview"
      contentViewAppearance="overlay"
      onDidHide={() => navigate('..')}
    >
      {!media.sm && publicCollection?.type === 'Template' && (
        <WebUI.HStack className="h-16 min-h-16 items-center justify-center border-b bg-natural-100">
          <ConvertTemplateToTabButton
            className="[&_>_.Button-content]:font-bold"
            size="default"
            templateId={publicCollection.id}
          >
            Make this template yours
          </ConvertTemplateToTabButton>
        </WebUI.HStack>
      )}

      <PayerRouter shouldRenderModalCloseButton />

      {publicCollection?.type === 'Template' && (
        <WebUI.Portal className="fixed bottom-12 left-12 hidden sm:flex">
          <UseTemplateCta templateId={publicCollection.id} />
        </WebUI.Portal>
      )}
    </WebUI.Modal>
  )
}

// MARK: – UseTemplateCta

interface UseTemplateCtaProps extends React.ComponentPropsWithoutRef<'div'> {
  templateId: number
}

const UseTemplateCta: React.FC<UseTemplateCtaProps> = ({
  templateId,
  className,
  ...restProps
}) => {
  const [isVisible, setIsVisible] = useState(true)

  if (!isVisible) {
    return null
  }

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'relative w-[320px] gap-6 rounded-[20px] p-8',
        className,
      )}
      as={WebUI.Panel}
      variant="shadowed"
      {...restProps}
    >
      <WebUI.Heading className="font-bold" as="h4">
        Like this Template?
        <br />
        Make it yours!
      </WebUI.Heading>

      <WebUI.VStack
        className="ml-3 list-disc gap-1 font-normal text-ds-sm"
        as="ul"
      >
        <li>May include paid features which can be removed if desired</li>
        <li>100% fully customizable</li>
        <li>Share and publish anywhere</li>
        <li>Start collecting payments and information in minutes</li>
      </WebUI.VStack>

      <ConvertTemplateToTabButton
        className="[&_>_.Button-content]:font-bold"
        variant="primary"
        templateId={templateId}
      />

      <WebUI.IconButton
        className="absolute top-4 right-4 text-ds-xl"
        size="default_alt"
        onClick={() => setIsVisible(false)}
      >
        <WebUI.PhosphorIcon icon="x" />
      </WebUI.IconButton>
    </WebUI.VStack>
  )
}
