import {AnimatePresence, motion} from 'framer-motion'
import {Link, LinkProps} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React, {useCallback, useState} from 'react'
import {Logo} from 'src/components'

interface NavBarMobileProps extends React.ComponentPropsWithoutRef<'div'> {
  branding?: Api.UserBranding
}

const NavBarMobile = ({
  className,
  branding,
  ...restProps
}: NavBarMobileProps) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const showMenu = useCallback(() => {
    setMenuVisible(true)
  }, [])
  const hideMenu = useCallback(() => {
    setMenuVisible(false)
  }, [])

  return (
    <nav
      className={WebUI.cn('w-full border-b bg-natural-100 p-4', className)}
      {...restProps}
    >
      <div className="flex items-start justify-between">
        {branding?.logo ? (
          <img src={branding?.logo?.url} alt="logo" className="max-h-13" />
        ) : (
          <Logo />
        )}
        <WebUI.PhosphorIcon
          className="text-ds-3xl text-gray800"
          icon="list"
          onClick={showMenu}
        />
      </div>
      <AnimatePresence>
        {menuVisible && (
          <motion.div
            className={
              'absolute top-0 left-0 z-[999] flex h-screen w-screen flex-col bg-natural-100'
            }
            initial={{x: '100%'}}
            animate={{
              x: 0,
              transition: {
                type: 'spring',
                stiffness: 200,
                damping: 20,
                duration: 200,
              },
            }}
          >
            <div className="min-h-0 min-w-0 flex-auto overflow-scroll pt-10">
              <WebUI.IconButton
                className="absolute top-2 right-2 text-ds-xl"
                size="default_alt"
                onClick={hideMenu}
              >
                <WebUI.PhosphorIcon icon="x" />
              </WebUI.IconButton>
              <nav>
                <ul>
                  <NavLi to="share" onClick={hideMenu}>
                    Share
                  </NavLi>
                  <NavLi to="help" onClick={hideMenu}>
                    Help
                  </NavLi>
                  {branding?.footer.mainWebsiteLink?.text && (
                    <NavLi
                      to={branding.footer.mainWebsiteLink.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-normal"
                    >
                      {branding.footer.mainWebsiteLink.text}
                    </NavLi>
                  )}
                </ul>
              </nav>
            </div>
            <footer
              className={WebUI.cn(
                'px-5 py-8',
                !branding?.logo && 'bg-gray100 ',
              )}
            >
              {branding?.logo ? (
                <img
                  src={branding?.logo?.url}
                  alt="logo"
                  className="max-h-13"
                />
              ) : (
                <WebUI.LogoIcon className="justify-start" />
              )}
            </footer>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

// MARK: – NavLi

const NavLi = ({className, to = '#', ...restProps}: LinkProps) => (
  <li>
    <Link
      replace
      className={WebUI.cn(
        'inline-block h-full w-full px-5 py-4 text-ds-sm text-gray800',
        className,
      )}
      to={to}
      {...restProps}
    />
  </li>
)

export default NavBarMobile
