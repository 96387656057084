import * as Yup from 'yup'
import {Field, Formik} from 'formik'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {
  endpoints,
  getEndpointKey,
  useQueryClient,
  useResetPasswordMutation,
} from '@cheddarup/api-client'
import queryString from 'query-string'
import * as WebUI from '@cheddarup/web-ui'
import {InquireVerificationCode} from 'src/components/InquireVerificationCode'
import {LoginFooter} from 'src/views/onboard/components'
import {AuthLayout} from 'src/components/AuthLayout'
import {read2FAError} from 'src/helpers/error-formatting'

const ResetPasswordPage = () => {
  const location = useLocation()
  const growlActions = WebUI.useGrowlActions()
  const resetPasswordMutation = useResetPasswordMutation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {code, email, view} = queryString.parse(location.search)

  if (typeof code !== 'string' || typeof email !== 'string') {
    return <Navigate replace to="/login" />
  }

  return (
    <AuthLayout>
      <WebUI.VStack className="gap-10 p-10">
        <WebUI.LogoIcon />
        <WebUI.VStack className="gap-1">
          <WebUI.Heading className="text-center">
            {view === 'signup' ? 'Set Your New' : 'Reset'} Password
          </WebUI.Heading>
          <InquireVerificationCode>
            {(verificationHelpers) => (
              <ResetPasswordForm
                code={code}
                email={email}
                onVerifyTwoFactor={async ({lastTwoNumbers, ...values}) => {
                  try {
                    const {verificationCode} =
                      await verificationHelpers.verifyPhone({lastTwoNumbers})
                    await resetPasswordMutation.mutateAsync({
                      body: {
                        email,
                        code,
                        security: {token: verificationCode},
                        ...values,
                      },
                    })

                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Your password was successfully updated',
                    })

                    const sessionQueryKey = getEndpointKey(
                      endpoints.auth.session,
                    )
                    await queryClient.invalidateQueries({
                      queryKey: sessionQueryKey,
                    })
                    navigate('/login', {replace: true})
                  } catch (err: any) {
                    growlActions.clear()
                    growlActions.show('error', {
                      title: 'Error',
                      body:
                        read2FAError(err) ||
                        'Something went wrong while resetting your password',
                    })
                  }
                }}
              />
            )}
          </InquireVerificationCode>
        </WebUI.VStack>
      </WebUI.VStack>
      <LoginFooter />
    </AuthLayout>
  )
}

// MARK: – ResetPasswordForm

interface ResetPasswordFormValues {
  password: string
  password_confirmation: string
}

interface ResetPasswordFormProps {
  className?: string
  code: string
  email: string
  onVerifyTwoFactor: (
    values: ResetPasswordFormValues & {lastTwoNumbers: string},
  ) => void
}

const ResetPasswordForm = ({
  code,
  email,
  onVerifyTwoFactor,
  className,
}: ResetPasswordFormProps) => {
  const resetPasswordMutation = useResetPasswordMutation()
  const navigate = useNavigate()
  const growlActions = WebUI.useGrowlActions()
  const queryClient = useQueryClient()

  return (
    <Formik<ResetPasswordFormValues>
      initialValues={{
        password: '',
        password_confirmation: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('Required')
          .min(6, 'Include 6 characters')
          .matches(/\d/, 'Include a number'),
        password_confirmation: Yup.string()
          .required('Required')
          .min(6, 'Include 6 characters')
          .matches(/\d/, 'Include a number')
          .oneOf([Yup.ref('password')], 'Must be equal'),
      })}
      onSubmit={async (values) => {
        const payload = {
          ...values,
          code,
          email,
        }
        try {
          await resetPasswordMutation.mutateAsync({body: payload})
          growlActions.show('success', {
            title: 'Success',
            body: 'Your password was successfully updated',
          })

          const sessionQueryKey = getEndpointKey(endpoints.auth.session)
          await queryClient.invalidateQueries({queryKey: sessionQueryKey})
          navigate('/login', {replace: true})
        } catch (err: any) {
          const firstError = err.response?.data?.errors?.[0]
          if (firstError?.error === 'security_token_sent') {
            onVerifyTwoFactor({
              ...values,
              lastTwoNumbers: firstError.details.lastTwoNumbers,
            })
          } else {
            growlActions.show('error', {
              title: 'Error',
              body: 'This reset link has expired. Please click the link in the most recent Reset Password email in your inbox.',
            })
          }
        }
      }}
    >
      {({errors, isSubmitting, handleSubmit, handleReset}) => (
        <WebUI.Form
          className={WebUI.cn('[&_>_.Form-inner]:gap-7', className)}
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <WebUI.VStack className="gap-4">
            <Field
              name="password"
              as={WebUI.PasswordFormFieldInput}
              autoComplete="new-password"
              error={errors.password}
              caption="Passwords must contain at least 6 characters and 1 number."
            />
            <WebUI.FormField label="Confirm Password">
              <Field
                name="password_confirmation"
                as={WebUI.Input}
                type="password"
              />
            </WebUI.FormField>
          </WebUI.VStack>
          <WebUI.Button
            className="mt-8 self-center"
            type="submit"
            size="large"
            variant="primary"
            loading={isSubmitting}
          >
            Reset Password
          </WebUI.Button>
        </WebUI.Form>
      )}
    </Formik>
  )
}

export default ResetPasswordPage
