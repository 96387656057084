import {BooleanParam, useQueryParam} from 'use-query-params'
import React, {useEffect, useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {api} from '@cheddarup/api-client'
import {BankAccountSelectRow, CreditCardSelectRow} from 'src/components'
import {InputWithTooltip} from 'src/components/BankAccountForm'
import ECheckAccountNumberHelpImage from 'src/images/ECheckAccountNumberHelp.jpg'
import ECheckRoutingNumberHelpImage from 'src/images/ECheckRoutingNumberHelp.jpg'
import {
  PaymentElement,
  PaymentElementProps,
} from 'src/components/Stripe/PaymentElement'
import * as Util from '@cheddarup/util'
import {useIsAuthed} from 'src/hooks/useAuthToken'

import useCart from '../hooks/useCart'
import type {CheckoutFormik} from './CheckoutPage'
import usePublicCollection from '../hooks/usePublicCollection'
import {CheckoutSteppedPanel} from './CheckoutSteppedPanel'

export interface PaymentMethodPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: CheckoutFormik
  onStripeElementLoadingChange?: (isLoading: boolean) => void
}

export const PaymentMethodPanel = ({
  formik,
  onStripeElementLoadingChange,
  className,
  ...restProps
}: PaymentMethodPanelProps) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const {publicCollection} = usePublicCollection()
  const paymentMethodsQuery = api.paymentMethods.list.useQuery(undefined, {
    enabled: !addPayment,
  })

  const banks = useMemo(
    () => paymentMethodsQuery.data?.banks ?? [],
    [paymentMethodsQuery.data?.banks],
  )
  const cards = useMemo(
    () => paymentMethodsQuery.data?.cards ?? [],
    [paymentMethodsQuery.data?.cards],
  )

  const setFieldValue = formik.setFieldValue
  const isCardSelectedRef = useLiveRef(!!formik.values.paymentMethod.card)
  const isEcheckSelectedRef = useLiveRef(
    !!formik.values.paymentMethod.echeck.id,
  )
  useEffect(() => {
    if (cards[0] && !isCardSelectedRef.current) {
      setFieldValue('paymentMethod.method', 'card', false)
      setFieldValue('paymentMethod.useSaved', true, false)
      setFieldValue('paymentMethod.card', cards[0], false)
    } else if (
      banks[0] &&
      !isEcheckSelectedRef.current &&
      publicCollection?.acceptsEcheck
    ) {
      setFieldValue('paymentMethod.method', 'echeck', false)
      setFieldValue('paymentMethod.useSaved', true, false)
      setFieldValue('paymentMethod.echeck.id', banks[0].id, false)
    }
    if (banks.length === 0) {
      setFieldValue('paymentMethod.echeck.id', null, false)
    }
    if (cards.length === 0) {
      setFieldValue('paymentMethod.card', null, false)
    }
    if (cards.length === 0 && banks.length === 0) {
      setFieldValue('paymentMethod.useSaved', false, false)
    }
  }, [banks, cards, publicCollection?.acceptsEcheck, setFieldValue])

  const isBanksEmpty = banks.length === 0
  const isCardsEmpty = cards.length === 0
  const setFormikFieldValue = formik.setFieldValue
  useUpdateEffect(() => {
    if (
      formik.values.paymentMethod.useSaved &&
      ((isCardsEmpty && formik.values.paymentMethod.method === 'card') ||
        (isBanksEmpty && formik.values.paymentMethod.method === 'echeck'))
    ) {
      setFormikFieldValue('paymentMethod.useSaved', false)
    }
  }, [
    formik.values.paymentMethod.useSaved,
    formik.values.paymentMethod.method,
    setFormikFieldValue,
    isBanksEmpty,
    isCardsEmpty,
  ])

  if (formik.values.paymentMethod.method === 'cash') {
    return null
  }

  if (paymentMethodsQuery.isFetching) {
    return (
      <WebUI.VStack
        className={WebUI.cn('items-center justify-center', className)}
        {...restProps}
      >
        <WebUI.Loader size="2.5em" />
      </WebUI.VStack>
    )
  }

  return (
    <CheckoutSteppedPanel
      className={className}
      step="payment"
      heading="Payment Method"
      {...restProps}
    >
      {formik.values.paymentMethod.method === 'card' ? (
        <CreditCardSelect
          cards={cards}
          formik={formik}
          onStripeElementLoadingChange={onStripeElementLoadingChange}
        />
      ) : formik.values.paymentMethod.method === 'echeck' ? (
        <WebUI.VStack className="gap-4">
          {formik.values.paymentMethod.useSaved ? (
            <BankAccountRadioGroup formik={formik} banks={banks} />
          ) : (
            <BankAccountForm formik={formik} />
          )}
          {banks.length > 0 && <UseSavedPaymentMethodButton formik={formik} />}
        </WebUI.VStack>
      ) : null}
    </CheckoutSteppedPanel>
  )
}

// MARK: – CreditCardSelect

export interface CreditCardSelectProps {
  cards: Api.CreditCard[]
  formik: CheckoutFormik
  onStripeElementLoadingChange?: (isLoading: boolean) => void
}

const CreditCardSelect = ({
  cards,
  formik,
  onStripeElementLoadingChange,
}: CreditCardSelectProps) => {
  const [cardPaymentMethodType, setCardPaymentMethodType] = useState('card')

  return (
    <WebUI.VStack className="gap-4">
      {formik.values.paymentMethod.useSaved ? (
        <CreditCardRadioGroup formik={formik} cards={cards} />
      ) : (
        <CreditCardForm
          formik={formik}
          cardPaymentMethodType={cardPaymentMethodType}
          onLoadingChange={onStripeElementLoadingChange}
          onPaymentElementChange={(event) =>
            setCardPaymentMethodType(event.value.type)
          }
        />
      )}
      {cardPaymentMethodType === 'card' && cards.length > 0 && (
        <UseSavedPaymentMethodButton formik={formik} />
      )}
    </WebUI.VStack>
  )
}

// MARK: – CreditCardForm

interface CreditCardFormProps extends React.ComponentPropsWithoutRef<'div'> {
  formik: CheckoutFormik
  cardPaymentMethodType?: string
  onPaymentElementChange?: PaymentElementProps['onChange']
  onLoadingChange?: (isLoading: boolean) => void
}

const CreditCardForm = ({
  formik,
  cardPaymentMethodType,
  onPaymentElementChange,
  onLoadingChange,
  className,
  ...restProps
}: CreditCardFormProps) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const {cart} = useCart()
  const isLoggedIn = useIsAuthed()
  const sessionQuery = api.auth.session.useQuery(undefined, {
    enabled: isLoggedIn,
  })
  const [isPaymentElementReady, setIsPaymentElementReady] = useState(false)

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              name: cart?.member?.name ?? sessionQuery.data?.user.full_name,
              email: cart?.member?.email ?? sessionQuery.data?.user.email,
              phone:
                cart?.phoneNumber ??
                sessionQuery.data?.user.profile.phone.fullNumber ??
                undefined,
            },
          },
        }}
        onLoadingChange={onLoadingChange}
        onReady={() => setIsPaymentElementReady(true)}
        onChange={onPaymentElementChange}
      />
      {cardPaymentMethodType === 'card' &&
        !!sessionQuery.data &&
        !addPayment &&
        isPaymentElementReady && <SaveForFutureCheckbox formik={formik} />}
    </WebUI.VStack>
  )
}

// MARK: – CreditCardRadioGroup

interface CreditCardRadioGroupProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    WebUI.RadioGroupProps
  > {
  formik: CheckoutFormik
  cards: Api.CreditCard[]
}

const CreditCardRadioGroup = ({
  formik,
  cards,
  className,
  ...restProps
}: CreditCardRadioGroupProps) => (
  <WebUI.RadioGroup
    aria-label="Credit card select"
    className="gap-0 rounded bg-natural-80 py-1"
    name="paymentMethod.card"
    state={formik.values.paymentMethod.card?.id as any}
    onChange={(event) =>
      formik.setFieldValue(
        'paymentMethod.card',
        cards.find((c) => c.id === event.target.value),
      )
    }
    onBlur={formik.handleBlur}
    {...restProps}
  >
    {cards.map((card) => (
      <CreditCardSelectRow
        key={card.id}
        className="[&_>_.CreditCardSelectRow-brandLogo]:h-9"
        creditCard={card}
        as={WebUI.Radio}
        value={card.id}
      />
    ))}
  </WebUI.RadioGroup>
)

// MARK: – BankAccountRadioGroup

interface BankAccountRadioGroupProps
  extends Util.Merge<
    WebUI.RadioGroupProps,
    React.ComponentPropsWithoutRef<'div'>
  > {
  formik: CheckoutFormik
  banks: Api.BankAccount[]
}

const BankAccountRadioGroup = ({
  formik,
  banks,
  className,
  ...restProps
}: BankAccountRadioGroupProps) => (
  <WebUI.RadioGroup
    aria-label="Bank account select"
    className={WebUI.cn('gap-0 rounded bg-natural-80 py-1', className)}
    name="paymentMethod.echeck.id"
    state={formik.values.paymentMethod.echeck.id as any}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    {...restProps}
  >
    {banks.map((bankAccount) => (
      <BankAccountSelectRow
        key={bankAccount.id}
        bankAccount={bankAccount}
        as={WebUI.Radio}
        value={bankAccount.id}
      />
    ))}
  </WebUI.RadioGroup>
)

// MARK: – BankAccountForm

interface BankAccountFormProps extends React.ComponentPropsWithoutRef<'div'> {
  formik: CheckoutFormik
}

const BankAccountForm = ({
  formik,
  className,
  ...restProps
}: BankAccountFormProps) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const isLoggedIn = useIsAuthed()

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.FormField
        label="Routing Number"
        error={formik.errors.paymentMethod?.echeck?.routingNumber}
      >
        <InputWithTooltip
          name="paymentMethod.echeck.routingNumber"
          tooltipContent={
            <WebUI.Image
              src={ECheckRoutingNumberHelpImage}
              alt="ECheck routing number help"
              width={240}
              height={240}
            />
          }
          placeholder="9 digits"
          value={formik.values.paymentMethod.echeck.routingNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField
        label="Account Number"
        error={formik.errors.paymentMethod?.echeck?.accountNumber}
      >
        <InputWithTooltip
          name="paymentMethod.echeck.accountNumber"
          tooltipContent={
            <WebUI.Image
              alt="ECheck account number help"
              src={ECheckAccountNumberHelpImage}
              width={240}
              height={240}
            />
          }
          placeholder="up to 17 digits"
          value={formik.values.paymentMethod.echeck.accountNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      {isLoggedIn && !addPayment && <SaveForFutureCheckbox formik={formik} />}
    </WebUI.VStack>
  )
}

// MARK: – UseSavedPaymentMethodButton

interface UseSavedPaymentMethodButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  formik: CheckoutFormik
}

const UseSavedPaymentMethodButton = ({
  formik,
  className,
  ...restProps
}: UseSavedPaymentMethodButtonProps) => (
  <WebUI.Button
    className={WebUI.cn('text-ds-sm', className)}
    variant="link"
    onClick={() =>
      formik.setFieldValue(
        'paymentMethod.useSaved',
        !formik.values.paymentMethod.useSaved,
      )
    }
    {...restProps}
  >
    Use {formik.values.paymentMethod.useSaved ? 'New' : 'Saved'}{' '}
    {formik.values.paymentMethod.method === 'card' ? 'Card' : 'Account'}
  </WebUI.Button>
)

// MARK: – SaveForFutureCheckbox

interface SaveForFutureCheckboxProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'input'>,
    WebUI.CheckboxProps
  > {
  formik: CheckoutFormik
}

const SaveForFutureCheckbox = ({
  formik,
  ...restProps
}: SaveForFutureCheckboxProps) => {
  const {cart} = useCart()
  return (
    <WebUI.Checkbox
      name="paymentMethod.saveSource"
      size="compact"
      disabled={!!cart && cart.recurringTotal > 0}
      checked={formik.values.paymentMethod.saveSource}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      {...restProps}
    >
      {`Save this ${
        formik.values.paymentMethod.method === 'card' ? 'card' : 'account'
      } for future payments`}
    </WebUI.Checkbox>
  )
}
