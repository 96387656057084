import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import React, {useState} from 'react'
import {
  api,
  useApplyCartDiscountMutation,
  useResetCartDiscountMutation,
} from '@cheddarup/api-client'
import CartHelpers from 'src/helpers/CartHelpers'
import {STATEMENT_DESCRIPTOR_PREFIX} from 'src/helpers/user-helpers'

import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'
import {CheckoutSteppedPanel} from './CheckoutSteppedPanel'
import type {CheckoutFormik} from './CheckoutPage'
import {CheckoutButton, TermsText} from '../components/CheckoutButton'
import {BooleanParam, useQueryParam} from 'use-query-params'
import {useAsyncEffect} from '@cheddarup/react-util'
import {memoizedFetchCheckEmailExistence} from '../utils/payer-auth-utils'
import {useIsAuthed} from 'src/hooks/useAuthToken'

export interface CheckoutInvoicePanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: CheckoutFormik
  hasPaymentIntent: boolean
  checkoutDisabled?: boolean
  onIsDiscountAppliedChange?: (isDiscountNotApplied: boolean) => void
}

export const CheckoutInvoicePanel = ({
  formik,
  hasPaymentIntent,
  checkoutDisabled,
  onIsDiscountAppliedChange,
  className,
  ...restProps
}: CheckoutInvoicePanelProps) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [updateCartMutation] = useEnhancedUpdateCartMutation()
  const isAuthed = useIsAuthed()
  const userEmailQuery = api.auth.session.useQuery(undefined, {
    enabled: isAuthed && !addPayment,
    select: (session) => session.user.email,
  })

  const paymentMethod =
    !cart || cart.total === 0 ? null : formik.values.paymentMethod.method
  const feesAmount = CartHelpers.getFees({cart, paymentMethod})
  const totalWithoutRecurring = cart?.totalWithoutFutureRecurring ?? 0
  const recurringTotal = cart?.recurringTotal ?? 0
  const isEcheckSelected = paymentMethod === 'echeck'
  const total = cart ? cart.total + feesAmount : 0

  const payerEmail = cart?.member?.email ?? userEmailQuery.data

  const isDripOptInCheckboxVisibleAsync = useAsyncEffect(async () => {
    if (addPayment || !payerEmail) {
      return false
    }

    const checkRes = await memoizedFetchCheckEmailExistence({
      body: {email: payerEmail},
    })
    return checkRes.show_opt_in
  }, [addPayment, payerEmail])
  const isDripOptInCheckboxVisible =
    Util.toNullable(isDripOptInCheckboxVisibleAsync) === true

  return (
    <CheckoutSteppedPanel
      className={WebUI.cn('relative', className)}
      step="payment"
      expanded
      {...restProps}
    >
      <WebUI.VStack className="gap-6">
        {total > 0 && (
          <>
            <WebUI.VStack className="gap-2">
              <CheckoutInvoiceRow
                amountBold
                title="Subtotal"
                amount={cart?.items_total ?? 0}
              />
              {Object.keys(cart?.discounts ?? {}).map((discountCode) => (
                <CheckoutInvoiceRow
                  key={discountCode}
                  title={`Promo (${discountCode})`}
                  amount={-(cart?.discounts[discountCode]?.amount ?? 0)}
                />
              ))}
              {feesAmount
                ? Object.keys(cart?.fees ?? {}).length > 0 && (
                    <CheckoutInvoiceRow title="Fees" amount={feesAmount} />
                  )
                : null}
              {!!publicCollection.shippingOptions.shipToEnabled &&
                cart?.shippingInfo.shippingMethod === 'toMe' && (
                  <CheckoutInvoiceRow
                    freeTitleAllowed
                    title="Shipping"
                    amount={CartHelpers.getShipping({cart})}
                  />
                )}
              {!!cart?.totalTaxes && (
                <CheckoutInvoiceRow title="Tax" amount={cart.totalTaxes} />
              )}
            </WebUI.VStack>

            <WebUI.Separator variant="primary" />

            {publicCollection.discountsEnabled && (
              <>
                <CheckoutDiscountCode
                  onIsDiscountAppliedChange={onIsDiscountAppliedChange}
                />
                <WebUI.Separator variant="primary" />
              </>
            )}

            <CheckoutInvoiceRow amountBold title="Total" amount={total} />
          </>
        )}

        {paymentMethod === 'card' && (
          <p className="text-ds-sm">
            This payment will appear as{' '}
            <span className="font-semibold">
              {STATEMENT_DESCRIPTOR_PREFIX}{' '}
              {publicCollection.statementDescriptor}
            </span>{' '}
            on credit card statement descriptor.
          </p>
        )}
        {paymentMethod === 'card' &&
          totalWithoutRecurring === 0 &&
          recurringTotal > 0 && (
            <p className="text-ds-sm">
              A $1.00 temporary hold may appear on your card for 5 business
              days.
            </p>
          )}

        <div className="flex flex-col gap-3">
          {isDripOptInCheckboxVisible && (
            <WebUI.Checkbox
              name="dripOptIn"
              state={formik.values.dripOptIn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              I want tips for collecting payments, sign ups, and forms
            </WebUI.Checkbox>
          )}
          <TermsText eCheck={isEcheckSelected} />
          <CheckoutButton
            type="submit"
            size="large"
            variant={hasPaymentIntent ? 'primary' : 'default'}
            roundness="capsule"
            echeckSelected={isEcheckSelected}
            loading={formik.isSubmitting}
            disabled={updateCartMutation.isPending || checkoutDisabled}
          >
            {hasPaymentIntent ? 'Pay Now' : 'Submit'}
          </CheckoutButton>
        </div>
      </WebUI.VStack>
    </CheckoutSteppedPanel>
  )
}

// MARK: – CheckoutInvoiceRow

interface CheckoutInvoiceRowProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  amountBold?: boolean
  freeTitleAllowed?: boolean
  title: React.ReactNode
  amount: number
}

const CheckoutInvoiceRow = ({
  className,
  amountBold,
  freeTitleAllowed,
  title,
  amount,
  ...restProps
}: CheckoutInvoiceRowProps) => (
  <WebUI.HStack
    className={WebUI.cn(
      'items-center',
      amountBold ? 'text-ds-base' : 'text-ds-sm',
      className,
    )}
    {...restProps}
  >
    <WebUI.Text className="min-h-0 min-w-0 flex-auto">{title}:</WebUI.Text>
    <WebUI.Text
      className={WebUI.cn(
        'text-right',
        freeTitleAllowed && amount === 0 ? 'text-orange-50' : 'text-gray750',
        amountBold && 'font-semibold text-gray800',
      )}
    >
      {freeTitleAllowed && amount === 0 ? 'Free!' : Util.formatAmount(amount)}
    </WebUI.Text>
  </WebUI.HStack>
)

// MARK: – CheckoutDiscountCode

interface CheckoutDiscountCodeProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onIsDiscountAppliedChange?: (isDiscountNotApplied: boolean) => void
}

const CheckoutDiscountCode = ({
  onIsDiscountAppliedChange,
  className,
  ...restProps
}: CheckoutDiscountCodeProps) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const applyDiscountCodeMutation = useApplyCartDiscountMutation()
  const resetDiscountCodeMutation = useResetCartDiscountMutation()
  const growlActions = WebUI.useGrowlActions()
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const appliedDiscountCodes = Object.keys(cart?.discounts ?? {})

  const isDiscountApplied = appliedDiscountCodes.length > 0

  return (
    <div className={WebUI.cn('relative', className)} {...restProps}>
      <WebUI.Input
        className="w-full"
        disabled={isLoading}
        placeholder="Have a promo code?"
        value={code}
        onChange={(event) => setCode(event.target.value)}
      />
      <WebUI.Button
        className="-translate-y-1/2 absolute top-1/2 right-3 font-light"
        variant="link"
        loading={isLoading}
        onClick={async () => {
          if (!cart) {
            return
          }

          setIsLoading(true)

          if (code || !isDiscountApplied) {
            try {
              if (
                publicCollection.onlyAllowOneDiscountPerPurchase &&
                appliedDiscountCodes.length > 0
              ) {
                growlActions.clear()
                growlActions.show('error', {
                  title: 'Sorry!',
                  body: 'Only one discount code is allowed on this checkout.',
                })

                return
              }

              await applyDiscountCodeMutation.mutateAsync({
                pathParams: {
                  tabId: publicCollection.slug,
                  cartUuid: cart.uuid,
                  discountCode: code,
                },
              })

              setCode('')
              await onIsDiscountAppliedChange?.(false)
            } catch (err: any) {
              growlActions.clear()
              if (err.response?.data?.error) {
                growlActions.show('error', {
                  title: 'Sorry!',
                  body:
                    (
                      {
                        minimum_not_met: `The order minimum of ${Util.formatAmount(
                          err.response.data.error.minimum,
                        )} has not been met.`,
                        not_found: 'Discount code is invalid.',
                      } as any
                    )[err.response.data.error.code] ??
                    'Something went wrong applying your discount code. Please try again',
                })
              } else {
                growlActions.show('error', {
                  title: 'Sorry!',
                  body: 'Something went wrong applying your discount code. Please try again',
                })
              }
            }
          } else {
            for (const appliedCode of appliedDiscountCodes) {
              await resetDiscountCodeMutation.mutateAsync({
                pathParams: {
                  tabId: publicCollection.slug,
                  cartUuid: cart.uuid,
                  discountCode: appliedCode,
                },
              })
            }

            setCode('')
            await onIsDiscountAppliedChange?.(false)
          }

          setIsLoading(false)
        }}
      >
        {code || !isDiscountApplied ? 'Apply' : 'Reset'}
      </WebUI.Button>
    </div>
  )
}
