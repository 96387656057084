import {z} from '@cheddarup/util'

export const tabDepositSchema = z.object({
  id: z.number(),
  amount_cents: z.number(),
  paid: z.boolean(),
  available_on: z.string().nullish(),
  created_at: z.string(),
  status: z.string(),
  bank_account: z.object({
    last4: z.string(),
    bank_name: z.string(),
  }),
})

declare global {
  namespace Api {
    type TabDeposit = z.infer<typeof tabDepositSchema>
  }
}
