import {useParams} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import {AppHeader} from './AppHeader'
import {CollectionsHeaderMobile} from './CollectionsMobileLayout/CollectionsMobileLayout'
import {AbsoluteLinkButton, LinkButton} from './LinkButton'
import {ToolbarLinkItem} from './ToolbarLinkItem'
import CollectionAppBanner from './CollectionAppBanner'
import {useManagerRole} from './ManageRoleProvider'

const CollectionCenterLayout = ({children}: {children: React.ReactNode}) => {
  const media = WebUI.useMedia()

  return (
    <>
      <WebUI.VStack>
        {media.sm ? <AppHeader /> : <CollectionsHeaderMobile />}
        <CollectionAppBanner />
      </WebUI.VStack>
      <CollectionNavbar />
      {children}
    </>
  )
}

// MARK: – CollectionNavbar

interface CollectionNavbarProps extends React.ComponentPropsWithoutRef<'div'> {}

const CollectionNavbar = ({className, ...restProps}: CollectionNavbarProps) => {
  const media = WebUI.useMedia()
  const urlParams = useParams()
  const [managerRole] = useManagerRole()
  const {data: collection} = api.tabs.detail.useQuery(
    // biome-ignore lint/style/noNonNullAssertion:
    {pathParams: {tabId: urlParams.collection!}},
    {enabled: !!urlParams.collection},
  )

  const isCollectionPublished = !!collection && collection.status !== 'draft'

  const PreviewLinkComp = isCollectionPublished
    ? LinkButton
    : AbsoluteLinkButton

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'relative max-h-16 min-h-13 justify-between bg-gray750 text-natural-100 shadow-z1 sm:min-h-16 sm:min-w-16 print:hidden [&_.ToolbarLinkItem]:text-ds-base sm:[&_.ToolbarLinkItem]:text-ds-lg',
        className,
      )}
      {...restProps}
    >
      {media.sm && (
        <WebUI.HStack className="grow items-center pl-8">
          <WebUI.Ellipsis className="text-ds-lg">
            {collection?.name ?? 'Untitled'}
          </WebUI.Ellipsis>
        </WebUI.HStack>
      )}
      <WebUI.Toolbar aria-label="Navigation">
        <ToolbarLinkItem
          disabled={managerRole?.permissions?.role === 'viewer'}
          preserveSearch
          to="details"
        >
          {isCollectionPublished ? 'Edit' : 'Build'}
        </ToolbarLinkItem>
        <ToolbarLinkItem disabled={!collection} to={{pathname: 'share'}}>
          {isCollectionPublished ? 'Share' : 'Publish'}
        </ToolbarLinkItem>
        <ToolbarLinkItem
          disabled={!collection || !isCollectionPublished}
          preserveSearch
          to="manage"
        >
          Manage
        </ToolbarLinkItem>
      </WebUI.Toolbar>
      {urlParams.collection && (
        <PreviewLinkComp
          className="h-auto rounded-none text-ds-base hover:text-inherit sm:text-ds-lg"
          variant="default"
          target={isCollectionPublished ? '_blank' : '_self'}
          disabled={!collection?.slug}
          to={
            isCollectionPublished
              ? `/c/${collection?.slug}`
              : `c/${collection?.slug}`
          }
        >
          {isCollectionPublished ? 'View' : 'Preview'}
        </PreviewLinkComp>
      )}
    </WebUI.HStack>
  )
}

export default CollectionCenterLayout
