import {
  BooleanParam,
  DelimitedNumericArrayParam,
  ObjectParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import {Link, useLocation} from 'react-router-dom'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {getMissingRequiredItems, isItemViewSoldOut} from '@cheddarup/core'

import useCart from '../../../hooks/useCart'
import usePublicCollection from '../../../hooks/usePublicCollection'
import {ItemViewGridItem, ItemViewRow} from './ItemViewCollectionItem'
import {AddItemViewToCartForm} from '../AddItemViewToCartForm'

export interface ItemPickerInstance {
  viewRequiredItems: () => void
}

export const ItemPicker = React.forwardRef<
  ItemPickerInstance,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => {
  const location = useLocation()
  const [preview] = useQueryParam('preview', BooleanParam)
  const [categoryPath] = useQueryParam(
    'categoryPath',
    withDefault(DelimitedNumericArrayParam, []),
  )
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const filteredItemViews = useFilteredItemViews()
  const itemViewRefMap = useRef<Record<string, HTMLDivElement | null>>({})
  const [missingItemViewsHighlighted, setMissingItemViewsHighlighted] =
    useState(false)

  useImperativeHandle(
    forwardedRef,
    () => ({
      viewRequiredItems: () => {
        const [firstMissingItemView] = getMissingRequiredItems({
          publicTab: publicCollection,
          cart,
        })

        if (firstMissingItemView != null) {
          const element = itemViewRefMap.current[firstMissingItemView.id]

          // HACK: No idea why it doesn't work without the timeout
          setTimeout(() => {
            element?.scrollIntoView({block: 'center', behavior: 'smooth'})
          }, 200)
        }

        setMissingItemViewsHighlighted(true)
      },
    }),
    [cart, publicCollection],
  )

  useEffect(() => {
    if (cart?.items) {
      setMissingItemViewsHighlighted(false)
    }
  }, [cart?.items])

  useEffect(() => {
    const [categoryId] = categoryPath
    if (categoryId) {
      document.querySelector(`#category-${categoryId}`)?.scrollIntoView()
    }
  }, [categoryPath])

  const visibleCategories =
    categoryPath.length === 0
      ? publicCollection.categories
      : [
          publicCollection.categories.find(
            (category) => category.id === categoryPath[0],
          ),
        ].filter((c) => !!c)
  const uncategorizedItemViews = publicCollection.items.filter(
    ({parent_id}) => !parent_id,
  )

  const missingItemViewIds = getMissingRequiredItems({
    publicTab: publicCollection,
    cart,
  }).map((i) => i.id)

  const renderItemViewAsForm = (itemView: Api.PublicTabItem) => (
    <AddItemViewToCartForm key={itemView.id} itemView={itemView}>
      {renderItemViewContent(itemView)}
    </AddItemViewToCartForm>
  )

  const renderItemViewAsLink = (itemView: Api.PublicTabItem) => (
    <Link
      key={itemView.id}
      className="text-gray800"
      to={{pathname: `item/${itemView.id}`, search: location.search}}
    >
      {renderItemViewContent(itemView)}
    </Link>
  )

  const renderItemViewContent = (itemView: Api.PublicTabItem) => {
    const commonProps = {
      itemView,
      'aria-invalid':
        missingItemViewsHighlighted && missingItemViewIds.includes(itemView.id),
    }

    return publicCollection.defaultToGalleryView ? (
      <ItemViewGridItem
        key={itemView.id}
        ref={(gridItemRef) => {
          itemViewRefMap.current[itemView.id] = gridItemRef
        }}
        {...commonProps}
      />
    ) : (
      <ItemViewRow
        key={itemView.id}
        ref={(listItemRef) => {
          itemViewRefMap.current[itemView.id] = listItemRef
        }}
        {...commonProps}
      />
    )
  }

  const renderItemView = (itemView: Api.PublicTabItem) => {
    const isSoldOut = isItemViewSoldOut({itemView, cart})

    return !!preview ||
      isItemViewRaw(itemView) ||
      (itemView.options.waitlist?.enabled && isSoldOut)
      ? renderItemViewAsLink(itemView)
      : renderItemViewAsForm(itemView)
  }

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      {visibleCategories.map((category) => {
        const categoryItemViews = filteredItemViews.filter(
          (itemView) => itemView.parent_id === category.id,
        )

        return categoryItemViews.length === 0 ? null : (
          <ItemPickerCategorySection
            key={category.id}
            grid={!!publicCollection.defaultToGalleryView}
            category={category as any}
          >
            {categoryItemViews.map(renderItemView)}
          </ItemPickerCategorySection>
        )
      })}
      {categoryPath.length === 0 && uncategorizedItemViews.length !== 0 && (
        <ItemPickerCategorySection
          grid={!!publicCollection.defaultToGalleryView}
        >
          {uncategorizedItemViews.map(renderItemView)}
        </ItemPickerCategorySection>
      )}
    </WebUI.VStack>
  )
})

// MARK: – ItemPickerCategorySection

export interface ItemViewListPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  grid?: boolean
  category?: Api.Category
  children: React.ReactNode
}

export const ItemPickerCategorySection: React.FC<ItemViewListPanelProps> =
  React.memo(({grid, category, className, children, ...restProps}) => (
    <WebUI.VStack
      className={WebUI.cn('gap-4 p-4 sm:[&.Panel]:p-8', className)}
      as={WebUI.Panel}
      variant="capsule"
      {...restProps}
    >
      {!!category && (
        <WebUI.Heading
          className="font-semibold"
          id={`category-${category.id}`}
          as="h2"
        >
          {category.name}
        </WebUI.Heading>
      )}
      {!!category?.description &&
        !Util.isMarkdownEmpty(category.description) && (
          <WebUI.MarkdownParagraph
            className="font-light text-ds-md"
            id={`item-category-${category.id}`}
            markdown={category.description}
          />
        )}
      {grid ? (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(144px,1fr))] gap-4 sm:grid-cols-[repeat(auto-fill,minmax(176px,1fr))]">
          {children}
        </div>
      ) : (
        <WebUI.VStack className="gap-4">{children}</WebUI.VStack>
      )}
    </WebUI.VStack>
  ))

// MARK: – Helpers

export const useFilteredItemViews = () => {
  const {publicCollection} = usePublicCollection()
  const [categoryPath] = useQueryParam(
    'categoryPath',
    withDefault(DelimitedNumericArrayParam, []),
  )
  const [selectedFilterValues] = useQueryParam(
    'filters',
    withDefault(ObjectParam, {}),
  )

  const [categoryId, subcategoryId] = categoryPath as [number?, string?]

  return [...publicCollection.categories, null].flatMap((category) =>
    publicCollection.items.filter((itemView) => {
      const allOptionValues =
        (itemView.options.variants?.enabled &&
          itemView.options.variants?.listings
            .filter((l) => l.available_quantity && l.available_quantity > 0)
            .map((listing) => listing.optionValues)) ||
        []
      const selectedFilterValuesKeys = Object.keys(
        Util.omitBy(selectedFilterValues, Util.isEmpty),
      )

      const filtersSatisfied =
        selectedFilterValuesKeys.length === 0 ||
        allOptionValues.some((optionValues) =>
          selectedFilterValuesKeys
            .map((key) => {
              // biome-ignore lint/style/noNonNullAssertion:
              const selectedValues = (selectedFilterValues as any)[key]!

              return (
                selectedValues.length === 0 ||
                selectedValues.includes(optionValues[key])
              )
            })
            .every(Boolean),
        )

      return (
        (itemView.parent_id || null) === (category?.id ?? null) &&
        (!categoryId || itemView.parent_id === categoryId) &&
        (!subcategoryId || itemView.options.subcategoryId === subcategoryId) &&
        filtersSatisfied
      )
    }),
  )
}

function isItemViewRaw(itemView: Api.PublicTabItem) {
  return (
    itemView.amount_type !== 'fixed' ||
    !!itemView.allow_quantity ||
    itemView.images.length > 0 ||
    !!itemView.description ||
    !!itemView.options.recurring?.enabled ||
    itemView.options.variants?.enabled ||
    itemView.fields.length > 0
  )
}
