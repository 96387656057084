import {getBrandKitColors} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

interface AboutUsProps extends React.ComponentPropsWithoutRef<'div'> {
  aboutUs: Api.GroupPageSection
  branding?: Api.UserBranding
}

const AboutUs: React.FC<AboutUsProps> = ({
  branding,
  aboutUs,
  className,
  style,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const brandKitColors = getBrandKitColors(branding)

  return (
    <WebUI.VStack
      className={WebUI.cn('px-2 py-18', className)}
      style={{
        backgroundColor: aboutUs.background_color,
        color: Util.getReadableColor(aboutUs.background_color, undefined, true),
        ...style,
      }}
      {...restProps}
    >
      <WebUI.VStack className="mx-auto w-full max-w-[1100px] gap-5 px-6">
        <WebUI.Heading className="font-accentAlt text-h-4">
          {aboutUs.headline}
        </WebUI.Heading>
        <div className="flex flex-col gap-6">
          <WebUI.MarkdownParagraph
            className="sm:text-ds-md [&_h2]:text-ds-md"
            markdown={aboutUs.description}
          />
          {aboutUs.details.includeWebsiteLink &&
            branding?.footer.mainWebsiteLink?.text && (
              <WebUI.RoundedButton
                as={WebUI.AnchorButton}
                size={media.sm ? 'big' : 'default'}
                className="self-start"
                style={{
                  color: Util.getReadableColor(
                    brandKitColors.primary_color,
                    undefined,
                    true,
                  ),
                  backgroundColor: brandKitColors.primary_color,
                }}
                rel="noopener noreferrer"
                target="_blank"
                href={Util.normalizeUrl(branding.footer.mainWebsiteLink.link)}
              >
                {branding.footer.mainWebsiteLink.text}
              </WebUI.RoundedButton>
            )}
        </div>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default AboutUs
