import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'

export interface GroupPageSectionCardProps
  extends Util.Merge<
      React.ComponentPropsWithoutRef<'div'>,
      Required<Pick<WebUI.SortableProps, 'id'>>
    >,
    Pick<WebUI.CardProps, 'dragListeners' | 'dragHandleVisible'> {
  sectionName: string
  editPath: string
  deletable?: boolean
  palette?: string[]
  defaultColor?: string
  colorPickerText?: React.ReactNode
  onColorChange?: (color: string) => void
  onDelete: (sectionId: WebUI.UniqueIdentifier) => void
}

const GroupPageSectionCard = ({
  dragHandleVisible = true,
  deletable = true,
  sectionName,
  editPath,
  palette,
  defaultColor,
  colorPickerText,
  onColorChange,
  onDelete,
  id,
  children,
  ...restProps
}: GroupPageSectionCardProps) => (
  <WebUI.Sortable id={id} draggable={false} {...restProps}>
    {({dragListeners}) => (
      <WebUI.Card
        className="flex flex-col gap-4 p-4 sm:px-8"
        dragHandleVisible={dragHandleVisible}
        dragListeners={dragListeners}
        accessoryView={
          <WebUI.ActionGroup>
            {deletable && (
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="x" />}
                execute={() => onDelete(id)}
              >
                Delete
              </WebUI.Action>
            )}
            <WebUI.Action
              icon={<WebUI.PhosphorIcon icon="pencil" />}
              as={LinkButton}
              to={editPath}
            >
              Edit
            </WebUI.Action>
          </WebUI.ActionGroup>
        }
      >
        <WebUI.Text className="text-ds-sm">{sectionName}</WebUI.Text>
        <WebUI.Separator className="-mx-8" variant="primary" />
        {children}
        <WebUI.Separator className="-mx-8" variant="primary" />
        {palette && palette.length > 0 && (
          <div className="flex flex-col gap-4 sm:flex-row sm:gap-5">
            {!!colorPickerText && (
              <WebUI.Text className="text-ds-sm">{colorPickerText}</WebUI.Text>
            )}
            <WebUI.ColorSwatchPicker
              className="bg-natural-100"
              defaultValue={defaultColor}
              onChange={(event) => onColorChange?.(event.target.value)}
            >
              {palette.map((c) => (
                <WebUI.ColorSwatchPickerItem
                  key={c}
                  className="h-5 w-5 border"
                  color={c}
                />
              ))}
            </WebUI.ColorSwatchPicker>
          </div>
        )}
      </WebUI.Card>
    )}
  </WebUI.Sortable>
)

export default GroupPageSectionCard
