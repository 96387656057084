import {sumBy} from 'remeda'
import {randomInteger} from 'remeda'

export {sumBy, randomInteger}

export function sum(collection: number[]) {
  return sumBy(collection, (i) => i)
}

export function clamp(
  value: number,
  min = Number.NEGATIVE_INFINITY,
  max: number = Number.POSITIVE_INFINITY,
) {
  return Math.min(Math.max(value, min), max)
}
