import {useState} from 'react'
import {
  api,
  useUpdateGroupPageMutation,
  useUpdateUserMutation,
} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {
  useManagerRole,
  useManagerRoleId,
  useUserSlug,
} from 'src/components/ManageRoleProvider'
import GroupPagePublishSuccessConfetti from './GroupPagePublishSuccessConfetti'
import {NextButton, NextPopoverDisclosure} from '@cheddarup/web-ui/next'
import {NextLinkButton} from 'src/components/__next/LinkButton'
import {NextLink} from 'src/components/__next/Link'
import {ShareQRCodeLinkModal} from 'src/components/ShareQRCodeLinkModal'
import {Link} from 'src/components/Link'

export interface GroupPagePublishAndSharePanelProps
  extends React.ComponentPropsWithoutRef<'div'> {}

const GroupPagePublishAndSharePanel = ({
  className,
  ...restProps
}: GroupPagePublishAndSharePanelProps) => {
  const [isConfettiVisible, setIsConfettiVisible] = useState(false)
  const [managerRoleId] = useManagerRoleId()
  const userSlug = useUserSlug()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const {data: groupPage} = api.groupPages.detail.useQuery()
  const hasGroupPageTabsQuery = api.groupPageCategories.list.useQuery(
    undefined,
    {
      enabled: !!groupPage?.sections.collection_section,
      select: (categories) =>
        categories.some((cat) => cat.collections.length > 0),
    },
  )
  const updateGroupPageMutation = useUpdateGroupPageMutation()

  const isPublished = groupPage?.status === 'published'
  const isPublishAvailable =
    managerRoleId != null || !!isSubscribedToTeamQuery.data
  const hasSections = Object.values(groupPage?.sections ?? {}).some(
    (s) => s != null,
  )

  const mePageLink = userSlug ? `/me/${userSlug}` : undefined

  return (
    <WebUI.Panel
      className={WebUI.cn('flex flex-col gap-4 p-6', className)}
      {...restProps}
    >
      <div className="flex flex-col gap-2">
        <WebUI.Heading className="text-ds-md" as="h3">
          {isPublishAvailable
            ? `Preview and ${isPublished ? 'Share' : 'Publish'}`
            : 'Preview Your Group Page'}
        </WebUI.Heading>
        <WebUI.Text className="font-light">
          {(() => {
            if (!isPublishAvailable) {
              return (
                <>
                  Start adding{' '}
                  <NextPopoverDisclosure
                    render={
                      <WebUI.Button
                        className="[&_>_.Text]:font-light"
                        variant="link"
                      />
                    }
                  >
                    page sections
                  </NextPopoverDisclosure>{' '}
                  to your group page to preview. Upgrade to our Team plan to
                  publish and share.
                </>
              )
            }
            if (!hasSections) {
              return (
                <>
                  Start adding{' '}
                  <NextPopoverDisclosure
                    render={
                      <WebUI.Button
                        className="[&_>_.Text]:font-light"
                        variant="link"
                      />
                    }
                  >
                    page sections
                  </NextPopoverDisclosure>{' '}
                  to your group page to preview and publish.
                </>
              )
            }
            if (!hasGroupPageTabsQuery.data) {
              return (
                <>
                  <NextLink to="add-collections">Add some collections</NextLink>{' '}
                  to publish and share your Group Page.
                </>
              )
            }

            return 'You can continue to edit your page after you’ve published it.'
          })()}
        </WebUI.Text>
      </div>
      <div className="flex flex-row gap-3 *:flex-auto">
        {!!mePageLink && (
          <NextLinkButton
            disabled={!isPublished && !hasSections}
            target={isPublished ? '_blank' : ''}
            to={mePageLink}
          >
            {isPublished ? 'View' : 'Preview'}
          </NextLinkButton>
        )}
        {isPublishAvailable ? (
          isPublished ? (
            userSlug != null && (
              <GroupPageShareLinkPrompt
                userSlug={userSlug}
                disclosure={
                  <WebUI.DialogDisclosure as={NextButton} variant="orange">
                    Share
                  </WebUI.DialogDisclosure>
                }
              />
            )
          ) : (
            <NextButton
              variant="orange"
              disabled={!hasGroupPageTabsQuery.data}
              loading={updateGroupPageMutation.isPending}
              onClick={async () => {
                try {
                  await updateGroupPageMutation.mutateAsync({
                    body: {status: 'published'},
                  })
                  setIsConfettiVisible(true)
                } catch {
                  // noop
                }
              }}
            >
              Publish
            </NextButton>
          )
        ) : (
          <NextLinkButton
            variant="orange"
            to="/group/i/plans?recommendedPlan=team"
          >
            Upgrade
          </NextLinkButton>
        )}
      </div>
      {isConfettiVisible && <GroupPagePublishSuccessConfetti />}
    </WebUI.Panel>
  )
}

// MARK: – GroupPageShareLinkPrompt

interface GroupPageShareLinkPromptProps extends WebUI.PromptProps {
  userSlug: string
}

const GroupPageShareLinkPrompt = ({
  userSlug,
  ...restProps
}: GroupPageShareLinkPromptProps) => {
  const [managerRole] = useManagerRole()
  const updateUserMutation = useUpdateUserMutation()
  const [editMode, setEditMode] = useState(false)
  const [slug, setSlug] = useState(userSlug ?? '')
  const [error, setError] = useState('')
  const growlActions = WebUI.useGrowlActions()

  const groupPageLink = `https://${slug}.mycheddarup.com`

  return (
    <WebUI.Prompt aria-label="Share group page link" {...restProps}>
      <div className="flex flex-col gap-4">
        <WebUI.PromptHeader
          heading="Share Your Link"
          subheading="Copy and share your Group Page URL"
        />
        <div className="flex flex-row gap-3">
          {editMode ? (
            <div className="flex flex-col gap-6">
              <div className="flex flex-row gap-1">
                <div className="flex flex-row items-center justify-center rounded-default bg-teal-80 px-2 text-center">
                  https://
                </div>
                <WebUI.FormField error={error}>
                  <WebUI.Input
                    className="grow text-center"
                    size="compact"
                    value={slug}
                    onChange={(event) => setSlug(event.target.value)}
                  />
                </WebUI.FormField>
                <div
                  className={
                    'flex flex-row items-center justify-center rounded-default bg-teal-80 px-2 text-center text-gray800'
                  }
                >
                  .mycheddarup.com
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <WebUI.Button
                  loading={updateUserMutation.isPending}
                  onClick={async () => {
                    const SLUG_REGEX = /^[\da-z]+(?:-[\da-z]+)*$/i

                    if (!slug) {
                      setError('Required')
                    } else if (slug.length > 63) {
                      setError('Must be 63 characters or less')
                    } else if (SLUG_REGEX.test(slug)) {
                      try {
                        const {
                          user: {slug: updatedSlug},
                        } = await updateUserMutation.mutateAsync({body: {slug}})
                        setSlug(updatedSlug)
                        setEditMode(false)
                      } catch {
                        setError(
                          'The slug was already taken. Please try another one.',
                        )
                      }
                    } else {
                      setError('Invalid format')
                    }
                  }}
                >
                  Save
                </WebUI.Button>

                <WebUI.Button
                  variant="secondary"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </WebUI.Button>
              </div>
            </div>
          ) : (
            <>
              <WebUI.Input
                className="grow"
                name="name"
                readOnly
                placeholder="Required"
                maxLength={44}
                value={groupPageLink}
                onClick={() => {
                  if (managerRole == null) {
                    setEditMode(true)
                  }
                }}
              />
              <WebUI.Button
                onClick={async () => {
                  try {
                    await WebUI.copyToClipboard(groupPageLink)
                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Link copied',
                    })
                  } catch {
                    // noop
                  }
                }}
              >
                Copy
              </WebUI.Button>
              <ShareQRCodeLinkModal
                link={groupPageLink}
                text="Share this QR code that will send people to your Group Page URL. Download it or take a screenshot, then add it to any of your marketing materials."
                disclosure={
                  <WebUI.DialogDisclosure
                    className="bg-gray400 text-ds-xl text-natural-100"
                    as={WebUI.IconButton}
                    variant="secondary"
                    size="default_alt"
                  >
                    <WebUI.PhosphorIcon icon="qr-code" />
                  </WebUI.DialogDisclosure>
                }
              />
            </>
          )}
        </div>
        {!editMode && (
          <>
            {managerRole == null && (
              <WebUI.Button
                className="text-ds-sm"
                variant="link"
                onClick={() => setEditMode((prevEditMode) => !prevEditMode)}
              >
                Edit Link
              </WebUI.Button>
            )}
            <div className="flex flex-row gap-4 text-ds-xl">
              <WebUI.Anchor
                href={`https://www.facebook.com/dialog/feed?app_id=413118622200717&link=${groupPageLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WebUI.PhosphorIcon icon="facebook-logo" width={24} />
              </WebUI.Anchor>
              <WebUI.Anchor
                href={`http://twitter.com/share?text=Check+it+out:&url=${groupPageLink}&hashtags=`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WebUI.PhosphorIcon icon="twitter-logo" width={24} />
              </WebUI.Anchor>
              <Link variant="primary" to="message-center?groupPage=1">
                <WebUI.PhosphorIcon icon="envelope-simple" width={24} />
              </Link>
            </div>
          </>
        )}
      </div>
    </WebUI.Prompt>
  )
}

export default GroupPagePublishAndSharePanel
