import {useContext, useMemo} from 'react'

import {FieldSetList} from '../FieldSetList'
import {FieldsEditInnerContext, unparseFieldGroups} from './FieldsEdit'

export const FieldGroupsPreview = () => {
  const contextValue = useContext(FieldsEditInnerContext)

  const fieldGroups = unparseFieldGroups({
    fieldGroups: contextValue.fieldGroups,
    fields: [],
  })

  const fieldSets = useMemo(
    () => fieldGroups.map((fg) => fg.fieldSet),
    [fieldGroups],
  )
  const fields = useMemo(
    () => fieldGroups.flatMap((fg) => fg.fields),
    [fieldGroups],
  )

  return (
    <FieldSetList
      fieldSets={fieldSets}
      fields={fields as any}
      defaultValues={{}}
      onValuesChange={() => {}}
    />
  )
}
